import styled from "styled-components";

const TaskDetailsCardDropDownWrapper = styled.div`
.games-dropdown-2 {
    background-color: #023950;
    color: #FFFFFF;
    padding-left: 15px;
    width: 93%;
  }
  
  .pretty.p-default input:checked ~ .state label:after{
      background-color:grey !important;
}

//datepicker style---

.date-picker-block {
  .roe-card-style {
    height: 100%;
  }
}
.react-datepicker-popper{
  box-shadow: 0px 0px 15px 1px #0000001a !important; 
}

.react-datepicker {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border: 0px solid !important;
 
}
.react-datepicker__triangle{
  display: none !important;
}
.react-datepicker__input-container {
  width: 100%;
}
.custom-datepicker {
  color: black !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  border: 1px solid #E6E6E6 !important;
  background-color: #F8F8F8 !important;
  padding: 2px 8px;
  width: 100%;
  &:focus {
    border: 1px solid #9a9a9a !important;
    box-shadow: none !important;
    outline: 0px !important;
  }
}
.react-datepicker__time-container {
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${props => props.topbarTheme};
    color: white;
    font-weight: bold;
  }
}

.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
  }
}

.custom-calender-class {
  .react-datepicker__triangle {
    border-bottom-color: rgba(0, 0, 0, 0.035);
  }
  .react-datepicker__time-list-item {
    color: black;
  }
  .react-datepicker__header {
    background: rgba(255, 255, 255) !important;
    border-bottom: none !important;
    .react-datepicker-time__header {
      color: rgba(0, 0, 0, 0.87) !important;
    }
    .react-datepicker__current-month {
      color: rgba(0, 0, 0, 0.87) !important;
    }
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: rgba(0, 0, 0, 0.87) !important;
      }
    }
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
    background-color: ${props => props.topbarTheme};
  }
  .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected{
    background-color: ${props => props.topbarTheme};
  }
  .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover{
    background-color: ${props => props.topbarTheme};
  }
}

  .react-datepicker__month {
    .react-datepicker__week {
      .react-datepicker__day--selected {
        background-color: ${props => props.topbarTheme};
        color: white;
      }
    }
  }
}

.pretty input{
  z-index:0 !important;
}
`;

export default TaskDetailsCardDropDownWrapper;
