import React, { useState, useRef, useEffect } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import CardTask from "./CardTask";
import classNames from "classnames";
import { projectSocketRoomPrefix } from "constatnt";
import { threeDot_Icon, addnew } from "helper/constant";
import { addTask, archiveTask, deleteTask, updateTask } from "services/socket";
import NewTask from "components/newTask/NewTask";
import { connect } from "react-redux";
import ToastMessage from "components/notifications/ToastMessage";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import EditList from "./EditList";
import ApiCalendar from "react-google-calendar-api";
import "./boardcard.style.css";
import IntlMessages from "util/intlMessages";
const BoardCard = props => {
  const { addToast } = useToasts();
  const [addCardFlag, setAddCardFlag] = useState(false);
  const [isEditTask, setIsEditTask] = useState({
    status: false,
    data: "",
    action: ""
  });

  const [taskLabel, setTaskLabel] = useState("");
  const [taskProject, setTaskProject] = useState("");
  const [todo, setTodo] = useState([]);
  const scrollRef = useRef(null);
  const bgColour = "#808080";
  const [isSectionScroll, setIsSectionScroll] = useState(false);

  const appStyles = {
    color: `${bgColour}`,
    display: "flex",
    // justifyContent: "left",
    alignItems: "center",
    marginTop: 5,
    marginLeft: 5,
    paddingLeft: 0
  };
  const {
    card,
    droppableId,
    tasks,
    index,
    openTaskDialogHandler,
    showNotifications,
    setSectionmenu,
    setSectionmenuID,
    sectionmenu,
    SetEditSectionData,
    refreshTodoList,
    editSectionFlag,
    editSectionData,
    SetEditSectionFlag,
    isUpdateSectionLoading,
    doUpdateSection,
    topbarTheme,
    setDeleteListTitleNameListView,
    SetEditSectionToggle,
    isBoardCardscroll,
    setIsBoardCardscroll,
    isGoogleCalenderData,
    setIsGoogleCalenderData,
    setSyncGoogle,
    syncGoogle,
    projectData,
    setProject,
    isBoardCardSectionClick,
    setIsBoardCardSectionClick
  } = props;
  // useEffect(() => {
  //   setTodo(tasks ? tasks : []);

  //   return () => {
  //     setIsEditTask({ status: false, data: "" });
  //   };
  // }, [tasks]);

  useEffect(() => {
    if (addCardFlag) {
      scrollToBottom();
    }
  }, [addCardFlag]);

  useEffect(() => {
    if (isBoardCardSectionClick) {
      setIsEditTask({
        status: false,
        data: ""
      });
      setAddCardFlag(false);
    }
  }, [isBoardCardSectionClick]);

  const scrollToBottom = () => {
    let element = scrollRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }
  };

  const handleScroll = () => {
    // Your scroll event handling logic here
    setIsSectionScroll(true);
  };
  const SyncTasktoGoogle = async taskData => {
    if (taskData) {
      return;
    }
    var eventFromNow = {
      summary: taskData?.title?.toString(),
      description: taskData?.description?.toString(),
      start: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      end: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    };
    if (isGoogleCalenderData == false) {
      return ApiCalendar.createEvent(eventFromNow).then(result => {
        if (result.error) {
          addToast(ToastMessage(result.error.message), {
            appearance: "error",
            autoDismiss: true
          });
        }
        addToast(ToastMessage("Sync to Google"), {
          appearance: "success",
          autoDismiss: true
        });
        return result.result.id;
      });
      // .catch(error => {
      //   addToast(ToastMessage("Something went wrong"), {
      //     appearance: "error",
      //     autoDismiss: true
      //   });
      // });
    } else {
      if (isEditTask && isEditTask?.data && isEditTask?.data?.calendar_id) {
        ApiCalendar.updateEvent(
          eventFromNow,
          isEditTask?.data?.calendar_id
        ).then(result => {
          if (result.error) {
            addToast(ToastMessage(result.error.message), {
              appearance: "error",
              autoDismiss: true
            });
          }
          addToast(ToastMessage("Event is Update"), {
            appearance: "success",
            autoDismiss: true
          });
          return true;
        });
        // .catch(error => {
        //   addToast(ToastMessage("Something went wrong"), {
        //     appearance: "error",
        //     autoDismiss: true
        //   });
        // });
      } else {
        return ApiCalendar.createEvent(eventFromNow).then(result => {
          if (result.error) {
            addToast(ToastMessage(result.error.message), {
              appearance: "error",
              autoDismiss: true
            });
          }
          addToast(ToastMessage("Sync to Google"), {
            appearance: "success",
            autoDismiss: true
          });
          return result.result.id;
        });
        // .catch(error => {
        //   addToast(ToastMessage("Something went wrong"), {
        //     appearance: "error",
        //     autoDismiss: true
        //   });
        // });
      }
    }
  };

  // const newTask = (payloadInput,) => {
  //   let payload = {
  //     ...payloadInput,
  //     project_id: props.projectId.toString(),
  //     section_id: props.sectionId ? props.sectionId.toString() : "",
  //     roomname: `${projectSocketRoomPrefix}${props.projectId}`
  //   };
  //   return payload;
  // }
  const addTaskHandler = async (payloadInput, syncGoogle) => {
    try {
      if (payloadInput && payloadInput.title) {
        let payload = {
          tempId: payloadInput?.tempId,
          title: payloadInput?.title,
          description: payloadInput?.description,
          label_ids: payloadInput?.label_ids,
          reminderAt: payloadInput?.reminderAt,
          priority: payloadInput?.priority,
          user_id: payloadInput?.user_id,
          scheduledAt: payloadInput?.scheduledAt,
          repeatType: "DO_NOT_REPEAT",
          project_id: props.projectId.toString(),
          section_id: props.sectionId ? props.sectionId.toString() : "",
          roomname: `${projectSocketRoomPrefix}${props.projectId}`
        };
        let array = projectData;
        let menupuletedArray = array?.section?.map((item, key) => {
          if (item.id == payload.section_id) {
            let data = {
              ...payloadInput,
              orderId: item.task.length + 1,
              doneSectionId: `${item.id}`,
              id: payload.tempId
            };
            return { ...item, task: [...item.task, data] };
          } else {
            return item;
          }
        });
        let data = { ...projectData, section: menupuletedArray };
        setProject({ ...data });
        setAddCardFlag(true);

        if (payloadInput && payloadInput.title && syncGoogle == true) {
          const calendarId = await SyncTasktoGoogle(payload);
          if (payloadInput && payloadInput.title && calendarId) {
            let payloads = {
              ...payloadInput,
              project_id: props.projectId.toString(),
              section_id: props.sectionId ? props.sectionId.toString() : "",
              roomname: `${projectSocketRoomPrefix}${props.projectId}`,
              calendar_id: calendarId ? calendarId : ""
            };
            setAddCardFlag(true);
            addTask(payloads, cb => {
              if (cb.status == "Ok") {
                setAddCardFlag(false);

                if (
                  payloads?.scheduledAt &&
                  payloads?.scheduledAt != null &&
                  syncGoogle
                ) {
                  // SyncTasktoGoogle(payload);
                }
              } else {
                if (cb.status || cb.message) {
                  props.handleResponse(cb.message);
                }
                /**
                 * @TODO
                 * Handle error callback from socket
                 * sample error cb: { status: "Failed", message: "error message" };
                 */
                showNotifications();
              }
            });
          }
        }
        addTask(payload, cb => {
          if (addCardFlag) {
            scrollToBottom();
          }
          if (cb.status == "Ok") {
            setAddCardFlag(true);

            if (
              payload?.scheduledAt &&
              payload?.scheduledAt != null &&
              syncGoogle
            ) {
              SyncTasktoGoogle(payload);
            }
          } else {
            if (cb.status || cb.message) {
              props.handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
            showNotifications();
          }
        });
      }
    } catch (e) {}
  };

  const deleteTaskHandlerCard = async (id, projectId, calendarId) => {
    let payload = {
      project_id: projectId.toString(),
      isDeleted: true,
      task_id: id.toString(),
      roomname: `${projectSocketRoomPrefix}${projectId}`
    };
    if (
      calendarId != "" &&
      calendarId != null &&
      calendarId != "null" &&
      calendarId != undefined
    ) {
      ApiCalendar.deleteEvent(calendarId).then(result => {
        if (result.error) {
          addToast(ToastMessage(result.error.message), {
            appearance: "error",
            autoDismiss: true
          });
        }
        addToast(ToastMessage("Event is deleted"), {
          appearance: "success",
          autoDismiss: true
        });
        return true;
      });
      // .catch(error => {
      //
      //   addToast(ToastMessage("Something went wrong"), {
      //     appearance: "error",
      //     autoDismiss: true
      //   });
      //
      // });
      deleteTask(payload, cb => {
        if (cb.status == "Ok") {
          props.showNotifications(
            <IntlMessages id={"project.taskdeleted"} />,
            "success"
          );
        } else {
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
          showNotifications();
        }
      });
    } else {
      deleteTask(payload, cb => {
        if (cb.status == "Ok") {
          props.showNotifications(
            <IntlMessages id={"project.taskdeleted"} />,
            "success"
          );
        } else {
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
          showNotifications();
        }
      });
    }
  };

  const doArchiveTask = async id => {
    try {
      if (id) {
        let payload = {
          roomname: `${projectSocketRoomPrefix}${props.projectId}`,
          task_id: id.toString()
        };
        archiveTask(payload, cb => {
          if (cb.status == "Ok") {
            props.showNotifications(cb.message, "success");
            setIsEditTask({ status: false, data: "" });
          } else {
            if (cb.status || cb.message) {
              props.handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            props.showNotifications();
          }
        });
      }
    } catch (e) {}
  };
  // const doUpdateTaskPriority = async (updatedPriority, taskDetails) => {
  //   const handleResponse = response => {
  //     if (response && response?.status.toLowerCase() == "ok") {
  //       setIsQuickActionTask({
  //         status: false,
  //         data: "",
  //         selectedData: "",
  //         action: "",
  //         target: ""
  //       });
  //     }
  //   };
  //   if (updatedPriority) {
  //     let payload = {
  //       priority: updatedPriority,
  //       task_id: isQuickActionTask?.data.id.toString()
  //     };

  //     doUpdateTask(payload, handleResponse);
  //   }
  // };
  // const doUpdateTaskScheduleDate = async (updatedDueDate, taskDetails) => {
  //   const handleResponse = response => {
  //     if (response && response?.status.toLowerCase() == "ok") {
  //       setIsQuickActionTask({
  //         status: false,
  //         data: "",
  //         selectedData: "",
  //         action: "",
  //         target: ""
  //       });
  //     }
  //   };
  //   let payload = {
  //     scheduledAt: updatedDueDate,
  //     task_id: isQuickActionTask?.data.id.toString()
  //   };

  //   doUpdateTask(payload, handleResponse);
  // };
  // const doUpdateTaskAssign = async udpatedAssignee => {
  //   const handleResponse = response => {
  //     if (response && response?.status.toLowerCase() == "ok") {
  //       setIsQuickActionTask({
  //         status: false,
  //         data: "",
  //         selectedData: "",
  //         action: "",
  //         target: ""
  //       });
  //     }
  //   };

  //   let payload = {
  //     user_id: udpatedAssignee,
  //     task_id: isQuickActionTask?.data.id.toString()
  //   };

  //   doUpdateTask(payload, handleResponse);
  // };
  // const doUpdateTask = (payloadInput, handleResponse) => {
  //   if (payloadInput) {
  //     try {
  //       let payload = {
  //         ...payloadInput,
  //         project_id: props.projectId.toString(),
  //         section_id: props.sectionId ? props.sectionId.toString() : "",
  //         roomname: `${projectSocketRoomPrefix}${props.projectId}`
  //       };

  //       if (!payloadInput.reminderAt && isQuickActionTask.data.reminderAt) {
  //         payload = {
  //           ...payload,
  //           reminderAt: isQuickActionTask.data.reminderAt
  //         };
  //       }

  //       if (
  //         !payloadInput.hasOwnProperty("scheduledAt") &&
  //         isQuickActionTask.data.scheduledAt
  //       ) {
  //         payload = {
  //           ...payload,
  //           scheduledAt: isQuickActionTask.data.scheduledAt
  //         };
  //       }

  //       updateTask(payload, cb => {
  //         if (cb.status == "Ok") {
  //           if (handleResponse) {
  //             handleResponse({
  //               status: cb.status
  //             });
  //           }
  //           setIsEditTask({ status: false, data: "" });
  //           if (payload?.scheduledAt && payload?.scheduledAt != null) {
  //             SyncTasktoGoogle(payload);
  //           }
  //         } else {
  //           if (cb.status || cb.message) {
  //             props.handleResponse(cb.message);
  //           }
  //           /**
  //            * @TODO
  //            * Handle error callback from socket
  //            * sample error cb: { status: "Failed", message: "error message" };
  //            */

  //           props.showNotifications();
  //         }
  //       });
  //     } catch (e) {}
  //   }
  // };

  const updateTaskHandler = async (payloadInput, syncGoogle) => {
    try {
      if (payloadInput.tempId) {
        delete payloadInput.tempId;
        delete payloadInput.taskAssign;
        delete payloadInput.labels;
      }
      if (payloadInput.title && isEditTask?.data.id) {
        const ids = taskLabel && taskLabel.map(a => a.id);
        let payload = {
          // title: taskinput.toString(),
          // description: taskDescription.toString() ? taskDescription : " ",
          // project_id: props.projectId.toString(),
          // label_ids: ids ? ids.toString() : "",
          //  scheduledAt: taskDueDate ? taskDueDate : "" ,
          // reminderAt: taskReminder ? taskReminder : null,
          // priority: taskPriority ? taskPriority : "",
          // user_id: taskAssigned ? taskAssigned.user.id.toString() : "",
          ...payloadInput,
          project_id: props.projectId.toString(),
          section_id: props.sectionId ? props.sectionId.toString() : "",
          roomname: `${projectSocketRoomPrefix}${props.projectId}`,
          task_id: isEditTask?.data.id.toString()
        };
        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            setIsEditTask({ status: false, data: "" });
            setIsEditTask({ status: false, data: "" });
            if (
              payload?.scheduledAt &&
              payload?.scheduledAt != null &&
              syncGoogle
            ) {
              SyncTasktoGoogle(payload);
            }
          } else {
            if (cb.status || cb.message) {
              props.handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            props.showNotifications();
          }
        });
      }
    } catch (e) {}
  };
  const toggleEditTask = async data => {
    if (data) {
      try {
        setTaskLabel(data.labels);
      } catch (e) {}
    }
  };

  return (
    <Draggable draggableId={droppableId} index={index} type="card">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="board-card card-shadow"
          onClick={e => {
            e.stopPropagation();
            setIsBoardCardSectionClick(false);
          }}
        >
          <div {...provided.dragHandleProps}>
            <div
              className="column-title"
              style={{ width: 350, marginLeft: "5px" }}
            >
              {editSectionFlag && editSectionData?.id == card?.id ? (
                <EditList
                  width={315}
                  title={card?.title}
                  id={card?.id}
                  orderId={card.orderId}
                  closeEditList={() => {
                    setSectionmenu(false);
                    setSectionmenuID(null);
                    SetEditSectionData("");
                    SetEditSectionFlag(false);
                  }}
                  isUpdateSectionLoading={isUpdateSectionLoading}
                  onSubmit={doUpdateSection}
                  PRIMARY_COLOR={topbarTheme.buttonColor}
                />
              ) : (
                <div
                  onClick={() => {
                    SetEditSectionToggle();
                    setDeleteListTitleNameListView(card.title);
                    if (sectionmenu) {
                      setSectionmenu(false);
                      setSectionmenuID(null);
                      SetEditSectionData("");
                    } else {
                      // setSectionmenu(true);
                      setSectionmenuID(`todomenu-board-${card?.id}`);
                      SetEditSectionData(card);
                    }
                  }}
                  // contentEditable={true}
                  title={card.title}
                  className="title fs-14 bold-text cursor-pointer"
                  style={{
                    fontWeight: 600,
                    color: "black",
                    width: "100%",
                    padding: "8px"
                  }}
                >
                  {card.title}
                </div>
              )}

              <div
                className="gray-hover"
                style={{
                  display:
                    editSectionFlag && editSectionData?.id == card?.id
                      ? "none"
                      : "block"
                }}
                id={`todomenu-board-${card?.id}`}
                onClick={() => {
                  setDeleteListTitleNameListView(card.title);
                  if (sectionmenu) {
                    setSectionmenu(false);
                    setSectionmenuID(null);
                    SetEditSectionData("");
                  } else {
                    setSectionmenu(true);
                    setSectionmenuID(`todomenu-board-${card?.id}`);
                    SetEditSectionData(card);
                  }
                }}
              >
                <img
                  alt=""
                  src={threeDot_Icon}
                  style={{
                    height: 6,
                    width: 22,
                    display:
                      editSectionFlag && editSectionData?.id == card?.id
                        ? "none"
                        : "block"
                    // cursor: "pointer"
                  }}
                ></img>
              </div>
            </div>
          </div>
          <Droppable droppableId={droppableId} type="task" direction="vertical">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  width: 360,
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: "75vh",
                  position: "relative",
                  whiteSpace: " normal"
                }}
              >
                <div
                  ref={scrollRef}
                  onScroll={handleScroll}
                  style={{
                    padding: "0 8px",
                    marginLeft: "5px"
                  }}
                  className={classNames(
                    "board-card-scroll",
                    addCardFlag && "board-card-height"
                  )}
                >
                  {tasks && tasks.length ? (
                    tasks
                      .sort((a, b) => a.orderId - b.orderId)
                      .map((task, index) => {
                        if (!task.isDeleted && !task.isArchived) {
                          return (
                            <>
                              {isEditTask &&
                              isEditTask?.status &&
                              isEditTask?.data.id == task.id ? (
                                <div
                                  className="add-card-input-block bg-white "
                                  style={{
                                    borderRadius: 5,
                                    marginBottom: 10
                                  }}
                                >
                                  <NewTask
                                    updateTask
                                    updateLabelToParent={data => {
                                      props.updateLabelToParent(data);
                                    }}
                                    type={"grid"}
                                    action={isEditTask?.action || ""}
                                    toggleAction={() => {
                                      setIsEditTask({
                                        ...isEditTask,
                                        action: ""
                                      });
                                      setAddCardFlag(false);
                                    }}
                                    collaboratorList={props.collaboratorList}
                                    toggle={() => {
                                      setIsEditTask({
                                        status: false,
                                        data: ""
                                      });

                                      setAddCardFlag(false);
                                    }}
                                    taskData={isEditTask.data}
                                    // onKeyDown={handleKeyDown}
                                    addTaskHandler={updateTaskHandler}
                                    taskProject={taskProject}
                                    setTaskProject={data =>
                                      setTaskProject(data)
                                    }
                                    setIsGoogleCalenderData={
                                      setIsGoogleCalenderData
                                    }
                                    setSyncGoogle={setSyncGoogle}
                                    syncGoogle={syncGoogle}
                                  />
                                </div>
                              ) : (
                                <CardTask
                                  // isQuickActionTask={isQuickActionTask}
                                  // setIsQuickActionTask={setIsQuickActionTask}
                                  // doUpdateTaskPriority={doUpdateTaskPriority}
                                  // doUpdateTaskAssign={doUpdateTaskAssign}
                                  // doUpdateTaskScheduleDate={
                                  //   doUpdateTaskScheduleDate
                                  // }
                                  updateLabelToParent={
                                    props.updateLabelToParent
                                  }
                                  {...props}
                                  PRIMARY_COLOR={props.PRIMARY_COLOR}
                                  index={index}
                                  key={task.id}
                                  task={task}
                                  tasks={tasks}
                                  openTaskDialogHandler={openTaskDialogHandler}
                                  deleteTaskHandler={(
                                    task,
                                    projectId,
                                    title
                                  ) => {
                                    deleteTaskHandlerCard(
                                      task.id,
                                      projectId,
                                      task.calendar_id
                                    );
                                  }}
                                  doArchiveTask={id => doArchiveTask(id)}
                                  projectId={props.projectId}
                                  sectionId={props.sectionId}
                                  sectionTitle={props.sectionTitle}
                                  taskId={props.projectId}
                                  refreshTodoList={newTodo =>
                                    refreshTodoList(newTodo)
                                  }
                                  collaboratorList={props.collaboratorList}
                                  showNotifications={message =>
                                    showNotifications(message)
                                  }
                                  toggleEditTask={toggleEditTask}
                                  setIsEditTask={setIsEditTask}
                                  setIsGoogleCalenderData={
                                    setIsGoogleCalenderData
                                  }
                                  setAddCardFlag={setAddCardFlag}
                                  setIsSectionScroll={setIsSectionScroll}
                                  isSectionScroll={isSectionScroll}
                                  isGoogleCalenderData={isGoogleCalenderData}
                                  isEditTask={isEditTask}
                                />
                              )}
                            </>
                          );
                        }
                      })
                  ) : (
                    <div style={{ minHeight: "25px" }}></div>
                  )}
                  {provided.placeholder}
                </div>
                {!props.isLoader && (
                  <div style={{ paddingLeft: "8px" }}>
                    {!addCardFlag ? (
                      <div
                        className="add-task-block fs-13 demi-bold-text "
                        style={appStyles}
                        onClick={() => {
                          setAddCardFlag(true);
                          scrollToBottom();
                        }}
                      >
                        <div
                          className="add-task-card d-flex justify-content-center align-items-center w-100 ptb-3 "
                          // style={appStyles}
                        >
                          <img
                            alt=""
                            src={addnew}
                            style={{
                              height: "10px",
                              width: "10px"
                            }}
                          ></img>
                          {/* <i className="fas fa-plus"></i>  */}
                          <span
                            style={{
                              color: "#808080",
                              fontsize: 13,
                              marginLeft: 5
                            }}
                          ></span>
                          {<IntlMessages id={"today.newtask"} />}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #c6c2c2",
                          marginBottom: 10
                        }}
                        className="add-card-input-block bg-white pa-8 "
                        id="newTaskasdwe"
                      >
                        <NewTask
                          hasClearEnabledOnAddTask
                          setSyncGoogle={setSyncGoogle}
                          syncGoogle={syncGoogle}
                          updateLabelToParent={props.updateLabelToParent}
                          type={"grid"}
                          action={isEditTask?.action || ""}
                          toggleAction={() => {
                            setIsEditTask({
                              ...isEditTask,
                              action: ""
                            });
                            setAddCardFlag(false);
                          }}
                          collaboratorList={props.collaboratorList}
                          toggle={() => {
                            setIsEditTask({
                              status: false,
                              data: ""
                            });
                            setAddCardFlag(false);
                          }}
                          // taskData={isEditTask.data}
                          // onKeyDown={handleKeyDown}
                          addTaskHandler={addTaskHandler}
                          taskProject={taskProject}
                          setTaskProject={data => setTaskProject(data)}
                          setIsBoardCardscroll={setIsBoardCardscroll}
                          setIsGoogleCalenderData={setIsGoogleCalenderData}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    businessId: state.auth.user?.businessId,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, null)(BoardCard);

// export default BoardCard;
