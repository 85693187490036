/** @format */

import React from "react";
import CustomToast from "components/notifications/CustomToast";
import { ToastProvider, useToasts } from "react-toast-notifications";

export default function CustomNotification(props) {
  return (
    <>
      <CustomToast
        // heading="Notify!"
        width={400}
        show={props.show}
        redirectLink={props.redirectLink ? props.redirectLink : false}
        redirectMessage={props.redirectMessage ? props.redirectMessage : false}
        transition={false}
        position="bottom-middle"
        className="c-secondary"
        message={props.message}
        onCloseCLick={() => props.closeNotification()}
      />
    </>
  );
}
