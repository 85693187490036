import React from "react";
import Shimmer from "components/shimmer/workspcaeShimmerHeader";

import { profileBackground } from "helper/methods/getUserAvatarColor";
import ShimmerAvatar from "components/shimmer/workspaceAvatar";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
const MyAssignmentsHeader = props => {
  const { tabs, activeTab, toggletab, isLoader, activeUserName } = props;

  const FindFirstLetter = () => {
    var str = activeUserName?.name;
    var matches = str.match(/\b(\w)/g);
    var acronym = matches.join("");
    var TwoLetter = acronym.substring(0, 2);
    return TwoLetter.toUpperCase();
  };

  return (
    <div>
      <div className="workspace-details-header">
        <div>
          <div className="workspace-details-wrapper" style={{ paddingTop: 15 }}>
            {isLoader ? (
              <div className="flex-x">
                <ShimmerAvatar />
                <Shimmer />
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <div>
                    {activeUserName?.userMeta?.thumbnail ? (
                      <img
                        alt=""
                        src={
                          activeUserName.userMeta.thumbnail
                            ? activeUserName.userMeta.thumbnail
                            : ""
                        }
                        style={profileBackground("", 64, 64, 30)}
                      ></img>
                    ) : (
                      <div
                        style={profileBackground(
                          activeUserName?.name,
                          64,
                          64,
                          30
                        )}
                      >
                        {FindFirstLetter()}
                        {/* BB */}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: 32,
                      fontWeight: "bold",
                      textAlign: "center"
                    }}
                  >
                    {" "}
                    <IntlMessages id={"globalmyassignment.decription1"} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="workspace-action-nav"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div style={{ width: "650px" }}>
          <ul className="ul-no-margin">
            <li
              className={`workspace-action-nav-items ${
                activeTab == tabs[0] ? "tab-active " : ""
              }`}
              onClick={() => toggletab(tabs[0])}
            >
              <span style={{ fontWeight: 500 }}>
                <div>
                  <IntlMessages id={"globalmyassignment.myassignmentstab"} />
                </div>
              </span>
            </li>
            <li
              className={`workspace-action-nav-items ${
                activeTab == tabs[1] ? "tab-active " : ""
              }`}
              onClick={() => toggletab(tabs[1])}
            >
              <span style={{ fontWeight: 500 }}>
                <div>
                  <IntlMessages
                    id={"globalmyassignment.stuffI’veassignedtab"}
                  />
                </div>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, null)(MyAssignmentsHeader);
