import { store } from "../../redux/store";
import CryptoJS from "crypto-js";

const encrypt_decrypt_key = process.env.REACT_APP_ENCRYPT_DECRYPT_SECRET_KEY;

export default function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        store.dispatch({
          type: "LOGOUT"
        });
        localStorage.removeItem("persist:root");
        localStorage.removeItem("recent_workspace");
        window.location.replace("/login");
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        //Logout from here

        const error = (data && data.errors.message) || response.statusText;
        return Promise.reject(error);
      }
    }
    if (data?.data?.encrypt) {
      const bytes = CryptoJS.AES.decrypt(
        data?.data?.encrypt,
        encrypt_decrypt_key,
        {
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
          keySize: 256 / 32
        }
      );
      const response = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      if (data?.totalPages) {
        return {
          data: response,
          totalPages: data?.totalPages,
          currentPage: data?.currentPage,
          totalRecords: data?.totalRecords,
          currentPageRecords: data?.currentPageRecords,
          message: data?.message
        };
      } else if (data?.extra) {
        return { data: response, extra: data?.extra, message: data?.message };
      } else {
        return { data: response, message: data?.message };
      }
    } else {
      return data;
    }
  });
}

export function encryptPayload(payload) {
  const encryptedPayload = CryptoJS.AES.encrypt(
    JSON.stringify(payload),
    encrypt_decrypt_key,
    {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
      keySize: 256 / 32
    }
  ).toString();
  return encryptedPayload;
}
