import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import CustomNotification from "util/CustomNotification";
import ImageUploading from "react-images-uploading";
import CustomAlert from "util/CustomAlert";
import { businessImage } from "helper/constant";
import BusinessWrapper from "./businessWrapper.style";
import { updateBusiness } from "helper/services/businessServices";
import RoyTooltip from "components/common/RoyTooltip";
import participantEmailEnhance from "./pages/authentication/enhancer/participantEmailEnhance";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import { bin } from "helper/constant";
import { edit } from "helper/constant";
import businessActions from "redux/business/actions";
import {
  getCollaboratorsList,
  inviteCollaborators,
  removeCollaborators
} from "helper/services/businessCollaboratorsServices";
import BusinessListShimmer from "components/shimmer/BusinessListShimmer";
import ChipInput from "material-ui-chip-input";
import Loader from "components/loader/Loader";
import AddBusiness from "components/business/AddBusiness";
import AuthActions from "redux/auth/actions";
import ToastMessage from "components/notifications/ToastMessage";
import { useToasts } from "react-toast-notifications";
import IntlMessages from "util/intlMessages";
const { update_business, update_token } = AuthActions;
const { default_business } = businessActions;
function Business(props) {
  const { addToast } = useToasts();
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [enterCount, setEnterCount] = useState(0);
  const [chipCount, setChipCount] = useState(0);
  const [images, setImages] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [business, setBusiness] = useState([]);
  const [isAddParticipant, setIsAddParticipant] = useState(false);
  const [isEditBusiness, setIsEditBusiness] = useState(false);
  const [isParticipantsLoader, setIsParticipantsLoader] = useState(false);
  const [addParticipant, setAddParticipant] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [collaboratorArr, setCollaboratorArr] = useState([]);
  const [isUserInput, setIsUserInput] = useState(false);
  const [isAddParticipantLoader, setIsAddParticipantLoader] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isDefaultBusiness, setIsDefaultBusiness] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [isEditBusinessName, setIsEditBusinessName] = useState(false);
  const [isEditBusinessLoader, setIsEditBusinessLoader] = useState(false);
  const [isDeleteProfilePic, setIsDeleteProfilePic] = useState(false);

  const [validateEmailAndColl, setValidateEmailAndColl] = useState({
    status: false,
    message: ""
  });

  const [isBusinessNameError, seisBusinessNameError] = useState({
    status: false,
    message: ""
  });

  const [isRemoveLoader, setIsRemoveLoader] = useState({
    id: "",
    status: false
  });
  const [isDeleteAlert, setDeleteAlert] = useState({
    id: "",
    status: false
  });
  const inputref = useRef(addParticipant);

  const { id } = props.match.params;
  const { topbarTheme } = props;
  useEffect(() => {
    // inputref.current && inputref.current.focus();
    if (inputref.current) inputref.current = addParticipant;
  }, [inputref]);

  useEffect(() => {
    if (id) {
      fetchParticipants(id);
      console.log(props.history);
      if (props.history.location.state) {
        props.history.location.state.business?.businessMeta
          ? setSelectedImage({
              data_url: props.history.location.state.business?.businessMeta?.url
            })
          : setSelectedImage(null);
        setBusiness(props.history.location.state.business);
        setBusinessName(props.history.location.state.business.title);

        setIsDefaultBusiness(props.history.location.state.isDefault);
      }
    }
    return () => {
      setIsOwner(false);
      setCollaboratorArr([]);
      setAddParticipant([]);
      setBusiness([]);
    };
  }, [id]);
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const onImageChange = (imageList, addUpdateIndex) => {
    // data for submit

    if (imageList) {
      setImages(imageList);
      setSelectedImage(imageList[0]);

      setIsDeleteProfilePic(false);
      handleupdateBusinessImage(
        imageList.length > 0 && imageList ? imageList[0] : "",
        false
      );
    } else {
      setIsDeleteProfilePic(true);
      handleupdateBusinessImage([], true);
    }
  };
  const handleUpdateBusinessName = async () => {
    if (businessName && business && businessName !== business.title) {
      try {
        setIsEditBusinessLoader(true);
        const Response = await updateBusiness(
          {
            title: businessName,
            isDeleteBusinessPic: false
          },
          business.id
        );
        if (Response) {
          if (Response.error) {
            addToast(ToastMessage(Response.error.message), {
              appearance: "error",
              autoDismiss: true
            });
            // showNotifications();
            setIsEditBusinessLoader(false);
          }
          if (Response.data) {
            if (isDefaultBusiness) {
              props.update_token(Response.data.token);
              props.update_business({
                id: Response.data.business.id,
                businessName: Response.data.business.title,
                businessMeta: Response.data.business.businessMeta
              });
            }
            setBusiness(Response.data.business);
            setIsEditBusinessLoader(false);
            showNotifications("Workspace name updated successfully", "success");
          }
        }
      } catch (err) {
        showNotifications();
      }
    }
  };
  const handleupdateBusinessImage = async (businessImage, isDeleteStatus) => {
    try {
      let userBusinessImage;
      if (businessImage && businessImage.file) {
        userBusinessImage = { selected: businessImage.file };
      } else {
        userBusinessImage = { selected: "" };
      }
      showNotifications("Updating workspace profile", "success");
      const Response = await updateBusiness(
        {
          businessImage: userBusinessImage.selected,
          isDeleteBusinessPic: isDeleteStatus
        },
        business.id
      );
      if (Response) {
        if (Response.error) {
          showNotifications();
          setIsDeleteProfilePic(false);
        }
        if (Response.data) {
          setBusiness(Response.data.business);
          if (isDefaultBusiness) {
            props.update_token(Response.data.token);
            props.update_business({
              id: Response.data.business.id,
              businessName: Response.data.business.title,
              businessMeta: Response.data.business.businessMeta
            });
          }
          setIsDeleteProfilePic(false);
          showNotifications(
            isDeleteStatus
              ? "Workspace image deleted successfully"
              : "Workspace image updated successfully",
            "success"
          );
        }
      }
    } catch (err) {
      showNotifications();
      setIsDeleteProfilePic(false);
    }
  };

  const isBusinessOwner = collaboratorArr => {
    if (collaboratorArr) {
      collaboratorArr.map(data => {
        if (
          data &&
          data.role.toLowerCase() == "owner" &&
          data.user.id == props.auth.user.id
        ) {
          setIsOwner(true);
        }
      });
    }
  };
  const handleRemoveParticipant = async participantId => {
    if (participantId) {
      try {
        setIsRemoveLoader({
          id: participantId,
          status: true
        });
        const Response = await removeCollaborators({
          collaboration_id: participantId
        });
        if (Response) {
          if (Response.error) {
            showNotifications();
            setIsRemoveLoader({
              id: "",
              status: false
            });
          }
          if (Response.message) {
            setIsRemoveLoader({
              id: "",
              status: false
            });
            showNotifications(Response.message);
            var checkArr = [...collaboratorArr];

            checkArr.map((data, i) => {
              if (data.id == participantId) {
                checkArr.splice(i, 1);
              }
            });

            setCollaboratorArr([...checkArr]);
          }
        }
      } catch (e) {
        setIsRemoveLoader({
          id: "",
          status: false
        });
      }
    }
  };
  const fetchParticipants = async e => {
    setIsParticipantsLoader(true);
    const result = await getCollaboratorsList(id);
    if (result) {
      if (result.error) {
        showNotifications(result.error.message, "error");
      }
      if (result.data) {
        setCollaboratorArr(result.data);
        isBusinessOwner(result.data);
      }
      setIsParticipantsLoader(false);
    }
  };
  const handleAddParticipant = async e => {
    if (addParticipant && addParticipant.length > 0) {
      setIsAddParticipantLoader(true);
      const response = await inviteCollaborators({
        business_id: id,
        recipient_email: addParticipant
      });
      if (response) {
        if (response.error) {
          showNotifications();
          setIsAddParticipantLoader(false);
        }
        if (response.message) {
          var newData = [...collaboratorArr];

          addParticipant.map(data =>
            newData.push({
              requestStatus: "SENT",
              role: "COLLABORATOR",
              requestedEmail: data
            })
          );

          if (newData) {
            setCollaboratorArr([...newData]);
          }
          setAddParticipant([]);
          setIsAddParticipantLoader(false);
          setIsAddParticipant(false);
          showNotifications(response.message, "success");
          setEnterCount(0);
          setChipCount(0);
        }
      }
    }
  };

  const ErrorEmailValidation = () => {
    return (
      <div>
        <span
          className={props.class ? props.class : "error-msg"}
          style={{ fontWeight: 300 }}
          // onClick= {"className.hide()"}
        >
          Please enter valid email example@email.com
        </span>
        {/* <button class="close" onclick="$('#alertBox').hide();; return false;">×</button> */}
      </div>
    );
  };

  const handleChangeChip = chip => {
    if (!isEmailAlreadyParticipant(chip) && !isEmailExist(chip)) {
      if (addParticipant) {
        setIsSendButtonDisabled(false);
      } else {
        setIsSendButtonDisabled(true);
      }
      setIsUserInput(true);
      setChipCount(addParticipant.length + 1);
      setAddParticipant([...addParticipant, chip]);
    } else if (isEmailAlreadyParticipant(chip)) {
      setEnterCount(0);
      // showNotifications("Email is already collaborator ", "error");

      setValidateEmailAndColl({
        status: true,
        message: <IntlMessages id={"workspace.email_collaborator"} />
      });
      console.log(validateEmailAndColl.message);
    } else if (isEmailExist(chip)) {
      setEnterCount(0);
      // setDynamicValue(true);
      // console.log(dynamicValue);
      // showNotifications("Email is already present ", "error");

      setValidateEmailAndColl({
        status: true,
        message: (
          <IntlMessages id={"project.project_email_is_already_present"} />
        )
      });
      console.log(validateEmailAndColl.message);
    } else {
      setEnterCount(0);
      // showNotifications("Email is already collaborator", "error");

      setValidateEmailAndColl({
        status: true,
        message: "Please enter valid email example@email.com"
      });
      console.log(validateEmailAndColl.message);
    }
  };
  const isEmailExist = chip => {
    var status = false;
    addParticipant.map(data => {
      if (data === chip) {
        status = true;
      }
    });
    return status;
  };
  const handleChipKeyUp = e => {
    // if (e.keyCode == 13) {
    //   console.log("ha");
    // }
  };
  const handleEmailValidation = email => {
    setIsUserInput(true);

    let givenEmail = handleCharactersValidation(email).toLowerCase();
    if (isEmailValidate(givenEmail)) {
      setIsEmailValid(true);
      handleChangeChip(givenEmail);
    } else {
      setIsEmailValid(false);
    }
  };
  // remove spaces and comma which are entered by user tio generate chips
  const handleCharactersValidation = email => {
    let givenEmail = email;
    if (removeComma(givenEmail)) {
      givenEmail = removeComma(email);
    }
    givenEmail = removeSpace(givenEmail);
    return givenEmail;
  };
  const removeSpace = email => {
    return email.replace(/\s/g, "");
  };
  const removeComma = email => {
    if (email.includes(",")) {
      const checkIndex = email.indexOf(",");
      if (checkIndex > -1) {
        let givenEmail = email;
        givenEmail = email.slice(checkIndex + 1, email.length);
        return givenEmail;
      }
      return 0;
    }
  };
  const profileImage = {
    borderRadius: "50%",
    width: 41,
    height: 41,
    objectFit: "contain",
    textAlign: "center",
    boxShadow: "0 0.125rem 0.1875rem rgb(0 0 0 / 3%)"
  };

  const InCap = {
    lineHeight: 2.2,
    fontSize: 20,
    color: "white",
    textAlign: "center"
  };
  const isEmailValidate = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const handleDeleteChip = (email, index) => {
    var emailArr = [...addParticipant];
    emailArr.splice(index, 1);
    setChipCount(emailArr?.length);
    setAddParticipant(emailArr);
  };
  const ParticipantType = (status, role) => {
    if (role.toLowerCase() == "owner") {
      return (
        <span
          style={{
            borderRadius: "15",
            color: "white",
            padding: "2px 10px",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: props.themeChanger.topbarTheme.buttonColor
              ? props.themeChanger.topbarTheme.buttonColor
              : "black"
          }}
          className="c-btn-round ma-5 fs-10"
        >
          {"Owner"}
        </span>
      );
    }
    if (
      role.toLowerCase() == "collaborator" &&
      status.toLowerCase() == "accepted"
    ) {
      // return (
      //   <span
      //     style={{
      //       borderRadius: "15",
      //       color: "white",
      //       padding: 6,
      // textAlign: "center",
      // justifyContent: "center"
      //       backgroundColor: "rgb(167, 167, 167)",
      //     }}
      //     className="c-btn-round ma-5 fs-10"
      //   >
      //     {role.toLowerCase()}
      //   </span>
      // );
    }
    if (
      role.toLowerCase() == "collaborator" &&
      status.toLowerCase() == "sent"
    ) {
      return (
        <span
          style={{
            borderRadius: "15",
            color: "#313131",
            padding: "2px 10px",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "rgb(222 222 222)"
          }}
          className="c-btn-round ma-5 fs-10"
        >
          {"Request "} {status.toLowerCase()}
        </span>
      );
    }
    if (
      role.toLowerCase() == "collaborator" &&
      status.toLowerCase() == "rejected"
    ) {
      return (
        <span
          style={{
            borderRadius: "15",
            color: "white",
            padding: "2px 10px",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "red"
          }}
          className="c-btn-round ma-5 fs-10"
        >
          {status.toLowerCase()}
        </span>
      );
    }
  };

  const isEmailAlreadyParticipant = email => {
    const participant = collaboratorArr;
    var isExist = false;
    participant.map(data => {
      if (data.user && email == data.user.email) isExist = true;
      if (!data.user && data.requestedEmail == email) isExist = true;
    });

    return isExist;
  };
  const handleBusinessNameValidatiton = e => {
    setBusinessName(e.target.value);
    const validate = e.target.value.match(/^\S+\w{0,50}\S{0,}/g);

    if (
      e.target.value.length > 1 &&
      e.target.value.length < 51 &&
      validate !== null &&
      validate
    ) {
      seisBusinessNameError({ status: false, message: null });
      return true;
    }
    if (e.target.value.length > 50) {
      seisBusinessNameError({
        status: true,
        message: "Maximum 50 characters are allowed"
      });
      return false;
    }
    if (validate == null) {
      seisBusinessNameError({ status: true, message: "" });
    }
    if (e.target.value.length <= 0)
      seisBusinessNameError({
        status: true,
        message: "Workspace name is required"
      });
    return false;
  };
  return (
    <div>
      <div className="row">
        <div className="col-lg-2 col-md-2 col-sm-0 col-xs-0" />
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <div className="plr-15">
            <div className="mtb-30 theme-color">
              {" "}
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ptb-15 roe-card-style mb-30">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="text-center profile-image">
                      {isOwner ? (
                        <ImageUploading
                          value={images}
                          onChange={onImageChange}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper text-center iconDemo-image">
                              <img
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                  border: "1px solid #c5c5c5"
                                }}
                                src={
                                  selectedImage && selectedImage.data_url
                                    ? selectedImage.data_url
                                    : businessImage
                                }
                                onClick={onImageUpload}
                                id="userBusinessImage"
                                alt="icon"
                                height="100px"
                                width="100px"
                              />

                              {imageList.length > 0 &&
                                imageList.map((image, index) => (
                                  <div key={index} className="image-item">
                                    <div className="image-item__btn-wrapper pt-2">
                                      <span
                                        className="hover-underline underlinetextStyle cursor-pointer "
                                        style={{
                                          backgroundColor: "white",
                                          padding: "3px 5px",
                                          borderRadius: "50%",
                                          position: "relative",
                                          top: "-20px",
                                          left: "-5px",
                                          border: "1px solid #c5c5c5"
                                        }}
                                        onClick={() => {
                                          setIsDeleteProfilePic(true);
                                          onImageRemove(index);
                                          handleupdateBusinessImage([], true);
                                        }}
                                      >
                                        <img
                                          alt=""
                                          src={bin}
                                          style={{
                                            height: "13px",
                                            width: "13px",
                                            borderRadius: "0px"
                                          }}
                                        />
                                      </span>
                                      <span
                                        className="hover-underline underlinetextStyle cursor-pointer "
                                        onClick={() => onImageUpdate(index)}
                                        style={{
                                          backgroundColor: "white",
                                          padding: "3px 5px",
                                          borderRadius: "50%",
                                          position: "relative",
                                          top: "-20px",
                                          left: "5px",
                                          border: "1px solid #c5c5c5"
                                        }}
                                      >
                                        <img
                                          alt=""
                                          src={edit}
                                          style={{
                                            height: "13px",
                                            width: "13px",
                                            borderRadius: "0px"
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                ))}

                              {imageList.length == 0 && (
                                <div key={1} className="image-item">
                                  <div className="image-item__btn-wrapper">
                                    {imageList.length === 0 && selectedImage && (
                                      <div>
                                        <span
                                          className="hover-underline underlinetextStyle cursor-pointer "
                                          style={{
                                            backgroundColor: "white",
                                            padding: "3px 5px",
                                            borderRadius: "50%",
                                            position: "relative",
                                            top: "-20px",
                                            left: "-5px",
                                            border: "1px solid #c5c5c5"
                                          }}
                                          onClick={() => {
                                            setSelectedImage(null);
                                            setIsDeleteProfilePic(true);
                                            handleupdateBusinessImage([], true);
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={bin}
                                            style={{
                                              height: "13px",
                                              width: "13px",
                                              borderRadius: "0px"
                                            }}
                                          />
                                        </span>
                                        <span
                                          className="hover-underline underlinetextStyle cursor-pointer "
                                          onClick={onImageUpload}
                                          style={{
                                            backgroundColor: "white",
                                            padding: "3px 5px",
                                            borderRadius: "50%",
                                            position: "relative",
                                            top: "-20px",
                                            left: "5px",
                                            border: "1px solid #c5c5c5"
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={edit}
                                            style={{
                                              height: "13px",
                                              width: "13px",
                                              borderRadius: "0px"
                                            }}
                                          />
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {imageList.length === 0 && !selectedImage && (
                                <>
                                  <span
                                    className="hover-underline underlinetextStyle cursor-pointer "
                                    onClick={onImageUpload}
                                    style={{
                                      backgroundColor: "white",
                                      padding: "3px 5px",
                                      borderRadius: "50%",
                                      position: "relative",
                                      top: "-30px",
                                      left: "40px",
                                      border: "1px solid #c5c5c5"
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={edit}
                                      style={{
                                        height: "13px",
                                        width: "13px",
                                        borderRadius: "0px"
                                      }}
                                    />
                                  </span>
                                </>
                              )}
                            </div>
                          )}
                        </ImageUploading>
                      ) : (
                        <div>
                          <img
                            style={{
                              objectFit: "cover"
                            }}
                            src={
                              selectedImage && selectedImage.data_url
                                ? selectedImage.data_url
                                : businessImage
                            }
                            id="profilePicture"
                            alt="icon"
                            height="100px"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div
                      className=" text-center header bold-text fs-14 ptb-10"
                      style={{
                        textAlign: "center",
                        justifyContent: "center"
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                          lineHeight: "normal"
                        }}
                      >
                        {isEditBusinessName ? (
                          <>
                            <div
                              className="searchStyle pos-relative"
                              style={{ marginBottom: 5 }}
                            >
                              <input
                                type="text"
                                defaultValue={businessName || business.title}
                                // className="form-control react-form-input"
                                placeholder="Workspace name"
                                // ref={titleRef}
                                autoFocus
                                className="business-name-input"
                                name="Workspacename"
                                style={{
                                  borderRadius: "5px",
                                  border: "1px solid #e2e2e2",
                                  outline: "none",
                                  height: 30,
                                  "&:focus": {
                                    outline: "none"
                                  }
                                }}
                                onKeyDown={e => {
                                  if (e.keyCode === 13) {
                                    handleUpdateBusinessName();
                                    setIsEditBusinessName(false);
                                  }
                                }}
                                onChange={handleBusinessNameValidatiton}
                              />

                              {/* <i
                                // onClick={() => setIsSearch(false)}
                                // onClick={() => {
                                //   setIsEditBusinessName(false);
                                //   setBusinessName(business.title);
                                // }}
                                className="text-muted fas fa-times close-search text-muted"
                                style={{ top: 6 }}
                              ></i> */}
                            </div>
                            {isBusinessNameError.status && (
                              <span style={{ color: "red" }} className="fs-14">
                                {isBusinessNameError.message}
                              </span>
                            )}
                            <div style={{ marginTop: 15, marginBottom: 15 }}>
                              <button
                                className="c-btn mr-10"
                                style={{
                                  backgroundColor:
                                    businessName &&
                                    businessName.length != 0 &&
                                    !isBusinessNameError.status &&
                                    business.title !== businessName
                                      ? props.topbarTheme.buttonColor
                                      : "grey",
                                  color: "#fff",
                                  boxShadow: "none"
                                }}
                                disabled={
                                  businessName.length == 0 &&
                                  !isBusinessNameError.status
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  handleUpdateBusinessName();
                                  setIsEditBusinessName(false);
                                }}
                              >
                                <IntlMessages id={"profile.savebutton"} />
                              </button>
                              {/* add cancel button in  */}
                              <button
                                className="c-btn c-default"
                                onClick={() => {
                                  setIsEditBusinessName(false);
                                  setBusinessName(business.title);
                                }}
                                style={{
                                  boxShadow: "none"
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            {business
                              ? business.title
                              : businessName
                              ? businessName
                              : " "}
                            {isOwner && (
                              <>
                                {isEditBusinessLoader ? (
                                  <div style={{ display: "inline-block" }}>
                                    <Loader height="16px" width="16px" />
                                  </div>
                                ) : (
                                  <i
                                    onClick={() => setIsEditBusinessName(true)}
                                    className="fa fa-pencil-alt fs-14 text-muted"
                                    aria-hidden="true"
                                    style={{
                                      marginLeft: "8px",
                                      cursor: "pointer"
                                    }}
                                  ></i>
                                  // <span style={{ fontSize: "11px", textDecoration: "underline", color: "#6c757d", cursor: "pointer" }} onClick={() => setIsEditBusinessName(true)}>Edit</span>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {isParticipantsLoader && <BusinessListShimmer />}
                {!isParticipantsLoader && (
                  <BusinessWrapper className="fill-height">
                    <div
                      className="card-body"
                      style={{ paddingBottom: 0, marginBottom: 0 }}
                    >
                      <div className="flex-x align-center">
                        <h3 className="mb-0 fs-14 header">
                          Workspace Collaborators
                        </h3>
                      </div>
                      {collaboratorArr &&
                        collaboratorArr.map((data, i) => {
                          if (data && data?.role?.toLowerCase() == "owner") {
                            return (
                              <div
                                className="flex-x ptb-10 border-bottom"
                                key={i}
                              >
                                <div>
                                  {data.user?.userMeta ? (
                                    <img
                                      className="img-sm"
                                      src={data.user?.userMeta.thumbnail}
                                    />
                                  ) : (
                                    <div
                                      style={profileBackground(
                                        data.user
                                          ? data.user.name
                                          : data.requestedEmail
                                          ? data.requestedEmail
                                          : ""
                                      )}
                                    >
                                      {data.user
                                        ? data.user.name.charAt(0).toUpperCase()
                                        : data.requestedEmail
                                            .charAt(0)
                                            .toUpperCase()
                                        ? data.requestedEmail
                                            .charAt(0)
                                            .toUpperCase()
                                        : ""}
                                    </div>
                                  )}
                                </div>
                                <div className="mlr-10 flex-1">
                                  <p
                                    className=" fs-14 demi-bold-text "
                                    style={{ marginBottom: "-3px" }}
                                  >
                                    {data?.user?.name && data?.user?.name}{" "}
                                    <small
                                      className="text-muted bold-text text-muted"
                                      style={{}}
                                    >
                                      {" "}
                                      {/* {`(${data.role.toLowerCase()})`} */}
                                      {ParticipantType(
                                        data.requestStatus,
                                        data.role
                                      )}
                                    </small>
                                  </p>
                                  <small
                                    className="text-muted fs-12 "
                                    style={{}}
                                  >
                                    {data?.user?.email
                                      ? data?.user?.email
                                      : " "}
                                  </small>
                                </div>
                              </div>
                            );
                          }
                        })}
                      {collaboratorArr &&
                        collaboratorArr.map((data, i) => {
                          if (
                            data.role.toLowerCase() !== "owner" &&
                            data.requestStatus.toLowerCase() !== "rejected"
                          ) {
                            if (
                              data &&
                              data.requestStatus == "SENT" &&
                              data.requestedEmail
                            ) {
                              return (
                                <div className="flex-x ptb-10 border-bottom mt-9">
                                  <div>
                                    <div
                                      style={profileBackground(
                                        data.user
                                          ? data.user.name
                                          : data.requestedEmail
                                          ? data.requestedEmail
                                          : ""
                                      )}
                                    >
                                      {data.user
                                        ? data.user.name.charAt(0).toUpperCase()
                                        : data.requestedEmail
                                        ? data.requestedEmail
                                            .charAt(0)
                                            .toUpperCase()
                                        : ""}{" "}
                                    </div>
                                  </div>
                                  <div className="mlr-10 flex-1">
                                    <p
                                      className=" fs-14 demi-bold-text "
                                      style={{ marginBottom: "-3px" }}
                                    >
                                      <small className="fs-14 demi-bold-text">
                                        {data.requestedEmail
                                          ? data.requestedEmail.substr(
                                              0,
                                              data.requestedEmail.indexOf("@")
                                            )
                                          : ""}{" "}
                                        {/* {`(${data.role.toLowerCase()})`} */}
                                        {ParticipantType(
                                          data.requestStatus,
                                          data.role
                                        )}
                                      </small>
                                    </p>
                                    <small className="text-muted fs-12">
                                      {data.requestedEmail
                                        ? data.requestedEmail
                                        : ""}
                                    </small>
                                  </div>
                                  {isOwner &&
                                    data.requestStatus.toLowerCase() !==
                                      "sent" && (
                                      <>
                                        {isRemoveLoader.participantId ==
                                          data.id && isRemoveLoader.status ? (
                                          <Loader />
                                        ) : (
                                          <RoyTooltip
                                            id="remove"
                                            title="Remove collaborator"
                                          >
                                            <small
                                              id="remove"
                                              className="  pt-2 plr-5 cursor-pointer hover-underline underlinetextStyle"
                                              style={{
                                                color: `${props.themeChanger.topbarTheme.buttonColor}`
                                              }}
                                              onClick={
                                                () =>
                                                  setDeleteAlert({
                                                    id: data.id,
                                                    status: true
                                                  })
                                                // handleRemoveParticipant(data.id)
                                              }
                                            >
                                              <i className="fas fa-trash test-muted" />
                                            </small>
                                          </RoyTooltip>
                                        )}
                                      </>
                                    )}
                                </div>
                              );
                            }

                            if (
                              data &&
                              data.requestStatus == "SENT" &&
                              data.user
                            ) {
                              return (
                                <div className="flex-x ptb-10 border-bottom mt-9">
                                  <div>
                                    <div
                                      style={profileBackground(
                                        data.user
                                          ? data.user.name
                                          : data.requestedEmail
                                          ? data.requestedEmail
                                          : ""
                                      )}
                                    >
                                      {data.user
                                        ? data.user.name.charAt(0).toUpperCase()
                                        : data.requestedEmail
                                        ? data.requestedEmail
                                            .charAt(0)
                                            .toUpperCase()
                                        : ""}{" "}
                                    </div>
                                  </div>
                                  <div className="mlr-10 flex-1">
                                    <p
                                      className=" fs-14 demi-bold-text "
                                      style={{ marginBottom: "-3px" }}
                                    >
                                      {data?.user?.name && data?.user?.name}{" "}
                                      <small
                                        className="text-muted bold-text text-muted"
                                        style={{}}
                                      >
                                        {" "}
                                        {/* {`(${data.role.toLowerCase()})`} */}
                                        {ParticipantType(
                                          data.requestStatus,
                                          data.role
                                        )}
                                      </small>
                                    </p>
                                    <small
                                      className="text-muted fs-12 "
                                      style={{}}
                                    >
                                      {data?.user?.email
                                        ? data?.user?.email
                                        : " "}
                                    </small>
                                  </div>
                                  {isOwner &&
                                    data.requestStatus.toLowerCase() !==
                                      "sent" && (
                                      <>
                                        {isRemoveLoader.participantId ==
                                          data.id && isRemoveLoader.status ? (
                                          <Loader />
                                        ) : (
                                          <RoyTooltip
                                            id="remove"
                                            title="Remove collaborator"
                                          >
                                            <small
                                              id="remove"
                                              className="  pt-2 plr-5 cursor-pointer hover-underline underlinetextStyle"
                                              style={{
                                                color: `${props.themeChanger.topbarTheme.buttonColor}`
                                              }}
                                              onClick={
                                                () =>
                                                  setDeleteAlert({
                                                    id: data.id,
                                                    status: true
                                                  })
                                                // handleRemoveParticipant(data.id)
                                              }
                                            >
                                              <i className="fas fa-trash test-muted" />
                                            </small>
                                          </RoyTooltip>
                                        )}
                                      </>
                                    )}
                                </div>
                              );
                            }
                            if (data && data.requestStatus == "ACCEPTED") {
                              return (
                                <div className="flex-x ptb-10 border-bottom mt-9">
                                  <div>
                                    {data.user?.userMeta ? (
                                      <img
                                        alt=""
                                        className="img-sm"
                                        src={data.user?.userMeta.thumbnail}
                                      />
                                    ) : (
                                      <>
                                        <div
                                          style={profileBackground(
                                            data.user
                                              ? data.user.name
                                              : data.requestedEmail
                                              ? data.requestedEmail
                                              : ""
                                          )}
                                        >
                                          {data.user
                                            ? data.user.name
                                                .charAt(0)
                                                .toUpperCase()
                                            : data.requestedEmail
                                            ? data.requestedEmail
                                                .charAt(0)
                                                .toUpperCase()
                                            : ""}{" "}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="mlr-10 flex-1">
                                    <p
                                      className=" fs-14 demi-bold-text  "
                                      style={{ marginBottom: "-3px" }}
                                    >
                                      {data?.user?.name && data?.user?.name}{" "}
                                      <small
                                        className="text-muted bold-text text-muted"
                                        style={{}}
                                      >
                                        {" "}
                                        {/* {`(${data.role.toLowerCase()})`} */}
                                        {ParticipantType(
                                          data.requestStatus,
                                          data.role
                                        )}
                                      </small>
                                    </p>
                                    <small
                                      className="text-muted fs-12 "
                                      style={{}}
                                    >
                                      {data?.user?.email
                                        ? data?.user?.email
                                        : " "}
                                    </small>
                                  </div>
                                  {isOwner &&
                                    data.requestStatus.toLowerCase() !==
                                      "sent" && (
                                      <>
                                        {" "}
                                        {isRemoveLoader.participantId ==
                                          data.id && isRemoveLoader.status ? (
                                          <Loader />
                                        ) : (
                                          <RoyTooltip
                                            id="remove"
                                            title="Remove collaborator"
                                          >
                                            <small
                                              id="remove"
                                              className="  pt-2 plr-5 cursor-pointer hover-underline underlinetextStyle"
                                              style={{
                                                color: `${props.themeChanger.topbarTheme.buttonColor}`
                                              }}
                                              onClick={() =>
                                                // handleRemoveParticipant(data.id)
                                                setDeleteAlert({
                                                  id: data.id,
                                                  status: true
                                                })
                                              }
                                            >
                                              <i className="fas fa-trash test-muted" />
                                            </small>
                                          </RoyTooltip>
                                        )}{" "}
                                      </>
                                    )}
                                </div>
                              );
                            }
                          }
                        })}
                      {isOwner && (
                        <>
                          {isAddParticipant ? (
                            <>
                              <h3
                                className="mb-0 fs-14 header"
                                style={{ marginTop: 20 }}
                              >
                                Add Collaborator(s)
                                <RoyTooltip
                                  id={`addParticipantsInfor`}
                                  title="Press comma, enter or space to add multiple email addresses"
                                >
                                  <i
                                    className="fa fa-info-circle ml-2"
                                    style={{ color: "grey" }}
                                    id={`addParticipantsInfor`}
                                  ></i>
                                </RoyTooltip>
                              </h3>
                              <div className="pb-0">
                                <ChipInput
                                  ref={inputref}
                                  value={addParticipant}
                                  autoFocus
                                  // helperText="Press enter to add multiple email addresses"
                                  id="participantemail"
                                  onKeyUp={handleChipKeyUp}
                                  name="participantemail"
                                  onBeforeAdd={handleEmailValidation}
                                  onChange={chips => {
                                    handleChangeChip(chips);
                                  }}
                                  clearInputValueOnChange={true}
                                  onDelete={(chip, index) => {
                                    handleDeleteChip(chip, index);
                                  }}
                                  // enter,comma,space to add new chips
                                  newChipKeyCodes={[13, 32, 188, 9]}
                                  style={{
                                    width: "100% "
                                  }}
                                  placeholder="example1@email.com, example2@email.com"
                                />
                                {/* {!isEmailValid && isUserInput ? (
                                  <ErrorEmailValidation field="name" />
                                ) : (
                                  ""
                                )} */}
                                {/* {
                                   validateEmailAndColl.status &&(
                                    <span
                                    style={{ color: "red", paddingLeft: 3 }}
                                    className="fs-14"
                                  >
                                    {validateEmailAndColl.message}
                                  </span>
                                   )} */}
                              </div>
                              {isAddParticipantLoader ? (
                                <div className="flex-x ptb-15 ">
                                  <Loader />
                                </div>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row-reverse"
                                    }}
                                  >
                                    <div
                                      className="ptb-5 plr-5 "
                                      style={{ alignItems: "flex-end" }}
                                    >
                                      <button
                                        className="btn button plr-5"
                                        style={{
                                          textTransform: "none ",
                                          color: "white",
                                          fontSize: "14px"
                                        }}
                                        disabled={!addParticipant?.length}
                                        onClick={handleAddParticipant}
                                      >
                                        send Invitation{" "}
                                      </button>
                                    </div>
                                    <div className="ptb-5">
                                      <button
                                        className="btn btn-secondary plr-5"
                                        style={{
                                          textTransform: "none ",
                                          color: "black",
                                          background: "transparent",
                                          border: 0,
                                          fontSize: "14px"
                                        }}
                                        onClick={() =>
                                          setIsAddParticipant(!isAddParticipant)
                                        }
                                      >
                                        Cancel{" "}
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <div className="text-center pt-40 pb-15">
                              <button
                                onClick={() =>
                                  setIsAddParticipant(!isAddParticipant)
                                }
                                className="btn "
                                style={{
                                  backgroundColor: topbarTheme.buttonColor,
                                  color: "#FFF",
                                  width: 240
                                }}
                              >
                                Add Collaborator(s)
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </BusinessWrapper>
                )}
              </div>
            </div>
            <CustomNotification
              show={toast}
              message={toastMessage}
              closeNotification={() => setToast(false)}
            />
          </div>
          <CustomAlert
            PRIMARY_COLOR={props.topbarTheme.buttonColor}
            action="remove"
            // messageType="collaborator"
            show={isDeleteAlert.status}
            onConfirm={() => {
              if (isDeleteAlert.id) {
                handleRemoveParticipant(isDeleteAlert.id);
                setDeleteAlert({ id: "", status: false });
              }
            }}
            // PRIMARY_COLOR={props.topbarTheme.buttonColor}
            onCancel={() => {
              setDeleteAlert({ id: "", status: false });
            }}
            alertMessage={"Are you sure you want to remove collaborator"}
            messageType={"leave"}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-0 col-xs-0" />
      </div>
      {business && (
        <AddBusiness
          status={"update"}
          businessData={business}
          modal={isEditBusiness}
          setmodal={() => setIsEditBusiness(!isEditBusiness)}
          showNotifications={showNotifications}
          updateBusinessData={data => {
            setBusiness(data);
            if (data?.businessMeta && data?.businessMeta?.url)
              setSelectedImage({
                data_url: data.businessMeta.url
              });

            if (isDefaultBusiness) {
              props.update_business({
                businessId: data.id,
                businessName: data.title,
                businessMeta: data.businessMeta
              });
            }
          }}
          updateDefaultBusiness={id => {}}
          updateToken={(token, businessName, businessMeta) => {
            // updateToken(token, businessName, businessMeta);
          }}
        />
      )}
    </div>
  );
}
const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default compose(
  withRouter,
  participantEmailEnhance,
  connect(mapStateToProps, { update_business, update_token, default_business })
)(Business);
