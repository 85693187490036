import projectActions from "./actions";

const initState = {
  projects: localStorage.getItem("projects")
    ? localStorage.getItem("projects")
    : null
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case projectActions.SET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
        recent_project: state.recent_project ? state.recent_project : ""
      };
    case projectActions.UPDATE_PROJECTS:
      let projectarr = state.projects.length > 0 ? state.projects : [];
      projectarr.push(action.projects);
      return {
        ...state,
        projects: projectarr,
        recent_project: state.recent_project ? state.recent_project : ""
      };
    case projectActions.REMOVE_PROJECTS:
      let projectArr = state.projects;
      let id = action.id;
      projectArr.map((project, i) => {
        if (project.id == id) {
          projectArr.splice(i, 1);
        }
      });
      return {
        ...state,
        projects: projectArr,
        recent_project: state.recent_project ? state.recent_project : ""
      };
    case projectActions.UNSET_PROJECTS:
      return {
        ...state,
        recent_project: ""
      };

    case projectActions.RECENT_PROJECTS:
      return {
        ...state,
        recent_project: action.project
      };

    case projectActions.UPDATE_PROJECTS_EXISTING:
      let array = state.projects;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (element.id == action.projects.projects.id) {
          array[index] = action.projects.projects;
        }
      }

      var projectData = { ...state.projects };

      Object.keys(projectData).map((data, i) => {
        if (data.id === action.projects.id) {
          data = action.projects;
        }
      });

      return {
        ...state,
        projects: array,
        recent_project: state.recent_project ? state.recent_project : ""
      };

    default:
      return state;
  }
}
