import React from "react";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import { GetActivityData } from "./GetActivityData";
import profileModelAction from "redux/profileModel/actions";
import moment from "moment";
import { connect } from "react-redux";
const { open_profile_modal } = profileModelAction;

const SingleActivityLefttView = props => {
  const {
    activity,
    handleRedirection,
    isHeaderVisible,
    ProjectActivityTitleVisible,
    key
  } = props;

  const FindFirstLetter = name => {
    var str = name;
    var matches = str.match(/\b(\w)/g);
    var acronym = matches.join("");
    var TwoLetter = acronym.substring(0, 2);
    return TwoLetter.toUpperCase();
  };

  return (
    <div
      key={key || Math.random()}
      className="task-activity-right"
      style={{ paddingRight: 0 }}
    >
      <div
        className="task-activity-right-box"
        style={{ width: "50%", borderRight: "1px solid #ebebeb" }}
      >
        {ProjectActivityTitleVisible != true ? (
          <>
            {isHeaderVisible && (
              <div
                className="task-activity-title"
                style={{
                  display: "flex",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                  textAlign: "left",
                  paddingLeft: 8,
                  paddingRight: 8,
                  fontSize: 16,
                  paddingTop: 5,
                  borderBottom: "1px solid #ebebeb",
                  color: "gray",
                  cursor: "pointer"
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "50%",
                    color: "#877457"
                  }}
                  onClick={() =>
                    handleRedirection({
                      business: activity.business,
                      redirection_type: "business"
                    })
                  }
                  title="Go to workspace"
                >
                  {" "}
                  {activity.business.title}{" "}
                </span>{" "}
                {activity.project && activity.project.id && (
                  <span
                    onClick={() =>
                      handleRedirection({
                        project: activity.project ? activity.project : "",
                        business: activity.business ? activity.business : "",
                        redirection_type: "project"
                      })
                    }
                    title="Go to project"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      width: "50%"
                    }}
                  >
                    <i
                      className="fa fa-circle fs-6"
                      style={{
                        paddingRight: 8,
                        paddingLeft: 8,
                        color: "#877457"
                      }}
                    />
                    <span
                      style={{
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        color: "#877457"
                      }}
                    >
                      {" "}
                      {activity.project.name}
                    </span>
                  </span>
                )}
              </div>
            )}
          </>
        ) : (
          ""
        )}

        <div
          style={{
            textAlign: "left",
            padding: "15px 0px",
            display: "flex"
          }}
        >
          <div
            style={{
              position: "relative",
              // top: "-3px",
              paddingRight: 10
            }}
          >
            <div>
              {activity?.actor?.thumbnail ? (
                <img
                  alt=""
                  src={activity.actor.thumbnail ? activity.actor.thumbnail : ""}
                  style={profileBackground("", 48, 48, 22)}
                  onClick={() => {
                    props.open_profile_modal({
                      profile: activity && activity?.actor
                    });
                  }}
                ></img>
              ) : (
                <div
                  // id="userAssigned"
                  style={profileBackground(
                    activity ? activity?.actor?.name.charAt(0) : "z",
                    48,
                    48,
                    22
                  )}
                  onClick={() => {
                    props.open_profile_modal({
                      profile: activity && activity?.actor
                    });
                  }}
                >
                  {FindFirstLetter(activity && activity?.actor?.name)}
                </div>
              )}
            </div>
          </div>
          <div
            className="task-activity-box"
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              width: "50%"
            }}
          >
            <GetActivityData
              activity={activity}
              handleRedirection={handleRedirection}
            />
            {/* <Commented/> */}
          </div>
          <div
            className="task-activity-date"
            style={{
              paddingRight: 15,
              fontSize: 12,
              paddingLeft: 10
            }}
          >
            {activity && moment(activity.createdAt).format("hh:mm a")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { open_profile_modal })(SingleActivityLefttView);
