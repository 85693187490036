/** @format */

import React from "react";
import LinearLoader from "components/loader/LinearLoader";
import OverDueTask from "./OverDueTask";
import IntlMessages from "util/intlMessages";

const OverDueTaskList = props => {
  const {
    overdueTasks,
    handleRedirection,
    doToggleOverdueTaskStatus,
    doViewMoreOverdue,
    isOverdueNextPageLoader,
    overduePageInfo
  } = props;
  // useEffect(() => {
  //   // console.clear();
  //   // console.log(overdueTasks);
  // }, [overdueTasks]);

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "10px 0px 0px",
          borderRadius: "6px",
          marginBottom: 15
        }}
      >
        <div
          style={{
            color: "#d4442e",
            fontSize: "16px",
            fontWeight: "bold",
            padding: "0px 15px 5px"
          }}
        >
          <IntlMessages id={"globalmyassignment.overduebymorethan"} />
        </div>
        {overdueTasks
          ? overdueTasks.map((mapData, dateindex) => {
              if (mapData.data) {
                return (
                  <>
                    <div style={{ padding: "0px 20px" }}>
                      <div
                        className="overDueTaskList row"
                        style={{
                          padding: "5px 0px",
                          borderTop: "1px solid #ebebeb",
                          justifyContent: "flex-end",
                          display: "flex"
                        }}
                      >
                        <div
                          className="overDueTaskListDate"
                          style={{
                            fontSize: "14px",
                            padding: "0px 0px 0px 10px",
                            width: "10%"
                          }}
                        >
                          {mapData.date}
                        </div>
                        {Object.keys(mapData.data).map(function(
                          objectKey,
                          index
                        ) {
                          return (
                            <div
                              className="overDueTaskListData"
                              style={{ width: "90%" }}
                            >
                              <div
                                style={{
                                  fontWeight: 600,
                                  color: "#877457",
                                  fontSize: "14px",
                                  cursor: "pointer"
                                }}
                                title="Go to Project"
                                onClick={() =>
                                  handleRedirection &&
                                  handleRedirection({
                                    redirection_type: "project",
                                    project: mapData.data[objectKey].project,
                                    business:
                                      mapData.data[objectKey].project.business
                                  })
                                }
                              >
                                {mapData.data[objectKey].project.name}
                              </div>
                              <OverDueTask
                                handleRedirection={handleRedirection}
                                taskArr={mapData.data[objectKey].task}
                                doToggleTaskStatus={(id, status) =>
                                  doToggleOverdueTaskStatus(
                                    id,
                                    status,
                                    dateindex,
                                    objectKey
                                  )
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                );
              }
            })
          : ""}
        {!overduePageInfo.isLastPage && (
          <div
            style={{
              color: props.PRIMARY_COLOR,
              fontSize: 14,
              textDecoration: isOverdueNextPageLoader ? "none" : "underline",
              textAlign: "center",
              cursor: isOverdueNextPageLoader ? "not-allowed" : "pointer",
              paddingBottom: 10
            }}
          >
            {isOverdueNextPageLoader ? (
              <LinearLoader />
            ) : (
              <div
                style={{
                  height: 100,
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "2.09rem"
                }}
                onClick={() =>
                  !overduePageInfo.isLastPage ? doViewMoreOverdue() : {}
                }
              >
                <IntlMessages id={"globalsearchbar.viewmore"} />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default OverDueTaskList;
