import React, { useEffect, useState } from "react";

import {
  commentnew,
  ListGridCircleImage,
  ListGridCheckImage,
  iconTag,
  datesnewmodal
} from "helper/constant";
import { toggleTaskStatus } from "helper/services/taskServices";
import { getColorCode } from "util/data/ProjectColors";
import IconTint from "react-icon-tint";
import { ReactComponent as RepeatSvgIcon } from "assets/images/recurrenceIcon.svg";
import TaskAssignee from "components/assignee/TaskAssignee";
import profileModelAction from "redux/profileModel/actions";
import { repeatTypeOptions } from "util/data/taskDetailsutilData";
import { getPriorityPath } from "constatnt";
import { allPriority } from "constatnt";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
const { open_profile_modal } = profileModelAction;

const OverDueTask = props => {
  const { taskArr, handleRedirection, doToggleTaskStatus } = props;
  const { addToast } = useToasts();
  const [isLoader, setIsLoader] = useState({ status: false, taskId: "" });

  useEffect(() => {
    return () => {
      setIsLoader({ status: false, taskId: "" });
    };
  }, []);
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const doUpdateTaskStatus = async id => {
    if (id) {
      try {
        setIsLoader({ status: true, taskId: id });
        const { message, error } = await toggleTaskStatus(id);
        if (message) {
          if (message == "Task completed") {
            doToggleTaskStatus(id, true);
          } else {
            doToggleTaskStatus(id, false);
          }
        }
        if (error) {
          showNotifications();
          setIsLoader({ status: false, taskId: "" });
        }
      } catch (e) {
      } finally {
        setIsLoader({ status: false, taskId: "" });
      }
    }
  };
  return (
    <>
      {taskArr &&
        taskArr.map((task, index) => {
          return (
            <div>
              <div
                className="flex-x   ptb-10 "
                style={{
                  borderBottom:
                    taskArr.length - 1 == index ? "" : "1px solid #ebebeb"
                  // alignItems: "baseline"
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img
                    alt="checkboxImage"
                    src={
                      task.isCompleted
                        ? ListGridCheckImage
                        : ListGridCircleImage
                    }
                    style={{
                      height: 16,
                      width: 16,
                      opacity:
                        isLoader.status && isLoader.taskId == task.id ? 0.5 : 1
                    }}
                    onClick={() => doUpdateTaskStatus(task.id)}
                  ></img>
                </div>
                <div className="flex-x" style={{ cursor: "pointer" }}>
                  <div className="flex-x flex-wrap align-center fs-14">
                    <div
                      className="flex-1"
                      style={{
                        display: "contents",
                        flexWrap: "wrap"
                      }}
                    >
                      <span
                        className="All_Task_Title fs-14"
                        style={{
                          maxWidth: "50%",
                          color: "black",
                          fontSize: "14px",
                          fontWeight: 500,
                          margin: "3px 25px  3px 0px",
                          wordBreak: "break-word",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          lineClamp: 1,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          WebkitLineClamp: 1,
                          WebkitFontSmoothing: "antialiased"
                        }}
                        onClick={() =>
                          handleRedirection({
                            redirection_type: "task",
                            project: task.project,
                            business: task.project.business,
                            task
                          })
                        }
                      >
                        {task.title}
                      </span>
                      {task && task.priority && task.priority != "no_priority" && (
                        <>
                          {allPriority.map(item => {
                            if (item.name == task.priority) {
                              return (
                                <>
                                  <div
                                    style={{
                                      margin: "3px 25px  3px 0px"
                                      // marginTop: 10
                                    }}
                                    onClick={() =>
                                      handleRedirection({
                                        redirection_type: "task",
                                        project: task.project,
                                        business: task.project.business,
                                        task
                                      })
                                    }
                                  >
                                    <img
                                      src={getPriorityPath(task.priority)}
                                      height="17"
                                      width="18"
                                      className=""
                                      alt="Priority"
                                    />
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        padding: "0px 6px",
                                        color: "gray"
                                      }}
                                    >
                                      {item.value}
                                    </span>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </>
                      )}

                      {task.labels &&
                        task.labels.map((item, index) => {
                          return (
                            <>
                              <div
                                style={{
                                  margin: "3px 25px  3px 0px"
                                  // marginTop: 10
                                }}
                                onClick={() =>
                                  handleRedirection({
                                    redirection_type: "task",
                                    project: task.project,
                                    business: task.project.business,
                                    task
                                  })
                                }
                              >
                                <div
                                  className="my-day-label flex-x mr-6 fs-14 nevy--text align-center overflow-hidden"
                                  key={index}
                                >
                                  <IconTint
                                    src={iconTag}
                                    style={{
                                      position: "relative",
                                      top: "1px",
                                      height: "18",
                                      width: "18"
                                    }}
                                    color={getColorCode(item.color)}
                                  />

                                  <span
                                    style={{
                                      color: "black",
                                      paddingLeft: 5,
                                      maxWidth: "300px",
                                      whiteSpace: "nowrap",
                                      textOverflow: " ellipsis",
                                      overflow: "hidden"
                                    }}
                                  >
                                    {item.name}
                                  </span>
                                </div>
                              </div>
                            </>
                          );
                        })}

                      <div
                        style={{
                          margin: "3px 25px  3px 0px"
                          // marginTop: 10
                        }}
                        onClick={() =>
                          handleRedirection({
                            redirection_type: "task",
                            project: task.project,
                            business: task.project.business,
                            task
                          })
                        }
                      >
                        <img
                          alt="task comment"
                          src={commentnew}
                          style={{
                            height: "18px",
                            width: "15px",
                            marginRight: "5px",
                            textAlign: "center"
                          }}
                        ></img>
                        <span
                          style={
                            {
                              // marginTop: 10
                            }
                          }
                        >
                          {task.commentCount}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "3px 25px  3px 0px"
                        }}
                        onClick={() =>
                          handleRedirection({
                            redirection_type: "task",
                            project: task.project,
                            business: task.project.business,
                            task
                          })
                        }
                      >
                        {task.scheduledAt && (
                          <img
                            alt=""
                            src={datesnewmodal}
                            height="15px"
                            width="15px"
                            style={{ marginRight: 5 }}
                          />
                        )}
                        {task.scheduledAt &&
                          task.repeatType !== repeatTypeOptions[0].value && (
                            <RepeatSvgIcon
                              style={{
                                fill: "green",
                                height: "16px",
                                width: "15px"
                              }}
                            />
                          )}
                        {task.scheduledAt && (
                          <div
                            className="due-date  label"
                            style={{
                              marginLeft: 5
                            }}
                          >
                            <IntlMessages id={"task-details.dueon"} />
                            &nbsp;
                            {moment(new Date(task.scheduledAt)).format(
                              "DD MMM YYYY"
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "contents",
                          alignItems: "center",
                          whiteSpace: "nowrap"
                          // marginTop: 10
                        }}
                      >
                        {task.taskAssign &&
                          task.taskAssign.map((item, i) => (
                            <span
                              onClick={() => {
                                props.open_profile_modal({ profile: item });
                              }}
                            >
                              <TaskAssignee taskAssign={item} key={i} />
                            </span>
                          ))}
                      </div>
                      {/* Self assigned task - Frontend */}
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};
export default connect(null, { open_profile_modal })(OverDueTask);
