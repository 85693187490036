import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import {
  editPencilIcon,
  archive,
  labelsIcon,
  priorityFlag,
  datesnewmodal,
  assignIcon,
  deleteIcon
} from "helper/constant";
import IntlMessages from "util/intlMessages";
const TaskMenu = ({
  target,
  toggleMenu,
  editLabels,
  isMenu,
  editTask,
  editDate,
  deleteTask,
  placement,
  doArchiveTask,
  editPriority,
  editAssign,
  projectTask,
  taskTitle,
  LanguageSwitcher
}) => {
  React.useEffect(() => {}, [isMenu]);

  return (
    <div>
      {isMenu ? (
        <Popover
          trigger="legacy"
          className="roy-menu"
          innerClassName="roy-inner-content"
          placement={placement || "left-end"}
          target={target}
          hideArrow={true}
          onScrollCapture={() => toggleMenu()}
          isOpen={isMenu}
          toggle={() => toggleMenu()}
        >
          <PopoverBody
            style={{
              width: LanguageSwitcher?.language?.locale != "en" ? 250 : 212,
              padding: 15
            }}
          >
            <div
              className="roy-menu-list flex-x"
              style={{
                padding: "10px 5px",
                color: "#000",
                display: "flex",
                alignItems: "center"
              }}
              onClick={e => {
                e.stopPropagation();

                editTask();
              }}
            >
              <div style={{ width: "18%", display: "flex" }} align="left">
                <img alt="" src={editPencilIcon} height="16px" width="18px" />
              </div>
              <span style={{ width: "80%" }}>
                {" "}
                {<IntlMessages id={"today.edittask"} />}
              </span>
            </div>
            {projectTask && (
              <>
                <div
                  className="roy-menu-list flex-x"
                  style={{
                    padding: "10px 5px",
                    color: "#000",
                    display: "flex",
                    alignItems: "center"
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    editLabels();
                    toggleMenu();
                    // setTaskMenu(false);
                    // setCustomAlert(true);
                  }}
                >
                  <div style={{ width: "18%", display: "flex" }} align="left">
                    <img alt="" src={labelsIcon} height="18px" width="18px" />
                  </div>
                  <span style={{ width: "80%" }}>
                    {<IntlMessages id={"today.editlabels"} />}
                  </span>
                </div>
                <div
                  className="roy-menu-list flex-x"
                  style={{
                    padding: "10px 5px",
                    color: "#000",
                    display: "flex",
                    alignItems: "center"
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    editPriority();
                    toggleMenu();
                    // setTaskMenu(false);
                    // setCustomAlert(true);
                  }}
                >
                  <div style={{ width: "18%", display: "flex" }} align="left">
                    <img alt="" src={priorityFlag} height="17px" width="18px" />
                  </div>
                  <span style={{ width: "80%" }}>
                    {" "}
                    {<IntlMessages id={"today.edit_priority"} />}
                  </span>
                </div>
                <div
                  className="roy-menu-list flex-x"
                  style={{
                    padding: "10px 5px",
                    color: "#000",
                    display: "flex",
                    alignItems: "center"
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    editDate();
                    toggleMenu();
                    // setTaskMenu(false);
                    // setCustomAlert(true);
                  }}
                >
                  <div style={{ width: "18%", display: "flex" }} align="left">
                    <img
                      alt=""
                      src={datesnewmodal}
                      height="16px"
                      width="16px"
                    />
                  </div>
                  <span style={{ width: "80%" }}>
                    {<IntlMessages id={"today.editdates"} />}
                  </span>
                </div>
                <div
                  className="roy-menu-list flex-x"
                  style={{
                    padding: "10px 5px",
                    color: "#000",
                    display: "flex",
                    alignItems: "center"
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    editAssign();
                    toggleMenu();
                    // setTaskMenu(false);
                    // setCustomAlert(true);
                  }}
                >
                  <div style={{ width: "18%", display: "flex" }} align="left">
                    <img alt="" src={assignIcon} height="16px" width="18px" />
                  </div>
                  <span style={{ width: "80%" }}>
                    {<IntlMessages id={"today.editassignee"} />}
                  </span>
                </div>
                <hr
                  style={{ margin: "10px 0px", borderTop: "1px solid #EBEBEB" }}
                />

                <div
                  className="roy-menu-list flex-x"
                  style={{
                    padding: "10px 5px",
                    color: "#000",
                    display: "flex",
                    alignItems: "center"
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    doArchiveTask();
                    // setTaskMenu(false);
                    toggleMenu();
                    // setCustomAlert(true);
                  }}
                >
                  <div style={{ width: "18%", display: "flex" }} align="left">
                    <img alt="" src={archive} height="16px" width="18px" />
                  </div>
                  <span style={{ width: "80%" }}>
                    {<IntlMessages id={"today.archivetask"} />}
                  </span>
                </div>

                {/* <div
                  className="roy-menu-list flex-x"
                  style={{
                    padding: "10px 5px",
                    color: "#000",
                    display: "flex",
                    alignItems: "center"
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    viewTaskDetails();
                  }}
                >
                  <div style={{ width: "18%", display: "flex" }} align="left">
                    <img src={taskActivity} height="16px" width="18px" />
                  </div>
                  <span style={{ width: "80%" }}>View Task Activity</span>
                </div> */}

                <hr
                  style={{ margin: "10px 0px", borderTop: "1px solid #EBEBEB" }}
                />
              </>
            )}

            <div
              className="roy-menu-list flex-x"
              style={{
                padding: "10px 5px",
                color: "#000",
                display: "flex",
                alignItems: "center"
              }}
              onClick={e => {
                e.stopPropagation();
                deleteTask();
              }}
            >
              <div style={{ width: "18%", display: "flex" }} align="left">
                <img alt="" src={deleteIcon} height="16px" width="18px" />
              </div>
              <span style={{ width: "80%" }}>
                {<IntlMessages id={"today.deletetask"} />}
              </span>
            </div>
          </PopoverBody>
        </Popover>
      ) : (
        ""
      )}
    </div>
  );
};

export default TaskMenu;
