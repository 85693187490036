import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import {
  archive,
  datesnewmodal,
  assignIcon,
  deleteIcon,
  checklistnewmodal,
  attachmentnew,
  prioritynewmodal
} from "helper/constant";
import { EDIT_ACTION_CONSTANT } from "./Constant";
import IntlMessages from "util/intlMessages";
const TaskDetailsPopoverMenu = ({
  toggleTaskDetailsMenu,
  handleMenuAction,
  isTaskDetailsPopover,
  isArchived,
  props
}) => {
  React.useEffect(() => {}, [isTaskDetailsPopover]);

  return (
    <div>
      {isTaskDetailsPopover ? (
        <Popover
          trigger="legacy"
          className="roy-menu"
          innerClassName="roy-inner-content"
          placement="left-start"
          target="popover"
          hideArrow={true}
          //   onScrollCapture={() => toggleTaskDetailsMenu()}
          isOpen={isTaskDetailsPopover}
          toggle={() => toggleTaskDetailsMenu()}
        >
          <PopoverBody style={{ width: 212, padding: 15 }}>
            <div
              className="roy-menu-list flex-x"
              style={{
                padding: "10px 5px",
                color: "#000",
                display: "flex",
                alignItems: "center"
              }}
              onClick={e => {
                e.stopPropagation();
                handleMenuAction(EDIT_ACTION_CONSTANT.assign);
                toggleTaskDetailsMenu();
              }}
            >
              <div style={{ width: "18%", display: "flex" }} align="left">
                <img alt="" src={assignIcon} height="15px" width="18px" />
              </div>
              <span style={{ width: "80%" }}>
                <IntlMessages id={"task-details.assigntask"} />
              </span>
            </div>
            <hr
              style={{ margin: "10px 0px", borderTop: "1px solid #EBEBEB" }}
            />

            <div
              className="roy-menu-list flex-x"
              style={{
                padding: "10px 5px",
                color: "#000",
                display: "flex",
                alignItems: "center"
              }}
              onClick={e => {
                e.stopPropagation();
                handleMenuAction(EDIT_ACTION_CONSTANT.schedule);
                toggleTaskDetailsMenu();
              }}
            >
              <div style={{ width: "18%", display: "flex" }} align="left">
                <img alt="" src={datesnewmodal} height="16px" width="18px" />
              </div>
              <span style={{ width: "80%" }}>
                <IntlMessages id={"task-details.schedule"} />
              </span>
            </div>
            {/* {projectTask && ( */}
            <>
              <div
                className="roy-menu-list flex-x"
                style={{
                  padding: "10px 5px",
                  color: "#000",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={e => {
                  e.stopPropagation();
                  handleMenuAction(EDIT_ACTION_CONSTANT.checklist);
                  toggleTaskDetailsMenu();
                }}
              >
                <div style={{ width: "18%", display: "flex" }} align="left">
                  <img
                    alt=""
                    src={checklistnewmodal}
                    height="15px"
                    width="18px"
                  />
                </div>
                <span style={{ width: "80%" }}>
                  <IntlMessages id={"task-details.checklist"} />
                </span>
              </div>

              <div
                className="roy-menu-list flex-x"
                style={{
                  padding: "10px 5px",
                  color: "#000",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={e => {
                  e.stopPropagation();
                  handleMenuAction(EDIT_ACTION_CONSTANT.prirority);
                  toggleTaskDetailsMenu();
                }}
              >
                <div style={{ width: "18%", display: "flex" }} align="left">
                  <img
                    alt=""
                    src={prioritynewmodal}
                    height="16px"
                    width="16px"
                  />
                </div>
                <span style={{ width: "80%" }}>
                  <IntlMessages id={"task-details.priority"} />
                </span>
              </div>

              <hr
                style={{ margin: "10px 0px", borderTop: "1px solid #EBEBEB" }}
              />

              <div
                className="roy-menu-list flex-x"
                style={{
                  padding: "10px 5px",
                  color: "#000",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={() => {
                  handleMenuAction(EDIT_ACTION_CONSTANT.attachment);
                }}
              >
                <div style={{ width: "18%", display: "flex" }} align="left">
                  <img
                    src={attachmentnew}
                    height="16px"
                    width="18px"
                    alt="attachment"
                  />
                </div>
                <span style={{ width: "80%" }}>
                  <IntlMessages id={"task-details.viewattachments"} />
                </span>
              </div>
              {/* <div
                className="roy-menu-list flex-x"
                style={{
                  padding: "10px 5px",
                  color: "#000",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={() => {
                  handleMenuAction(EDIT_ACTION_CONSTANT.activity);
                  toggleTaskDetailsMenu();
                }}
              >
                <div style={{ width: "18%", display: "flex" }} align="left">
                  <img  alt="" src={taskActivity} height="16px" width="18px" />
                </div>
                <span style={{ width: "80%" }}>View Activity</span>
              </div> */}
              {/* <div
                className="roy-menu-list flex-x"
                style={{
                  padding: "10px 5px",
                  color: "#000",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={e => {
                  e.stopPropagation();
                  handleMenuAction(EDIT_ACTION_CONSTANT.share);
                  toggleTaskDetailsMenu();
                }}
              >
                <div style={{ width: "18%", display: "flex" }} align="left">
                  <img  alt="" src={sharenew} height="16px" width="18px" />
                </div>
                <span style={{ width: "80%" }}>Share</span>
              </div> */}

              <hr
                style={{ margin: "10px 0px", borderTop: "1px solid #EBEBEB" }}
              />
            </>
            {/* )} */}
            <div
              className="roy-menu-list flex-x"
              style={{
                padding: "10px 5px",
                color: "#000",
                display: "flex",
                alignItems: "center"
              }}
              onClick={e => {
                e.stopPropagation();
                handleMenuAction(EDIT_ACTION_CONSTANT.archiveTask);
                toggleTaskDetailsMenu();
              }}
            >
              <div style={{ width: "18%", display: "flex" }} align="left">
                <img alt="" src={archive} height="16px" width="18px" />
              </div>
              <span style={{ width: "80%" }}>
                {isArchived ? (
                  <IntlMessages id={"task-details.anarchive"} />
                ) : (
                  <IntlMessages id={"task-details.archivetask"} />
                )}
              </span>
            </div>
            <div
              className="roy-menu-list flex-x"
              style={{
                padding: "10px 5px",
                color: "#000",
                display: "flex",
                alignItems: "center"
              }}
              onClick={e => {
                e.stopPropagation();
                handleMenuAction(EDIT_ACTION_CONSTANT.deleteTask);
                toggleTaskDetailsMenu();
              }}
            >
              <div style={{ width: "18%", display: "flex" }} align="left">
                <img alt="" src={deleteIcon} height="17px" width="18px" />
              </div>
              <span style={{ width: "80%" }}>
                <IntlMessages id={"task-details.deletetask"} />
              </span>
            </div>
          </PopoverBody>
        </Popover>
      ) : (
        ""
      )}
    </div>
  );
};

export default TaskDetailsPopoverMenu;
