const projectActions = {
  SET_PROJECTS: "SET_PROJECTS",
  UNSET_PROJECTS: "UNSET_PROJECTS",
  UPDATE_PROJECTS: "UPDATE_PROJECTS",
  RECENT_PROJECTS: "RECENT_PROJECTS",
  REMOVE_PROJECTS: "REMOVE_PROJECT",
  UPDATE_PROJECTS_EXISTING: "UPDATE_PROJECTS_EXISTING",

  set_projects: data => {
    return {
      type: projectActions.SET_PROJECTS,
      projects: data.projects
    };
  },
  unset_projects: () => {
    return {
      type: projectActions.UNSET_PROJECTS,
      projects: null
    };
  },
  update_projects_existing: projects => {
    return {
      type: projectActions.UPDATE_PROJECTS_EXISTING,
      projects: projects
    };
  },
  update_Project: projects => {
    return {
      type: projectActions.UPDATE_PROJECTS,
      projects: projects
    };
  },
  update_recent_Project: projects => {
    return {
      type: projectActions.RECENT_PROJECTS,
      project: projects
    };
  },
  remove_Project: id => {
    return {
      type: projectActions.REMOVE_PROJECTS,
      id
    };
  }
};
export default projectActions;
