import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { Button, Modal, ModalBody } from "reactstrap";
import { Alert } from "reactstrap";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import DescriptionEditor from "components/business/DescriptionEdiotr";
import { addBusiness, updateBusiness } from "helper/services/businessServices";
import alertActions from "redux/globalAlert/actions";
import {
  AddWorkspaceImage,
  crossIcon,
  EditIconModal,
  TreshImageCreateWorkSpace
} from "helper/constant";
import { Row, Col } from "reactstrap";
import { getUserCharColor } from "helper/methods/PlaceholderColor";
import CreateWorkSpace from "./CreateWorkSpace.style";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";

const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const AddBusiness = props => {
  const {
    modal,
    setmodal,
    pushNewBusiness,
    topbarTheme,
    status,
    businessData,
    updateBusinessData,
    updateToken,
    showNotifications
  } = props;
  const [isLoader, setIsLoader] = useState(false);
  const [isVisibleAlert, setIsVisibleAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState(null);
  const [imageFlage, setImageFlag] = useState(false);
  const { addToast } = useToasts();
  const [isDeleteProfilePic, setIsDeleteProfilePic] = useState(false);
  useEffect(() => {
    if (modal) {
      if (status.toLowerCase() == "update") {
        setIsDisabled(businessData?.title?.length > 0 ? false : true);
        setBusinessName(businessData?.title);
        setBusinessDescription(businessData?.description);
        if (businessData?.businessMeta && businessData?.businessMeta?.url) {
          setSelectedImage({ data_url: businessData?.businessMeta?.url });
          setIsDeleteProfilePic(false);
        }
        // if (Response.user.userMeta) {
        //   setSelectedImage({
        //     data_url: Response.user.userMeta.thumbnail,
        //   });
        // }
      }
      if (status.toLowerCase() == "Add") {
        setBusinessName("");
        setIsDisabled(true);
      }
    }
    return () => {
      setBusinessName("");
      setIsLoader(false);
      setIsVisibleAlert(false);
      setSelectedImage(null);
      setIsDeleteProfilePic(false);
    };
  }, [modal]);
  const onImageChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    setSelectedImage(imageList[0]);
    setIsDisabled(false);
    setImageFlag(true);
    if (imageList?.length > 0) {
      setIsDeleteProfilePic(false);
    }
  };

  const validateName = businessName => {
    const validate = businessName.match(/^\S+\w{0,50}\S{0,}/g);

    if (
      businessName.length > 1 &&
      businessName.length < 51 &&
      validate !== null &&
      validate
    ) {
      return true;
    }
    if (businessName.length > 50) {
      return false;
    }
    if (businessName.length <= 0) return false;
  };
  const handleUpdateBusiness = async e => {
    if (
      e.target.businessName.value &&
      businessData &&
      validateName(e.target.businessName.value)
    ) {
      try {
        setIsLoader(true);
        let userBusinessImage;
        if (selectedImage && imageFlage) {
          userBusinessImage = { selected: selectedImage.file };
        } else {
          userBusinessImage = { selected: "" };
        }

        const Response = await updateBusiness(
          {
            title: e.target.businessName.value,
            businessImage: userBusinessImage.selected,
            description: e.target.businessDescription?.value
              ? e.target.businessDescription?.value
              : businessDescription,
            isDeleteBusinessPic: isDeleteProfilePic
          },
          businessData.id
        );

        if (Response) {
          if (Response.error) {
            addToast(ToastMessage(Response.error.message), {
              appearance: "error",
              autoDismiss: true
            });
            handleResponse(Response.error.message);
            setIsLoader(false);
            setErrorMessage(Response.error.message);
            setIsVisibleAlert(true);
            // showNotifications();
          }
          if (Response.data) {
            setIsLoader(false);
            // updateBusinessData(Response.data.business);

            updateBusinessData(Response.data.business);

            updateToken(
              Response.data.token,
              Response.data.business.title,
              Response.data.business?.businessMeta
                ? Response.data.business?.businessMeta
                : null,
              Response.data.business.id
            );

            setmodal(!modal);
            showNotifications(Response.message, "success");
            setSelectedImage(null);
          }
        }
      } catch (err) {
        showNotifications();
      }
    } else {
      setIsDisabled(true);
    }
  };
  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        // status:action.alert.status, message:action.alert.message,action:'open_businesslist'
        setmodal(!modal);
        props.open_workpsace_alert({
          status: true,
          message: "workspace"
        });
      }
      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
      }
    } catch (e) {}
  };
  const handleFormSubmit = async e => {
    e.preventDefault();
    try {
      if (status.toLowerCase() == "update") {
        /* update Api  */
        handleUpdateBusiness(e);
      }
      if (status.toLowerCase() == "add") {
        handleAddBusiness(e);
      }
    } catch (e) {}
  };
  const handleAddBusiness = async e => {
    if (e.target.businessName.value) {
      try {
        setIsLoader(true);
        let userBusinessImage;
        if (selectedImage && imageFlage) {
          userBusinessImage = { selected: selectedImage.file };
        } else {
          userBusinessImage = { selected: "" };
        }
        const Response = await addBusiness({
          title: e.target.businessName.value,
          // isDefault: false,
          businessImage: userBusinessImage.selected
        });
        if (Response) {
          if (Response.error) {
            addToast(ToastMessage(Response.error.message), {
              appearance: "error",
              autoDismiss: true
            });
            setIsLoader(false);
            setErrorMessage(Response.error.message);
            setIsVisibleAlert(true);
            // showNotifications();
          }
          if (Response.data) {
            addToast(ToastMessage(Response.message), {
              appearance: "success",
              autoDismiss: true
            });
            pushNewBusiness(Response.data.business);
            updateToken(
              Response.data.token,
              Response.data.business.title,
              Response.data.business?.businessMeta
                ? Response.data.business?.businessMeta
                : null,
              Response.data.business.id
            );
            setSelectedImage(null);
            setIsLoader(false);
            showNotifications("New Business Added Successfully", "success");
          }
        }
      } catch (e) {
        showNotifications();
      }
    }
  };
  return (
    <Modal isOpen={modal} toggle={() => setmodal(!modal)} size="lg" centered>
      <form onSubmit={handleFormSubmit}>
        {/* <ModalHeader toggle={() => setmodal(!modal)}>
          {status.toLowerCase() == "update" ? "Update" : "Add "} Workspace
        </ModalHeader> */}
        <Alert
          className="c-"
          style={{ backgroundColor: "RED ", color: "white" }}
          isOpen={isVisibleAlert}
          toggle={() => setIsVisibleAlert(false)}
        >
          {isVisibleAlert ? errorMessage : ""}
        </Alert>

        <ModalBody>
          <div style={{ position: "relative" }} className="container">
            <Row
              style={{
                marginRight: "-30px",
                marginTop: "-15px",
                marginBottom: "-15px"
              }}
            >
              <Col
                className="col-lg-6 col-md-12 col-sm-12 col-xs-12"
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  paddingLeft: "0px"
                }}
              >
                <span
                  style={{
                    fontSize: 20,
                    fontWeight: "600",
                    color: "black",
                    paddingLeft: "15px"
                  }}
                >
                  {status.toLowerCase() == "update" ? (
                    <IntlMessages id={"workspace.update"} />
                  ) : (
                    <IntlMessages id={"globalquickaddactions.addbutton"} />
                  )}{" "}
                  <IntlMessages id={"header.workspace"} />
                </span>

                <div
                  className="container list-container"
                  style={{ padding: "0px 15px 20px 15px" }}

                  /* key={i} */
                >
                  <div
                    className="text-center iconDemo-image"
                    style={{ paddingTop: "30px" }}
                  >
                    <ImageUploading
                      value={images}
                      onChange={onImageChange}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps
                      }) => (
                        // write your building UI
                        <div
                          className="upload__image-wrapper text-center iconDemo-image"
                          style={{ display: "inline-block", height: "100px" }}
                        >
                          {/* <img
                            style={{
                              objectFit: "cover",
                              borderRadius: "50%",
                              border: "10px solid red"
                            }}
                            src={
                              selectedImage && selectedImage.data_url
                                ? selectedImage.data_url
                                : businessImage
                            }
                            onClick={onImageUpload}
                            id="userBusinessImage"
                            alt="icon"
                            height="100px"
                            width="100px"
                          /> */}
                          {selectedImage && selectedImage ? (
                            <img
                              src={selectedImage.data_url}
                              onClick={onImageUpload}
                              id="iconDemoPicture"
                              alt="icon"
                              height="100px"
                              width="100px"
                              style={{
                                borderRadius: "8px",
                                objectFit: "cover"
                                // borderRadius: "50%"
                              }}
                            />
                          ) : (
                            <div
                              className=" workspace-placeholder-input-value"
                              style={{
                                // backgroundColor: topbarTheme.buttonColor
                                background: getUserCharColor(
                                  businessName.charAt(0)
                                )
                              }}
                            >
                              {businessName && businessName ? (
                                businessName[0].toUpperCase()
                              ) : (
                                <div
                                  className="workspace-placeholder-input-value-change"
                                  style={{
                                    // backgroundColor: topbarTheme.buttonColor
                                    background: getUserCharColor("W")
                                  }}
                                >
                                  W
                                </div>
                              )}
                            </div>
                          )}

                          {imageList.length > 0 &&
                            imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <div className="image-item__btn-wrapper pt-2">
                                  <a
                                    onClick={() => onImageUpdate(index)}
                                    style={{
                                      position: "relative",
                                      top: "-38px",
                                      left: "35px"
                                    }}
                                  >
                                    <img
                                      className="close-icon-modal"
                                      src={EditIconModal}
                                      style={{ height: 20 }}
                                    ></img>
                                    {/* <i className="fa fa-edit" /> */}
                                  </a>
                                  <a
                                    onClick={() => {
                                      setIsDeleteProfilePic(true);
                                      onImageRemove(index);
                                    }}
                                    style={{
                                      position: "relative",
                                      top: "-38px",
                                      left: "-55px"
                                    }}
                                  >
                                    <img
                                      className="close-icon-modal"
                                      src={TreshImageCreateWorkSpace}
                                      style={{ height: 20 }}
                                    ></img>
                                    {/* <i className="fa fa-trash" /> */}
                                  </a>
                                </div>
                              </div>
                            ))}
                          {imageList.length == 0 && (
                            <div key={1} className="image-item">
                              <div className="image-item__btn-wrapper">
                                <a
                                  onClick={onImageUpload}
                                  style={{
                                    position: "relative",
                                    top: "-30px",
                                    left: "35px"
                                  }}
                                >
                                  <img
                                    className="close-icon-modal"
                                    src={EditIconModal}
                                    style={{ height: 20 }}
                                  ></img>
                                  {/* <i className="fa fa-edit" /> */}
                                </a>
                                {imageList.length === 0 && selectedImage && (
                                  <a
                                    onClick={() => {
                                      setSelectedImage(null);
                                      setIsDeleteProfilePic(true);
                                      setIsDisabled(false);
                                    }}
                                    style={{
                                      position: "relative",
                                      top: "-30px",
                                      left: "35px"
                                    }}
                                  >
                                    <img
                                      className="close-icon-modal"
                                      src={TreshImageCreateWorkSpace}
                                      style={{ height: 20 }}
                                    ></img>
                                    {/* <i className="fa fa-trash" /> */}
                                  </a>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                  <div className="form-group mt-50">
                    <div>
                      <span style={{ fontSize: 14, fontWeight: 600 }}>
                        <label>
                          <IntlMessages id={"globalworkspacelist.name"} />
                        </label>
                      </span>
                    </div>
                    <CreateWorkSpace>
                      <input
                        type="text"
                        className="form-control react-form-input"
                        id="businessName"
                        placeholder={InjectPlaceHolderMessage(
                          "workspace.enterworkspacename",
                          props?.LanguageSwitcher?.language?.languageId
                        )}
                        defaultValue={businessName}
                        autoFocus
                        onChange={e => {
                          setBusinessName(e.target.value);
                          setIsDisabled(
                            validateName(e.target.value) ? false : true
                          );
                        }}
                      />
                    </CreateWorkSpace>
                  </div>
                  <div className="form-group mb-30">
                    {/* <label>Description (optional)</label> */}
                    <span style={{ fontSize: 14, fontWeight: 600 }}>
                      <IntlMessages id={"globalworkspacelist.description"} />
                    </span>
                    <CreateWorkSpace>
                      <DescriptionEditor
                        inputValue={businessDescription}
                        onChange={data => setBusinessDescription(data)}
                        id="businessDescription"
                        className="form-control react-form-input"
                        rows="5"
                        placeholder={InjectPlaceHolderMessage(
                          "workspace.ourteam",
                          props?.LanguageSwitcher?.language?.languageId
                        )}
                      />
                    </CreateWorkSpace>
                    {/* <textarea
                value={businessDescription}
                onChange={e => setBusinessDescription(e.target.value)}
                id="businessDescription"
                className="form-control react-form-input"
                rows="5"
                placeholder="Our team organizes everything here."
              /> */}
                  </div>
                  {/* <Button
                    className="button btn"
                    style={{ background: "#FFF", border: 0, color: "#000" }}
                    onClick={() => setmodal(!modal)}
                  >
                    Cancel
          </Button> */}
                  {isLoader ? (
                    <Loader height="24px" width="20px" />
                  ) : (
                    <Button
                      type="submit"
                      className="btn button"
                      disabled={isDisabled}
                      style={{
                        // backgroundColor: isDisabled
                        //   ? topbarTheme.buttonColor
                        //   : topbarTheme.buttonColor,
                        backgroundColor: topbarTheme.buttonColor,
                        border: "none",
                        width: "100%",
                        padding: "10px"
                      }}
                    >
                      {status.toLowerCase() == "update" ? (
                        <IntlMessages id={"workspace.update"} />
                      ) : (
                        <IntlMessages id={"globalquickaddactions.addbutton"} />
                      )}
                    </Button>
                  )}
                </div>
              </Col>

              <div>
                <img
                  className="close-icon-modal"
                  onClick={() => setmodal(!modal)}
                  src={crossIcon}
                  style={{
                    color: "black",
                    height: "15px",
                    position: "absolute",
                    left: "98%",
                    zIndex: 5,
                    top: 20,
                    cursor: "pointer"
                  }}
                ></img>
              </div>

              <Col
                className="ImgeNone col-lg-6 col-md-12 col-sm-12 col-xs-12"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <div
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    height: "100%",
                    backgroundColor: "#F9F9F9",
                    display: " flex",
                    justifyContent: "center",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderBottomLeftRadius: "7px"
                  }}
                >
                  <img
                    className="ImageName"
                    src={AddWorkspaceImage}
                    style={{
                      color: "black",
                      height: "192px"
                    }}
                  ></img>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>

        {/* <ModalFooter>
          <Button
            className="button btn"
            style={{ background: "#FFF", border: 0, color: "#000" }}
            onClick={() => setmodal(!modal)}
          >
            Cancel
          </Button>
          {isLoader ? (
            <Loader height="24px" width="20px" />
          ) : (
            <Button
              type="submit"
              className="btn button"
              disabled={isDisabled}
              style={{
                backgroundColor: isDisabled ? "gray" : topbarTheme.buttonColor,
                border: "none"
              }}
            >
              {status.toLowerCase() == "update" ? "Update" : "Add"}
            </Button>
          )}
        </ModalFooter> */}
      </form>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {
  open_workpsace_alert,
  open_project_alert,
  close_alert
})(AddBusiness);
