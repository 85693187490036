import labelActions from "./actions";

const initState = {
  labels: localStorage.getItem("labels") ? localStorage.getItem("labels") : []
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case labelActions.SET_LABELS:
      return {
        // ...state,
        labels: action.labels
      };
    case labelActions.UPDATE_LABELS:
      let labelarr = state.labels.length > 0 ? state.labels : [];
      labelarr.push(action.labels);
      return {
        ...state,
        labels: labelarr
      };
    case labelActions.UNSET_LABELS:
      return {
        labels: []
      };

    case labelActions.UPDATE_LABELS_EXISTING:
      let array = [...state.labels];

      var projectData = [...state.labels];

      projectData &&
        projectData.map((data, i) => {
          if (data.id === action.labels.labels.id) {
            array[i] = action.labels.labels;
          }
        });

      return {
        ...state,
        labels: array
      };

    default:
      return state;
  }
}
