import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { connect } from "react-redux";
import TaskDetailsCard from "components/TaskDetails/TaskDetailsCard";
import TaskCommentCard from "components/TaskDetails/TaskCommentCard";
import TaskChecklistCard from "components/TaskDetails/TaskChecklistCard";
import OneLineShimmer from "components/shimmer/OneLineShimmer.jsx";
import TaskAlert from "components/TaskDetails/Alerts/TaskAlert";
import Loader from "components/loader/Loader";
import { getTaskDetails } from "helper/services/taskServices";
import { checkIsArchivedMessage } from "helper/methods/workspacestatus";
import { getCollaboratorsList } from "helper/services/collboratorsServices";
import { taskDetailsSocketRoomPrefix } from "constatnt";
import {
  taskDetailsInit,
  joinRoom,
  leaveRoom,
  updateTask,
  updateComment,
  addSubTask,
  deleteChecklist,
  addChecklist,
  archiveTask,
  deleteTask,
  updateSubTask,
  updateChecklist,
  addTaskcomment,
  deleteTaskcomment
} from "services/socket";
import { getAllSubtask } from "helper/services/subtaskService";
import {
  getTaskActivity,
  addcomment,
  getTaskcomments
} from "helper/services/taskServices";
import CustomAlert from "util/CustomAlert";
import TaskAssignModal from "components/projects/collaborators/TaskAssignModal";
import ToastMessage from "components/notifications/ToastMessage";
import { useToasts } from "react-toast-notifications";
import alertActions from "redux/globalAlert/actions";
import projectActions from "redux/projects/actions";
import fileDropActions from "redux//Filedrop/actions";

import authActions from "redux/auth/actions";
import { toggleArchive } from "helper/services/businessServices";
import { updateProject } from "helper/services/projectServices";
import { FileDrop } from "react-file-drop";
import IntlMessages from "util/intlMessages";

const {
  open_workpsace_alert,
  open_project_alert,
  open_task_alert,
  close_alert
} = alertActions;
const { update_projects_existing, remove_Project } = projectActions;
const { doEnableFileDropEvent, doDisableFileDropEvent } = fileDropActions;
const { update_business_archive } = authActions;
const TaskDetailsView = props => {
  const { addToast } = useToasts();
  const mainDivRef = useRef(null);
  const { PRIMARY_COLOR } = props;
  const [commentInput, setCommentInput] = useState(false);
  const [allFileDrop, setAllFileDrop] = useState(false);
  const [isTrixDragEnter, setIsTrixDragEnter] = useState(false);
  const [isDrag, setIsDrag] = useState(false);

  const [isTaskDetailsPopover, setIsTaskDetailsPopover] = useState(false);
  const [isArchivedLoader, setIsArchivedLoader] = useState(false);
  const [isEditTask, setIsEditTask] = useState(false);
  const [activeTaskMenu, setActiveTaskMenu] = useState("");
  const [taskDetailsData, setTaskDetailsData] = useState("");
  const [ActivityHistory, setActivityHistory] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isFetchCommentsLoader, setIsFetchCommentsLoader] = useState(false);
  const [isFetchDetailsLoader, setIsFetchDetailsLoader] = useState(false);
  const [attachmentCommentsLoad, setAttachmentCommentsLoad] = useState(false);
  const [taskActivityData, setTaskActivityData] = useState([]);
  const [shareLink, setShareLink] = useState("");
  const [checklistLoader, setChecklistLoader] = useState(false);
  const [isLoaderChecklist, setIsFetchCommentsLoaderChecklist] = useState(
    false
  );
  const [isUploadCommentLoader, setIsUploadCommentLoader] = useState(false);
  const [subtaskList, setSubtaskList] = useState([]);
  const [taskComments, setTaskComments] = useState([]);
  const [isAddChecklist, setIsAddChecklist] = useState(false);
  const [projectId, setProjectId] = useState(false);
  const [sectionId, setSectionId] = useState(false);
  const [isAssignTaskModal, setIsAssignTaskModal] = useState(false);
  const [isTaskAlert, setIsTaskAlert] = useState(false);
  const [collaboratorListArr, setCollaboratorListArr] = useState([]);
  const [isCollabFetchingLoader, setIsCollabFetchingLoader] = useState(false);

  const [showDeleteCommentAlert, setShowDeleteCommentAlert] = useState({
    status: false,
    action: "delete",
    messageType: "comment",
    id: "",
    title: ""
  });

  // const { taskDetails, projectId, sectionId } = props.history?.location?.state;
  const checkListRef = React.useRef(subtaskList);
  const taskactivityRef = React.useRef(null);
  const taskDetailscrollRef = React.useRef(null);
  const taskDetailDataRef = React.useRef(null);
  const taskId = props.match.params.id;
  useEffect(() => {
    taskDetailDataRef.current = taskDetailsData;
    taskactivityRef.current = ActivityHistory;
    return () => {};
  });
  useEffect(() => {
    if (taskId) {
      props.doEnableFileDropEvent();
      taskDetailscrollRef.current.scrollIntoView();
      // console.clear();
      handleTaskDetailsInitialize(taskId);
    }
    return () => {
      props.doDisableFileDropEvent();
      handleCleanup();
      handleLeaveSocketRoom(taskId);
    };
  }, [taskId]);
  const handleTaskDetailsInitialize = async taskId => {
    setIsFetchCommentsLoaderChecklist(true);
    setIsFetchCommentsLoader(true);
    setActivityHistory([]);
    setSubtaskList([]);
    setCollaboratorListArr([]);
    const taskResults = await doGetTaskDetails(taskId);

    if (taskResults && taskResults.status == "ok") {
      fetchComments(true);
      setShareLink(taskDetailsData.shareablelink);
      handleSocketInitializeAndJoinRoom(props.auth.accessToken, taskId);
      retrieveSubtask(true, taskResults.projectId);
      retrieveCollaboratsList(taskResults.projectId);
      // taskInit(
      //   addSubTaskCB,
      //   updateSubTaskCB,
      //   addChecklistCB,
      //   updateChecklistCB,
      //   deleteChecklistCB,
      //   updateCommentCB,
      //   subtaskAssignCB
      // );
    }
  };
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const handleCleanup = () => {
    setIsTaskAlert(false);
    setIsCollabFetchingLoader(false);
    setCollaboratorListArr([]);
    setTaskDetailsData("");
    setActiveTaskMenu("");
    setIsTaskDetailsPopover(false);
    setIsTaskDetailsPopover("");
    setIsEditTask("");
    setActiveTaskMenu("");
    setTaskDetailsData("");
    setActivityHistory([]);
    setIsFetchCommentsLoader(false);
    setAttachmentCommentsLoad(false);
    setTaskActivityData("");
    setShareLink("");
    setChecklistLoader(false);
    setIsFetchCommentsLoaderChecklist(false);
    setIsUploadCommentLoader(false);
    setSubtaskList([]);
    setTaskComments([]);
    setIsFetchCommentsLoader(false);
    setIsAddChecklist(false);
    setProjectId("");
    setSectionId("");
    setIsAssignTaskModal(false);
  };
  const handleLeaveSocketRoom = id => {
    leaveRoom(`${taskDetailsSocketRoomPrefix}${id}`);
  };
  const retrieveSubtask = async (isLoader, projectID = projectId) => {
    try {
      setIsFetchCommentsLoaderChecklist(isLoader);
      let payload = {
        project_id: projectID.toString(),
        task_id: taskId.toString()
      };
      const Response = await getAllSubtask(payload);
      if (Response) {
        if (Response.error) {
          setIsFetchCommentsLoaderChecklist(false);
          showNotifications();
        }
        if (Response.data) {
          setSubtaskList(Response.data);
          checkListRef.current = Response.data;

          const ResponseData = isUniqueSubtask(Response.data, "id");

          setIsFetchCommentsLoaderChecklist(false);
        }
      }
    } catch (e) {
      showNotifications();
    }
  };
  const handlePostRequestResponse = Response => {
    if (Response) {
      if (Response.status.toLowerCase() === "ok") {
      } else {
        showNotifications();
      }
    }
  };
  const handleSocketInitializeAndJoinRoom = (token, id) => {
    taskDetailsInit(
      updateTaskCB,
      deleteTaskCB,
      moveTaskCB,
      reorderTaskCB,
      addCollaboratorCB,
      archiveTaskCB,
      updateCommentCB,
      addSubTaskCB,
      updateSubTaskCB,
      addChecklistCB,
      updateChecklistCB,
      deleteChecklistCB,
      subtaskAssignCB,
      addTaskCommentCB,
      deleteTaskCommentCB,
      updateTaskCommentCB,
      deleteTaskcommentEmojiCB,
      addTaskcommentEmojiCB,
      token
    );
    joinRoom(`${taskDetailsSocketRoomPrefix}${id}`);
  };

  /* Collabaroators */
  const retrieveCollaboratsList = async (projectID = projectId) => {
    if (projectID) {
      try {
        setIsCollabFetchingLoader(true);
        const result = await getCollaboratorsList(projectID);
        if (result) {
          if (result.error) {
          }
          if (result.data) {
            setCollaboratorListArr([...result.data]);
          }
        }
        setIsCollabFetchingLoader(false);
      } catch (error) {
        setIsCollabFetchingLoader(false);
      }
    }
  };

  /* Comment Start */
  const fetchComments = async loadingStatus => {
    // const messagesEndRef = useRef(null)
    try {
      if (taskId) {
        setIsSyncing(true);
        setAttachmentCommentsLoad(loadingStatus);
        setIsFetchCommentsLoader(loadingStatus);

        const Response = await getTaskcomments(taskId);
        console.log(Response, "");
        if (Response) {
          if (Response.error) {
            setAttachmentCommentsLoad(false);
            setIsFetchCommentsLoader(false);
            showNotifications();
          } else {
            let unsortedComments = Response.data.taskComment;

            unsortedComments.sort((a, b) => a.id - b.id);
            // setTaskComments(unsortedComments);
            window.scroll({
              top: document.body.offsetHeight,
              left: 0,
              behavior: "smooth"
            });
            fetchTaskActivity(unsortedComments);

            // setAttachmentComments(commentsWithAttachment);
            setIsFetchCommentsLoader(false);
            setAttachmentCommentsLoad(false);
          }
        }
      }
    } catch (e) {
      console.log(e);
      showNotifications();
    }
  };
  const fetchTaskActivity = async comments => {
    try {
      if (taskId) {
        // pass type: 1 for task duedate and checked change activity only
        const Response = await getTaskActivity(taskId, 1);
        if (Response) {
          if (Response.error) {
            showNotifications();
          }
          if (Response.data) {
            setTaskActivityData(
              Response.data ? Response.data[0]?.taskActivity : []
            );
            handleMergeActivity(
              comments,
              Response.data ? Response.data[0]?.taskActivity : []
            );
            setIsFetchCommentsLoader(false);
          }
        }
      }
    } catch (e) {
      showNotifications();
    }
  };
  const handleMergeActivity = (taskComments = [], taskActivityDatas = []) => {
    if (taskActivityDatas || taskComments) {
      let activity = [...taskActivityDatas, ...taskComments];
      activity.sort(function compare(a, b) {
        var dateA = new Date(a.updatedAt);
        var dateB = new Date(b.updatedAt);
        return dateA - dateB;
      });
      //
      setIsSyncing(false);
      setActivityHistory(activity);
    }
  };
  /* Comment end */

  /* task APi */
  const doGetTaskDetails = async taskId => {
    if (taskId) {
      try {
        setIsFetchDetailsLoader(true);
        const response = await getTaskDetails(taskId);

        if (response) {
          setIsFetchDetailsLoader(false);
          if (response.error) {
            setIsFetchDetailsLoader(false);
            // some thing went wrong
            showNotifications();
            handleResponse(response.error.message);
            return { status: "error", message: response.error.message };
          }
          if (response.data) {
            // console.log(response.data);
            // setTaskDetailsData(response.data)
            setIsFetchDetailsLoader(false);
            setTaskDetailsData({ ...response.data });
            if (response.data.project && response.data.project.id) {
              setProjectId(response.data.project.id);
              setIsFetchDetailsLoader(false);
            }
            if (response.data.section && response.data.section.id) {
              setSectionId(response.data.section.id);
              setIsFetchDetailsLoader(false);
            }
            return {
              status: "ok",
              projectId: response.data.project ? response.data.project.id : "",
              sectionId: response.data.section ? response.data.section.id : ""
            };
          }
        }
      } catch (e) {
        showNotifications();
        console.log(e);
        return {
          status: "error",
          message: <IntlMessages id={"task-details.something_went_wrong"} />
        };
      }
    }
  };
  const updateTaskArchive = () => {};
  /* task Socket start */
  const updateTaskCB = data => {
    if (data) {
      try {
        let taskDetailsCurrentData = taskDetailDataRef.current;

        setTaskDetailsData({ ...taskDetailsCurrentData, ...data });
        doCheckDataUpdate(data);
      } catch (e) {
        console.log(e);
      }
    }
  };
  const deleteTaskCB = data => {
    if (data) {
      // console.log(data);
    }
  };
  const moveTaskCB = data => {
    if (data) {
      // console.log(data);
    }
  };
  const reorderTaskCB = data => {
    if (data) {
      // console.log(data);
    }
  };
  const addCollaboratorCB = data => {
    if (data) {
      // console.log(data);
    }
  };
  const archiveTaskCB = data => {
    if (data) {
      let taskDetailsCurrentData = taskDetailDataRef.current;
      setTaskDetailsData({
        ...taskDetailsCurrentData,
        isArchived: data.isArchived
      });
    }
  };

  /* Task socket end */

  const BreadCrumb = ({ prevPage, currentPage, parentTask }) => {
    return (
      <div
        title={prevPage.name}
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          padding: 12,
          backgroundColor: "white",
          margin: "25px 40px 0px ",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          boxShadow: "0px 2px 6px #00000014"
        }}
      >
        <span
          style={{
            textDecoration: "underline",
            fontWeight: 600,
            color: "black",
            cursor: "pointer",
            maxWidth: 180,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block"
          }}
          onClick={() => props.history.push(prevPage.url)}
        >
          {prevPage.name}
        </span>
        <>
          &nbsp; &gt;&nbsp;
          <span
            title={taskDetailsData?.section?.title}
            style={{
              textDecoration: "underline",
              fontWeight: 600,
              color: "black",
              cursor: "pointer",
              maxWidth: 180,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "inline-block"
            }}
            onClick={() => props.history.push(prevPage.url)}
          >
            {taskDetailsData?.section?.title}
          </span>
        </>
        {parentTask && parentTask.id && parentTask.title && (
          <>
            &nbsp; &gt;&nbsp;
            <span
              title={parentTask.title}
              onClick={() => props.history.push(parentTask.url)}
              style={{
                textDecoration: "underline",
                color: "black",
                cursor: "pointer",
                maxWidth: 300,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "inline-block"
              }}
            >
              {parentTask.title}
            </span>
          </>
        )}
        &nbsp; &gt;&nbsp;
        <span
          title={currentPage}
          style={{
            textDecoration: "underline",
            color: "black",
            maxWidth:
              parentTask && parentTask.id && parentTask.title ? "35%" : "75%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block"
          }}
        >
          {currentPage}
        </span>
      </div>
    );
  };

  /* Task update method */

  const doCheckDataUpdate = updatedData => {
    let taskDetailsCurrentData = taskDetailDataRef.current;
    let activityData = [...taskactivityRef.current] || [];
    let isDateDiff = checkIsDateDiff(
      updatedData.scheduledAt,
      taskDetailsCurrentData.scheduledAt
    );
    let taskStatus = checkIsTaskStatusUpdated(
      updatedData.isCompleted,
      taskDetailsCurrentData.isCompleted
    );
    if (isDateDiff) {
      let newActivity = {
        activity_type: "duedatechange",
        id: Math.floor(Math.random() * 101000),
        user: updatedData.editedBy,
        updatedAt: updatedData.updatedAt,
        createdAt: updatedData.updatedAt,
        past_value: taskDetailsCurrentData.scheduledAt,
        value: updatedData.scheduledAt
      };
      activityData.push(newActivity);
    }
    if (taskStatus) {
      let newActivity = {
        activity_type: "checkedchange",
        id: Math.floor(Math.random() * 101000),
        user: updatedData.editedBy,
        updatedAt: updatedData.updatedAt,
        createdAt: updatedData.updatedAt,
        past_value: null,
        value: updatedData.isCompleted ? "completed" : "uncompleted"
      };
      activityData.push(newActivity);
    }
    setActivityHistory(activityData);
  };
  const checkIsTaskStatusUpdated = (newState, currenState) => {
    if (newState !== currenState) {
      return true;
    }
    return false;
  };
  const checkIsDateDiff = (newDate, oldDate) => {
    if (newDate && oldDate) {
      const present = moment(oldDate);
      const endDate = moment(newDate);
      let isDateDiff = present.diff(endDate);
      if (isDateDiff && isDateDiff !== 0) {
        return true;
      }
    }
    return false;
  };
  const updateTaskAssign = async (taskAssignedUser, handleResponse) => {
    let userArr = taskAssignedUser.hasOwnProperty("user")
      ? taskAssignedUser["user"]
      : [];

    let userIdArr = [];
    if (userArr.length > 0) {
      await Promise.all(
        userArr.map(item => {
          if (item) {
            userIdArr.push(item.id);
          }
        })
      );
    }

    let userIds = userIdArr.join(",");

    let payload = {
      project_id: projectId.toString(),
      user_id: userIds ? userIds : "",
      roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
    };

    doUpdateTask(payload, handleResponse);
  };
  const updateTaskPriority = async (priority, handleResponse) => {
    let payload = {
      priority
    };
    doUpdateTask(payload, handleResponse ? handleResponse : () => {});
  };
  const updateTaskLabels = async (labels = [], handleResponse) => {
    const id = labels && labels.map(id => id.id).toString();
    let payload = {
      label_ids: id
    };
    doUpdateTask(payload, handleResponse ? handleResponse : () => {});
  };
  const updateTaskSchedule = async (scheduledAt, handleResponse) => {
    let payload = {
      scheduledAt: scheduledAt ? new Date(scheduledAt).toString() : ""
    };
    doUpdateTask(payload, handleResponse ? handleResponse : () => {});
  };
  const updateIsCompleted = async handleResponse => {
    try {
      const checked = !taskDetailsData.isCompleted;
      let payload = {
        project_id: projectId && projectId.toString(),
        section_id: sectionId ? sectionId.toString() : "",
        isCompleted: checked,
        roomname: `${taskDetailsSocketRoomPrefix}${taskId}`,
        task_id: taskId.toString()
      };
      doUpdateTask(payload, handleResponse ? handleResponse : () => {});
    } catch (e) {
      console.log(e);
    }
  };

  const doUpdateTask = (reqPayload, handleResponse) => {
    try {
      if (reqPayload) {
        let payload = { ...reqPayload };

        if (!reqPayload.project_id) {
          payload = {
            ...payload,
            project_id: projectId ? projectId.toString() : ""
          };
        }
        if (!reqPayload.section_id) {
          payload = {
            ...payload,
            section_id: sectionId ? sectionId.toString() : ""
          };
        }
        if (!reqPayload.reminderAt && taskDetailsData.reminderAt) {
          payload = { ...payload, reminderAt: taskDetailsData.reminderAt };
        }
        if (
          !reqPayload.hasOwnProperty("scheduledAt") &&
          taskDetailsData.scheduledAt
        ) {
          payload = { ...payload, scheduledAt: taskDetailsData.scheduledAt };
        }
        if (!reqPayload.roomname) {
          payload.roomname = `${taskDetailsSocketRoomPrefix}${taskId}`;
        }
        if (!reqPayload.task_id) {
          payload.task_id = taskId.toString();
        }

        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            handleResponse({
              status: cb.status
            });
            // fetchComments(false);
          }
          //  else {
          //   handleResponse({
          //     status: cb.status
          //   });
          //   /**
          //    * @TODO
          //    * Handle error callback from socket
          //    * sample error cb: { status: "Failed", message: "error message" };
          //    */
          //   // showNotifications();
          // }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const doArchiveTask = async () => {
    try {
      if (taskId) {
        let payload = {
          roomname: `${taskDetailsSocketRoomPrefix}${taskId}`,
          task_id: taskId.toString()
        };
        archiveTask(payload, cb => {
          if (cb.status == "Ok") {
            showNotifications(cb.message, "success");
          } else {
            if (cb.status || cb.message) {
              handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            showNotifications();
          }
        });
      }
    } catch (e) {}
  };
  const doDeleteTask = async () => {
    try {
      let payload = {
        project_id: projectId.toString(),
        isDeleted: true,
        task_id: taskId.toString(),
        roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
      };
      deleteTask(payload, cb => {
        if (cb.status == "Ok") {
          showNotifications(
            <IntlMessages id={"task-details.task_deleted_successfully"} />,
            "success"
          );
          props.history.push(`/project/${projectId}`);
        } else {
          if (cb.status || cb.message) {
            handleResponse(cb.message);
          }
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
          showNotifications();
        }
      });
    } catch (e) {}

    // const Response = await deleteTodo(id, projectId);
    // if (Response) {
    //   if (Response.error) {
    //
    //   }
    //   if (Response.data) {
    //
    //     if (Response.data.isDeleted) {
    //       removeTaskFromList(Response.data.id);
    //       props.showNotifications("Task deleted");
    //     }
    //   }
    // }
  };
  /* Task update method end */
  /* Project method */

  const updateArchiveProject = async () => {
    try {
      if (taskDetailsData.project && taskDetailsData.project.id) {
        setIsArchivedLoader(true);
        const Response = await updateProject(
          {
            isArchived: !taskDetailsData.project.isArchived
          },
          taskDetailsData.project.id
        );
        if (Response) {
          if (Response.error) {
            handleResponse(Response.error.message);
            showNotifications();
          }

          if (Response.data) {
            updateProjectToRedux(Response.data);
            setTaskDetailsData({
              ...taskDetailsData,
              project: {
                ...taskDetailsData.project,
                isArchived: Response.data.isArchived
              }
            });
            showNotifications(
              `Project ${
                Response?.data?.isArchived ? "archived" : "unarchived"
              } successfully`,
              "success"
            );
          }
          setIsArchivedLoader(false);
        }
      }
    } catch (e) {
      setIsArchivedLoader(false);
    }
  };
  const handleArchiveWorkpsace = async () => {
    if (props?.auth?.user?.businessId) {
      try {
        setIsArchivedLoader(true);
        const response = await toggleArchive(props?.auth?.user?.businessId);
        if (response) {
          if (response.message) {
            setIsArchivedLoader(false);

            if (!checkIsArchivedMessage(response.message)) {
              props.update_business_archive(false);
            }
          }
          if (response.error) {
            handleResponse(response.error.message);
            setIsArchivedLoader(false);
            showNotifications();
          }
        }
      } catch (e) {
        setIsArchivedLoader(false);
        showNotifications();
      }
    }
  };
  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        // status:action.alert.status, message:action.alert.message,action:'open_businesslist'

        props.open_workpsace_alert({
          status: true,
          message: "workspace"
        });
      }
      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
        props.remove_Project(taskDetailsData.project.id);
      }
      if (
        message.toLocaleLowerCase() == "Project not found".toLocaleLowerCase()
      ) {
        props.open_project_alert({
          status: true,
          message: "project"
        });
        props.remove_Project(taskDetailsData.project.id);
      }
      if (message.toLocaleLowerCase() == "Task not found".toLocaleLowerCase()) {
        setIsTaskAlert(true);
      }
    } catch (e) {}
  };
  const updateProjectToRedux = data => {
    props.update_projects_existing({ projects: data });
  };
  /* Subtask Sockets */

  const addSubTaskCB = async data => {
    let subtasks = checkListRef.current;

    if (subtasks && subtasks?.length > 0) {
      await Promise.all(
        subtasks.map((s, i) => {
          if (s.id == data.checklist.id) {
            if (s.subTask) {
              isSubtaskExist(s.subTask, data) && s.subTask.push(data);
            } else {
              s.subTask = [data];
            }
          }
        })
      );
    } else {
    }
    setSubtaskList([...subtasks]);
    checkListRef.current = [...subtasks];
  };

  const updateSubTaskCB = async data => {
    try {
      let subTaskData = checkListRef.current;

      if (subTaskData) {
        if (data.isDeleted) {
          await Promise.all(
            subTaskData.map((c, i) => {
              return c.subTask.map((s, j) => {
                if (s.id == data.id) {
                  subTaskData[i].subTask.splice(j, 1);
                }
              });
            })
          );
        } else {
          await Promise.all(
            subTaskData &&
              subTaskData.map((c, i) => {
                return (
                  c?.subTask &&
                  c?.subTask?.length > 0 &&
                  c?.subTask.map((s, j) => {
                    if (s.id == data.id) {
                      subTaskData[i].subTask[j] = {
                        ...subTaskData[i].subTask[j],
                        ...data
                      };
                    }
                  })
                );
              })
          );
        }
        setSubtaskList([...subTaskData]);
        checkListRef.current = [...subTaskData];
      }
    } catch (e) {}
  };
  const addChecklistCB = async data => {
    let subTaskData = checkListRef.current;
    if (subTaskData.length > 0) {
      if (isChecklistExist(subTaskData, data)) {
        setSubtaskList([...subTaskData, ...[data]]);
        checkListRef.current = [...subTaskData, ...[data]];
      }
    } else {
      setSubtaskList([...[data]]);
      checkListRef.current = [...[data]];
    }
  };

  const updateChecklistCB = async data => {
    let subTaskData = checkListRef.current;
    if (subTaskData) {
      await Promise.all(
        subTaskData.map((c, i) => {
          if (c.id == data.id) {
            subTaskData[i] = {
              ...subTaskData[i],
              ...data
            };
          }
        })
      );
      setSubtaskList([...subTaskData]);
      checkListRef.current = [...subTaskData];
    }
  };

  const deleteChecklistCB = async data => {
    let subTaskData = checkListRef.current;
    if (subTaskData) {
      await Promise.all(
        subTaskData.map((c, i) => {
          if (c.id == data.checklist_id) {
            subTaskData.splice(i, 1);
          }
        })
      );
      setSubtaskList([...subTaskData]);
      checkListRef.current = [...subTaskData];
    }
  };
  const deleteTaskcommentEmojiCB = data => {
    if (data) {
      try {
        let activityData = [...taskactivityRef.current];
        activityData &&
          activityData.map((comment, commentIndex) => {
            if (
              comment &&
              (comment.hasOwnProperty("comment") ||
                comment.hasOwnProperty("commentBy")) &&
              comment?.id == data?.taskcomment.id
            ) {
              var emojiArr = comment.commentEmoji
                ? comment.commentEmoji.filter(d => parseInt(data.id) !== d.id)
                : [];
              activityData[commentIndex].commentEmoji = emojiArr;
            }
          });
        setActivityHistory(activityData);
      } catch (e) {
        console.log(e);
      }
    }
  };
  const addTaskcommentEmojiCB = data => {
    if (data) {
      try {
        let activityData = [...taskactivityRef.current];
        activityData &&
          activityData.map((comment, commentIndex) => {
            if (
              comment &&
              (comment.hasOwnProperty("comment") ||
                comment.hasOwnProperty("commentBy")) &&
              comment?.id == data?.taskcomment.id
            ) {
              var emojiArr = comment.commentEmoji ? comment.commentEmoji : [];

              emojiArr.push(data);
              activityData[commentIndex].commentEmoji = [...emojiArr];
            }
          });
        setActivityHistory(activityData);
      } catch (e) {
        console.log(e);
      }
    }
  };
  const updateCommentCB = async data => {
    if (props.auth.user.id !== data.user.id) {
      fetchComments(false);
    }
  };
  const addTaskCommentCB = async data => {
    if (data) {
      let activityData = [...taskactivityRef.current];
      activityData.push(data);
      setActivityHistory(activityData);
    }
  };
  const deleteTaskCommentCB = async data => {
    try {
      let activityData = [...taskactivityRef.current];

      if (data && data.id && activityData) {
        let updateActivityData = activityData;
        activityData.map((a, i) => {
          if (
            a.id == parseInt(data.id) &&
            (a.hasOwnProperty("comment") ||
              a.hasOwnProperty("commentBy") ||
              a.hasOwnProperty("commentMeta"))
          ) {
            updateActivityData.splice(i, 1);
          }
        });

        setActivityHistory(updateActivityData);
      }
    } catch (e) {}
  };
  const updateTaskCommentCB = async data => {
    let activityData = [...taskactivityRef.current];
    if (data && data.id && activityData) {
      activityData &&
        activityData.map((a, index) => {
          if (a.id == data.id) {
            activityData[index] = { ...a, ...data };
          }
        });

      setActivityHistory(activityData);
    }
  };
  const subtaskAssignCB = async data => {
    retrieveSubtask(false);
  };
  const addNewSubtask = async (checklistId, subtaskTitle) => {
    if (subtaskTitle != "") {
      let payload = {
        project_id: projectId.toString(),
        task_id: taskId.toString(),
        checklist_id: checklistId.toString(),
        title: subtaskTitle,
        orderIndex: 1,
        roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
      };

      addSubTask(payload, cb => {
        // if (cb.status == "Ok") {
        //   // fetchComments(false);
        // } else {
        //   /**
        //    * @TODO
        //    * Handle error callback from socket
        //    * sample error cb: { status: "Failed", message: "error message" };
        //    */
        // }
      });
      updateComment(
        {
          task_id: taskId.toString(),
          roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
        },
        cb => {
          // if (cb.status == "Ok") {
          // } else {
          // }
        }
      );
    }
  };

  const doUpdateSubtask = async (
    reqObj,
    subtaskId,
    checklistId,
    handleResponse
  ) => {
    try {
      let data = {
        project_id: projectId.toString(),
        task_id: subtaskId.toString(),
        checklist_id: checklistId.toString(),
        roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
      };
      let payload = { ...data, ...reqObj };

      updateSubTask(payload, cb => {
        if (cb.status == "Ok") {
          // fetchComments(false);
          handleResponse({ status: "Ok" });
        } else {
          handleResponse({
            status: cb.status
          });
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
        }
      });
    } catch (e) {}
  };

  const doDeleteSubtask = async (subtaskId, checklistId, handleResponse) => {
    let payload = {
      isDeleted: true
    };

    doUpdateSubtask(payload, subtaskId, checklistId, handleResponse);
  };
  const updateChecklistItem = async (
    title,
    subtaskId,
    checklistId,
    handleResponse
  ) => {
    let payload = {
      title: title
    };

    doUpdateSubtask(payload, subtaskId, checklistId, handleResponse);
  };
  /* Checklist */
  const addnewCheckList = async (ChecklistInput, handleResponse) => {
    if (ChecklistInput !== "") {
      try {
        setChecklistLoader(true);
        /**
         * Add new checklist through socket
         */

        let payload = {
          title: ChecklistInput,
          project_id: projectId.toString(),
          task_id: taskId.toString(),
          roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
        };
        addChecklist(payload, cb => {
          handleResponse({ status: cb.status });
          // if (cb.status == "Ok") {
          //   // fetchComments(false);
          // } else {
          //   /**
          //    * @TODO
          //    * Handle error callback from socket
          //    * sample error cb: { status: "Failed", message: "error message" };
          //    */
          // }
        });
      } catch (e) {}
    }
  };
  const doUpdateChecklistTitle = async (title, id) => {
    if (id && title) {
      try {
        let payload = {
          title,
          checklist_id: id.toString(),
          roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
        };
        updateChecklist(payload, cb => {
          // if (cb.status == "Ok") {
          //   // fetchComments(false);
          // } else {
          //   /**
          //    * @TODO
          //    * Handle error callback from socket
          //    * sample error cb: { status: "Failed", message: "error message" };
          //    */
          // }
        });
      } catch (e) {}
    }
  };
  const doDeleteChecklistTitle = async id => {
    if (id) {
      try {
        let payload = {
          checklist_id: id.toString(),
          roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
        };
        deleteChecklist(payload, cb => {
          if (cb.status == "Ok") {
            // fetchComments(false);
          } else {
            showNotifications();
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
          }
        });
      } catch (e) {}
    }
  };

  /* Subtask methods */
  const updateIsCompletedSubTask = async (
    subtaskId,
    checked,
    checklistId,
    handleResponse
  ) => {
    let payload = {
      isCompleted: !checked
    };
    doUpdateSubtask(payload, subtaskId, checklistId, handleResponse);
  };

  const isUniqueSubtask = (subtaskArr, key) => {
    const arrayUniqueByKey = [
      ...new Map(
        subtaskArr && subtaskArr.map(item => [item[key], item])
      ).values()
    ];

    if (arrayUniqueByKey) {
      return arrayUniqueByKey;
    }
    return false;
  };
  const isChecklistExist = (oldArray, newData) => {
    var isUnique = true;
    if (oldArray) {
      oldArray.map((checklist, i) => {
        if (checklist.id == newData.id) {
          isUnique = false;
        }
      });

      return isUnique;
    }
  };
  const isSubtaskExist = (oldArray, newData) => {
    var isUnique = true;
    if (oldArray) {
      oldArray.map((subtask, i) => {
        if (subtask.id == newData.id) {
          isUnique = false;
        }
      });

      return isUnique;
    }
  };

  /* Custom alert */
  const closeCustomAlert = () => {
    setShowDeleteCommentAlert({
      status: false,
      action: "",
      messageType: "",
      id: "",
      title: ""
    });
  };

  const openCustomAlert = (status = true, action, messageType, id, title) => {
    setShowDeleteCommentAlert({
      status: status,
      action: action,
      messageType: messageType,
      id: id,
      title: title
    });
  };
  const handleCustomAlertOnConfirm = () => {
    if (
      showDeleteCommentAlert.status &&
      showDeleteCommentAlert.action == "delete" &&
      showDeleteCommentAlert.messageType == "comment" &&
      showDeleteCommentAlert.id
    ) {
      handleDeleteComment(showDeleteCommentAlert.id, taskId);
      closeCustomAlert();
    }
    if (
      showDeleteCommentAlert.status &&
      showDeleteCommentAlert.action == "delete" &&
      showDeleteCommentAlert.messageType == "checklist"
    ) {
      // doDeleteChecklistTitle(showDeleteCommentAlert.id, taskId);
      doDeleteChecklistTitle(showDeleteCommentAlert.id);

      closeCustomAlert();
    }
    if (
      showDeleteCommentAlert.status &&
      showDeleteCommentAlert.action == "delete" &&
      showDeleteCommentAlert.messageType == "checklist item"
    ) {
      doDeleteSubtask(
        showDeleteCommentAlert.id.subtaskId,
        showDeleteCommentAlert.id.checklistId,
        handlePostRequestResponse
      );
      closeCustomAlert();
    }
    if (
      showDeleteCommentAlert.status &&
      showDeleteCommentAlert.action == "delete" &&
      showDeleteCommentAlert.messageType == "task"
    ) {
      doDeleteTask();
      closeCustomAlert();
    }
  };
  const handleCustomAlertOnCancel = () => {
    if (
      showDeleteCommentAlert.status &&
      showDeleteCommentAlert.action == "delete"
    ) {
      closeCustomAlert();
    }
  };
  /* comment */
  const handleAddComment = async (
    newComment,
    handleResponse,
    commentAttachmentsUrl = []
  ) => {
    if (newComment) {
      let updateComment = newComment;

      let mentionRegex = /<a\s+(?:[^>]*?\s+)?href=(["|']#mention-user-(\d+)["|'])/g;
      let regexResults = newComment.match(mentionRegex);
      if (regexResults?.length > 0) {
        regexResults &&
          regexResults.map((elemnt, i) => {
            let dataId = elemnt.match(/(\d+)/);
            if (dataId && dataId?.length > 0) {
              updateComment = updateComment.replace(
                elemnt,
                elemnt + ` data-id="${dataId[0]}"`
              );
            }
          });
      }
      // let commentDomData=document.createElement('div')
      // commentDomData.innerHTML=newComment
      // let newDOMFIGURE=document.createElement('div')
      //   var datta=commentDomData.getElementsByTagName('figure')
      // datta && datta.map((figEl)=>{
      //   let parsedData=JSON.parse(figEl.dataset.trixAttachment)
      // })
      //
      // let newDomFigureSTring=newDOMFIGURE.outerHTML.toString()
      //
      // let urlsMatcharr=newDomFigureSTring.match(/(https?:\/\/[^ ]*)/)
      // let isUrlReplaced=false
      // urlsMatcharr && urlsMatcharr.map((data)=>{
      //   let formatedData=data.replace(/&amp;/g, '&')
      //   commentAttachmentsUrl && commentAttachmentsUrl.map((element)=>{
      //     if(formatedData==element.url){
      //       newDomFigureSTring.replace(data,element.keyId);
      //       isUrlReplaced=true
      //
      //   }
      //   if (formatedData.replace('"','')==element.url){
      //
      //     newDomFigureSTring=newDomFigureSTring.replace(data,element.keyId+'"');
      //     isUrlReplaced=true
      //
      //   }

      //   })
      // })
      //
      // if(isUrlReplaced){
      //   newDOMFIGURE.innerHTML=newDomFigureSTring
      //
      // }

      //

      //
      // if(commentAttachmentsUrl){
      //   commentAttachmentsUrl.map((data)=>{
      //     if(data.url){
      //
      //       let format=data.url.replace(/&amp;/g,'&')
      //
      //       commentData.replace(format,data.keyId)

      //

      //     }
      //   })
      // }
      //
      // console.log(commentData)

      let payload = {
        comment: updateComment,
        task_id: taskId.toString(),
        project_id: projectId.toString(),
        roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
      };
      setIsUploadCommentLoader(true);

      addTaskcomment(payload, cb => {
        if (cb.status == "Ok") {
          setIsUploadCommentLoader(false);

          handleResponse({
            status: cb.status
          });
        }
        // else {
        //   setIsUploadCommentLoader(false);
        //   handleResponse({
        //     status: cb.status
        //   });
        // }
      });
      if (false) {
        const Response = await addcomment({
          comment: newComment,
          taskId: taskId,
          projectId: projectId.toString()
        });

        if (Response) {
          if (Response.error) {
            if (handleResponse) {
              handleResponse({
                status: "Failed",
                message: Response.error.message
              });
              handleResponse({
                status: Response.error.message
              });
            }
          } else {
            setActivityHistory([Response.data, ...ActivityHistory]);
            handleResponse({
              status: "Ok"
            });
            // fetchComments(false);
            updateComment(payload, cb => {
              if (cb.status == "Ok") {
              } else {
                handleResponse({
                  status: cb.status
                });
                /**
                 * @TODO
                 * Handle error callback from socket
                 * sample error cb: { status: "Failed", message: "error message" };
                 */
              }
            });
          }

          setIsUploadCommentLoader(false);
        }
      }
    }
    // setCommentInput(!commentInput);
  };
  const doRefreshComment = () => {
    fetchComments(false);
    let payload = {
      comment: null,
      task_id: taskId.toString(),
      projectId: projectId.toString(),
      roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
    };
    updateComment(payload, cb => {
      if (cb.status == "Ok") {
      } else {
        handleResponse({
          status: cb.status
        });
        /**
         * @TODO
         * Handle error callback from socket
         * sample error cb: { status: "Failed", message: "error message" };
         */
      }
    });
  };
  // update comment

  // let payload={
  //   comment:'UPDATES',
  //   comment_id:commentId.toString(),
  //   project_id: projectId.toString(),
  //   task_id: taskId.toString(),
  //   roomname: `${taskDetailsSocketRoomPrefix}${taskId}`,
  // }
  //
  // updateTaskcomment(payload, cb => {})

  const handleDeleteComment = async (commentId, taskId) => {
    try {
      if ((commentId, taskId)) {
        let payload = {
          comment_id: commentId.toString(),
          project_id: projectId.toString(),
          task_id: taskId.toString(),
          roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
        };
        deleteTaskcomment(payload, cb => {
          if (cb.status == "Ok") {
            setIsUploadCommentLoader(false);
            handleResponse({
              status: cb.status
            });
          }
          // else {
          //   setIsUploadCommentLoader(false);
          //   handleResponse({
          //     status: cb.status
          //   });
          // }
        });
        // const Response = await deleteComment(
        //   {
        //     project_id: projectId.toString(),
        //     task_id: taskId.toString()
        //   },
        //   commentId
        // );

        // if (Response) {
        //   if (Response.error) {
        //     showNotifications();
        //   }
        //   if (Response.message) {
        //     let comments = taskComments;
        //     comments.map((data, i) => {
        //       if (data.id == commentId) {
        //         comments.splice(i, 1);
        //       }
        //     });
        //     let activityHistory = ActivityHistory;

        //     activityHistory.map((data, i) => {
        //       if (data.id == commentId) {
        //         if (
        //           data.hasOwnProperty("comment") ||
        //           hasOwnProperty("commentBy")
        //         ) {
        //           activityHistory.splice(i, 1);
        //         }
        //       }
        //     });
        //     // props.updateCommentCount(task.id, "delete_comment");
        //     setActivityHistory(activityHistory);
        //     setTaskComments([...comments]);
        //     let payload = {
        //       roomname: `${taskDetailsSocketRoomPrefix}${taskId}`
        //     };
        //     showNotifications(Response.message);
        //     fetchComments(false);
        //     updateComment(payload, cb => {
        //       if (cb.status == "Ok") {
        //       } else {
        //         /**
        //          * @TODO
        //          * Handle error callback from socket
        //          * sample error cb: { status: "Failed", message: "error message" };
        //          */
        //       }
        //     });
        //   }
        // }
      }
    } catch (e) {}
  };
  const checkArchive = () => {
    if (props?.auth?.user?.isArchived) {
      return (
        <>
          <div className="workspace-archived">
            <div className="workspace-archived-border flex-x fs-14">
              This workspace has been&nbsp;archived.&nbsp;{""}
              {props?.auth?.user?.businessId &&
                props?.auth?.user?.isBusinessOwner && (
                  <>
                    Click here to&nbsp;
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        display: "flex"
                      }}
                      onClick={handleArchiveWorkpsace}
                    >
                      <b>Unarchive</b>{" "}
                      {isArchivedLoader && (
                        <Loader height="18px" width="15px" />
                      )}
                    </div>{" "}
                  </>
                )}
            </div>
          </div>
        </>
      );
    }
    if (taskDetailsData && taskDetailsData?.project?.isArchived) {
      return (
        <div
          className="workspace-archived"
          style={{ paddingTop: 25, paddingBottom: 32 }}
        >
          <div className="workspace-archived-border flex-x fs-14">
            <IntlMessages id={"archived.archived"} />
            {
              <>
                {taskDetailsData?.project?.isOwner && (
                  <div style={{ display: "inline-flex" }}>
                    <IntlMessages id={"project.clickhereto"} />
                    &nbsp;
                    <b
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                      onClick={updateArchiveProject}
                    >
                      <IntlMessages id={"project.Unarchive"} />
                    </b>{" "}
                    {isArchivedLoader && <Loader height="18px" width="15px" />}
                  </div>
                )}
              </>
            }
          </div>
        </div>
      );
    }
    if (taskDetailsData && taskDetailsData?.isArchived) {
      return (
        <div
          className="workspace-archived"
          style={{ paddingTop: 25, paddingBottom: 32 }}
        >
          <div className="workspace-archived-border flex-x fs-14">
            <IntlMessages id={"archived.archived"} />

            {
              <>
                <div style={{ display: "inline-flex" }}>
                  <IntlMessages id={"project.clickhereto"} />
                  &nbsp;
                  <b
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer"
                    }}
                    onClick={doArchiveTask}
                  >
                    <IntlMessages id={"project.Unarchive"} />
                  </b>{" "}
                  {isArchivedLoader && <Loader height="18px" width="15px" />}
                </div>
              </>
            }
          </div>
        </div>
      );
    }
  };
  return (
    <div className="row" ref={taskDetailscrollRef}>
      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
      <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12">
        {taskDetailsData ? (
          // false
          <BreadCrumb
            prevPage={{
              url: `/project/${taskDetailsData.project.id}`,
              name: taskDetailsData.project.name,
              id: taskDetailsData.project.id
            }}
            currentPage={taskDetailsData.title}
            parentTask={
              taskDetailsData?.parentTask
                ? {
                    ...taskDetailsData?.parentTask,
                    url: `/task-details/${taskDetailsData.parentTask.id}`
                  }
                : false
            }
          />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 48,
              width: "100%",
              margin: "25px 0px 0px"
            }}
          >
            <div
              style={{
                textAlign: "center",
                height: 54,
                padding: "14px 0px",
                margin: "0px 40px ",
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0px 2px 6px #00000014",
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4
              }}
            >
              <OneLineShimmer />
            </div>
          </div>
        )}
      </div>
      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>

      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
      <div
        className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"
        style={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          padding: "0px 0px",
          marginBottom: "260px"
        }}
      >
        <div
          ref={mainDivRef}
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            boxShadow: "0px 0px 6px #00000029",
            borderRadius: 4,
            minHeight: "80vh"
          }}
        >
          <FileDrop
          // onFrameDragEnter={event => {
          //   setCommentInput(true);
          //   setIsTrixDragEnter(true);
          //   // console.log(event);
          // }}
          // onFrameDragLeave={event => {
          //   // setCommentInput(false);
          //   setIsTrixDragEnter(false);
          // }}
          // onFrameDrop={event => {
          //   // setCommentInput(false);
          //   // debugger
          //   console.log(event, "event");
          //   setIsTrixDragEnter(false);
          // }}
          // onDragOver={event => {
          //   // setIsTrixDragEnter (true)
          // }}
          // onDragLeave={event => {
          //   // setIsTrixDragEnter (false)
          // }}
          // // onDrop={(files, event) => {
          // //   var myObject = files;
          // //   let valuesArray = Object.values(myObject);
          // //   setAllFileDrop(valuesArray[0]);
          // //   setIsDrag(true);
          // //   // setIsTaskCommentShimmer(true);
          // // }}
          // onDrop={(files, event) => {
          //   setAllFileDrop(Object.values(files)[0]);
          //   setIsDrag(true);
          // }}
          >
            {checkArchive()}
            <div
              className="TaskDetailsContainer"
              style={{ margin: "0px 65px" }}
            >
              {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <TaskTitleCard
                taskTitleUpdatedValue={taskTitleUpdatedValue}
                setTaskTitleUpdatedValue={setTaskTitleUpdatedValue}
                isEditTask={isEditTask}
                setIsEditTask={setIsEditTask}
                taskTitle={taskDetailsData.title}
                taskIsCompleted={taskDetailsData.isCompleted}
                updateIsCompleted={updateIsCompleted}
              />
              <div
                id="popover"
                onClick={() => {
                  setIsTaskDetailsPopover(!isTaskDetailsPopover);
                }}
              >
                <img
                  src={threeDot_Icon}
                  style={{
                    height: 6,
                    width: 22,
                    position: "absolute",
                    right: "30px",
                    cursor: "pointer"
                  }}
                ></img>
              </div>
            </div> */}

              <TaskDetailsCard
                updateTaskSchedule={updateTaskSchedule}
                updateTaskPriority={updateTaskPriority}
                updateTaskLabels={updateTaskLabels}
                isFetchDetailsLoader={isFetchDetailsLoader}
                updateTaskLabelName={labels => {
                  setTaskDetailsData({ ...taskDetailsData, labels: labels });
                }}
                collaboratorListArr={collaboratorListArr}
                openCustomAlert={openCustomAlert}
                showNotifications={showNotifications}
                doArchiveTask={doArchiveTask}
                isAssignTaskModal={isAssignTaskModal}
                setIsAssignTaskModal={() =>
                  setIsAssignTaskModal(!isAssignTaskModal)
                }
                AllowAddPreviousDateTask={
                  props.auth.user?.userSetting?.AllowAddPreviousDateTask
                }
                updateIsCompleted={updateIsCompleted}
                taskDetails={taskDetailsData}
                setIsEditTask={setIsEditTask}
                isEditTask={isEditTask}
                updateTaskDetails={doUpdateTask}
                isAddChecklist={isAddChecklist}
                setIsAddChecklist={setIsAddChecklist}
                history={props.history}
                PRIMARY_COLOR={PRIMARY_COLOR}
              />

              {!taskDetailsData.isSubTask && (
                <TaskChecklistCard
                  doUpdateChecklistTitle={doUpdateChecklistTitle}
                  showNotifications={showNotifications}
                  isLoaderChecklist={isLoaderChecklist}
                  isAddChecklist={isAddChecklist}
                  setIsAddChecklist={setIsAddChecklist}
                  addnewCheckList={addnewCheckList}
                  subtaskList={subtaskList}
                  addNewSubtask={addNewSubtask}
                  updateIsCompletedSubTask={updateIsCompletedSubTask}
                  openCustomAlert={openCustomAlert}
                  updateChecklistItem={updateChecklistItem}
                  history={{ ...props.history }}
                />
              )}

              <TaskCommentCard
                commentInput={commentInput}
                setCommentInput={setCommentInput}
                isTrixDragEnter={isTrixDragEnter}
                setIsTrixDragEnter={setIsTrixDragEnter}
                isSyncing={isSyncing}
                setActivityHistory={setActivityHistory}
                collaboratorListArr={collaboratorListArr}
                doRefreshComment={doRefreshComment}
                showNotifications={showNotifications}
                setIsFetchCommentsLoader={setIsFetchCommentsLoader}
                isFetchCommentsLoader={isFetchCommentsLoader}
                openCustomAlert={openCustomAlert}
                currentUser={props.auth.user}
                setTaskComments={setTaskComments}
                ActivityHistory={ActivityHistory}
                taskComments={taskComments}
                handleAddComment={handleAddComment}
                taskId={taskId}
                projectId={projectId}
                PRIMARY_COLOR={PRIMARY_COLOR}
                mainDivRef={mainDivRef}
                setAllFileDrop={setAllFileDrop}
                setIsDrag={setIsDrag}
                isDrag={isDrag}
                allFileDrop={allFileDrop}
              />
            </div>
          </FileDrop>
        </div>
      </div>

      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>

      {/* <TaskDetailsPopoverMenu
        isTaskDetailsPopover={isTaskDetailsPopover}
        toggleTaskDetailsMenu={() => toggleTaskDetailsMenu()}
      /> */}
      <CustomAlert
        CustomMessage={showDeleteCommentAlert.title}
        action={showDeleteCommentAlert.action}
        messageType={showDeleteCommentAlert.messageType}
        show={showDeleteCommentAlert.status}
        onConfirm={handleCustomAlertOnConfirm}
        PRIMARY_COLOR={PRIMARY_COLOR}
        onCancel={handleCustomAlertOnCancel}
      />
      <TaskAssignModal
        projectId={projectId}
        taskId={taskId}
        isOpen={isAssignTaskModal}
        toggle={() => {
          setIsAssignTaskModal(!isAssignTaskModal);
        }}
        collaboratorList={props.collaboratorList}
        taskAssign={taskDetailsData && taskDetailsData.taskAssign}
        doUpdateTaskAssign={updateTaskAssign}
        updateTaskAssign={(data, handlePostRequestResponse) => {
          setIsAssignTaskModal(!isAssignTaskModal);
          updateTaskAssign(data, handlePostRequestResponse);
        }}
        PRIMARY_COLOR={PRIMARY_COLOR}
      />
      <TaskAlert
        show={isTaskAlert}
        onConfirm={() => {
          setIsTaskAlert(!isTaskAlert);
          props.history.push("/today");
        }}
        onCancel={() => {
          setIsTaskAlert(!isTaskAlert);
        }}
        PRIMARY_COLOR={PRIMARY_COLOR}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    businessId: state.auth.user?.businessId,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    PRIMARY_COLOR: state.themeChanger.topbarTheme.buttonColor
  };
};

export default connect(mapStateToProps, {
  open_workpsace_alert,
  open_project_alert,
  open_task_alert,
  close_alert,
  update_business_archive,
  update_projects_existing,
  doEnableFileDropEvent,
  doDisableFileDropEvent,
  remove_Project
})(TaskDetailsView);
