/** @format */

const workspaceDropdownAction = {
  OPEN_WORKSPACE_MODAL: "OPEN_WORKSPACE_MODAL",
  CLOSE_WORKSPACE_MODAL: "CLOSE_WORKSPACE_MODAL",

  open_workspace_modal: () => {
    return {
      type: workspaceDropdownAction.OPEN_WORKSPACE_MODAL,
      isOpen: true
    };
  },
  close_workspace_modal: () => {
    return {
      type: workspaceDropdownAction.CLOSE_WORKSPACE_MODAL,
      isOpen: false
    };
  }
};

export default workspaceDropdownAction;
