import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
const ShimmerLoader = props => {
  const StyleSheet = {
    container: {
      border: "0px solid rgba(255, 255, 255, 1)",
      // boxShadow: "0px 0px 20px rgba(0, 0, 0, .1)",
      borderRadius: "4px",
      backgroundColor: "white",
      display: "flex",
      marginBottom: "10px",
      paddingLeft: "10px",
      width: "100%",
      boxShadow: "rgb(0 0 0 / 0%) 0px 0px 20px"
    },
    circle: {
      height: "42px",
      width: "42px",
      background: "#e4e4e4",
      borderRadius: "5px"
    },
    nameline: {
      width: "150px",
      height: "8px",
      alignSelf: "center",
      marginLeft: "10px",
      marginTop: "30px",
      borderRadius: "8px",
      marginBlockStart: "-25px",
      background: "#e4e4e4"

      // backgroundImage: " linear- gradient(to right,#dcdcdc 40 %,#cacaca 50 %,#ffffff 60 %)"
    }
  };
  // const { classes } = props.props;
  let i = 0;
  return (
    <>
      <div key={i++ * 2}>
        <Shimmer>
          <div
            className="flex-x ptb-15 mb-6 border-bottom "
            style={StyleSheet.container}
          >
            <div style={StyleSheet.circle} key={i++} />
            <div style={{ paddingTop: 40 }}>
              <div style={StyleSheet.nameline} key={i++} />
            </div>
          </div>
        </Shimmer>
        <Shimmer>
          <div
            className="flex-x ptb-15 mb-6 border-bottom "
            style={StyleSheet.container}
          >
            <div style={StyleSheet.circle} key={i++} />
            <div style={{ paddingTop: 40 }}>
              <div style={StyleSheet.nameline} key={i++} />
            </div>
          </div>
        </Shimmer>
      </div>
    </>
  );
};
export default ShimmerLoader;
