/** @format */

const profileModelAction = {
  OPEN_PROFILE_MODAL: "OPEN_PROFILE_MODAL",
  CLOSE_PROFILE_MODAL: "CLOSE_PROFILE_MODAL",

  open_profile_modal: data => {
    return {
      type: profileModelAction.OPEN_PROFILE_MODAL,
      isOpen: true,
      profile: data.profile
    };
  },
  close_profile_modal: () => {
    return {
      type: profileModelAction.CLOSE_PROFILE_MODAL,
      isOpen: false,
      profile: null
    };
  }
};

export default profileModelAction;
