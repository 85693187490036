import React, { useEffect, useState } from "react";
import ThemeSettingWrapper from "components/themesetting/themeSetting.style";
import IntlMessages from "util/intlMessages";
import Loader from "components/loader/Loader";
import classNames from "classnames";
import { commentnew, close_Icon } from "helper/constant";
import { getColorCode } from "components/projects/utils/color";
import "./ProjectSideMenu.style.css";
import moment from "moment";
import { getPriorityPath } from "constatnt";
import CustomAlert from "util/CustomAlert";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
const ProjectSideMenu = props => {
  const {
    mini,
    drawerWidth,
    closeSettingDrawer,
    sidebarTheme,
    project,
    doArchiveSection,
    doArchiveTask,
    doDeleteTask,
    PRIMARY_COLOR,
    isDeleteArchiveCustomAlert,
    setIsDeleteArchiveCustomAlert,
    themeSetting: { layout }
  } = props;
  const tabs = ["task", "list"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [sectionArr, setSectionArr] = useState([]);
  const [taskArr, setTaskArr] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [sectionArrTitle, setSectionArrTitle] = useState("");
  const [isLoader, setIsLoader] = useState({
    status: false,
    id: "",
    activeTab: ""
  });
  const [searchQueryResults, setSearchQueryResults] = useState("");

  const sidebar = {
    width: mini ? 0 : drawerWidth,
    "@media (max-width: 575.98px)": {
      width: mini ? 0 : drawerWidth
    }
  };

  useEffect(() => {
    if (!mini && project) {
      let filteredSection =
        project &&
        project?.section?.filter(data => data.isArchived && !data.isDeleted);

      setSectionArr([...filteredSection]);
      let filteredTasks = [];
      project &&
        project.section.map(data => {
          if (data && data.task?.length > 0) {
            let filter = data.task.filter(
              task => task.isArchived && !task.isDeleted
            );
            if (filter && filter.length > 0) {
              filteredTasks.push(filter);
            }
          }
        });

      setTaskArr([...filteredTasks]);
    }
    return () => {
      setSearchInput("");
      setIsLoader({
        status: false,
        id: "",
        activeTab: ""
      });
    };
  }, [mini, project]);
  const toggletab = id => {
    setSearchInput("");
    setActiveTab(id);
    setIsSearch(false);
    setSearchQueryResults([]);
  };
  const handleSearch = searchQuery => {
    setSearchInput(searchQuery);
    if (!searchQuery) {
      setIsSearch(false);
    }
    if (searchQuery.length > 0) {
      setIsSearch(true);
      if (project && project?.section?.length > 0) {
        if (activeTab == "list") {
          let searchResults =
            project &&
            project?.section?.filter(section => {
              return (
                section.isArchived &&
                !section.isDeleted &&
                section.title
                  .toLowerCase()
                  .indexOf(searchQuery.toLowerCase()) !== -1
              );
            });

          setSearchQueryResults([...searchResults]);
        }

        if (activeTab == "task") {
          let filteredTasks = [];
          let map =
            project &&
            project?.section?.forEach(section => {
              let data =
                section?.task?.length > 0 &&
                section?.task.filter(
                  tasks =>
                    tasks.isArchived &&
                    !tasks.isDeleted &&
                    tasks.title
                      .toLowerCase()
                      .indexOf(searchQuery.toLowerCase()) !== -1
                );

              if (data?.length > 0) {
                filteredTasks = [...filteredTasks, ...data.flat()];
              }
              return data;
            });

          setSearchQueryResults([...filteredTasks]);
        }
      }
    } else {
      setIsSearch(false);
    }
  };
  const HandleActiveTabs = tabId => {
    if (activeTab == "task") {
      return (
        <div className="task-container" style={{ padding: 15 }}>
          <div className="form-group">
            <input
              value={searchInput}
              placeholder={InjectPlaceHolderMessage(
                "project.search_task",
                props?.LanguageSwitcher?.language?.languageId
              )}
              className="form-control form-input pa-10"
              onChange={e => handleSearch(e.target.value)}
              style={{ fontWeight: 400 }}
            />
          </div>
          <div className="task-list">
            {isSearch ? (
              <>
                {searchQueryResults?.length > 0 ? (
                  <>
                    {searchQueryResults.map(task => {
                      if (task.isArchived) {
                        return (
                          <>
                            <div className="task-card ">
                              <div className="task-card-title">
                                <div className="task-1">{task.title}</div>
                              </div>
                              <div className="status-block">
                                {task?.labels?.length > 0 &&
                                  task.labels.map((e, i) => {
                                    return (
                                      <div style={{ padding: 5 }}>
                                        <div
                                          key={i}
                                          className={classNames(
                                            "task-status",
                                            e.color
                                          )}
                                          style={{
                                            backgroundColor: getColorCode(
                                              e.color
                                            ),
                                            textOverflow: "ellipsis",
                                            wordBreak: "break-all",
                                            overflow: "hidden"
                                          }}
                                        >
                                          {e.name}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                              {task.scheduledAt && (
                                <>
                                  <i
                                    id="calender"
                                    className="far fa-calendar-alt"
                                    style={{ fontSize: 15, color: "#6B778C" }}
                                  ></i>
                                  <span
                                    style={{
                                      fontSize: 13,
                                      marginLeft: 10,
                                      color: "black"
                                    }}
                                  >
                                    {moment(new Date(task.scheduledAt)).format(
                                      "DD MMM YYYY"
                                    )}
                                  </span>
                                </>
                              )}
                              <div className="task-options">
                                {task.priority !== "no_priority" && (
                                  <img
                                    src={getPriorityPath(task.priority)}
                                    height="18"
                                    width="18"
                                    className=""
                                    alt="Priority"
                                    // style={{ cursor: "pointer",color: priority.color }}
                                  />
                                )}
                                <div className="icon-space">
                                  <img
                                    alt=""
                                    src={commentnew}
                                    style={{
                                      height: "15px",
                                      width: "15px",
                                      marginRight: "0px",
                                      marginTop: "-6px",
                                      textAlign: "center"
                                    }}
                                  ></img>
                                  {/* <i title="Comments" className="far fa-comment-alt"></i> */}
                                </div>
                                <div className="count-space">
                                  {task.commentCount ? task.commentCount : 0}
                                </div>
                              </div>

                              {/* {isLoader.status &&
                                  isLoader.id === task.id &&
                                  isLoader.activeTab === activeTab ? (
                                    <Loader height="20px" width="20px" />
                                  ) : (
                                    <button
                                      className="btn button"
                                      style={{
                                        color: "#000",
                                        cursor:'pointer',
                                        fontSize: 12,
                                        padding: 4,,
                                        display:'flex',
                                        justifyContent:'start',
                                        alignItems:'center'
                                        height: 30
                                      }}
                                      onClick={() => {
                                        setIsLoader({
                                          status: true,
                                          id: task.id,
                                          activeTab: activeTab
                                        });
                                        doArchiveTask(task?.id);
                                      }}
                                    >
                                      Unarchive
                                    </button>
                                  )} */}
                            </div>
                            <div style={{ marginBottom: 12 }}>
                              {isLoader.status &&
                              isLoader.id === task.id &&
                              isLoader.activeTab === activeTab ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center"
                                  }}
                                >
                                  <Loader height="20px" width="18px" />
                                </div>
                              ) : (
                                <div style={{ display: "flex" }}>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      fontSize: 14,
                                      textDecoration: "underline",
                                      marginLeft: 10,
                                      color: "gray",
                                      fontWeight: 400,
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "center",
                                      paddingRight: 4
                                    }}
                                    onClick={() => {
                                      setIsLoader({
                                        status: true,
                                        id: task.id,
                                        activeTab: activeTab
                                      });
                                      doArchiveTask(task?.id, task);
                                    }}
                                  >
                                    Unarchive
                                  </span>
                                  {"-"}
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      fontSize: 14,
                                      textDecoration: "underline",
                                      marginLeft: 4,
                                      color: "gray",
                                      fontWeight: 400,
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "center"
                                    }}
                                    onClick={() => {
                                      setSectionArrTitle(task.title);
                                      setIsLoader({
                                        status: true,
                                        id: task.id,
                                        activeTab: activeTab
                                      });
                                      setIsDeleteArchiveCustomAlert({
                                        status: true,
                                        id: task.id
                                      });
                                      // doDeleteTask(task?.id);
                                    }}
                                  >
                                    Delete
                                  </span>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      }
                    })}
                  </>
                ) : (
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      textAlign: "center"
                    }}
                  >
                    <IntlMessages id={"sidebar.notfound"} />
                  </div>
                )}
              </>
            ) : (
              <>
                {taskArr &&
                taskArr.length > 0 &&
                project &&
                project?.section?.length > 0 ? (
                  project.section.map(section => {
                    return (
                      <>
                        {!section.isDeleted &&
                          section.task?.length > 0 &&
                          section.task.map(task => {
                            if (task.isArchived && !task.isDeleted) {
                              return (
                                <>
                                  <div className="task-card">
                                    <div className="task-card-title">
                                      <div className="task-1">{task.title}</div>
                                    </div>
                                    <div className="status-block">
                                      {task?.labels?.length > 0 &&
                                        task.labels.map((e, i) => {
                                          return (
                                            <div style={{ padding: 5 }}>
                                              <div
                                                key={i}
                                                className={classNames(
                                                  "task-status",
                                                  e.color
                                                )}
                                                style={{
                                                  backgroundColor: getColorCode(
                                                    e.color
                                                  ),
                                                  textOverflow: "ellipsis",
                                                  wordBreak: "break-all",
                                                  overflow: "hidden"
                                                }}
                                              >
                                                {e.name}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                    {task.scheduledAt && (
                                      <>
                                        <i
                                          id="calender"
                                          className="far fa-calendar-alt"
                                          style={{
                                            fontSize: 15,
                                            color: "#6B778C"
                                          }}
                                        ></i>
                                        <span
                                          style={{
                                            fontSize: 13,
                                            marginLeft: 10,
                                            color: "black"
                                          }}
                                        >
                                          {moment(
                                            new Date(task.scheduledAt)
                                          ).format("DD MMM YYYY")}
                                        </span>
                                      </>
                                    )}
                                    <div className="task-options">
                                      {task.priority !== "no_priority" && (
                                        <img
                                          src={getPriorityPath(task.priority)}
                                          height="18"
                                          width="18"
                                          className=""
                                          alt="Priority"
                                          // style={{ cursor: "pointer",color: priority.color }}
                                        />
                                      )}
                                      <div className="icon-space">
                                        <img
                                          alt=""
                                          src={commentnew}
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                            marginRight: "0px",
                                            marginTop: "-6px",
                                            textAlign: "center"
                                          }}
                                        ></img>
                                        {/* <i title="Comments" className="far fa-comment-alt"></i> */}
                                      </div>
                                      <div className="count-space">
                                        {task.commentCount
                                          ? task.commentCount
                                          : 0}
                                      </div>
                                    </div>

                                    {/* {isLoader.status &&
                                  isLoader.id === task.id &&
                                  isLoader.activeTab === activeTab ? (
                                    <Loader height="20px" width="20px" />
                                  ) : (
                                    <button
                                      className="btn button"
                                      style={{
                                        color: "#000",
                                        cursor:'pointer',
                                        fontSize: 12,
                                        padding: 4,,
                                        display:'flex',
                                        justifyContent:'start',
                                        alignItems:'center'
                                        height: 30
                                      }}
                                      onClick={() => {
                                        setIsLoader({
                                          status: true,
                                          id: task.id,
                                          activeTab: activeTab
                                        });
                                        doArchiveTask(task?.id);
                                      }}
                                    >
                                      Unarchive
                                    </button>
                                  )} */}
                                  </div>
                                  <div style={{ marginBottom: 12 }}>
                                    {isLoader.status &&
                                    isLoader.id === task.id &&
                                    isLoader.activeTab === activeTab ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                          alignItems: "center"
                                        }}
                                      >
                                        <Loader height="20px" width="18px" />
                                      </div>
                                    ) : (
                                      <div style={{ display: "flex" }}>
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontSize: 14,
                                            textDecoration: "underline",
                                            marginLeft: 10,
                                            color: "gray",
                                            fontWeight: 400,
                                            display: "flex",
                                            justifyContent: "start",
                                            alignItems: "center",
                                            paddingRight: 4
                                          }}
                                          onClick={() => {
                                            setIsLoader({
                                              status: true,
                                              id: task.id,
                                              activeTab: activeTab
                                            });
                                            doArchiveTask(task?.id, task);
                                          }}
                                        >
                                          Unarchive
                                        </span>
                                        {"-"}
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontSize: 14,
                                            textDecoration: "underline",
                                            marginLeft: 4,
                                            color: "gray",
                                            fontWeight: 400,
                                            display: "flex",
                                            justifyContent: "start",
                                            alignItems: "center"
                                          }}
                                          onClick={() => {
                                            setSectionArrTitle(task.title);
                                            setIsLoader({
                                              status: true,
                                              id: task.id,
                                              activeTab: activeTab
                                            });
                                            // doDeleteTask(task?.id);
                                            setIsDeleteArchiveCustomAlert({
                                              status: true,
                                              id: task.id
                                            });
                                          }}
                                        >
                                          Delete
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            }
                          })}
                      </>
                    );
                  })
                ) : (
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      textAlign: "center"
                    }}
                  >
                    <IntlMessages id={"sidebar.noarchive"} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      );
    }
    if (activeTab == "list") {
      return (
        <div
          className="list-container"
          style={{ backgroundColor: "#f4f5f7", padding: 15 }}
        >
          <div className="form-group">
            <input
              value={searchInput}
              placeholder={InjectPlaceHolderMessage(
                "project.search_list",
                props?.LanguageSwitcher?.language?.languageId
              )}
              className="form-control form-input pa-10"
              onChange={e => handleSearch(e.target.value)}
              style={{ fontWeight: 400 }}
            />
          </div>
          <div className="task-list">
            {isSearch ? (
              <>
                {searchQueryResults?.length > 0 ? (
                  <>
                    {searchQueryResults.map(section => {
                      if (section.isArchived) {
                        return (
                          <div
                            className="list-card flex-x "
                            style={{
                              padding: 6,
                              borderRadius: 0,
                              borderBottom: "1px solid #EBEBEB"
                            }}
                          >
                            <div
                              className="list-card-title flex-1 fs-14"
                              style={{ position: "relative", top: 3 }}
                            >
                              {" "}
                              {/* <svg
                              data-svgs-path=""
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            > */}
                              <path
                                fill="grey"
                                fillRule="nonzero"
                                d="M17.5 20c.276 0 .5.224.5.5s-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11zM16 8c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2H8c-1.105 0-2-.895-2-2v-5c0-1.105.895-2 2-2h8zm0 1H8c-.513 0-.936.386-.993.883L7 10v5c0 .513.386.936.883.993L8 16h8c.513 0 .936-.386.993-.883L17 15v-5c0-.513-.386-.936-.883-.993L16 9zm1.5-5c.276 0 .5.224.5.5s-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11z"
                              ></path>
                              {/* </svg> */}
                              {section.title}
                            </div>
                            {isLoader.status &&
                            isLoader.id === section.id &&
                            isLoader.activeTab === activeTab ? (
                              <Loader height="20px" width="20px" />
                            ) : (
                              <button
                                className="btn button"
                                style={{
                                  color: "black",
                                  fontSize: 12,
                                  padding: 4,
                                  height: 30,
                                  backgroundColor: "#EBEBEB",
                                  width: 100
                                }}
                                onClick={() => {
                                  setIsLoader({
                                    status: true,
                                    id: section.id,
                                    activeTab: activeTab
                                  });
                                  doArchiveSection(section);
                                }}
                              >
                                Unarchive
                              </button>
                            )}
                          </div>
                        );
                      }
                    })}
                  </>
                ) : (
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      textAlign: "center"
                    }}
                  >
                    <IntlMessages id={"sidebar.notfound"} />
                  </div>
                )}
              </>
            ) : (
              <>
                {searchQueryResults?.length <= 0 &&
                sectionArr &&
                sectionArr?.length > 0 &&
                project &&
                project?.section?.length > 0 ? (
                  project.section.map(section => {
                    if (section.isArchived) {
                      return (
                        <div style={{ paddingTop: 0 }}>
                          <div
                            className="list-card flex-x "
                            style={{
                              padding: 6,
                              borderRadius: 0,
                              borderBottom: "1px solid #EBEBEB"
                            }}
                          >
                            <div
                              className="list-card-title flex-1"
                              style={{ position: "relative", top: 3 }}
                            >
                              {" "}
                              {/* <svg
                                data-svgs-path=""
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              > */}
                              <path
                                fill="grey"
                                fillRule="nonzero"
                                d="M17.5 20c.276 0 .5.224.5.5s-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11zM16 8c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2H8c-1.105 0-2-.895-2-2v-5c0-1.105.895-2 2-2h8zm0 1H8c-.513 0-.936.386-.993.883L7 10v5c0 .513.386.936.883.993L8 16h8c.513 0 .936-.386.993-.883L17 15v-5c0-.513-.386-.936-.883-.993L16 9zm1.5-5c.276 0 .5.224.5.5s-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11z"
                              ></path>
                              {/* </svg> */}
                              {section.title}
                            </div>
                            {isLoader.status &&
                            isLoader.id === section.id &&
                            isLoader.activeTab === activeTab ? (
                              <Loader height="20px" width="20px" />
                            ) : (
                              <button
                                className="btn button"
                                style={{
                                  color: "black",
                                  fontSize: 12,
                                  padding: 4,
                                  height: 30,
                                  backgroundColor: "#EBEBEB",
                                  width: 100
                                }}
                                onClick={() => {
                                  setIsLoader({
                                    status: true,
                                    id: section.id,
                                    activeTab: activeTab
                                  });
                                  doArchiveSection(section);
                                }}
                              >
                                Unarchive
                              </button>
                            )}{" "}
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      textAlign: "center"
                    }}
                  >
                    <IntlMessages id={"siderbar.noarchhivelist"} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <ThemeSettingWrapper sidebarTheme={sidebarTheme}>
        {!mini && (
          <div
            className="themesetting-overlay"
            onClick={closeSettingDrawer}
          ></div>
        )}
        <div
          id="sidebar"
          className="themeSettingRightside sideBack"
          style={sidebar}
        >
          <div className="sidebar-wrapper">
            <div
              className="themeSettingHeading fs-14 medium-text"
              style={{ fontWeight: 600, textAlign: "center" }}
            >
              <IntlMessages id={"themeChanger.archivedItems"} />
              <img
                alt=""
                onClick={closeSettingDrawer}
                src={close_Icon}
                style={{
                  height: "11px",
                  width: "11px",
                  position: "relative",
                  left: 80,
                  cursor: "pointer"
                }}
              ></img>
              {/* <i
                className="fas fa-times close-icon cursor-pointer"
                onClick={closeSettingDrawer}
                style={{
                  backgroundColor: "white",
                  color: topbarTheme.buttonColor,
                  top: 17
                }}
              ></i> */}
            </div>
          </div>
          <div className="side-menu-action-nav" style={{ fontSize: 14 }}>
            <ul className="ul-no-margin">
              <li
                className={`side-menu-action-nav-items ${
                  activeTab == "task" ? "tab-active " : ""
                }`}
                onClick={() => toggletab(tabs[0])}
                style={{ fontSize: 14 }}
              >
                <span style={{ fontWeight: 600 }}>
                  <div>
                    <IntlMessages id={"globalsearchbar.tasks"} />{" "}
                  </div>
                </span>
              </li>
              <li
                className={`side-menu-action-nav-items ${
                  activeTab == "list" ? "tab-active " : ""
                }`}
                onClick={() => toggletab(tabs[1])}
                style={{ fontSize: 14 }}
              >
                <span style={{ fontWeight: 600 }}>
                  <div>
                    <IntlMessages id={"Lists"} />{" "}
                  </div>
                </span>
              </li>
            </ul>
          </div>
          <>{HandleActiveTabs(activeTab)}</>
        </div>
        <CustomAlert
          CustomMessage={sectionArrTitle}
          action="delete"
          messageType="task"
          PRIMARY_COLOR={PRIMARY_COLOR}
          show={isDeleteArchiveCustomAlert?.status}
          onConfirm={() => {
            doDeleteTask(
              isDeleteArchiveCustomAlert?.status &&
                isDeleteArchiveCustomAlert?.id
            );
            setIsDeleteArchiveCustomAlert({ status: false, id: "" });
            setIsLoader({
              status: false,
              id: "",
              activeTab: ""
            });
          }}
          onCancel={() => {
            setIsLoader({
              status: false,
              id: "",
              activeTab: ""
            });
            setIsDeleteArchiveCustomAlert({ status: false, id: "" });
          }}
        />
      </ThemeSettingWrapper>
    </>
  );
};

export default ProjectSideMenu;
