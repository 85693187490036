import styled from "styled-components";

const NotificationWrapper = styled.div`
  .notification-list-container {
    background: white;
    border-radius: 12px;
  }
  .notification-list-container:hover {
    background: #ebebeb;
    box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
  }
`;

export default NotificationWrapper;
