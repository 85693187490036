export const getRedirectionStyle = item => {
  if (item && item.redirection_type && item.redirection_type !== null) {
    if (item.redirection_type && item.redirection_type === "task") {
      if (item.project_id && item.task_id && item.business_id) {
        return { cursor: "pointer", marginTop: "15px" };
      }
      return { cursor: "default" };
    }
    if (item.redirection_type && item.redirection_type === "taskcomment") {
      if (item.project_id && item.task_id && item.business_id) {
        return { cursor: "pointer", marginTop: "15px" };
      }
      return { cursor: "default" };
    }
    if (item.redirection_type && item.redirection_type === "business") {
      if (item.business_id) {
        return { cursor: "pointer", marginTop: "15px" };
      }
      return { cursor: "default" };
    }
    if (item.redirection_type && item.redirection_type === "project") {
      if (item.project_id) {
        return { cursor: "pointer", marginTop: "15px" };
      }
      return { cursor: "default" };
    }
    if (item.redirection_type && item.redirection_type === "projectcomment") {
      if (item.project_id) {
        return { cursor: "pointer", marginTop: "15px" };
      }
      return { cursor: "default" };
    }
  }
  return { cursor: "default" };
};
