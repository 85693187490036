import styled from "styled-components";

const TaskTitleCardWrapper = styled.div`
  .task-title-container {
  }
  .input--borderless {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none !important;
    border: 0;
    border-radius: 0;
    outline: 0;
  }
  .input--borderless::focus {
    outline: none;
    border-color: #a4d2ff;
    box-shadow: none !important;
    border: 0;
    border-radius: 0;
    outline: 0;
  }

  input {
    font-size: 28px !important;
    resize: none;
  }
  .task-checkbox {
    height: 60px;
    width: 60px;
    border-radius: 9px;
    border: 1px solid #808080;
    font-size: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
  }
  .un-checked {
    border-color: rgba(0, 0, 0, 0.15);
    border-width: 0.1rem;
    border-radius: 0.8em;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 5%);
    font-weight: 400;
    padding: 0px;
    color: #6d6d6d;
  }
  .checked {
    border: none;
  }
`;

export default TaskTitleCardWrapper;
