import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import "./alertstyle.css";
import { InfoIconsCoustomAlert } from "helper/constant";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "components/loader/Loader";
import IntlMessages from "./intlMessages";
export default function CustomAlertAction({
  show,
  onConfirm,
  onCancel,
  action,
  PRIMARY_COLOR,
  message,
  confirmMessage,
  loader
}) {
  //  user action for to check do we need to give user input to delete /or any other action
  return (
    <>
      <Modal
        isOpen={show}
        toggle={onCancel}
        // className={className}
        centered
        size="md"
      >
        <ModalHeader
          toggle={onCancel}
          style={{ paddingLeft: 28, paddingRight: "24px" }}
        >
          <span style={{ fontSize: 16, fontWeight: "600", color: "black" }}>
            <IntlMessages id={"workspace.areyousure"} />
          </span>
        </ModalHeader>
        <ModalBody style={{ padding: "16px 29px " }}>
          <span className="text-muted">{message}</span>
        </ModalBody>
        <ModalFooter style={{ paddingRight: "23px" }}>
          <Button
            className="button btn"
            onClick={onCancel}
            style={{
              background: "#FFF",
              border: 0,
              color: "gray"
            }}
          >
            <IntlMessages id={"workspace.notnow"} />
          </Button>{" "}
          {loader ? (
            <Loader height="24px" width="24px" />
          ) : (
            <Button
              className="button btn"
              style={{
                background: PRIMARY_COLOR ? PRIMARY_COLOR : "#0073E6",
                color: "#FFF",
                border: "none"
                // textTransform: "capitalize"
              }}
              onClick={onConfirm}
            >
              {confirmMessage ? (
                confirmMessage
              ) : (
                <IntlMessages id={"workspace.doit"} />
              )}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}
