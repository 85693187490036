import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

const AddChecklistItem = ({ addNewSubtask, topbarTheme }) => {
  const [isAdd, setisAdd] = useState(false);
  const [input, setInput] = useState("");
  useEffect(() => {
    return () => {
      setisAdd(false);
      setInput("");
    };
  }, []);
  const handleAddNewItem = () => {
    addNewSubtask(input);
    setInput("");
  };
  const handleCancelAddNewItem = () => {
    setisAdd(false);
    setInput("");
  };
  return (
    <div>
      <div className="row" style={{ display: "flex", padding: "10px 0px" }}>
        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12"></div>
        <div
          className="col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12"
          style={{ width: "100%" }}
        >
          {isAdd ? (
            <div>
              {/* {checklistLoader && <ChecklistShimmer />} */}

              {/* {addChecklistFlag && ( */}
              <>
                <div className="add-comment-input-block mt-10">
                  <input
                    // ref={textArea}
                    value={input}
                    autoFocus
                    className="add-subtask-input inputStyle"
                    name="checklistTitle"
                    rows="1"
                    onChange={e => setInput(e.target.value)}
                    style={{
                      border: "1px solid #EBEBEB",
                      backgroundColor: "#F8F8F8"
                    }}
                    placeholder={"Item title"}
                    onKeyPress={event => {
                      if (event.key === "Enter") {
                        /* checks if white space at starting of input present or not */
                        if (
                          !event.shiftKey &&
                          input.match(/^\S+\w{0,50}\S{0,}/g)
                        ) {
                          event.preventDefault();
                          handleAddNewItem();
                        }
                      }
                    }}
                  />
                  <div
                    className="subtask-action"
                    style={{ margin: "15px 0px" }}
                  >
                    <>
                      <button
                        className="btn mr-10"
                        style={{
                          boxShadow: "none",
                          borderRadius: "17px",
                          backgroundColor: topbarTheme.buttonColor,
                          color: "white",
                          width: "65px"
                        }}
                        disabled={
                          !input.match(/^\S+\w{0,50}\S{0,}/g) ? true : false
                        }
                        // style={{
                        //   backgroundColor: ChecklistInput.match(
                        //     /^\S+\w{0,50}\S{0,}/g
                        //   )
                        //     ? props.topbarTheme.buttonColor
                        //     : props.topbarTheme.buttonColor,
                        //   color: "#fff",
                        //   boxShadow: "none"
                        // }}
                        // disabled={
                        //   !ChecklistInput.match(
                        //     /^\S+\w{0,50}\S{0,}/g
                        //   )
                        //     ? true
                        //     : false
                        // }
                        onClick={handleAddNewItem}
                      >
                        Add
                      </button>
                      <button
                        className="btn btn"
                        onClick={handleCancelAddNewItem}
                        style={{
                          boxShadow: "none",
                          borderRadius: "17px",
                          border: `1px solid ${topbarTheme.buttonColor}`,
                          color: topbarTheme.buttonColor,
                          width: "80px"
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  </div>
                </div>
              </>
              {/* )} */}
            </div>
          ) : (
            <div style={{ margin: "0px" }}>
              <button
                className="btn btn"
                style={{
                  boxShadow: "none",
                  borderRadius: "17px",
                  backgroundColor: topbarTheme.buttonColor,
                  color: "white",
                  width: "115px"
                }}
                onClick={() => setisAdd(true)}
              >
                Add an item
              </button>
            </div>
          )}
        </div>
        <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {})(AddChecklistItem);
