/** @format */

import profileModelAction from "./actions";

const initialState = {
  isOpen: false,
  profile: null
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case profileModelAction.OPEN_PROFILE_MODAL:
      return {
        ...state,
        isOpen: false,
        profile: action.profile
      };

    case profileModelAction.CLOSE_PROFILE_MODAL:
      return {
        ...state,
        isOpen: false,
        profile: null
      };

    default:
      return state;
  }
}
