/** @format */

import React, { useState } from "react";
import moment from "moment";
import {
  getNotifications,
  acceptBusinessRequest,
  rejectBusinessRequest
} from "helper/services/notificationService";
import { getRedirectionStyle } from "../components/notifications/util/functions.js";
import ChangeWorkspaceAlert from "util/ChangeWorkspaceAlert";
import Button from "components/button/Button";
import { swicthBusiness } from "helper/services/businessServices";
import {
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import { getProject, getLables } from "helper/services/projectServices";
import CustomNotification from "util/CustomNotification";
import Loader from "components/loader/Loader";
import { connect } from "react-redux";
import Shimmer from "components/shimmer/Shimmer";
import {
  profilePlus,
  joined,
  overdueTask,
  declained,
  acceptInvitation,
  added,
  mentioned,
  assignNotification,
  reminder
} from "helper/constant";

import { notificationInit, joinRoom, leaveRoom } from "services/socket";
import { notificationsSocketRoomPrefix } from "constatnt";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import labelActions from "redux/labels/actions";
import projectActions from "redux/projects/actions";
import businessActions from "redux/business/actions";
import AuthActions from "redux/auth/actions";
import { handleRecentWorkspace } from "util/RecentWorkspace";
import NotificationWrapper from "./NotificationWrapper.style";
import LinearLoader from "components/loader/LinearLoader";
import IntlMessages from "util/intlMessages";

const { update_token, update_business } = AuthActions;
const { default_business } = businessActions;
const { set_labels, update_Label } = labelActions;
const { set_projects, update_recent_Project } = projectActions;
const { update_Project } = projectActions;
function Notifications(props) {
  const { addToast } = useToasts();
  const [notifications, setNotifications] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [toast, setToast] = useState(false);
  const [load, setLoad] = useState(false);
  const [isViewMoreLoader, setIsViewMoreLoader] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [isMoveToWorkspace, setIsMoveToWorkspace] = useState(false);
  const [isAlertAction, setIsAlertAction] = useState({ status: false, id: "" });
  const [isAlertActionLoader, setIsAlertActionLoader] = useState(false);
  const [isViewMore, setIsViewMore] = useState(false);
  const [currentRetrievedPage, setCurrentRetrievedPage] = useState("0");

  React.useEffect(() => {
    setCurrentRetrievedPage("0");
    fetchNotification(true, false, "0");
    notificationInit(notificationsRefreshCB, props.auth.accessToken);
    setCurrentRetrievedPage(1);
    handleSocketRoom();
    return () => {
      setIsMoveToWorkspace({ status: false, id: "" });
      setCurrentRetrievedPage("0");
      setIsViewMore(false);
      setIsViewMoreLoader(false);
      handleLeaveSocketRoom();
    };
  }, [props?.history?.location?.state]);

  const handleSocketRoom = () => {
    // Join room
    joinRoom(`${notificationsSocketRoomPrefix}${props.auth.user.id}`);
  };
  const handleLeaveSocketRoom = () => {
    console.log("leave");
    // Join room
    leaveRoom(`${notificationsSocketRoomPrefix}${props.auth.user.id}`);
  };
  const notificationsRefreshCB = data => {};
  const fetchNotification = async (
    isLoading = true,
    isviewmoreloader = false,
    reqPage
  ) => {
    try {
      setLoad(isLoading);
      setIsViewMoreLoader(isviewmoreloader);
      const retrivePage = parseInt(reqPage || currentRetrievedPage);
      const Response = await getNotifications(
        props.auth.accessToken,
        parseInt(retrivePage) + 1,
        10
      );
      if (Response) {
        if (Response.error) {
          showNotifications();
          setLoad(false);
          setIsViewMoreLoader(false);
          return;
        }
        if (Response.data) {
          setIsViewMore(!Response?.extra?.isLastPage);
          if (!Response?.extra?.isLastPage) {
            setCurrentRetrievedPage(
              (parseInt(currentRetrievedPage) + 1).toString()
            );
          }

          setLoad(false);
          setIsViewMoreLoader(false);
          if (reqPage !== "0") {
            setNotifications(
              notifications
                ? [...notifications, ...Response.data]
                : [...Response.data]
            );
          } else {
            // if view all is pressed from popover notification
            setNotifications([...Response.data]);
          }
        }
      }
    } catch (e) {
      setLoad(false);
      setIsViewMoreLoader(false);
      showNotifications();
    }
  };

  const doAcceptRequest = async (collabsId, invitationCode, title, id, i) => {
    try {
      setBtnLoad(true);
      const Response = await acceptBusinessRequest(collabsId, invitationCode);
      if (Response) {
        if (Response.error) {
          showNotifications();
          return;
        }
        if (Response.message) {
          const notificationArr = notifications;
          if (notificationArr[i].id === id) {
            notificationArr[i].notification_type = "accepted";
            notificationArr[i].username = "You";
            setNotifications([...notificationArr]);
            setBtnLoad(false);
            showNotifications(Response.message, "success");
            setIsMoveToWorkspace({ status: true, id: id });
          }
        }

        if (!Response.data.id && !Response.data.title && !Response.data.color) {
          // props.update_Project({
          //   id: Response.data,
          //   title: title,
          // });
        }
        // props.update_Project(Response.data);
        setBtnLoad(false);
        // props.history.push(`project/${Response.data}`);
      }
    } catch (e) {
      setBtnLoad(false);

      showNotifications();
    }
  };
  const updateProjectandLabel = async token => {
    fecthProject(token);
    fetchLabels(token);
  };
  const updateToken = (token, businessName, businessMeta, id) => {
    props.update_token(token);
    props.default_business({
      id: id,
      businessName: businessName,
      businessMeta: businessMeta
    });
    fecthProject(token);
    fetchLabels(token);
    // updateUser(token);
    props.update_business({ businessId: id, businessName, businessMeta });
  };
  const fetchLabels = async token => {
    try {
      const Response = await getLables(token);
      if (Response) {
        if (Response.data) {
          props.set_labels({ labels: Response.data });
          updateSidebarLabelToSidebar(Response.data);
        }
        if (Response.error) {
        }
      }
    } catch (e) {}
  };

  const fecthProject = async token => {
    const Response = await getProject(token);
    if (Response) {
      props.set_projects({ projects: Response.data });
      updateSidebarProjectToSidebar(Response.data);
    }
  };

  /* if project is not present then fetch projects api will be called and update sidebar */
  const isProjectPresentInSidebar = projectId => {
    if (projectId) {
      let projectArr = props.projects.projects;
      if (projectArr) {
        const isProjectPresent =
          projectArr && projectArr.filter(p => p.id === projectId);
        if (isProjectPresent && isProjectPresent?.length > 0) {
          return true;
        } else {
          fecthProject();
        }
      }
    }
  };
  const selectBusiness = async id => {
    try {
      setIsAlertActionLoader(true);
      const Response = await swicthBusiness(id);
      if (Response) {
        if (Response.data) {
          await updateToken(
            Response.data.token,
            Response.data.business.title,
            Response.data.business?.businessMeta
              ? Response.data.business?.businessMeta
              : null,
            Response.data.business.id
          );
          handleRecentWorkspace(Response.data.business);
          handleRedirection(isAlertAction.data, Response.data.business.id);
          updateProjectandLabel(Response.data.token);
          props.update_recent_Project({
            project_id: ""
          });
          setIsAlertAction({ data: "", status: false });
        }
        if (Response.error) {
          showNotifications(Response.error.message, "error");
          setIsAlertAction({ id: "", status: false });
        }
        setIsAlertActionLoader(false);
      }
    } catch (e) {
      setIsAlertActionLoader(false);
    }
  };
  const getMessageType = item => {
    if (item.redirection_type === "task") {
      return (
        <>
          <IntlMessages id={"notification.taskbelongsto"} />
          {item.business_title ? (
            <b>{item.business_title}</b>
          ) : (
            "different"
          )}{" "}
          <IntlMessages id={"notification.switchworkspacetaskdetails"} />
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "project") {
      return (
        <>
          <IntlMessages id={"notification.projectbelongs"} />
          {item.business_title ? (
            <b>{item.business_title}</b>
          ) : (
            "different"
          )}{" "}
          <IntlMessages id={"notification.switchworkspaceprojectdetails"} />
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "business") {
      return (
        <>
          <IntlMessages id={"notification.switchtheworkspacetoseethe"} />
          {item.business_title ? (
            <b>{item.business_title}</b>
          ) : (
            "different"
          )}{" "}
          <IntlMessages id={"notification.workspacedetails"} />
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "taskcomment") {
      return (
        <>
          <IntlMessages id={"notification.taskcomment"} />
          {item.business_title ? (
            <b>{item.business_title}</b>
          ) : (
            "different"
          )}{" "}
          <IntlMessages id={"notification.switchworkspacetaskcommentdetails"} />
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "projectcomment") {
      return (
        <>
          <IntlMessages id={"notification.projectcomment"} />
          {item.business_title ? (
            <b>{item.business_title}</b>
          ) : (
            "different"
          )}{" "}
          <IntlMessages
            id={"notification.switchworkspaceprojectcommentdetails"}
          />
        </>
      );
    }
  };

  const doRejectRequest = async (collabsId, invitationCode, id, i) => {
    try {
      setBtnLoad(true);
      const Response = await rejectBusinessRequest(collabsId, invitationCode);
      if (Response) {
        if (Response.error) {
          setBtnLoad(false);
          showNotifications();
          return;
        }

        if (Response.message) {
          const notificationArr = notifications;
          if (notificationArr[i].id === id) {
            notificationArr[i].notification_type = "rejected";
            notificationArr[i].username = "You";
            setNotifications([...notificationArr]);
            setBtnLoad(false);
          }
        }

        let notificationArr = notifications;
        await Promise.all(
          notificationArr.map((n, i) => {
            if (n.collaborator_id == collabsId) {
              notificationArr.splice(i, 1);
            }
          })
        );
        setNotifications([...notificationArr]);
      }
    } catch (e) {
      setBtnLoad(false);
      showNotifications();
    }
  };
  const handleRedirection = (item, newBusinessId) => {
    if (item.redirection_type && item.redirection_type !== "rejected") {
      if (item.redirection_type && item.redirection_type === "task") {
        console.log(item);

        if (item.project_id && item.task_id && item.business_id) {
          if (
            item.business_id == props.auth.user.businessId ||
            item.business_id == newBusinessId
          ) {
            isProjectPresentInSidebar(item.project_id);
            return props.history.push(`/task-details/${item.task_id}`);
          } else {
            setIsAlertAction({ status: true, data: item });
          }
        }
        return false;
      }
      if (item.redirection_type && item.redirection_type === "taskcomment") {
        if (item.project_id && item.task_id && item.business_id) {
          if (
            item.business_id == props.auth.user.businessId ||
            item.business_id == newBusinessId
          ) {
            isProjectPresentInSidebar(item.project_id);
            return props.history.push(`/task-details/${item.task_id}`);
          } else {
            setIsAlertAction({ status: true, data: item });
          }
        }
        return false;
      }
      if (item.redirection_type && item.redirection_type === "business") {
        if (item.business_id) {
          if (
            item.business_id == props.auth.user.businessId ||
            item.business_id == newBusinessId
          ) {
            return props.history.push(`/business/${item.business_id}`);
          } else {
            setIsAlertAction({ status: true, data: item });
          }
        }
        return false;
      }
      if (item.redirection_type && item.redirection_type === "project") {
        if (item.project_id) {
          if (
            item.business_id == props.auth.user.businessId ||
            item.business_id == newBusinessId
          ) {
            isProjectPresentInSidebar(item.project_id);
            return props.history.push(`/project-details/${item.project_id}`);
          } else {
            setIsAlertAction({ status: true, data: item });
          }
        }
        return false;
      }
      if (item.redirection_type && item.redirection_type === "projectcomment") {
        if (item.project_id) {
          if (
            item.business_id == props.auth.user.businessId ||
            item.business_id == newBusinessId
          ) {
            isProjectPresentInSidebar(item.project_id);
            return props.history.push(`/project/${item.project_id}`, {
              commentModal: true
            });
          } else {
            setIsAlertAction({ status: true, data: item });
          }
        }
        return false;
      }
    }
  };

  const generateSentense = item => {
    if (item.notification_type == "joined") {
      return (
        <>
          <div>
            <p
              style={{
                fontSize: 14,
                marginBottom: 2,
                color: "#000000",
                font: "Segoe UI"
              }}
            >
              {" "}
              <b>{item.username || "You"}</b>{" "}
              <IntlMessages id={"notification.joined"} />{" "}
              <b
                style={getRedirectionStyle(item)}
                onClick={() => handleRedirection(item)}
              >
                {item && item.business_title !== null
                  ? item.business_title
                  : item.title}
              </b>
              <small
                // className="row"
                style={{ fontSize: 12, color: "gray", font: "Segoe UI" }}
                className="flex-x"
              >
                <p
                  title={item.business_title}
                  style={{
                    display: "inline-block",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingRight: "0px",
                    maxWidth: "350px"
                  }}
                >
                  {item.business_title && item.business_title != "" && (
                    <>
                      {/* <img
                      src={workspaceSymbolIcon}
                      style={{ height: 16, width: 16, paddingRight: 4 }}
                    />{" "} */}
                      {item.business_title}
                    </>
                  )}
                </p>
                <p
                  style={{
                    paddingLeft: 10,
                    display: "inline-block",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingRight: "0px"
                  }}
                >
                  {item.project_name && item.project_name != "" && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      <i
                        class="fa fa-circle fs-6"
                        style={{ paddingRight: 4, color: "#8f8f8f" }}
                      />
                      <p
                        title={item.project_name}
                        className="fs-12"
                        style={{
                          display: "inline-block",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          paddingRight: "0px",
                          maxWidth: "350px"
                        }}
                      >
                        {item.project_name}
                      </p>
                    </div>
                  )}
                </p>
              </small>
            </p>
          </div>
          {isMoveToWorkspace.status && item.id == isMoveToWorkspace.id && (
            <Button
              onClick={() => {
                showNotifications("Id not given");
              }}
              className="c-btn  c-outline-danger"
              style={{ boxShadow: "none" }}
            >
              <IntlMessages id={"notification.acceptswitch"} />
            </Button>
          )}
        </>
      );
    } else if (item.notification_type == "invited") {
      return (
        <>
          <div>
            <p
              style={{
                fontSize: 14,
                marginBottom: 2,

                color: "#000000",
                font: "Segoe UI"
              }}
            >
              {" "}
              <b>{item.username || "You"}</b>{" "}
              <IntlMessages id={"notification.invitetojoinworkspace"} />
              <b>{item.title}</b>
              <small
                style={{ fontSize: 12, color: "gray", font: "Segoe UI" }}
                className="flex-x"
              >
                <p
                  title={item.business_title}
                  style={{
                    display: "inline-block",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingRight: "0px",
                    maxWidth: "350px"
                  }}
                >
                  {item.business_title && item.business_title != "" && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* <img
                      src={workspaceSymbolIcon}
                      style={{ height: 16, width: 16, paddingRight: 4 }}
                    />{" "} */}
                      {item.business_title}
                    </div>
                  )}
                </p>
                <p
                  style={{
                    paddingLeft: 5,
                    display: "inline-block",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingRight: "0px"
                  }}
                >
                  {item.project_name && item.project_name != "" && (
                    <>
                      {" "}
                      <i
                        class="fa fa-circle fs-6"
                        style={{ paddingRight: 4, color: "#8f8f8f" }}
                      />
                      <p
                        title={item.project_name}
                        className="fs-12"
                        style={{
                          display: "inline-block",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          paddingRight: "0px",
                          maxWidth: "350px"
                        }}
                      >
                        {item.project_name}
                      </p>
                    </>
                  )}
                </p>
              </small>
            </p>
          </div>
        </>
      );
    } else if (item.notification_type == "accepted") {
      return (
        <div>
          <p style={{ fontSize: 15, marginBottom: 1 }}>
            {/* <span
              style={{
                fontSize: 14,
                color: "#808080"
              }}
            >
              {" "}
              {item.username}
            </span> */}{" "}
            <b>{item.username || "You"}</b>{" "}
            <IntlMessages id={"notification.acceptedinvitation"} />
            <b
              style={getRedirectionStyle(item)}
              onClick={() => handleRedirection(item)}
            >
              {item.title}
            </b>
          </p>
          <small
            style={{ fontSize: 12, color: "gray", font: "Segoe UI" }}
            className="flex-x"
          >
            <p
              title={item.business_title}
              style={{
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px",
                maxWidth: "350px"
              }}
            >
              {item.business_title && item.business_title != "" && (
                <>
                  {/* <img
                    src={workspaceSymbolIcon}
                    style={{ height: 16, width: 16, paddingRight: 4 }}
                  />{" "} */}
                  {item.business_title}
                </>
              )}
            </p>
            <p
              style={{
                paddingLeft: 5,
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px"
              }}
            >
              {item.project_name && item.project_name != "" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <i
                    class="fa fa-circle fs-6"
                    style={{ paddingRight: 4, color: "#8f8f8f" }}
                  />
                  <p
                    title={item.project_name}
                    className="fs-12"
                    style={{
                      display: "inline-block",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      paddingRight: "0px",
                      maxWidth: "350px"
                    }}
                  >
                    {item.project_name}
                  </p>
                </div>
              )}
            </p>
          </small>
        </div>
      );
    } else if (item.notification_type == "rejected") {
      return (
        <div>
          <p
            style={{
              fontSize: 14,
              marginBottom: 2,

              color: "#000000",
              font: "Segoe UI"
            }}
          >
            {/* <span
              style={{
                fontSize: 14,
                color: "#808080"
              }}
            >
              {" "}
              {item.username}
            </span> */}{" "}
            <b>{item.username || "You"}</b>{" "}
            <IntlMessages id={"notification.declinedinvitation"} />
            <b
              style={getRedirectionStyle(item)}
              onClick={() => handleRedirection(item)}
            >
              {item.title}
            </b>
          </p>
          <small
            style={{ fontSize: 12, color: "gray", font: "Segoe UI" }}
            className="flex-x"
          >
            <p
              title={item.business_title}
              style={{
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px",
                maxWidth: "350px"
              }}
            >
              {item.business_title && item.business_title != "" && (
                <>
                  {/* <img
                    src={workspaceSymbolIcon}
                    style={{ height: 16, width: 16, paddingRight: 4 }}
                  />{" "} */}
                  {item.business_title}
                </>
              )}
            </p>
            <p
              style={{
                paddingLeft: 5,
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px"
              }}
            >
              {item.project_name && item.project_name != "" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <i
                    class="fa fa-circle fs-6"
                    style={{ paddingRight: 4, color: "#8f8f8f" }}
                  />
                  <p
                    title={item.project_name}
                    className="fs-12"
                    style={{
                      display: "inline-block",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      paddingRight: "0px",
                      maxWidth: "350px"
                    }}
                  >
                    {item.project_name}
                  </p>
                </div>
              )}
            </p>
          </small>
        </div>
      );
    } else if (item.notification_type == "overdue") {
      return (
        <div>
          <p
            style={{
              fontSize: 14,
              marginBottom: 2,

              color: "#000000",
              font: "Segoe UI"
            }}
          >
            {/* <span
              style={{
                fontSize: 14,
                color: "#808080"
              }}
            >
              {" "}
              You
            </span> */}{" "}
            <b
              style={getRedirectionStyle(item)}
              onClick={() => handleRedirection(item)}
            >
              {item.title}
            </b>{" "}
            is overdue
          </p>
          <small
            style={{ fontSize: 12, color: "gray", font: "Segoe UI" }}
            className="flex-x"
          >
            <p
              title={item.business_title}
              style={{
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px",
                maxWidth: "350px"
              }}
            >
              {item.business_title && item.business_title != "" && (
                <>
                  {/* <img
                    src={workspaceSymbolIcon}
                    style={{ height: 16, width: 16, paddingRight: 4 }}
                  />{" "} */}
                  {item.business_title}
                </>
              )}
            </p>
            <p
              style={{
                paddingLeft: 5,
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px"
              }}
            >
              {item.project_name && item.project_name != "" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <i
                    class="fa fa-circle fs-6"
                    style={{ paddingRight: 4, color: "#8f8f8f" }}
                  />
                  <p
                    title={item.project_name}
                    className="fs-12"
                    style={{
                      display: "inline-block",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      paddingRight: "0px",
                      maxWidth: "350px"
                    }}
                  >
                    {item.project_name}
                  </p>
                </div>
              )}
            </p>
          </small>
        </div>
      );
    } else if (item.notification_type == "assign") {
      return (
        <div>
          <p
            style={{
              fontSize: 14,
              marginBottom: 2,

              color: "#000000",
              font: "Segoe UI"
            }}
          >
            {/* <span
              style={{
                fontSize: 14,
                color: "#808080"
              }}
            >
              {" "}
              {item.username}
            </span> */}{" "}
            <b>{item.username || <IntlMessages id={"notification.you"} />}</b>
            {"  "}
            <IntlMessages id={"notification.hasassigned"} />
            <b
              style={getRedirectionStyle(item)}
              onClick={() => handleRedirection(item)}
            >
              {" "}
              {item.title}
            </b>
          </p>
          <small
            style={{ fontSize: 12, color: "gray", font: "Segoe UI" }}
            className="flex-x"
          >
            <p
              title={item.business_title}
              style={{
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px",
                maxWidth: "350px"
              }}
            >
              {item.business_title && item.business_title != "" && (
                <>
                  {/* <img
                    src={workspaceSymbolIcon}
                    style={{ height: 16, width: 16, paddingRight: 4 }}
                  />{" "} */}
                  {item.business_title}
                </>
              )}
            </p>
            <p
              style={{
                paddingLeft: 5,
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px"
              }}
            >
              {item.project_name && item.project_name != "" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <i
                    class="fa fa-circle fs-6"
                    style={{ paddingRight: 4, color: "#8f8f8f" }}
                  />
                  <p
                    title={item.project_name}
                    className="fs-12"
                    style={{
                      display: "inline-block",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      paddingRight: "0px",
                      maxWidth: "350px"
                    }}
                  >
                    {item.project_name}
                  </p>
                </div>
              )}
            </p>
          </small>
        </div>
      );
    } else if (item.notification_type == "subtaskassign") {
      // Text: John has assigned you a task in checklist
      return (
        <div>
          <p
            style={{
              fontSize: 14,
              marginBottom: 2,

              color: "#000000",
              font: "Segoe UI"
            }}
          >
            {" "}
            <b>{item.username || "You"}</b>
            <IntlMessages id={"notification.aassignedtotask"} />
            <b
              style={getRedirectionStyle(item)}
              onClick={() => handleRedirection(item)}
            >
              {item.title}
            </b>{" "}
            checklist
          </p>
          <small
            style={{ fontSize: 12, color: "gray", font: "Segoe UI" }}
            className="flex-x"
          >
            <p
              title={item.business_title}
              style={{
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px",
                maxWidth: "350px"
              }}
            >
              {item.business_title && item.business_title != "" && (
                <>
                  {/* <img
                    src={workspaceSymbolIcon}
                    style={{ height: 16, width: 16, paddingRight: 4 }}
                  />{" "} */}
                  {item.business_title}
                </>
              )}
            </p>
            <p
              style={{
                paddingLeft: 5,
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px"
              }}
            >
              {item.project_name && item.project_name != "" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <i
                    class="fa fa-circle fs-6"
                    style={{ paddingRight: 4, color: "#8f8f8f" }}
                  />
                  <p
                    title={item.project_name}
                    className="fs-12"
                    style={{
                      display: "inline-block",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      paddingRight: "0px",
                      maxWidth: "350px"
                    }}
                  >
                    {item.project_name}
                  </p>
                </div>
              )}
            </p>
          </small>
        </div>
      );
    } else if (item.notification_type == "mention") {
      return (
        <div>
          <p
            style={{
              fontSize: 14,
              color: "#000000",
              font: "Segoe UI",
              marginBottom: 2
            }}
          >
            {" "}
            <b>{item.username || "You"}</b>{" "}
            <IntlMessages id={"notification.mentionedyou"} />
            <b
              style={getRedirectionStyle(item)}
              onClick={() => handleRedirection(item)}
            >
              {item.title}
            </b>
          </p>
          <small
            style={{ fontSize: 12, color: "gray", font: "Segoe UI" }}
            className="flex-x"
          >
            <p
              title={item.business_title}
              style={{
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px",
                maxWidth: "350px"
              }}
            >
              {item.business_title && item.business_title != "" && (
                <>
                  {/* <img
                    src={workspaceSymbolIcon}
                    style={{ height: 16, width: 16, paddingRight: 4 }}
                  />{" "} */}
                  {item.business_title}
                </>
              )}
            </p>
            <p
              style={{
                paddingLeft: 5,
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px"
              }}
            >
              {item.project_name && item.project_name != "" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <i
                    class="fa fa-circle fs-6"
                    style={{ paddingRight: 4, color: "#8f8f8f" }}
                  />
                  <p
                    title={item.project_name}
                    className="fs-12"
                    style={{
                      display: "inline-block",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      paddingRight: "0px",
                      maxWidth: "350px"
                    }}
                  >
                    {item.project_name}
                  </p>
                </div>
              )}
            </p>
          </small>
        </div>
      );
    } else if (item.notification_type == "added") {
      return (
        <div>
          <p
            style={{
              fontSize: 14,
              marginBottom: 2,
              color: "#000000",
              font: "Segoe UI"
            }}
          >
            {" "}
            <b>{item.username}</b>{" "}
            {<IntlMessages id={"notification.hasaddedproject"} />}
            <b
              style={getRedirectionStyle(item)}
              onClick={() => handleRedirection(item)}
            >
              {item.project_name && item.project_name != ""
                ? item.project_name
                : item.title}
            </b>
          </p>
          <small
            style={{ fontSize: 12, color: "gray", font: "Segoe UI" }}
            className="flex-x"
          >
            <p
              title={item.business_title}
              style={{
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px",
                maxWidth: "350px"
              }}
            >
              {item.business_title && item.business_title != "" && (
                <>
                  {/* <img
                    src={workspaceSymbolIcon}
                    style={{ height: 16, width: 16, paddingRight: 4 }}
                  />{" "} */}
                  {item.business_title}
                </>
              )}
            </p>
            <p
              style={{
                paddingLeft: 5,
                display: "inline-block",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "0px"
              }}
            >
              {item.project_name && item.project_name != "" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <i
                    class="fa fa-circle fs-6"
                    style={{ paddingRight: 4, color: "#8f8f8f" }}
                  />
                  <p
                    title={item.project_name}
                    className="fs-12"
                    style={{
                      display: "inline-block",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      paddingRight: "0px",
                      maxWidth: "350px"
                    }}
                  >
                    {item.project_name}
                  </p>
                </div>
              )}
            </p>
          </small>
        </div>
      );
    }
  };
  const getNotificationIcon = type => {
    if (type == "joined") {
      return (
        <>
          <img
            src={joined}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "invited") {
      return (
        <>
          {/* <img src={editProfilePic} style={{ height: "22px", marginTop: "-22px" }} /> */}
          <img
            src={profilePlus}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "accepted") {
      return (
        <>
          <img
            src={acceptInvitation}
            style={{
              alignSelf: "center",
              width: "40px"
            }}
          />
        </>
      );
    } else if (type == "rejected") {
      return (
        <>
          <img
            src={declained}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "overdue") {
      return (
        <>
          <img
            src={overdueTask}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "assign") {
      return (
        <>
          <img
            src={assignNotification}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "subtaskassign") {
      return (
        <>
          <img
            src={assignNotification}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "mention") {
      return (
        <>
          <img
            src={mentioned}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "added") {
      return (
        <>
          <img
            src={added}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "reminder") {
      return (
        <>
          <img
            src={reminder}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    }
  };
  const chooseBGColor = item => {
    if (item.notification_type == "joined") {
      return "rgb(40 167 69 / 64%)";
    } else if (item.notification_type == "invited") {
      return "#a7b7c8";
    } else if (item.notification_type == "accepted") {
      return "#ffc107ad";
    } else if (item.notification_type == "rejected") {
      return "rgb(220 53 69 / 90%)";
    } else {
      return "#ecba00";
    }
  };

  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  return (
    <div className="content-panel">
      <div className="row">
        <div className="col-lg-2 col-md-2 col-sm-0 col-xs-0" />
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 roe-card-style ptb-25">
          {btnLoad && (
            <Loader
              style={{
                position: "absolute",
                top: "50%",
                left: "50%"
              }}
            />
          )}
          <div className=" align-center ">
            <h4 className="mb-0 " style={{ fontSize: 14, color: "black" }}>
              <span style={{ fontWeight: "600" }}>
                <IntlMessages id={"notification.recentnotification"} />
              </span>
            </h4>
          </div>

          {load ? (
            <Shimmer />
          ) : (
            <div style={{ opacity: btnLoad ? 0.3 : 1.0 }}>
              {notifications.length > 0 ? (
                <>
                  {" "}
                  {notifications &&
                    notifications.map((n, i) => {
                      if (n.notification_type !== "accepted") {
                        return (
                          <NotificationWrapper {...props}>
                            <div
                              className={
                                n.notification_type != "invited"
                                  ? "notification-list-container"
                                  : ""
                              }
                              onClick={() => handleRedirection(n)}
                            >
                              <div
                                key={i}
                                className="flex-x"
                                style={{
                                  backgroundColor:
                                    n.notification_type == "invited"
                                      ? "white"
                                      : "",
                                  paddingLeft: "10px ",
                                  paddingRight: "10px",
                                  paddingBottom:
                                    n.notification_type == "invited" ? 5 : 15,
                                  paddingTop: " 15px",
                                  borderTopLeftRadius: "12px",
                                  borderTopRightRadius: "12px",
                                  marginTop: 15,
                                  ...getRedirectionStyle(n)
                                }}
                              >
                                <div>
                                  <div
                                    style={{
                                      borderRadius: "50%",
                                      border: "1 solid",
                                      width: 40,
                                      // background: "#F4F5F7",
                                      height: 40,
                                      objectFit: "fill",
                                      textAlign: "center",
                                      color: "#6f6f6f",
                                      fontSize: 18,
                                      // paddingTop: "9px",
                                      display: "flow-root"
                                    }}
                                  >
                                    {getNotificationIcon(n.notification_type)}
                                  </div>
                                </div>
                                <div
                                  className="mlr-10 flex-1"
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  {generateSentense(n)}
                                </div>
                                <small
                                  style={{
                                    fontSize: "14px",
                                    color: "#808080",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "500"
                                  }}
                                >
                                  {moment(n.createdAt)
                                    .local()
                                    .format("DD MMM YY hh:mm a")}
                                </small>
                              </div>
                              {n.notification_type == "invited" && (
                                <div
                                  style={{
                                    backgroundColor:
                                      n.notification_type == "invited"
                                        ? "white"
                                        : "",
                                    // margin: "12px 0px 0px 0px",
                                    borderBottomLeftRadius: "12px",
                                    borderBottomRightRadius: "12px",
                                    padding: "0px 15px 15px 61px"
                                  }}
                                >
                                  <Button
                                    onClick={() => {
                                      doAcceptRequest(
                                        n.business_collaborator_id,
                                        n.invitation_code,
                                        n.title,
                                        n.id,
                                        i
                                      );
                                    }}
                                    className="c-btn  c-outline-success"
                                    style={{
                                      boxShadow: "none",
                                      marginRight: "5px"
                                    }}
                                  >
                                    <IntlMessages id={"notification.accept"} />
                                  </Button>
                                  {"   "}
                                  <Button
                                    onClick={() => {
                                      doRejectRequest(
                                        n.business_collaborator_id,
                                        n.invitation_code,
                                        n.id,
                                        i
                                      );
                                    }}
                                    className="c-btn  c-outline-danger"
                                    style={{ boxShadow: "none" }}
                                  >
                                    <IntlMessages id={"notification.decline"} />
                                  </Button>
                                </div>
                              )}
                            </div>
                          </NotificationWrapper>
                        );
                      }
                    })}
                  {isViewMore && (
                    <div
                      style={{
                        color: props.PRIMARY_COLOR,
                        fontSize: 14,
                        textDecoration: isViewMoreLoader ? "none" : "underline",
                        textAlign: "center",
                        cursor: isViewMoreLoader ? "not-allowed" : "pointer",
                        paddingBottom: 10
                      }}
                    >
                      {isViewMoreLoader ? (
                        <LinearLoader />
                      ) : (
                        <div
                          style={{
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "2.09rem"
                          }}
                          onClick={() =>
                            isViewMore ? fetchNotification(false, true) : {}
                          }
                        >
                          <IntlMessages id={"globalactivity.viewmorebutton"} />
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <div
                  style={{
                    height: 100,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <p style={{ color: "grey" }}>
                    <IntlMessages id={"notification.nonewnotification"} />
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="col-lg-2 col-md-2 col-sm-0 col-xs-0" />
        <CustomNotification
          show={toast}
          message={toastMessage}
          closeNotification={() => setToast(false)}
        />
        <ChangeWorkspaceAlert
          loader={isAlertActionLoader}
          action="Switch Workspace"
          message={isAlertAction.status && getMessageType(isAlertAction.data)}
          PRIMARY_COLOR={props.topbarTheme.buttonColor}
          show={isAlertAction.data && isAlertAction.status}
          onConfirm={() => {
            isAlertAction.status &&
              selectBusiness(isAlertAction.data.business_id);
          }}
          onCancel={() => setIsAlertAction({ id: "", status: false })}
        />
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {
  update_Project,
  update_token,
  update_business,
  default_business,
  set_labels,
  update_Label,
  set_projects,
  update_recent_Project
})(Notifications);
