import React, { useState, useEffect } from "react";
import { TaskDialogChecklistImage } from "helper/constant";

const CheckListTitle = ({ checklist, PRIMARY_COLOR, updateChecklistTitle }) => {
  const [isEditCheckList, setIsEditCheckList] = useState(false);
  const [CheckList, setCheckList] = useState(checklist);

  useEffect(() => {
    if (checklist) {
      setCheckList(checklist);
    }
  }, [checklist]);

  return (
    <div>
      <div style={{ width: "100%", marginTop: 1 }}>
        {isEditCheckList ? (
          <>
            <div className="add-comment-input-block mt-10">
              <input
                type="text"
                id="subtasktitle"
                defaultValue={CheckList.title}
                className="add-subtask-input inputStyle"
                name="checklistTitle"
                aria-describedby="subtask"
                placeholder="Title"
                autoFocus
                style={{
                  border: "1px solid #e2e2e2",
                  ":focus": {
                    outline: "none",
                    paddingRight: 5
                  }
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    if (CheckList.title) {
                      updateChecklistTitle(CheckList.title, CheckList.id);
                      setIsEditCheckList(false);
                    }
                  }
                }}
                onChange={e =>
                  setCheckList({
                    id: checklist.id,
                    title: e.target.value
                  })
                }
              />
            </div>
            <div className="subtask-action" style={{ margin: "15px 0px" }}>
              <>
                <button
                  className="btn mr-10"
                  style={{
                    boxShadow: "none",
                    borderRadius: "17px",
                    backgroundColor: PRIMARY_COLOR,
                    color: "white"
                  }}
                  // disabled={
                  //   !CheckList.title.match(/^\S+\w{0,50}\S{0,}/g) ? true : false
                  // }
                  disabled={
                    CheckList.title.length == 0 ||
                    !CheckList.title.match(/^\S+\w{0,50}\S{0,}/g)
                      ? true
                      : false
                  }
                  onClick={e => {
                    updateChecklistTitle(CheckList.title, CheckList.id);
                    setIsEditCheckList(false);
                  }}
                >
                  Update
                </button>
                <button
                  className="btn btn"
                  style={{
                    boxShadow: "none",
                    borderRadius: "17px",
                    border: `1px solid ${PRIMARY_COLOR}`,
                    color: PRIMARY_COLOR,
                    width: "80px"
                  }}
                  onClick={() => {
                    setIsEditCheckList(false);
                    setCheckList({
                      id: checklist.id,
                      title: checklist.title
                    });
                  }}
                >
                  Cancel
                </button>
              </>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "inline-block",
              alignItems: "center",
              justifyContent: "flex-start",
              fontWeight: "600",
              padding: "15px 0px",
              whiteSpace: "nowrap",
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              cursor: "pointer"
            }}
            title={"update checklist"}
            onClick={() => {
              setIsEditCheckList(!isEditCheckList);
            }}
          >
            {CheckList.title}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckListTitle;
