import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  assignTaskToUser,
  removeAssignTask,
  getCollaboratorsList
} from "helper/services/collboratorsServices";
import { Alert } from "reactstrap";
import { connect } from "react-redux";
import BusinessListShimmer from "components/shimmer/BusinessListShimmer";
import { ProjectCollaboratorsCross } from "helper/constant";
import { profileBackground } from "helper/methods/getUserAvatarColor";
const ShareModal = ({
  isOpen,
  toggle,
  projectId,
  notify,
  collaboratorList,
  taskId,
  taskAssign,
  updatetaskAssign,
  dummyData,
  topbarTheme,
  PRIMARY_COLOR,
  assignSubtask,
  socket
}) => {
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [collaboratorListArr, setCollaboratorListArr] = useState([]);
  const [isCollabFetchingLoader, setIsCollabFetchingLoader] = useState(false);
  const profileImage = {
    borderRadius: "50%",
    border: "1 solid",
    width: 42,
    height: 41,
    objectFit: "cover",
    textAlign: "center"
  };
  useEffect(() => {
    if (isOpen && projectId) {
      if (collaboratorList && collaboratorList?.length) {
        setCollaboratorListArr(collaboratorList);
      } else {
        retrieveCollaboratsList(projectId);
      }
    }
    return () => {
      setCollaboratorListArr([]);
    };
  }, [isOpen]);
  const handleAssignTask = async user => {
    if (projectId && taskId && projectId) {
      try {
        if (socket) {
          assignSubtask({
            project_id: projectId ? projectId.toString() : "",
            task_id: taskId ? taskId.toString() : "",
            user_id: user ? user.id.toString() : ""
          });
          toggle();
        } else {
          const Response = await assignTaskToUser({
            project_id: projectId ? projectId.toString() : "",
            task_id: taskId ? taskId.toString() : "",
            user_id: user ? user.id.toString() : ""
          });
          if (Response) {
            if (Response.error) {
            }
            if (Response.message) {
              updatetaskAssign(user, taskId);
            }
          }
        }
      } catch (e) {}
    }
  };
  const handleRemoveAssignTask = async (user, collabId) => {
    if (projectId && taskId && projectId && collabId) {
      try {
        setIsLoader(true);

        const Response = await removeAssignTask({
          project_id: projectId ? projectId.toString() : "",
          task_id: taskId ? taskId.toString() : "",
          collaborator_id: collabId ? collabId.toString() : ""
        });
        if (Response) {
          if (Response.error) {
            setIsLoader(false);
          }
          if (Response.message) {
            setIsLoader(false);
            updatetaskAssign(null, taskId);
          }
        }
      } catch (e) {}
    }
  };
  const retrieveCollaboratsList = async projectId => {
    if (projectId) {
      try {
        setIsCollabFetchingLoader(true);
        const result = await getCollaboratorsList(projectId);
        if (result) {
          if (result.error) {
          }
          if (result.data) {
            setCollaboratorListArr([...result.data]);
          }
        }
        setIsCollabFetchingLoader(false);
      } catch (error) {
        setIsCollabFetchingLoader(false);
      }
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} centered>
      <ModalHeader>
        <span style={{ fontSize: 17, color: "black", fontWeight: "600" }}>
          Manage Assignee
        </span>
        <img
          alt=""
          src={ProjectCollaboratorsCross}
          style={{
            height: "18px",
            width: "15px",
            marginRight: "0px",
            marginTop: "0px",
            textAlign: "center",
            position: "absolute",
            top: 22,
            right: "20px",
            cursor: "pointer"
          }}
          onClick={() => toggle()}
        ></img>
      </ModalHeader>
      <Alert
        className="c-"
        style={{ backgroundColor: "RED ", color: "white" }}
        isOpen={visible}
        toggle={() => setVisible(false)}
      >
        {visible ? errorMessage : ""}
      </Alert>
      <ModalBody style={{ padding: "10px" }}>
        <div style={{ height: 300, overflow: "auto" }}>
          <div
            className="container list-container"
            /* key={i} */
          >
            {isCollabFetchingLoader ? (
              // <Loader />
              <BusinessListShimmer />
            ) : (
              <>
                {collaboratorListArr &&
                  collaboratorListArr.map((data, i) => {
                    if (data.user && data.requestStatus === "ACCEPTED") {
                      return (
                        <div
                          className="ptb-5 ma-0 flex-x align-center"
                          key={i}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {/* change class name up className="ptb-10 ma-0 flex-x align-center" */}
                          {data.user?.userMeta &&
                          data.user?.userMeta !== null ? (
                            <div>
                              <img
                                alt=""
                                src={data.user.userMeta.thumbnail}
                                style={profileImage}
                              />
                            </div>
                          ) : (
                            <div
                              style={profileBackground(
                                data.user && data.user.name
                                  ? data.user.name
                                  : data.user.email
                              )}
                            >
                              {data.user && data.user.name
                                ? data.user.name.charAt(0).toUpperCase()
                                : data.user.email.charAt(0).toUpperCase()}
                            </div>
                          )}
                          <div className="mlr-10 flex-1">
                            <div style={{ fontSize: "14px", color: "black" }}>
                              {/* change class name up fs-14 demi-bold-text */}
                              {data.user && data.user.name
                                ? data.user.name
                                : data.user.email.split("@")[0]}
                              {"     "}
                              <span>
                                {/* change class name up className="fs-12" */}
                                {data.role === "OWNER" ? (
                                  <span
                                    className="c-btn-round ma-5 fs-10"
                                    style={{
                                      padding: "2px 10px 4px 10px",
                                      backgroundColor: "#dedede"
                                    }}
                                  >
                                    owner
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                            <div style={{ fontSize: "14px", color: "#808080" }}>
                              {data.user.email}
                            </div>
                          </div>

                          <div
                            className="task-icons"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {/* <i className="fas fa-trash"></i> */}

                            <span style={{ paddingLeft: 5 }}>
                              {taskAssign ? (
                                data.user.id !== taskAssign.id ? (
                                  //
                                  <Button
                                    className=""
                                    style={{
                                      backgroundColor: topbarTheme.buttonColor,
                                      lineSpacing: "1px",
                                      borderRadius: "5px",
                                      fontSize: "14px",
                                      display: "flex",
                                      color: "white",
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: 71,
                                      height: 30,
                                      border: "0px solid "
                                    }}
                                    onClick={() => handleAssignTask(data.user)}
                                  >
                                    Assign
                                  </Button>
                                ) : (
                                  <Button
                                    className=""
                                    style={{
                                      backgroundColor: topbarTheme.buttonColor,
                                      lineSpacing: "1px",
                                      borderRadius: "5px",
                                      fontSize: "14px",
                                      display: "flex",
                                      color: "white",
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: 71,
                                      height: 30,
                                      border: "0px solid "
                                    }}
                                    onClick={() =>
                                      handleRemoveAssignTask(data.user, data.id)
                                    }
                                  >
                                    Remove
                                  </Button>
                                )
                              ) : (
                                <Button
                                  className=""
                                  style={{
                                    backgroundColor: topbarTheme.buttonColor,
                                    lineSpacing: "1px",
                                    borderRadius: "5px",
                                    fontSize: "14px",
                                    display: "flex",
                                    color: "white",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 71,
                                    height: 30,
                                    border: "0px solid "
                                  }}
                                  onClick={() => handleAssignTask(data.user)}
                                >
                                  Assign
                                </Button>
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  })}
              </>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, null)(ShareModal);
