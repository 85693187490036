import styled from "styled-components";

const CommentModalWrapper = styled.div`
  .quill {
    min-height: 15px !important;
    max-height: 200px !important;
  }
  .ql-editor {
    min-height: 15px !important;
    max-height: 200px !important;
    padding: 0px 15px !important;
  }
  .ql-toolbar.ql-snow {
    border: none !important;
  }
  .ql-container.ql-snow {
    border: none !important;
  }
  .ql-editor ql-blank {
    min-height: 15px !important;
    max-height: 200px !important;
  }
  ul {
    padding-inline-start: 17px !important;
  }
  ol {
    padding-inline-start: 15px !important;
  }
`;

export default CommentModalWrapper;
