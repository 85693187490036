import React, { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import {
  workspaceSymbolIcon,
  sectionIcon,
  newProjectPlus
} from "helper/constant";
import { getColorCode, ProjectColors } from "components/projects/utils/color";
import classNames from "classnames";
import ShimmerLoader from "components/shimmer/BusinessListShimmer";
import IntlMessages from "util/intlMessages";
const WorkspaceDropdown = ({
  changeColor,
  value,
  selectedProject,
  setSelectedProject,
  type,
  workspaceData,
  PRIMARY_COLOR,
  isError,
  toggleError,
  disbleError,
  isLoader,
  toggleAddProject
  // setDefaultWorkspace
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const changeSelectionNested = (workspace, project, section) => {
    setSelectedProject({
      workspace: workspace,
      project: project,
      section: section
    });
    if (workspace && project && section) {
      disbleError();
    }
  };

  return (
    <ButtonDropdown
      style={{ width: "100%" }}
      isOpen={dropdownOpen}
      className="c-outline-lightd"
      toggle={() => setDropdownOpen(!dropdownOpen)}
    >
      <DropdownToggle
        // caret
        style={{
          backgroundColor: "white",
          width: "100%",
          textTransform: "capitalize",
          border: isError ? "1px solid red" : "1px solid #E6E6E6",
          borderRadius: 5,
          fontSize: 12,
          color: "grey",
          padding: 0,
          paddingLeft: 10,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          textAlign: "left"
        }}
        className={"form-control"}
      >
        {workspaceData ? (
          <>
            {selectedProject && selectedProject.workspace ? (
              <>
                {" "}
                <img alt="" src={workspaceSymbolIcon} />
                <span style={{ paddingLeft: 7 }}>
                  {selectedProject.workspace.title}{" "}
                </span>
              </>
            ) : (
              <IntlMessages id={"workspace.workspace"} />
            )}{" "}
            /
            {selectedProject &&
            selectedProject?.project &&
            selectedProject?.section ? (
              <>
                {" "}
                {/* <i
                  className="fa fa-circle  fs-8  grey--text"
                  style={{
                    color: getColorCode(selectedProject.project.color),
                    paddingRight: "8px"
                  }}
                /> */}
                {selectedProject.project.name}
              </>
            ) : (
              <IntlMessages id={"globalquickaddactions.selectproject1"} />
            )}{" "}
            /{" "}
            {selectedProject ? (
              selectedProject.section?.title
            ) : (
              <IntlMessages id={"today.selectlikst"} />
            )}
          </>
        ) : (
          <IntlMessages id={"workspace.workspace"} />
        )}
      </DropdownToggle>

      <DropdownMenu
        style={{
          border: "0px solid ",
          background: "#FFFFFF",
          boxShadow: "0px 0px 15px #0000001A",
          minWidth: 400,
          marginTop: 10,
          padding: 10
        }}
      >
        <div style={{ height: 250, overflow: "scroll" }}>
          {isLoader ? (
            <div>
              <ShimmerLoader />
            </div>
          ) : (
            <>
              {workspaceData &&
                workspaceData.map((workspace, i) => {
                  return (
                    <div key={i} style={{ padding: 10 }}>
                      <div
                        style={{
                          paddingLeft: 8,
                          paddingRight: 8,
                          fontSize: 14,
                          marginTop: i == 0 ? 0 : 10,
                          width: "100%",
                          backgroundColor: "#F4F5F7",
                          border: "0.5px solid #E6E6E6",
                          borderRadius: 3,
                          display: "flex",
                          alignItems: "center"
                        }}
                        className="flex-x"
                      >
                        <div
                          className="flex-1 "
                          style={{ paddingTop: 5, paddingBottom: 5 }}
                        >
                          {/* <i className="fa fa-building fs-12  pr-2 grey--text" /> */}
                          <img alt="" src={workspaceSymbolIcon} />
                          <span style={{ marginLeft: 9 }}>
                            {workspace.title}
                          </span>
                        </div>{" "}
                        <div>
                          {selectedProject &&
                            selectedProject.workspace.id == workspace.id && (
                              <i
                                className={classNames("fas fa-check fs-12")}
                                style={{
                                  color: PRIMARY_COLOR ? PRIMARY_COLOR : "gray"
                                }}
                              ></i>
                            )}
                        </div>
                      </div>

                      <div
                        toggle="false"
                        style={{ width: 300, fontSize: 14 }}
                        //   onClick={() => changeSelection(data)}
                      >
                        {workspace &&
                        workspace.project &&
                        workspace.project.length > 0 ? (
                          workspace.project.map((project, j) => {
                            if (project) {
                              return (
                                <div toggle="false" key={j}>
                                  <div
                                    style={{
                                      // width: 200,
                                      textTransform: "capitalize",
                                      paddingLeft: 30,
                                      zIndex: "0"
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: 5,
                                        height: 25,
                                        alignItems: "center",
                                        display: "inline-block",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        width: 335
                                      }}
                                    >
                                      <i
                                        className="fa fa-circle  fs-10  grey--text"
                                        style={{
                                          color: getColorCode(project.color),
                                          paddingRight: "8px"
                                        }}
                                      />
                                      {project.name}
                                    </div>
                                    {project &&
                                      project.section &&
                                      project.section.length > 0 &&
                                      project.section.map((section, i) => {
                                        return (
                                          <DropdownItem
                                            key={i}
                                            onClick={() =>
                                              changeSelectionNested(
                                                workspace,
                                                project,
                                                section
                                              )
                                            }
                                            style={{ paddingLeft: 24 }}
                                          >
                                            <div className="flex-x">
                                              <div className="flex-1">
                                                <img alt="" src={sectionIcon} />
                                                <span
                                                  style={{
                                                    color: "grey",
                                                    fontSize: 12,
                                                    paddingLeft: 12
                                                  }}
                                                >
                                                  {section.title}
                                                </span>
                                              </div>
                                              {selectedProject &&
                                                selectedProject.section &&
                                                selectedProject.section.id ==
                                                  section.id && (
                                                  <i
                                                    style={{
                                                      color: PRIMARY_COLOR
                                                    }}
                                                    className={classNames(
                                                      "fas fa-check fs-12",
                                                      "ml-15",
                                                      "text-center",
                                                      "justify-content-center"
                                                    )}
                                                  ></i>
                                                )}
                                            </div>
                                          </DropdownItem>
                                        );
                                      })}
                                  </div>
                                </div>
                              );
                            } else {
                            }
                          })
                        ) : (
                          <div
                            style={{ paddingLeft: 11 }}
                            className="text-muted"
                            onClick={() => {
                              // setDefaultWorkspace(workspace)

                              toggleAddProject(workspace.id);
                            }}
                          >
                            <IntlMessages
                              id={"globalquickaddactions.taskdecription2"}
                            />
                            <br />
                            <span
                              className="custom-link-action"
                              style={{ fontWeight: 600 }}
                            >
                              <IntlMessages
                                id={"globalquickaddactions.clickhere"}
                              />
                            </span>{" "}
                            <IntlMessages id={"globalquickaddactions.addone"} />
                          </div>
                        )}
                        {workspace?.project?.length > 0 && (
                          <div
                            style={{ paddingLeft: 38, color: PRIMARY_COLOR }}
                          >
                            <img
                              alt=""
                              src={newProjectPlus}
                              style={{ paddingRight: 4 }}
                            />
                            <span
                              className="hover-underline cursor-pointer"
                              style={{
                                paddingLeft: 1,
                                color: PRIMARY_COLOR,
                                textTransform: "capitalize"
                              }}
                              onClick={() => {
                                // setDefaultWorkspace(workspace)

                                toggleAddProject(workspace.id);
                              }}
                            >
                              <IntlMessages id={"new_project"} />
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default WorkspaceDropdown;
