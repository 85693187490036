import styled from "styled-components";

const SearchPopoverWrapper = styled.div`
display:inline-block;

.chip-button:hover{
    .search{
        display:block !important;
    }
  
}
.search{
    display:none !important;
// display:${propss => (propss.selected ? "" : "none")}
}
`;

export default SearchPopoverWrapper;
