import styled from "styled-components";

const BusinessWrapper = styled.div`
  .img-sm {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
  }
  .business-name-input {
  border: "1px solid #e2e2e2 !important",  
    resize: none;
    border-radius: 3px !important;
    border: 0;
    font-size: 14px !important;
    padding: 8px !important;
    &:focus {
      outline: 0;
    }
  } 
  .trash-icon{
       @media (max-width: 350.98px) {
            position: absolute !important;
                left: 35% !important;        
                bottom: -9px !important;
            }
            @media (max-width: 350.98px) {
            position: absolute !important;
                left: 36% !important;        
                bottom: -4px !important;
            }
             @media (max-width: 420.98px) {
           position: absolute !important;
            left: 38% !important;        
            bottom: -4px !important;
        }
        @media (max-width: 575.98px) {
           position: absolute !important;
            left: 42% !important;        
            bottom: -4px !important;
        }
         @media (max-width: 770.98px) {
           position: absolute !important;
            left: 38% !important;        
            bottom: -4px !important;
        }
        @media (max-width: 1024.98px) {
           position: absolute !important;
            left: 42% !important;        
            bottom: -9px !important;
        } @media (max-width: 2608.98px) {
           position: absolute !important;
            left: 41% !important;        
            bottom: -5px !important;
        }
  }
  .file-overlay {
      display: none;
    }
    &:hover {
      .file-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; 
      
        .options {
          width: 40px;
          height: 40px;
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        
            background-color: rgba(0, 0, 0, 1);
          i {
            font-size: 14px;
            color:rgb(255, 0, 0,1);
        
            
          }
        }
      }
    }
    
  
`;

export default BusinessWrapper;
