import styled from "styled-components";

const HeaderPopover = styled.div`
  @media only screen and (max-width: 600px) {
    .header-popover .popover {
      width: 295px !important;
      padding-right: 15px !important;
    }
  }
  @media only screen and (max-width: 600px) {
    .popover-body {
      width: 295px !important;
      padding-right: 15px !important;
    }
  }

  // @media only screen and (max-width: 600px) {
  //   .Search-data {
  //     height: 400px !important;
  //   }
  // }
  .popover-body {
    // width: 520px !important;
    padding: 10px 3px !important;
  }
`;

export default HeaderPopover;
