/** @format */

const projectModalAction = {
  OPEN_PROJECT_MODAL: "OPEN_PROJECT_MODAL",
  CLOSE_PROJECT_MODAL: "CLOSE_PROJECT_MODAL",

  open_project_modal: data => {
    return {
      type: projectModalAction.OPEN_PROJECT_MODAL,
      project: data.project
    };
  },
  close_project_modal: data => {
    return {
      type: projectModalAction.CLOSE_PROJECT_MODAL
    };
  }
};
export default projectModalAction;
