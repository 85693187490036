import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";

const StyleSheet = {
  container: {
    border: "0px solid rgba(255, 255, 255, 1)"
  },
  card: {
    height: "100%",
    width: "100%",
    backgroundSize: "802px 113px"
  },
  line: {
    width: "90%",
    height: "15px",
    alignSelf: "center",
    marginLeft: "16px"
  },
  line2: {
    width: "40px",
    height: "8px"
  }
};
class CardShimmer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <Shimmer>
          <div className={classes.card}></div>
        </Shimmer>
      </>
    );
  }
}

export default injectSheet(StyleSheet)(CardShimmer);
