import React from "react";
import IntlMessages from "util/intlMessages";
const ToastMessage = (message, url, actionMessage, history) => {
  return (
    <div>
      {message ? (
        message
      ) : (
        <IntlMessages id={"task-details.something_went_wrong"} />
      )}
      &nbsp;
      {url ? (
        <span
          onClick={() => history(url)}
          className="text-underline cursor-pointer"
          style={{ textDecoration: "underline" }}
          // href={redirectLink}
        >
          {actionMessage ? actionMessage : ""}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default ToastMessage;
