import React, { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { getColorCode } from "./utils/color";
const ColorPopover = ({ options, selectedColor, changeColor }) => {
  const [selectedLable, setSelectedLable] = useState(selectedColor);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [selectedColor, changeColor] = useState(options[0].color);
  const changeSelectedColor = (value, code) => {
    changeColor(code);
    changeColor(value);
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <ButtonDropdown
      style={{ width: "100%" }}
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
    >
      <DropdownToggle
        // caret
        style={{
          backgroundColor: "transparent",
          width: "100%",
          textTransform: "capitalize",
          border: "1px solid #E6E6E6",
          borderRadius: 5,
          textAlign: "left",
          fontSize: 14,
          color: "#495057",
          padding: 0,
          paddingLeft: 20,
          zIndex: 0
        }}
        className={"form-control"}
      >
        <i
          className="fa fa-circle fs-10"
          style={{
            paddingRight: 5,
            color: getColorCode(
              selectedColor == "grau" ? "grey" : selectedColor
            )
          }}
        ></i>{" "}
        {selectedLable}
      </DropdownToggle>
      <DropdownMenu style={{ paddingLeft: 17 }}>
        {/* <div className="container" style={{width:"100%"}}> */}

        {options &&
          options.map((color, i) => {
            return (
              <>
                <div className="label-color-block flex-x text-center">
                  <div
                    className="label-color-block flex-x text-center"
                    style={{
                      background: color.color,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    onClick={() => {
                      // changeSelectedColor(
                      //   color.value,
                      //   color.color,
                      //   color.label
                      // );
                      changeSelectedColor(color.value, color.color);
                      setSelectedLable(color.label);
                    }}
                  >
                    {((selectedColor && selectedColor == color.label) ||
                      (selectedColor && selectedColor == color.value)) && (
                      <i
                        className="fa fa-check"
                        aria-hidden="true"
                        style={{ color: "#FFF" }}
                      ></i>
                    )}
                  </div>
                </div>

                {/* <DropdownItem
                key={i * 1454}
                onClick={() => changeSelectedColor(color.value, color.color)}
              >
                <div style={{ width: 800, textTransform: "capitalize" }}>
                  <i
                    className="fa fa-circle fs-10"
                    style={{
                      paddingRight: 20,
                      color: color.color
                    }}
                  ></i>
                  
                  
                  <div style={{ width: 50,height:25,   backgroundColor: color.color }}>

                  </div>
                  {color.label}
                </div>
              </DropdownItem> */}
              </>
            );
          })}

        {/* </div> */}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default ColorPopover;
