import React, { useState, useRef, useEffect } from "react";
import { addSection } from "services/socket";
import { projectSocketRoomPrefix } from "constatnt";
import { AddSectionCardImage } from "helper/constant";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";

const AddSectionCard = props => {
  const [cardInput, setCardInput] = useState("");
  const textArea = useRef(null);
  const { showNotifications } = props;
  const addSectionCardRef = useRef(null);

  useEffect(() => {
    if (props.addSectionFlag) {
      scrollToBottom();
    } else {
    }
  }, [props.addSectionFlag]);

  const addNewCard = async () => {
    if (cardInput.match(/^\S+\w{0,50}\S{0,}/g)) {
      try {
        //Socket add section
        let payload = {
          title: cardInput,
          orderId: props.totalsection + 1,
          projectId: props.projectId,
          roomname: `${projectSocketRoomPrefix}${props.projectId}`
        };
        addSection(payload, cb => {
          if (cb.status == "Ok") {
            props.setSectionCardFlag();
            setCardInput("");
          } else {
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
            showNotifications();
          }
        });
      } catch (e) {}
    }
  };

  const scrollToBottom = () => {
    addSectionCardRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  };

  const closeAddingCard = () => {
    if (cardInput === "") {
      props.setSectionCardFlag();
    }
  };

  return (
    <div>
      <div>
        {!props.addSectionFlag ? (
          <div
            style={{
              textAlign: "center",
              marginBottom: "50px",
              marginTop: "55px"
            }}
            className="add-subtask-block add-card-block fs-14 "
            onClick={() => props.setSectionCardFlag()}
          >
            {/* <i className="fas fa-plus"></i> */}
            <img
              alt=""
              src={AddSectionCardImage}
              style={{
                height: "12px",
                width: "12px",
                marginRight: "10px",
                marginTop: "-3px",
                textAlign: "center"
              }}
            ></img>
            <span
              style={{ fontSize: "14px", color: "black", fontWeight: "600" }}
            >
              {<IntlMessages id={"project.add_new_list"} />}
            </span>
          </div>
        ) : (
          <div style={{ paddingBottom: 20 }}>
            <div
              className="add-comment-input-block add-card-block roe-box-shadow"
              style={{
                // border: "1px solid rgb(221, 221, 221)",
                backgroundColor: "white",
                width: "100%",
                padding: "10px 15px"
              }}
            >
              <textarea
                style={{ backgroundColor: "#f4f5f7", marginTop: "5px " }}
                ref={textArea}
                value={cardInput}
                autoFocus
                rows="2"
                className="add-subtask-input"
                name="taskTitle"
                placeholder={InjectPlaceHolderMessage(
                  "project.namethislist",
                  props?.props?.LanguageSwitcher?.language?.languageId
                )}
                // placeholder="Name this list 55"
                onChange={e => setCardInput(e.target.value)}
                onBlur={closeAddingCard}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    if (!event.shiftKey) {
                      event.preventDefault();
                      addNewCard();
                    }
                  }
                }}
              ></textarea>
              <div ref={addSectionCardRef} className="subtask-action ">
                <button
                  className="btn btn-secondary "
                  onClick={addNewCard}
                  disabled={!cardInput.match(/^\S+\w{0,50}\S{0,}/g)}
                  style={{
                    backgroundColor: props.PRIMARY_COLOR
                      ? props.PRIMARY_COLOR
                      : "#ff6b6b",
                    // backgroundColor: "#ff6b6b",
                    border: 0,
                    // marginLeft: "8px",
                    marginRight: "10px"
                  }}
                >
                  {/* change class name up className="button btn mr-10 ml-2" */}
                  {<IntlMessages id={"project.add_list"} />}
                </button>
                <button
                  className="btn btn-secondary"
                  style={{
                    background: "transparent",
                    border: 0,
                    color: "#000",
                    padding: "0px",
                    margin: "12px"
                  }}
                  onClick={() => props.setSectionCardFlag()}
                >
                  {<IntlMessages id={"project.cancel"} />}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddSectionCard;
