import {
  svgPriority_1,
  svgPriority_2,
  svgPriority_3,
  svgPriority_4,
  svgNo_priority
} from "helper/constant";
export const PRIMARY_COLOR = "#FF6B6B";
export const allPriority = [
  {
    name: "priority_1",
    value: "Priority 1",
    color: "red",
    path: svgPriority_1,
    number: 1
  },
  {
    name: "priority_2",
    value: "Priority 2",
    color: "orange",
    path: svgPriority_2,
    number: 2
  },
  {
    name: "priority_3",
    value: "Priority 3",
    color: "skyblue",
    path: svgPriority_3,
    number: 3
  },
  {
    name: "priority_4",
    value: "Priority 4",
    color: "green",
    path: svgPriority_4,
    number: 4
  },
  {
    name: "no_priority",
    value: "No priority",
    color: "#c7c7c7",
    path: svgNo_priority,
    number: 0
  }
];

export const getPriorityNumber = colorname => {
  let colorcode = null;
  allPriority &&
    allPriority.filter(priority => {
      if (priority.name === colorname) {
        colorcode = priority.number;
        return priority.number;
      }
    });
  return colorcode;
};

export const getPriorityColorCode = colorname => {
  let colorcode = null;
  allPriority &&
    allPriority.filter(priority => {
      if (priority.name === colorname) {
        colorcode = priority.color;
        return priority.path;
      }
    });
  return colorcode;
};
export const getPriorityPath = colorname => {
  let colorcode = null;
  allPriority &&
    allPriority.filter(priority => {
      if (priority.name === colorname) {
        colorcode = priority.path;
        return priority.path;
      }
    });
  return colorcode;
};
export const getPriorityValue = colorname => {
  let colorcode = null;
  allPriority &&
    allPriority.filter(priority => {
      if (priority.name === colorname) {
        colorcode = priority.value;
        return priority.value;
      }
    });
  return colorcode;
};
export const firebaseConfig = {
  apiKey: "AIzaSyBRv2N1BSWcUUHsw5oDQ07sli_89oiXP6U",
  authDomain: "todoist-2fc62.firebaseapp.com",
  projectId: "todoist-2fc62",
  storageBucket: "todoist-2fc62.appspot.com",
  messagingSenderId: "682817600624",
  appId: "1:682817600624:web:5ee9a64873c898ac8748b5",
  measurementId: "G-M5W6EVZ13R"
};

export const firebasePushCert =
  "BPgJDoxdIZ-PB_wVb5hFQ_B833E3XLgmhTvVdt1sMtpIUDJEvy6t3w6JOF5pU90nxvatOnxtjHM14AuQrcbbaM8";

export const firebaseAPIKey =
  "AAAAnvsZvHA:APA91bGlwuJWLZzbaDgUJwQBJGMMt0HG0RIxEGm9ce4ddtLYPFyeQ5Y_sLDI4kj8eK5lob9UDvUaj73lg1JXKv8uiPbptZofewoc2LXN7zq9P5Is1FTBeKpDcsB5AxcUL6kdG9MQjv8I";

export const userTopic = "user_";
export const projectSocketRoomPrefix = "project_";
export const businessSocketRoomPrefix = "business_";
export const dashboardSocketRoomPrefix = "dashboard_";
export const dateFilterSocketRoomPrefix = "date_filter_";
export const todaySocketRoomPrefix = "today_";
export const upcommingSocketRoomPrefix = "upcoming_";
export const labelSocketRoomPrefix = "label_";
export const prioritySocketRoomPrefix = "priority_";
export const taskSocketRoomPrefix = "task_";
export const taskDetailsSocketRoomPrefix = "task_details_";
export const notificationsSocketRoomPrefix = "notification_";
export const chatSocketRoomPrefix = "user_";

// local
// export const socketEndpoint = "http://localhost:8081"; //local socket endpoint
// export const socketPath = "/socket.io"; // local socket endpoint

// sandbox
export const socketEndpoint = "https://web.theappauthors.com"; // live socket endpoint
export const socketPath = "/a169-todo/socket.io";

// Production
// export const socketEndpoint = "https://dataio.fiinish.com"; // live socket endpoint
// export const socketPath = "/socket.io";
export const invalidWorkspace = "NOT_WORKSPACE_COLLABORATOR";
export const invalidProject = "NOT_PROJECT_COLLABORATOR";

export const pageLimit = 10;
