import React from "react";
import "assets/plugins/jquery-flipster/dist/jquery.flipster.min.css";
import "assets/css/theme.css";

const Landing = props => {
  React.useEffect(() => {
    props.history.push("/login");
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        overflow: "scroll"
      }}
    >
      <header className="header">
        <div className="branding">
          <div className="container position-relative">
            <nav className="navbar navbar-expand-lg">
              <h1 className="site-logo">
                <a className="navbar-brand" href="/">
                  <img
                    className="logo-icon"
                    src={require("assets/images/Logos1.svg")}
                    style={{ height: 40 }}
                    alt=""
                  />{" "}
                  <span
                    className="logo-text"
                    style={{ color: "#0073E6", fontSize: 22 }}
                  >
                    ToDo
                  </span>
                </a>
              </h1>
            </nav>
            {/* // Free Version ONLY */}
            <ul className="social-list list-inline  position-absolute">
              {/* change class name up "social-list list-inline mb-0 position-absolute" */}
              <li className="list-inline-item">
                <a
                  className=" btn btn-primary font-weight-bold  "
                  // change name up className="text-dark"
                  href="/login"
                  style={{
                    backgroundColor: "#0073E6",
                    border: 0,
                    margin: "5",
                    marginTop: "-25px"
                  }}
                >
                  <p> Login</p>
                </a>
              </li>
              {/* <li className="list-inline-item"><a className="text-dark" href="#"><i className="fab fa-facebook-f fa-fw" /></a></li>
                            <li className="list-inline-item"><a className="text-dark" href="#"><i className="fab fa-instagram fa-fw" /></a></li> */}
            </ul>
            {/*//social-list*/}
          </div>
          {/*//container*/}
        </div>
        {/*//branding*/}
      </header>
      {/*//header*/}
      <section className="hero-section">
        <div className="container">
          <div className="row figure-holder">
            <div className="col-12 col-md-6 pt-3 pt-md-4">
              <h2
                className="site-headline font-weight-bold "
                style={{
                  marginBottom: "10px",
                  marginTop: "48px",
                  paddingTop: "48px"
                }}
              >
                {/* change class name className="site-headline font-weight-bold mt-lg-5 pt-lg-5" */}
                The best way to promote your app and convert users.
              </h2>
              <div className="site-tagline mb-3">
                High converting Bootstrap landing page template made for app
                developers. Built with Bootstrap 4 and Sass. Get started now!
              </div>
              <div className="cta-btns">
                <div style={{ marginBottom: "25px", marginTop: "25px" }}>
                  {/* class change up  className="mb-15"   */}
                  <a
                    className="btn btn-primary font-weight-bold "
                    href="#"
                    style={{ backgroundColor: "#0073E6", border: 0 }}
                  >
                    Try ToDo for FREE
                  </a>
                </div>
                <ul className="app-stores list-unstyled list-inline mx-auto mx-md-0 d-inline-block">
                  <li
                    className="list-inline-item"
                    style={{ marginRight: "16px" }}
                  >
                    {/* class change up  className="list-inline-item mr-3"  */}
                    <a href="#">
                      <img
                        className="ios"
                        src={require("assets/images/appstore-apple.svg")}
                        alt="app-store"
                      />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <img
                        className="android"
                        src={require("assets/images/appstore-android.svg")}
                        alt="google play"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*//row*/}
        </div>
      </section>
      {/*//hero-section*/}
      <section className="logos-section theme-bg-primary py-5 text-center">
        <div className="container">
          <h3 style={{ marginBottom: "45px" }}>
            Trusted by hundreds of software businesses
          </h3>
          {/* h3 tage class name change */}
          <div className="logos-row row mx-auto">
            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
              <div className="item-inner">
                <img src={require("assets/images/logos/logo-1.svg")} />
              </div>
              {/*//item-inner*/}
            </div>
            {/*//item*/}
            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
              <div className="item-inner">
                <img src={require("assets/images/logos/logo-2.svg")} />
              </div>
              {/*//item-inner*/}
            </div>
            {/*//item*/}
            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
              <div className="item-inner">
                <img src={require("assets/images/logos/logo-3.svg")} />
              </div>
              {/*//item-inner*/}
            </div>
            {/*//item*/}
            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
              <div className="item-inner">
                <img src={require("assets/images/logos/logo-4.svg")} />
              </div>
              {/*//item-inner*/}
            </div>
            {/*//item*/}
            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
              <div className="item-inner">
                <img src={require("assets/images/logos/logo-5.svg")} />
              </div>
              {/*//item-inner*/}
            </div>
            {/*//item*/}
            <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
              <div className="item-inner">
                <img src={require("assets/images/logos/logo-6.svg")} />
              </div>
              {/*//item-inner*/}
            </div>
            {/*//item*/}
          </div>
        </div>
        {/*//container*/}
      </section>
      {/*//logo-section*/}
      <section className="benefits-section py-5">
        <div className="container py-lg-5">
          <h3
            className=" text-center font-weight-bold "
            style={{ marginBottom: "48px" }}
          >
            Market your mobile app effectively with todo
          </h3>
          <div className="row">
            <div className="item col-12 col-lg-4">
              <div className="item-inner text-center p-3 p-lg-5">
                <img
                  style={{ marginBottom: "16px" }}
                  src={require("assets/images/icon-target.svg")}
                />
                {/* change class in up   className="mb-3"  */}
                <h5>Focused on UX</h5>
                <div>
                  Use this area to list your app's key benefits. Lorem ipsum
                  dolor sit amet, consectetuer adipiscing elit. Aenean commodo
                  ligula eget dolor. Aenean massa.{" "}
                </div>
              </div>
              {/*//item-inner*/}
            </div>
            {/*//item*/}
            <div className="item col-12 col-lg-4">
              <div className="item-inner text-center p-3 p-lg-5">
                <img
                  style={{ marginBottom: "16px" }}
                  src={require("assets/images/icon-rocket.svg")}
                />
                {/* change class in up   className="mb-3"  */}
                <h5>Convert Users</h5>
                <div>
                  Use this area to list your app's key benefits. Lorem ipsum
                  dolor sit amet, consectetuer adipiscing elit. Aenean commodo
                  ligula eget dolor. Aenean massa.{" "}
                </div>
              </div>
              {/*//item-inner*/}
            </div>
            {/*//item*/}
            <div className="item col-12 col-lg-4">
              <div className="item-inner text-center p-3 p-lg-5">
                <img
                  style={{ marginBottom: "16px" }}
                  src={require("assets/images/icon-cogs.svg")}
                />
                {/* change class in up   className="mb-3"  */}
                <h5>Easy Customisations</h5>
                <div>
                  Use this area to list your app's key benefits. Lorem ipsum
                  dolor sit amet, consectetuer adipiscing elit. Aenean commodo
                  ligula eget dolor. Aenean massa.{" "}
                </div>
              </div>
              {/*//item-inner*/}
            </div>
            {/*//item*/}
          </div>
          {/*//row*/}
          <div className="text-center" style={{ paddingTop: "16px" }}>
            {/* change class in up  className="pt-3 text-center" */}
            <a
              className="btn btn-primary theme-btn theme-btn-ghost font-weight-bold"
              href="#"
              style={{ backgroundColor: "#0073E6", border: 0, color: "#fff" }}
            >
              Learn More
            </a>
          </div>
        </div>
      </section>
      {/*//benefits-section*/}
      <section className="features-section py-5">
        <div className="container py-lg-5">
          <h3
            className="text-center font-weight-bold section-heading"
            style={{ marginBottom: "10px" }}
          >
            {/* change class in up className="mb-3 text-center font-weight-bold section-heading" */}
            Feature Highlights
          </h3>
          <div className="row pt-5 mb-5">
            <div className="col-12 col-md-6 col-xl-5 offset-xl-1 d-none d-md-block">
              <img
                className="product-figure product-figure-1 img-fluid"
                src={require("assets/images/product-figure-1.png")}
              />
            </div>
            <div className="col-12 col-md-6 col-xl-5 pr-xl-3 pt-md-3">
              <div
                className="card rounded border-0 shadow-lg "
                style={{ marginBottom: "20px" }}
              >
                <div className="card-body p-4">
                  <h5 className="card-title">
                    <i className="far fa-chart-bar mr-2 mr-lg-3 text-primary fa-lg fa-fw" />
                    Feature Lorem Ipsum
                  </h5>
                  <p className="card-text">
                    List one of your product's main features here. The
                    screenshots used in this template are taken from{" "}
                    <a href="">Bootstrap 4 admin template Appify</a>.{" "}
                  </p>
                  <a href="#">
                    Learn more <span className="more-arrow">→</span>
                  </a>
                </div>
              </div>
              {/*//card*/}
              <div
                className="card rounded border-0 shadow-lg"
                style={{ marginBottom: "20px" }}
              >
                {/* class change className="card rounded border-0 shadow-lg mb-5" */}
                <div className="card-body p-4">
                  <h5 className="card-title">
                    <i className="fas fa-laptop-code mr-2 mr-lg-3 text-primary fa-lg fa-fw" />
                    Feature Consectetuer
                  </h5>
                  <p className="card-text">
                    List one of your product's main features here. The
                    screenshots used in this template are taken from{" "}
                    <a href="#">Bootstrap 4 admin template Appify</a>.
                  </p>
                  <a href="#">
                    Learn more <span className="more-arrow">→</span>
                  </a>
                </div>
              </div>
              {/*//card*/}
              <div className="card rounded border-0 shadow-lg">
                <div className="card-body p-4">
                  <h5 className="card-title">
                    <i className="far fa-calendar-alt mr-2 mr-lg-3 text-primary fa-lg fa-fw" />
                    Feature Lorem Ipsum
                  </h5>
                  <p className="card-text">
                    List one of your product's main features here. The
                    screenshots used in this template are taken from{" "}
                    <a href="#">Bootstrap 4 admin template Appify</a>.
                  </p>
                  <a href="#">
                    Learn more <span className="more-arrow">→</span>
                  </a>
                </div>
              </div>
              {/*//card*/}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-xl-5 order-md-2 pr-xl-3 d-none d-md-block">
              <img
                className="product-figure product-figure-2 img-fluid"
                src={require("assets/images/product-figure-2.png")}
              />
            </div>
            <div className="col-12 col-md-6 col-xl-5 order-md-1 offset-xl-1 pt-xl-5">
              <div className="card rounded border-0 shadow-lg  mb-20">
                <div className="card-body p-4">
                  <h5 className="card-title">
                    <i className="fas fa-microphone-alt mr-2 mr-lg-3 text-primary fa-lg fa-fw" />
                    Feature Commodo
                  </h5>
                  <p className="card-text">
                    List one of your product's main features here. The
                    screenshots used in this template are taken from{" "}
                    <a href="#">Bootstrap 4 admin template Appify</a>. Donec
                    quam felis, ultricies nec, pellentesque eu, pretium quis,
                    sem.{" "}
                  </p>
                  <a href="#">
                    Learn more <span className="more-arrow">→</span>
                  </a>
                </div>
              </div>
              {/*//card*/}
              <div className="card rounded border-0 shadow-lg">
                <div className="card-body p-4">
                  <h5 className="card-title">
                    <i className="far fa-comments mr-2 mr-lg-3 text-primary fa-lg fa-fw" />
                    Feature Ligula Eget
                  </h5>
                  <p className="card-text">
                    List one of your product's main features here. Lorem ipsum
                    dolor sit amet. The screenshots used in this template are
                    taken from <a href="#">Bootstrap 4 admin template Appify</a>
                    .
                  </p>
                  <a href="#">
                    Learn more <span className="more-arrow">→</span>
                  </a>
                </div>
              </div>
              {/*//card*/}
            </div>
          </div>
          <div className=" text-center" style={{ marginTop: "48px" }}>
            {/* className="pt-5 text-center" */}
            <a
              className="btn btn-primary theme-btn theme-btn-ghost font-weight-bold"
              href="#"
              style={{ backgroundColor: "#0073E6", border: 0, color: "#fff" }}
            >
              View all features
            </a>
          </div>
        </div>
        {/*//container*/}
      </section>
      {/*//features-section*/}
      <section
        className="cta-section py-5 theme-bg-secondary text-center"
        style={{ backgroundColor: "#EFF2F5" }}
      >
        <div className="container">
          <h3 className="font-weight-bold" style={{ marginBottom: "16px" }}>
            {/* change class name font-weight-bold mb-3 */}
            Ready to turn your visitors to app users?
          </h3>
          <div className="mx-auto single-col-max-width section-intro">
            Try todo Free Today. Lorem ipsum dolor sit amet, consectetuer
            adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
            sociis natoque penatibus et magnis dis.
          </div>
        </div>
      </section>
      {/*//cta-section*/}
      <section id="quote" className="testimonial-section py-5">
        <div
          className="container "
          style={{
            marginTop: "48px",
            paddingRight: "15px",
            paddingLeft: "15px",
            marginBottom: "48px"
          }}
        >
          {/* className="container py-lg-5" */}
          <h3 className=" text-center">
            Loved by thousands of app developers like you
          </h3>
          <div id="flipster-carousel" className="flipster-carousel pt-md-3">
            <div className="flip-items pb-5">
              <div className="flip-item text-center text-md-left">
                <div className="item-inner shadow-lg rounded">
                  <h5 className="mb-2">Amazing App!</h5>
                  <div className="ratings text-primary mb-3">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                  <div className="mb-3">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis.Lorem ipsum dolor sit amet,
                    consectetuer adipiscing elit. Aenean commodo ligula eget
                    dolor. Aenean massa.
                  </div>
                  <div className="source media flex-column flex-md-row">
                    <img
                      className="source-profile rounded-circle mr-md-3 mx-auto mx-md-0"
                      // src={require("assets/images/users/user-1.jpg")}
                    />
                    <div className="source-info media-body pt-3">
                      <div>Tony Carter</div>
                      <div>London, UK</div>
                    </div>
                  </div>
                </div>
                {/*//item-inner*/}
              </div>
              {/*//flip-item*/}
              <div className="flip-item">
                <div className="item-inner shadow-lg rounded">
                  <h5 className="mb-2">Marvellous!</h5>
                  <div className="ratings text-primary mb-3">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star-half" />
                  </div>
                  <div className="mb-3">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis.Lorem ipsum dolor sit amet,
                    consectetuer adipiscing elit. Aenean commodo ligula eget
                    dolor. Aenean massa.
                  </div>
                  <div className="source media ">
                    <img
                      className="source-profile rounded-circle mr-3"
                      // src={require("assets/images/users/user-2.jpg")}
                    />
                    <div className="source-info media-body pt-3">
                      <div>Helen Owens</div>
                      <div>New York, US</div>
                    </div>
                  </div>
                </div>
                {/*//item-inner*/}
              </div>
              {/*//flip-item*/}
              <div className="flip-item">
                <div className="item-inner shadow-lg rounded">
                  <h5 className="mb-2">Actually Impressive!</h5>
                  <div className="ratings text-primary mb-3">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                  <div className="mb-3">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis.Lorem ipsum dolor sit amet,
                    consectetuer adipiscing elit. Aenean commodo ligula eget
                    dolor. Aenean massa.
                  </div>
                  <div className="source media">
                    <img
                      className="source-profile rounded-circle mr-3"
                      // src={require("assets/images/users/user-3.jpg")}
                    />
                    <div className="source-info media-body pt-3">
                      <div>Scott Rivera</div>
                      <div>Florida, US</div>
                    </div>
                  </div>
                </div>
                {/*//item-inner*/}
              </div>
              {/*//flip-item*/}
              <div className="flip-item">
                <div className="item-inner shadow-lg rounded">
                  <h5 className="mb-2">Total Game Changer!</h5>
                  <div className="ratings text-primary mb-3">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                  <div className="mb-3">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis.Lorem ipsum dolor sit amet,
                    consectetuer adipiscing elit. Aenean commodo ligula eget
                    dolor. Aenean massa.
                  </div>
                  <div className="source media">
                    <img
                      className="source-profile rounded-circle mr-3"
                      // src={require("assets/images/users/user-4.jpg")}
                    />
                    <div className="source-info media-body pt-3">
                      <div>Charles Brewer</div>
                      <div>San Francisco, US</div>
                    </div>
                  </div>
                </div>
                {/*//item-inner*/}
              </div>
              {/*//flip-item*/}
              <div className="flip-item">
                <div className="item-inner shadow-lg rounded">
                  <h5 className="mb-2">Just Perfect!</h5>
                  <div className="ratings text-primary mb-3">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                  <div className="mb-3">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis.Lorem ipsum dolor sit amet,
                    consectetuer adipiscing elit. Aenean commodo ligula eget
                    dolor. Aenean massa.
                  </div>
                  <div className="source media">
                    <img
                      className="source-profile rounded-circle mr-3"
                      // src={require("assets/images/users/user-5.jpg")}
                    />
                    <div className="source-info media-body pt-3">
                      <div>Deborah Reed</div>
                      <div>Paris, France</div>
                    </div>
                  </div>
                </div>
                {/*//item-inner*/}
              </div>
              {/*//flip-item*/}
              <div className="flip-item">
                <div className="item-inner shadow-lg rounded">
                  <h5 className="mb-2">Great App!</h5>
                  <div className="ratings text-primary mb-3">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                  <div className="mb-3">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis.Lorem ipsum dolor sit amet,
                    consectetuer adipiscing elit. Aenean commodo ligula eget
                    dolor. Aenean massa.
                  </div>
                  <div className="source media">
                    <img
                      className="source-profile rounded-circle mr-3"
                      // src={require("assets/images/users/user-6.jpg")}
                    />
                    <div className="source-info media-body pt-3">
                      <div>Tony Cheng</div>
                      <div>San Francisco, US</div>
                    </div>
                  </div>
                </div>
                {/*//item-inner*/}
              </div>
              {/*//flip-item*/}
              <div className="flip-item">
                <div className="item-inner shadow-lg rounded">
                  <h5 className="mb-2">Exceptional!</h5>
                  <div className="ratings text-primary mb-3">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                  <div className="mb-3">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis.Lorem ipsum dolor sit amet,
                    consectetuer adipiscing elit. Aenean commodo ligula eget
                    dolor. Aenean massa.
                  </div>
                  <div className="source media">
                    <img
                      className="source-profile rounded-circle mr-3"
                      // src={require("assets/images/users/user-7.jpg")}
                    />
                    <div className="source-info media-body pt-3">
                      <div>Kimberly Wells</div>
                      <div>London, UK</div>
                    </div>
                  </div>
                </div>
                {/*//item-inner*/}
              </div>
              {/*//flip-item*/}
            </div>
            {/*//items-wrapper*/}
            <div className="pt-5 text-center">
              <a
                className="btn btn-primary theme-btn font-weight-bold"
                href="#"
                style={{ backgroundColor: "#0073E6", border: 0 }}
              >
                Try todo Now
              </a>
            </div>
          </div>
        </div>
        {/*//container*/}
      </section>
      {/*//testimonial-section*/}
      <footer className="footer theme-bg-primary">
        <div
          className="container py-5 mb-3"
          style={{
            paddingTop: "48px",
            paddingBottom: "48px",
            paddingLeft: "15px",
            paddingRight: "15px"
          }}
        >
          {/* change class name className="container py-5 mb-3"  */}
          <div className="row">
            <div className="footer-col col-6 col-lg-3">
              <h4 className="col-heading">About todo</h4>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Our Story</a>
                </li>
                <li>
                  <a href="#">Pricing</a>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
                <li>
                  <a href="#">Jobs</a>
                </li>
              </ul>
            </div>
            {/*//footer-col*/}
            <div className="footer-col col-6 col-lg-3">
              <h4 className="col-heading">Resources</h4>
              <ul className="list-unstyled">
                <li>
                  <a href="#">FAQs</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Support</a>
                </li>
                <li>
                  <a href="#">Developer APIs</a>
                </li>
              </ul>
            </div>
            {/*//footer-col*/}
            <div className="footer-col col-6 col-lg-3">
              <h4 className="col-heading">Legal</h4>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Privacy</a>
                </li>
                <li>
                  <a href="#">Terms of Services</a>
                </li>
                <li>
                  <a href="#">Security</a>
                </li>
              </ul>
            </div>
            {/*//footer-col*/}
            <div className="footer-col col-6 col-lg-3">
              <h4 className="col-heading">Stay Connected</h4>
              <ul className="social-list list-unstyled mb-0">
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-instagram fa-fw" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-twitter fa-fw" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-facebook-f fa-fw" />
                  </a>
                </li>
              </ul>
              {/*//social-list*/}
              <div className="mb-2">
                Company Name
                <br />
                2419 Locust View Drive <br />
                San Francisco, CA, 94143
              </div>
              <div>
                <a href="#">hello@yourcompany.com</a>
              </div>
            </div>
            {/*//footer-col*/}
          </div>
          {/*//row*/}
        </div>
        {/*//container*/}
        <div className="container">
          <hr />
        </div>
        <div className="download-area py-4">
          <div className="container text-center">
            <h3 style={{ marginBottom: "16px" }}>Get the app</h3>
            {/* change class  className="mb-3" */}
            <div
              className="section-intro  single-col-max-width mx-auto"
              style={{
                marginBottom: "24px",
                marginLeft: "35px",
                marginRight: "35px"
              }}
            >
              {/* change class  className="section-intro mb-4 single-col-max-width mx-auto" */}
              Download our apps now. lorem ipsum dolor sit amet, consectetuer
              adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
              Cum sociis natoque penatibus et magnis dis.{" "}
            </div>
            <ul className="app-stores list-unstyled list-inline mx-auto  d-inline-block">
              <li
                className="list-inline-item"
                style={{ marginBottom: "16px", marginRight: "16px" }}
              >
                {/* class name change className="list-inline-item mr-3" */}
                <a href="#">
                  <img
                    className="ios"
                    src={require("assets/images/appstore-apple.svg")}
                    alt="app-store"
                  />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#">
                  <img
                    className="android"
                    src={require("assets/images/appstore-android.svg")}
                    alt="google play"
                  />
                </a>
              </li>
            </ul>
          </div>
          {/*//container*/}
        </div>
        {/*//download-area*/}
        {/* <div className="footer-bottom text-center pb-5">
                    <small className="copyright">Designed with <i className="fas fa-heart" style={{ color: '#fb866a' }} /> by <a href="http://themes.3rdwavemedia.com" >Xiaoying Riley</a> for developers</small>
                </div> */}
      </footer>
      {/* Javascript */}
      {/* Page Specific JS */}
    </div>
  );
};

export default Landing;
