import styled from "styled-components";

const WeekDateButtonWrapper = styled.div`
  .btn {
    background: white !important;
    border: 0px !important;
  }
`;

export default WeekDateButtonWrapper;
