/** @format */

import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import Radium from "radium";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import CommentEditorWrapper from "./CommentEditorWrapper.js";
import { PRIMARY_COLOR } from "constatnt";
import MentionPicker from "components/projects/comments/MentionPickerComment";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
var atValues = [];
const hashValues = [];

function CommentEditorInput(props) {
  // in some component where the editor is used

  const {
    value,
    placeholder,
    onChangeValue,
    toggleInput,
    topbarTheme,
    collaboratorsList,
    handleSubmitData,
    isEmptySpace,
    setIsEmptySpace
  } = props;
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (collaboratorsList) {
      var collabs = [];
      collaboratorsList.map((data, i) => {
        if (data.user) {
          collabs.push({
            id: data.user.id,
            username: data.user.name,
            value: data.user.name
          });
        }
      });
      atValues = collabs;

      // atValues = collaboratorsListArr;
      // ReactQuill.getEditor().focus();
    }
    return () => {};
  }, []);
  // Autofocus method
  const useFocusAndSetRef = ref => {
    ref = useCallback(
      node => {
        if (node !== null) {
          ref.current = node; // it is not done on it's own
          const len = value.length;
          const selection = { index: len, length: len };

          node.setEditorSelection(node.editor, selection);
        }
      },
      [ref]
    );

    return ref;
  };

  let editorRef;
  editorRef = useFocusAndSetRef(editorRef);
  const handleSubmit = () => {
    if (
      value.length > 0 &&
      isEmptySpace &&
      value !== "<p><br></p><p><br></p>"
    ) {
      handleSubmitData(description);
    }
  };
  const handleKeyDown = e => {
    if (e.shiftKey && e.keyCode == 13) {
      if (
        value.length > 0 &&
        isEmptySpace &&
        value !== "<<p><br></p><p><br></p>>"
      ) {
        handleSubmit();
      }
    } else {
      if (e.keyCode == 13) {
      }
    }
  };

  return (
    <>
      <CommentEditorWrapper>
        <div className="quill-editor-comment">
          <ReactQuill
            // preserveWhitespace
            ref={editorRef}
            autoFocus
            value={value}
            onChange={(content, delta, source, editor) => {
              onChangeValue(content);
              setDescription(content);
              // console.log(editor.getText().match(/^\s+$/));
              if (editor.getText().match(/^\s+$/) == null) {
                setIsEmptySpace(true);
              } else {
                setIsEmptySpace(false);
              }
            }}
            theme={"bubble"}
            placeholder={InjectPlaceHolderMessage(
              "project.write_something_here",
              props?.LanguageSwitcher?.language?.languageId
            )}
            modules={CommentEditorInput.modules}
            formats={CommentEditorInput.formats}
            onKeyDown={e => handleKeyDown(e)}
            bounds={".app"}
            // onBlur={(e, s, delta, sss) => {
            //   // if source s==silent then allow to copy else if it is user than toggle input
            //   if (s !== "silent") {
            //     toggleInput();
            //   }
            // }}
          />
        </div>
      </CommentEditorWrapper>

      <div style={{ margin: "10px 0px" }}></div>
    </>
  );
}
// export default CardTask;
CommentEditorInput.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike", "blockquote", "link"],
    [{ list: "ordered" }, { list: "bullet" }],
    [
      {
        // adding new color increase width in style.css in current directry for to keep in one row otherwise it will move to next row
        color: ["#F00", "#0F0", "#00F", "#000" /* "color-picker" */]
      }
    ]
  ],
  clipboard: {
    matchVisual: false
  },
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    source: function(searchTerm, renderList, mentionChar) {
      let values;
      if (mentionChar === "@") {
        values = atValues;
      }
      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    }
  }
};
// CommentEditorInput.keyboard({
//   key: 'B',
//   shortKey: true
// }, function(range, context) {
//   CommentEditorInput.formatText(range, 'bold', true);
// });

// addBinding may also be called with one parameter,
// in the same form as in initialization
// CommentEditorInput.keyboard.addBinding({ key: 'B', shiftKey: true }, handler);

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
CommentEditorInput.formats = [
  "autofocus",
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "mention",
  "color"
];

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {})(Radium(CommentEditorInput));
