import React, { useState, useEffect } from "react";
import {
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import { getUser } from "helper/services/authServices";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import EditBusinessModal from "components/business/AddBusiness";
import AuthActions from "redux/auth/actions";

import AddBusiness from "components/business/CreateWorkspace&InviteTeam";
import ToastMessage from "components/notifications/ToastMessage";
import { useToasts } from "react-toast-notifications";
import {
  getBusinessList,
  swicthBusiness
} from "helper/services/businessServices";
import { getProject, getLables } from "helper/services/projectServices";
import { businessImage } from "helper/constant";
import BusinessListShimmer from "components/shimmer/BusinessListShimmer";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import labelActions from "redux/labels/actions";
import projectActions from "redux/projects/actions";
import businessActions from "redux/business/actions";
import CustomNotification from "util/CustomNotification";

const { update_token, update_business } = AuthActions;
const { default_business } = businessActions;
const { set_labels, update_Label } = labelActions;
const { set_projects } = projectActions;
const BusinessModal = props => {
  const [isAddBusiness, setIsAddBusiness] = useState(false);
  const [isShowArchived, setIsShowArchived] = useState(false);
  const [isToggleAllowed, setIsToggleAllowed] = useState(true);
  const { modal, setmodal, topbarTheme } = props;
  const [businessListArr, setBusinessListArr] = useState([]);
  const [archivedbusinessListArr, setArchivedBusinessListArr] = useState([]);
  const [isBusinessLoader, setIsBusinessLoader] = useState(false);
  const [editBusinessData, setEditBusinessData] = useState("");
  const [isBusinessModalStatus, setIsBusinessModalStatus] = useState("Add");
  const [isDefaultLoader, setIsDefaultLoader] = useState({
    id: "",
    status: false
  });
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [addBusiness, setAddBusiness] = useState(false);
  const { addToast } = useToasts();
  let toggleProps = {};
  useEffect(() => {
    if (modal) {
      retriveBusiness();
    }
    return () => {
      setBusinessListArr([]);
      setBusinessListArr([]);
      setArchivedBusinessListArr([]);
      setIsToggleAllowed(true);
    };
  }, [modal]);
  const profileImage = {
    borderRadius: "50%",
    width: 42,
    height: 42,
    objectFit: "cover",
    textAlign: "center",
    boxShadow: "0 0.125rem 0.1875rem rgb(0 0 0 / 3%)"
  };
  const placeholderImage = {
    borderRadius: "50%",
    width: 42,
    height: 42,
    objectFit: "cover",
    textAlign: "center",
    boxShadow: "0 0.125rem 0.1875rem rgb(0 0 0 / 3%)"
  };
  const profileBackground = {
    borderRadius: "50%",
    width: 50,
    background: "rgb(250,0,0)",
    height: 50,
    objectFit: "fill",
    textAlign: "center"
  };
  const InCap = {
    lineHeight: 2.2,
    fontSize: 20,
    color: "white",
    textAlign: "center"
  };

  const retriveBusiness = async () => {
    try {
      setIsBusinessLoader(true);
      const Response = await getBusinessList();
      if (Response) {
        if (Response.error) {
        }
        if (Response.data) {
          setIsBusinessLoader(false);
          console.log(
            Response.data?.some(
              a =>
                a.collaborator[0]?.isDefault &&
                props?.auth?.user?.businessId === a.id
            )
          );
          // check if user have any one default busienss if yes than modal header have toggle option otherwise user cannot toggle
          if (
            Response.data?.some(
              a =>
                a.collaborator[0]?.isDefault &&
                props?.auth?.user?.businessId === a.id
            )
          ) {
            setIsToggleAllowed(true);
          } else {
            setIsToggleAllowed(false);
          }
          await filterArchiveBusiness(Response.data);
        }
      }
    } catch (e) {}
  };
  const filterArchiveBusiness = async businessArr => {
    try {
      if (businessArr?.length > 0) {
        let archivedBusinessArr = [];
        let unarchivedBusinessArr = [];
        await Promise.all(
          (archivedBusinessArr =
            businessArr && businessArr.filter(business => business.isArchived))
        );
        await Promise.all(
          (archivedBusinessArr =
            archivedBusinessArr &&
            archivedBusinessArr
              .sort(
                (a, b) =>
                  a.collaborator[0]?.isDefault - b.collaborator[0]?.isDefault
              )
              .reverse())
        );
        await Promise.all(
          (unarchivedBusinessArr =
            businessArr && businessArr.filter(business => !business.isArchived))
        );
        await Promise.all(
          (unarchivedBusinessArr =
            unarchivedBusinessArr &&
            unarchivedBusinessArr
              .sort(
                (a, b) =>
                  a.collaborator[0]?.isDefault - b.collaborator[0]?.isDefault
              )
              .reverse())
        );

        setBusinessListArr([...unarchivedBusinessArr]);
        setIsShowArchived(unarchivedBusinessArr?.length > 0 ? false : true);
        setArchivedBusinessListArr([...archivedBusinessArr]);
        checkForDefaulBusinessInArchive([...archivedBusinessArr]);
      } else {
        setBusinessListArr([]);
        setArchivedBusinessListArr([]);
      }
    } catch (e) {}
  };
  const checkForDefaulBusinessInArchive = async business => {
    var getDefault = [];
    if (business) {
      getDefault =
        business && business.filter(data => data.collaborator[0]?.isDefault);
    }

    if (getDefault && getDefault.length > 0) {
      setIsShowArchived(true);
    } else {
      setIsShowArchived(false);
    }
  };
  const updateProjectandLabel = async token => {
    fecthProject(token);
    fetchLabels(token);
  };
  const fetchLabels = async token => {
    try {
      const Response = await getLables(token);
      if (Response) {
        if (Response.data) {
          props.set_labels({ labels: Response.data });
          updateSidebarLabelToSidebar(Response.data);
        }
        if (Response.error) {
        }
      }
    } catch (e) {}
  };

  const fecthProject = async token => {
    const Response = await getProject(token);
    if (Response) {
      props.set_projects({ projects: Response.data });
      updateSidebarProjectToSidebar(Response.data);
    }
  };
  const checkforunique = (datachild, toCheckValue) => {
    datachild.map((data, i) => {
      if (data.id === toCheckValue.id) {
        return true;
      }
      return false;
    });
  };
  const selectBusiness = async id => {
    try {
      setIsDefaultLoader({
        id: id,
        status: true
      });
      const Response = await swicthBusiness(id);
      if (Response) {
        if (Response.data) {
          let businessArr = businessListArr;
          businessArr.map((data, i) => {
            if (data.id == Response.data.business.id) {
              businessArr[i].isDefault = Response.data.business.isDefault;
            } else {
              businessArr[i].isDefault = false;
            }
          });
          setBusinessListArr([...businessArr]);

          await updateToken(
            Response.data.token,
            Response.data.business.title,
            Response.data.business?.businessMeta
              ? Response.data.business?.businessMeta
              : null,
            Response.data.business.id
          );

          await props.handleBusinessJoinRoom(
            props.auth.user.businessId,
            Response.data.business.id,
            Response.data.token
          );

          updateProjectandLabel(Response.data.token);
          setIsDefaultLoader({
            id: "",
            status: false
          });
          // if (props.history.location.pathname !== "/dashboard") {
          //   props.history.push("/dashboard", {
          //     token: Response.data.token
          //   });
          // }
          if (props.history.location.pathname !== "/today") {
            props.history.push("/today", {
              token: Response.data.token
            });
          }

          setmodal(!modal);
        }
        if (Response.error) {
          setIsDefaultLoader({
            id: "",
            status: false
          });
        }
      }
    } catch (e) {
      setIsDefaultLoader({
        id: "",
        status: false
      });
    }
  };
  /* by passing id it will be become default business */
  const updateDefaultBusiness = id => {
    let businessArr = businessListArr;
    businessArr.map((data, i) => {
      if (data.id == id) {
        businessArr[i].isDefault = true;
      } else {
        businessArr[i].isDefault = false;
      }
    });
    setBusinessListArr([...businessArr]);

    setmodal(!modal);
  };
  function onHoverStyle(div) {
    document.getElementById(div).style.background = "#f5f5f5";
    // document.getElementById(div).style.cursor = "pointer";
    // e.target.style.background = "#f5f5f5";
  }
  function onMouseLeave(div) {
    document.getElementById(div).style.background = "#fff";
    // e.target.style.background = "#fff";
  }
  const updateBusinessData = businessData => {
    let businessArr = businessListArr;
    businessArr.map((data, i) => {
      if (data.id == businessData.id) {
        businessArr[i] = businessData;
      }
    });
    setBusinessListArr([...businessArr]);
  };
  const updateToken = (token, businessName, businessMeta, id) => {
    props.update_token(token);
    props.default_business({
      id: id,
      businessName: businessName,
      businessMeta: businessMeta
    });
    fecthProject(token);
    fetchLabels(token);
    // updateUser(token);
    props.update_business({ businessId: id, businessName, businessMeta });

    setmodal(!modal);
  };
  const handleRedirectWorkspace = business => {
    props.history.push(`/business/${business.id}`, {
      business,
      isDefault: business?.collaborator[0]?.isDefault
    });
    setmodal(!modal);
  };
  const updateUser = async token => {
    try {
      const result = await getUser(token);
      if (result) {
      }
    } catch (err) {}
  };
  const pushNewBusiness = data => {
    let businessArr = businessListArr;
    businessArr.map((data, i) => {
      businessArr[i].isDefault = false;
    });
    setBusinessListArr([...businessArr, data]);
    setIsAddBusiness(false);
    if (props.history.location.pathname !== "/today") {
      props.history.push("/today");
    }
    /* if (props.history.location.pathname !== "/dashboard") {
      props.history.push("/dashboard");
    } */
    setmodal(!modal);
  };
  const openEditModal = data => {
    if (data) {
      setEditBusinessData(data);
      setIsBusinessModalStatus("update");
      setIsAddBusiness(true);
    }
  };
  const checkIsOwner = collaboratorArr => {
    const user = props?.auth?.user;
    var status = false;
    collaboratorArr.map(collaborator => {
      if (
        collaborator.role.toLowerCase() == "owner" &&
        user.id == collaborator.user.id
      ) {
        status = true;
      }
    });
    return status;
  };
  const showNotifications = message => {
    addToast(ToastMessage(message), {
      appearance: message ? "success" : "error",
      autoDismiss: true
    });
    // setToast(true);
    // setToastMessage(message ? message : "something went wrong");
    // setTimeout(() => {
    //   setToast(false);
    //   setToastMessage("");
    // }, 2500);
  };
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={() => (isToggleAllowed ? setmodal(!modal) : false)}
        size="md"
        centered
        {...toggleProps}
      >
        {isToggleAllowed ? (
          <ModalHeader
            toggle={() => (isToggleAllowed ? setmodal(!modal) : false)}
            {...toggleProps}
          >
            <span style={{ fontWeight: 500, fontSize: "18px" }}>
              Manage Workspace
            </span>
          </ModalHeader>
        ) : (
          <ModalHeader>
            <span style={{ fontWeight: 500, fontSize: "18px" }}>
              Manage Workspace
            </span>
          </ModalHeader>
        )}

        <ModalBody>
          <busienssListWrapper>
            <div
              className="container list-container"
              style={{
                overflow: " scroll",
                height: " 370px",
                paddingRight: "5px"
              }}

              /* key={i} */
            >
              <div className="text-muted fs-14">
                {businessListArr
                  ? businessListArr.length > 1
                    ? businessListArr.length + " Workspaces"
                    : !isBusinessLoader &&
                      businessListArr?.length === 0 &&
                      archivedbusinessListArr?.length === 0 && (
                        <div
                          style={{
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "center"
                          }}
                        >
                          No workspace found,
                          <br />
                          Add workspace than you will be able to do any action
                        </div>
                      )
                  : ""}
              </div>
              {isBusinessLoader && <BusinessListShimmer />}
              {businessListArr && businessListArr?.length > 0 && (
                <div>
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: "1em .5em",
                      padding: "0 2em 1em 0"
                    }}
                  >
                    <tbody>
                      {businessListArr.map((business, i) => {
                        return (
                          <tr
                            key={i}
                            id={`divqw${i}`}
                            onMouseEnter={() => onHoverStyle(`divqw${i}`)}
                            onMouseLeave={() => onMouseLeave(`divqw${i}`)}
                          >
                            {/* <td
                              onClick={() => handleRedirectWorkspace(business)}
                              style={{
                                paddingBottom: 8,
                                paddingTop: 8,
                                paddingLeft: 8
                              }}
                            >
                              <div style={{ paddingRight: 10 }}>
                                {
                                  <>
                                    {business?.collaborator[0]?.isDefault ? (
                                      <i
                                        className="fa fa-check-circle"
                                        style={{
                                          color: topbarTheme?.buttonColor
                                            ? topbarTheme.buttonColor
                                            : "black"
                                        }}
                                        aria-hidden="true"
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-check-circle"
                                        style={{
                                          color: "gray"
                                        }}
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </>
                                }
                              </div>
                            </td> */}
                            <td
                              onClick={() => handleRedirectWorkspace(business)}
                              style={{
                                width: 1,
                                paddingBottom: 8,
                                paddingTop: 8,
                                cursor: "pointer",
                                paddingLeft: 8
                              }}
                            >
                              {" "}
                              <div
                                style={
                                  businessImage
                                    ? profileImage
                                    : profileBackground
                                }
                              >
                                {business?.businessMeta ? (
                                  <img
                                    style={profileImage}
                                    src={business?.businessMeta?.thumbnail}
                                  />
                                ) : (
                                  <img
                                    style={placeholderImage}
                                    src={businessImage}
                                  />
                                )}
                              </div>
                            </td>
                            <td
                              onClick={() => handleRedirectWorkspace(business)}
                              style={{
                                width: "100%",
                                paddingBottom: 8,
                                paddingTop: 8,
                                cursor: "pointer",
                                paddingLeft: 8
                              }}
                            >
                              <div className="mlr-5  " id="changeBusinessss">
                                <p className=" fs-14 fw-400  demi-bold-text">
                                  {business && business.title}
                                </p>
                                {/* <small className="text-muted">{data.user.email}</small> */}
                              </div>
                            </td>
                            {/* <td
                            onClick={() => openEditModal(business)}
                            style={{ paddingBottom: 8, paddingTop: 8 }}
                          >
                            {" "}
                            {checkIsOwner(business.collaborator) && (
                              <div className="task-icons">
                                <RoyTooltip id={`edit`} title="Edit">
                                  <i
                                    className="fas fa-edit text-muted"
                                    id="edit"
                                  />
                                </RoyTooltip>
                              </div>
                            )}
                          </td> */}

                            <td
                              // onClick={()=>selectBusiness(business.id)}
                              style={{ paddingBottom: 8, paddingTop: 8 }}
                            >
                              {" "}
                              {isDefaultLoader.status &&
                              isDefaultLoader.id === business.id ? (
                                <Loader height="23px" width="18px" />
                              ) : !business?.collaborator[0]?.isDefault ? (
                                <div
                                  className="task-icons plr-10"
                                  style={{ textAlign: "center" }}
                                >
                                  <button
                                    className="btn button button-hover btn-sm"
                                    style={{
                                      border: `1px solid ${topbarTheme?.buttonColor}`,
                                      color: topbarTheme?.buttonColor
                                    }}
                                    onClick={() => selectBusiness(business.id)}
                                  >
                                    Switch
                                  </button>
                                </div>
                              ) : (
                                // <span
                                //   style={{
                                //     color: topbarTheme?.buttonColor
                                //       ? topbarTheme.buttonColor
                                //       : "",
                                //       fontSize:"14px",
                                //       paddingLeft:"3px"
                                //   }}
                                // >
                                //   Selected
                                // </span>
                                <div
                                  className="task-icons plr-10"
                                  style={{ textAlign: "end" }}
                                >
                                  <i
                                    className="fa fa-check-circle"
                                    style={{
                                      color: topbarTheme?.buttonColor
                                        ? topbarTheme.buttonColor
                                        : "black"
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              )}
                              {/* <RoyTooltip id={`info`} title="Info">
                                <i
                                  className="fas fa-info-circle text-muted"
                                  id="info"
                                />
                              </RoyTooltip> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {archivedbusinessListArr && archivedbusinessListArr?.length > 0 && (
                <>
                  {isShowArchived ? (
                    <div>
                      <div
                        className="project-header-heading fs-14"
                        onClick={() => setIsShowArchived(!isShowArchived)}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline"
                        }}
                      >
                        Hide Archived Workspaces
                      </div>
                      <table
                        style={{
                          width: "100%",
                          borderSpacing: "1em .5em",
                          padding: "0 2em 1em 0"
                        }}
                      >
                        <tbody>
                          {archivedbusinessListArr &&
                            archivedbusinessListArr?.length > 0 &&
                            archivedbusinessListArr.map((business, ai) => {
                              return (
                                <tr
                                  key={ai}
                                  id={`divai${ai}`}
                                  onMouseEnter={() =>
                                    onHoverStyle(`divai${ai}`)
                                  }
                                  onMouseLeave={() =>
                                    onMouseLeave(`divai${ai}`)
                                  }
                                >
                                  {/* <td
                                    onClick={() =>
                                      handleRedirectWorkspace(business)
                                    }
                                    style={{
                                      paddingBottom: 8,
                                      paddingTop: 8
                                    }}
                                  >
                                    <div style={{ paddingRight: 10 }}>
                                      {isDefaultLoader.id === business.id &&
                                      isDefaultLoader.status ? (
                                        <Loader height="16px" width="16px" />
                                      ) : (
                                        <>
                                          {business?.collaborator[0]
                                            ?.isDefault ? (
                                            <i
                                              className="fa fa-check-circle"
                                              style={{
                                                color: topbarTheme?.buttonColor
                                                  ? topbarTheme.buttonColor
                                                  : "black"
                                              }}
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-check-circle"
                                              style={{
                                                color: "gray"
                                              }}
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </td> */}
                                  <td
                                    onClick={() =>
                                      handleRedirectWorkspace(business)
                                    }
                                    style={{
                                      width: 1,
                                      paddingBottom: 8,
                                      paddingTop: 8,
                                      cursor: "pointer"
                                    }}
                                  >
                                    {" "}
                                    <div
                                      style={
                                        businessImage
                                          ? profileImage
                                          : profileBackground
                                      }
                                    >
                                      {business?.businessMeta ? (
                                        <img
                                          style={profileImage}
                                          src={
                                            business?.businessMeta?.thumbnail
                                          }
                                        />
                                      ) : (
                                        <img
                                          style={placeholderImage}
                                          src={businessImage}
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    onClick={() =>
                                      handleRedirectWorkspace(business)
                                    }
                                    style={{
                                      paddingLeft: 5,
                                      width: "100%",
                                      paddingBottom: 8,
                                      paddingTop: 8,
                                      cursor: "pointer"
                                    }}
                                  >
                                    <div
                                      className="mlr-5  "
                                      id="changeBusinessss"
                                    >
                                      <p className=" fs-14 fw-400  demi-bold-text">
                                        {business && business.title}
                                      </p>
                                      {/* <small className="text-muted">{data.user.email}</small> */}
                                    </div>
                                  </td>

                                  {/* <td
                            onClick={() => openEditModal(business)}
                            style={{ paddingBottom: 8, paddingTop: 8 }}
                          >
                            {" "}
                            {checkIsOwner(business.collaborator) && (
                              <div className="task-icons">
                                <RoyTooltip id={`edit`} title="Edit">
                                  <i
                                    className="fas fa-edit text-muted"
                                    id="edit"
                                  />
                                </RoyTooltip>
                              </div>
                            )}
                          </td> */}

                                  <td
                                    // onClick={() => {
                                    //   props.history.push(
                                    //     `/business/${business.id}`,
                                    //     {
                                    //       business,
                                    //       isDefault:
                                    //         business?.collaborator[0]
                                    //           ?.isDefault
                                    //     }
                                    //   );
                                    //   setmodal(!modal);
                                    // }}
                                    style={{
                                      paddingBottom: 8,
                                      paddingTop: 8,
                                      cursor: "pointer"
                                    }}
                                  >
                                    {" "}
                                    {/* style={{ textAlign: "center" }}
                                    > */}
                                    {isDefaultLoader.status &&
                                    isDefaultLoader.id === business.id ? (
                                      <>
                                        {" "}
                                        <Loader height="23px" width="18px" />
                                      </>
                                    ) : !business?.collaborator[0]
                                        ?.isDefault ? (
                                      <div className="task-icons plr-10">
                                        <div
                                          style={{
                                            textAlign: "center"
                                          }}
                                        >
                                          <button
                                            className="btn button button-hover btn-sm"
                                            style={{
                                              border: `1px solid ${topbarTheme?.buttonColor}`,
                                              color: topbarTheme?.buttonColor
                                            }}
                                            onClick={() =>
                                              selectBusiness(business.id)
                                            }
                                          >
                                            Switch
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      // <span
                                      //   style={{
                                      //     color: topbarTheme?.buttonColor
                                      //       ? topbarTheme.buttonColor
                                      //       : "",
                                      //       fontSize:"14px",
                                      //       paddingLeft:"3px"
                                      //   }}
                                      // >
                                      //   Selected
                                      // </span>

                                      <div
                                        className="task-icons plr-10"
                                        style={{
                                          textAlign: "end"
                                        }}
                                      >
                                        <i
                                          className="fa fa-check-circle"
                                          style={{
                                            color: topbarTheme?.buttonColor
                                              ? topbarTheme.buttonColor
                                              : "black"
                                          }}
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    )}
                                    {/* {isDefaultLoader.status &&
                                      isDefaultLoader.id === business.id ? (
                                        <Loader height="16px" width="16px" />
                                      ) : (
                                        <button
                                          className="btn button button-hover btn-sm"
                                          style={{
                                            border: `1px solid ${topbarTheme?.buttonColor}`,
                                            color: topbarTheme?.buttonColor
                                          }}
                                          onClick={() =>
                                            selectBusiness(business.id)
                                          }
                                        >
                                          Switch
                                        </button>
                                      )} */}
                                    {/* <RoyTooltip id={`info`} title="Info">
                                          <i
                                            className="fas fa-info-circle text-muted"
                                            id="info"
                                          />
                                        </RoyTooltip> */}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div
                      className="project-header-heading fs-14"
                      onClick={() => setIsShowArchived(!isShowArchived)}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                    >
                      Show Archived Workspaces
                    </div>
                  )}
                </>
              )}
              <div>
                {/* {businessListArr?.length==0 && archivedbusinessListArr?.length==0 && !isDefaultLoader.status &&  <>
                <div className="text-muted" style={{justifyContent: 'center',textAlign: 'center'}}>No workspace found </div>
                </>} */}
              </div>
              <CustomNotification
                show={toast}
                message={toastMessage}
                closeNotification={() => setToast(false)}
              />
            </div>
          </busienssListWrapper>
        </ModalBody>
        <ModalFooter style={{ paddingTop: "10px", justifyContent: "center" }}>
          <div>
            <button
              className="btn "
              style={{
                backgroundColor: topbarTheme.buttonColor,
                color: "#FFF",
                width: 280
              }}
              onClick={() => {
                setAddBusiness(true);
                // isToggleAllowed && setmodal(!modal);
              }}
            >
              Add Workspace
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <AddBusiness
        modal={addBusiness}
        isExistingUser={true}
        setmodal={() => setAddBusiness(!addBusiness)}
        updateRedux={data => props.update_Project(data)}
        updateProjectandLabel={(token, businessName, businessMeta) => {
          updateProjectandLabel(token);
          setTimeout(() => {
            // Delay this action by one second
          }, 800);
          setmodal(!modal);
        }}
        history={(route, data) => {
          setAddBusiness(false);
          props.history.push(route, data);
        }}
      />
      <EditBusinessModal
        status={"update"}
        businessData={editBusinessData}
        modal={isAddBusiness}
        setmodal={() => setIsAddBusiness(!isAddBusiness)}
        pushNewBusiness={data => pushNewBusiness(data)}
        updateBusinessData={data => updateBusinessData(data)}
        updateDefaultBusiness={id => updateDefaultBusiness(id)}
        updateToken={(token, businessName, businessMeta, id) => {
          updateToken(token, businessName, businessMeta, id);
          if (props.history.location.pathname !== "/today") {
            props.history.push("/today");
          }
        }}
        showNotifications={message => showNotifications(message)}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {
  update_token,
  default_business,
  update_business,
  set_projects,
  set_labels,
  update_Label
})(BusinessModal);
