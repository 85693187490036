const businessAction = {
  DEFAULT_BUSINESS: "DEFAULT_BUSINESS",
  UPDATE_DEFAULT_BUSINESS: "UPDATE_DEFAULT_BUSINESS",
  PARTICIPANT: "PARTICIPANT",
  UPDATE_PARTICIPANT: "UPDATE_PARTICIPANT",

  default_business: data => {
    return {
      type: businessAction.DEFAULT_BUSINESS,
      business: data
    };
  },
  update_default_business: data => {
    return {
      type: businessAction.UPDATE_DEFAULT_BUSINESS,
      business: data
    };
  },
  participant: data => {
    return {
      type: businessAction.PARTICIPANT,
      participant: data
    };
  },
  update_participant: data => {
    return {
      type: businessAction.UPDATE_PARTICIPANT,
      participant: data
    };
  }
};

export default businessAction;
