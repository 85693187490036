import React, { useRef, useState, useEffect } from "react";
// import { PRIMARY_COLOR } from "constatnt/index";
import { addSubtask } from "helper/services/subtaskService";
import OneLineShimmer from "components/shimmer/OneLineShimmer";
const AddItemInput = ({
  checklistId,
  setChecklistId,
  todoId,
  projectId,
  subtaskList,
  setSubtaskList,
  PRIMARY_COLOR,
  isAddItemFlag,
  setIsAddItemFlag,
  addNewSubtask
}) => {
  const [AddSubtaskFlag, setAddSubtaskFlag] = useState(false);
  const [subtaskInput, setSubtaskInput] = useState("");
  const [itemLoader, setItemLoader] = useState(false);
  useEffect(() => {
    if (isAddItemFlag) {
      setAddSubtaskFlag(true);
    }
  }, []);

  const closeAddingSubtask = () => {
    if (subtaskInput === "") {
      setAddSubtaskFlag(!AddSubtaskFlag);
      setIsAddItemFlag();
    }
  };
  const handleaddNewSubtask = async (checkListId, subtaskInput) => {
    if (subtaskInput !== "" && checkListId) {
      setItemLoader(true);
      try {
        await addNewSubtask(checkListId, subtaskInput);
        setItemLoader(false);
        setIsAddItemFlag();
      } catch (e) {
        setItemLoader(false);
        setIsAddItemFlag();
      }
      setSubtaskInput("");
    }
  };

  return (
    <div style={{ marginLeft: 20 }}>
      {!AddSubtaskFlag ? (
        <div
          className=" c-btn c-default "
          style={{
            background: "#E7E9EC",
            boxShadow: "none",
            fontSize: 12,
            marginTop: 10,
            height: 25,
            width: 94,
            borderRadius: 4
          }}
          onClick={() => {
            setAddSubtaskFlag(!AddSubtaskFlag);
          }}
        >
          <div
            style={{
              fontSize: 12,
              fontWeight: "600",
              color: "black",
              position: "relative",
              top: -4
            }}
          >
            {" "}
            Add an item
          </div>
        </div>
      ) : (
        <>
          {itemLoader ? (
            <div>
              <OneLineShimmer />
            </div>
          ) : (
            <div className="add-comment-input-block mt-10">
              <textarea
                value={subtaskInput}
                autoFocus
                className="add-subtask-input"
                name="taskTitle"
                rows="1"
                onChange={e => setSubtaskInput(e.target.value)}
                onBlur={closeAddingSubtask}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    if (
                      !event.shiftKey &&
                      subtaskInput.match(/^\S+\w{0,50}\S{0,}/g)
                    ) {
                      event.preventDefault();

                      handleaddNewSubtask(checklistId, subtaskInput);
                    }
                  }
                }}
                style={{
                  border: "1px solid #e2e2e2",
                  backgroundColor: "#fff"
                }}
                placeholder={"Item title"}
              ></textarea>
              <div className="subtask-action">
                <button
                  className="c-btn mr-10"
                  style={{
                    // backgroundColor:
                    //   subtaskInput.match(/^\S+\w{0,50}\S{0,}/g) &&
                    //   subtaskInput.length != 0
                    //     ? PRIMARY_COLOR
                    //     : PRIMARY_COLOR,
                    backgroundColor: PRIMARY_COLOR,
                    color: "#fff",
                    boxShadow: "none"
                  }}
                  disabled={
                    !subtaskInput.match(/^\S+\w{0,50}\S{0,}/g) &&
                    subtaskInput.length == 0
                      ? true
                      : false
                  }
                  onClick={() => {
                    handleaddNewSubtask(checklistId, subtaskInput);
                    setSubtaskInput("");
                  }}
                >
                  Add
                </button>
                <button
                  className="c-btn c-default"
                  onClick={() => {
                    setAddSubtaskFlag(!AddSubtaskFlag);
                    setSubtaskInput("");
                    setChecklistId(null);
                  }}
                  style={{
                    boxShadow: "none"
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddItemInput;
