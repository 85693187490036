import React, { useEffect, useState } from "react";
import DatepickerWrapper from "components/DatePickers/TaskDialogDatePicker.style.js";
import DatePicker, { CalendarContainer } from "react-datepicker";
import moment from "moment";
import { connect } from "react-redux";
import { datesnewmodal } from "helper/constant";
import IntlMessages from "util/intlMessages";
import { de, nl } from "date-fns/locale";

const DueDatePicker = props => {
  const {
    dueDate,
    isOpen,
    toggle,
    onUpdateDate,
    topbarTheme,
    AllowAddPreviousDateTask,
    PRIMARY_COLOR
  } = props;
  const [tempDate, setTempDate] = useState(new Date());
  const [locale, setLocale] = useState();

  useEffect(() => {
    if (isOpen) {
      if (dueDate) {
        setTempDate(new Date(dueDate));
      } else {
        setTempDate(getRoundOfMinute());
      }
      if (props.language.locale == "de") {
        setLocale(de);
      } else if (props.language.locale == "nl") {
        setLocale(nl);
      } else {
        setLocale(props.language.locale);
      }
    }
    return () => {};
  }, [isOpen]);
  const getRoundOfMinute = (givenDate = new Date()) => {
    try {
      const start = moment(givenDate);
      const remainder = 15 - (start.minute() % 15);
      const dateTime = moment(start).add(remainder, "minutes");

      return new Date(dateTime);
    } catch (E) {
      console.log(E);
    }
  };
  const onChangeDate = async date => {
    setTempDate(date);
  };

  const DueDateInput = React.forwardRef(
    ({ value, onClick }, ref) => (
      //  { dueDate ?
      <div onClick={onClick} ref={ref}>
        <div onClick={toggle} style={{ cursor: "pointer" }}>
          {dueDate ? (
            moment(new Date(dueDate)).format("DD MMM YYYY hh:mm a")
          ) : (
            <div style={{ fontSize: 14, color: "#808080" }}>
              <IntlMessages id={"task-details.selectadate"} />
              ...
            </div>
          )}
        </div>
      </div>
    )
    // :""}
  );

  return (
    <div>
      <DatepickerWrapper {...props}>
        <DatePicker
          arrow={false}
          open={isOpen}
          onClickOutside={toggle}
          value={tempDate}
          shouldCloseOnSelect={false}
          selected={tempDate || new Date()}
          onChange={onChangeDate}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          disabledKeyboardNavigation
          dateFormat="MMMM d, yyyy h:mm aa"
          timeCaption="Time"
          className="custom-datepicker"
          calendarClassName="custom-calender-class"
          placeholderText={"Schedule"}
          // isClearable={true}
          minDate={!AllowAddPreviousDateTask && new Date()}
          customInput={<DueDateInput />}
          // popperPlacement="bottom-start"
          popperModifiers={{
            flip: {
              behavior: ["bottom-start"] // don't allow it to flip to be above
            },
            preventOverflow: {
              enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            },
            hide: {
              enabled: false // turn off since needs preventOverflow to be enabled
            }
          }}
          locale={locale}
        >
          <div>
            <CalendarContainer>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "center",
                    width: "100%",
                    fontSize: 14,
                    padding: 10,
                    borderRadius: 4,
                    color: "#FFF",
                    height: "20%"
                  }}
                >
                  <div
                    onClick={() => {
                      onChangeDate("");
                      onUpdateDate("");
                      // toggle();
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      fontSize: 14,
                      padding: 6,
                      borderRadius: 4,
                      cursor: "pointer",
                      border: "1px solid #E6E6E6",
                      color: "black",
                      marginRight: "8px"
                    }}
                  >
                    <IntlMessages id={"task-details.removedate"} />
                  </div>
                  <div
                    onClick={() => {
                      onChangeDate(tempDate || new Date());
                      onUpdateDate(tempDate || new Date());
                      // toggle();
                    }}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      width: "30%",
                      fontSize: 14,
                      background: topbarTheme.buttonColor,
                      padding: 6,
                      borderRadius: 4,
                      color: "#FFF"
                    }}
                  >
                    <IntlMessages id={"globalsearchbar.applybutton"} />
                  </div>
                </div>
              </div>
            </CalendarContainer>
          </div>
        </DatePicker>
      </DatepickerWrapper>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher.language }
  };
};

export default connect(mapStateToProps, {})(DueDatePicker);

// export default DueDatePicker;
