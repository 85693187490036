import React, { useState, useEffect } from "react";
import TodolistWrapper from "./todolistWrapper.style";
import { addTodo, updateTodo } from "helper/services/todoService";
import { ReactComponent as RepeatSvgIcon } from "assets/images/recurrenceIcon.svg";

import DeleteAlert from "components/alert/DeleteAlert";
import moment from "moment";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import RoyTooltip from "components/common/RoyTooltip";
import IconTint from "react-icon-tint";
import TaskDialog from "components/scrumboard/TaskDialog";
import TaskMenu from "components/projects/Menus/TaskMenu";
import { deleteTodo } from "helper/services/todoService";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import CustomAlert from "util/CustomAlert";
import {
  commentnew,
  addnew,
  iconTag,
  AssignTaskImage,
  threeDot_Icon,
  ListGridCheckImage,
  ListGridCircleImage
} from "helper/constant";
import { getColorCode } from "util/data/ProjectColors";
import {
  allPriority,
  taskSocketRoomPrefix,
  upcommingSocketRoomPrefix
} from "constatnt";
import {
  getPriorityPath,
  getPriorityValue,
  projectSocketRoomPrefix
} from "constatnt";
import {
  upcommingInit,
  joinRoom,
  leaveRoom,
  disconnect
} from "services/socket";
import NewTask from "components/newTask/NewTask";
import TaskAssignee from "components/assignee/TaskAssignee";
// import OrderPlacedWrapper from "components/widgets/e-commerce/orderplaced/orderplaced.style";
import PriorityPopover from "components/priority/priorityPopover";
import DatePickerNoInput from "components/DatePickers/DatePickerNoInput";
import AssigneeDropdown from "components/newTask/AssigneeDropdown";
import { onChangeTaskAssign } from "helper/methods/QuickActions";
import { repeatTypeOptions } from "util/data/taskDetailsutilData";
import { doGroupByproject } from "helper/methods/utilFunctions";
import SingleTaskView from "./SingleTaskView";
const UpcommingTodoListView = props => {
  const [todo, SetTodo] = useState(null);
  const [taskModel, setTaskModel] = useState(false);
  const [activeTaskDetail, setActiveTaskDetail] = useState(null);
  const [taskMenu, setTaskMenu] = useState(false);
  const [activeTaskMenu, setActiveTaskMenu] = useState(null);
  const [isCustomAlert, setCustomAlert] = useState(false);
  const [isDataFlag, setIsDataFlag] = useState(false);
  const [sectionalertMessage, setSectionAlertMessage] = useState(
    "Task will be deleted!"
  );
  const [projectArr, setProjectArr] = useState([]);
  const [istaskinput, setIsTaskinput] = useState({ status: false, id: "" });
  const { isEditTask, setIsEditTask, updateTaskHandler } = props;
  const [upCommingListName, setUpCommingListName] = useState("");
  const [taskProject, setTaskProject] = useState("");
  const { selectedUpcomingDate, businessId, AllowAddPreviousDateTask } = props;
  const {
    doCloseQuickAction,
    doUpdateTaskPriority,
    doUpdateTaskScheduleDate,
    doUpdateTaskAssign,
    isQuickActionTask,
    setIsQuickActionTask,
    language
  } = props;

  useEffect(() => {
    if (props.todoList) {
      SetTodo(props.todoList);
      var getRegularTasks = props.todoList.filter(
        (data, i) => !data.isDeleted && !data.isArchived
      );
      let formattedData = doGroupByproject(getRegularTasks);
      SetTodo(formattedData);
    }
    return () => {};
  }, [props.todoList]);

  const isAddTaskPermission = () => {
    if (
      moment(selectedUpcomingDate).format("DD-MM-YYYY") >=
        moment().format("DD-MM-YYYY") ||
      (moment(selectedUpcomingDate).format("DD-MM-YYYY") <=
        moment().format("DD-MM-YYYY") &&
        AllowAddPreviousDateTask)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateisCompleted = async (projectId, todoId, taskStatus, payload) => {
    props.updateIsCompleted(projectId, todoId, taskStatus, payload);
  };
  const deleteTask = async (id, projectId) => {
    updateTaskHandler(
      {
        task_id: id.toString(),
        project_id: projectId?.toString(),
        isDeleted: true
      },
      false
    );

    // const Response = await deleteTodo(id, projectId);
    // if (Response) {
    //   if (Response.error) {
    //   }
    //   if (Response.data) {
    //     if (Response.data.isDeleted) {
    //       removeTaskFromList(Response.data.id);
    //       props.showNotifications("Task deleted");
    //     }
    //   }
    // }
  };
  const doArchiveTask = async (id, projectId) => {
    updateTaskHandler(
      {
        task_id: id.toString(),
        project_id: projectId?.toString(),
        isArchived: true
      },
      false
    );
  };
  const doOpenTaskDetail = data => {
    props.history.push(`/task-details/${data.id}`);
    //   setActiveTaskDetail(data);
    //   setTodoId(data.id);
    //   // Join task socket room
    //   joinRoom(
    //     `${taskSocketRoomPrefix}${data.id}`
    //   );
    //   setTaskModel(true);
  };
  const handleActiveTaskDetails = data => {
    doOpenTaskDetail(data);
  };
  const toggleTaskMenu = (target, taskData) => {
    if (taskMenu) {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(null);
      setActiveTaskDetail(null);
    } else {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(target);
      // joinRoom(`${upcommingSocketRoomPrefix}${businessId}`);
      setActiveTaskDetail(taskData);
    }
  };

  return (
    <div>
      <div className=" ">
        <TodolistWrapper>
          <div
            style={{
              fontSize: 14,
              fontWeight: 600,
              color: "black",
              marginBottom: 8,
              marginTop: 15
            }}
          >
            {selectedUpcomingDate && props.overdueTasksLength
              ? moment(new Date(selectedUpcomingDate)).format("DD MMM YYYY")
              : ""}
          </div>
          {todo && Object.keys(todo).length > 0 && props.todoList.length > 0 ? (
            <div>
              <div>
                {Object.keys(todo).length &&
                  Object.keys(todo).map((projectKey, i) => {
                    return (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: 5,
                            marginTop: 20
                          }}
                          className="pa-5 flex-x align-center"
                          id={`section-edit-${i}`}
                        >
                          <div className="flex-1">
                            <div className="fs-14 demi-bold-text flex-x align-center">
                              <i
                                className="fa fa-circle fs-10 pr-10 fs-10"
                                style={{
                                  color: getColorCode(
                                    todo[projectKey].project.color
                                  )
                                }}
                              />
                              <span
                                style={{
                                  fontSize: 14,
                                  color: "black",
                                  fontWeight: 600
                                }}
                              >
                                {" "}
                                {todo[projectKey].project.name}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="todo-container flex-y"
                          style={{ backgroundColor: "white", borderRadius: 10 }}
                        >
                          {todo[projectKey].task &&
                            todo[projectKey].task.map((data, i) => {
                              if (
                                isEditTask?.status &&
                                isEditTask?.data?.id === data.id &&
                                !isEditTask?.isOverdue
                              ) {
                                return (
                                  <div
                                    style={{
                                      paddingTop: 5,
                                      backgroundColor: "white",
                                      borderRadius: "5px",
                                      border: "1px solid #c6c2c2",
                                      position: "relative"
                                    }}
                                    // onBlur={e => {
                                    //
                                    //
                                    //   if (e.relatedTarget) {
                                    //
                                    //   } else {
                                    //
                                    //     setIsTaskinput(false);
                                    //   }
                                    // }}
                                  >
                                    <NewTask
                                      updateTask
                                      action={isEditTask?.action || ""}
                                      toggleAction={() =>
                                        setIsEditTask({
                                          ...isEditTask,
                                          action: ""
                                        })
                                      }
                                      collaboratorList={props.collaboratorList}
                                      toggle={() => {
                                        setIsEditTask({
                                          status: false,
                                          data: ""
                                        });
                                        setIsTaskinput({
                                          status: false,
                                          id: null
                                        });
                                      }}
                                      taskData={isEditTask.data}
                                      // onKeyDown={handleKeyDown}
                                      addTaskHandler={updateTaskHandler}
                                      taskProject={taskProject}
                                      setTaskProject={data =>
                                        setTaskProject(data)
                                      }
                                    />
                                  </div>
                                );
                              }
                              return (
                                <>
                                  <SingleTaskView
                                    auth={props.auth}
                                    index={i}
                                    data={data}
                                    todo={todo}
                                    updateisCompleted={updateisCompleted}
                                    doOpenTaskDetail={doOpenTaskDetail}
                                    doCloseQuickAction={doCloseQuickAction}
                                    isQuickActionTask={isQuickActionTask}
                                    doUpdateTaskScheduleDate={
                                      doUpdateTaskScheduleDate
                                    }
                                    doUpdateTaskAssign={doUpdateTaskAssign}
                                    toggleTaskMenu={toggleTaskMenu}
                                    todoLength={
                                      todo[projectKey].task.length || 0
                                    }
                                  />
                                </>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <>
              {/* {moment(selectedUpcomingDate).format("DD MM YYYY") <
                moment(new Date()).format("DD MM YYYY") && "No Data"}{" "} */}
            </>
          )}
          <>
            {istaskinput.status && isAddTaskPermission() ? (
              <div style={{ border: "1px solid #c6c2c2", borderRadius: 5 }}>
                <NewTask
                  defaultUpcommingDate={selectedUpcomingDate}
                  modal={istaskinput.status}
                  isProjectSelection
                  action={isEditTask?.action || ""}
                  toggleAction={() =>
                    setIsEditTask({ ...isEditTask, action: "" })
                  }
                  collaboratorList={props.collaboratorList || []}
                  toggle={() => {
                    setIsEditTask({ status: false, data: "" });
                    setIsTaskinput({ status: false, id: null });
                  }}
                  // taskData={isEditTask.data}
                  // onKeyDown={handleKeyDown}
                  addTaskHandler={props.addTaskHandler}
                  taskProject={taskProject}
                  setTaskProject={data => setTaskProject(data)}
                />
              </div>
            ) : (
              <>
                {isAddTaskPermission() && (
                  <div
                    className="list-name flex-x align-center  todo-topborder cursor-pointer"
                    style={{ padding: "10px 15px 15px 30px" }}
                  >
                    <div className="mr-10 flex-x icon cursor-pointer">
                      <img
                        alt=""
                        src={addnew}
                        style={{
                          height: "15px",
                          width: "15px"
                        }}
                      ></img>
                    </div>
                    <div className="mr-10 flex-1 cursor-pointer">
                      <input
                        // value={taskinput}
                        onFocus={() => {
                          setIsTaskinput({ status: true, id: "" });
                        }}
                        // onChange={e => {
                        //   setTaskinput(e.target.value);
                        //   setIsTaskinput(false);
                        //   setIsTaskinput({ status: true, id: props.sectionId });
                        // }}
                        className="task-add-input fs-14 fill-width cursor-pointer"
                        type="text"
                        placeholder={InjectPlaceHolderMessage(
                          "today.newtask",
                          language
                        )}
                        // onKeyDown={handleKeyDown}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </TodolistWrapper>
      </div>

      {/* <TodolistWrapper>
        <div className="todo-container flex-y">
          {todo?.length > 0 ? (
            todo.map((data, i) => {
              if (data.isDeleted == false) {
                return (
                  <div
                    key={i}
                    className="flex-x my-todo align-center plr-10 "
                    style={{ borderTop: "1px solid #f0f0f0" }}
                  >
                    <div>
                      <div className="mt-1 pretty p-icon p-smooth">
                        <input
                          type="checkbox"
                          checked={data.isCompleted}
                          onChange={e =>
                            updateisCompleted(data.project.id, data.id, e)
                          }
                        />
                        <div className="state p-info-o">
                          <i className="icon fas fa-check"></i>
                          <label></label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex-1"
                      onClick={() => {
                        setActiveTaskDetail(data);
                        setTaskModel(true);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <span
                          className="fs-14"
                          style={data.isCompleted ? { color: "#767678" } : {}}
                        >
                          <span
                            
                            style={data.isCompleted ? { width: "100%" } : {}}
                          ></span>
                          {data.title}
                        </span>
                      </div>
                      <div className="flex-x flex-wrap align-center fs-14">
                        {data.labels &&
                          data.labels.map((item, index) => {
                            return (
                              <>
                                <div
                                  className="my-day-label flex-x mr-6 fs-14 nevy--text align-center overflow-hidden"
                                  key={index}
                                >
                                  <i
                                    className="fa fa-tag"
                                    style={{ color: item.color }}
                                  />
                                  <span
                                    style={{
                                      color: item.color,
                                      paddingLeft: 5
                                    }}
                                  >
                                    {item.name}
                                  </span>
                                </div>
                              </>
                            );
                          })}
                        <div
                          className={`my-day-label flex-x mr-6 fs-14 nevy--text align-center overflow-hidden ${
                            data.labels
                              ? data.labels.length > 0
                                ? "label"
                                : ""
                              : ""
                          }`}
                        >
                          <i
                            className="fa fa-comment-dots"
                            style={{ color: "grey" }}
                          />
                          <span style={{ color: "grey", paddingLeft: 5 }}>
                            {data.commentCount}
                          </span>
                        </div>
                        {data.scheduledAt && (
                          <div className="due-date mr-6 label">
                            Due on{" "}
                            {moment(new Date(data.scheduledAt)).format(
                              "DD MMM YYYY"
                            )}
                          </div>
                        )}
                      </div>{" "}
                    </div>
                    <div className="mr-20">
                      <div>{data.project && data.project.name}</div>
                    </div>
                    <div
                      id={`taskmenu-${data.id}`}
                      onClick={() =>
                        toggleTaskMenu(`taskmenu-${data.id}`, data)
                      }
                      className="cursor-pointer"
                    >
                      <i
                        className="fas fa-ellipsis-h"
                        style={{ color: "grey" }}
                      ></i>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <div
              className="text-muted"
              style={{
                padding: 50,
                fontSize: 15,
                display: "flex",
                justifyContent: "center"
              }}
            >
              No Upcoming Task
            </div>
          )}

          
        </div>
      </TodolistWrapper> */}

      {activeTaskDetail && (
        <TaskDialog
          taskType="date_filter_upcomming"
          taskModel={taskModel}
          collaboratorList={props.collaboratorList}
          setTaskModel={() => {
            setTaskModel(!taskModel);
            setActiveTaskDetail(null);
          }}
          taskDetail={activeTaskDetail}
          projectId={activeTaskDetail.project.id}
          projectName={activeTaskDetail.project.name}
          todoId={activeTaskDetail.id}
          updateTodoList={newTodo => props.refreshTodoList(newTodo)}
          dummyData={props.dummyData || false}
          showNotifications={props.showNotifications}
        />
      )}
      <TaskMenu
        projectTask
        isMenu={taskMenu}
        toggleMenu={() => toggleTaskMenu()}
        viewTaskDetails={() => {
          doOpenTaskDetail(activeTaskDetail);
          // setActiveTaskDetail({ ...activeTaskDetail, action: "taskactivity" });
          // setTaskModel(true);
          setTaskMenu(false);
          setActiveTaskMenu("");
        }}
        doArchiveTask={() =>
          doArchiveTask(activeTaskDetail?.id, activeTaskDetail.project.id)
        }
        target={activeTaskMenu}
        editLabels={() => {
          setIsEditTask({
            status: true,
            data: activeTaskDetail,
            action: "label"
          });
        }}
        editAssign={() => {
          if (activeTaskDetail && activeTaskDetail.id) {
            setIsQuickActionTask({
              status: true,
              taskId: activeTaskDetail.id,
              action: "assign",
              target: `taskmenu-assign-${activeTaskDetail.id}`,
              selectedData:
                activeTaskDetail && activeTaskDetail.taskAssign
                  ? { user: activeTaskDetail?.taskAssign }
                  : "",
              data: activeTaskDetail
            });
          }
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "taskassign"
          // });
        }}
        editPriority={() => {
          if (activeTaskDetail && activeTaskDetail.id) {
            setIsQuickActionTask({
              status: true,
              taskId: activeTaskDetail.id,
              action: "priority",
              target: `taskmenu-priority-${activeTaskDetail.id}`,
              selectedData: activeTaskDetail && activeTaskDetail.priority,
              data: activeTaskDetail
            });
          }
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "priority"
          // });
        }}
        editDate={() => {
          setIsQuickActionTask({
            status: true,
            taskId: activeTaskDetail.id,
            action: "schedule",
            target: `taskmenu-schedule-${activeTaskDetail.id}`,
            selectedData: activeTaskDetail && activeTaskDetail.scheduledAt,
            data: activeTaskDetail
          });
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "schedule"
          // });
        }}
        editTask={() => {
          if (taskMenu) {
            setIsEditTask({ status: true, data: activeTaskDetail });
          } else {
            setIsEditTask({ status: false, data: "" });
          }
          // setTaskModel(!taskModel);
          setTaskMenu(!taskMenu);
          // setActiveTaskDetail(null);
        }}
        deleteTask={id => {
          setTaskMenu(!taskMenu);
          setCustomAlert(true);
        }}
      />
      <CustomAlert
        CustomMessage={upCommingListName}
        action="delete"
        messageType="task"
        PRIMARY_COLOR={props.PRIMARY_COLOR}
        show={isCustomAlert}
        onConfirm={() => {
          deleteTask(activeTaskDetail.id, activeTaskDetail.project.id);
          setCustomAlert(false);
        }}
        onCancel={() => setCustomAlert(false)}
        alertMessage={sectionalertMessage}
      />
      {isQuickActionTask &&
        isQuickActionTask.status &&
        isQuickActionTask.action == "priority" && (
          <PriorityPopover
            isOpen={isQuickActionTask.status}
            toggle={doCloseQuickAction}
            target={
              isQuickActionTask.status && isQuickActionTask.target
                ? isQuickActionTask.target
                : "priority-task-details"
            }
            selectedPriority={
              isQuickActionTask?.status && isQuickActionTask.selectedData
                ? isQuickActionTask.selectedData
                : "no_priority"
            }
            updatePriority={doUpdateTaskPriority}
            PRIMARY_COLOR={props.PRIMARY_COLOR}
          />
        )}
      {/* <DeleteAlert
        modal={isCustomAlert}
        PRIMARY_COLOR={props.PRIMARY_COLOR}
        setmodal={() => setCustomAlert(false)}
      /> */}
    </div>
  );
};

export default UpcommingTodoListView;
