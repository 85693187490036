import React from "react";

const profileBackground = {
  borderRadius: "50%",
  border: "1 solid",
  width: 25,
  background: "orange",
  height: 25,
  objectFit: "fill",
  textAlign: "center",
  paddingTop: 0,
  marginTop: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const InCap = {
  lineHeight: 1,
  fontSize: 15,
  color: "white",
  textAlign: "center"
};

export default function Assignee({ thumbnail, name }) {
  return (
    <>
      <div
        style={{
          // paddingRight: 5,
          display: "flex",
          flexDirection: "row"
        }}
      >
        {/* {thumbnail ? (
          <img
            style={{
              width: 25,
              height: 25,
              borderRadius: "50%",
              objectFit: "cover",
              border: "1px solid #d4d4d4",
              backgroundColor: "#d4d4d4"
            }}
            src={thumbnail}
            alt=""
          />
        ) : (
          <div style={profileBackground}>
            <span style={InCap}>
              {name ? name.charAt(0).toUpperCase() : ""}
            </span>
          </div>
        )} */}
        <span
          className="fs-12"
          style={{
            // paddingLeft: 5,
            lineHeight: 2
            // cheng color
          }}
        >
          {name}
        </span>
      </div>
    </>
  );
}
