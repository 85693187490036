/** @format */

import React, { useEffect, useState } from "react";
import TaskAllAttachmentsImage from "components/TaskDetails/TaskAllAttachmentsImage";
import { getCommentsAttachment } from "helper/services/taskServices";
import ToastMessage from "components/notifications/ToastMessage";
import { useToasts } from "react-toast-notifications";
import { connect } from "react-redux";
import { searchNotFound } from "helper/constant";
import OneLineShimmer from "components/shimmer/OneLineShimmer.jsx";
import AllAttachmentsShimmer from "components/shimmer/AllAttachmentsShimmer.jsx";
import alertActions from "redux/globalAlert/actions";
import projectActions from "redux/projects/actions";
import TaskAlert from "components/TaskDetails/Alerts/TaskAlert";
import LinearLoader from "components/loader/LinearLoader";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import IntlMessages from "util/intlMessages";

const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const { update_projects_existing, remove_Project } = projectActions;
const TaskAllAttachments = props => {
  const { addToast } = useToasts();
  const [attachments, setAttachments] = useState([]);
  const [isFetchingAttachment, setIsFetchingAttachment] = useState(false);
  const [isNextPageLoader, setIsNextPageLoader] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [taskAttachment, setTaskAttachment] = useState("");
  const [projectAttachment, setProjectAttachment] = useState("");
  const [parentTaskAttachment, setParentTaskAttachment] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryResult, setSearchQueryResult] = useState("");
  const [isTaskAlert, setIsTaskAlert] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    totalPages: 0,
    isLastPage: false
  });
  const taskId = props.match.params.id;
  useEffect(() => {
    if (taskId) {
      retrieveAttachments();
    }
    return () => {
      setAttachments([]);
      setIsFetchingAttachment(false);
      setPageInfo({
        currentPage: 0,
        totalPages: 0,
        isLastPage: false
      });
    };
  }, [taskId]);
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const handleResponse = message => {
    try {
      if (message.toLocaleLowerCase() == "Task not found".toLocaleLowerCase()) {
        setIsTaskAlert(true);
      }
    } catch (e) {}
  };
  const retrieveAttachments = async (isNextPage = false) => {
    if (taskId) {
      try {
        !isNextPage && setIsFetchingAttachment(true);
        let nextPage = parseInt(pageInfo.currentPage) + 1;

        isNextPage && setIsNextPageLoader(true);
        const response = await getCommentsAttachment(
          taskId,
          10,
          nextPage,
          false
        );
        if (response) {
          if (response.error) {
            handleResponse(response.error.message);
            setIsFetchingAttachment(false);
            showNotifications();
          }
          if (response.data) {
            // breadrcumb info
            setTaskAttachment(response.data.task);
            setProjectAttachment(response.data.project);
            // nested/sub task info
            setParentTaskAttachment(
              response.data?.parentTask ? response.data.parentTask : false
            );
            // attachment data info
            setAttachments(
              isNextPage
                ? [...attachments, ...response.data.taskAttachment]
                : [...response.data.taskAttachment]
            );
            console.log(
              isNextPage
                ? [...taskAttachment, ...response.data.taskAttachment]
                : [...response.data.taskAttachment]
            );
            setPageInfo({
              currentPage: parseInt(response.data.currentPage),
              totalPages: response.data.totalPages,
              isLastPage: response.data.isLastPage
            });

            setIsFetchingAttachment(false);
            isNextPage && setIsNextPageLoader(false);
          }
        }
      } catch (e) {
        console.log(e);

        isNextPage && setIsNextPageLoader(false);
        setIsFetchingAttachment(false);
      }
    }
  };
  const handleSearch = e => {
    setSearchQuery(e.target.value);
    const searchquerydata = e.target.value.trim();
    if (searchquerydata && searchquerydata?.length > 0) {
      setIsSearch(true);
      let filteredTodos;
      filteredTodos = attachments.filter(tasks => {
        return (
          tasks.originalname
            .toLowerCase()
            .indexOf(searchquerydata.toLowerCase()) !== -1
        );
      });

      setSearchQueryResult(filteredTodos);
    }
    if (!searchquerydata) {
      setIsSearch(false);
      setSearchQueryResult(null);
    }
  };
  return (
    <div className="row">
      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
      <div
        className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          padding: 15
        }}
      >
        {taskAttachment &&
        taskAttachment.title &&
        projectAttachment &&
        projectAttachment.title ? (
          <>
            <span
              title={projectAttachment.title}
              style={{
                textDecoration: "underline",
                fontWeight: 600,
                color: "black",
                cursor: "pointer",
                maxWidth: 180,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "inline-block"
              }}
              onClick={() =>
                props.history.push(`/project/${projectAttachment.id}`)
              }
            >
              {projectAttachment && projectAttachment.title}
            </span>
            {parentTaskAttachment && (
              <>
                &nbsp; &gt;&nbsp;
                <span
                  title={parentTaskAttachment.title}
                  style={{
                    textDecoration: "underline",
                    color: "black",
                    cursor: "pointer",
                    maxWidth: 300,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "inline-block"
                  }}
                  onClick={() =>
                    props.history.push(
                      `/task-details/${parentTaskAttachment.id}`
                    )
                  }
                >
                  {parentTaskAttachment && parentTaskAttachment.title}
                </span>
              </>
            )}
            &nbsp; &gt;&nbsp;
            <span
              title={taskAttachment.title}
              style={{
                textDecoration: "underline",
                color: "black",
                maxWidth:
                  parentTaskAttachment &&
                  parentTaskAttachment.id &&
                  parentTaskAttachment.title
                    ? "35%"
                    : "75%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "inline-block"
              }}
              onClick={() =>
                props.history.push(`/task-details/${taskAttachment.id}`)
              }
            >
              {taskAttachment && taskAttachment.title}
            </span>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%"
            }}
          >
            <div
              style={{
                textAlign: "center",
                width: "80%"
              }}
            >
              <OneLineShimmer />
            </div>
          </div>
        )}
      </div>
      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>

      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
      <div
        className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"
        style={{
          width: "100%",
          minHeight: "70vh",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          padding: "0px 0px",
          marginBottom: "100px"
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: 15
          }}
        >
          {isFetchingAttachment ? (
            <AllAttachmentsShimmer />
          ) : (
            <>
              <div
                className="TaskDetailsContainer"
                style={{ margin: "0px 65px" }}
              >
                <div className="add-comment-input-block mt-40">
                  <input
                    autoFocus
                    className="add-subtask-input inputStyle"
                    name="checklistTitle"
                    rows="1"
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#F5F5F5"
                    }}
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder={InjectPlaceHolderMessage(
                      "project.search_in_all_the_files_posted_in_this_thread",
                      props?.LanguageSwitcher?.language?.languageId
                    )}
                  />
                </div>
                <div
                  className="row"
                  style={{
                    margin: "30px 0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start"
                  }}
                >
                  {isSearch && (
                    <>
                      {searchQueryResult && searchQueryResult?.length > 0 ? (
                        searchQueryResult.map(comment => {
                          return (
                            <>
                              <div
                                className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12"
                                style={{
                                  margin: "5px 0px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                <TaskAllAttachmentsImage
                                  comment={{
                                    commentMeta: {
                                      url: comment.url,
                                      originalname: comment.originalname,
                                      size: comment.size,
                                      mimetype: comment.mimetype,
                                      location: comment.location,
                                      id: comment.id,
                                      createdAt: comment.createdAt,
                                      bucket: comment.bucket
                                    },
                                    commentedAt: {
                                      ...comment.taskComment.createdAt
                                    },
                                    commentBy: {
                                      ...comment.taskComment.commentBy
                                    }
                                  }}
                                />
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            padding: 50,
                            display: "flex",
                            justifyContent: "center",
                            color: "#000",
                            wordBreak: "break-all"
                          }}
                        >
                          couldn't find search results for {searchQuery}
                        </div>
                      )}
                    </>
                  )}
                  {/* {!isSearch &&
                    attachments &&
                    attachments.map(comment => {
                      return (
                        <>
                          <div
                            className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12"
                            style={{
                              margin: "5px 0px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                           <TaskAllAttachmentsImage
                              isFetchingAttachment={isFetchingAttachment}
                              comment={{
                                commentMeta: {
                                  url: comment.url,
                                  originalname: comment.originalname,
                                  size: comment.size,
                                  mimetype: comment.mimetype,
                                  location: comment.location,
                                  id: comment.id,
                                  createdAt: comment.createdAt,
                                  bucket: comment.bucket
                                },
                                commentedAt: comment.taskComment.createdAt,
                                commentBy: { ...comment.taskComment.commentBy }
                              }}
                            />
                          </div>
                        </>
                      );
                    })
                    } */}
                  {!isSearch && (
                    <>
                      {" "}
                      {attachments.length > 0 ? (
                        attachments.map(comment => {
                          return (
                            <>
                              <div
                                className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12"
                                style={{
                                  margin: "5px 0px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                <TaskAllAttachmentsImage
                                  isFetchingAttachment={isFetchingAttachment}
                                  comment={{
                                    commentMeta: {
                                      url: comment.url,
                                      originalname: comment.originalname,
                                      size: comment.size,
                                      mimetype: comment.mimetype,
                                      location: comment.location,
                                      id: comment.id,
                                      createdAt: comment.createdAt,
                                      bucket: comment.bucket
                                    },
                                    commentedAt: comment.taskComment.createdAt,
                                    commentBy: {
                                      ...comment.taskComment.commentBy
                                    }
                                  }}
                                />
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            display: "block",
                            margin: "210px auto ",
                            width: "100%",
                            height: "100%",
                            textAlign: "center"
                          }}
                        >
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                              <img
                                src={searchNotFound}
                                height="230px"
                                width="450px"
                              />
                              <div style={{ marginTop: "0px" }}>
                                {" "}
                                <IntlMessages
                                  id={"task-details.taskrelatedallattchment"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {!pageInfo.isLastPage && (
                  <div
                    style={{
                      color: props.PRIMARY_COLOR,
                      fontSize: 14,
                      textDecoration: isNextPageLoader ? "none" : "underline",
                      textAlign: "center",
                      cursor: isNextPageLoader ? "not-allowed" : "pointer",
                      paddingBottom: 10
                    }}
                  >
                    {isNextPageLoader ? (
                      <LinearLoader />
                    ) : (
                      <div
                        style={{
                          height: 100,
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "2.09rem"
                        }}
                        onClick={() =>
                          !pageInfo.isLastPage ? retrieveAttachments(true) : {}
                        }
                      >
                        View more
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <TaskAlert
        show={isTaskAlert}
        onConfirm={() => {
          setIsTaskAlert(!isTaskAlert);
          props.history.push("/today");
        }}
        onCancel={() => {
          setIsTaskAlert(!isTaskAlert);
        }}
        PRIMARY_COLOR={props.PRIMARY_COLOR}
      />
      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state,
    businessId: state.auth.user?.businessId,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    PRIMARY_COLOR: state.themeChanger.topbarTheme.buttonColor
  };
};

export default connect(mapStateToProps, {
  open_workpsace_alert,
  open_project_alert,
  close_alert,
  update_projects_existing,
  remove_Project
})(TaskAllAttachments);
