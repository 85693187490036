import React from "react";
import { ListGridCheckImage } from "helper/constant";
export const TaskComplete = ({ activity, handleRedirection }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column"
      }}
    >
      <div
        className="task-activity-name flex-x"
        style={{
          fontWeight: "600",
          fontSize: 14
        }}
      >
        On &nbsp;
        <div
          style={{
            color: "#1b6ac9",
            textDecoration: "underline",
            cursor: "pointer"
          }}
          onClick={() =>
            handleRedirection({
              redirection_type: "project",
              project: activity.project,
              task: activity.task
            })
          }
        >
          <span>{activity?.project && activity.project.name}&nbsp;</span>
        </div>
        , {activity.actor && activity.actor.name} checked off:
      </div>
      <div
        style={{
          fontSize: 13,
          wordBreak: "break-word",
          // display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          lineClamp: 2,
          textOverflow: "ellipsis",
          overflow: "hidden",
          WebkitLineClamp: 5,
          WebkitFontSmoothing: "antialiased",
          cursor: "pointer",
          display: "flex"
        }}
        onClick={() =>
          handleRedirection({
            redirection_type: "task",
            project: activity.project,
            task: activity.task
          })
        }
      >
        <img
          alt=""
          src={ListGridCheckImage}
          style={{
            height: 16,
            width: 16,
            marginRight: 5,
            marginTop: 3
          }}
        ></img>
        <span style={{ wordBreak: "break-all" }}>{activity.task.title}</span>
      </div>
    </div>
  );
};
