import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { getLabelByID } from "helper/services/labelsServices";
import {
  labelInit,
  joinRoom,
  leaveRoom,
  disconnect,
  updateTask
} from "services/socket";
import { labelSocketRoomPrefix } from "constatnt";
import { checkIsArchivedMessage } from "helper/methods/workspacestatus";
import { toggleArchive } from "helper/services/businessServices";
import LabelsTodoListView from "components/TodoListview/LabelsTodoListView";
import Shimmer from "components/shimmer/Shimmer";
import EditLabel from "components/Labels/EditLabel";
import Loader from "components/loader/Loader";
import labelActions from "redux/labels/actions";
import CustomNotification from "util/CustomNotification";
import moment from "moment";
import alertActions from "redux/globalAlert/actions";
import authActions from "redux/auth/actions";
import ApiCalendar from "react-google-calendar-api";
import { useToasts } from "react-toast-notifications";
import { iconTag } from "helper/constant";
import ToastMessage from "components/notifications/ToastMessage";
import { LableIcon } from "./LableIcon";
import IntlMessages from "util/intlMessages";

const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const { update_business_archive } = authActions;
const { update_labels_existing } = labelActions;
const Label = props => {
  const { addToast } = useToasts();
  const { id } = props.match.params;
  const [LabelTodo, setLabelTodo] = useState([]);
  const [isEditTask, setIsEditTask] = useState({
    status: false,
    data: "",
    action: ""
  });
  const [isQuickActionTask, setIsQuickActionTask] = useState({
    status: false,
    data: "",
    selectedData: "",
    action: "",
    target: ""
  });
  const [isLoader, SetIsLoader] = useState(false);
  const [label, setLabel] = useState(null);
  const [lableColor, setLabelColor] = useState("");
  const [editLabelModal, setEditLabelModal] = useState(false);
  const [isArchiveLoader, setIsArchiveloader] = useState(false);
  const [isErrorAlert, setIsErrorAlert] = useState({
    status: false,
    messageType: ""
  });
  const [toast, setToast] = useState(false);
  const [SearchLabelTodoQueryResult, setSearchLabelTodoQueryResult] = useState(
    null
  );
  const [toastMessage, setToastMessage] = useState("Something went wrong");
  const [tempTask, setTempTask] = useState([]);
  const labelRef = React.useRef(LabelTodo);
  const LableViewRef = useRef(null);

  const scrollToTop = () => {
    LableViewRef.current.scrollIntoView({
      block: "center",
      inline: "nearest"
    });
  };

  React.useEffect(() => {
    labelRef.current = LabelTodo;
  });

  useEffect(() => {
    if (id) {
      scrollToTop();
      fetchLabelFromRedux(id);
      fetchLabelTask(id);
      labelInit(props.auth.accessToken, updateTaskCB, removeTaskLabelCB);
      handleSocketRoom();
    }
    return () => {
      leaveRoom(
        `${labelSocketRoomPrefix}${id}_${props?.auth?.user?.businessId}`
      );
      disconnect();
      setIsErrorAlert({ status: false, messageType: "" });
      setIsEditTask({
        status: false,
        data: "",
        action: ""
      });
    };
  }, [id]);
  const handleSocketRoom = () => {
    // Join room
    joinRoom(`${labelSocketRoomPrefix}${id}_${props?.auth?.user?.businessId}`);
  };
  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        // status:action.alert.status, message:action.alert.message,action:'open_businesslist'

        props.open_workpsace_alert({
          status: true,
          message: "workspace"
        });
      }
      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
      }
    } catch (e) {}
  };
  const updateTaskCB = async data => {
    fetchLabelFromRedux(id);
    let projectData = labelRef.current;
    refreshTodoList(data);
  };
  const removeTaskLabelCB = async data => {
    fetchLabelFromRedux(id);
    let projectData = labelRef.current;
    removeTodoList(data);
  };
  const updateLabelToRedux = data => {
    props.update_labels_existing({ labels: data });
  };
  const fetchLabelFromRedux = id => {
    if (props.labels && props.labels?.labels) {
      props.labels.labels.map(data => {
        if (data.id == parseInt(id)) {
          setLabel(data);
          setLabelColor(data.color);
        }
      });
    }
  };
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const fetchLabelTask = async id => {
    try {
      SetIsLoader(true);
      const Response = await getLabelByID(id);
      if (Response) {
        SetIsLoader(false);
        if (Response.error) {
          showNotifications();
          handleResponse(Response.error.message);
        }
        if (Response.data) {
          setLabelTodo([...Response.data]);
        }
      }
    } catch (e) {
      SetIsLoader(false);
    }
  };
  const SyncTasktoGoogle = async taskData => {
    var eventFromNow = {
      summary: taskData.title.toString(),
      description: taskData.description.toString(),
      start: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      end: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    };

    ApiCalendar.createEvent(eventFromNow)
      .then(result => {
        if (result.error) {
          addToast(ToastMessage(result.error.message), {
            appearance: "error",
            autoDismiss: true
          });
        }
        addToast(ToastMessage("Sync to Google"), {
          appearance: "success",
          autoDismiss: true
        });
        return true;
      })
      .catch(error => {
        addToast(
          ToastMessage(
            <IntlMessages id={"task-details.Something_went_wrong"} />
          ),
          {
            appearance: "error",
            autoDismiss: true
          }
        );
      });
  };
  const updateTaskHandler = async (payloadInput, syncGoogle) => {
    try {
      let manuplatedObj = {
        title: payloadInput.title,
        description: payloadInput.description,
        label_ids: payloadInput.label_ids,
        reminderAt: payloadInput.reminderAt,
        priority: payloadInput.priority,
        user_id: payloadInput.user_id,
        project_id: payloadInput.project_id,
        task_id: payloadInput.task_id,
        isDeleted: payloadInput.isDeleted,
        isCompleted: payloadInput.isCompleted,
        isArchived: payloadInput?.isArchived
      };
      if (manuplatedObj && manuplatedObj.project_id) {
        let payload = {
          ...manuplatedObj,
          roomname: `${labelSocketRoomPrefix}${id}_${props?.auth?.user?.businessId}`
        };
        if (
          manuplatedObj?.title &&
          isEditTask &&
          isEditTask.status &&
          isEditTask?.data?.id
        ) {
          payload = {
            ...payload,
            task_id: isEditTask?.data.id.toString()
          };
        }
        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            setIsEditTask({ status: false, data: "" });
            if (
              payload?.scheduledAt &&
              payload?.scheduledAt != null &&
              syncGoogle
            ) {
              SyncTasktoGoogle(payload);
            }
          } else {
            if (cb.status || cb.message) {
              handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            showNotifications();
          }
        });
      }
    } catch (e) {}
  };
  const updateIsCompleted = async (projectId, todoId, isCompleted, data) => {
    try {
      if (projectId && todoId) {
        let payload = {
          project_id: projectId.toString(),
          isCompleted: isCompleted,
          task_id: todoId.toString()
        };
        if (data) {
          if (data.scheduledAt) {
            payload = { ...payload, scheduledAt: data.scheduledAt };
          }
        }
        if (data) {
          if (data.reminderAt) {
            payload = { ...payload, reminderAt: data.scheduledAt };
          }
        }
        updateTaskHandler(payload, null);
      }
    } catch (e) {}
  };

  const doCloseQuickAction = () => {
    setIsQuickActionTask({
      status: false,
      data: "",
      target: "",
      selectedData: ""
    });
  };
  const doUpdateTaskPriority = async (updatedPriority, taskDetails) => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };
    if (updatedPriority) {
      let payload = {
        priority: updatedPriority,
        task_id: isQuickActionTask?.data.id.toString(),
        project_id: isQuickActionTask.data.project.id.toString(),
        section_id: isQuickActionTask.data.section.id
          ? isQuickActionTask.data.section.id.toString()
          : ""
      };

      doUpdateQuickActionTask(payload, handleResponse);
    }
  };
  const doUpdateTaskScheduleDate = async (updatedDueDate, taskDetails) => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };
    let payload = {
      scheduledAt: new Date(updatedDueDate),
      task_id: isQuickActionTask?.data.id.toString(),
      project_id: isQuickActionTask.data.project.id.toString(),
      section_id: isQuickActionTask.data.section.id
        ? isQuickActionTask.data.section.id.toString()
        : ""
    };

    doUpdateQuickActionTask(payload, handleResponse);
  };
  const doUpdateTaskAssign = async udpatedAssignee => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };

    let payload = {
      user_id: udpatedAssignee,
      task_id: isQuickActionTask?.data.id.toString(),
      project_id: isQuickActionTask.data.project.id.toString(),
      section_id: isQuickActionTask.data.section.id
        ? isQuickActionTask.data.section.id.toString()
        : ""
    };

    doUpdateQuickActionTask(payload, handleResponse);
  };
  const doUpdateQuickActionTask = (payloadInput, handleResponse) => {
    if (payloadInput) {
      try {
        let payload = {
          ...payloadInput,
          roomname: `${labelSocketRoomPrefix}${id}_${props?.auth?.user?.businessId}`
        };

        if (!payloadInput.reminderAt && isQuickActionTask.data.reminderAt) {
          payload = {
            ...payload,
            reminderAt: isQuickActionTask.data.reminderAt
          };
        }

        if (
          !payloadInput.hasOwnProperty("scheduledAt") &&
          isQuickActionTask.data.scheduledAt
        ) {
          payload = {
            ...payload,
            scheduledAt: isQuickActionTask.data.scheduledAt
          };
        }

        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            if (handleResponse) {
              handleResponse({
                status: cb.status
              });
            }
            setIsEditTask({ status: false, data: "" });
            // if (
            //   payload?.scheduledAt &&
            //   payload?.scheduledAt != null &&
            //   syncGoogle
            // ) {
            //   SyncTasktoGoogle(payload);
            // }
            // setTaskinput("");
          } else {
            if (cb.status || cb.message) {
              handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            showNotifications();
          }
        });
      } catch (e) {}
    }
  };
  const refreshTodoList = async newTodo => {
    let todoArr = labelRef.current;
    let newTodoToarr = [newTodo];
    let status = false;
    await Promise.all(
      todoArr.map((item, index) => {
        if (item.id == newTodoToarr[0].id) {
          status = true;

          todoArr[index] = { ...todoArr[index], ...newTodoToarr[0] };
        }
      })
    );

    if (!status) {
      todoArr = [...todoArr, newTodo];
    }
    setLabelTodo([...todoArr]);
  };
  const removeTodoList = async newTodo => {
    let todoArr = labelRef.current;
    let newTodoToarr = [newTodo];
    let status = false;
    await Promise.all(
      todoArr.map((item, index) => {
        if (item.id == newTodoToarr[0].id) {
          status = true;
          todoArr.splice(index, 1);
        }
      })
    );

    if (!status) {
      todoArr = [...todoArr, newTodo];
    }
    setLabelTodo([...todoArr]);
  };
  const removeTaskFromList = id => {
    const TodoArr = LabelTodo;
    TodoArr.map((data, i) => {
      if (data.id == id) {
        setTempTask(TodoArr[i]);
        TodoArr.splice(i, 1);
      }
    });

    setLabelTodo([...TodoArr]);
  };
  const pushTask = taskData => {
    if (taskData.id == tempTask.id) {
      const TodoArr = LabelTodo;
      TodoArr.push(tempTask);
      setLabelTodo([...TodoArr]);
    }
  };
  const handleSearch = query => {
    /* fetching the input and checking if exist in customerlistarray */
    let filteredTodos;
    if (!query) {
      // setSearchContact(false);
      setSearchLabelTodoQueryResult(null);
    } else {
      filteredTodos = LabelTodo.filter(labels => {
        return labels.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });

      // setSearchContact(true);
      setSearchLabelTodoQueryResult(filteredTodos);
    }

    /* To display content of array of search filter what I can do is to pass this array to Contact Component */
  };
  const handleArchiveWorkpsace = async () => {
    if (props?.auth?.user?.businessId) {
      try {
        setIsArchiveloader(true);
        const response = await toggleArchive(props?.auth?.user?.businessId);
        if (response) {
          console.log(response);
          if (response.message) {
            setIsArchiveloader(false);

            if (!checkIsArchivedMessage(response.message)) {
              props.update_business_archive(false);
            }
          }
          if (response.error) {
            handleResponse(response.error.message);
            setIsArchiveloader(false);
            showNotifications();
          }
        }
      } catch (e) {
        setIsArchiveloader(false);
        showNotifications();
      }
    }
  };

  const checkArchive = () => {
    if (props?.auth?.user?.isArchived) {
      return (
        <>
          <div className="workspace-archived">
            <div className="workspace-archived-border flex-x fs-14">
              This workspace has been&nbsp;archived.&nbsp;{""}
              {props?.auth?.user?.businessId &&
                props?.auth?.user?.isBusinessOwner && (
                  <>
                    Click here to&nbsp;
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        display: "flex"
                      }}
                      onClick={handleArchiveWorkpsace}
                    >
                      <b>Unarchive</b>{" "}
                      {isArchiveLoader && <Loader height="18px" width="15px" />}
                    </div>{" "}
                  </>
                )}
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
        <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
          {" "}
          {checkArchive()}
        </div>
        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
      </div>
      <div className="row">
        <div
          className="col-lg-1 col-md-1 col-sm-0 col-xs-0"
          style={{ padding: 0 }}
        >
          <div
            className="lable"
            style={{
              backgroundColor: "white",
              height: 60,
              borderBottom: "1px solid #EBEBEB"
            }}
          ></div>
        </div>
        <div
          className="col-lg-10 col-md-10 col-sm-12 col-xs-12"
          style={{ padding: 0 }}
        >
          <div className="roe-card-style">
            <div
              ref={LableViewRef}
              className="roe-card-header"
              style={{
                height: "60px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                borderBottom: "1px solid #EBEBEB"
              }}
            >
              <div
                className="file-manager-toolbar flex-x"
                style={{ width: "100%" }}
              >
                <div
                  className="flex-1"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span
                    className="fs-14 demi-bold-text"
                    style={{ display: "flex" }}
                  >
                    {/* <i
                    className="fa fa-tag"
                    style={{
                      paddingRight: 10,
                      color: getColorCode(label && label.color)
                    }}
                  /> */}
                    {isLoader ? (
                      <img src={iconTag} height="22px" width="22px" />
                    ) : (
                      <LableIcon lableColor={lableColor}></LableIcon>
                    )}
                    {/* {lableColor? <IconTint
                      maxHeight="22"
                      maxWidth="22"
                      src={iconTag}
                      style={{
                        position: "relative",
                        top: "1px",
                        height: "18",
                        width: "18",
                      }}
                      color={lableColor}
                    />:""} */}
                    <span
                      style={{
                        fontSize: "15px",
                        color: "black",
                        fontWeight: "600",
                        marginLeft: 12,
                        wordBreak: " break-all",
                        lineHeight: "1.5em",
                        textOverflow: "ellipsis",
                        width: "100%",
                        overflow: "hidden",
                        WebkitLineClamp: 2,
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical"
                      }}
                    >
                      {" "}
                      {label && label.name}
                    </span>
                  </span>
                </div>
                <div style={{}}>
                  {/* {isSearch && ( */}
                  {/* <LableWrapper>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: 35,
                        backgroundColor: " #F5F5F5",
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5
                      }}
                    >
                      <img
                        src={SearchHeaderPriorityLableImage}
                        style={{
                          height: 16,
                          width: 22,
                          marginLeft: 3
                        }}
                      ></img>
                    </div>

                    <div className="searchStyle pos-relative">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSearchInput(null);
                          setIsSearch(!isSearch);
                        }}
                        className="search-contant "
                      >
                        <Input
                          autoFocus
                          value={searchInput}
                          placeholder="Search"
                          className="react-form-search-input"
                          onChange={e => handleSearch(e.target.value)}
                        />

                      </span>
                    </div>
                  </div>
                </LableWrapper> */}
                  {/* )} */}
                  {/* {!isSearch && ( */}
                  {/* <div className="flex center">
                  <div className="mr-1">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSearchInput(null);
                        setIsSearch(!isSearch);
                      }}
                      className="search-contant "
                    >
                      <i className="fas fa-search text-muted"></i>
                    </span>
                  </div>
                </div> */}
                  {/* )} */}
                </div>

                {/* <div
                className="ml-15"
                id="popover"
                onClick={() => setMoreLabelOptions(!moreLabelOptions)}
              > */}
                {/* <span>
                  <div id="moreoptions" className="cursor-pointer">
                    <i className="fas fa-ellipsis-h"></i>
                  </div>
                  <LabelMenu
                    PRIMARY_COLOR={props.PRIMARY_COLOR}
                    moreLabelOptions={moreLabelOptions}
                    setMoreLabelOptions={() =>
                      setMoreLabelOptions(!moreLabelOptions)
                    }
                    toggleEditLabel={() => setEditLabelModal(!editLabelModal)}
                  />
                </span> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-lg-1 col-md-1 col-sm-0 col-xs-0"
          style={{ padding: 0 }}
        >
          <div
            className="lable"
            style={{
              backgroundColor: "white",
              height: 60,
              borderBottom: "1px solid #EBEBEB"
            }}
          ></div>
        </div>

        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0" />
        <div
          className="col-lg-10 col-md-10 col-sm-12 col-xs-12 "
          style={{ padding: 0, minHeight: "87vh" }}
        >
          <div className="">
            {isLoader && (
              <div className="roe-card-style mtb-15 todo-board">
                {" "}
                <Shimmer />
              </div>
            )}
            {!isLoader && LabelTodo && (
              <>
                <LabelsTodoListView
                  doCloseQuickAction={doCloseQuickAction}
                  doUpdateTaskPriority={doUpdateTaskPriority}
                  doUpdateTaskScheduleDate={doUpdateTaskScheduleDate}
                  doUpdateTaskAssign={doUpdateTaskAssign}
                  isQuickActionTask={isQuickActionTask}
                  setIsQuickActionTask={setIsQuickActionTask}
                  history={{ ...props.history }}
                  isEditTask={isEditTask}
                  setIsEditTask={setIsEditTask}
                  updateTaskHandler={updateTaskHandler}
                  PRIMARY_COLOR={props.topbarTheme.buttonColor}
                  updateIsCompleted={updateIsCompleted}
                  labelId={id}
                  todoList={LabelTodo}
                  refreshTodoList={data => refreshTodoList(data)}
                  showNotifications={showNotifications}
                  collaboratorList={[]}
                  dummyData={false}
                  currentLabelId={id}
                  removeTaskFromList={id => removeTaskFromList(id)}
                  pushTask={task => pushTask(task)}
                  auth={{ ...props.auth }}
                />
              </>
            )}

            <EditLabel
              modal={editLabelModal}
              setEditLabelModal={() => setEditLabelModal(!editLabelModal)}
              project={label}
              updateLabelToRedux={data => updateLabelToRedux(data)}
              setProject={data => setLabel(data)}
              showNotifications={showNotifications}
            />
            <CustomNotification
              show={toast}
              message={toastMessage}
              closeNotification={() => setToast(false)}
            />
          </div>
        </div>
        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0" />
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    ...state
  };
};

export default connect(mapStateToProps, {
  update_labels_existing,
  open_workpsace_alert,
  open_project_alert,
  close_alert,
  update_business_archive
})(Label);
