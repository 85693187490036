import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";

const StyleSheet = {
  container: {
    border: "0px solid rgba(255, 255, 255, 1)",
    borderRadius: "4px",
    display: "flex",
    padding: "16px",
    width: "100%"
  },
  square: {
    height: "50px",
    width: "50px"
  },
  line: {
    width: "90px",
    height: "8px",
    alignSelf: "center",
    marginLeft: "16px"
  },
  line2: {
    width: "40px",
    height: "8px",
    // alignSelf: "center",
    marginTop: 12,
    marginLeft: "300px"
  }
};
class App extends Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.container}>
          <Shimmer>
            <div className={classes.square} />
            <div className={classes.line} />
          </Shimmer>
        </div>
        <div className={classes.container}>
          <Shimmer>
            <div className={classes.square} />
            <div className={classes.line} />
          </Shimmer>
        </div>
      </>
    );
  }
}

export default injectSheet(StyleSheet)(App);
