import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    newPassword: Yup.string()
      .required("Please enter new password ")
      .min(8, "Password must be at least 8 characters long"),
    confirmPassword: Yup.string()
      .required("Please enter confirm new password")
      .min(8, "Password must be at least 8 characters long")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "New password and confirm new password must be same to change password "
      )
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
