/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ProjectCollaboratorsCross, emailIcon } from "helper/constant";
import { connect } from "react-redux";
import { cardImage } from "helper/methods/getUserAvatarColor";
import profileModelAction from "redux/profileModel/actions";
import "./userProfileModel.css";
const { open_profile_modal, close_profile_modal } = profileModelAction;
const UserProfileModel = props => {
  const InCap = {
    lineHeight: 1,
    fontSize: 100,
    // color: "rgb(23, 43, 77)",
    color: "#fff",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600
  };

  return (
    <>
      {props.profileModelAction.isOpen &&
      props.profileModelAction &&
      props.profileModelAction.profile ? (
        <Modal
          isOpen={props.profileModelAction.isOpen}
          toggle={() =>
            props.profileModelAction.isOpen
              ? props.close_profile_modal()
              : props.open_profile_modal()
          }
          size="lg"
          centered
          contentClassName="over_hidden"
          style={{
            width: 300
          }}
        >
          <form>
            <ModalHeader
              className="border_none"
              style={{
                position: "relative",
                borderBottom: "1px solid #fff !important",
                backgroundColor: "#fff"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #fff"
                }}
                onClick={() => {
                  props.close_profile_modal();
                }}
              >
                <div style={{ marginBottom: "30px" }}>
                  <img
                    src={ProjectCollaboratorsCross}
                    style={{
                      height: "30px",
                      width: "30px",
                      marginRight: "0px",
                      marginTop: "0px",
                      textAlign: "center",
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                      cursor: "pointer",
                      color: "#fff",
                      backgroundColor: "white",
                      padding: 10,
                      borderRadius: 5,
                      filter: "grayscale(100%)",
                      zIndex: 10
                    }}
                  ></img>
                </div>
              </div>
            </ModalHeader>

            <ModalBody
              style={{
                minHeight: 320,
                backgroundColor: "#fff"
              }}
            >
              <div
                style={{
                  height: 400,
                  overflow: "auto",
                  position: "absolute",
                  top: "-65px",
                  left: "0px"
                }}
              >
                <div
                  // style={{ paddingLeft: 14 }}
                  className=""
                  /* key={i} */
                >
                  <div
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "max-content"
                    }}
                  >
                    {(props.profileModelAction.profile.thumbnail &&
                      props.profileModelAction.profile.thumbnail !== null) ||
                    (props.profileModelAction.profile?.userMeta &&
                      props.profileModelAction.profile?.userMeta !== null) ? (
                      <div>
                        <img
                          src={
                            props.profileModelAction.profile.thumbnail ||
                            props.profileModelAction.profile.userMeta.thumbnail
                          }
                          style={{
                            width: 300,
                            height: 250,
                            fontWeight: "600",
                            textAlign: " center",
                            color: "white",
                            display: " flex",
                            justifyContent: "center",
                            alignItems: " center",
                            border: "none",
                            objectFit: "cover"
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={cardImage(
                          props.profileModelAction.profile
                            ? props.profileModelAction.profile.name.charAt(0)
                            : props.profileModelAction.profile.email,
                          300,
                          250,
                          "none"
                        )}
                      >
                        <span style={InCap}>
                          {props.profileModelAction.profile &&
                          props.profileModelAction.profile.name
                            ? props.profileModelAction.profile.name
                                .charAt(0)
                                .toUpperCase()
                            : props.profileModelAction.profile.email
                                .charAt(0)
                                .toUpperCase()}
                        </span>
                      </div>
                    )}

                    <div className="">
                      <p
                        className=" fs-14 demi-bold-text"
                        style={{
                          marginBottom: "5px",
                          marginLeft: "0px",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            color: "#000"
                          }}
                        >
                          {props.profileModelAction.profile &&
                          props.profileModelAction.profile.name
                            ? props.profileModelAction.profile.name
                            : props.profileModelAction.profile.email.split(
                                "@"
                              )[0]}
                        </span>
                      </p>
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          color: "#000",
                          fontSize: "16px",
                          alignItems: "center",
                          textAlign: "left",
                          justifyContent: "center",
                          marginBottom: "15px"
                        }}
                      >
                        {/* <img
                          src={emailIcon}
                          style={{
                            height: "16px",
                            width: "16px",
                            marginRight: 6,
                          }}
                          alt="email-icon"
                        /> */}
                        <span
                          style={{
                            position: "relative",
                            top: "-2px",
                            color: "#808080"
                          }}
                        >
                          {props.auth.user.designation}
                        </span>
                      </span>{" "}
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          color: "#000",
                          fontSize: "16px",
                          alignItems: "center",
                          textAlign: "left",
                          margin: "0px 20px",
                          padding: "8px",
                          borderRadius: 8,
                          justifyContent: "center",
                          backgroundColor: "#bababa40"
                        }}
                      >
                        {/* <img
                          src={emailIcon}
                          style={{
                            height: "16px",
                            width: "16px",
                            marginRight: 6,
                          }}
                          alt="email-icon"
                        /> */}
                        <div>
                          <span style={{ position: "relative", top: "-2px" }}>
                            {props.profileModelAction.profile.email}
                          </span>
                        </div>
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </form>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {
  open_profile_modal,
  close_profile_modal
})(UserProfileModel);
