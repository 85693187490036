import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
const ShimmerLoader = props => {
  const StyleSheet = {
    container: {
      border: "0px solid rgba(255, 255, 255, 1)",
      // boxShadow: "0px 0px 20px rgba(0, 0, 0, .1)",
      borderRadius: "4px",
      backgroundColor: "white",
      display: "flex",
      marginBottom: "10px",
      paddingLeft: "10px",
      width: "100%",
      boxShadow: "rgb(0 0 0 / 0%) 0px 0px 20px"
    },
    circle: {
      height: "190px",
      width: "210px",
      background: "#e4e4e4",
      borderRadius: "5px"
    },
    nameline: {
      width: "220px",
      height: "8px",
      alignSelf: "center",
      marginLeft: "10px",
      marginTop: "30px",
      borderRadius: "8px",
      marginBlockStart: "-25px",
      background: "#e4e4e4"

      // backgroundImage: " linear- gradient(to right,#dcdcdc 40 %,#cacaca 50 %,#ffffff 60 %)"
    }
  };
  // const { classes } = props.props;
  let i = 0;
  return (
    <>
      <div
        key={i++ * 2}
        style={{ textAlign: "center", height: "100%" }}
        className="row"
      >
        <div
          className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12"
          style={{ padding: 0 }}
        >
          <Shimmer>
            <div
              className="flex-x ptb-15 plr-5 mt-33 border-bottom "
              // style={StyleSheet.container}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div style={StyleSheet.circle} key={i++} />
              </div>
              <div style={{ paddingTop: 40, marginTop: 15 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
              <div style={{ paddingTop: 40 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
            </div>
          </Shimmer>
        </div>
        <div
          className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12"
          style={{ padding: 0 }}
        >
          <Shimmer>
            <div
              className="flex-x ptb-15 plr-5 mt-33 border-bottom "
              // style={StyleSheet.container}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div style={StyleSheet.circle} key={i++} />
              </div>
              <div style={{ paddingTop: 40, marginTop: 15 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
              <div style={{ paddingTop: 40 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
            </div>
          </Shimmer>
        </div>
        <div
          className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12"
          style={{ padding: 0 }}
        >
          <Shimmer>
            <div
              className="flex-x ptb-15 plr-5 mt-33 border-bottom "
              // style={StyleSheet.container}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div style={StyleSheet.circle} key={i++} />
              </div>
              <div style={{ paddingTop: 40, marginTop: 15 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
              <div style={{ paddingTop: 40 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
            </div>
          </Shimmer>
        </div>
        <div
          className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12"
          style={{ padding: 0 }}
        >
          <Shimmer>
            <div
              className="flex-x ptb-15 plr-5 mt-33 border-bottom "
              // style={StyleSheet.container}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div style={StyleSheet.circle} key={i++} />
              </div>
              <div style={{ paddingTop: 40, marginTop: 15 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
              <div style={{ paddingTop: 40 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
            </div>
          </Shimmer>
        </div>
        <div
          className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12"
          style={{ padding: 0 }}
        >
          <Shimmer>
            <div
              className="flex-x ptb-15 plr-5 mt-33 border-bottom "
              // style={StyleSheet.container}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div style={StyleSheet.circle} key={i++} />
              </div>
              <div style={{ paddingTop: 40, marginTop: 15 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
              <div style={{ paddingTop: 40 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
            </div>
          </Shimmer>
        </div>
        <div
          className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12"
          style={{ padding: 0 }}
        >
          <Shimmer>
            <div
              className="flex-x ptb-15 plr-5 mt-33 border-bottom "
              // style={StyleSheet.container}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div style={StyleSheet.circle} key={i++} />
              </div>
              <div style={{ paddingTop: 40, marginTop: 15 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
              <div style={{ paddingTop: 40 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
            </div>
          </Shimmer>
        </div>

        {/* <div className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12">
          <Shimmer>
            <div
              className="flex-x pa-15 mt-35 mlr-15 mb-6 border-bottom "
              // style={StyleSheet.container}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div style={StyleSheet.circle} key={i++} />
              </div>
              <div style={{ paddingTop: 40, marginTop: 15 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
              <div style={{ paddingTop: 40 }}>
                <div style={StyleSheet.nameline} key={i++} />
              </div>
            </div>
          </Shimmer>
        </div> */}
      </div>
    </>
  );
};
export default ShimmerLoader;
