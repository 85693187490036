import React from "react";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
const AddCheckList = ({
  addNewChecklist,
  isAddChecklist,
  setIsAddChecklist,
  showNotifications,
  topbarTheme,
  props
}) => {
  const myRef = useRef(null);
  const [isAdd, setisAdd] = useState(false);
  const [input, setInput] = useState("Checklist name");
  const checkListInputRef = useRef("");
  useEffect(() => {
    if (isAddChecklist) {
      setisAdd(true);
      checkListInputRef.current && checkListInputRef.current.focus();
    }
    if (myRef && myRef.current /* + other conditions */) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    return () => {
      setisAdd(false);
      setInput("");
      checkListInputRef.current && checkListInputRef.current.blur();
    };
  }, [isAddChecklist]);
  const handleResponse = Response => {
    if (Response) {
      if (Response.status.toLowerCase() === "ok") {
      } else {
        showNotifications && showNotifications();
      }
    }
  };
  const handleAddNewItem = () => {
    addNewChecklist(input, handleResponse);
    setisAdd(false);
    setInput("");
    setIsAddChecklist(false);
    checkListInputRef.current && checkListInputRef.current.blur();
  };
  const handleCancelAddNewItem = () => {
    setisAdd(false);
    setInput("");
    setIsAddChecklist(false);
    checkListInputRef.current && checkListInputRef.current.blur();
  };

  return (
    <>
      {isAddChecklist && (
        <>
          <div className="add-comment-input-block mt-10" ref={myRef}>
            <input
              ref={checkListInputRef}
              defaultValue={"Checklist Title"}
              value={input}
              autoFocus
              className="add-subtask-input inputStyle"
              name="checklistTitle"
              rows="1"
              onFocus={e => e.target.select()}
              onChange={e => setInput(e.target.value)}
              style={{
                border: "1px solid #EBEBEB",
                backgroundColor: "#F8F8F8"
              }}
              placeholder={"Item title"}
              onKeyPress={event => {
                if (event.key === "Enter") {
                  /* checks if white space at starting of input present or not */
                  if (!event.shiftKey && input.match(/^\S+\w{0,50}\S{0,}/g)) {
                    event.preventDefault();
                    handleAddNewItem();
                  }
                }
              }}
            />
            <div className="subtask-action" style={{ margin: "15px 0px" }}>
              <>
                <button
                  className="btn mr-10"
                  style={{
                    boxShadow: "none",
                    borderRadius: "17px",
                    backgroundColor: topbarTheme.buttonColor,
                    color: "white",
                    width:
                      props.language.language.languageId !== "english"
                        ? "98px"
                        : "65px"
                  }}
                  disabled={!input.match(/^\S+\w{0,50}\S{0,}/g) ? true : false}
                  onClick={handleAddNewItem}
                >
                  <IntlMessages id={"project.add"} />
                </button>
                <button
                  className="btn btn"
                  onClick={handleCancelAddNewItem}
                  style={{
                    boxShadow: "none",
                    borderRadius: "17px",
                    border: `1px solid ${topbarTheme.buttonColor}`,
                    color: topbarTheme.buttonColor,
                    width:
                      props?.language?.language?.languageId !== "english"
                        ? "95px"
                        : "80px"
                  }}
                >
                  <IntlMessages id={"project.cancel"} />
                </button>
              </>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {})(AddCheckList);
