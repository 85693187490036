import styled from "styled-components";

const DatePickerWrapper = styled.div`
  .date-picker-block {
    .roe-card-style {
      height: 100%;
    }
  }
  .react-datepicker-time__header {
    visibility:${props =>
      props.language.languageId == "german" ? "hidden" : "visible"};
    position: relative;

}
.react-datepicker-time__header:after {
    visibility: ${props =>
      props.language.languageId == "german" ? "visible" : "hidden"};
    position: absolute;
    left : 17px !important;       
    content: "Zeit"
   
}
 
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    // margin: 8px !important;
    font-size: 14px !important;
    // font-weight: bold !important;
    outline: none !important;
  }
  .react-datepicker-popper {
    border-radius: 10px !important;
    box-shadow: 0px 0px 15px 1px #0000001a !important;
  }
  .react-datepicker {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border: 0px solid !important;
    display: flow-root !important;
    min-width: 330px !important;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker__triangle{
    display: none !important;
  }
 
  .react-datepicker__month-container{
    border-bottom: 1px solid #EBEBEB!important; 
    min-height:276px !important;
  }
  .custom-datepicker {
    color: black !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    border: 1px solid #ddd !important;
    padding: 8px;
    width: 100%;
    &:focus {
      border: 1px solid #9a9a9a !important;
      box-shadow: none !important;
      outline: 0;
    }
  }
  .custom-calender-class .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name{
    color: red !important;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
    width:60px !important;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
    height: 38px !important;
    padding: 8px 10px 0px !important;
    white-space: nowrap !important;
    // font-weight: bold !important;
  }
  .react-datepicker__time-container{
    width:70px !important;
    border-left: 1px solid #EBEBEB !important; 
    border-bottom: 1px solid #EBEBEB!important; 
  }
  .react-datepicker__month-container{
    border-bottom: 1px solid #EBEBEB!important; 
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
    }
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
    right: 90px !important;
    top:20px !important;
    border-left-color: #808080 !important;
  } 
  .react-datepicker__navigation--previous{
    left: 25px !important;
    top:20px !important;
    border-right-color: #808080 !important;
  }
  
  

  .custom-calender-class {
    display: -webkit-inline-flex;
    .react-datepicker__triangle { 
      border-bottom-color: rgba(0, 0, 0, 0.035);
    }
    .react-datepicker__time-list-item {
      color: black;
    }
    .react-datepicker__header {
      padding-top: 15px !important;
      background: rgba(255, 255, 255) !important;
      border-bottom: none !important;
      .react-datepicker-time__header {
        color: rgba(0, 0, 0, 0.87) !important;
      }
      .react-datepicker__current-month {
        color: rgba(0, 0, 0, 0.87) !important;
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          color: rgba(0, 0, 0, 0.87) !important;
        }
      }
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-boxreact-datepicker__time-box ul.react-datepicker__time-list{
      // min-height: 264px !important;
    }
    .react-datepicker__month{
      margin: 5px 15px  !important;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected {
      background-color: ${props => props.topbarTheme};
    }

    .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      background-color: ${props => props.topbarTheme};
    }
    .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected:hover {
      background-color: ${props => props.topbarTheme};
    }
  }

  .react-datepicker__month {
    .react-datepicker__week {
      .react-datepicker__day--selected {
        background-color: ${props => props.topbarTheme};
        color: white;
      }
    }


    .container, .myDatePickerWrapper, .container .react-datepicker-wrapper, .container .react-datepicker__input-container {
      .myDatePicker, .myDatePickerWrapper, .container .react-datepicker-wrapper, .container .react-datepicker__input-container {
        background: red;
        width: 100px;
        & :global .react-datepicker__input-container {
          width: 100px;
        }
      }
    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
      border-left: 1px solid red !important;
      width: 100px !important;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
      border-left: 1px solid red !important;
      width: 100px !important;
    }
    .react-datepicker__time-container {
      .react-datepicker__time {
        .react-datepicker__time-box ul {
          .react-datepicker__time-list li {
            .react-datepicker__time-list-item--selected {
              background-color: ${props => props.topbarTheme};
              border-left: 1px solid red !important;
              width: 100px !important;
            }
          }
        }
      }
    }
  }
`;

export default DatePickerWrapper;
