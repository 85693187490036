import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import TaskactivityList from "./TaskactivityList";
import { getTaskActivity } from "helper/services/taskServices";
// import GlobalWrapper from "./wrapper.style";
const TaskActivity = ({
  isOpen,
  toggleTaskActivity,
  projectId,
  showNotifications,
  taskId
}) => {
  const [taskActivityData, setTaskActivity] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fecthTaskActivity();
    }
    return () => {
      setTaskActivity(null);
    };
  }, [isOpen]);
  const fecthTaskActivity = async () => {
    const Response = await getTaskActivity(taskId);
    if (Response) {
      if (Response.error) {
      }
      if (Response.data) {
        setTaskActivity(Response.data[0]?.taskActivity);
      }
    }
  };

  return (
    <div>
      {/* <GlobalWrapper> */}
      <Modal
        isOpen={isOpen}
        toggle={() => toggleTaskActivity()}
        size="xl"
        centered
      >
        <ModalHeader toggle={() => toggleTaskActivity()}>
          Task Activity{" "}
          <span style={{ color: "grey", fontSize: 16 }}>
            ({taskActivityData && taskActivityData.length})
          </span>
        </ModalHeader>
        <ModalBody
          style={{
            height: 400,
            overflow: "auto"
          }}
        >
          <TaskactivityList
            taskActivityArr={taskActivityData && taskActivityData}
          />
        </ModalBody>
      </Modal>
      {/* </GlobalWrapper> */}
    </div>
  );
};

export default TaskActivity;
