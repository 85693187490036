import React from "react";
import { Popover, PopoverBody } from "reactstrap";
const MoreOptionsMenu = ({
  moreProjectOptions,
  setMoreProjectOptions,
  toggleEditProject,
  viewMode,
  updateViewOptions,
  setSectionCardFlag
}) => {
  React.useEffect(() => {}, []);
  return (
    <div>
      <Popover
        trigger="legacy"
        className="roy-menu"
        innerClassName="roy-inner-content"
        placement="bottom-end"
        target="popover"
        hideArrow={true}
        isOpen={moreProjectOptions}
        toggle={() => setMoreProjectOptions()}
      >
        <PopoverBody>
          {/* <div
            className="roy-menu-list"
            onClick={updateViewOptions}
          >{`View as ${viewMode && viewMode !== null && viewMode == "list"
              ? "Grid"
              : "List"
            }`}</div>
          <hr /> */}
          <div className="roy-menu-list" onClick={() => setSectionCardFlag()}>
            Add List
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default MoreOptionsMenu;
