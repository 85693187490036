export const getArraytoString = arrayData => {
  try {
    if (arrayData && arrayData.length > 0) {
      let newArray = "";
      arrayData &&
        arrayData.map(
          a => (newArray = newArray ? newArray + `,${a.id}` : `${a.id}`)
        );

      return newArray;
    } else {
      return "";
    }
  } catch (e) {}
};
export const onChangeTaskAssign = (data, onSubmit) => {
  if (data && data.user) {
    let userIds = getArraytoString(data.user);
    onSubmit(userIds);
  }
};
export const getCollabData = (givenId, collaboratorList) => {
  if (collaboratorList) {
    collaboratorList &&
      collaboratorList.map(data => {
        if (data?.user && data.user.id === givenId) {
          return data;
        }
      });
  }

  return false;
};
