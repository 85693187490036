import React, { useEffect } from "react";
import RoyTooltip from "components/common/RoyTooltip";
import { profileBackground } from "helper/methods/getUserAvatarColor";

const Member = ({ member, text, last, index }) => {
  const profileImage = {
    // borderRadius: "50%",
    // border: "2px solid white",
    // width: "24px",
    // height: "24px",
    // objectFit: "fill",
    // textAlign: "center",
    // backgroundColor: "#eaeaea",
    // // backgroundColor: "#e2e2e2",
    // position: "relative",
    // zIndex: "2",
    // marginTop: "4px",
    // fontSize: "12px"
    // background: "#F86B5E",
    borderRadius: " 50%",
    width: "35px",
    height: "35px",
    fontWeight: "500",
    textAlign: " center",
    color: "white",
    fontSize: " 14px",
    display: " flex",
    justifyContent: "center",
    alignItems: " center",
    objectFit: "cover",
    border: "1px solid white"
  };
  const collabsStyle = (index = "") => {
    if (index && index == 1)
      return {
        display: "flex",
        paddingLeft: 0,
        textAlign: "center",
        justifyContent: "center",
        position: "relative",
        left: "-5px",
        zIndex: 4
      };
    else if (index && index == 2) {
      return {
        display: "flex",
        paddingLeft: 0,
        textAlign: "center",
        justifyContent: "center",
        position: "relative",
        zIndex: 3,
        left: "-10px"
      };
    } else if (index && index == 3) {
      return {
        display: "flex",
        paddingLeft: 0,
        textAlign: "center",
        justifyContent: "center",
        position: "relative",

        zIndex: 2,
        left: "-15px"
      };
    }

    return {
      display: "flex",
      paddingLeft: 0,
      textAlign: "center",
      justifyContent: "center",
      position: "relative",
      zIndex: 5
      // left: "-4px",
    };
  };
  const InCap = {
    fontSize: 13,
    color: "white",
    textAlign: "center",
    marginBottom: 0,
    display: "",
    position: "relative",
    left: -1,
    fontWeight: "bold"
  };
  useEffect(() => {
    if (member) {
    }
  }, []);
  if (last) {
    return (
      <div style={{ paddingLeft: 0, zIndex: 1 }}>
        <div
          style={{
            background: "#F86B5E",
            borderRadius: " 50%",
            width: "35px",
            height: "35px",
            fontWeight: "500",
            textAlign: " center",
            color: "white",
            fontSize: " 14px",
            display: " flex",
            justifyContent: "center",
            alignItems: " center",
            objectFit: "cover",
            position: "relative",
            left: -20,
            border: "1px solid white"
          }}
        >
          {/* style={profileBackground("", 25, 25)} */}
          <span style={InCap}>
            {" "}
            {/* <i
              className="material-icons"
              style={{ fontSize: "12px", color: "black", display: "inline" }}
            >
              {" "}
              add{" "}
            </i> */}
            <span
              style={{
                fontSize: "14px",
                paddingRight: "1px",
                fontWeight: "bold",
                position: "relative",
                left: 1
              }}
            >
              +
            </span>
            {text}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <RoyTooltip
        id={`member-${member.id}`}
        title={member?.user?.name}
        style={{ display: "flex" }}
      >
        <div
          id={`member-${member.id}`}
          className=" "
          style={collabsStyle(index)}
        >
          {member.user &&
          member.user?.thumbnail &&
          member.user?.thumbnail != "" ? (
            <img
              src={member.user?.thumbnail}
              // alt="member"
              style={profileImage}
              alt=""
            />
          ) : (
            <div
              style={profileBackground(
                member.user && member.user.name
                  ? member.user.name
                  : member.user.email,
                35,
                35,
                14
              )}
            >
              {member.user && member.user.name
                ? member.user.name.charAt(0).toUpperCase()
                : member.user.email.charAt(0).toUpperCase()}
            </div>
          )}
        </div>
      </RoyTooltip>
    );
  }
};

export default Member;
