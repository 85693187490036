import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import {
  retrieveGlobalSearch,
  retrieveTaskGlobalSearch,
  retrieveProjectGlobalSearch
} from "helper/services/globalSearchServices";
import SearchPopover from "./SearchPopover";
import { pageLimit } from "constatnt/index";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";
import ChangeWorkspaceAlert from "util/ChangeWorkspaceAlert";
import { swicthBusiness } from "helper/services/businessServices";
import { connect } from "react-redux";
import {
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import { getProject, getLables } from "helper/services/projectServices";
import { textConstants } from "helper/textConstants";
import labelActions from "redux/labels/actions";
import projectActions from "redux/projects/actions";
import businessActions from "redux/business/actions";
import AuthActions from "redux/auth/actions";
import { handleRecentWorkspace } from "util/RecentWorkspace";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
const { update_token, update_business } = AuthActions;
const { default_business } = businessActions;
const { set_labels, update_Label } = labelActions;
const { set_projects, update_recent_Project } = projectActions;
const { update_Project } = projectActions;

const GlobalSearch = props => {
  const { PRIMARY_COLOR } = props;
  const [searchResult, setSearchResult] = useState({
    workspaces: [],
    tasks: [],
    projects: [],
    moreProject: false,
    moreTask: false,
    moreWorkspace: false
  });
  const [searchResultPages, setSearchResultPages] = useState({
    tasks: {
      totalPages: 1,
      totalRecords: "",
      currentPage: 1
    },
    workspaces: {
      totalPages: 1,
      totalRecords: "",
      currentPage: 1
    },
    projects: {
      totalPages: 1,
      totalRecords: "",
      currentPage: 1
    }
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [filterSearch, setFilterSearch] = useState({
    business_ids: "",
    label_ids: "",
    project_ids: "",
    search_string: "",
    priority: []
    // date_filter: []
  });
  const [isSearchPopover, setIsSearchPopover] = useState(false);
  const [isSearchFetching, setIsSearchFetching] = useState(false);
  const [isViewMoreLoader, setIsViewMoreLoader] = useState({
    tasks: false,
    workspaces: false,
    projects: false
  });
  const [isAlertAction, setIsAlertAction] = useState({ status: false, id: "" });
  const [isAlertActionLoader, setIsAlertActionLoader] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [priority, setPriority] = useState([]);
  const [filterDates, setFilterDates] = useState({
    today: false,
    Upcoming: false,
    overdue: false
  });
  const [filterprojects, setFilterProjects] = useState([]);
  const [filterLabels, setFilterLabels] = useState([]);

  useEffect(() => {
    // setFilteredProjects(props.projects.projects && props.projects.projects.sort((a, b) => a.updatedAt - b.updatedAt));
    return () => {};
  }, []);
  const handleCleanup = () => {
    setFilterSearch({
      business_ids: "",
      label_ids: "",
      project_ids: "",
      search_string: "",
      priority: []
      // date_filter: []
    });
    setSearchQuery("");
    setIsViewMoreLoader({
      tasks: false,
      workspaces: false,
      projects: false
    });
    setIsSearchPopover(false);
    setSearchResult({
      workspaces: [],
      tasks: [],
      projects: [],
      moreProject: false,
      moreTask: false,
      moreWorkspace: false
    });
    setSearchResultPages({
      tasks: {
        totalPages: 1,
        totalRecords: "",
        currentPage: 1
      },
      workspaces: {
        totalPages: 1,
        totalRecords: "",
        currentPage: 1
      },
      projects: {
        totalPages: 1,
        totalRecords: "",
        currentPage: 1
      }
    });
    setPriority([]);
    setWorkspaces([]);
    setFilterDates({
      today: false,
      Upcoming: false,
      overdue: false
    });
    setFilterProjects([]);
    setFilterLabels([]);
  };
  const doClearResultOnResetFilter = () => {
    setSearchResult({
      workspaces: [],
      tasks: [],
      projects: [],
      moreProject: false,
      moreTask: false,
      moreWorkspace: false
    });
    setIsFilter(false);
    setSearchResultPages({
      tasks: {
        totalPages: 1,
        totalRecords: "",
        currentPage: 1
      },
      workspaces: {
        totalPages: 1,
        totalRecords: "",
        currentPage: 1
      },
      projects: {
        totalPages: 1,
        totalRecords: "",
        currentPage: 1
      }
    });
    setPriority([]);
    setWorkspaces([]);
    setFilterDates({
      today: false,
      Upcoming: false,
      overdue: false
    });
    setFilterProjects([]);
    setFilterLabels([]);
    setFilterSearch({
      business_ids: "",
      label_ids: "",
      project_ids: "",
      search_string: "",
      priority: []
      // date_filter: []
    });
  };

  const delayedQuery = useCallback(
    _.debounce((q, f) => {
      const trimmedQuery = q.trim();
      if (trimmedQuery !== "" && trimmedQuery !== " " && trimmedQuery) {
        retrieveSearchQuery(trimmedQuery, f);
      }
    }, 800),
    []
  );

  const getSerchQueryLength = searchResult => {
    var count = 0;
    if (searchResult.length > 0) {
      searchResult.map(data => {
        if (data.task.length > 0) {
          count = count + data.task.length;
        }
      });
    }
    return count;
  };
  const retrieveSearchQuery = async (
    query = "",
    filterParams = filterSearch
  ) => {
    try {
      setIsSearchPopover(true);
      setIsSearchFetching(true);

      const Response = await retrieveGlobalSearch({
        ...filterParams,
        search_string: query
      });

      if (Response) {
        if (Response.data) {
          setIsSearchPopover(true);
          setSearchResult({
            workspaces:
              Response.data && Response.data?.length > 0
                ? Response.data.filter(data => data.type == "WORKSPACE")
                : [],
            tasks:
              Response.data && Response.data.length > 0
                ? Response.data.filter(data => data.type == "TASK")
                : [],
            projects:
              Response.data && Response.data?.length > 0
                ? Response.data.filter(data => data.type == "PROJECT")
                : [],

            moreProject: Response?.moreProject,
            moreTask: Response?.moreTask,
            moreWorkspace: Response?.moreWorkspace
          });
          setSearchResultPages({
            tasks: {
              totalPages: 1,
              totalRecords: "",
              currentPage: 1
            },
            workspaces: {
              totalPages: 1,
              totalRecords: "",
              currentPage: 1
            },
            projects: {
              totalPages: 1,
              totalRecords: "",
              currentPage: 1
            }
          });
          setIsSearchFetching(false);
        }
        if (Response.error) {
          setIsSearchPopover(false);
          props.showNotifications();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const retrieveTaskSearchQuery = async () => {
    try {
      if (true) {
        // setIsSearchPopover(true);
        // setIsSearchFetching(true);
        setIsViewMoreLoader({ ...isViewMoreLoader, tasks: true });
        const Response = await retrieveTaskGlobalSearch(
          searchQuery,
          null,
          pageLimit,
          searchResultPages?.tasks?.currentPage + 1 || 2
        );
        if (Response) {
          if (Response.data) {
            setIsSearchPopover(true);
            setSearchResultPages({
              tasks: {
                totalPages: parseInt(Response.totalPages),
                totalRecords: parseInt(Response.totalRecords),
                currentPage: parseInt(Response.currentPage)
              }
            });
            setSearchResult({
              ...searchResult,
              tasks: [...searchResult.tasks, ...Response.data],
              moreTask:
                parseInt(Response.currentPage) < parseInt(Response.totalPages)
                  ? true
                  : false
            });
            setIsSearchFetching(false);
          }
          if (Response.error) {
            setIsSearchPopover(false);
            props.showNotifications();
          }
          setIsViewMoreLoader({ ...isViewMoreLoader, tasks: false });
        }
      }
    } catch (err) {
      setIsViewMoreLoader({ ...isViewMoreLoader, tasks: false });
    }
  };
  const retrieveWorkspaceSearchQuery = async () => {
    try {
      // if (searchQuery !== "" && searchQuery !== " " && searchQuery) {
      if (true) {
        // setIsSearchPopover(true);
        // setIsSearchFetching(true);
        setIsViewMoreLoader({ ...isViewMoreLoader, workspaces: true });
        const Response = await retrieveProjectGlobalSearch(
          searchQuery,
          null,
          pageLimit,
          searchResultPages?.workspaces?.currentPage + 1 || 2
        );
        if (Response) {
          if (Response.data) {
            setIsSearchPopover(true);
            setSearchResultPages({
              ...searchResultPages,
              workspaces: {
                totalPages: parseInt(Response.totalPages),
                totalRecords: parseInt(Response.totalRecords),
                currentPage: parseInt(Response.currentPage)
              }
            });
            setSearchResult({
              ...searchResult,
              workspaces: [...searchResult.workspaces, ...Response.data],
              moreWorkspace:
                parseInt(Response.currentPage) < parseInt(Response.totalPages)
                  ? true
                  : false
            });
            setIsSearchFetching(false);
          }
          if (Response.error) {
            setIsSearchPopover(false);
            props.showNotifications();
          }
          setIsViewMoreLoader({ ...isViewMoreLoader, workspaces: false });
        }
      }
    } catch (err) {
      setIsViewMoreLoader({ ...isViewMoreLoader, workspaces: false });
    }
  };
  const handleRedirection = (item, givenId) => {
    if (item.type && item.type === textConstants.TASK) {
      if (item.projectId && item.taskId && item.businessId) {
        if (
          item.businessId == props.auth.user.businessId ||
          item.businessId == givenId
        ) {
          setIsSearchPopover(false);
          handleCleanup();
          return props.history.push(`/task-details/${item.taskId}`);
        } else {
          setIsSearchPopover(false);
          setIsAlertAction({ status: true, data: item });
        }
      }
      return false;
    }
    if (
      (item.type && item.type === textConstants.BUSINESS) ||
      item.type === textConstants.WORKSPACE
    ) {
      if (item.businessId) {
        if (
          item.businessId == props.auth.user.businessId ||
          item.businessId == givenId
        ) {
          setIsSearchPopover(false);
          handleCleanup();
          return props.history.push(`/business/${item.businessId}`);
        } else {
          setIsSearchPopover(false);
          setIsAlertAction({ status: true, data: item });
        }
      }
      return false;
    }
    if (item.type && item.type === textConstants.PROJECT) {
      if (item.projectId) {
        if (
          item.businessId == props.auth.user.businessId ||
          item.businessId == givenId
        ) {
          setIsSearchPopover(false);
          handleCleanup();
          return props.history.push(`/project-details/${item.projectId}`);
        } else {
          setIsSearchPopover(false);
          setIsAlertAction({ status: true, data: item });
        }
      }
      return false;
    }
  };
  const getTotalAppliedCount = () => {
    var prjectsIds = getIds([...filterprojects]).count;
    var labelsIds = getIds([...filterLabels]).count;
    var workspaceIds = getIds([...workspaces]).count;
    var priorityId = getPriorityIds([...priority]).count;
    let totalSelectedFilters =
      prjectsIds + labelsIds + workspaceIds + priorityId;
    return totalSelectedFilters;
  };
  const getIds = arr => {
    let ids = "";
    let count = 0;
    if (arr && arr.length > 0) {
      arr &&
        arr.map(data => {
          if (data && data.isSelected) {
            ids = ids && ids?.length > 0 ? ids + `,${data.id}` : `${data.id}`;
            count++;
          }
        });
    }
    return { ids: ids, count: count };
  };
  const getPriorityIds = arr => {
    let ids = [];
    let count = 0;
    if (arr && arr.length > 0) {
      arr &&
        arr.map(data => {
          if (data && data.isSelected) {
            count++;
            ids.push(`${data.name}`);
          }
        });
    }
    return { ids: ids, count: count };
  };
  const getDatetypes = arr => {
    let ids = [];
    if (filterDates.today) {
      ids.push("today");
    }
    if (filterDates.Upcoming) {
      ids.push("Upcoming");
    }
    if (filterDates.overdue) {
      ids.push("overdue");
    }

    return ids;
  };
  const updateProjectandLabel = async token => {
    fecthProject(token);
    fetchLabels(token);
  };
  const fetchLabels = async token => {
    try {
      const Response = await getLables(token);
      if (Response) {
        if (Response.data) {
          props.set_labels({ labels: Response.data });
          updateSidebarLabelToSidebar(Response.data);
        }
        if (Response.error) {
        }
      }
    } catch (e) {}
  };

  const fecthProject = async token => {
    const Response = await getProject(token);
    if (Response) {
      props.set_projects({ projects: Response.data });
      updateSidebarProjectToSidebar(Response.data);
    }
  };
  const updateToken = (token, businessName, businessMeta, id) => {
    props.update_token(token);
    props.default_business({
      id: id,
      businessName: businessName,
      businessMeta: businessMeta
    });
    fecthProject(token);
    fetchLabels(token);
    // updateUser(token);
    props.update_business({ businessId: id, businessName, businessMeta });
  };
  const selectBusiness = async id => {
    try {
      setIsAlertActionLoader(true);
      const Response = await swicthBusiness(id);
      if (Response) {
        if (Response.data) {
          await updateToken(
            Response.data.token,
            Response.data.business.title,
            Response.data.business?.businessMeta
              ? Response.data.business?.businessMeta
              : null,
            Response.data.business.id
          );
          handleRecentWorkspace(Response.data.business);
          handleRedirection(isAlertAction.data, Response.data.business.id);
          updateProjectandLabel(Response.data.token);
          setIsAlertAction({ data: "", status: false });
          props.update_recent_Project({
            project_id: ""
          });
        }
        if (Response.error) {
          props.showNotifications(Response.error.message, "error");
          setIsAlertAction({ id: "", status: false });
        }
        setIsAlertActionLoader(false);
      }
    } catch (e) {
      setIsAlertActionLoader(false);
    }
  };
  const getMessageType = item => {
    if (item.type === textConstants.TASK) {
      return (
        <>
          <IntlMessages id="notification.capital_this" />
          <IntlMessages id="notification.task" />
          <IntlMessages id="notification.switchworkspacetaskdetails" />
          <IntlMessages id="notification.task" />
          <IntlMessages id="notification.details_now" />
        </>
      );
    }
    if (item.type && item.type === textConstants.PROJECT) {
      return (
        <>
          <IntlMessages id="notification.capital_this" />
          <IntlMessages id="workspace.project" />
          <IntlMessages id="notification.switchworkspacetaskdetails" />
          <IntlMessages id="workspace.project" />
          <IntlMessages id="notification.details_now" />
        </>
      );
    }
    if (
      item.type &&
      (item.type === textConstants.BUSINESS ||
        item.type === textConstants.WORKSPACE)
    ) {
      return (
        <>
          <IntlMessages id="notification.switchtheworkspacetoseethe" />
          <IntlMessages id="notification.workspacedetailsnow." />
        </>
      );
    }
    if (item.type && item.type === textConstants.taskcomment) {
      return (
        <>
          <IntlMessages id="notification.capital_this" />
          <IntlMessages id="notification.task" />
          <IntlMessages id="notification.comment" />
          <IntlMessages id="notification.switchworkspacetaskdetails" />
          <IntlMessages id="notification.workspacedetailsnow." />
          <IntlMessages id="notification.task" />
          <IntlMessages id="notification.comment" />
          <IntlMessages id="notification.details_now" />
        </>
      );
    }
    if (item.type && item.type === textConstants.projectcomment) {
      return (
        <>
          <IntlMessages id="notification.capital_this" />
          <IntlMessages id="workspace.project" />
          <IntlMessages id="notification.comment" />
          <IntlMessages id="notification.switchworkspacetaskdetails" />
          <IntlMessages id="notification.workspacedetailsnow." />
          <IntlMessages id="workspace.project" />
          <IntlMessages id="notification.comment" />
          <IntlMessages id="notification.details_now" />
        </>
      );
    }
  };
  const retrieveProjectSearchQuery = async () => {
    try {
      // if (searchQuery !== "" && searchQuery !== " " && searchQuery) {
      if (true) {
        // setIsSearchPopover(true);
        // setIsSearchFetching(true);
        setIsViewMoreLoader({ ...isViewMoreLoader, projects: true });
        const Response = await retrieveProjectGlobalSearch(
          searchQuery,
          null,
          pageLimit,
          searchResultPages?.projects?.currentPage + 1 || 2
        );
        if (Response) {
          if (Response.data) {
            setIsSearchPopover(true);
            setSearchResultPages({
              ...searchResultPages,
              projects: {
                totalPages: parseInt(Response.totalPages),
                totalRecords: parseInt(Response.totalRecords),
                currentPage: parseInt(Response.currentPage)
              }
            });
            setSearchResult({
              ...searchResult,
              projects: [...searchResult.projects, ...Response.data],
              moreProject:
                parseInt(Response.currentPage) < parseInt(Response.totalPages)
                  ? true
                  : false
            });
            setIsSearchFetching(false);
          }
          if (Response.error) {
            setIsSearchPopover(false);
            props.showNotifications();
          }
          setIsViewMoreLoader({ ...isViewMoreLoader, projects: false });
        }
      }
    } catch (err) {
      setIsViewMoreLoader({ ...isViewMoreLoader, projects: false });
    }
  };

  const handleCloseAndClearSearchData = e => {
    if (e && e?.target?.id == "input-search") {
    }
    if (e && e?.target?.id !== "input-search") {
      handleCleanup();
    }
  };

  return (
    <div>
      <div className=" searchbox pl-10" id="searchbox">
        <div className="project-searchbar">
          <InputGroup>
            <InputGroupAddon
              style={{
                backgroundColor: "rgb(255 255 255 / 18%)",
                borderTopLeftRadius: "5px ",
                borderBottomLeftRadius: "5px ",
                margin: 0
              }}
              addonType="prepend"
              className="icon-search-input"
              size="sm"
            >
              <i
                className="fas fa-search"
                style={{ fontSize: 13, paddingLeft: 5 }}
              ></i>
              {/* <img src={search} style={{ marginLeft: "5px" }} /> */}
            </InputGroupAddon>
            <Input
              autoComplete="off"
              style={{
                backgroundColor: "rgb(255 255 255 / 18%)",
                height: 36,
                caretColor: "white"
              }}
              value={searchQuery || ""}
              id="input-search"
              placeholder={InjectPlaceHolderMessage(
                "workspace.search",
                props?.LanguageSwitcher?.language?.languageId
              )}
              // onFocus={() => setIsSearchPopover(true)}
              className=" button-search"
              onChange={e => {
                const query = e.target.value.trim();
                setSearchQuery(e.target.value);
                if (query !== "" && query !== " " && query) {
                  delayedQuery(e.target.value, filterSearch);
                  setIsSearchFetching(true);
                }
                // if last character
                if (searchQuery?.length > 0 && query.length <= 0) {
                  retrieveSearchQuery(query, filterSearch);
                }
              }}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  setSearchQuery(e.target.value);
                  const query = e.target.value.trim();
                  if (query !== "" && query !== " " && query) {
                    retrieveSearchQuery(query, filterSearch);
                  }
                }
              }}
            />
          </InputGroup>

          <SearchPopover
            doClearResultOnResetFilter={() => {
              doClearResultOnResetFilter();
              const query = searchQuery.trim();
              if (query !== "" && query !== " " && query) {
                retrieveSearchQuery(query, filterSearch);
              }
            }}
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            doSearch={filter => retrieveSearchQuery(searchQuery, filter)}
            filterSearch={filterSearch}
            setFilterSearch={setFilterSearch}
            projects={props.projects ? props.projects.projects : []}
            currentWorkspace={props.currentWorkspace}
            closeSearchPopover={handleCloseAndClearSearchData}
            viewMoreTasksLoader={isViewMoreLoader.tasks || false}
            viewMoreWorkspacesLoader={isViewMoreLoader.workspaces || false}
            viewMoreProjectsLoader={isViewMoreLoader.projects || false}
            viewMoreTasks={retrieveTaskSearchQuery}
            viewMoreWorkspaces={retrieveWorkspaceSearchQuery}
            viewMoreProjects={retrieveProjectSearchQuery}
            isSearchPopover={isSearchPopover}
            isFetching={isSearchFetching}
            searchResult={searchResult}
            searchQuery={searchQuery}
            setIsSearchPopover={() => setIsSearchPopover(!isSearchPopover)}
            getSerchQueryLength={data => getSerchQueryLength(data)}
            history={props.history}
            handleRedirection={handleRedirection}
            workspaces={workspaces}
            setWorkspaces={setWorkspaces}
            priority={priority}
            setPriority={setPriority}
            filterDates={filterDates}
            setFilterDates={setFilterDates}
            filterprojects={filterprojects}
            setFilterProjects={setFilterProjects}
            filterLabels={filterLabels}
            setFilterLabels={setFilterLabels}
            getTotalAppliedCount={getTotalAppliedCount}
            getIds={getIds}
            getPriorityIds={getPriorityIds}
            getDatetypes={getDatetypes}
            {...props}
          />
          <ChangeWorkspaceAlert
            loader={isAlertActionLoader}
            action="Switch Workspace"
            message={isAlertAction.status && getMessageType(isAlertAction.data)}
            PRIMARY_COLOR={props.topbarTheme.buttonColor}
            show={isAlertAction.data && isAlertAction.status}
            onConfirm={() => {
              isAlertAction.status &&
                selectBusiness(isAlertAction.data.businessId);
            }}
            onCancel={() => {
              setIsSearchPopover(true);
              setIsAlertAction({ id: "", status: false });
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {
  update_Project,
  update_token,
  update_business,
  default_business,
  set_labels,
  update_Label,
  set_projects,
  update_recent_Project
})(GlobalSearch);
