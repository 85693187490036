export const EDIT_ACTION_CONSTANT = {
  prirority: "PRIORITY",
  schedule: "SCHEDULE",
  repeat: "REPEAT",
  checklist: "CHECKLIST",
  labels: "LABEL",
  archiveTask: "ARCHIVE_TASK",
  deleteTask: "DELETE_TASK",
  assign: "ASSIGN",
  attachment: "ATTACHMENT",
  share: "SHARE",
  title: "TITLE",
  description: "DESCRIPTION",
  activity: "ACTIVITY"
};
