import React from "react";
import Wrapper from "./themeselct.style";
import { checked } from "helper/constant";
import IntlMessages from "util/intlMessages";
function ThemeSelect({ color, themeName, selected, changeTheme }) {
  return (
    <Wrapper>
      <div>
        <div
          style={{
            padding: "0px 0px 10px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer"
          }}
        >
          <div
            onClick={() => changeTheme(themeName.toLowerCase())}
            style={{
              backgroundColor: "white",
              height: 66,
              width: 66,
              border: "1px solid #EBEBEB",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: " 0px 0px 8px #00000014"
            }}
          >
            <div
              style={{
                backgroundColor: color,
                height: 54,
                width: 54,
                border: "1px solid #EBEBEB",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {selected && (
                  <img
                    src={checked}
                    style={{
                      height: 17,
                      width: 17
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "600",
            fontSize: 13,
            color: "black",
            textAlign: "center"
            // maxWidth:"90px"
          }}
        >
          {/* {themeName} */}
          {<IntlMessages id={`setting.themes.${themeName}`} />}
        </div>
      </div>
    </Wrapper>
  );
}

export default ThemeSelect;

// <div
// className="ptb-10 container"
// onClick={() => changeTheme(themeName.toLowerCase())}
// >
// <div className="roe-card-style ">
//   <div
//     style={{
//       borderRadius: 5
//     }}
//   >
//     {/* <div className="row"> */}
//     <div
//       // className="col-lg-12 col-md-12 col-sm-12 col-xs-12 xl-12"
//       style={{
//         background: color,
//         height: 30,
//         color: "white",
//         borderRadius: 2
//       }}
//     >
//       <div className="flex-x">
//         <span
//           className="fs-14 flex-1"
//           style={{
//             paddingTop: 5,
//             paddingLeft: 25,
//             // fontWeight: 900,
//             // color: themeName == "light" ? "black" : "white",
//             color: "white",
//             textTransform: "capitalize"
//           }}
//         >
//           {themeName}
//         </span>
//         <span
//           style={{
//             paddingRight: 20,
//             paddingTop: 3
//           }}
//         >
//           {selected && (
//             <img
//               src={checked}
//               style={{
//                 height: 12,
//                 width: 12,
//                 marginRight: "-10px",
//                 marginTop: "7px",
//                 marginBottom: "8px"
//               }}
//             />
//           )}
//         </span>
//       </div>
//     </div>

//     <div className="roe-card-style pt-10">
//       <div className="roe-card-body" style={{ height: "10vh" }}>
//         <div
//           style={{
//             height: "10px",

//             background: "#e5e3e3",
//             borderRadius: 3,
//             paddingTop: 5,
//             paddingBottom: 5
//           }}
//         ></div>
//         <div
//           style={{
//             height: "1vh"
//           }}
//         ></div>
//         <div
//           style={{
//             height: "10px",

//             background: "#e5e3e3",
//             borderRadius: 3,
//             paddingTop: 5,
//             paddingBottom: 5
//           }}
//         ></div>
//         <div
//           style={{
//             height: "1vh"
//           }}
//         ></div>
//         <div
//           style={{
//             height: "10px",

//             background: "#e5e3e3",
//             borderRadius: 3,
//             paddingTop: 5,
//             paddingBottom: 5
//           }}
//         ></div>
//         <div
//           style={{
//             height: "1vh"
//           }}
//         ></div>
//         <div
//           style={{
//             height: "10px",
//             width: 30,
//             background: color,
//             borderRadius: 3,
//             paddingTop: 5,
//             paddingBottom: 5
//           }}
//         ></div>
//         {/* </div> */}
//       </div>
//     </div>
//     {/* <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 xl-1"></div> */}
//     {/* </div> */}
//   </div>
// </div>
// </div>
