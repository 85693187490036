import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import { retrivePublicTask } from "helper/services/publicShareLinkServices";
const ShareLink = props => {
  const INVALID = "This link is broken. Please generate new one.";
  const [isLoading, setIsLoading] = useState(false);
  const [documentHtml, setDocumentHtml] = useState("");
  const [isError, setError] = useState(false);
  const reqTaskTokenparams = new URLSearchParams(
    props.history.location?.search
  );
  const reqtaskToken = reqTaskTokenparams.get("token");
  console.log(reqtaskToken);
  useEffect(() => {
    if (reqtaskToken) {
      fetchDocuments(reqtaskToken);
    }
  }, []);
  const fetchDocuments = async token => {
    if (token) {
      try {
        setIsLoading(true);
        const Response = await retrivePublicTask(token);

        if (Response) {
          setIsLoading(false);
          setDocumentHtml(Response);
        } else {
          setIsLoading(false);
          setError(true);
        }
      } catch (e) {
        setIsLoading(false);
      }
    }
  };
  const ErrorHtml = `<section class="vh-100" style="background-color: #F6F2EF;">
        <div class="container py-5 h-100">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col col-lg-8 col-xl-8">
                    <div class="card rounded-3">
                        <div class="card-body p-4">
                            <p class="mb-2">
                                <span class="h4 me-2">${INVALID}</span>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>`;
  var stringToHTML = function(str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, "text/html");
    return doc.body.innerHTML;
  };
  return (
    <>
      {isLoading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 50
            }}
          >
            <Loader height="50px" width="50px" />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 50,
              fontSize: 18
            }}
          >
            Fetching data...
          </div>
        </>
      ) : (
        <div>
          <div
            id="html-content"
            dangerouslySetInnerHTML={{
              __html: documentHtml ? stringToHTML(documentHtml) : ""
            }}
          ></div>
        </div>
      )}
      {isError && (
        <div
          dangerouslySetInnerHTML={{
            __html: ErrorHtml
          }}
        ></div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};
export default connect(mapStateToProps, null)(ShareLink);
