import React, { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { getColorCode } from "./utils/color";
const ColorDropdown = ({ options, changeColor, value }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedColorCode, setSelectedColorCode] = useState(options[0].color);
  const changeSelectedColor = (value, code) => {
    setSelectedColorCode(code);
    changeColor(value);
  };
  return (
    <ButtonDropdown
      style={{ width: "100%" }}
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
    >
      <DropdownToggle
        // caret
        style={{
          backgroundColor: "transparent",
          width: "100%",
          textTransform: "capitalize",
          border: "1px solid #E6E6E6",
          borderRadius: 5,
          textAlign: "left",
          fontSize: 14,
          color: "#495057",
          padding: 0,
          paddingLeft: 20,
          zIndex: 0
        }}
        className={"form-control"}
      >
        <i
          className="fa fa-circle fs-10"
          style={{
            paddingRight: 5,
            color: getColorCode(value)
          }}
        ></i>{" "}
        {value}
      </DropdownToggle>
      <DropdownMenu>
        <div>
          lets test this
          {options &&
            options.map((color, i) => {
              return (
                <DropdownItem
                  key={i * 1454}
                  onClick={() => changeSelectedColor(color.value, color.color)}
                >
                  <div style={{ width: 200, textTransform: "capitalize" }}>
                    <i
                      className="fa fa-circle fs-10"
                      style={{
                        paddingRight: 20,
                        color: color.color
                      }}
                    ></i>
                    {color.label}
                  </div>
                </DropdownItem>
              );
            })}
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default ColorDropdown;
