import { allPriority } from "constatnt";
import React from "react";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import IconTint from "react-icon-tint";
import { singleCheckIcon, NewTaskPriorityCross } from "helper/constant";
import IntlMessages from "util/intlMessages";
const priorityPopover = ({
  isOpen,
  toggle,
  target,
  placement,
  selectedPriority,
  updatePriority,
  PRIMARY_COLOR
}) => {
  // PRIMARY_COLOR = { props.topbarTheme.buttonColor }
  return (
    <div>
      {" "}
      <UncontrolledPopover
        isOpen={isOpen}
        trigger="legacy"
        className="roy-menu"
        hideArrow={true}
        innerClassName="roy-inner-content"
        placement={placement || "bottom-start"}
        target={target}
        toggle={toggle}
      >
        <PopoverBody style={{ minWidth: "220px" }}>
          <div className="float-right" onClick={toggle}>
            <img
              src={NewTaskPriorityCross}
              style={{
                height: "10px",
                width: "10px",
                marginRight: "5px",
                marginTop: "6px"
              }}
            ></img>
          </div>
          <div
            className="fs-14 text-center border-bottom"
            style={{
              padding: "4px 4px 10px 4px",
              color: "#808080",
              borderBottom: "1px solid #ebebeb"
            }}
          >
            <IntlMessages id={"globalsearchbar.priority"} />
          </div>

          {allPriority &&
            selectedPriority &&
            allPriority.map((priority, i) => {
              return (
                <div
                  className="roy-menu-list"
                  key={priority.value}
                  onClick={() => {
                    updatePriority(priority.name);
                    toggle();
                  }}
                >
                  <div
                    className="label-list"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <div>
                        <img
                          src={priority.path}
                          height="20"
                          width="16"
                          className=""
                          alt={priority.name}
                          style={{
                            cursor: "pointer",
                            color: priority.color
                          }}
                        />
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        {" "}
                        {<IntlMessages id={priority.value} />}{" "}
                      </div>
                    </div>
                    {priority.name == selectedPriority && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <IconTint
                          maxHeight="15"
                          maxWidth="15"
                          src={singleCheckIcon}
                          color={PRIMARY_COLOR}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

export default priorityPopover;
