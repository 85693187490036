import React from "react";
export const AddedUser = ({ activity, type }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
      <div
        style={{
          fontWeight: "500",
          fontSize: 14,
          wordBreak: "break-word",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          lineClamp: 4,
          textOverflow: "ellipsis",
          overflow: "hidden",
          WebkitLineClamp: 2,
          WebkitFontSmoothing: "antialiased"
        }}
      >
        {activity?.actor && activity?.actor?.name}{" "}
        <b>
          {/* changed who can access{" "} */}
          joined{" "}
          {type == "business"
            ? activity.business.title
            : activity?.project?.name}
        </b>
        {type == "business" ? " workspace" : " project"}
      </div>
      {/* <div
        style={{
          fontSize: 13,
          wordBreak: "break-word",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          lineClamp: 2,
          textOverflow: "ellipsis",
          overflow: "hidden",
          WebkitLineClamp: 5,
          WebkitFontSmoothing: "antialiased"
        }}
      >
        {activity.actor && activity.actor.name} was granted to access{" "}
        {type == "business" ? activity.business.title : activity.project.name}{" "}
        {type == "business" ? " workspace" : " project"}.
      </div> */}
    </div>
  );
};
