import styled from "styled-components";

const NewTaskDescriptionWrapper = styled.div`
  .description-input::placeholder {
    color: #808080 !important;
    font-size: 14px !important;
    // font-weight:600 !important;
  }
  .description-input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: black !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #879eb4;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: ${props =>
      props.backgroundColor ? props.backgroundColor : "#f4f5f7 !important"};
    border-radius: 5px !important;
    // border:1px solid #e6e6e6 !important;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 13px;
    font-weight: normal;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 16px;
    font-weight: normal;
  }
`;

export default NewTaskDescriptionWrapper;
