/** @format */

import Shimmer from "components/shimmer/Shimmer";
import {
  getMyAssignments,
  getMyOverdueAssignments
} from "helper/services/globalmyassignment";
import React, { useEffect, useState } from "react";

import ProjecttList from "./ProjecttList";
import TaskDataNotFound from "./TaskDataNotFound";
import MyCompletedAssignments from "./MyCompletedAssignments";
import OverDueTaskList from "./OverDueTaskList";
import {
  getIndexOf,
  getKeyIndexOf,
  groupDataToTime
} from "helper/methods/utilFunctions";
import { doGroupByproject } from "helper/methods/utilFunctions";
import moment from "moment";
import LinearLoader from "components/loader/LinearLoader";

const MyAssignments = props => {
  const {
    myAssignmentsData,
    setMyAssignmentsData,
    handleRedirection,
    showNotifications
  } = props;
  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    totalPages: 0,
    isLastPage: false
  });
  const [isLoader, setIsLoader] = useState(false);
  const [isNextPageLoader, setIsNextPageLoader] = useState(false);
  const [overduePageInfo, setOverduePageInfo] = useState({
    currentPage: 0,
    totalPages: 0,
    isLastPage: false
  });
  const [isOverdueNextPageLoader, setIsOverdueNextPageLoader] = useState(false);
  const [isCompletedVisible, setIsCompletedVisible] = useState(true);
  const [overdueTaskGroupDataTotime, setOverdueTaskGroupDataTotime] = useState(
    {}
  );
  useEffect(() => {
    fetchMyAssignments();
    fetchOverdueAssignments();
    return () => {
      setPageInfo({
        currentPage: 0,
        totalPages: 0
      });
      setIsNextPageLoader(false);
      setIsLoader(false);
    };
  }, []);

  const fetchMyAssignments = async (isNextPage = false) => {
    try {
      !isNextPage && setIsLoader(true);
      let nextPage = pageInfo.currentPage + 1;
      isNextPage && setIsNextPageLoader(true);
      const { data, error } = await getMyAssignments(10, nextPage);
      if (data) {
        setPageInfo({
          currentPage: parseInt(data.currentPage),
          totalPages: data.totalPages,
          isLastPage: data.isLastPage
        });
        if (data.task?.length > 0) {
          let groupData = doGroupByproject(data.task);
          let arrayData = await doGroupByproject(groupData);

          if (arrayData) {
            if (isNextPage) {
              // const updatedData = getDistinctDateArrayData(
              //   myAssignmentsData,
              //   groupData
              // );

              setMyAssignmentsData([...myAssignmentsData, ...arrayData]);
            } else {
              setMyAssignmentsData([...arrayData]);
            }
          }
        }
        setIsLoader(false);
      } else {
        setMyAssignmentsData([]);
      }
      if (error) {
        showNotifications();
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoader(false);
      setIsNextPageLoader(false);
    }
  };
  const fetchOverdueAssignments = async (isNextPage = false) => {
    try {
      !isNextPage && setIsLoader(true);
      let nextPage = overduePageInfo.currentPage + 1;
      isNextPage && setIsOverdueNextPageLoader(true);

      const { data, error } = await getMyOverdueAssignments(
        moment(new Date()).format('"YYYY-MM-DD"'),
        10,
        nextPage
      );
      if (data) {
        setOverduePageInfo({
          currentPage: parseInt(data.currentPage),
          totalPages: data.totalPages,
          isLastPage: data.isLastPage
        });
        if (data.task?.length > 0) {
          let overdueTaskGroupDataTotimeArr = groupDataToTime(
            data.task,
            "data"
          );

          if (overdueTaskGroupDataTotimeArr.status) {
            let updatedFormatTaskData = [...overdueTaskGroupDataTotimeArr.data];
            overdueTaskGroupDataTotimeArr.data &&
              overdueTaskGroupDataTotimeArr.data.map((mapData, index) => {
                if (mapData.data) {
                  return (updatedFormatTaskData[index].data = doGroupByproject(
                    mapData.data
                  ));
                }
              });
            if (isNextPage) {
              // const result=await getDistinctDateArrayData([...overdueTaskGroupDataTotime,...overdueTaskGroupDataTotimeArr.data])
              setOverdueTaskGroupDataTotime([
                ...overdueTaskGroupDataTotime,
                ...overdueTaskGroupDataTotimeArr.data
              ]);
            } else {
              setOverdueTaskGroupDataTotime(overdueTaskGroupDataTotimeArr.data);
            }
          }
        } else {
          setOverdueTaskGroupDataTotime([]);
        }
        !isNextPage && setIsLoader(false);
      }
      if (error) {
        showNotifications();
        if (isNextPage) {
          setOverduePageInfo({ ...pageInfo, isLastPage: true });
          isNextPage && setIsOverdueNextPageLoader(false);
        }
      }
    } catch (error) {
      if (isNextPage) {
        setOverduePageInfo({ ...pageInfo, isLastPage: true });
        isNextPage && setIsOverdueNextPageLoader(false);
      }

      // console.log(error);
    } finally {
      setIsLoader(false);
      setIsNextPageLoader(false);
    }
  };

  const getDistinctDateArrayData = (currentAssignment, newAssignment) => {
    //  mergin data for elements which have  same type of date but are more than one array elemnt to one
    let currentAssignmentData = { ...currentAssignment };
    if (newAssignment && currentAssignment) {
      Object.keys(newAssignment).map(function(objectKey, index) {
        if (currentAssignment[objectKey]) {
          if (
            currentAssignment[objectKey].project.id ==
            newAssignment[objectKey].project.id
          ) {
            return (currentAssignmentData[objectKey].task = [
              ...currentAssignment[objectKey].task,
              ...newAssignment[objectKey].task
            ]);
          } else {
            return (currentAssignmentData[objectKey] =
              newAssignment[objectKey]);
          }
        } else {
          return (currentAssignmentData[objectKey] = newAssignment[objectKey]);
        }
      });
      return currentAssignmentData;
    }
  };

  const doUpdateTaskStatus = (taskId, status, objectKey, givenIndex) => {
    try {
      let assignmentDataarr = [...myAssignmentsData];
      let isStatusUpdated = false;
      var index = getKeyIndexOf(
        myAssignmentsData,
        "project",
        objectKey.project.id
      );

      if (index !== -1) {
        let taskIndex = getIndexOf(assignmentDataarr[index].task, taskId);
        if (taskIndex !== -1) {
          assignmentDataarr[index].task[
            taskIndex
          ].isCompleted = !assignmentDataarr[index].task[taskIndex].isCompleted;

          isStatusUpdated = true;
        }
      }
      if (isStatusUpdated) {
        setMyAssignmentsData([...assignmentDataarr]);
      }
      return 0;
    } catch (err) {
      // console.log(err)
    }
  };
  const doToggleOverdueTaskStatus = (id, status, index, objectKey) => {
    if (id) {
      let isStatusUpdated = false;
      // {overdueTasks[index].data[objectKey]}
      let overdueTasksArr = { ...overdueTaskGroupDataTotime };
      if (overdueTasksArr[index].data[objectKey].task) {
        overdueTasksArr[index].data[objectKey].task &&
          overdueTasksArr[index].data[objectKey].task.map((data, i) => {
            if (data.id === id) {
              overdueTasksArr[index].data[objectKey].task[
                i
              ].isCompleted = !overdueTasksArr[index].data[objectKey].task[i]
                .isCompleted;
              isStatusUpdated(true);
            }
          });
      }
      if (isStatusUpdated) {
        setOverdueTaskGroupDataTotime(overdueTasksArr);
      }
    }
  };
  return (
    <>
      {isLoader ? (
        <>
          <Shimmer />
          <Shimmer />
        </>
      ) : (
        <>
          {isCompletedVisible ||
          (myAssignmentsData && myAssignmentsData?.length > 0) ||
          (overdueTaskGroupDataTotime &&
            overdueTaskGroupDataTotime?.length > 0) ? (
            <>
              {overdueTaskGroupDataTotime &&
                overdueTaskGroupDataTotime.length > 0 && (
                  <>
                    <OverDueTaskList
                      overduePageInfo={overduePageInfo}
                      doViewMoreOverdue={() => fetchOverdueAssignments(true)}
                      isOverdueNextPageLoader={isOverdueNextPageLoader}
                      doToggleOverdueTaskStatus={doToggleOverdueTaskStatus}
                      handleRedirection={handleRedirection}
                      overdueTasks={overdueTaskGroupDataTotime}
                      {...props}
                    />
                  </>
                )}

              {myAssignmentsData && myAssignmentsData?.length > 0 && (
                <>
                  {myAssignmentsData &&
                    myAssignmentsData.map((element, index) => {
                      return (
                        <ProjecttList
                          handleRedirection={handleRedirection}
                          project={element.project}
                          task={element.task}
                          index={index}
                          toggleTaskStatus={(id, status) => {
                            doUpdateTaskStatus(id, status, element, index);
                          }}
                        />
                      );
                    })}
                  {!pageInfo.isLastPage && (
                    <div
                      style={{
                        color: props.PRIMARY_COLOR,
                        fontSize: 14,
                        textDecoration: isNextPageLoader ? "none" : "underline",
                        textAlign: "center",
                        cursor: isNextPageLoader ? "not-allowed" : "pointer",
                        paddingBottom: 10
                      }}
                    >
                      {isNextPageLoader ? (
                        <LinearLoader />
                      ) : (
                        <div
                          style={{
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "2.09rem"
                          }}
                          onClick={() =>
                            !pageInfo.isLastPage ? fetchMyAssignments(true) : {}
                          }
                        >
                          View more
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
              <MyCompletedAssignments
                isMyAssignments={true}
                isVisibility={isCompletedVisible}
                updateIsVisibility={setIsCompletedVisible}
                handleRedirection={handleRedirection}
                {...props}
              />
            </>
          ) : (
            <>
              <TaskDataNotFound isSelf={true} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default MyAssignments;
