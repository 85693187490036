import styled from "styled-components";

const UserProfileWrapper = styled.div`
  .form-control:disabled,
  .form-control[readonly] {
    background-color: white !important;
  }
  .form-control {
    color: grey !important;
  }
  .btn: hover {
    color: white !important;
  }
`;

export default UserProfileWrapper;
