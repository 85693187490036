import React, { useEffect, useState } from "react";
import { Progress } from "react-sweet-progress";
import AddChecklistItem from "./AddChecklistItem";
import CheckListItem from "./CheckListItem";
import { connect } from "react-redux";
import AddCheckList from "./AddCheckList";
import Shimmer from "components/shimmer/Shimmer";
import TaskCheckListInput from "./TaskCheckListInput";
const TaskChecklistCard = props => {
  const {
    subtaskList,
    addNewSubtask,
    updateChecklistItem,
    updateIsCompletedSubTask,
    openCustomAlert,
    addnewCheckList,
    isAddChecklist,
    setIsAddChecklist,
    topbarTheme,
    isLoaderChecklist,
    showNotifications,
    doUpdateChecklistTitle
  } = props;
  useEffect(() => {
    return () => {};
  }, []);
  const handleResponse = Response => {
    if (Response) {
      if (Response.status.toLowerCase() === "ok") {
      } else {
        showNotifications();
      }
    }
  };

  return (
    <>
      {isLoaderChecklist ? (
        <Shimmer />
      ) : (
        <>
          {isAddChecklist && (
            <div
              className="row"
              style={{ borderTop: "1px solid #EBEBEB", margin: 0 }}
            >
              <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
              <div
                className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"
                style={{
                  padding: "20px 0px"
                }}
              >
                <AddCheckList
                  showNotifications={showNotifications}
                  addNewChecklist={addnewCheckList}
                  isAddChecklist={isAddChecklist}
                  setIsAddChecklist={setIsAddChecklist}
                  props={props}
                />
              </div>
              <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
            </div>
          )}
          {subtaskList &&
            subtaskList?.length > 0 &&
            subtaskList
              .sort((a, b) => b.id - a.id)
              .map(checklist => {
                return (
                  <div className="" style={{ margin: "10px 0" }}>
                    <div className="row" style={{ margin: 0 }}>
                      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
                      <div
                        className="col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8"
                        style={{ borderTop: "1px solid #EBEBEB" }}
                      >
                        <TaskCheckListInput
                          checklist={checklist}
                          PRIMARY_COLOR={topbarTheme.buttonColor}
                          updateChecklistTitle={doUpdateChecklistTitle}
                        />
                      </div>
                      <div
                        className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2 "
                        style={{
                          padding: "15px",
                          borderTop: "1px solid #EBEBEB"
                        }}
                      >
                        <div
                          title={`Delete ${checklist.title}`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            fontWeight: "600",
                            cursor: "pointer"
                          }}
                          onClick={e =>
                            openCustomAlert(
                              true,
                              "delete",
                              "checklist",
                              checklist.id,
                              checklist.title
                            )
                          }
                        >
                          Delete
                        </div>
                      </div>
                      <div
                        className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"
                        style={{ borderTop: "1px solid #EBEBEB" }}
                      ></div>
                    </div>

                    <div className="row">
                      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
                      <div
                        className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"
                        style={{ padding: "0px 10px 0px 0px" }}
                      >
                        <Progress
                          percent={
                            checklist &&
                            checklist.subTask &&
                            checklist?.subTask.length > 0
                              ? (
                                  (checklist.subTask.filter(s => s.isCompleted)
                                    .length /
                                    checklist.subTask.length) *
                                  100
                                ).toFixed(0)
                              : 0
                          }
                          theme={{
                            active: {
                              color: topbarTheme.buttonColor
                            }
                          }}
                        />
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
                    </div>

                    {checklist &&
                      checklist.subTask?.length > 0 &&
                      checklist.subTask
                        .sort((a, b) => a.id - b.id)
                        .map(Item => {
                          return (
                            <>
                              <CheckListItem
                                itemData={Item}
                                updateChecklistItem={title =>
                                  updateChecklistItem(
                                    title,
                                    Item.id,
                                    checklist.id,
                                    handleResponse
                                  )
                                }
                                updateIsCompletedSubTask={() =>
                                  updateIsCompletedSubTask(
                                    Item.id,
                                    Item.isCompleted,
                                    checklist.id,
                                    handleResponse
                                  )
                                }
                                openCustomAlert={() =>
                                  openCustomAlert(
                                    true,
                                    "delete",
                                    "checklist item",
                                    {
                                      subtaskId: Item.id,
                                      checklistId: checklist.id
                                    },
                                    Item.title
                                  )
                                }
                                handleOpenSubtask={id =>
                                  props.history.push(`/task-details/${id}`)
                                }
                              />
                              <div>
                                {/*  <div className="row">
                            <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
                            <div
                              className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"
                              style={{
                                padding: "0px 10px 0px 0px",
                                display: "flex",
                                alignItems: "center"
                              }}
                              onMouseOver={e => {
                                setStyle({ display: "block" });
                              }}
                              onMouseOut={e => {
                                setStyle({ display: "none" });
                              }}
                            >
                              <div
                                className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"
                                style={{
                                  padding: "0px",
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "flex-end",
                                  height: 24,
                                  paddingRight: "0px"
                                }}
                              >
                                <div>
                                  <img
                                    src={TaskChecklistCardItemImage}
                                    style={{
                                      height: 20,
                                      width: 20,
                                      marginRight: 25
                                    }}
                                  ></img>
                                </div>

                                <div
                                  onClick={() => updateIsCompletedSubTask(checkListItem.id, checkListItem.isCompleted, checklist.id)}
                                > <img
                                    src={checkListItem.isCompleted ? checkedImage : unCheckedImage}
                                    style={{
                                      height: 20,
                                      width: 20,
                                      cursor: "pointer"
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10"
                                style={{
                                  padding: "0px 30px",
                                  display: "flex",
                                  alignItems: "flex-end",
                                  justifyContent: "flex-start",
                                  margin: "10px 0px"
                                }}
                              >
                                <div style={{ margin: "0px 15px 0px 0px " }}>
                                  {" "}
                                  {checkListItem.title}
                                </div>
                                <div style={style}>
                                  <img
                                    src={TaskChecklistCardEditPencilImage}
                                    style={{
                                      height: 17,
                                      width: 17,
                                      cursor: "pointer"
                                    }}
                                  ></img>{" "}
                                </div>
                              </div>
                              <div
                                className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  padding: 0
                                }}
                              >
                                <div style={style}
                                  onClick={() => openCustomAlert(true, 'delete', 'checklist item',
                                    {
                                      subtaskId: checkListItem.id,
                                      checklistId: checklist.id
                                    }
                                    , checklist.title)}
                                >
                                  <img
                                    src={deleteItemImage}
                                    style={{
                                      height: 18,
                                      width: 18,
                                      cursor: "pointer"
                                    }}
                                  ></img>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
                          </div> */}
                              </div>
                            </>
                          );
                        })}
                    {/* jjj */}
                    <AddChecklistItem
                      // isItemAddInputOpen={isItemAddInputOpen}
                      // setIsItemAddInputOpen={setIsItemAddInputOpen}
                      addNewSubtask={title =>
                        addNewSubtask(checklist.id, title)
                      }
                    />
                  </div>
                );
              })}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {})(TaskChecklistCard);
