/** @format */

import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  getCreatedByMeAssignment,
  getCreatedByMeOverdueAssignment
} from "helper/services/globalmyassignment";
import OverDueTaskList from "./OverDueTaskList";
import TaskDataNotFound from "./TaskDataNotFound";
import Shimmer from "components/shimmer/Shimmer";
import ProjecttList from "./ProjecttList";
import MyCompletedAssignments from "./MyCompletedAssignments";
import {
  doGroupByproject,
  getDistinctArrayDataProject,
  getIndexOf,
  getKeyIndexOf,
  objectToArray
} from "helper/methods/utilFunctions";
import LinearLoader from "components/loader/LinearLoader";

const StuffAssignments = props => {
  const {
    handleRedirection,
    showNotifications,
    overdueTaskGroupDataTotime,
    setOverdueTaskGroupDataTotime
  } = props;
  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    totalPages: 0
  });
  const [overduePageInfo, setOverduePageInfo] = useState({
    currentPage: 0,
    totalPages: 0
  });
  const [myAssignmentsData, setMyAssignmentsData] = useState({});

  const [isLoader, setIsLoader] = useState(false);
  const [isCompletedVisible, setIsCompletedVisible] = useState(true);
  const [isOverdueLoader, setIsOverdueLoader] = useState(false);

  const [isOverdueNextPageLoader, setIsOverdueNextPageLoader] = useState(false);
  const [isNextPageLoader, setIsNextPageLoader] = useState(false);

  useEffect(() => {
    fetchAssignedByMe();
    fetchOverdueAssignments();
  }, []);

  const fetchAssignedByMe = async (isNextPage = false) => {
    try {
      !isNextPage && setIsLoader(true);
      let nextPage = pageInfo.currentPage + 1;
      isNextPage && setIsNextPageLoader(true);
      const { data, error } = await getCreatedByMeAssignment(
        10,
        nextPage,
        false
      );
      if (data) {
        setPageInfo({
          currentPage: parseInt(data.currentPage),
          totalPages: data.totalPages,
          isLastPage: data.isLastPage
        });
        if (data.task?.length > 0) {
          let groupData = doGroupByproject(data.task);
          let arrayData = await objectToArray(groupData);
          if (arrayData) {
            if (isNextPage) {
              setMyAssignmentsData([...myAssignmentsData, ...arrayData]);
            } else {
              setMyAssignmentsData([...arrayData]);
            }
          }
        }
        setIsLoader(false);
      } else {
        setMyAssignmentsData([]);
      }
      if (error) {
        showNotifications();
        if (isNextPage) {
          setPageInfo({ ...pageInfo, isLastPage: true });
          isNextPage && setIsNextPageLoader(false);
        }
      }
    } catch (error) {
      if (isNextPage) {
        setPageInfo({ ...pageInfo, isLastPage: true });
        setIsLoader(false);
        isNextPage && setIsNextPageLoader(false);
      }

      // console.log(error);
    } finally {
      setIsLoader(false);
      setIsNextPageLoader(false);
    }
  };
  const fetchOverdueAssignments = async (isNextPage = false) => {
    try {
      !isNextPage && setIsLoader(true);
      let nextPage = overduePageInfo.currentPage + 1;
      isNextPage && setIsOverdueNextPageLoader(true);

      const { data, error } = await getCreatedByMeOverdueAssignment(
        moment(new Date()).format('"YYYY-MM-DD"'),
        10,
        nextPage
      );
      if (data) {
        setOverduePageInfo({
          currentPage: parseInt(data.currentPage),
          totalPages: data.totalPages,
          isLastPage: data.isLastPage
        });
        if (data.task?.length > 0) {
          let overdueTaskGroupDataTotimeArr = groupDataToTime(
            data.task,
            "data"
          );

          if (overdueTaskGroupDataTotimeArr.status) {
            let updatedFormatTaskData = [...overdueTaskGroupDataTotimeArr.data];
            overdueTaskGroupDataTotimeArr.data &&
              overdueTaskGroupDataTotimeArr.data.map((mapData, index) => {
                if (mapData.data) {
                  return (updatedFormatTaskData[index].data = doGroupByproject(
                    mapData.data
                  ));
                }
              });
            if (isNextPage) {
              // const result=await getDistinctDateArrayData([...overdueTaskGroupDataTotime,...overdueTaskGroupDataTotimeArr.data])
              setOverdueTaskGroupDataTotime([
                ...overdueTaskGroupDataTotime,
                ...overdueTaskGroupDataTotimeArr.data
              ]);
            } else {
              setOverdueTaskGroupDataTotime(overdueTaskGroupDataTotimeArr.data);
            }
          }
        } else {
          setOverdueTaskGroupDataTotime([]);
        }
        !isNextPage && setIsLoader(false);
      }
      if (error) {
        showNotifications();
        if (isNextPage) {
          setOverduePageInfo({ ...pageInfo, isLastPage: true });
          isNextPage && setIsOverdueNextPageLoader(false);
        }
      }
    } catch (error) {
      if (isNextPage) {
        setOverduePageInfo({ ...pageInfo, isLastPage: true });
        setIsOverdueLoader(false);
        isNextPage && setIsOverdueNextPageLoader(false);
      }

      // console.log(error);
    } finally {
      setIsLoader(false);
      setIsNextPageLoader(false);
    }
  };
  const groupDataToTime = (data = [], keyname_ = "activity") => {
    // this gives an object with dates as keys
    try {
      const groups = data.reduce((groups, activity) => {
        // const date = moment(activity.createdAt).format("DD-MM-YYYY[T]HH:mm a");
        const keyDate = moment(activity.scheduledAt).format("DD MMM YYYY");
        if (!groups[keyDate]) {
          groups[keyDate] = [];
        }
        groups[keyDate].push(activity);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map(date => {
        return {
          date,
          [keyname_]: groups[date]
        };
      });

      return { status: true, data: groupArrays };
    } catch (e) {
      return {
        status: false
      };
    }
  };

  const doUpdateTaskStatus = (taskId, status, objectKey, givenIndex) => {
    try {
      let assignmentDataarr = [...myAssignmentsData];
      let isStatusUpdated = false;
      var index = getKeyIndexOf(
        myAssignmentsData,
        "project",
        objectKey.project.id
      );
      if (index !== -1) {
        let taskIndex = getIndexOf(assignmentDataarr[index].task, taskId);
        if (taskIndex !== -1) {
          assignmentDataarr[index].task[
            taskIndex
          ].isCompleted = !assignmentDataarr[index].task[taskIndex].isCompleted;
          isStatusUpdated = true;
        }
      }
      if (isStatusUpdated) {
        setMyAssignmentsData([...assignmentDataarr]);
      }
      return 0;
    } catch (err) {
      // console.log(err)
    }
  };

  const doToggleOverdueTaskStatus = (id, status, index, objectKey) => {
    if (id) {
      let isStatusUpdated = false;
      // {overdueTasks[index].data[objectKey]}
      let overdueTasksArr = { ...overdueTaskGroupDataTotime };
      if (overdueTasksArr[index].data[objectKey].task) {
        overdueTasksArr[index].data[objectKey].task &&
          overdueTasksArr[index].data[objectKey].task.map((data, i) => {
            if (data.id === id) {
              overdueTasksArr[index].data[objectKey].task[
                i
              ].isCompleted = !overdueTasksArr[index].data[objectKey].task[i]
                .isCompleted;
              isStatusUpdated(true);
            }
          });
      }
      if (isStatusUpdated) {
        setOverdueTaskGroupDataTotime(overdueTasksArr);
      }
    }
  };
  return (
    <>
      {isLoader ? (
        <>
          <Shimmer />
          <Shimmer />
        </>
      ) : (
        <>
          {overdueTaskGroupDataTotime.length > 0 ||
          (myAssignmentsData && myAssignmentsData.length) ||
          isCompletedVisible ? (
            <>
              {overdueTaskGroupDataTotime &&
              overdueTaskGroupDataTotime.length > 0 ? (
                <>
                  <OverDueTaskList
                    overduePageInfo={overduePageInfo}
                    doViewMoreOverdue={() => fetchOverdueAssignments(true)}
                    isOverdueNextPageLoader={isOverdueNextPageLoader}
                    doToggleOverdueTaskStatus={doToggleOverdueTaskStatus}
                    handleRedirection={handleRedirection}
                    overdueTasks={overdueTaskGroupDataTotime}
                    {...props}
                  />
                </>
              ) : (
                ""
              )}
              {Object.keys(myAssignmentsData).length ? (
                <>
                  {myAssignmentsData &&
                    myAssignmentsData.map((element, index) => {
                      return (
                        <ProjecttList
                          key={index}
                          handleRedirection={handleRedirection}
                          project={element.project}
                          task={element.task}
                          index={index}
                          toggleTaskStatus={(id, status) => {
                            doUpdateTaskStatus(id, status, element, index);
                          }}
                        />
                      );
                    })}
                  {!pageInfo.isLastPage && (
                    <div
                      style={{
                        color: props.PRIMARY_COLOR,
                        fontSize: 14,
                        textDecoration: isNextPageLoader ? "none" : "underline",
                        textAlign: "center",
                        cursor: isNextPageLoader ? "not-allowed" : "pointer",
                        paddingBottom: 10
                      }}
                    >
                      {isNextPageLoader ? (
                        <LinearLoader />
                      ) : (
                        <div
                          style={{
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "2.09rem"
                          }}
                          onClick={() =>
                            !pageInfo.isLastPage ? fetchAssignedByMe(true) : {}
                          }
                        >
                          View more
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
              <MyCompletedAssignments
                isMyAssignments={false}
                handleRedirection={handleRedirection}
                isVisibility={isCompletedVisible}
                updateIsVisibility={setIsCompletedVisible}
                {...props}
              />
            </>
          ) : (
            <TaskDataNotFound />
          )}
        </>
      )}
    </>
  );
};

export default StuffAssignments;
