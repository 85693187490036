const labelActions = {
  SET_LABELS: "SET_LABELS",
  UNSET_LABELS: "UNSET_LABELS",
  UPDATE_LABELS: "UPDATE_LABELS",
  UPDATE_LABELS_EXISTING: "UPDATE_LABELS_EXISTING",

  set_labels: data => {
    return {
      type: labelActions.SET_LABELS,
      labels: data.labels
    };
  },
  unset_labels: () => {
    return {
      type: labelActions.UNSET_LABELS,
      labels: []
    };
  },
  update_labels_existing: labels => {
    return {
      type: labelActions.UPDATE_LABELS_EXISTING,
      labels: labels
    };
  },
  update_Label: labels => {
    return {
      type: labelActions.UPDATE_LABELS,
      labels: labels
    };
  }
};
export default labelActions;
