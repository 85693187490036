export const EmojiJson = [
  {
    no: 2,
    code: "U+1F601",
    emoji: "😁",
    description: "GRINNING FACE WITH SMILING EYES"
  },
  {
    no: 454,
    code: "U+1F44D",
    emoji: "👍",
    description: "THUMBS UP SIGN≊ thumbs up"
  },
  {
    no: 460,
    code: "U+1F44E",
    emoji: "👎",
    description: "THUMBS DOWN SIGN≊ thumbs down"
  },
  {
    no: 3,
    code: "U+1F602",
    emoji: "😂",
    description: "FACE WITH TEARS OF JOY"
  },
  {
    no: 565,
    code: "U+1F493",
    emoji: "💓",
    description: "BEATING HEART"
  },
  {
    no: 31,
    code: "U+1F62F",
    emoji: "😯",
    description: "HUSHED FACE"
  },
  {
    no: 65,
    code: "U+1F621",
    emoji: "😡",
    description: "POUTING FACE"
  },
  {
    no: 502,
    code: "U+1F44F",
    emoji: "👏",
    description: "CLAPPING HANDS SIGN≊ clapping hands"
  },
  {
    no: 54,
    code: "U+1F622",
    emoji: "😢",
    description: "CRYING FACE"
  },
  {
    no: 54,
    code: "U+1F389",
    emoji: "🎉",
    description: "PARTY POPPER"
  }
];
