import React, { useEffect } from "react";
import TaskTitleCardWrapper from "components/TaskDetails/TaskTitleCardWrapper.style";
import { completedCheck } from "helper/constant";
import IntlMessages from "util/intlMessages";
const TaskTitleCard = props => {
  const {
    taskTitle,
    taskIsCompleted,
    updateIsCompleted,
    isEditTask,
    taskTitleInput,
    setTaskTitleInput,
    titleRef,
    handleEditTask,
    taskArchive,
    handleUpdateTaskDetails
  } = props;
  useEffect(() => {
    return () => {
      setTaskTitleInput("");
    };
  }, [isEditTask]);
  return (
    <div
      className="task-title-container"
      style={{
        padding: taskArchive != true ? "30px 0px 20px " : "0px 0px 20px",
        width: "100%"
      }}
    >
      <TaskTitleCardWrapper {...props}>
        <div className="row">
          <div
            className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 TextTitleCardImage"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: 0
            }}
            onClick={updateIsCompleted}
          >
            {taskIsCompleted ? (
              <img
                alt=""
                className="task-checkbox checked"
                src={completedCheck}
              />
            ) : (
              <div className="task-checkbox un-checked fs-10">
                <IntlMessages id="project.mark_this_complete" />
              </div>
            )}
          </div>
          <div
            className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12 TextTitleCardImage"
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center"
            }}
          >
            {!isEditTask ? (
              <div
                className=""
                style={{
                  fontSize: 28,
                  fontWeight: "600",
                  color: "black",
                  display: "inline-block",
                  alignItems: "center",
                  justifyContent: "left",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: " ellipsis",
                  width: "100%",
                  textAlign: "start"
                }}
                onClick={() => handleEditTask("TITLE")}
              >
                {" "}
                {taskTitle}
              </div>
            ) : (
              // <textarea
              //   ref={titleRef}
              //   rows="1"
              //   placeholder="Describe this to-do…"
              //   required="required"
              //   name="todo[content]"
              //   id="todo_content"
              //   className="input--borderless"
              //   style={{
              //     border: "none",
              //     fontWeight: "600",
              //     color: "black",
              //     display: "flex",
              //     alignItems: "center",
              //     justifyContent: "left",
              //     width: "100%",
              //     padding: 0
              //   }}
              //   onChange={e => setTaskTitleInput(e.target.value)}
              //   defaultValue={taskTitleInput}
              // ></textarea>

              <input
                onKeyPress={e => {
                  if (e.key === "Enter")
                    //  e.preventDefault()
                    handleUpdateTaskDetails();
                }}
                type="text"
                ref={titleRef}
                rows="1"
                // rows="1"
                placeholder="Describe this to-do…"
                required="required"
                name="todo[content]"
                id="todo_content"
                className="input--borderless"
                style={{
                  fontSize: "28px !important",
                  border: "none",
                  fontWeight: "600",
                  color: "black",
                  width: "100%"
                }}
                onChange={e => setTaskTitleInput(e.target.value)}
                defaultValue={taskTitleInput}
              />
            )}
          </div>
        </div>
      </TaskTitleCardWrapper>
    </div>
  );
};

export default TaskTitleCard;
