import React, { useState, useEffect } from "react";
import {
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import { getUser } from "helper/services/authServices";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AuthActions from "redux/auth/actions";
import ToastMessage from "components/notifications/ToastMessage";
import { useToasts } from "react-toast-notifications";
import {
  getBusinessList,
  swicthBusiness
} from "helper/services/businessServices";
import { moveProject } from "helper/services/projectServices";
import { getProject, getLables } from "helper/services/projectServices";
import { businessImage } from "helper/constant";
import BusinessListShimmer from "components/shimmer/BusinessListShimmer";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import labelActions from "redux/labels/actions";
import projectActions from "redux/projects/actions";
import businessActions from "redux/business/actions";
import CustomNotification from "util/CustomNotification";

const { update_token, update_business } = AuthActions;
const { remove_Project } = projectActions;
const { default_business } = businessActions;
const { set_labels, update_Label } = labelActions;
const { set_projects } = projectActions;

const BusinessModal = props => {
  const [isShowArchived, setIsShowArchived] = useState(false);
  const [isToggleAllowed, setIsToggleAllowed] = useState(true);
  const { modal, setmodal, topbarTheme } = props;
  const [businessListArr, setBusinessListArr] = useState([]);
  const [archivedbusinessListArr, setArchivedBusinessListArr] = useState([]);
  const [isBusinessLoader, setIsBusinessLoader] = useState(false);
  const [isDefaultLoader, setIsDefaultLoader] = useState({
    id: "",
    status: false
  });
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const { addToast } = useToasts();
  let toggleProps = {};
  useEffect(() => {
    if (modal && props.project) {
      retriveBusiness();
    }
    return () => {
      setBusinessListArr([]);
      setBusinessListArr([]);
      setArchivedBusinessListArr([]);
      setIsToggleAllowed(true);
    };
  }, [modal]);
  const profileImage = {
    borderRadius: "50%",
    width: 42,
    height: 42,
    objectFit: "cover",
    textAlign: "center",
    boxShadow: "0 0.125rem 0.1875rem rgb(0 0 0 / 3%)"
  };
  const placeholderImage = {
    borderRadius: "50%",
    width: 42,
    height: 42,
    objectFit: "cover",
    textAlign: "center",
    boxShadow: "0 0.125rem 0.1875rem rgb(0 0 0 / 3%)"
  };
  const profileBackground = {
    borderRadius: "50%",
    width: 50,
    background: "rgb(250,0,0)",
    height: 50,
    objectFit: "fill",
    textAlign: "center"
  };
  const InCap = {
    lineHeight: 2.2,
    fontSize: 20,
    color: "white",
    textAlign: "center"
  };

  const retriveBusiness = async () => {
    try {
      setIsBusinessLoader(true);
      const Response = await getBusinessList(true);
      if (Response) {
        if (Response.error) {
        }
        if (Response.data) {
          setIsBusinessLoader(false);
          console.log(
            Response.data?.some(
              a =>
                a.collaborator[0]?.isDefault &&
                props?.auth?.user?.businessId === a.id
            )
          );
          // check if user have any one default busienss if yes than modal header have toggle option otherwise user cannot toggle
          if (
            Response.data?.some(
              a =>
                a.collaborator[0]?.isDefault &&
                props?.auth?.user?.businessId === a.id
            )
          ) {
            setIsToggleAllowed(true);
          } else {
            setIsToggleAllowed(false);
          }
          await filterArchiveBusiness(Response.data);
        }
      }
    } catch (e) {}
  };
  const filterArchiveBusiness = async businessArr => {
    try {
      if (businessArr?.length > 0) {
        let archivedBusinessArr = [];
        let unarchivedBusinessArr = [];
        await Promise.all(
          (archivedBusinessArr =
            businessArr && businessArr.filter(business => business.isArchived))
        );
        await Promise.all(
          (archivedBusinessArr =
            archivedBusinessArr &&
            archivedBusinessArr
              .sort(
                (a, b) =>
                  a.collaborator[0]?.isDefault - b.collaborator[0]?.isDefault
              )
              .reverse())
        );
        await Promise.all(
          (unarchivedBusinessArr =
            businessArr && businessArr.filter(business => !business.isArchived))
        );
        await Promise.all(
          (unarchivedBusinessArr =
            unarchivedBusinessArr &&
            unarchivedBusinessArr
              .sort(
                (a, b) =>
                  a.collaborator[0]?.isDefault - b.collaborator[0]?.isDefault
              )
              .reverse())
        );

        setBusinessListArr([...unarchivedBusinessArr]);
        setIsShowArchived(unarchivedBusinessArr?.length > 0 ? false : true);
        setArchivedBusinessListArr([...archivedBusinessArr]);
        checkForDefaulBusinessInArchive([...archivedBusinessArr]);
      } else {
        setBusinessListArr([]);
        setArchivedBusinessListArr([]);
      }
    } catch (e) {}
  };
  const checkForDefaulBusinessInArchive = async business => {
    var getDefault = [];
    if (business) {
      getDefault =
        business && business.filter(data => data.collaborator[0]?.isDefault);
    }

    if (getDefault && getDefault.length > 0) {
      setIsShowArchived(true);
    } else {
      setIsShowArchived(false);
    }
  };

  const moveProjectToWorkpsace = async id => {
    try {
      if (props.project && props.project) {
        setIsDefaultLoader({
          id: id,
          status: true
        });
        const Response = await moveProject({
          business_id: id,
          project_id: props.project && props.project.id
        });
        if (Response) {
          if (Response.message) {
            if (props.history.location.pathname !== "/today") {
              props.history.push("/today");
            }
            props.remove_Project(props.project.id);
            showNotifications(Response.message, "success", true, {
              projectId: props.project.id,
              businessId: id
            });
            setIsDefaultLoader({
              id: "",
              status: false
            });

            setmodal(!modal);
          }
          if (Response.error) {
            setIsDefaultLoader({
              id: "",
              status: false
            });
          }
        }
      }
    } catch (e) {
      setIsDefaultLoader({
        id: "",
        status: false
      });
    }
  };
  /* by passing id it will be become default business */
  const updateDefaultBusiness = id => {
    let businessArr = businessListArr;
    businessArr.map((data, i) => {
      if (data.id == id) {
        businessArr[i].isDefault = true;
      } else {
        businessArr[i].isDefault = false;
      }
    });
    setBusinessListArr([...businessArr]);

    setmodal(!modal);
  };
  function onHoverStyle(div) {
    document.getElementById(div).style.background = "#f5f5f5";
  }
  function onMouseLeave(div) {
    document.getElementById(div).style.background = "#fff";
    // e.target.style.background = "#fff";
  }
  const updateBusinessData = businessData => {
    let businessArr = businessListArr;
    businessArr.map((data, i) => {
      if (data.id == businessData.id) {
        businessArr[i] = businessData;
      }
    });
    setBusinessListArr([...businessArr]);
  };
  const updateToken = (token, businessName, businessMeta, id) => {
    props.update_token(token);
    props.default_business({
      id: id,
      businessName: businessName,
      businessMeta: businessMeta
    });

    props.update_business({ businessId: id, businessName, businessMeta });
  };
  const handleRedirectWorkspace = business => {
    props.history.push(`/business/${business.id}`, {
      business,
      isDefault: business?.collaborator[0]?.isDefault
    });
    setmodal(!modal);
  };
  const updateUser = async token => {
    try {
      const result = await getUser(token);
      if (result) {
      }
    } catch (err) {}
  };
  const showNotifications = (
    message,
    status,
    redirect = false,
    project = ""
  ) => {
    if (redirect && project) {
      addToast(
        ToastMessage(
          message,
          `/project/${project?.projectId}`,
          "Go to project",
          data => {
            selectBusiness(project.businessId, project?.projectId);
            props.history.push(data);
          }
        ),
        {
          appearance: status,
          autoDismiss: true,
          autoDismissTimeout: 8000
        }
      );
    } else {
      addToast(ToastMessage(message), {
        appearance: status ? "success" : "error",
        autoDismiss: true
      });
    }
  };

  const updateProjectandLabel = async token => {
    fecthProject(token);
    fetchLabels(token);
  };
  const fetchLabels = async token => {
    try {
      const Response = await getLables(token);
      if (Response) {
        if (Response.data) {
          props.set_labels({ labels: Response.data });
          updateSidebarLabelToSidebar(Response.data);
        }
        if (Response.error) {
        }
      }
    } catch (e) {}
  };

  const fecthProject = async token => {
    const Response = await getProject(token);
    if (Response) {
      props.set_projects({ projects: Response.data });
      updateSidebarProjectToSidebar(Response.data);
    }
  };

  const selectBusiness = async (id, projectId) => {
    try {
      const Response = await swicthBusiness(id);
      if (Response) {
        if (Response.data) {
          await updateToken(
            Response.data.token,
            Response.data.business.title,
            Response.data.business?.businessMeta
              ? Response.data.business?.businessMeta
              : null,
            Response.data.business.id
          );

          updateProjectandLabel(Response.data.token);

          if (props.history.location.pathname !== "/today") {
            props.history.push(`/project/${projectId}`, {
              token: Response.data.token
            });
          }
        }
        if (Response.error) {
        }
      }
    } catch (e) {}
  };
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={() => (isToggleAllowed ? setmodal(!modal) : false)}
        size="md"
        centered
        {...toggleProps}
      >
        <ModalHeader
          toggle={() => (isToggleAllowed ? setmodal(!modal) : false)}
          {...toggleProps}
        >
          <span style={{ fontWeight: 500, fontSize: "18px" }}>
            Choose Workspace
          </span>
        </ModalHeader>

        <ModalBody>
          {/* <busienssListWrapper> */}
          <div
            className="container list-container"
            style={{
              overflow: " scroll",
              height: " 370px",
              paddingRight: "5px"
            }}

            /* key={i} */
          >
            <div className="text-muted fs-14">
              {/* {businessListArr
                ? businessListArr.length > 1
                  ? businessListArr.length + " Workspaces"
                  : !isBusinessLoader &&
                    businessListArr?.length === 0 &&
                    archivedbusinessListArr?.length === 0 && (
                      <div
                        style={{
                          display: "flex",
                          textAlign: "center",
                          justifyContent: "center"
                        }}
                      >
                        No workspace found,
                        <br />
                        Add workspace than you will be able to do any action
                      </div>
                    )
                : ""}{businessListArr
                ? businessListArr.length > 1
                  ? businessListArr.length + " Workspaces"
                  : !isBusinessLoader &&
                    businessListArr?.length === 0 &&
                    archivedbusinessListArr?.length === 0 && (
                      <div
                        style={{
                          display: "flex",
                          textAlign: "center",
                          justifyContent: "center"
                        }}
                      >
                        No workspace found,
                        <br />
                        Add workspace than you will be able to do any action
                      </div>
                    )
                : ""} */}
            </div>
            {isBusinessLoader && <BusinessListShimmer />}
            {businessListArr && businessListArr?.length > 0 && (
              <div>
                <table
                  style={{
                    width: "100%",
                    borderSpacing: "1em .5em",
                    padding: "0 2em 1em 0"
                  }}
                >
                  <tbody>
                    {businessListArr.map((business, i) => {
                      if (props?.project?.business?.id !== business.id) {
                        return (
                          <tr
                            key={i}
                            id={`divqw${i}`}
                            onMouseEnter={() => onHoverStyle(`divqw${i}`)}
                            onMouseLeave={() => onMouseLeave(`divqw${i}`)}
                          >
                            <td
                              onClick={() => handleRedirectWorkspace(business)}
                              style={{
                                width: 1,
                                paddingBottom: 8,
                                paddingTop: 8,
                                cursor: "pointer"
                              }}
                            >
                              {" "}
                              <div
                                style={
                                  businessImage
                                    ? profileImage
                                    : profileBackground
                                }
                              >
                                {business?.businessMeta ? (
                                  <img
                                    style={profileImage}
                                    src={business?.businessMeta?.thumbnail}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    style={placeholderImage}
                                    src={businessImage}
                                    alt=""
                                  />
                                )}
                              </div>
                            </td>
                            <td
                              onClick={() => handleRedirectWorkspace(business)}
                              style={{
                                paddingLeft: 5,
                                width: "100%",
                                paddingBottom: 8,
                                paddingTop: 8,
                                cursor: "pointer"
                              }}
                            >
                              <div className="mlr-5  " id="changeBusinessss">
                                <p className=" fs-14 fw-400  demi-bold-text">
                                  {business && business.title}
                                </p>
                              </div>
                            </td>

                            <td style={{ paddingBottom: 8, paddingTop: 8 }}>
                              {" "}
                              {isDefaultLoader.status &&
                              isDefaultLoader.id === business.id ? (
                                <Loader height="23px" width="18px" />
                              ) : props?.project &&
                                props?.project?.business?.id !== business.id ? (
                                <div
                                  className="task-icons plr-10"
                                  style={{ textAlign: "center" }}
                                >
                                  <button
                                    className="btn button button-hover btn-sm"
                                    style={{
                                      border: `1px solid ${topbarTheme?.buttonColor}`,
                                      color: topbarTheme?.buttonColor
                                    }}
                                    onClick={() =>
                                      moveProjectToWorkpsace(business.id)
                                    }
                                  >
                                    Move
                                  </button>
                                </div>
                              ) : (
                                <div
                                  className="task-icons plr-10"
                                  style={{ textAlign: "end" }}
                                >
                                  <i
                                    className="fa fa-check-circle"
                                    style={{
                                      color: topbarTheme?.buttonColor
                                        ? topbarTheme.buttonColor
                                        : "black"
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {archivedbusinessListArr && archivedbusinessListArr?.length > 0 && (
              <>
                {isShowArchived ? (
                  <div>
                    <div
                      className="project-header-heading fs-14"
                      onClick={() => setIsShowArchived(!isShowArchived)}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline"
                      }}
                    >
                      Hide Archived Workspaces
                    </div>
                    <table
                      style={{
                        width: "100%",
                        borderSpacing: "1em .5em",
                        padding: "0 2em 1em 0"
                      }}
                    >
                      <tbody>
                        {archivedbusinessListArr &&
                          archivedbusinessListArr?.length > 0 &&
                          archivedbusinessListArr.map((business, ai) => {
                            if (props?.project?.business?.id !== business.id) {
                              return (
                                <tr
                                  key={ai}
                                  id={`divai${ai}`}
                                  onMouseEnter={() =>
                                    onHoverStyle(`divai${ai}`)
                                  }
                                  onMouseLeave={() =>
                                    onMouseLeave(`divai${ai}`)
                                  }
                                >
                                  <td
                                    onClick={() =>
                                      handleRedirectWorkspace(business)
                                    }
                                    style={{
                                      width: 1,
                                      paddingBottom: 8,
                                      paddingTop: 8,
                                      cursor: "pointer"
                                    }}
                                  >
                                    {" "}
                                    <div
                                      style={
                                        businessImage
                                          ? profileImage
                                          : profileBackground
                                      }
                                    >
                                      {business?.businessMeta ? (
                                        <img
                                          style={profileImage}
                                          alt=""
                                          src={
                                            business?.businessMeta?.thumbnail
                                          }
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          style={placeholderImage}
                                          src={businessImage}
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    onClick={() =>
                                      handleRedirectWorkspace(business)
                                    }
                                    style={{
                                      paddingLeft: 5,
                                      width: "100%",
                                      paddingBottom: 8,
                                      paddingTop: 8,
                                      cursor: "pointer"
                                    }}
                                  >
                                    <div
                                      className="mlr-5  "
                                      id="changeBusinessss"
                                    >
                                      <p className=" fs-14 fw-400  demi-bold-text">
                                        {business && business.title}
                                      </p>
                                    </div>
                                  </td>

                                  <td
                                    style={{
                                      paddingBottom: 8,
                                      paddingTop: 8,
                                      cursor: "pointer"
                                    }}
                                  >
                                    {isDefaultLoader.status &&
                                    isDefaultLoader.id === business.id ? (
                                      <>
                                        {" "}
                                        <Loader height="23px" width="18px" />
                                      </>
                                    ) : !business?.collaborator[0]
                                        ?.isDefault ? (
                                      <div className="task-icons plr-10">
                                        <div
                                          style={{
                                            textAlign: "center"
                                          }}
                                        >
                                          <button
                                            className="btn button button-hover btn-sm"
                                            style={{
                                              border: `1px solid ${topbarTheme?.buttonColor}`,
                                              color: topbarTheme?.buttonColor
                                            }}
                                            onClick={() =>
                                              moveProjectToWorkpsace(
                                                business.id
                                              )
                                            }
                                          >
                                            Move
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="task-icons plr-10"
                                        style={{
                                          textAlign: "end"
                                        }}
                                      >
                                        <i
                                          className="fa fa-check-circle"
                                          style={{
                                            color: topbarTheme?.buttonColor
                                              ? topbarTheme.buttonColor
                                              : "black"
                                          }}
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div
                    className="project-header-heading fs-14"
                    onClick={() => setIsShowArchived(!isShowArchived)}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer"
                    }}
                  >
                    Show Archived Workspaces
                  </div>
                )}
              </>
            )}
            <div></div>
            <CustomNotification
              show={toast}
              message={toastMessage}
              closeNotification={() => setToast(false)}
            />
          </div>
          {/* </busienssListWrapper> */}
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {
  update_token,
  default_business,
  update_business,
  set_projects,
  set_labels,
  update_Label,
  remove_Project
})(BusinessModal);
