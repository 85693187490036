import styled from "styled-components";

const MyCustomTostWrapper = styled.div`
  .react-toast-notifications__toast__dismiss-button {
    color: #69727d !important;
    opacity: 1 !important;
    background: white !important;
    display: flex !important;
    align-items: center !important;
    border-bottom-right-radius: 13px !important;
    border-top-right-radius: 13px !important;
    cursor: pointer !important;
  }
  .react-toast-notifications__toast__dismiss-button:hover {
    opacity: 1 !important;
    display: flex !important;
    align-items: center !important;
    cursor: pointer !important;
  }
  .css-1klfh0r-Button:hover {
    opacity: 1 !important;
    color: ${props =>
      props.appearance != "error"
        ? props.topbarTheme.backgroundColor
        : "black"};
    display: flex !important;
    align-items: center !important;
  }
  .react-toast-notifications__toast__icon-wrapper {
    background: ${props =>
      props.appearance != "error" ? props.topbarTheme.backgroundColor : ""};
    display: none !important;
  }
  .react-toast-notifications__toast__content {
    padding: 6px !important;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 13px !important;
    border-top-left-radius: 13px !important;
    background: white !important;
  }
  .css-tje2md-Countdown {
    background: ${props =>
      props.appearance != "error" ? props.topbarTheme.backgroundColor : ""};
  }
`;

export default MyCustomTostWrapper;
