import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { addnew, AddSectionCardImage } from "helper/constant";
import IntlMessages from "util/intlMessages";
const BoardCardAdder = props => {
  const [cardInput, setCardInput] = useState("");
  const textArea = useRef(null);
  const { addNewCardHandler, topbarTheme } = props;
  // addSectionFlag
  // setSectionCardFlag
  const addNewCard = () => {
    if (cardInput.match(/^\S+\w{0,50}\S{0,}/g)) {
      addNewCardHandler(cardInput);
    }
    setCardInput("");
    props.setSectionCardFlag(false);
  };

  const closeAddingCard = () => {
    if (cardInput === "") {
      props.setSectionCardFlag(!props.addSectionFlag);
    }
  };

  return (
    <div>
      <div>
        {!props.addSectionFlag ? (
          <div
            className="add-subtask-block add-card-block roe-box-shadow"
            onClick={() => props.setSectionCardFlag(true)}
          >
            <img
              src={AddSectionCardImage}
              style={{
                height: "12px",
                width: "12px",
                marginRight: "5px",
                marginTop: "-3px",
                textAlign: "center"
              }}
            ></img>
            {/* <i className="fas fa-plus"></i> */}
            <span style={{ fontWeight: "bold", fontSize: 14, color: "black" }}>
              {<IntlMessages id={"project.add_new_list"} />}
            </span>
          </div>
        ) : (
          <div
            className="add-comment-input-block add-card-block roe-box-shadow"
            style={{ backgroundColor: "white" }}
          >
            <textarea
              style={{ backgroundColor: "#f4f5f7" }}
              ref={textArea}
              value={cardInput}
              autoFocus
              className="add-subtask-input"
              name="taskTitle"
              rows="2"
              onChange={e => setCardInput(e.target.value)}
              onBlur={closeAddingCard}
              onKeyPress={event => {
                if (event.key === "Enter") {
                  if (!event.shiftKey) {
                    event.preventDefault();
                    if (cardInput.match(/^\S+\w{0,50}\S{0,}/g)) {
                      addNewCard();
                    }
                  }
                }
              }}
            ></textarea>
            <div className="subtask-action">
              <button
                className="btn btn-secondary "
                style={{
                  // backgroundColor: "#ff6b6b",
                  backgroundColor: topbarTheme.buttonColor,
                  border: 0,
                  // marginLeft: "8px",
                  marginRight: "10px",
                  fontSize: "13px"
                }}
                disabled={!cardInput.match(/^\S+\w{0,50}\S{0,}/g)}
                onClick={addNewCard}
              >
                {/* // change class name up c-btn c-danger */}
                {<IntlMessages id={"project.add_list"} />}
              </button>
              <button
                className="btn btn-secondary"
                // change class name up c-btn c-danger
                style={{
                  color: "black",
                  background: "transparent",
                  // background: "red",
                  border: 0,
                  fontSize: "13px",
                  paddingLeft: "0px"
                }}
                onClick={() => props.setSectionCardFlag(false)}
              >
                {<IntlMessages id={"project.cancel"} />}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    businessId: state.auth.user?.businessId,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};
export default connect(mapStateToProps, null)(BoardCardAdder);
