import React, { Component } from "react";
import { connect } from "react-redux";
import { page_not_found } from "helper/constant";
class NotFound extends Component {
  render() {
    const { sidebarTheme } = this.props;

    const activeColor = {
      color: sidebarTheme.activeColor
    };

    return (
      <div
        className="text-muted"
        style={{
          padding: 50,
          fontSize: 15,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <div>
          <img src={page_not_found} height="400" />
          <h4 className="text-muted text-center flex-x">
            Its seems like the url you're trying to access doesn't exist
          </h4>
          <div className="flex pt-10" style={{ justifyContent: "center" }}>
            <button className="btn button">Dashboard</button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(NotFound);
