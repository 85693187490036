// TaskActivitySingle.jsx;
import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  profileBackground,
  profileImage
} from "helper/methods/getUserAvatarColor";
import IconTint from "react-icon-tint";
import {
  CardTaskCalenderImage,
  DuedateChange,
  changedate,
  CrossTaskDetailsActivityImage
} from "helper/constant";
function TaskactivityList(props) {
  const { lastActivity } = props;
  const [taskActivity, setTaskActivity] = useState([]);
  useEffect(() => {
    setTaskActivity(props.taskActivityArr);
  }, [props]);

  const profileImage = {
    borderRadius: "50%",
    border: "1 solid",
    width: 22,
    height: 22,
    objectFit: "cover",
    textAlign: "center",
    background: "#e2e2e2"
  };

  const InCap = {
    lineHeight: 1,
    fontSize: 12,
    color: "white",
    textAlign: "center"
  };

  const ActivityTypes = {
    TASK_ADD: "taskadd",
    TASK_ASSIGN: "taskassign",
    TASK_MOVE: "taskmove",
    REMOVE_ASSIGN: "removeassign",
    NAME_CHANGE: "namechange",
    LABEL_CHANGE: "labelchange",
    PRIORITY_CHANGE: "prioritychange",
    CHECHED_CHANGE: "checkedchange",
    DUEDATE_CHANGE: "duedatechange",
    DUEDATE_REMOVED: "duedateremoved",
    REMINDER_CHANGE: "reminderchange",
    REMINDER_REMOVED: "reminderremoved",
    SUBTASK_ADD: "subtaskadd",
    SUBTASK_UPDATE: "updatesubtasktitle",
    SUBTASK_DELETE: "subtaskdelete",
    ADD_SUBTASK_DATE: "addsubtaskdate",
    REMOVE_SUBTASK_DATE: "removesubtaskdate",
    ADD_SUBTASK_ASSIGNEE: "addsubtaskassignee",
    REMOVE_SUBTASK_ASSIGNEE: "removesubtaskassignee",
    CHECKLIST_ADD: "checklistadd",
    CHECKLIST_DELETE: "checklistdelete",
    CHECKLIST_UPDATE: "checklistupdate",
    ADD_ATTACHMENT: "addattachment",
    REMOVE_ATTACHMENT: "removeattatchment",
    TASK_ARCHIVE: "archivedtask",
    TASK_UNARCHIVE: "unarchivedtask",
    LABEL_ADD: "labeladd",
    LABEL_DELETE: "labeldelete"
  };

  const generateSentense = item => {
    if (item.activity_type == ActivityTypes.TASK_ADD) {
      return (
        <span className="fs-14 ">
          {" "}
          has added new task <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.TASK_ASSIGN) {
      return (
        <span className="fs-14 ">
          {" "}
          has assigned task to <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.TASK_MOVE) {
      return (
        <span className="fs-14 ">
          {" "}
          has moved task from <b>{item.past_value}</b> to <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.REMOVE_ASSIGN) {
      return (
        <span className="fs-14 ">
          {" "}
          has removed <b>{item.value}</b> as task assignee
        </span>
      );
    } else if (item.activity_type == ActivityTypes.NAME_CHANGE) {
      return (
        <span className="fs-14 ">
          {" "}
          has updated task name from <b>{item.past_value}</b> to{" "}
          <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.LABEL_CHANGE) {
      return (
        <span className="fs-14 ">
          {" "}
          has added label <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.PRIORITY_CHANGE) {
      return (
        <span className="fs-14 ">
          {" "}
          has changed priority from{" "}
          <b>{item?.past_value?.replace("_", " ")} </b>
          to <b>{item?.value?.replace("_", " ")}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.CHECHED_CHANGE) {
      return (
        <span className="fs-14" style={{ fontWeight: "600" }}>
          {" "}
          {item.value == "completed"
            ? `completed this to-do`
            : `re‑opened this to-do`}
        </span>
      );
    } else if (item.activity_type == ActivityTypes.DUEDATE_CHANGE) {
      if (!item.past_value) {
        return (
          <span className="fs-14 ">
            {" "}
            has added due date{" "}
            <span style={{ fontWeight: "600", fontSize: 13 }}>
              {moment(item.value).format("DD MMM hh:mm a")}
            </span>
          </span>
        );
      }
      return (
        <span className="fs-14 ">
          {" "}
          <span style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                fontWeight: "600",
                display: "flex",
                alignItems: "center"
              }}
            >
              <img
                src={changedate}
                style={{
                  height: "16px",
                  width: "16px",
                  margin: "0px 5px 0px 0px"
                }}
              ></img>{" "}
              {item.user.name} changed the due date
            </span>
            &nbsp;for this to-do
          </span>{" "}
          <span
            style={{
              fontSize: 14,
              display: "flex",
              alignItems: "center",
              margin: "10px 0px"
            }}
          >
            <span style={{ fontWeight: "600", width: "12%" }}>Was </span>

            <span
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 5px 0px 0px"
              }}
            >
              <img
                src={DuedateChange}
                style={{
                  height: "16px",
                  width: "16px",
                  margin: "0px 5px 0px 0px"
                }}
              ></img>{" "}
            </span>
            {moment(item.past_value).format("DD MMM hh:mm a")}
          </span>{" "}
          {/* <br />   */}
          <span style={{ fontSize: 14, display: "flex", alignItems: "center" }}>
            <span style={{ fontWeight: "600", width: "12%" }}>Now </span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 5px 0px 0px"
              }}
            >
              <img
                src={DuedateChange}
                style={{
                  height: "16px",
                  width: "16px",
                  margin: "0px 5px 0px 0px"
                }}
              ></img>{" "}
            </span>
            {moment(item.value).format("DD MMM hh:mm a")}
          </span>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.DUEDATE_REMOVED) {
      return <span className="fs-14 "> removed due date </span>;
    } else if (item.activity_type == ActivityTypes.REMINDER_CHANGE) {
      if (!item.past_value) {
        return (
          <span className="fs-14 ">
            {" "}
            has added reminder{" "}
            <span style={{ fontWeight: "600", fontSize: 13 }}>
              {moment(item.value).format("DD MMM hh:mm a")}
            </span>
          </span>
        );
      }
      return (
        <span className="fs-14 ">
          {" "}
          has changed reminder from{" "}
          <span style={{ fontWeight: "600", fontSize: 13 }}>
            {moment(item.past_value).format("DD MMM hh:mm a")}
          </span>{" "}
          to{" "}
          <span style={{ fontWeight: "600", fontSize: 13 }}>
            {moment(item.value).format("DD MMM hh:mm a")}
          </span>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.REMINDER_REMOVED) {
      return (
        <span className="fs-14 ">
          {" "}
          has removed reminder
          {/* <span style={{ fontWeight: "600", fontSize: 13 }}>
            {moment(item.past_value).format("DD MMM hh:mm a")}
          </span> */}
        </span>
      );
    } else if (item.activity_type == ActivityTypes.SUBTASK_ADD) {
      return (
        <span className="fs-14 ">
          {" "}
          has added new subtask <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.ADD_SUBTASK_ASSIGNEE) {
      return (
        <span className="fs-14 ">
          {" "}
          has assigned subtask to <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.REMOVE_SUBTASK_ASSIGNEE) {
      return (
        <span className="fs-14 ">
          {" "}
          has removed subtask <b>{item.past_value}</b> assignee
        </span>
      );
    } else if (item.activity_type == ActivityTypes.REMOVE_SUBTASK_DATE) {
      return (
        <span className="fs-14 ">
          {" "}
          has removed subtask due date{" "}
          <span style={{ fontWeight: "600", fontSize: 13 }}>
            {moment(item.past_value).format("DD MMM hh:mm a")}
          </span>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.SUBTASK_UPDATE) {
      return (
        <span className="fs-14 ">
          {" "}
          has changed subtask title from <b>{item.past_value}</b> to{" "}
          <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.ADD_SUBTASK_DATE) {
      if (!item.past_value) {
        return (
          <span className="fs-14 ">
            {" "}
            has added subtask due date{" "}
            <span style={{ fontWeight: "600", fontSize: 13 }}>
              {moment(item.value).format("DD MMM hh:mm a")}
            </span>
          </span>
        );
      }
      return (
        <span className="fs-14 ">
          {" "}
          has udpated subtask due date from <b>{item.past_value}</b> to{" "}
          <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.SUBTASK_DELETE) {
      return (
        <span className="fs-14 ">
          {" "}
          has deleted subtask <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.CHECKLIST_ADD) {
      return (
        <span className="fs-14 ">
          {" "}
          has added new checklist <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.CHECKLIST_UPDATE) {
      return (
        <span className="fs-14 ">
          {" "}
          has changed checklist title from <b>{item.past_value}</b> to{" "}
          <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.CHECKLIST_DELETE) {
      return (
        <span className="fs-14 ">
          {" "}
          has deleted checklist <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.ADD_ATTACHMENT) {
      if (item.value) {
        return (
          <span className="fs-14 ">
            {" "}
            has added an attachment <b>{item.value}</b>
          </span>
        );
      }
    } else if (item.activity_type == ActivityTypes.REMOVE_ATTACHMENT) {
      return (
        <span className="fs-14 ">
          {" "}
          has removed attachment <b>{item.past_value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.TASK_ARCHIVE) {
      return (
        <span className="fs-14 ">
          {" "}
          has <b>Archived</b> this task
        </span>
      );
    } else if (item.activity_type == ActivityTypes.TASK_UNARCHIVE) {
      return (
        <span className="fs-14 ">
          {" "}
          has <b>Unarchived</b> this task
        </span>
      );
    } else if (item.activity_type == ActivityTypes.LABEL_ADD) {
      return <span className="fs-14 "> has added {item.value} label</span>;
    } else if (item.activity_type == ActivityTypes.LABEL_DELETE) {
      return <span className="fs-14 "> has removed {item.value} label</span>;
    }
  };

  const chooseBGColor = item => {
    if (item.activity_type == ActivityTypes.TASK_ADD) {
      return "#ff6b6b";
    } else if (item.activity_type == ActivityTypes.TASK_ASSIGN) {
      return "#6badff";
    } else if (item.activity_type == ActivityTypes.NAME_CHANGE) {
      return "#ef6bff";
    } else if (item.activity_type == ActivityTypes.LABEL_CHANGE) {
      return "#27cea7";
    } else if (item.activity_type == ActivityTypes.PRIORITY_CHANGE) {
      return "#ecba00";
    } else if (item.activity_type == ActivityTypes.CHECHED_CHANGE) {
      return "#ff6b6b";
    } else if (item.activity_type == ActivityTypes.DUEDATE_CHANGE) {
      return "#6badff";
    } else if (item.activity_type == ActivityTypes.SUBTASK_ADD) {
      return "#ef6bff";
    } else if (item.activity_type == ActivityTypes.SUBTASK_DELETE) {
      return "#ecba00";
    }
  };

  return (
    <div>
      <div>
        <div className="" style={{ marginLeft: 1 }}>
          <div className="mr-10">
            <div>
              <span
                style={{
                  fontSize: "14px",
                  color: "black",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  hyphens: "auto"
                }}
              >
                {generateSentense(taskActivity)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskactivityList;
