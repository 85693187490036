import React from "react";
import SingleTaskList from "./SingleTaskList";

const ProjecttList = ({
  project,
  task,
  handleRedirection,
  toggleTaskStatus
}) => {
  return (
    <div>
      <div style={{ padding: "10px 0px" }}>
        <div className="flex-x fs-14">
          <div
            title="Go to project"
            style={{
              fontWeight: 600,
              paddingBottom: 5,
              color: "#877457",
              cursor: "pointer"
            }}
            onClick={() => {
              handleRedirection &&
                handleRedirection({
                  redirection_type: "project",
                  project,
                  business: project.business
                });
            }}
          >
            {project && project.name}
          </div>
          <i
            className="fa fa-circle fs-6"
            style={{
              padding: "0px 8px 5px",
              color: "#877457",
              display: "flex",
              alignItems: "center"
            }}
          />
          <div
            style={{
              fontWeight: 600,
              paddingBottom: 5,
              color: "#877457",
              cursor: "pointer"
            }}
            title="Go to workspace"
            onClick={() =>
              handleRedirection &&
              handleRedirection({
                redirection_type: "business",
                project: project,
                business: project.business
              })
            }
          >
            {project.business && project.business.title}
          </div>
        </div>
        {task &&
          task?.length &&
          task.map((data, i) => (
            <SingleTaskList
              task={data}
              i={i}
              todoLength={task?.length || 0}
              handleRedirection={handleRedirection}
              doToggleTaskStatus={toggleTaskStatus}
            />
          ))}
      </div>
    </div>
  );
};

export default ProjecttList;
