import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import AddSectionCard from "components/projects/sections/AddSectionCard";
import RoyTooltip from "components/common/RoyTooltip";
import BodyWrapper from "./pages/wrapper/BodyWrapper.style";
import TodoList from "components/TodoListview/TodoList";
import CustomAlert from "util/CustomAlert";
import moment from "moment";
import EditList from "components/scrumboard/EditList";
import { threeDot_Icon } from "helper/constant";
import ProjectSideMenu from "components/projects/Menus/ProjectSideMenu";
import TodolistWrapper from "components/TodoListview/todolistWrapper.style";

import {
  miniDrawerWidth,
  themeSettingDrawerWidth,
  CardTaskCalenderImage
} from "helper/constant";

import {
  updateProject,
  getProjectById,
  deleteProject,
  removeCollaborators
} from "helper/services/projectServices";
import CustomNotification from "util/CustomNotification";
import ShareModal from "components/collaborators/ShareModal";
import EditProject from "components/projects/EditProject";
import Notifications from "react-notify-toast";
import MoreOptionsMenu from "components/projects/Menus/MoreOptions";
import SectionHeaderMenu from "components/projects/Menus/SectionHeaderMenu";
import projectActions from "redux/projects/actions";
import CommentModal from "components/projects/comments/CommentModal";
import BoardCard from "components/scrumboard/BoardCard";
import ScrumboardWrapper from "components/scrumboard/scrumboard.style";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import BoardCardAdder from "components/scrumboard/BoardCardAdder";
import Shimmer from "components/shimmer/Shimmer";
import { grid } from "helper/constant";
import { commentnew, invite } from "helper/constant";
import {
  projectInit,
  joinRoom,
  leaveRoom,
  disconnect,
  deleteSection,
  addSection,
  updateTask,
  moveTask,
  updateSection,
  moveSection,
  archiveSection,
  duplicateSection,
  archiveTask,
  deleteTask,
  reorderTask,
  reorderSection
} from "services/socket";
import Member from "components/scrumboard/Member";
import { Row } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import Loader from "components/loader/Loader";
import alertActions from "redux/globalAlert/actions";
import profileModelAction from "redux/profileModel/actions";
import updateProjectActions from "redux/projectAction/actions";

import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import { projectSocketRoomPrefix } from "constatnt";
import { toggleArchive } from "helper/services/businessServices";
import authActions from "redux/auth/actions";
import { checkIsArchivedMessage } from "helper/methods/workspacestatus";
import MoveListProject from "components/projects/sections/MoveListProject";
import ProjectDetailsPopover from "./ProjectDetailsPopover";
import IntlMessages from "util/intlMessages";

const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const { open_profile_modal } = profileModelAction;
const {
  update_projects_existing,
  remove_Project,
  update_recent_Project
} = projectActions;
const { update_business_archive } = authActions;
const { do_update_project, close_update_project } = updateProjectActions;
const ProjectGrid = props => {
  const TASKSTATUS = {
    TASKFOUND: "Found",
    TASKNOTFOUND: "Not Found",
    NODATAPROJECT: "No Data in project"
  };

  const newtaskEditorRef = useRef(null);
  const { addToast } = useToasts();
  const [addSectionFlag, setSectionCardFlag] = useState(false);
  const [isLoader, SetIsLoader] = useState(false);
  const [isViewLoader, setIsViewLoader] = useState(false);
  const [isArchivedLoader, setIsArchivedLoader] = useState(false);
  const [defaultView, SetDefaultView] = useState("List");
  const [project, setProject] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [istaskinput, setIsTaskinput] = useState({ status: false, id: null });
  const [commentModal, setCommentModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [editProjectModal, setEditProjectModal] = useState(false);

  const [syncGoogle, setSyncGoogle] = useState(false);
  const [isGoogleCalenderData, setIsGoogleCalenderData] = useState(false);
  const [moreProjectOptions, setMoreProjectOptions] = useState(false);

  const [isProjectDetails, setIsProjectDetails] = useState(false);
  const [isBoardCardscroll, setIsBoardCardscroll] = useState(false);

  const [sectionmenu, setSectionmenu] = useState(false);
  const [sectionmenuID, setSectionmenuID] = useState(null);
  const [editSectionToggle, SetEditSectionToggle] = useState(false);
  const [sectionEdiId, setSectionEditId] = useState(null);
  const [
    deleteListTitleNameListView,
    setDeleteListTitleNameListView
  ] = useState();
  const [showDeleteProjectAlert, ToggleshowDeleteProjectAlert] = useState(
    false
  );
  const [showDeleteLabelAlert, ToggleshowDeleteLabelAlert] = useState(false);

  const [isLeaveProject, setIsLeaveProject] = useState(false);
  const [editSectionFlag, SetEditSectionFlag] = useState(false);
  const [isUpdateSectionLoading, setIsUpdateSectionLoading] = useState(false);
  const [editSectionData, SetEditSectionData] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [isMoveListModal, setIsMoveListModal] = useState({
    status: false,
    id: ""
  });
  const [isDeleteArchiveCustomAlert, setIsDeleteArchiveCustomAlert] = useState({
    status: false,
    id: ""
  });

  const [toastMessage, setToastMessage] = useState(null);
  const [toast, setToast] = useState(false);

  const [projectSideMenu, setProjectSideMenu] = useState(true);

  const [stateminiDrawerWidth, setStateminiDrawerWidth] = useState(
    miniDrawerWidth
  );

  const [isBoardCardSectionClick, setIsBoardCardSectionClick] = useState(false);
  const [businessId, SetBusinessId] = useState(null);
  // const [profilemodel, Setprofilemodel] = useState(null);
  const [reqTaskId, setRequestTaskId] = useState({ status: false, id: "" });
  const [delectAction, setDeleteAction] = useState();
  const { id } = props.match.params;

  const projectRef = React.useRef(project);
  React.useEffect(() => {
    // Setprofilemodel(props.profileModelAction.isOpen);
    // if (profilemodel === true) {
    // 	props.close_profile_modal()
    // }
    projectRef.current = project;
  });
  React.useEffect(() => {
    if (props.updateProjectActions?.status && id) {
      fetchProjects(false);
      props.close_update_project();
    }

    if (props.language.languageId == "german") {
      setDeleteAction("Löschliste");
    } else if (props.language.languageId == "dutch") {
      setDeleteAction("verwijder-lijst");
    } else {
      setDeleteAction("delete -list");
    }
  });

  // -------scroll horizontal-------
  // const handleScroll = event => {
  //   const container = event.target;
  //   const scrollAmount = event.deltaY;
  //   // newtaskEditorRef.current.clientHeight
  //   container.scrollTo({
  //     top: 0,
  //     left: container.scrollLeft + scrollAmount,
  //     behavior: "smooth"
  //   });
  // };
  // @params {reqtaskId} getting task id to open task dialog from url
  const reqTaskIdparams = new URLSearchParams(props.history.location?.search);
  const reqtaskId = reqTaskIdparams.get("tId");
  useEffect(() => {
    if (id) {
      props.update_recent_Project({
        project_id: id
      });
      fetchProjects(true);

      // Initialize socket connection
      projectInit(
        addSectionCB,
        updateSectionCB,
        deleteSectionCB,
        addTaskCB,
        updateTaskCB,
        deleteTaskCB,
        moveTaskCB,
        reorderTaskCB,
        reorderSectionCB,
        addCollaboratorCB,
        moveSectionCB,
        archiveSectionCB,
        duplicateSectionCB,
        archiveTaskCB,
        props.auth.accessToken
      );
      // getBusinessId();
      // Handle socket room
      handleSocketRoom();
    }
    return () => {
      setCommentModal(false);
      setIsDeleteArchiveCustomAlert({ status: false, id: "" });
      setProject(null);
      setSectionmenuID(null);
      setIsOwner(false);
      // leave room on cleanup
      leaveRoom(`${projectSocketRoomPrefix}${id}`);
      setRequestTaskId({ status: false, id: "" });
      // disconnect socket on cleanup
      setIsArchived(false);
      disconnect();
      setProjectSideMenu(true);
      SetDefaultView("List");
    };
  }, [id, reqtaskId]);

  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        // status:action.alert.status, message:action.alert.message,action:'open_businesslist'

        props.open_workpsace_alert({
          status: true,
          message: "workspace"
        });
      }
      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
        props.remove_Project(id);
      }
      if (
        message.toLocaleLowerCase() == "Project not found".toLocaleLowerCase()
      ) {
        props.open_project_alert({
          status: true,
          message: "project"
        });
        props.remove_Project(id);
      }
    } catch (e) {}
  };
  const doArchiveTask = async (taskId, taskData) => {
    try {
      if (taskId) {
        let payload = {
          roomname: `${projectSocketRoomPrefix}${id}`,
          task_id: taskId.toString()
        };
        archiveTask(payload, cb => {
          if (cb.status == "Ok") {
            showNotifications(cb.message, "success");
          } else {
            if (cb.status || cb.message) {
              handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            showNotifications();
          }
        });
      }
    } catch (e) {}
  };
  const doDeleteTask = async taskId => {
    try {
      let payload = {
        project_id: id.toString(),
        isDeleted: true,
        task_id: taskId.toString(),
        roomname: `${projectSocketRoomPrefix}${id}`
      };
      deleteTask(payload, cb => {
        if (cb.status == "Ok") {
          setIsDeleteArchiveCustomAlert({ status: false, id: "" });
        } else {
          setIsDeleteArchiveCustomAlert({ status: false, id: "" });
          if (cb.status || cb.message) {
            handleResponse(cb.message);
          }
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
          showNotifications();
        }
      });
    } catch (e) {
      setIsDeleteArchiveCustomAlert({ status: false, id: "" });
    }
  };
  const addSectionCB = data => {
    if (data) {
      let projectData = projectRef.current;
      if (projectData) {
        projectData.section.push(data);
        setProject({ ...projectData });
      }
    }
  };

  //  get business id from redux default workspace
  const getBusinessId = async () => {
    var projects = props?.projects?.projects;
    var data = await projects.filter(data => data?.id === parseInt(id));
    if (data && data[0] && data[0]?.business?.id) {
      SetBusinessId(data[0].business.id);
    } else {
      SetBusinessId(props?.business?.business?.id);
    }
  };
  const updateSectionCB = async data => {
    if (data) {
      let projectData = projectRef.current;
      if (projectData) {
        await Promise.all(
          projectData.section.map((s, i) => {
            if (s.id === data.id) {
              projectData.section[i] = {
                ...projectData.section[i],
                ...data
              };
              projectData.section.sort((a, b) => {
                return parseInt(a?.orderId) - parseInt(b?.orderId);
              });
            }
          })
        );
        setProject({ ...projectData });
      }
    }
  };
  const moveSectionCB = async data => {
    if (data) {
      try {
        let projectData = projectRef.current;
        let isPresent = false;
        if (projectData) {
          await Promise.all(
            projectData.section.map((s, i) => {
              if (s?.id === data?.id) {
                projectData.section.splice(i, 1);
                isPresent = true;

                projectData.section.sort((a, b) => {
                  return parseInt(a?.orderId) - parseInt(b?.orderId);
                });
              }
            })
          );

          if (!isPresent) {
            projectData.section.push(data);
          }
          setProject({ ...projectData });
        }
      } catch (e) {}
    }
  };
  const archiveSectionCB = async data => {
    if (data) {
      let projectData = projectRef.current;
      if (projectData) {
        await Promise.all(
          projectData.section.map((s, i) => {
            if (s.id === data.id) {
              projectData.section[i] = {
                ...projectData.section[i],
                ...data
              };
              projectData.section.sort((a, b) => {
                return parseInt(a?.orderId) - parseInt(b?.orderId);
              });
            }
          })
        );
        setProject({ ...projectData });
      }
    }
  };
  const duplicateSectionCB = async data => {
    if (data) {
      let projectData = projectRef.current;
      if (projectData) {
        projectData.section.push(data);
        projectData.section.sort((a, b) => {
          return parseInt(a?.orderId) - parseInt(b?.orderId);
        });
        setProject({ ...projectData });
      }
    }
  };
  const themeSettingDrawer = () => {
    if (projectSideMenu) {
      setProjectSideMenu(false);
    } else {
      setProjectSideMenu(true);
    }
  };
  const deleteSectionCB = async data => {
    let projectData = projectRef.current;
    if (projectData) {
      let sectionIndex = null;
      await Promise.all(
        projectData.section.map((s, i) => {
          if (s.id == data.id) {
            sectionIndex = i;
          }
          if (data.orderId < s.orderId) {
            projectData.section[i].orderId = s.orderId - 1;
          }
        })
      );
      await Promise.all(
        projectData.section.map((s, i) => {
          if (s.id == data.id) {
            projectData.section.splice(i, 1);
          }
        })
      );
      setProject({ ...projectData });
    }
  };
  // const deleteSectionCBs = async data => {
  //   if (data) {
  //     let projectData = projectRef.current;
  //     if (projectData) {
  //       await Promise.all(
  //         projectData.section.map((s, i) => {
  //           if (s.id === data.id) {
  //             projectData.section.splice(i, 1);
  //             projectData.section.sort((a, b) => {
  //               return parseInt(a?.orderId) - parseInt(b?.orderId);
  //             });
  //             setProject({ ...projectData });
  //           }
  //         })
  //       );
  //     }
  //   }
  // };
  // socket for just to emit cb for to add members and other actions in sharemdoal.jsx after api response
  const addCollaboratorCB = async data => {
    // fetchProjects(false);
  };
  const addTaskCB = async data => {
    try {
      let projectData = projectRef.current;
      if (data) {
        if (projectData) {
          projectData.section = await Promise.all(
            projectData.section.map(async (s, i) => {
              if (data.section) {
                if (s.id == data.section.id) {
                  let array = [...s.task];
                  let isUpdated = false;
                  for (let index = 0; index < array.length; index++) {
                    if (
                      array[index]?.tempId &&
                      array[index]?.tempId == data.tempId
                    ) {
                      array[index] = data;
                      array[index].id = data.id;
                      isUpdated = true;
                    }
                  }
                  if (isUpdated == false) {
                    array.push(data);
                  }
                  return { ...s, task: array };
                }
              } else {
                if (!s.id) {
                  // s.task.push(data);
                }
              }
              return s;
            })
          );
          setIsBoardCardscroll(true);
          setProject({ ...projectData });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateLabelToParent = async updatedData => {
    try {
      if (updatedData && updatedData.id) {
        let projectData = projectRef.current;
        await Promise.all(
          projectData.section.map((sectionMap, sectionIndex) => {
            sectionMap &&
              sectionMap.task &&
              sectionMap.task.map((taskMap, taskIndex) => {
                taskMap &&
                  taskMap.labels &&
                  taskMap.labels.map((labelMap, labelIndex) => {
                    if (labelMap.id == updatedData.id) {
                      projectData.section[sectionIndex].task[taskIndex].labels[
                        labelIndex
                      ] = {
                        ...labelMap,
                        ...updatedData
                      };
                    }
                  });
              });
          })
        );
        setProject({ ...projectData });
      }
    } catch (e) {}
  };
  const updateTaskCB = async data => {
    let projectData = projectRef.current;

    if (projectData) {
      await Promise.all(
        projectData.section.map((s, i) => {
          return s.task.map((t, j) => {
            if (t.id == data.id) {
              projectData.section[i].task[j] = {
                ...projectData.section[i].task[j],
                ...data
              };
            }
          });
        })
      );
      setProject({ ...projectData });
    }
  };
  const archiveTaskCB = async data => {
    let projectData = projectRef.current;
    if (projectData) {
      let sectionIndex = null;
      await Promise.all(
        projectData.section.map((s, i) => {
          return s.task.map((t, j) => {
            if (t.id == data.id) {
              projectData.section[i].task[j] = {
                ...projectData.section[i].task[j],
                orderId: data.isArchived ? t.orderId : data.orderId,
                isArchived: data.isArchived
              };
              sectionIndex = i;
            }
            // changing order of tasks after delete which comes after archived task
            if (i == sectionIndex && data.isArchived) {
              if (data.orderId < t.orderId) {
                projectData.section[sectionIndex].task[j].orderId =
                  t.orderId - 1;
              }
            }
          });
        })
      );
      setProject({ ...projectData });
    }
  };
  const deleteTaskCB = async data => {
    let projectData = projectRef.current;
    if (projectData) {
      let sectionIndex = null;
      await Promise.all(
        projectData.section.map((s, i) => {
          return s.task.map((t, j) => {
            if (t.id == data.id) {
              sectionIndex = i;
            }
            // changing order of tasks after delete which comes after dleted task
            if (i == sectionIndex) {
              if (data.orderId < t.orderId) {
                projectData.section[sectionIndex].task[j].orderId =
                  t.orderId - 1;
              }
            }
          });
        })
      );
      await Promise.all(
        projectData.section.map((s, i) => {
          return s.task.map((t, j) => {
            if (t.id == data.id) {
              s.task.splice(j, 1);
            }
          });
        })
      );
      setProject({ ...projectData });
    }
  };
  // const deleteTaskCB = async data => {
  //   let projectData = projectRef.current;
  //   if (projectData) {
  //     await Promise.all(
  //       projectData.section.map((s, i) => {
  //         return s.task.map((t, j) => {
  //           if (t.id == data.id) {
  //             projectData.section[i].task.splice(j, 1);
  //           }
  //         });
  //       })
  //     );
  //     setProject({ ...projectData });
  //   }
  // };
  const moveTaskCB = async data => {
    // let projectObj = projectRef.current;
    // console.log(projectObj, "projectObj");
    // let obj;
    // if (projectObj) {
    //   await Promise.all(
    //     projectObj.section.map((s, i) => {
    //       return s.task.map((t, j) => {
    //         console.log(t, "t");
    //         console.log(j, "j");
    //         if (t.id == data.id) {
    //           obj = t;
    //           s.task.splice(j, 1);
    //         }
    //       });
    //     })
    //   );

    //   await Promise.all(
    //     projectObj.section.map((s, i) => {
    //       console.log(s, "s");
    //       console.log(i, "i");
    //       if (data.section) {
    //         console.log(data, "data");
    //         if (s.id == data.section.id) {
    //           s.task.push(obj);
    //         }
    //       } else {
    //         if (s.id == undefined) {
    //           s.task.push(obj);
    //         }
    //       }
    //     })
    //   );

    //   setProject({ ...projectObj });
    // }
    if (data?.section) {
      doMoveTask(data?.section?.id, data?.id, data?.orderId, false);
    }
  };
  const reorderTaskCB = async data => {
    if (data?.section) {
      doReOrderTask(data?.section?.id, data?.id, data?.orderId, false);
    }
  };
  const reorderSectionCB = async data => {
    console.log(data, "data");
    if (data) {
      doReOrderSection(data?.section?.id, data?.id, data?.orderId, false);
    }
  };
  const handleSocketRoom = () => {
    // Join room
    joinRoom(`${projectSocketRoomPrefix}${id}`);
  };
  const removeCollaboratorById = id => {
    var projects = project;

    var check = projects.collaborator.filter(
      collaborator => collaborator.id !== id
    );
    setProject({ ...project, collaborator: check });
  };
  const pushCollaborator = data => {
    var projectData = project;
    projectData.collaborator.push(data[0]);
    setProject(projectData);
  };
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const fetchProjects = async (isLoader = true) => {
    SetIsLoader(isLoader);
    const Response = await getProjectById(id);
    if (Response) {
      if (Response.error) {
        if (Response.error.message == "Project not found") {
          // props.history.push("/inbox");
          props.history.push("/today");
        }
        handleResponse(Response.error.message);
        showNotifications();
        SetIsLoader(false);
      }
      if (Response.data) {
        Response?.data?.business?.id
          ? SetBusinessId(Response?.data?.business?.id)
          : getBusinessId();
        Response.data.section.sort((a, b) => {
          return parseInt(a?.orderId) - parseInt(b?.orderId);
        });
        setIsArchived(
          Response.data?.isArchived ? Response.data?.isArchived : false
        );
        let projectObj = Response.data;
        projectObj.section.sort((a, b) =>
          a?.orderId > b?.orderId ? 1 : b?.orderId > a?.orderId ? -1 : 0
        );
        checkIsowner(Response?.data?.collaborator);

        setProject({ ...projectObj });
        SetDefaultView(Response.data.defaultView == "grid" ? "Grid" : "List");
        SetIsLoader(false);
        if (parseInt(reqtaskId)) {
          setRequestTaskId({ status: true, id: parseInt(reqtaskId) });

          let taskStatus = await isTaskAccessible(parseInt(reqtaskId), {
            ...projectObj
          });

          if (taskStatus) {
            if (taskStatus.status == TASKSTATUS.TASKNOTFOUND) {
              showNotifications("Task not found", "error");
              handleUrlUpdate();
            } else if (
              (taskStatus.status =
                TASKSTATUS.TASKFOUND && taskStatus.isAccessible == false)
            ) {
              // task is not accessible
              showNotifications("Task is not accessible", "error");
              handleUrlUpdate();
            } else {
              if ((taskStatus.status = TASKSTATUS.NODATAPROJECT)) {
                // Something went wrong
              }
            }
          }
        }
      }
      if (
        props.history.location?.state &&
        props.history.location?.state?.commentModal
      ) {
        setCommentModal(true);
      }
    }
  };
  const handleUrlUpdate = () => {
    if (props.history && props.history?.location?.search) {
      const reqTaskIdparams = new URLSearchParams(
        props.history.location?.search
      );
      const reqtaskIds = reqTaskIdparams.get("tId");
      if (reqtaskIds) {
        props.history.replace(`/project/${id}`);
      }
    }
  };
  const isTaskAccessible = async (taskId, projectData = []) => {
    let projectArr = projectData ? projectData : project;
    if (projectArr && id) {
      let TASKFOUNDStatus,
        isAccessible = false;
      await Promise.all(
        projectArr.section.map((s, i) => {
          return s.task.map((task, j) => {
            if (task.id == taskId) {
              TASKFOUNDStatus = true;
              if (!task.isDeleted && !task.isArchived) {
                isAccessible = true;
              }
            }
          });
        })
      );
      return {
        status: TASKFOUNDStatus
          ? TASKSTATUS.TASKFOUND
          : TASKSTATUS.TASKNOTFOUND,
        isAccessible: isAccessible
      };
    }
    return {
      status: TASKSTATUS.NODATAPROJECT,
      isAccessible: false
    };
  };
  const checkIsowner = collaborator => {
    let status = false;

    if (collaborator) {
      collaborator &&
        collaborator.map((data, index) => {
          if (
            data.role.toLowerCase() == "owner" &&
            data.user.id === props.auth.user.id
          ) {
            status = true;
          }
        });
    }
    setIsOwner(status);
    return status;
  };
  const doArchiveSection = async data => {
    if (data.id) {
      let payload = {
        section_id: data.id.toString(),
        roomname: `${projectSocketRoomPrefix}${project.id}`
      };
      archiveSection(payload, cb => {
        if (cb.status == "Ok") {
          showNotifications(
            <>
              <IntlMessages id={"project.list"} />{" "}
              {data?.isArchived ? (
                <IntlMessages id={"project.unarchived"} />
              ) : (
                <IntlMessages id={"project.archived"} />
              )}{" "}
              <IntlMessages id={"task-details.successfully"} />
            </>,

            "success"
          );
          setSectionmenu(false);
          setSectionmenuID(null);
          SetEditSectionData("");
          SetEditSectionFlag(false);
        } else {
          console.log(payload, cb);
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
          showNotifications();
        }
      });
    }
  };
  const doDuplicateSection = async data => {
    if (data.id) {
      let payload = {
        section_id: data.id.toString(),
        roomname: `${projectSocketRoomPrefix}${project.id}`
      };

      duplicateSection(payload, cb => {
        if (cb.status == "Ok") {
          setSectionmenu(false);
          setSectionmenuID(null);
          SetEditSectionData("");
          SetEditSectionFlag(false);
        } else {
          console.log(payload, cb);
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
          showNotifications();
        }
      });
    }
  };
  const doUpdateSection = async data => {
    try {
      if (data !== "" && data.title && data.id) {
        let payload = {
          title: data.title,
          section_id: data.id.toString(),
          orderId: data?.orderId,
          project_id: project && project.id.toString(),
          roomname: `${projectSocketRoomPrefix}${project.id}`
        };
        updateSection(payload, cb => {
          if (cb.status == "Ok") {
            setSectionmenu(false);
            setSectionmenuID(null);
            SetEditSectionData("");
            SetEditSectionFlag(false);
          } else {
            console.log(payload, cb);
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
            showNotifications();
          }
        });
      }
    } catch (e) {}
  };
  const doMoveSection = async data => {
    try {
      if (data.sectionId && data.projectId) {
        let payload = {
          section_id: data?.sectionId,
          project_id: data?.projectId,
          roomname: `${projectSocketRoomPrefix}${project.id}`
        };

        moveSection(payload, cb => {
          if (cb.status == "Ok") {
            setSectionmenu(false);
            setSectionmenuID(null);
            SetEditSectionData("");
            SetEditSectionFlag(false);
          } else {
            console.log(payload, cb);
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
            showNotifications();
          }
        });
      }
    } catch (e) {}
  };
  const handleDeleteProject = async () => {
    if (project) {
      try {
        const response = await deleteProject(project.id);
        if (response) {
          if (response.error) {
            handleResponse(response.error.message);
            setToast(true);
            setToastMessage(
              <IntlMessages id={"task-details.something_went_wrong"} />
            );
            setTimeout(() => {
              setToast(false);
              setToastMessage("");
            }, 2500);
          }
          if (response.data) {
            props.history.push("/today");
          }
        }
      } catch (e) {}
    }
  };
  const updateProjectToRedux = data => {
    props.update_projects_existing({ projects: data });
  };
  const doDeleteSection = async () => {
    try {
      let payload = {
        title: editSectionData?.title,
        project_id: project && project?.id,
        orderId: editSectionData?.orderId,
        isDeleted: true,
        section_id: editSectionData.id.toString(),
        roomname: project && `${projectSocketRoomPrefix}${project.id}`
      };
      deleteSection(payload, cb => {
        if (cb.status == "Ok") {
        } else {
          console.log(cb);
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
          showNotifications();
        }
      });
    } catch (e) {}
  };
  const getCollaboratorsUserId = () => {
    var collabId = null;
    if (project) {
      project.collaborator.map((data, i) => {
        if (data.user.id == props.auth.user.id) {
          collabId = data.id;
        }
      });
    }
    return collabId;
  };
  const leaveProject = async () => {
    var collabId = getCollaboratorsUserId();
    if (collabId) {
      try {
        const Response = await removeCollaborators({
          collaboration_id: collabId
        });
        if (Response) {
          if (Response.error) {
            handleResponse(Response.error.message);
            showNotifications();
            ToggleshowDeleteLabelAlert(false);
            setIsLeaveProject(false);
          }
          if (Response.message) {
            showNotifications("You  left project");
            props.remove_Project(id);
            // props.history.push("/dashboard");
            props.history.push("/today");
            setIsLeaveProject(false);
            ToggleshowDeleteLabelAlert(false);
          }
        }
      } catch (error) {
        showNotifications();
        setIsLeaveProject(false);
        ToggleshowDeleteLabelAlert(false);
      }
    }
  };
  const updateViewOptions = async () => {
    try {
      if (project && project.id) {
        const view = project.defaultView == "list" ? "grid" : "list";
        SetDefaultView(defaultView == "Grid" ? "List" : "Grid");
        // setIsViewLoader(true);
        doMoveSection();
        const Response = await updateProject(
          {
            defaultView: view
          },
          project.id
        );
        if (Response) {
          if (Response.error) {
            handleResponse(Response.error.message);
            setToast(true);
            setToastMessage(
              <IntlMessages id={"task-details.something_went_wrong"} />
            );
            setTimeout(() => {
              setToast(false);
              setToastMessage("");
            }, 2500);
          }
          setIsViewLoader(false);
          if (Response.data) {
            var projectData = project;
            projectData.defaultView = Response.data.defaultView;
            setProject(projectData);
            SetDefaultView(
              Response.data.defaultView == "grid" ? "Grid" : "List"
            );
          }
          setIsViewLoader(false);
        }
      }
    } catch (e) {
      setIsViewLoader(false);
    }
  };
  const updateFavorite = async () => {
    try {
      if (project && project.id) {
        let isFavorite = project.isFavorite;
        setIsViewLoader(true);
        const Response = await updateProject(
          {
            isFavorite: !isFavorite
          },
          project.id
        );
        if (Response) {
          if (Response.error) {
            handleResponse(Response.error.message);
            setToast(true);
            showNotifications(
              <IntlMessages id={"task-details.something_went_wrong"} />
            );
            setTimeout(() => {
              setToast(false);
              setToastMessage("");
            }, 2500);
          }
          setIsViewLoader(false);
          if (Response.data) {
            var projectData = {
              ...project,
              isFavorite: Response?.data?.isFavorite
            };
            showNotifications(
              Response?.data?.isFavorite
                ? "Project added to favorites"
                : "Project removed from favorites",
              "success"
            );

            setProject(projectData);
            updateProjectToRedux(Response.data);
          }
          setIsViewLoader(false);
        }
      }
    } catch (e) {
      setIsViewLoader(false);
    }
  };
  const updateArchiveProject = async () => {
    try {
      if (project && project.id) {
        setIsArchivedLoader(true);
        const Response = await updateProject(
          {
            isArchived: !isArchived
          },
          project.id
        );
        if (Response) {
          if (Response.error) {
            handleResponse(Response.error.message);
            showNotifications();
          }

          if (Response.data) {
            var projectData = {
              ...project,
              isArchived: Response?.data?.isArchived
            };

            setIsArchived(Response?.data?.isArchived);
            setProject(projectData);
            updateProjectToRedux(projectData);
            showNotifications(
              <>
                <IntlMessages id={"Project"} />
                &nbsp;
                {Response?.data?.isArchived ? (
                  <IntlMessages id={"project.archived"} />
                ) : (
                  <IntlMessages id={"project.unarchive"} />
                )}{" "}
                <IntlMessages id={"task-details.successfully"} />
              </>,

              "success"
            );
          }
          setIsArchivedLoader(false);
        }
      }
    } catch (e) {
      setIsArchivedLoader(false);
    }
  };
  const updateIsCompleted = async (sectionId, todoId, checked, data) => {
    try {
      let payload = {
        project_id: project && project.id.toString(),
        section_id: sectionId ? sectionId.toString() : "",
        isCompleted: checked,
        roomname: project && `${projectSocketRoomPrefix}${project.id}`,
        task_id: todoId.toString()
      };
      if (data) {
        if (data.scheduledAt) {
          payload = { ...payload, scheduledAt: data.scheduledAt };
        }
      }
      if (data) {
        if (data.reminderAt) {
          payload = { ...payload, reminderAt: data.scheduledAt };
        }
      }
      updateTask(payload, cb => {
        if (cb.status == "Ok") {
        } else {
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
          showNotifications();
        }
      });
    } catch (e) {}
  };
  // Board function start
  // const onDragEnd = async result => {
  //   try {
  //     if (result.type == "task") {
  //       let currentIndex = result.source.index;
  //       let newIndex = result.destination?.index;
  //       if (
  //         result.destination &&
  //         result.destination !== null &&
  //         result.destination !== undefined
  //       ) {
  //         if (result.destination.droppableId !== result.source.droppableId) {
  //           doMoveTask(
  //             result.destination.droppableId == 9999999999
  //               ? ""
  //               : result.destination.droppableId,
  //             result.draggableId,
  //             result.destination.index
  //           );
  //         } else {
  //           if (currentIndex !== newIndex) {
  //             doReOrderTask(
  //               result.destination.droppableId,
  //               result.draggableId,
  //               result.destination.index
  //             );
  //           }
  //         }
  //       }
  //     } else if (result.type == "card") {
  //       let currentIndex = result.source.index;
  //       let newIndex = result.destination?.index;
  //       if (
  //         result.destination &&
  //         result.destination !== null &&
  //         result.destination !== undefined
  //       ) {
  //         if (currentIndex !== newIndex) {
  //           doReOrderSection(
  //             result.destination.droppableId,
  //             result.draggableId,
  //             result.destination.index
  //           );
  //         }
  //       }
  //     } else {
  //       if (result.destination) {
  //         if (result.destination.droppableId != result.source.droppableId) {
  //           // doMoveTask(result.destination.droppableId, result.draggableId)
  //         }
  //       }
  //     }
  //   } catch (e) {}
  // };

  const onDragEnd = async result => {
    try {
      if (result.type == "task") {
        let currentIndex = result.source.index;
        let newIndex = result.destination?.index;
        if (
          result.destination &&
          result.destination !== null &&
          result.destination !== undefined
        ) {
          if (result.destination.droppableId !== result.source.droppableId) {
            await doMoveTask(
              result.destination.droppableId == 9999999999
                ? ""
                : result.destination.droppableId,
              result.draggableId,
              result.destination.index
            );
          } else {
            if (currentIndex !== newIndex) {
              await doReOrderTask(
                result.destination.droppableId,
                result.draggableId,
                result.destination.index
              );
            }
          }
        }
      } else if (result.type == "card") {
        let currentIndex = result.source.index;
        let newIndex = result.destination?.index;
        if (
          result.destination &&
          result.destination !== null &&
          result.destination !== undefined
        ) {
          if (currentIndex !== newIndex) {
            await doReOrderSection(
              result.destination.droppableId,
              result.draggableId,
              result.destination.index
            );
          }
        }
      } else {
        if (result.destination) {
          if (result.destination.droppableId != result.source.droppableId) {
            // await doMoveTask(result.destination.droppableId, result.draggableId)
          }
        }
      }
    } catch (e) {
      // Handle errors here
      console.error(e);
    }
  };

  const isMoveListAction = () => {
    let status = false;
    if (props.projects?.projects && props.projects.projects.length > 1) {
      var projectFilter =
        props.projects?.projects &&
        props.projects?.projects.filter(
          data =>
            !data.isDeleted && !data.isArchived && data.id !== parseInt(id)
        );

      if (projectFilter && projectFilter.length > 0) {
        status = true;
      }
    }
    return status;
  };
  const addNewCard = async title => {
    try {
      //Socket add section
      let payload = {
        title: title,
        orderId: project && project.section.length + 1,
        projectId: project && project.id,
        roomname: project && `${projectSocketRoomPrefix}${project.id}`
      };
      addSection(payload, cb => {
        if (cb.status == "Ok") {
        } else {
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
          if (cb.status || cb.message) {
            handleResponse(cb.message);
          }
          showNotifications();
        }
      });
    } catch (e) {}
  };
  const doMoveTask = async (sectionId, taskId, newIndex, isIntiator = true) => {
    try {
      let projectObj = project ?? projectRef?.current;
      let obj;
      let sectionIndex = null,
        oldSectionIndex = null;

      let splicedTaskOrderId = null,
        taskIndex = null;
      await Promise.all(
        projectObj.section.map((s, i) => {
          if (s.id == sectionId) {
            sectionIndex = i;
          }
          return s.task.map((t, j) => {
            if (t.id == taskId) {
              obj = t;
              oldSectionIndex = i;
              taskIndex = j;
              splicedTaskOrderId = t.orderId;
            }
            // decresing index by 1 for tasks which are below moved task in old section
            if (
              oldSectionIndex == i &&
              splicedTaskOrderId &&
              splicedTaskOrderId < t.orderId
            ) {
              s.task[j].orderId = t.orderId - 1;
            }
          });
        })
      );
      // then removing old task
      if (oldSectionIndex !== null && taskIndex !== null) {
        projectObj.section[oldSectionIndex].task.splice(taskIndex, 1);
      }
      let beforeOrderTask = [],
        afterOrderTask = [];
      // in  moved new section getting task before moved order id
      projectObj.section[sectionIndex].task.map((data, i) => {
        if (isIntiator) {
          if (newIndex + 1 > data.orderId) {
            beforeOrderTask.push(data);
          }
        } else {
          if (newIndex > data.orderId) {
            beforeOrderTask.push(data);
          }
        }
        // in  moved new section getting task after moved order id
        if (isIntiator) {
          if (newIndex + 1 <= data.orderId) {
            afterOrderTask.push(data);
          }
        } else {
          if (newIndex <= data.orderId) {
            afterOrderTask.push(data);
          }
        }
      });
      let updatedAfterOrderTask = [...afterOrderTask];
      // after task orderid increased by 1
      afterOrderTask &&
        afterOrderTask.map(
          (data, i) =>
            (updatedAfterOrderTask[i].orderId =
              updatedAfterOrderTask[i].orderId + 1)
        );

      projectObj.section[sectionIndex].task = [
        ...beforeOrderTask,
        ...afterOrderTask
      ];
      projectObj.section[sectionIndex].task.push({
        ...obj,
        orderId: isIntiator ? newIndex + 1 : newIndex
      });
      // await Promise.all(
      //   projectObj.section.map(async (s, i) => {
      //     if (sectionId != "") {
      //       if (s.id == sectionId) {
      //         s.task.push(obj);
      //       }
      //     } else {
      //       if (s.id == undefined) {
      //         s.task.push(obj);
      //       }
      //     }

      // update card index
      // let tasks = s.task.filter(task => {
      //   if (task.orderId >= newIndex + 1) {
      //     return task;
      //   }
      // });

      // await Promise.all(
      //   tasks.map(item => {
      //     item.orderId = item.orderId + 1;
      //   })
      // );
      //   })
      // );

      setProject({ ...projectObj });
      if (isIntiator) {
        let payload = {
          project_id: project && project.id.toString(),
          section_id: sectionId.toString(),
          roomname: project && `${projectSocketRoomPrefix}${project.id}`,
          task_id: taskId.toString(),
          orderIndex: newIndex + 1
        };
        moveTask(payload, cb => {
          if (cb.status == "Ok") {
            console.log("ok");
          } else {
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
            showNotifications();
          }
        });
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
  const doReOrderSection = async (
    sectionId,
    taskId,
    newIndex,
    isIntiator = true
  ) => {
    newIndex = isIntiator ? newIndex + 1 : newIndex;
    try {
      let projectObj = isIntiator ? project : projectRef.current;
      let obj;
      await Promise.all(
        projectObj.section.map(async (s, i) => {
          if (s.id == taskId) {
            if (s.orderId > newIndex) {
              let projects;
              if (isIntiator) {
                projects = project.section.filter(project => {
                  if (
                    project.orderId < s.orderId &&
                    project.orderId >= newIndex
                  ) {
                    return project;
                  }
                });
              } else {
                projects = projectRef?.current?.section.filter(project => {
                  if (
                    project.orderId < s.orderId &&
                    project.orderId >= newIndex
                  ) {
                    return project;
                  }
                });
              }
              await Promise.all(
                projects.map(item => {
                  item.orderId = item.orderId + 1;
                })
              );
            } else {
              let projects;
              if (isIntiator) {
                projects = project.section.filter(project => {
                  if (
                    project.orderId > s.orderId &&
                    project.orderId <= newIndex
                  ) {
                    return project;
                  }
                });
              } else {
                projects = projectRef?.current?.section.filter(project => {
                  if (
                    project.orderId > s.orderId &&
                    project.orderId <= newIndex
                  ) {
                    return project;
                  }
                });
              }
              await Promise.all(
                projects.map(item => {
                  item.orderId = item.orderId - 1;
                })
              );
            }
            s.orderId = newIndex;
          }
        })
      );
      setProject({ ...projectObj });

      if (isIntiator) {
        let payload = {
          project_id: project && project.id.toString(),
          section_id: taskId.toString(),
          roomname: project && `${projectSocketRoomPrefix}${project.id}`,
          // task_id: taskId.toString(),
          orderIndex: newIndex
        };

        reorderSection(payload, cb => {
          if (cb.status == "Ok") {
            console.log("ok");
          } else {
            console.log("cb", cb);
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
            showNotifications();
          }
        });
      }
    } catch (e) {
      console.log(e, "e");
    }
  };
  const doReOrderTask = async (
    sectionId,
    taskId,
    newIndex,
    isIntiator = true
  ) => {
    newIndex = isIntiator ? newIndex + 1 : newIndex;
    try {
      let projectObj = project ? project : projectRef.current;
      let obj;
      await Promise.all(
        projectObj.section.map((s, i) => {
          return s.task.map(async (t, j) => {
            if (t.id == taskId) {
              if (t.orderId > newIndex) {
                // move task up
                let tasks = s.task.filter(task => {
                  if (task.orderId < t.orderId && task.orderId >= newIndex) {
                    return task;
                  }
                });

                await Promise.all(
                  tasks.map(item => {
                    item.orderId = item.orderId + 1;
                  })
                );
              } else {
                // move task down
                let tasks = s.task.filter(task => {
                  if (task.orderId > t.orderId && task.orderId <= newIndex) {
                    return task;
                  }
                });

                await Promise.all(
                  tasks.map(item => {
                    item.orderId = item.orderId - 1;
                  })
                );
              }
              t.orderId = newIndex;
            }
          });
        })
      );
      setProject({ ...projectObj });
      if (isIntiator) {
        let payload = {
          project_id: project && project.id.toString(),
          section_id: sectionId.toString(),
          roomname: project && `${projectSocketRoomPrefix}${project.id}`,
          task_id: taskId.toString(),
          orderIndex: newIndex
        };

        reorderTask(payload, cb => {
          if (cb.status == "Ok") {
          } else {
            console.log("cb", cb);
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
            showNotifications();
          }
        });
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
  const refreshTodoList = newTodo => {
    let todoArr = project;
    todoArr.section.map((item, SectionI) => {
      item.task.map((task, taskI) => {
        if (task.id == newTodo.id) {
          todoArr.section[SectionI].task[taskI] = newTodo;
        }
      });
    });
    setProject(todoArr);
  };
  const handleArchiveWorkpsace = async () => {
    if (props?.auth?.user?.businessId) {
      try {
        setIsArchivedLoader(true);
        const response = await toggleArchive(props?.auth?.user?.businessId);
        if (response) {
          if (response.message) {
            setIsArchivedLoader(false);

            if (!checkIsArchivedMessage(response.message)) {
              props.update_business_archive(false);
            }
          }
          if (response.error) {
            handleResponse(response.error.message);
            setIsArchivedLoader(false);
            showNotifications();
          }
        }
      } catch (e) {
        setIsArchivedLoader(false);
        showNotifications();
      }
    }
  };
  const getOverDueProjectDate = endDate => {
    if (endDate) {
      const checkDate = moment().diff(new Date(endDate), "days");
      if (checkDate > 0) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={CardTaskCalenderImage}
              style={{ marginRight: 5, height: 12, width: 12 }}
            ></img>
            <div className="text-overdue">{`${checkDate} day${
              checkDate == 1 ? "" : "s"
            } overdue`}</div>
          </div>
        );
      }
      if (checkDate < 0) {
        return "";
        // return (
        //   <div className="text-remaining">{`${Math.abs(
        //     checkDate
        //   )} days remaining`}</div>
        // );
      }
      if (checkDate == 0) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={CardTaskCalenderImage}
              style={{ marginRight: 5, height: 12, width: 12 }}
            ></img>
            <div className="text-due-today">Due today</div>
          </div>
        );
      }
    }
    return "";
  };
  const data = getOverDueProjectDate(project?.endDate);
  const checkArchive = () => {
    if (props?.auth?.user?.isArchived) {
      return (
        <>
          <div className="workspace-archived">
            <div className="workspace-archived-border flex-x fs-14">
              This workspace has been&nbsp;archived.&nbsp;{""}
              {props?.auth?.user?.businessId &&
                props?.auth?.user?.isBusinessOwner && (
                  <>
                    Click here to&nbsp;
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        display: "flex"
                      }}
                      onClick={handleArchiveWorkpsace}
                    >
                      <b>Unarchive</b>{" "}
                      {isArchivedLoader && (
                        <Loader height="18px" width="15px" />
                      )}
                    </div>{" "}
                  </>
                )}
            </div>
          </div>
        </>
      );
    }
    if (isArchived && project) {
      return (
        <div className="workspace-archived">
          <div className="workspace-archived-border flex-x fs-14">
            <IntlMessages id={"project.projecthasbeen"} />
            &nbsp; <IntlMessages id={"project.archived"} />
            .&nbsp;{" "}
            {
              <>
                {isOwner && (
                  <div style={{ display: "inline-flex" }}>
                    <IntlMessages id={"project.clickhereto"} />
                    &nbsp;
                    <b
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                      onClick={updateArchiveProject}
                    >
                      <b>
                        {" "}
                        <IntlMessages id={"project.unarchive"} />{" "}
                      </b>
                    </b>{" "}
                    {isArchivedLoader && <Loader height="18px" width="15px" />}
                  </div>
                )}
              </>
            }
          </div>
        </div>
      );
    }
  };
  const actions = [
    {
      id: "today",
      name: "Today",
      shortcut: ["b"],
      keywords: "writing words",
      perform: () => props.history.push(`/today`)
    },
    {
      id: "Upcomming",
      name: "Upcomming",
      shortcut: ["u"],
      keywords: "Upcomming",
      perform: () => props.history.push(`/upcoming`)
    }
  ];

  return (
    <BodyWrapper {...props}>
      {/* <KBarProvider actions={actions}>     
   <KBarPortal style={{backgroundColor: "red" }}> 
    <KBarPositioner> 
      <KBarAnimator> 
        <KBarSearch /> 
        <KBarResults />
      </KBarAnimator>
    </KBarPositioner>
  </KBarPortal> */}
      <div>
        <div className="row" style={{ backgroundColor: "white" }}>
          <div className={"col-lg-2 col-md-2 col-sm-0 col-xs-0"} />
          <div className={"col-lg-8 col-md-8 col-sm-12 col-xs-12"}>
            {checkArchive()}
          </div>
          <div className={"col-lg-2 col-md-2 col-sm-0 col-xs-0"} />

          <div
            className={"col-lg-1 col-md-1 col-sm-0 col-xs-0"}
            style={{
              padding: 0,
              borderBottom: "1px solid #EBEBEB"
            }}
          />
          <div
            className={"col-lg-10 col-md-10 col-sm-12 col-xs-12"}
            style={{ padding: 0 }}
          >
            {!isLoader && (
              <div className="roe-card-style">
                <div
                  className="roe-card-header "
                  style={{
                    padding: "11px 0px",
                    borderBottom: "1px solid #EBEBEB"
                  }}
                >
                  <div
                    className="file-manager-toolbar flex-x align-center "
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      // backgroundColor: "#f9f9f9",
                      // backgroundColor: "red",
                      borderRadius: 5
                      // padding: 2,
                      // paddingTop: 4,
                      // paddingBottom: 5,
                      // paddingRight: 3
                    }}
                  >
                    <div className="flex-1" style={{ width: "100%" }}>
                      <div
                        className="fs-16 demi-bold-text "
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center"
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center"
                          }}
                        >
                          {/* <Col
                          onClick={() =>
                            id &&
                            project &&
                            props.history.push(`/project-details/${id}`)
                          }
                          // className="col-lg-9 col-md-8 col-sm-12 col-xs-12 cursor-pointer"
                          style={{
                            display: "inline-block",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingRight: "0px",
                            borderRadius: "5px",
                            width: "10vw",
                            height: "38px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: " flex",
                            justifyContent: "center",
                            alignItems: " center"
                          }}
                        > */}
                          <RoyTooltip
                            id={`View_project_detail`}
                            title={InjectPlaceHolderMessage(
                              "project.viewprojects_activity",
                              props.LanguageSwitcher.language.languageId
                            )}
                            placement="bottom"
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer"
                            }}
                          >
                            {/* <i
                          className="fab fa-wpforms pr-10 fs-16"
                          style={{
                            color:
                              project && project.color
                                ? getColorCode(project.color)
                                : "gray"
                          }}
                        ></i>{" "} */}
                            <span
                              onClick={() =>
                                id &&
                                project &&
                                props.history.push(`/project-details/${id}`)
                              }
                              id={`View_project_detail`}
                              className="fs-14 hover-underline"
                              style={{
                                // width: "100%",
                                // marginTop: 3,
                                display: "inline-block",
                                marginLeft: 14,
                                fontWeight: 700,
                                backgroundColor: "#F4F5F7",
                                maxWidth: "140px",
                                height: "38px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: "black",
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                                borderRadius: "5px",
                                padding: "8px 18px",
                                justifyContent: "center",
                                alignItems: " center",
                                cursor: "pointer"
                                // textDecoration: "underline"
                              }}
                            >
                              {" "}
                              {project && project.name}
                            </span>
                          </RoyTooltip>
                          {/* </Col>
                        <Col
                          // className="col-lg-3 col-md-4 col-sm-12 col-xs-12"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingRight: "0px",
                            // marginBottom: "3px",
                            paddingLeft: "16px"
                          }}
                        > */}
                          <div
                            style={{
                              display: "inline-flex",
                              paddingLeft: "20px",
                              cursor: "pointer"
                            }}
                            id={"collaboratorContainer"}
                          >
                            {project &&
                              project.collaborator &&
                              project.collaborator.map((data, i) => {
                                if (i >= 5) {
                                } else {
                                  if (i == 4) {
                                    return (
                                      <span onClick={() => setModal(!modal)}>
                                        <Member
                                          key={i}
                                          text={`${project.collaborator.length -
                                            i}`}
                                          last={true}
                                          index={i}
                                        />
                                      </span>
                                    );
                                  } else {
                                    return (
                                      <span
                                        onClick={() => {
                                          props.open_profile_modal({
                                            profile: data && data.user
                                          });
                                        }}
                                      >
                                        <Member
                                          key={i}
                                          member={data}
                                          index={i}
                                        />
                                      </span>
                                    );
                                  }
                                }
                              })}
                          </div>
                          <button
                            className="btn btn-sm"
                            id="share"
                            onClick={() => setModal(!modal)}
                            style={{
                              // background: "#f5f6f7",
                              background: "#F3F4F6",
                              border: 0,
                              fontSize: "14px",
                              borderRadius: "5px",
                              position: "relative",
                              left: "20px",
                              maxWidth:
                                props?.LanguageSwitcher?.language?.locale ==
                                "en"
                                  ? "67px"
                                  : "100px",
                              height: "36px",
                              color: "#000",
                              padding: "8px 16px"
                              // paddingTop: "2px",
                              // // marginTop: "3px",
                              // padding: "0.15rem 0.5rem !important"
                            }}
                          >
                            {<IntlMessages id={"project.invite_button"} />}
                          </button>
                          {/* </Col> */}
                        </Row>
                        <div style={{ marginLeft: 45 }}>
                          {project?.startDate || project?.endDate ? (
                            <>
                              <div
                                className="ProjectGridStartAndEndDate"
                                style={{
                                  fontSize: 12,
                                  color: "#808080",
                                  marginTop: 3,
                                  display: "flex"
                                }}
                              >
                                <span>
                                  {<IntlMessages id={"project.starts"} />}:{" "}
                                  {project?.startDate
                                    ? moment(project?.startDate).format(
                                        "DD MMM YYYY"
                                      )
                                    : "N/A "}
                                </span>
                                <span
                                  className="ml-1"
                                  style={{
                                    display: project?.endDate ? "flex " : "none"
                                  }}
                                >
                                  {" "}
                                  - &nbsp;
                                  {<IntlMessages id={"project.ends"} />}:{" "}
                                  {moment(project?.endDate).format(
                                    "DD MMM YYYY"
                                  )}
                                </span>
                                {/* <span style={{ color: "red"}} >{getOverDueProjectDate(project?.endDate)}</span> */}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 7
                        // marginTop: "3px"
                      }}
                    >
                      {isViewLoader && <Loader height="18px" width="15px" />}
                      <div
                        style={{ cursor: "pointer" }}
                        id="Upopover"
                        onClick={() => setIsProjectDetails(!isProjectDetails)}
                      >
                        <img
                          alt=""
                          className="ProjectGridStartAndEndDateImage"
                          src={CardTaskCalenderImage}
                          style={{
                            height: "16px",
                            width: "15px",
                            display: "none"
                          }}
                        ></img>

                        <ProjectDetailsPopover
                          projectName={project && project.name}
                          isOpen={isProjectDetails}
                          toggle={() => setIsProjectDetails(!isProjectDetails)}
                          startsDate={moment(project?.startDate).format(
                            "DD MMM YYYY"
                          )}
                          endsDate={moment(project?.endDate).format(
                            "DD MMM YYYY"
                          )}
                        />
                      </div>
                      <div className="ml-15 text-muted">
                        <span
                          onClick={() => {
                            updateViewOptions();
                          }}
                        >
                          <RoyTooltip
                            id={`viewMode`}
                            title={
                              defaultView == "List"
                                ? InjectPlaceHolderMessage(
                                    "project.viewas_grid",
                                    props.LanguageSwitcher.language.languageId
                                  )
                                : InjectPlaceHolderMessage(
                                    "project.viewas_list",
                                    props.LanguageSwitcher.language.languageId
                                  )
                            }
                            placement="bottom"
                          ></RoyTooltip>
                          <div id="viewMode" className="cursor-pointer">
                            {/* <i
                        className={
                          defaultView == "List"
                            ? "fas fa-newspaper fs-14"
                            : "fas fa-list fs-14"
                        }
                      ></i> */}
                            <img
                              alt=""
                              src={grid}
                              style={{
                                height: "15px",
                                width: "15px",
                                marginBottom: "1px"
                              }}
                              className={
                                defaultView == "List"
                                  ? "fas fa-newspaper fs-14"
                                  : "fas fa-list fs-14"
                              }
                            ></img>
                          </div>
                        </span>
                      </div>
                      <div className="ml-15 text-muted ">
                        <span onClick={() => setCommentModal(!commentModal)}>
                          <RoyTooltip
                            id={`Comments`}
                            title={InjectPlaceHolderMessage(
                              "project.comments",
                              props.LanguageSwitcher.language.languageId
                            )}
                            placement="bottom"
                          ></RoyTooltip>
                          <div id="Comments" className="cursor-pointer">
                            {/* <i className="far fa-comment-alt fs-14"></i> */}
                            <img
                              alt=""
                              src={commentnew}
                              style={{
                                height: "15px",
                                width: "15px"
                                // paddingBottom: "4px"
                              }}
                            ></img>
                          </div>
                        </span>
                      </div>
                      <div style={{ paddingLeft: "15px" }}>
                        <span
                          style={{
                            display: "inline-flex"
                          }}
                        >
                          <RoyTooltip
                            id={`share`}
                            title={InjectPlaceHolderMessage(
                              "project.invitecollaborators",
                              props.LanguageSwitcher.language.languageId
                            )}
                            placement="bottom"
                          ></RoyTooltip>
                          <div
                            id="share"
                            className="cursor-pointer"
                            onClick={() => setModal(!modal)}
                          >
                            {/* <i
                        className="far fa-user fs-14 pr-1"
                        style={{ paddingTop: "0.29rem" }}
                      >
                        +
                      </i> */}

                            <img
                              alt=""
                              src={invite}
                              style={{
                                height: "14px",
                                width: "14px",
                                paddingRight: "1px"
                              }}
                            ></img>
                            <span
                              style={{
                                position: "relative",
                                top: "5px"
                              }}
                            >
                              <label
                                style={{
                                  fontSize: "12px",
                                  marginBottom: "0px",
                                  color: "#808080"
                                }}
                              >
                                {project && project.collaborator
                                  ? project.collaborator.length
                                  : ""}
                              </label>
                            </span>
                          </div>
                        </span>
                      </div>
                      <div
                        className="ml-15 text-muted"
                        id="popover"
                        onClick={() =>
                          setMoreProjectOptions(!moreProjectOptions)
                        }
                      >
                        <span>
                          <div
                            id="moreoptions"
                            className="cursor-pointer plr-5"
                          >
                            {/* <i className="fas fa-ellipsis-h"></i> */}
                            <img
                              alt=""
                              src={threeDot_Icon}
                              style={{
                                marginBottom: "2px",
                                height: 6,
                                width: 22
                              }}
                            ></img>
                          </div>
                          <MoreOptionsMenu
                            doOpenDocuments={() =>
                              props.history.push({
                                pathname: `/project-documents/${id}`,
                                state: {
                                  recentProjectName: project.name
                                }
                              })
                            }
                            doOpenAttachment={() =>
                              props.history.push(`/project-attachments/${id}`)
                            }
                            doOpenProjectActivity={() =>
                              props.history.push(`/project/${id}/activity`)
                            }
                            moreProjectOptions={moreProjectOptions}
                            setSectionCardFlag={() => {
                              setSectionCardFlag(!addSectionFlag);
                            }}
                            isFavorite={project && project.isFavorite}
                            setMoreProjectOptions={() =>
                              setMoreProjectOptions(!moreProjectOptions)
                            }
                            toggleEditProject={() =>
                              setEditProjectModal(!editProjectModal)
                            }
                            deleteProject={() => {
                              setMoreProjectOptions(false);
                              ToggleshowDeleteProjectAlert(true);
                            }}
                            isArchived={project && project.isArchived}
                            updateArchiveProject={updateArchiveProject}
                            isOwner={isOwner}
                            leaveProject={() => {
                              setDeleteListTitleNameListView(project.name);
                              setIsLeaveProject(true);
                              ToggleshowDeleteLabelAlert(true);
                            }}
                            updateFavorite={updateFavorite}
                            viewMode={project && project.defaultView}
                            updateViewOptions={() => updateViewOptions()}
                            toggleProjectSideMenu={() => {
                              setProjectSideMenu(!projectSideMenu);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className={"col-lg-1 col-md-1 col-sm-0 col-xs-0"}
            style={{
              padding: 0,
              borderBottom: "1px solid #EBEBEB"
            }}
          />

          <div
            style={{ backgroundColor: "#F4F5F7", padding: 0 }}
            className={
              defaultView == "List"
                ? "col-lg-1 col-md-1 col-sm-0 col-xs-0"
                : "col-lg-2 col-md-2 col-sm-0 col-xs-0"
            }
          />
          <div
            style={{
              backgroundColor: "#F4F5F7",
              padding: 0,
              minHeight: "85vh"
            }}
            className={
              defaultView == "List"
                ? "col-lg-10 col-md-10 col-sm-12 col-xs-12"
                : "col-lg-12 col-md-12 col-sm-12 col-xs-12"
            }
          >
            <Notifications />
            {/* Header start */}

            {/* Header end */}
            {isLoader && (
              <>
                <div className="roe-card-style mtb-15 todo-board">
                  <Shimmer />
                </div>
                <div className="roe-card-style mtb-15 todo-board">
                  <Shimmer />
                </div>
              </>
            )}
            {defaultView == "List" ? (
              <div className=" mt-15">
                {/* Section start */}
                {project && !isLoader && project.section.length > 0
                  ? project.section.map((data, i) => {
                      if (!data.isDeleted && !data.isArchived) {
                        return (
                          <div
                            className=" roe-card-style mt-25 todo-board"
                            key={i * 2333}
                          >
                            {editSectionToggle ? (
                              <div>Edit</div>
                            ) : (
                              data?.orderId != 0 && (
                                <>
                                  {editSectionFlag &&
                                  editSectionData?.id == data?.id ? (
                                    <EditList
                                      width={"100%"}
                                      title={data?.title}
                                      id={data?.id}
                                      orderId={data?.orderId}
                                      closeEditList={() => {
                                        setSectionmenu(false);
                                        setSectionmenuID(null);
                                        SetEditSectionData("");
                                        SetEditSectionFlag(false);
                                      }}
                                      isUpdateSectionLoading={
                                        isUpdateSectionLoading
                                      }
                                      onSubmit={doUpdateSection}
                                      PRIMARY_COLOR={
                                        props.topbarTheme.buttonColor
                                      }
                                    />
                                  ) : (
                                    <div className="flex-x">
                                      {" "}
                                      <div
                                        onClick={() => {
                                          setDeleteListTitleNameListView(
                                            data?.title
                                          );
                                          if (sectionmenu) {
                                            setSectionmenuID(null);
                                          } else {
                                            setSectionmenuID(
                                              `todomenu-${data?.id}`
                                            );
                                            SetEditSectionData(data);
                                            SetEditSectionFlag(
                                              !editSectionFlag
                                            );
                                          }
                                        }}
                                        style={{
                                          cursor: "pointer"
                                        }}
                                        className="pa-5 flex-1 align-center"
                                        id={`section-edit-${i}`}
                                      >
                                        <div className="flex-1">
                                          <div
                                            className="demi-bold-text fw-600"
                                            style={{
                                              color: "black",
                                              fontSize: "14px"
                                            }}
                                          >
                                            {data.title}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="gray-hover-list"
                                        // style={{
                                        //   paddingTop: 5,
                                        //   paddingRight: 10,
                                        //   paddingLeft: 10,
                                        //   cursor: "pointer"
                                        // }}
                                        onClick={() => {
                                          setDeleteListTitleNameListView(
                                            data?.title
                                          );
                                          if (sectionmenu) {
                                            setSectionmenu(!sectionmenu);
                                            setSectionmenuID(null);
                                          } else {
                                            setSectionmenu(!sectionmenu);
                                            setSectionmenuID(
                                              `todomenu-${data?.id}`
                                            );
                                            SetEditSectionData(data);
                                          }
                                        }}
                                      >
                                        {data?.orderId !== 0 && (
                                          // <i
                                          //   id={`todomenu-${data?.id}`}
                                          //   style={{border:"1px solid red"}}
                                          //   className="fas fa-ellipsis-h cursor-pointer text-muted"
                                          //   onClick={() => {
                                          //     if (sectionmenu) {
                                          //       setSectionmenu(!sectionmenu);
                                          //       setSectionmenuID(null);
                                          //     } else {
                                          //       setSectionmenu(!sectionmenu);
                                          //       setSectionmenuID(
                                          //         `todomenu-${data?.id}`
                                          //       );
                                          //       SetEditSectionData(data);
                                          //     }
                                          //   }}
                                          // >
                                          //   {/* Delete Section */}
                                          // </i>
                                          <img
                                            alt=""
                                            id={`todomenu-${data?.id}`}
                                            src={threeDot_Icon}
                                            style={{
                                              height: 6,
                                              width: 22
                                            }}
                                          ></img>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </>
                              )
                            )}
                            <div key={data.id * 3878}>
                              {
                                <TodolistWrapper>
                                  <TodoList
                                    setSyncGoogle={setSyncGoogle}
                                    syncGoogle={syncGoogle}
                                    isGoogleCalenderData={isGoogleCalenderData}
                                    setIsGoogleCalenderData={
                                      setIsGoogleCalenderData
                                    }
                                    updateLabelToParent={updateLabelToParent}
                                    reqTaskId={reqTaskId}
                                    {...props}
                                    handleResponse={handleResponse}
                                    istaskinput={istaskinput}
                                    setIsTaskinput={data =>
                                      setIsTaskinput(data)
                                    }
                                    PRIMARY_COLOR={
                                      props.topbarTheme.buttonColor
                                    }
                                    key={data.id * 3875658}
                                    sectionId={data.id}
                                    sectionTitle={data.title}
                                    projectId={project && project.id}
                                    projectName={project && project.name}
                                    collaboratorList={
                                      project ? project.collaborator : null
                                    }
                                    todoList={data.task}
                                    updateisCompleted={(
                                      sectionId,
                                      taskid,
                                      isCompleted,
                                      projectId
                                    ) => {
                                      let projectData = project;
                                      projectData.section.map((data, i) => {
                                        if (data.id == sectionId) {
                                          data.task.map((t, i) => {
                                            if (t.id == taskid) {
                                              t.isCompleted = isCompleted;
                                            }
                                          });
                                        }
                                      });
                                      setProject(projectData);
                                    }}
                                    updateIsCompleted={(
                                      sectionId,
                                      todoId,
                                      checked,
                                      data
                                    ) => {
                                      updateIsCompleted(
                                        sectionId,
                                        todoId,
                                        checked,
                                        data
                                      );
                                    }}
                                    updateSection={(
                                      newTodo,
                                      projectId,
                                      sectionId
                                    ) => {
                                      let projectData = project;
                                      projectData.section.map((data, i) => {
                                        if (data.id == sectionId) {
                                          if (data.task) {
                                            data.task.push(newTodo);
                                          } else {
                                            data.task = [newTodo];
                                          }
                                        }
                                      });
                                      setProject({
                                        ...projectData
                                      });
                                    }}
                                    projectData={project}
                                    showNotifications={showNotifications}
                                    setProject={setProject}
                                  />
                                </TodolistWrapper>
                              }
                            </div>
                          </div>
                        );
                      }
                    })
                  : ""}{" "}
                {!isLoader && (
                  <AddSectionCard
                    props={props}
                    PRIMARY_COLOR={props.topbarTheme.buttonColor}
                    totalsection={project && project.section.length}
                    projectId={project && project.id}
                    project={project}
                    // New Section
                    addSectionFlag={addSectionFlag}
                    setSectionCardFlag={() =>
                      setSectionCardFlag(!addSectionFlag)
                    }
                    addSection={data => {
                      let projectData = {
                        ...project
                      };
                      projectData.section.push(data);
                      setProject(projectData);
                    }}
                    // Edit Section
                    projectData={project}
                    UpdateProjectData={data => {
                      setProject(data);
                    }}
                    showNotifications={showNotifications}
                  />
                )}
                {/* Section end */}
              </div>
            ) : (
              <ScrumboardWrapper {...props}>
                {/* Board start */}
                <div className="flex-y">
                  <div className="flex-1">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable
                        droppableId="card"
                        type="card"
                        // isDragDisabled={false}
                        direction="horizontal"
                      >
                        {provided => (
                          <div ref={newtaskEditorRef}>
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className="scrum-board"
                              style={{
                                overflowY: "hidden",
                                height: "100%",
                                padding: "10px 0px 0px 40px",
                                backgroundColor: "#fff"
                              }}
                              onClick={() => setIsBoardCardSectionClick(true)}
                              // onWheel={(e)=>handleScroll(e)}
                            >
                              {project &&
                                project?.section?.length > 0 &&
                                project.section
                                  .sort((a, b) => a.orderId - b.orderId)
                                  .map((s, index) => {
                                    if (s && !s.isDeleted && !s.isArchived) {
                                      return (
                                        <BoardCard
                                          setSyncGoogle={setSyncGoogle}
                                          syncGoogle={syncGoogle}
                                          isGoogleCalenderData={
                                            isGoogleCalenderData
                                          }
                                          setIsGoogleCalenderData={
                                            setIsGoogleCalenderData
                                          }
                                          deleteListTitleNameListView={
                                            deleteListTitleNameListView
                                          }
                                          SetEditSectionToggle={() => {
                                            SetEditSectionFlag(
                                              !editSectionFlag
                                            );
                                          }}
                                          setDeleteListTitleNameListView={
                                            setDeleteListTitleNameListView
                                          }
                                          updateLabelToParent={
                                            updateLabelToParent
                                          }
                                          isLoader={isLoader}
                                          reqTaskId={reqTaskId}
                                          {...props}
                                          isUpdateSectionLoading={
                                            isUpdateSectionLoading
                                          }
                                          handleResponse={handleResponse}
                                          refreshTodoList={data =>
                                            refreshTodoList(data)
                                          }
                                          // updateIsCompleted={(
                                          //   sectionId,
                                          //   todoId,
                                          //   checked,
                                          //   data
                                          // ) => {
                                          //   updateIsCompleted(
                                          //     sectionId,
                                          //     todoId,
                                          //     checked,
                                          //     data
                                          //   );
                                          // }}
                                          SetEditSectionFlag={
                                            SetEditSectionFlag
                                          }
                                          editSectionFlag={editSectionFlag}
                                          PRIMARY_COLOR={
                                            props.topbarTheme.buttonColor
                                          }
                                          // newTaskArray={newTaskArray}
                                          // setNewTaskArray={setNewTaskArray}
                                          key={s.id}
                                          index={index}
                                          droppableId={s.id ? s.id : 9999999999}
                                          card={s}
                                          tasks={s.task}
                                          setProject={setProject}
                                          doUpdateSection={doUpdateSection}
                                          openTaskDialogHandler={() => {}}
                                          new={(data, cb) => () => {}}
                                          deleteTaskHandler={() => {}}
                                          sectionId={s.id}
                                          sectionTitle={s.title}
                                          projectId={project && project.id}
                                          // collaboratorList={project.collaborator}
                                          collaboratorList={
                                            project
                                              ? project.collaborator
                                              : null
                                          }
                                          updateIsCompleted={(
                                            sectionId,
                                            todoId,
                                            checked,
                                            data
                                          ) => {
                                            updateIsCompleted(
                                              sectionId,
                                              todoId,
                                              checked,
                                              data
                                            );
                                          }}
                                          updateSection={async (
                                            newTodo,
                                            projectId,
                                            sectionId
                                          ) => {
                                            let projectData = project;
                                            projectData.section.map(
                                              (data, i) => {
                                                if (data.id == sectionId) {
                                                  if (data.task) {
                                                    data.task.push(newTodo);
                                                  } else {
                                                    data.task = [newTodo];
                                                  }
                                                }
                                              }
                                            );
                                            setProject(projectData);
                                          }}
                                          showNotifications={showNotifications}
                                          setSectionmenu={setSectionmenu}
                                          setSectionmenuID={setSectionmenuID}
                                          sectionmenu={sectionmenu}
                                          editSectionData={editSectionData}
                                          SetEditSectionData={
                                            SetEditSectionData
                                          }
                                          totalsection={
                                            project && project.section.length
                                          }
                                          project={project}
                                          // New Section

                                          addSection={data => {
                                            let projectData = {
                                              ...project
                                            };
                                            projectData.section.push(data);
                                            setProject(projectData);
                                          }}
                                          // Edit Section
                                          projectData={project}
                                          UpdateProjectData={data => {
                                            setProject(data);
                                          }}
                                          isBoardCardscroll={isBoardCardscroll}
                                          setIsBoardCardscroll={
                                            setIsBoardCardscroll
                                          }
                                          isBoardCardSectionClick={
                                            isBoardCardSectionClick
                                          }
                                          setIsBoardCardSectionClick={
                                            setIsBoardCardSectionClick
                                          }
                                        />
                                      );
                                    }
                                  })}
                              {provided.placeholder}

                              <BoardCardAdder
                                addSectionFlag={addSectionFlag}
                                setSectionCardFlag={() =>
                                  setSectionCardFlag(!addSectionFlag)
                                }
                                showNotifications={showNotifications}
                                addNewCardHandler={async title =>
                                  addNewCard(title)
                                }
                              />
                            </div>
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
                {/* Board end */}
              </ScrumboardWrapper>
            )}
          </div>
          <div
            style={{ backgroundColor: "#F4F5F7", padding: 0 }}
            className={
              defaultView == "List"
                ? "col-lg-1 col-md-1 col-sm-0 col-xs-0"
                : "col-lg-2 col-md-2 col-sm-0 col-xs-0"
            }
          />

          <ProjectSideMenu
            // mini={projectSideMenu}
            isDeleteArchiveCustomAlert={isDeleteArchiveCustomAlert}
            setIsDeleteArchiveCustomAlert={setIsDeleteArchiveCustomAlert}
            mini={projectSideMenu}
            doDeleteTask={doDeleteTask}
            doArchiveSection={doArchiveSection}
            doArchiveTask={doArchiveTask}
            drawerWidth={themeSettingDrawerWidth}
            miniDrawerWidth={stateminiDrawerWidth}
            closeSettingDrawer={themeSettingDrawer}
            projectSideMenu={projectSideMenu}
            project={project && project}
            {...props}
          />

          <SectionHeaderMenu
            isMoveTaskAction={isMoveListAction()}
            isOpen={sectionmenuID && sectionmenu}
            toggle={() => {
              if (sectionmenu) {
                setSectionmenuID(null);
              }

              setSectionmenu(!sectionmenu);
            }}
            toggleMoveList={id =>
              setIsMoveListModal({
                status: !isMoveListModal.status,
                id: id
              })
            }
            setSectionmenuID={data => sectionmenuID(data)}
            // divid={`section-edit-${i}`}
            doArchiveSection={doArchiveSection}
            doDuplicateSection={doDuplicateSection}
            sectionmenuID={sectionmenuID}
            editSectionData={editSectionData}
            sectionEdiId={sectionEdiId}
            deleteSection={() => {
              setIsLeaveProject(false);
              ToggleshowDeleteLabelAlert(true);
            }}
            SetEditSectionToggle={() => {
              SetEditSectionFlag(!editSectionFlag);
            }}
          />
          <CustomAlert
            CustomMessage={deleteListTitleNameListView}
            isuserAction={!isLeaveProject}
            actionName={delectAction}
            action={isLeaveProject ? "leave" : "delete"}
            messageType={isLeaveProject ? "project" : "list"}
            PRIMARY_COLOR={props.topbarTheme.buttonColor}
            show={showDeleteLabelAlert}
            onConfirm={() => {
              isLeaveProject ? leaveProject() : doDeleteSection();
              setIsLeaveProject(false);
              ToggleshowDeleteLabelAlert(false);
              setDeleteListTitleNameListView("");
            }}
            onCancel={() => {
              setIsLeaveProject(false);
              ToggleshowDeleteLabelAlert(false);
              setDeleteListTitleNameListView("");
            }}
          />

          <CustomNotification
            show={toast}
            message={toastMessage}
            closeNotification={() => setToast(false)}
          />
          <CommentModal
            handleResponse={handleResponse}
            isOpen={commentModal}
            name={project && project.name}
            projectId={project && project.id}
            toggleCommentModal={() => {
              if (props.history?.location?.state?.commentModal) {
                props.history.replace({
                  pathname: `/project/${project.id}`
                });
              }
              setCommentModal(!commentModal);
            }}
            showNotifications={showNotifications}
            collaboratorList={project ? project.collaborator : null}
          />
          <ShareModal
            handleResponse={handleResponse}
            leftCollab={() => {
              props.remove_Project(id);
              props.open_project_alert({
                status: true,
                message: "project"
              });
            }}
            collaboratorList={project ? project.collaborator : null}
            modal={modal}
            removeCollaboratorById={id => removeCollaboratorById(id)}
            setmodal={() => setModal(!modal)}
            projectId={id}
            projectName={project && project.name}
            showNotifications={showNotifications}
            businessId={businessId}
            PRIMARY_COLOR={props.topbarTheme.buttonColor}
            pushCollaborator={data => pushCollaborator(data)}
            currentLoggedInUser={props.auth.user}
          />
          {/* comment */}
          <EditProject
            modal={editProjectModal}
            setmodal={() => setEditProjectModal(!editProjectModal)}
            project={project}
            setProject={data => {
              let projectObj = { ...project };
              projectObj.name = data.name;
              projectObj.color = data.color;
              projectObj.description = data.description;
              projectObj.startDate = data.startDate;
              projectObj.endDate = data.endDate;
              projectObj.isFavourite = data.isFavourite;
              projectObj.defaultView = data.defaultView;
              SetDefaultView(data.defaultView == "grid" ? "Grid" : "List");
              setProject({ ...projectObj });
            }}
            updateProjectToRedux={updateProjectToRedux}
            showNotifications={showNotifications}
            languageName={
              editProjectModal && props.LanguageSwitcher.language.languageId
            }
          />
          <MoveListProject
            showNotifications={showNotifications}
            modal={isMoveListModal.status}
            setmodal={() =>
              setIsMoveListModal({
                status: !isMoveListModal.status,
                id: ""
              })
            }
            PRIMARY_COLOR={props.topbarTheme.buttonColor}
            currentProjectId={id}
            sectionId={isMoveListModal.id}
            doMoveSection={doMoveSection}
          />
        </div>
      </div>
      {/* </KBarProvider> */}
    </BodyWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher.language }
  };
};

export default connect(mapStateToProps, {
  update_projects_existing,
  remove_Project,
  open_workpsace_alert,
  open_project_alert,
  open_profile_modal,
  close_alert,
  update_business_archive,
  do_update_project,
  close_update_project,
  update_recent_Project
})(ProjectGrid);
