import styled from "styled-components";

const ProjectMoreOptionWrapper = styled.div`
  .bs-popover-auto {
    border: none !important;
    box-shadow: 0px 0px 15px 1px #0000001a !important;
  }
`;

export default ProjectMoreOptionWrapper;
