import styled from "styled-components";

const NewTaskInputWrapper = styled.div`
  .form-control::placeholder {
    color: #808080 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding-left: 1px !important;
  }
  .form-control {
    padding-left: 0px !important;
  }
`;

export default NewTaskInputWrapper;
