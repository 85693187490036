import React, { Fragment } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { store, history, persistor } from "./redux/store";
import { StyleRoot } from "radium";
import layoutRoutes from "./routes/index.jsx";
import themes from "./settings/themes";
import AuthLayout from "routes/AuthLayout";
import AppLocale from "./languageProvider";
import { themeConfig } from "./settings";
import config, { getCurrentLanguage } from "./settings/languageConfig";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/scss/app.scss";
import "pretty-checkbox/src/pretty-checkbox.scss";
import "./app.css";
import "moment/locale/de";
import "moment/locale/nl";

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];
const App = props => {
  return (
    <Fragment>
      {/* <Online> */}{" "}
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeConfig.theme]}>
          <StyleRoot>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {/*Start layout routes */}
                {/* 
                Routes which are accessible in loggedin and public routes are kept in AuthLayout 
                */}
                <Router history={history}>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <AuthLayout to="/" />}
                    />
                    {layoutRoutes.map((prop, index) => {
                      return (
                        <Route
                          path={prop.path}
                          component={prop.component}
                          key={index}
                          history={history}
                        />
                      );
                    })}
                  </Switch>
                </Router>
                {/*End layout routes */}
              </PersistGate>
            </Provider>
          </StyleRoot>
        </ThemeProvider>
      </IntlProvider>
      {/* </Online> */}
      {/* <Offline>
        <div style={{ paddingLeft: 100, paddingTop: 100, paddingRight: 100 }}>
          <div
            className="text-muted roe-card-style"
            style={{
              boxShadow:
                "0 0 0 0 rgba(90, 113, 208, 0.11), 0 4px 16px 0 rgba(167, 175, 183, 0.33);",
              padding: 50,
              fontSize: 18,
              display: "flex",
              justifyContent: "center",
              border: "1px solid #dde4eb",
              borderRadius: "6px",
              transition: " all .2s"
            }}
          >
            <div style={{ padding: "0 24px 24px" }}>No Internet</div>
          </div>
        </div>
      </Offline>
    */}
    </Fragment>
  );
};

export default App;
