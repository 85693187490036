import { themeConfig } from "./index";

const changeThemes = {
  id: "changeThemes",
  label: "themeChanger",
  defaultTheme: themeConfig.theme,
  options: [
    {
      themeName: "themedefault",
      buttonColor: "#ffffff",
      textColor: "#323332"
    }
  ]
};

const topbarTheme = {
  id: "topbarTheme",
  label: "themeChanger.topbar",
  defaultTheme: themeConfig.topbar,
  options: [
    {
      themeName: "dark",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#222436",
      backgroundColor: "#222436",
      textColor: "rgba(255, 255, 255, .75)",
      badgeBackColor: "#FF0017",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#2196f3",
      horSidebarActiveBackColor: "rgba(33, 150, 243, 0.2)",
      horSidebarActiveTextColor: "#2196f3"
    },
    {
      themeName: "rackley",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#0073E6",
      backgroundColor: "#0073E6",
      textColor: "rgba(255, 255, 255, .85)",
      badgeBackColor: "#0073E6",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#0073E6",
      horSidebarActiveBackColor: "rgba(0,0,0,.15)",
      horSidebarActiveTextColor: "#fff",
      type: "gredient",
      buttonOpacity: "0.18"
    },
    {
      themeName: "beer",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#FE8E26",
      backgroundColor: "#FE8E26",
      textColor: "rgba(0, 0, 0, .55)",
      badgeBackColor: "#FE8E26",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#FE8E26",
      horSidebarActiveBackColor: "#fff",
      horSidebarActiveTextColor: "#000",
      type: "gredient"
    },
    {
      themeName: "tomato",
      buttonBackColor: "#FF6348",
      buttonColor: "#FF6348",
      backgroundColor: "#FF6348",
      textColor: "rgba(0, 0, 0, .65)",
      badgeBackColor: "#FF6348",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#FF6348",
      horSidebarActiveBackColor: "#fff",
      horSidebarActiveTextColor: "rgba(0, 0, 0, .65)"
    },

    {
      themeName: "slate_gray",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#747D8C",
      backgroundColor: "#747D8C",
      textColor: "rgba(255, 255, 255, .85)",
      badgeBackColor: "#747D8C",
      badgeTextColor: "rgba(0,0,0,0.5)",
      breadCumbActiveColor: "#747D8C",
      horSidebarActiveBackColor: "rgba(0,0,0,.15)",
      horSidebarActiveTextColor: "#fff",
      type: "gredient"
    },
    {
      // "Helitrope
      // #FF6348
      themeName: "dark_gunmetal",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#222436",
      backgroundColor: "#222436",
      textColor: "rgba(0,0,0,0.8)",
      badgeBackColor: "#222436",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#222436",
      horSidebarActiveBackColor: "rgba(0,0,0,.15)",
      horSidebarActiveTextColor: "#fff",
      type: "gredient"
    },
    {
      themeName: "royal_purple",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#735D9F",
      backgroundColor: "#735D9F",
      textColor: "rgba(255, 255, 255, .85)",
      badgeBackColor: "#735D9F",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#735D9F",
      horSidebarActiveBackColor: "rgba(0,0,0,.15)",
      horSidebarActiveTextColor: "#fff",
      type: "gredient"
    },
    {
      themeName: "deep_puce",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#A75F5F",
      backgroundColor: "#A75F5F",
      textColor: "rgba(255, 255, 255, .85)",
      badgeBackColor: "#A75F5F",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#A75F5F",
      horSidebarActiveBackColor: "rgba(0,0,0,.15)",
      horSidebarActiveTextColor: "#fff",
      type: "gredient"
    },
    {
      themeName: "tufts_blue",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#4394EF",
      backgroundColor: "#4394EF",
      textColor: "rgba(255, 255, 255, .85)",
      badgeBackColor: "#4394EF",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#4394EF",
      horSidebarActiveBackColor: "rgba(0,0,0,.15)",
      horSidebarActiveTextColor: "#fff",
      type: "gredient"
    },
    {
      themeName: "caribbean",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#0EB998",
      backgroundColor: "#0EB998",
      textColor: "rgba(255, 255, 255, .85)",
      badgeBackColor: "#0EB998",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#0EB998",
      horSidebarActiveBackColor: "rgba(0,0,0,.15)",
      horSidebarActiveTextColor: "#fff",
      type: "gredient"
    },
    {
      themeName: "turquoise",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#05C0C6",
      backgroundColor: "#05C0C6",
      textColor: "rgba(255, 255, 255, .85)",
      badgeBackColor: "#05C0C6",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#05C0C6",
      horSidebarActiveBackColor: "rgba(0,0,0,.15)",
      horSidebarActiveTextColor: "#fff",
      type: "gredient"
    },
    {
      themeName: "coral_reef",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#FF7675",
      backgroundColor: "#FF7675",
      textColor: "rgba(255, 255, 255, .85)",
      badgeBackColor: "#FF7675",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#FF7675",
      horSidebarActiveBackColor: "rgba(0,0,0,.15)",
      horSidebarActiveTextColor: "#fff",
      type: "gredient"
    },
    {
      themeName: "fuchsia_rose",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#C44569",
      backgroundColor: "#C44569",
      textColor: "rgba(255, 255, 255, .85)",
      badgeBackColor: "#C44569",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#C44569",
      horSidebarActiveBackColor: "rgba(0,0,0,.15)",
      horSidebarActiveTextColor: "#fff",
      type: "gredient"
    },

    {
      themeName: "gredient5",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg, #ff5252, #f48fb1)",
      backgroundColor: "linear-gradient(45deg, #ff5252, #f48fb1)",
      textColor: "rgba(255, 255, 255, .85)",
      badgeBackColor: "#fef9d7",
      badgeTextColor: "rgba(0,0,0,0.5)",
      breadCumbActiveColor: "#fef9d7",
      horSidebarActiveBackColor: "#fff",
      horSidebarActiveTextColor: "#ff5252",
      type: "gredient"
    },
    {
      themeName: "gredient6",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg,#2ed8b6,#2a5298)",
      backgroundColor: "linear-gradient(45deg,#2ed8b6,#2a5298)",
      textColor: "rgba(255, 255, 255, 1)",
      badgeBackColor: "#fef9d7",
      badgeTextColor: "rgba(0,0,0,0.5)",
      breadCumbActiveColor: "#fef9d7",
      horSidebarActiveBackColor: "#fff",
      horSidebarActiveTextColor: "#2a5298",
      type: "gredient"
    },
    {
      themeName: "gredient7",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg,#9D50BB,#7b1fa2)",
      backgroundColor: "linear-gradient(45deg,#9D50BB,#7b1fa2)",
      textColor: "rgba(255, 255, 255, .85)",
      badgeBackColor: "rgba(201,221,162,1)",
      badgeTextColor: "#000",
      breadCumbActiveColor: "rgba(201,221,162,1)",
      horSidebarActiveBackColor: "#fff",
      horSidebarActiveTextColor: "#7b1fa2",
      type: "gredient"
    },
    {
      themeName: "gredient8",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg,rgba(216,241,230,0.46),#e2ebf0)",
      backgroundColor: "linear-gradient(45deg,rgba(216,241,230,0.46),#e2ebf0)",
      textColor: "rgba(0, 0, 0, .55)",
      badgeBackColor: "#FF6B6B",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#FF6B6B",
      horSidebarActiveBackColor: "#fff",
      horSidebarActiveTextColor: "#000",
      type: "gredient"
    }
  ]
};

const sidebarTheme = {
  id: "sidebarTheme",
  label: "themeChanger.sidebar",
  defaultTheme: themeConfig.sidebar,
  options: [
    {
      themeName: "sky",
      backgroundColor: "#fafafa",
      textColor: "rgba(0, 0, 0, .87)",
      activeRouteTextColor: "#480048",
      buttonColor: "rgba(0,0,0,.2)",
      activeRouteBackColor: "#FFF",
      style4VarientActiveTextColor: "#F4C95D",
      sideOpenListBackground: "rgba(0, 0, 0, .05)"
    },
    {
      themeName: "dark",
      backgroundColor: "#fafafa",
      textColor: "#000",
      activeRouteTextColor: "#000",
      buttonColor: "#ff6b6b",
      activeRouteBackColor: "#ff6b6b",
      style4VarientActiveTextColor: "#ff6b6b",
      sideOpenListBackground: "linear-gradient(to top, #222436, #222436)"
    },
    {
      themeName: "light",
      backgroundColor: "#fafafa",
      textColor: "rgba(0, 0, 0, .87)",
      activeRouteTextColor: "rgb(121, 78, 110)",
      buttonColor: "rgb(121, 78, 110)",
      activeRouteBackColor: "rgb(121, 78, 110)",
      style4VarientActiveTextColor: "#ff6b6b",
      sideOpenListBackground: "rgba(0, 0, 0, .035)"
    },
    {
      themeName: "tangerine",
      backgroundColor: "#fafafa",
      textColor: "rgba(0, 0, 0, .87)",
      activeRouteTextColor: "rgba(255, 255, 255, 1)",
      buttonColor: "#FE8E26",
      activeRouteBackColor: "#FE8E26",
      style4VarientActiveTextColor: "#FE8E26",
      sideOpenListBackground: "rgba(0, 0, 0, .035)",
      type: "gredient"
    },
    {
      themeName: "helitrope",
      backgroundColor: "#fafafa",
      textColor: "rgba(0, 0, 0, .87)",
      activeRouteTextColor: "#FF6348",
      buttonColor: "#FF6348",
      activeRouteBackColor: "#FF6348",
      style4VarientActiveTextColor: "#FF6348",
      sideOpenListBackground: "rgba(0, 0, 0, .035)"
    },
    {
      themeName: "coral",
      backgroundColor: "#fafafa",
      textColor: "rgba(0, 0, 0, .87)",
      activeRouteTextColor: "rgba(0,0,0,0.8)",
      buttonColor: "#0073E6",
      activeRouteBackColor: "#0073E6",
      style4VarientActiveTextColor: "#0073E6",
      sideOpenListBackground: "rgba(0, 0, 0, .035)",
      type: "gredient"
    },
    {
      themeName: "gredient4",
      backgroundColor: "#fafafa",
      textColor: "rgba(0, 0, 0, .87)",
      activeRouteTextColor: "rgba(255, 255, 255, .85)",
      buttonColor:
        "linear-gradient(45deg,rgba(76,79,106,1),rgba(118,124,163,1))",
      activeRouteBackColor:
        "linear-gradient(45deg,rgba(76,79,106,1),rgba(118,124,163,1))",
      style4VarientActiveTextColor: "rgba(118,124,163,1)",
      sideOpenListBackground: "rgba(0, 0, 0, .035)",
      type: "gredient"
    },
    {
      themeName: "sapphire",
      backgroundColor: "#fafafa",
      textColor: "rgba(0, 0, 0, .87)",
      activeRouteTextColor: "rgba(255, 255, 255, .85)",
      buttonColor: "linear-gradient(45deg, #ff5252, #f48fb1)",
      activeRouteBackColor: "linear-gradient(45deg, #ff5252, #f48fb1)",
      style4VarientActiveTextColor: "#ff5252",
      sideOpenListBackground: "rgba(0, 0, 0, .035)",
      type: "gredient"
    },
    {
      themeName: "gredient6",
      backgroundColor: "#fafafa",
      textColor: "rgba(0, 0, 0, .87)",
      activeRouteTextColor: "rgba(255, 255, 255, .85)",
      buttonColor: "linear-gradient(45deg,#2ed8b6,#2a5298)",
      activeRouteBackColor: "linear-gradient(45deg,#2ed8b6,#2a5298)",
      style4VarientActiveTextColor: "#2a5298",
      sideOpenListBackground: "rgba(0, 0, 0, .035)",
      type: "gredient"
    },
    {
      themeName: "gredient7",
      backgroundColor: "#fafafa",
      textColor: "rgba(0, 0, 0, .87)",
      activeRouteTextColor: "rgba(255, 255, 255, .85)",
      buttonColor: "linear-gradient(45deg,#9D50BB,#7b1fa2)",
      activeRouteBackColor: "linear-gradient(45deg,#9D50BB,#7b1fa2)",
      style4VarientActiveTextColor: "#9D50BB",
      sideOpenListBackground: "rgba(0, 0, 0, .035)",
      type: "gredient"
    },
    {
      themeName: "gredient8",
      backgroundColor: "#fafafa",
      textColor: "rgba(0, 0, 0, .87)",
      activeRouteTextColor: "#FF6B6B",
      buttonColor: "linear-gradient(45deg,rgba(216,241,230,0.46),#e2ebf0)",
      activeRouteBackColor:
        "linear-gradient(45deg,rgba(216,241,230,0.46),#e2ebf0)",
      style4VarientActiveTextColor: "#FF6B6B",
      sideOpenListBackground: "rgba(0, 0, 0, .035)",
      type: "gredient"
    }
  ]
};

const layoutTheme = {
  id: "layoutTheme",
  label: "themeChanger.background",
  defaultTheme: themeConfig.layout,
  options: [
    {
      themeName: "themedefault",
      buttonColor: "#fafafa",
      backgroundColor: "rgb(249, 249, 249)",
      cardBackground: "#fff",
      headingColor: "#6a727a",
      textColor: "#000"
    },
    {
      themeName: "theme1",
      buttonColor: "#ffffff",
      backgroundColor: "#ffffff",
      cardBackground: "#fff",
      headingColor: "#6a727a",
      textColor: "#000"
    },
    {
      themeName: "theme2",
      buttonColor: "#F9F9F9",
      backgroundColor: "#F9F9F9",
      cardBackground: "#fff",
      headingColor: "#6a727a",
      textColor: "#000"
    },
    {
      themeName: "theme3",
      buttonColor: "#ebebeb",
      backgroundColor: "#ebebeb",
      cardBackground: "#fff",
      headingColor: "#6a727a",
      textColor: "#000"
    },
    {
      themeName: "theme4",
      buttonColor: "#D9D9D9",
      backgroundColor: "#D9D9D9",
      cardBackground: "#fff",
      headingColor: "#6a727a",
      textColor: "#000"
    },
    {
      themeName: "theme5",
      buttonColor: "#eef5f9",
      backgroundColor: "#eef5f9",
      cardBackground: "#fff",
      headingColor: "#000",
      textColor: "#000"
    },
    {
      themeName: "theme6",
      buttonColor: "#2d3038",
      backgroundColor: "#2d3038",
      cardBackground: "rgba(68, 70, 79, 0.5)",
      headingColor: "#fff",
      textColor: "#949ba2"
    },
    {
      themeName: "theme7",
      buttonColor: "#1c1e2f",
      backgroundColor: "#1c1e2f",
      cardBackground: "#222436",
      headingColor: "#fff",
      textColor: "#949ba2"
    }
  ]
};

const footerTheme = {
  id: "footerTheme",
  label: "themeChanger.footer",
  defaultTheme: themeConfig.footer,
  options: [
    {
      themeName: "dark",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "#222436",
      backgroundColor: "#222436",
      textColor: "#ffffff"
    },
    {
      themeName: "light",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg,#F5F7FA,#B8C6DB)",
      backgroundColor: "linear-gradient(45deg,#F5F7FA,#B8C6DB)",
      textColor: "rgba(0, 0, 0, .65)"
    },
    {
      themeName: "sky",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg,#F5F7FA, rgb(10, 191, 188))",
      backgroundColor: "linear-gradient(45deg,#F5F7FA, rgb(10, 191, 188))",
      textColor: "rgba(0, 0, 0, .65)"
    },
    {
      themeName: "tangerine",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg,#5C258D,#4389A2)",
      backgroundColor: "linear-gradient(45deg,#5C258D,#4389A2)",
      textColor: "#ffffff",
      type: "gredient"
    },
    {
      themeName: "helitrope",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor:
        "linear-gradient(45deg,rgba(167,139,252,1),rgba(82,107,248,1))",
      backgroundColor:
        "linear-gradient(45deg,rgba(167,139,252,1),rgba(82,107,248,1))",
      textColor: "#ffffff",
      type: "gredient"
    },
    {
      themeName: "sapphire",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor:
        "linear-gradient(45deg,rgba(255,230,112,1),rgba(255,100,100,0.6))",
      backgroundColor:
        "linear-gradient(45deg,rgba(255,230,112,1),rgba(255,100,100,0.6))",
      textColor: "rgba(0,0,0,0.8)",
      type: "gredient"
    },
    {
      themeName: "gredient4",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor:
        "linear-gradient(45deg,rgba(76,79,106,1),rgba(118,124,163,1))",
      backgroundColor:
        "linear-gradient(45deg,rgba(76,79,106,1),rgba(118,124,163,1))",
      textColor: "rgba(255,255,255,.85)",
      type: "gredient"
    },
    {
      themeName: "gredient5",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg, #ff5252, #f48fb1)",
      backgroundColor: "linear-gradient(45deg, #ff5252, #f48fb1)",
      textColor: "rgba(255,255,255,.85)",
      type: "gredient"
    },
    {
      themeName: "gredient6",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg,#2ed8b6,#2a5298)",
      backgroundColor: "linear-gradient(45deg,#2ed8b6,#2a5298)",
      textColor: "#ffffff",
      type: "gredient"
    },
    {
      themeName: "gredient7",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg,#9D50BB,#7b1fa2)",
      backgroundColor: "linear-gradient(45deg,#9D50BB,#7b1fa2)",
      textColor: "rgba(255,255,255,.85)",
      type: "gredient"
    },
    {
      themeName: "gredient8",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg,rgba(216,241,230,0.46),#e2ebf0)",
      backgroundColor: "linear-gradient(45deg,rgba(216,241,230,0.46),#e2ebf0)",
      textColor: "rgba(0,0,0,0.8)",
      type: "gredient"
    }
  ]
};

const customizedThemes = {
  changeThemes,
  topbarTheme,
  sidebarTheme,
  layoutTheme,
  footerTheme
};

export function getCurrentTheme(attribute, selectedThemename) {
  let selecetedTheme = {};
  customizedThemes[attribute].options.forEach(theme => {
    if (theme.themeName === selectedThemename) {
      selecetedTheme = theme;
    }
  });
  return selecetedTheme;
}
export default customizedThemes;
