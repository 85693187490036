import React, { useState } from "react";
import "./attachmentComment.style.css";
import ModalImage, { Lightbox } from "react-modal-image";
import { calc_file_size } from "helper/methods/GetFileSize";
import { pdfIcon } from "helper/constant";
import IntlMessages from "util/intlMessages";
const filePlaceholder = require("assets/images/file.png");

export default function CommentAttachment({ comment }) {
  const MEME_TYPE = {
    IMAGE_PNG: "image/png",
    IMAGE_JPG: "image/jpg",
    IMAGE_JPEG: "image/jpeg",
    VIDEO_FLASH: "video/x-flv",
    VIDEO_IPHONE_INDEX: "application/x-mpegURL",
    VIDEO_IPHONE_SEGMENT: "video/MP2T",
    VIDEO_3GP: "video/3gpp",
    VIDEO_MP4: "video/mp4",
    VIDEO_QUICK_TIME: "video/quicktime",
    VIDEO_AV_INTERLEAVE: "video/x-msvideo",
    VIDEO_WEBM: "video/webm",
    VIDEO_WINDOWS_MEDIA: "video/x-msvideo",
    DOCUMENT_PDF: "application/pdf"
  };
  const [openLighBox, setOpenLightBox] = useState([]);
  const handleDocumentDownload = async (url, name) => {
    const image = await fetch(url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const GetMimetype = attachment => {
    if (
      attachment.mimetype == MEME_TYPE.IMAGE_PNG ||
      attachment.mimetype == MEME_TYPE.IMAGE_JPG ||
      attachment.mimetype == MEME_TYPE.IMAGE_JPEG
    ) {
      return (
        <div>
          <img
            alt=""
            className="image-attachment"
            src={attachment.url}
            style={{
              maxHeight: 300,
              maxWidth: 300,
              border: "1px solid #eaeaea",
              borderRadius: 2,
              objectFit: "contain",
              cursor: "zoom-in"
            }}
            onClick={() =>
              setOpenLightBox({
                url: attachment.url,
                id: attachment.id
              })
            }
          />
          {openLighBox &&
            openLighBox.url &&
            openLighBox.id &&
            attachment.id == openLighBox.id && (
              <Lightbox
                hideZoom
                hideDownload
                small={openLighBox.url}
                large={openLighBox.url}
                onClose={() => setOpenLightBox("")}
                imageBackgroundColor={"handy"}
              />
            )}
          {/* <ModalImage
            small={attachment.url}
            large={attachment.url}
            hideZoom={false}
            hideDownload={false}
          /> */}
        </div>
      );
    }

    if (
      attachment.mimetype == MEME_TYPE.VIDEO_3GP ||
      attachment.mimetype == MEME_TYPE.VIDEO_FLASH ||
      attachment.mimetype == MEME_TYPE.VIDEO_IPHONE_INDEX ||
      attachment.mimetype == MEME_TYPE.VIDEO_IPHONE_SEGMENT ||
      attachment.mimetype == MEME_TYPE.VIDEO_QUICK_TIME ||
      attachment.mimetype == MEME_TYPE.VIDEO_AV_INTERLEAVE ||
      attachment.mimetype == MEME_TYPE.VIDEO_WINDOWS_MEDIA ||
      attachment.mimetype == MEME_TYPE.VIDEO_WEBM
    ) {
      return (
        <div
          style={{
            height: 300,
            width: 533,
            border: "1px solid #eaeaea",
            borderRadius: 2
          }}
        >
          <video width="533" height="300" controls autoplay muted>
            <source
              src={attachment.url}
              type={
                attachment.mimetype == MEME_TYPE.VIDEO_QUICK_TIME
                  ? "video/mp4"
                  : attachment.mimetype
              }
            />
          </video>
        </div>
      );
    }
    if (attachment.mimetype == MEME_TYPE.DOCUMENT_PDF) {
      return (
        <a href={attachment.url} target="_blank">
          <img
            alt=""
            src={pdfIcon}
            style={{
              height: 60
              // margin: 10
            }}
          />
        </a>
      );
    }
    return (
      <a href={attachment.url} target="_blank">
        <img
          alt=""
          src={filePlaceholder}
          style={{
            height: 60
            // margin: 10
          }}
        />
      </a>
    );
  };
  return (
    <>
      {comment && comment.commentMeta && (
        <div
          style={{
            padding: 0,
            marginTop: 2
          }}
        >
          <div className="" align="left">
            {GetMimetype(comment.commentMeta)}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
              }}
              align="left"
            >
              <div style={{ display: "block" }}>
                <a
                  href={comment.commentMeta.url}
                  target="_blank"
                  style={{
                    color: "grey",
                    fontSize: 12,
                    fontFamily: "revert"
                    // fontWeight: "600"
                  }}
                >
                  {comment.commentMeta.originalname}
                  <i
                    style={{
                      color: "#626262",
                      marginLeft: 5,
                      cursor: "pointer"
                    }}
                    className="fa fa-link fs-10"
                  ></i>
                </a>
                <span
                  style={{
                    color: "grey",
                    fontSize: 12,
                    cursor: "pointer"
                  }}
                >
                  {" "}
                  - {calc_file_size(comment.commentMeta.size)}
                </span>
                <span
                  style={{
                    color: "grey",
                    fontSize: 12,
                    cursor: "pointer"
                  }}
                  onClick={() =>
                    handleDocumentDownload(
                      comment.commentMeta.url,
                      comment.commentMeta.originalname
                    )
                  }
                >
                  - <u>{<IntlMessages id={"project.download"} />} </u>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
