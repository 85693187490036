import { getColorCode } from "components/projects/utils/color";
import React, { useEffect, useState } from "react";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import IconTint from "react-icon-tint";
import {
  iconTag,
  editPencilIcon,
  NewTaskPriorityCross,
  singleCheckIcon
} from "helper/constant";
import { sidebarData } from "util/data/sidebar";
import { connect } from "react-redux";
import labelActions from "redux/labels/actions";
import { createLabel } from "helper/services/projectServices";
import { updateLabel } from "helper/services/labelsServices";
import { ProjectColors } from "components/projects/utils/color";
import Loader from "components/loader/Loader";
import IntlMessages from "util/intlMessages";

const { update_Label, update_labels_existing } = labelActions;
const LabelsPopover = props => {
  const {
    isOpen,
    toggle,
    labels,
    target,
    selectedLabels,
    selectLabelHandler,
    topbarTheme,
    placement,
    updateLabelToParent
  } = props;
  const labelActionsArr = ["create", "update"];
  const [isLabelAction, setIsLabelAction] = useState({
    status: false,
    action: labelActionsArr[0]
  });
  const [isEditLabel, setIsEditLabel] = useState({
    status: false,
    id: ""
  });
  const [isLoader, setIsLoader] = useState(false);
  const [newLabelInput, setNewLabelInput] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedColor, setSelectedColor] = useState(
    ProjectColors[Math.floor(Math.random() * ProjectColors?.length)].label
  );

  useEffect(() => {
    if (isOpen) {
      setIsEditLabel({ status: false, id: "" });
      setIsLabelAction({ status: false, action: "" });
      setSelectedColor(
        ProjectColors[Math.floor(Math.random() * ProjectColors?.length)].label
      );
    }
    return () => {
      setIsEditLabel({ status: false, id: "" });
      setIsLabelAction({ status: false, action: "" });
    };
  }, [isOpen]);

  const updateLabelToRedux = data => {
    props.update_Label(data);
  };
  const handleLabelClickAction = () => {
    if (
      isLabelAction &&
      isLabelAction.status &&
      isLabelAction.action == labelActionsArr[0]
    ) {
      handleCreateLabel();
    }

    if (
      isLabelAction &&
      isLabelAction.status &&
      isLabelAction.action == labelActionsArr[1]
    ) {
      handleUpdateLabel();
    }
  };
  const handleUpdateLabel = async () => {
    try {
      if (
        newLabelInput &&
        newLabelInput.length > 0 &&
        newLabelInput.match(/^\S+\w{0,50}\S{0,}/g) &&
        isLabelAction.status &&
        isLabelAction.data
      ) {
        setIsLoader(true);
        const Response = await updateLabel(
          {
            name: newLabelInput,
            color: selectedColor,
            isFavorite: false
          },
          isLabelAction.data?.id
        );

        if (Response) {
          setIsLoader(false);
          if (Response.error) {
          } else {
            if (Response.data) {
              props.update_labels_existing({ labels: Response.data });
              setIsLabelAction({ status: false, action: "" });
              !selectedLabels.includes(Response.data.id) &&
                selectLabelHandler(Response.data);
              sidebarData.map((data, i) => {
                if (data.name === "sidebar.labels") {
                  data.child = [];
                  data.child.push({
                    id: Response.data.id,
                    listname: Response.data.name,
                    routepath: `/label/${Response.data.id}`,
                    color: Response.data.color
                  });
                }
              });

              // if(updateLabelToParent){

              updateLabelToParent(Response.data);
            }
          }
        }
      }
    } catch (e) {
      setIsLoader(false);
    }
  };
  const handleCreateLabel = async () => {
    try {
      if (
        newLabelInput &&
        newLabelInput.length > 0 &&
        newLabelInput.match(/^\S+\w{0,50}\S{0,}/g)
      ) {
        setIsLoader(true);
        const Response = await createLabel({
          name: newLabelInput,
          color: selectedColor,
          isFavorite: false,
          businessId: props.auth?.user?.businessId || ""
        });

        if (Response) {
          setIsLoader(false);
          if (Response.error) {
          } else {
            if (Response.data) {
              updateLabelToRedux(Response.data);
              setIsLabelAction({ status: false, action: "" });
              selectLabelHandler(Response.data);
              sidebarData.map((data, i) => {
                if (data.name === "sidebar.labels") {
                  data.child = [];
                  data.child.push({
                    id: Response.data.id,
                    listname: Response.data.name,
                    routepath: `/label/${Response.data.id}`,
                    color: Response.data.color
                  });
                }
              });
            }
          }
        }
      }
    } catch (e) {
      setIsLoader(false);
    }
  };
  const toggleUpdateLabel = label => {
    setNewLabelInput(label.name);
    setSelectedColor(label.color);
    setIsLabelAction({
      status: true,
      action: labelActionsArr[1],
      data: label
    });
    setIsDisabled(false);
  };
  const toggleCreateLabel = label => {
    setNewLabelInput("");
    setSelectedColor(
      ProjectColors[Math.floor(Math.random() * ProjectColors?.length)].label
    );
    setIsLabelAction({
      status: true,
      action: labelActionsArr[0],
      data: label
    });
    setIsDisabled(true);
  };
  return (
    <div>
      <UncontrolledPopover
        style={{
          minWidth: "250px",
          background: "#FFF",
          boxShadow: "0px 0px 15px #0000001A",
          borderRadius: "10px"
        }}
        isOpen={isOpen}
        // trigger="PopoverClick"
        trigger="legacy"
        className="roy-menu label-options-pop header-popover"
        innerClassName="roy-inner-content label-options-pop aa"
        placement={placement || "top"}
        target={target}
        hideArrow
        toggle={() => toggle()}
      >
        <PopoverBody
          style={{ borderRadius: "10px" }}
          // onBlur={() => toggle()}
        >
          <div>
            {isLabelAction && isLabelAction.status && (
              <div
                className="float-left"
                onClick={() => setIsLabelAction({ status: false, action: "" })}
              >
                <i
                  className="fa fa-chevron-left fs-12"
                  style={{
                    marginLeft: 20,
                    marginTop: 10,
                    color: "#808080",
                    cursor: "pointer"
                  }}
                />
              </div>
            )}
            <div className="float-right" onClick={() => toggle()}>
              {/* <i className="fa fa-times text-muted" /> */}
              <img
                alt=""
                src={NewTaskPriorityCross}
                style={{
                  height: "10px",
                  width: "10px",
                  marginRight: "20px",
                  marginTop: "6px",
                  cursor: "pointer"
                }}
              ></img>
            </div>
            <div
              className="fs-14 text-center "
              style={{
                padding: 4,
                // textTransform: "capitalize",
                paddingBottom: "10px",
                borderBottom: "1px solid #EBEBEB",
                color: "#808080"
                // border:"1px solid red"
              }}
            >
              {isLabelAction?.status ? (
                <>
                  {" "}
                  <span
                    style={{ textTransform: "capitalize" }}
                  >{`${isLabelAction.action}`}</span>{" "}
                  <IntlMessages id={"today.labels"} />
                </>
              ) : (
                <IntlMessages id={"today.labels"} />
              )}
              {/* {console.log(isLabelAction?.status,"oooo")} */}
            </div>
          </div>

          {isLabelAction?.status && isLabelAction.action ? (
            <div style={{ padding: "10px 20px" }}>
              <div style={{ paddingBottom: 8 }}>
                <label>Name</label>
                <input
                  className="form-control react-form-input pr-2 add-task-input"
                  type="text"
                  autoFocus
                  defaultValue={newLabelInput}
                  onChange={e => {
                    setIsDisabled(
                      e.target.value.match(/^\S+\w{0,50}\S{0,}/g) ? false : true
                    );
                    setNewLabelInput(e.target.value);
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      if (
                        newLabelInput &&
                        newLabelInput.match(/^\S+\w{0,50}\S{0,}/g)
                      ) {
                        handleLabelClickAction();
                      }
                    }
                  }}
                />
              </div>
              <div>
                <label>
                  <IntlMessages id={"labels.selectacolor"} />
                </label>
                <div className="container">
                  <div className="row">
                    {ProjectColors &&
                      ProjectColors.map(color => {
                        return (
                          <div
                            className="label-color-block flex-x text-center"
                            style={{
                              background: color.color,
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            onClick={() => setSelectedColor(color.value)}
                          >
                            {selectedColor && selectedColor == color.value && (
                              <i
                                className="fa fa-check"
                                aria-hidden="true"
                                style={{ color: "#FFF" }}
                              ></i>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              {isLoader ? (
                <div
                  className="btn button cursor-pointer fs-14"
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    WebkitAppearance: "none",
                    borderRadius: 3,
                    border: "none",
                    display: "inline-block",
                    textDecoration: "none",
                    position: "relative",
                    backgroundColor: topbarTheme.buttonColor
                  }}
                >
                  <Loader height="20px" width="16px" />
                </div>
              ) : (
                <button
                  className="btn button cursor-pointer fs-14"
                  disabled={isDisabled}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    WebkitAppearance: "none",
                    borderRadius: 3,
                    border: "none",
                    display: "inline-block",
                    color: "#FFF",
                    textDecoration: "none",
                    textTransform: "capitalize",
                    position: "relative",
                    backgroundColor: topbarTheme.buttonColor,
                    margin: "15px 0px"
                  }}
                  onClick={() => handleLabelClickAction()}
                >
                  {(isLabelAction?.status && isLabelAction.action) || ""}{" "}
                  <IntlMessages id={"labels.label"} />
                </button>
              )}
            </div>
          ) : (
            <>
              {labels && labels.labels?.length > 0 ? (
                <div
                  className=""
                  style={{
                    minHeight: 100,
                    maxHeight: 250,
                    overflowX: "scroll",
                    paddingTop: "5px"
                  }}
                >
                  {labels.labels.map((label, i) => {
                    return (
                      <div
                        className="roy-menu-list flex-x"
                        key={label.id + 3555844}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          maxHeight: "40px",
                          width: 300
                        }}
                      >
                        {isEditLabel &&
                        isEditLabel.status &&
                        isEditLabel.id == label.id ? (
                          <>
                            <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                              <input
                                autoFocus
                                className="form-control react-form-input pr-2 add-task-input"
                                type="text"
                                defaultValue={label.name}
                              />
                            </div>
                            <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                              <button
                                className="c-btn c-default"
                                onClick={() =>
                                  setIsEditLabel({ status: false, id: "" })
                                }
                              >
                                X
                              </button>
                            </div>
                            <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                              <button
                                className="button btn"
                                style={{
                                  background: topbarTheme.buttonColor,
                                  color: "#FFF"
                                }}
                              >
                                <IntlMessages id={"workspace.update"} />
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="flex-1"
                              onClick={() => {
                                selectLabelHandler(label);
                                // toggle();
                              }}
                            >
                              <div
                                className="label-list"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  <div style={{ height: 18, width: 18 }}>
                                    <IconTint
                                      maxHeight="20"
                                      maxWidth="20"
                                      src={iconTag}
                                      color={getColorCode(label.color)}
                                    />

                                    {/* <i
                                    className="fa fa-tag"
                                    style={{
                                      color: getColorCode(label.color)
                                    }}
                                  /> */}
                                  </div>
                                  <div
                                    style={{
                                      width: "200px",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      fontSize: 13,
                                      color: "black",
                                      margin: "0px 10px",
                                      WebkitLineClamp: 4 /* number of lines to show */,
                                      WebkitBoxOrient: "vertical"
                                    }}
                                  >
                                    {label.name}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: 16,
                                    width: 16,
                                    marginRight: 15
                                  }}
                                >
                                  {selectedLabels &&
                                    selectedLabels.includes(label.id) && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginRight: 15
                                        }}
                                      >
                                        <IconTint
                                          //  maxHeight="18"
                                          //  maxWidth="18"
                                          src={singleCheckIcon}
                                          style={{
                                            height: "18px",
                                            width: "18px"
                                          }}
                                          color={topbarTheme.buttonColor}
                                        />
                                        {/* <img
                                        src={singleCheckIcon}
                                        className={classNames("mlr-15")}
                                      ></img> */}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                alt=""
                                onClick={() => toggleUpdateLabel(label)}
                                style={{
                                  height: 17,
                                  width: 17
                                }}
                                src={editPencilIcon}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}{" "}
                </div>
              ) : (
                <>
                  <div className="pa-50 " style={{ cursor: "auto" }}>
                    <div className="text-muted text-center">
                      <IntlMessages id={"task-details.nolabel"} />
                    </div>
                  </div>
                  <div></div>
                </>
              )}{" "}
              <div style={{ padding: 6, paddingBottom: 10, paddingTop: 10 }}>
                <button
                  className="btn button cursor-pointer fs-14"
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    WebkitAppearance: "none",
                    borderRadius: 5,
                    border: "none",
                    display: "inline-block",
                    textDecoration: "none",
                    position: "relative",
                    backgroundColor: "rgba(9, 30, 66, 0.04)"
                  }}
                  onClick={() => {
                    toggleCreateLabel();
                  }}
                >
                  <span style={{ color: "black", fontSize: "13px" }}>
                    {" "}
                    <IntlMessages id={"today.createnewlabel"} />
                  </span>
                </button>
              </div>
            </>
          )}
        </PopoverBody>
      </UncontrolledPopover>

      {/* <AddLabel
        modal={addLabelModal}
        sidebarData={sidebarData}
        setmodal={() => {
          isOpen && toggle();
          setAddLabelModal(!addLabelModal);
        }}
        updateLabelToRedux={(data) => {
          props.update_Label(data);
        }}
      /> */}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    labels: { ...state.labels }
  };
};

export default connect(mapStateToProps, {
  update_Label,
  update_labels_existing
})(LabelsPopover);
