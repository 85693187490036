import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Collapse, CardBody, Card } from "reactstrap";
import IntlMessages from "util/intlMessages";
import classNames from "classnames";
import { getColorCode } from "components/projects/utils/color";
import {
  todayIcon,
  project_clndr,
  dotIcon,
  SidebarDownArrow,
  SidebarUpArrow
} from "helper/constant";
import ProjectArchivedMoreOptions from "components/sidebar/ProjectArchivedMoreOptions";
const ProjectArchivedSidebarList = props => {
  const [collapse, setCollapse] = useState(false);
  const [isHoverProject, setIsHoverProject] = useState({
    id: "",
    status: false
  });
  useEffect(() => {
    if (props.childList.map(a => a.routepath).includes(props.CurrentRoute)) {
      setCollapse(true);
    }
  }, [props.CurrentRoute, props.childList]);
  const {
    listNameStyle,
    childList,
    listname,
    mini,
    drawerWidth,
    miniDrawerWidth,
    CurrentRoute,
    key,
    setArchivedMoreProjectOptions,
    archivedMoreProjectOptions,
    updateArchiveProject
  } = props;
  const toggle = e => {
    e.preventDefault();
    setCollapse(!collapse);
  };
  const handleMouseEnter = id => {
    setIsHoverProject({
      id: id,
      status: true
    });
  };
  const handleMouseLeave = () => {
    setIsHoverProject({
      id: "",
      status: false
    });
  };

  return (
    <ul className="pa-0" key={key} onMouseLeave={handleMouseLeave}>
      <li className="pos-relative">
        {/* {!mini || miniDrawerWidth === drawerWidth ? (
          collapse ? (
            <i
              className="fas fa-angle-up arrow-sidebar second-child-list-icon"
              onClick={toggle}
            />
          ) : (
            <i
              className="fas fa-angle-down arrow-sidebar second-child-list-icon"
              onClick={toggle}
            />
          )
        ) : (
          ""
        )} */}
        <NavLink
          key={key}
          to={"demo"}
          onClick={toggle}
          className={classNames(
            childList.map(a => a.routepath).includes(CurrentRoute) && "active",
            "nav-link",
            "main-list"
          )}
          style={{ marginLeft: 5, display: "flex", alignItems: "center" }}
        >
          {!mini || miniDrawerWidth === drawerWidth ? (
            collapse ? (
              <img
                className="fas fa-angle-up text-muted"
                style={{
                  color: "gray",
                  marginRight: "19px",
                  marginLeft: "14px"
                }}
                onClick={toggle}
                src={SidebarUpArrow}
              ></img>
            ) : (
              // <i
              //   className="fas fa-angle-up text-muted"
              //   style={{ color: "gray" }}
              //   onClick={toggle}
              // />
              <img
                className={"fas fa-angle-down text-muted"}
                style={{
                  color: "gray",
                  marginRight: "19px",
                  marginLeft: "14px"
                }}
                onClick={toggle}
                src={SidebarDownArrow}
              ></img>
              // <i
              //   className={"fas fa-angle-down text-muted"}
              //   style={{ color: "gray" }}
              //   onClick={toggle}
              // />
            )
          ) : (
            ""
          )}
          {/* <i className={childList.iconClass} /> */}
          <p style={({ listNameStyle }, { fontSize: 14, color: "black" })}>
            {<IntlMessages id={"sidebar.archivedprojects"} />}
          </p>
        </NavLink>
        <Collapse isOpen={collapse}>
          <Card className="background-transparent border-none">
            <CardBody className="pa-0">
              {childList
                ? childList.map((listData, i) => {
                    if (listData.isArchived) {
                      return (
                        <div
                          className="nav-link child-list"
                          key={i}
                          onMouseEnter={() => handleMouseEnter(i)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <p>
                            <NavLink to={`/project/${listData.id}`}>
                              <span className="span-shortname">
                                <i
                                  className="fa fa-circle"
                                  style={{
                                    color: getColorCode(listData.color),
                                    fontSize: 10
                                  }}
                                >
                                  {/* {childList.color} */}
                                </i>
                                {/* {childList.color} */}
                              </span>
                              <p
                                style={
                                  (listNameStyle,
                                  {
                                    display: "inline-block",
                                    width: 150,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    color: "#000"
                                  })
                                }
                                key={i}
                              >
                                {listData.name}
                              </p>
                            </NavLink>
                            {listData && listData.isProjectOwner && (
                              <img
                                id={`project-archived-menu-${i}`}
                                src={dotIcon}
                                style={
                                  isHoverProject.status &&
                                  isHoverProject.id == i
                                    ? {
                                        height: "15px",
                                        width: "15px",
                                        position: "relative",
                                        bottom: "10px",
                                        marginLeft: "23px",
                                        cursor: "pointer",
                                        display: "inline-block"
                                      }
                                    : {
                                        height: "15px",
                                        width: "15px",
                                        position: "relative",
                                        bottom: "10px",
                                        marginLeft: "23px",
                                        cursor: "pointer",
                                        display: "none"
                                      }
                                }
                                onClick={() => {
                                  if (archivedMoreProjectOptions.status) {
                                    setArchivedMoreProjectOptions({
                                      status: !archivedMoreProjectOptions.status,
                                      id: "",
                                      project: ""
                                    });
                                  } else {
                                    setArchivedMoreProjectOptions({
                                      status: !archivedMoreProjectOptions.status,
                                      id: `project-archived-menu-${i}`,
                                      project: listData
                                    });
                                  }
                                }}
                              />
                            )}
                          </p>
                          <ProjectArchivedMoreOptions
                            isOpen={archivedMoreProjectOptions.status}
                            isOwner={
                              archivedMoreProjectOptions.status &&
                              archivedMoreProjectOptions?.project
                                ?.isProjectOwner
                            }
                            isArchived={
                              archivedMoreProjectOptions?.project?.isArchived
                            }
                            popoverTarget={archivedMoreProjectOptions?.id || ""}
                            toggle={() =>
                              setArchivedMoreProjectOptions({
                                status: !archivedMoreProjectOptions.status,
                                id: "",
                                project: ""
                              })
                            }
                            updateArchiveProject={() =>
                              updateArchiveProject(
                                archivedMoreProjectOptions?.project
                                  ? archivedMoreProjectOptions?.project
                                  : listData
                              )
                            }
                          />
                        </div>
                      );
                    }
                  })
                : ""}
              {childList &&
                !childList.some(
                  listData => listData.isArchived && !listData.isDeleted
                ) && (
                  <div className="nav-link child-list fs-12 text-muted">
                    <div
                      style={
                        (listNameStyle,
                        {
                          marginLeft: 50,
                          color: "gray"
                        })
                      }
                    >
                      <IntlMessages id={"project.no_archive_project"} />
                    </div>
                  </div>
                )}
            </CardBody>
          </Card>
        </Collapse>
      </li>
    </ul>
  );
};

export default ProjectArchivedSidebarList;
