import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { getPriority } from "helper/services/priorityServices";
import CustomNotification from "util/CustomNotification";
import PriorityListView from "components/TodoListview/PriorityListView";
import moment from "moment";
import Shimmer from "components/shimmer/Shimmer";
import {
  priorityInit,
  joinRoom,
  leaveRoom,
  disconnect,
  updateTask
} from "services/socket";
import { prioritySocketRoomPrefix } from "constatnt";
import alertActions from "redux/globalAlert/actions";
import { checkIsArchivedMessage } from "helper/methods/workspacestatus";
import { toggleArchive } from "helper/services/businessServices";
import Loader from "components/loader/Loader";
import authActions from "redux/auth/actions";
import ApiCalendar from "react-google-calendar-api";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import IntlMessages from "util/intlMessages";
const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const { update_business_archive } = authActions;
const Today = props => {
  const { addToast } = useToasts();
  const { id } = props.match.params;
  const [priorityTodo, setPriorityTodo] = useState(null);
  const [isEditTask, setIsEditTask] = useState({
    status: false,
    data: "",
    action: ""
  });
  const [isLoader, SetIsLoader] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [isArchiveLoader, setIsArchiveloader] = useState(false);
  const [SearchLabelTodoQueryResult, setSearchLabelTodoQueryResult] = useState(
    null
  );
  const [isQuickActionTask, setIsQuickActionTask] = useState({
    status: false,
    data: "",
    selectedData: "",
    action: "",
    target: ""
  });
  const [isErrorAlert, setIsErrorAlert] = useState({
    status: false,
    messageType: ""
  });
  const [tempTask, setTempTask] = useState([]);
  const priorityRef = React.useRef(priorityTodo);

  const PriorityViewRef = useRef(null);

  const scrollToTop = () => {
    PriorityViewRef.current.scrollIntoView({
      block: "center",
      inline: "nearest"
    });
  };
  React.useEffect(() => {
    priorityRef.current = priorityTodo;
  });
  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        // status:action.alert.status, message:action.alert.message,action:'open_businesslist'

        props.open_workpsace_alert({
          status: true,
          message: "workspace"
        });
      }
      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
      }
    } catch (e) {}
  };

  useEffect(() => {
    scrollToTop();
    if (id) {
      fetchPriority();
      priorityInit(props.auth.accessToken, updateTaskCB, removeTaskCB);
      handleSocketRoom();
    }

    return () => {
      leaveRoom(
        `${prioritySocketRoomPrefix}${id}_${props.auth.user.businessId}`
      );
      setIsEditTask({
        status: false,
        data: "",
        action: ""
      });
      disconnect();
      setIsErrorAlert({ status: false, messageType: "" });
    };
  }, [id]);
  const handleSocketRoom = () => {
    // Join room
    joinRoom(`${prioritySocketRoomPrefix}${id}_${props.auth.user.businessId}`);
  };

  const updateTaskCB = async data => {
    refreshTodoList(data);
  };
  const removeTaskCB = async data => {
    removeTaskCBTodoList(data);
  };
  const handleSearch = query => {
    /* fetching the input and checking if exist in customerlistarray */
    let filteredTodos;
    if (!query) {
      // setSearchContact(false);
      setSearchLabelTodoQueryResult(null);
    } else {
      filteredTodos = priorityTodo.filter(customer => {
        return customer.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });

      // setSearchContact(true);
      setSearchLabelTodoQueryResult(filteredTodos);
    }
  };
  // const showNotifications = message => {
  //   setToast(true);
  //   setToastMessage(message ? message : "something went wrong");
  //   setTimeout(() => {
  //     setToast(false);
  //     setToastMessage("");
  //   }, 2500);
  // };
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const updateTaskHandler = async (payloadInput, syncGoogle) => {
    try {
      let manuplatedObj = {
        title: payloadInput.title,
        description: payloadInput.description,
        label_ids: payloadInput.label_ids,
        reminderAt: payloadInput.reminderAt,
        priority: payloadInput.priority,
        user_id: payloadInput.user_id,
        project_id: payloadInput.project_id,
        task_id: payloadInput.task_id,
        isDeleted: payloadInput.isDeleted,
        isCompleted: payloadInput.isCompleted,
        isArchived: payloadInput?.isArchived
      };
      if (manuplatedObj && manuplatedObj.project_id) {
        let payload = {
          ...manuplatedObj,
          roomname: `${prioritySocketRoomPrefix}_${props.auth.user.businessId}`
        };
        if (
          manuplatedObj?.title &&
          isEditTask &&
          isEditTask.status &&
          isEditTask?.data?.id
        ) {
          payload = {
            ...payload,
            task_id: isEditTask?.data.id.toString()
          };
        }
        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            setIsEditTask({ status: false, data: "" });
            if (
              payload?.scheduledAt &&
              payload?.scheduledAt != null &&
              syncGoogle
            ) {
              SyncTasktoGoogle(payload);
            }
          } else {
            if (cb.status || cb.message) {
              handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            showNotifications();
          }
        });
      }
    } catch (e) {}
  };
  const doCloseQuickAction = () => {
    setIsQuickActionTask({
      status: false,
      data: "",
      target: "",
      selectedData: ""
    });
  };
  const doUpdateTaskPriority = async (updatedPriority, taskDetails) => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };
    if (updatedPriority) {
      let payload = {
        priority: updatedPriority,
        task_id: isQuickActionTask?.data.id.toString(),
        project_id: isQuickActionTask.data.project.id.toString(),
        section_id: isQuickActionTask.data.section.id
          ? isQuickActionTask.data.section.id.toString()
          : ""
      };

      doUpdateQuickActionTask(payload, handleResponse);
    }
  };
  const doUpdateTaskScheduleDate = async (updatedDueDate, taskDetails) => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };
    let payload = {
      scheduledAt: new Date(updatedDueDate),
      task_id: isQuickActionTask?.data.id.toString(),
      project_id: isQuickActionTask.data.project.id.toString(),
      section_id: isQuickActionTask.data.section.id
        ? isQuickActionTask.data.section.id.toString()
        : ""
    };

    doUpdateQuickActionTask(payload, handleResponse);
  };
  const doUpdateTaskAssign = async udpatedAssignee => {
    const handleResponse = response => {
      if (response && response?.status.toLowerCase() == "ok") {
        setIsQuickActionTask({
          status: false,
          data: "",
          selectedData: "",
          action: "",
          target: ""
        });
      }
    };

    let payload = {
      user_id: udpatedAssignee,
      task_id: isQuickActionTask?.data.id.toString(),
      project_id: isQuickActionTask.data.project.id.toString(),
      section_id: isQuickActionTask.data.section.id
        ? isQuickActionTask.data.section.id.toString()
        : ""
    };

    doUpdateQuickActionTask(payload, handleResponse);
  };
  const doUpdateQuickActionTask = (payloadInput, handleResponse) => {
    if (payloadInput) {
      try {
        let payload = {
          ...payloadInput,
          roomname: `${prioritySocketRoomPrefix}${id}_${props.auth.user.businessId}`
        };

        if (!payloadInput.reminderAt && isQuickActionTask.data.reminderAt) {
          payload = {
            ...payload,
            reminderAt: isQuickActionTask.data.reminderAt
          };
        }

        if (
          !payloadInput.hasOwnProperty("scheduledAt") &&
          isQuickActionTask.data.scheduledAt
        ) {
          payload = {
            ...payload,
            scheduledAt: isQuickActionTask.data.scheduledAt
          };
        }

        updateTask(payload, cb => {
          if (cb.status == "Ok") {
            if (handleResponse) {
              handleResponse({
                status: cb.status
              });
            }
            setIsEditTask({ status: false, data: "" });
            // if (
            //   payload?.scheduledAt &&
            //   payload?.scheduledAt != null &&
            //   syncGoogle
            // ) {
            //   SyncTasktoGoogle(payload);
            // }
            // setTaskinput("");
          } else {
            if (cb.status || cb.message) {
              handleResponse(cb.message);
            }
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            showNotifications();
          }
        });
      } catch (e) {}
    }
  };
  const SyncTasktoGoogle = async taskData => {
    var eventFromNow = {
      summary: taskData.title.toString(),
      description: taskData.description.toString(),
      start: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      end: {
        dateTime: moment(new Date(taskData.scheduledAt)),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    };

    ApiCalendar.createEvent(eventFromNow)
      .then(result => {
        if (result.error) {
          addToast(ToastMessage(result.error.message), {
            appearance: "error",
            autoDismiss: true
          });
        }
        addToast(ToastMessage("Sync to Google"), {
          appearance: "success",
          autoDismiss: true
        });
        return true;
      })
      .catch(error => {
        addToast(
          ToastMessage(
            <IntlMessages id={"task-details.something_went_wrong"} />
          ),
          {
            appearance: "error",
            autoDismiss: true
          }
        );
      });
  };
  const fetchPriority = async () => {
    try {
      SetIsLoader(true);

      const Response = await getPriority(id);

      if (Response) {
        SetIsLoader(false);
        if (Response.error) {
          handleResponse(Response.error.message);
        }
        if (Response.data) {
          var filteredData = Response.data.filter(
            data => !data.isDeleted && !data.isArchived
          );
          setPriorityTodo([...filteredData]);
        }
      }
    } catch (e) {
      SetIsLoader(false);
    }
  };

  const updateIsCompleted = async (projectId, todoId, isCompleted, data) => {
    try {
      if (projectId && todoId) {
        let payload = {
          project_id: projectId.toString(),
          isCompleted: isCompleted,
          task_id: todoId.toString()
        };
        if (data) {
          if (data.scheduledAt) {
            payload = { ...payload, scheduledAt: data.scheduledAt };
          }
          if (data.reminderAt) {
            payload = { ...payload, reminderAt: data.scheduledAt };
          }
        }
        updateTaskHandler(payload, null);
      }
    } catch (e) {}
  };
  // const updateIsCompleted = async (projectId, todoId, isCompleted) => {
  //   try {
  //     if (projectId && todoId) {
  //       let payload = {
  //         project_id: projectId.toString(),
  //         isCompleted: isCompleted,
  //         task_id: todoId.toString()
  //       };
  //       updateTaskHandler(payload, null);
  //     }
  //   } catch (e) {}
  // };
  const refreshTodoList = async newTodo => {
    let todoArr = priorityRef.current;
    // let todoArr = priorityTodo;
    let newTodoToarr = [newTodo];
    let status = false;
    if (todoArr) {
      await Promise.all(
        todoArr &&
          todoArr.map((item, index) => {
            if (item.id == newTodoToarr[0].id) {
              status = true;
              todoArr[index] = { ...todoArr[index], ...newTodoToarr[0] };
            }
          })
      );
      if (!status) {
        todoArr = [...todoArr, newTodo];
      }

      setPriorityTodo([...todoArr]);
    }
  };
  const removeTaskCBTodoList = async newTodo => {
    let todoArr = priorityRef.current;
    // let todoArr = priorityTodo;
    let newTodoToarr = [newTodo];
    let status = false;
    if (todoArr) {
      await Promise.all(
        todoArr &&
          todoArr.map((item, index) => {
            if (item.id == newTodoToarr[0].id) {
              status = true;
              todoArr.splice(index, 1);
            }
          })
      );
      if (!status) {
        todoArr = [...todoArr, newTodo];
      }

      setPriorityTodo([...todoArr]);
    }
  };
  const removeTaskFromList = id => {
    const TodoArr = priorityRef.current;
    TodoArr.map((data, i) => {
      if (data.id == id) {
        setTempTask(TodoArr[i]);
        TodoArr.splice(i, 1);
      }
    });

    setPriorityTodo([...TodoArr]);
  };

  const pushTask = taskData => {
    if (taskData.id == tempTask.id) {
      const TodoArr = priorityTodo;
      TodoArr.push(tempTask);
      setPriorityTodo([...TodoArr]);
    }
  };
  const handleArchiveWorkpsace = async () => {
    if (props?.auth?.user?.businessId) {
      try {
        setIsArchiveloader(true);
        const response = await toggleArchive(props?.auth?.user?.businessId);
        if (response) {
          console.log(response);
          if (response.message) {
            setIsArchiveloader(false);

            if (!checkIsArchivedMessage(response.message)) {
              props.update_business_archive(false);
            }
          }
          if (response.error) {
            handleResponse(response.error.message);
            setIsArchiveloader(false);
            showNotifications();
          }
        }
      } catch (e) {
        setIsArchiveloader(false);
        showNotifications();
      }
    }
  };
  const checkArchive = () => {
    if (props?.auth?.user?.isArchived) {
      return (
        <>
          <div className="workspace-archived">
            <div className="workspace-archived-border flex-x fs-14">
              This workspace has been&nbsp;archived.&nbsp;{""}
              {props?.auth?.user?.businessId &&
                props?.auth?.user?.isBusinessOwner && (
                  <>
                    Click here to&nbsp;
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        display: "flex"
                      }}
                      onClick={handleArchiveWorkpsace}
                    >
                      <b>Unarchive</b>{" "}
                      {isArchiveLoader && <Loader height="18px" width="15px" />}
                    </div>{" "}
                  </>
                )}
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
        <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
          {" "}
          {checkArchive()}
        </div>
        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
      </div>
      <div className="content-panel">
        <div className="row">
          <div
            className="col-lg-1 col-md-1 col-sm-0 col-xs-0"
            style={{ padding: 0 }}
          >
            <div
              className="priority"
              style={{
                backgroundColor: "white",
                height: 60,
                borderBottom: "1px solid #EBEBEB"
              }}
            ></div>
          </div>
          <div
            className="col-lg-10 col-md-10 col-sm-12 col-xs-12"
            style={{ padding: 0 }}
          >
            <div className="roe-card-style">
              <div
                ref={PriorityViewRef}
                className="roe-card-header"
                style={{
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderBottom: "1px solid #EBEBEB"
                }}
              >
                <div
                  className="file-manager-toolbar flex-x"
                  style={{ width: "100%" }}
                >
                  <div
                    className="flex-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="fs-14 demi-bold-text">
                      <i
                        className="fa fa-filter"
                        style={{
                          paddingRight: 5
                        }}
                      />
                      <span
                        style={{
                          fontSize: "15px",
                          color: "black",
                          fontWeight: "600"
                        }}
                      >
                        {<IntlMessages id={`Priority ${id && id}`} />}
                      </span>
                    </div>
                  </div>

                  <div>
                    {/* {isSearch && ( */}
                    {/* <LableWrapper>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: 35,
                        backgroundColor: " #F5F5F5",
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5
                      }}
                    >
                      <img
                        src={SearchHeaderPriorityLableImage}
                        style={{
                          height: 16,
                          width: 22,
                          marginLeft: 3
                        }}
                      ></img>
                    </div>
                    <div className="searchStyle pos-relative">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSearchInput(null);
                          setIsSearch(!isSearch);
                        }}
                        className="search-contant "
                      >
                        <Input
                          value={searchInput}
                          placeholder="Search"
                          className="react-form-search-input"
                          onChange={e => handleSearch(e.target.value)}
                        />
                        
                      </span>
                    </div>
                  </div>
                </LableWrapper> */}
                    {/* )} */}
                    {/* {!isSearch && (
                  <div className="flex center">
                    <div className="mr-1">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSearchInput(null);
                          setIsSearch(!isSearch);
                        }}
                        className="search-contant "
                      >
                        <i className="fas fa-search text-muted"></i>
                      </span>
                    </div>
                  </div>
                )} */}
                  </div>
                </div>
              </div>
            </div>

            <div>
              {isLoader ? (
                <div className="roe-card-style mtb-15 todo-board">
                  <Shimmer />
                </div>
              ) : (
                <PriorityListView
                  doCloseQuickAction={doCloseQuickAction}
                  doUpdateTaskPriority={doUpdateTaskPriority}
                  doUpdateTaskScheduleDate={doUpdateTaskScheduleDate}
                  doUpdateTaskAssign={doUpdateTaskAssign}
                  isQuickActionTask={isQuickActionTask}
                  setIsQuickActionTask={setIsQuickActionTask}
                  history={{ ...props.history }}
                  isEditTask={isEditTask}
                  setIsEditTask={setIsEditTask}
                  updateTaskHandler={updateTaskHandler}
                  taskType="priority"
                  businessId={props.auth.user.businessId}
                  PRIMARY_COLOR={props.topbarTheme.buttonColor}
                  updateIsCompleted={updateIsCompleted}
                  priorityId={id}
                  showNotifications={showNotifications}
                  refreshTodoList={data => refreshTodoList(data)}
                  updateTodoList={newTodo => refreshTodoList(newTodo)}
                  // todoList={
                  //   isSearch && SearchLabelTodoQueryResult
                  //     ? SearchLabelTodoQueryResult
                  //     : priorityTodo
                  // }
                  todoList={priorityTodo}
                  collaboratorList={[]}
                  dummyData={false}
                  currentPriorityId={id}
                  isPriorityComponent={true}
                  removeTaskFromList={id => removeTaskFromList(id)}
                  pushTask={task => pushTask(task)}
                  auth={{ ...props.auth }}
                  LanguageSwitcher={props.LanguageSwitcher}
                />
              )}
              <CustomNotification
                show={toast}
                message={toastMessage}
                closeNotification={() => setToast(false)}
              />
            </div>
          </div>
          <div
            className="col-lg-1 col-md-1 col-sm-0 col-xs-0"
            style={{ padding: 0 }}
          >
            <div
              style={{
                backgroundColor: "white",
                height: 60,
                borderBottom: "1px solid #EBEBEB"
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {
  open_workpsace_alert,
  open_project_alert,
  close_alert,
  update_business_archive
})(Today);
