import React from "react";
import TaskActivityAttachment from "./TaskActivityAttachment";
import IntlMessages from "util/intlMessages";
export const Commented = ({ activity, handleRedirection }) => {
  if (activity.projectComment) {
    return (
      <div
        style={{
          fontSize: "14px",
          wordBreak: "break-word",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          lineClamp: 2,
          textOverflow: "ellipsis",
          overflow: "hidden",
          WebkitLineClamp: 2,
          WebkitFontSmoothing: "antialiased"
        }}
      >
        <span className="task-active-comments-data">
          {" "}
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {activity && activity.actor.name}
          </span>
          &nbsp;{<IntlMessages id={"globalactivity.commentedon"} />}&nbsp;
        </span>

        <span
          onClick={() => {
            handleRedirection({
              redirection_type: "project",
              project: activity.project,
              task: activity.task
            });
          }}
          className="task-active-link"
          style={{
            color: "#1b6ac9",
            textDecoration: "underline",
            maxWidth: "100%",
            cursor: "pointer"
          }}
        >
          <span>{activity.project && activity.project.name}</span>
        </span>
        {activity?.projectComment &&
          activity?.projectComment.comment !== "null" &&
          activity?.projectComment.comment !== null && (
            <div
              style={{
                color: "black",
                fontSize: "13px",
                wordBreak: "break-word",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                lineClamp: 2,
                textOverflow: "ellipsis",
                overflow: "hidden",
                WebkitLineClamp: 6,
                WebkitFontSmoothing: "antialiased"
              }}
              dangerouslySetInnerHTML={{
                __html: activity.projectComment.comment
              }}
            />
          )}

        <TaskActivityAttachment comment={activity.projectComment} />
      </div>
    );
  }
  if (activity.taskComment) {
    return (
      <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <div
          style={{
            fontSize: "14px",
            wordBreak: "break-word",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            lineClamp: 2,
            textOverflow: "ellipsis",
            overflow: "hidden",
            WebkitLineClamp: 2,
            WebkitFontSmoothing: "antialiased"
          }}
        >
          <span className="task-active-comments-data">
            {" "}
            <span style={{ fontWeight: "bold" }}>
              {activity && activity.actor.name}
            </span>
            &nbsp;{<IntlMessages id={"globalactivity.commentedon"} />}&nbsp;
          </span>

          <span
            title={activity.task.title}
            onClick={() => {
              handleRedirection({
                redirection_type: "task",
                project: activity.project,
                task: activity.task
              });
            }}
            className="task-active-link"
            style={{
              color: "#1b6ac9",
              textDecoration: "underline",
              maxWidth: "100%",
              cursor: "pointer"
            }}
          >
            <span>{activity && activity.task.title} </span>
          </span>
        </div>
        {/* <span style={{ justifyContent: "center" }}>-</span> */}
        {/* <div
          title="project"
          className="task-active-link"
          onClick={() => {
            handleRedirection({
              redirection_type: "project",
              project: activity.project
            });
          }}
          style={{
            color: "#1b6ac9",
            textDecoration: "underline",
            maxWidth: "55%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
            whiteSpace: "nowrap"
          }}
        >
          {activity && activity.project.name}
        </div> */}
        {activity?.taskComment &&
          activity?.taskComment.comment !== "null" &&
          activity?.taskComment.comment !== null && (
            <div
              style={{
                color: "black",
                fontSize: "13px",
                wordBreak: "break-word",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                lineClamp: 2,
                textOverflow: "ellipsis",
                overflow: "hidden",
                WebkitLineClamp: 6,
                WebkitFontSmoothing: "antialiased"
              }}
              dangerouslySetInnerHTML={{
                __html: activity && activity.taskComment.comment
              }}
            ></div>
          )}
        <TaskActivityAttachment comment={activity.taskComment} />
      </div>
    );
  }
};
