import React from "react";
import { profileBackground } from "helper/methods/getUserAvatarColor";

export default function TaskAssignee({ taskAssign }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "3px 10px  3px 0px"
        }}
      >
        {taskAssign.thumbnail ? (
          <img
            alt=""
            title={taskAssign.name}
            id="userAssigned"
            src={taskAssign.thumbnail ? taskAssign.thumbnail : null}
            style={{
              height: 20,
              width: 20,
              borderRadius: "50%",
              border: "1px solid white",
              objectFit: "cover"
            }}
          />
        ) : (
          <div
            title={taskAssign.name}
            style={profileBackground(
              taskAssign.name ? taskAssign.name[0] : taskAssign.email[0],
              20,
              20,
              12
            )}
          >
            <div>
              {taskAssign.name
                ? taskAssign.name[0].toUpperCase()
                : taskAssign.email[0].toUpperCase()}
            </div>
          </div>
        )}
        <div
          className=" fs-12"
          style={{
            paddingLeft: 2,
            display: "inline-block",
            maxWidth: "115px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {taskAssign.name}
        </div>
      </div>
    </>
  );
}
