import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  profileBackground,
  profileImage
} from "helper/methods/getUserAvatarColor";
function TaskactivityList(props) {
  const [taskActivity, setTaskActivity] = useState([]);
  const { lastActivity } = props;
  useEffect(() => {
    setTaskActivity(props.taskActivityArr);
  }, [props]);

  const ActivityTypes = {
    TASK_ADD: "taskadd",
    TASK_ASSIGN: "taskassign",
    NAME_CHANGE: "namechange",
    LABEL_CHANGE: "labelchange",
    PRIORITY_CHANGE: "prioritychange",
    CHECHED_CHANGE: "checkedchange",
    DUEDATE_CHANGE: "duedatechange",
    SUBTASK_ADD: "subtaskadd",
    SUBTASK_DELETE: "subtaskdelete",
    CHECKLIST_ADD: "checklistadd"
  };

  const chooseBGColor = item => {
    if (item.activity_type == ActivityTypes.TASK_ADD) {
      return "#ff6b6b";
    } else if (item.activity_type == ActivityTypes.TASK_ASSIGN) {
      return "#6badff";
    } else if (item.activity_type == ActivityTypes.NAME_CHANGE) {
      return "#ef6bff";
    } else if (item.activity_type == ActivityTypes.LABEL_CHANGE) {
      return "#27cea7";
    } else if (item.activity_type == ActivityTypes.PRIORITY_CHANGE) {
      return "#ecba00";
    } else if (item.activity_type == ActivityTypes.CHECHED_CHANGE) {
      return "#ff6b6b";
    } else if (item.activity_type == ActivityTypes.DUEDATE_CHANGE) {
      return "#6badff";
    } else if (item.activity_type == ActivityTypes.SUBTASK_ADD) {
      return "#ef6bff";
    } else if (item.activity_type == ActivityTypes.SUBTASK_DELETE) {
      return "#ecba00";
    }
  };

  const generateSentense = item => {
    if (item.activity_type == ActivityTypes.TASK_ADD) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          added new task <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.TASK_ASSIGN) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          assigned task to <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.NAME_CHANGE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          changes task name from <b>{item.past_value}</b> to <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.LABEL_CHANGE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          added label <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.PRIORITY_CHANGE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          changed priority from <b>{item.past_value.replace("_", " ")} </b>
          to <b>{item.value.replace("_", " ")}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.CHECHED_CHANGE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          marked task as <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.DUEDATE_CHANGE) {
      if (item.past_value) {
        return (
          <span className="fs-14 text-muted">
            {" "}
            added
            <span style={{ fontWeight: "bold", fontSize: 13 }}>
              {moment(item.value).format("DD MMM YYYY")}
            </span>{" "}
            due date
          </span>
        );
      }
      return (
        <span className="fs-14 text-muted">
          {" "}
          changed due date from{" "}
          <span style={{ fontWeight: "bold", fontSize: 13 }}>
            {moment(item.past_value).format("DD MMM YYYY")}
          </span>{" "}
          to{" "}
          <span style={{ fontWeight: "bold", fontSize: 13 }}>
            {moment(item.value).format("DD MMM YYYY")}
          </span>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.SUBTASK_ADD) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          added new subtask <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.SUBTASK_DELETE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          deleted subtask <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.CHECKLIST_ADD) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          added new checklist <b>{item.value}</b>
        </span>
      );
    }
  };

  return (
    <div>
      {taskActivity.map((data, i) => {
        return (
          <div
            key={i}
            style={
              lastActivity
                ? { border: "none" }
                : { borderBottom: "1px solid #e8e8e8" }
            }
          >
            {lastActivity}
            <div className="ptb-10 ma-0">
              {data?.user?.url ? (
                <div>
                  <img
                    style={profileImage}
                    src={data?.user?.url && data?.user?.url}
                  />
                </div>
              ) : (
                <div
                  style={profileBackground(
                    data?.user?.name && data?.user?.name
                  )}
                >
                  {data?.user?.name && data?.user?.name.charAt(0).toUpperCase()}
                </div>
              )}
              <div className="mlr-10 flex-1">
                <div>
                  <span className="fs-14 demi-bold-text">
                    {data.user?.name}
                  </span>
                  {generateSentense(data)}
                </div>
                <div>
                  <p className="text-muted" style={{ fontSize: 11 }}>
                    <small>
                      {/* {moment(data.createdAt).format("DD MMM YYYY hh:mm a")} */}
                      {moment(data.createdAt).format("DD MMM YYYY") ==
                      moment().format("DD MMM YYYY")
                        ? `${moment(data.createdAt).fromNow()}`
                        : `${moment(data.createdAt).format(
                            "DD MMM YYYY hh:mm a"
                          )}`}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TaskactivityList;
