/** @format */

import React, { useState } from "react";
import { getAllActivity } from "helper/services/globalactivityServices";
import moment from "moment";
import ChangeWorkspaceAlert from "util/ChangeWorkspaceAlert";
import LinearLoader from "components/loader/LinearLoader";
import { searchNotFound } from "helper/constant";
import SingleActivityRightView from "components/Activity/SingleActivityRightView";
import SingleActivityLeftView from "components/Activity/SingleActivityLeftView";
import ToastMessage from "components/notifications/ToastMessage";
import { handleSwitchWorkspace } from "util/FetchProjectAndLabel";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import NewTaskActivityShimmer from "components/shimmer/NewTaskActivityShimmer";
import IntlMessages from "util/intlMessages";

const TaskActivity = props => {
  const [isAlertActionLoader, setIsAlertActionLoader] = useState(false);
  const [isAlertAction, setIsAlertAction] = useState({
    status: false,
    data: ""
  });
  const [activities, setActivities] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [isNextPageLoader, setIsNextPageLoader] = useState(false);

  useEffect(() => {
    fetchActivity();

    return () => {
      setCurrentPage(0);
      setIsAlertAction({ status: false, data: "" });
    };
  }, []);

  const { addToast } = useToasts();
  const ActivityType = {
    COMMENTADD: "comment_add",
    TASKCOMPLETE: "task_complete",
    TASKADD: "task_add",
    ADDINTOBUSINESS: "add_into_business",
    REMOVEFROMBUSINESS: "remove_from_business",
    ADDINTOPROJECT: "add_into_project",
    REMOVEFROMPROJECT: "remove_from_project"
  };
  const doSwitchBusiness = async () => {
    if (isAlertAction.status) {
      setIsAlertActionLoader(true);
      const response = await handleSwitchWorkspace(
        isAlertAction.data.business.id,
        id => handleRedirection(isAlertAction.data, id),
        showNotifications
      );

      if (response.status) {
        setIsAlertActionLoader(false);
      } else {
        console.error(response.error);
      }
    }
  };
  const handleRedirection = (item, newBusinessId) => {
    if (item.redirection_type && item.redirection_type === "task") {
      if (item.project.id && item.task.id && item.business.id) {
        if (
          item.business.id == props.auth.user.businessId ||
          item.business.id == newBusinessId
        ) {
          return props.history.push(`/task-details/${item.task.id}`);
        } else {
          setIsAlertAction({ status: true, data: item });
        }
      }
      return false;
    }
    if (item.redirection_type && item.redirection_type === "taskcomment") {
      if (item.project.id && item.task.id && item.business.id) {
        if (
          item.business.id == props.auth.user.businessId ||
          item.business.id == newBusinessId
        ) {
          return props.history.push(`/task-details/${item.task_id}`);
        } else {
          setIsAlertAction({ status: true, data: item });
        }
      }
      return false;
    }
    if (item.redirection_type && item.redirection_type === "business") {
      if (item.business.id) {
        if (
          item.business.id == props.auth.user.businessId ||
          item.business.id == newBusinessId
        ) {
          return props.history.push(`/business/${item.business.id}`);
        } else {
          setIsAlertAction({ status: true, data: item });
        }
      }
      return false;
    }
    if (item.redirection_type && item.redirection_type === "project") {
      if (item.project.id) {
        if (
          item.business.id == props.auth.user.businessId ||
          item.business.id == newBusinessId
        ) {
          return props.history.push(`/project/${item.project.id}`);
        } else {
          setIsAlertAction({ status: true, data: item });
        }
      }
      return false;
    }
    if (item.redirection_type && item.redirection_type === "projectcomment") {
      if (item.project.id) {
        if (
          item.business.id == props.auth.user.businessId ||
          item.business.id == newBusinessId
        ) {
          return props.history.push(`/project/${item.project.id}`, {
            commentModal: true
          });
        } else {
          setIsAlertAction({ status: true, data: item });
        }
      }
      return false;
    }
  };

  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };

  const fetchActivity = async (isNextPage = false) => {
    try {
      !isNextPage && setIsLoader(true);
      let nextPage = currentPage + 1;
      isNextPage && setIsNextPageLoader(true);
      const { data, error } = await getAllActivity(10, nextPage);
      if (data) {
        const responseData = data.data;
        const formattedData = groupDataToTime(responseData);
        if (formattedData.status) {
          if (hasMorePages && nextPage > 1) {
            //  mergin data for elements which have  same type of date but are more than one array elemnt to one
            let distinctData = await getDistinctDateArrayData(
              activities?.length > 0
                ? [...activities, ...formattedData.data]
                : [...formattedData.data]
            );
            let dataArr = await doUpdateActivityPosition(distinctData);
            setActivities([...dataArr]);
          } else {
            let dataArr = await doUpdateActivityPosition(formattedData.data);
            setActivities([...dataArr]);
          }
        }
        setIsLoader(false);
        isNextPage && setIsNextPageLoader(false);
        setHasMorePages(!data.isLastPage);
        setCurrentPage(nextPage);
      }
      if (error) {
        isNextPage && setIsNextPageLoader(false);
        showNotifications();
      }
    } catch (error) {
    } finally {
      isNextPage && setIsNextPageLoader(false);
      setIsLoader(false);
    }
  };
  const getDistinctDateArrayData = activityArray => {
    //  mergin data for elements which have  same type of date but are more than one array elemnt to one
    if (activityArray?.length > 0) {
      var output = [];
      activityArray.forEach(function(item) {
        var existing = output.filter(function(v, i) {
          return v.date == item.date;
        });

        if (existing.length) {
          var existingIndex = output.indexOf(existing[0]);
          output[existingIndex].activity = output[
            existingIndex
          ].activity.concat(item.activity);
          output[existingIndex].activity = [
            ...new Set(output[existingIndex].activity)
          ];
        } else {
          item.activity = [...item.activity];
          output.push(item);
        }
      });
      return output;
    }
  };
  const doUpdateActivityPosition = (formattedData = []) => {
    // position for displaying at frontent
    let PostFormatData = formattedData;
    formattedData &&
      formattedData.map((act, ind) => {
        if (act.activity) {
          const result = doGetPlacementForActivity([...act.activity]);
          PostFormatData[ind].activity = [...result];
        }
      });

    return PostFormatData;
  };
  const groupDataToTime = (data = []) => {
    // this gives an object with dates as keys
    try {
      const groups = data.reduce((groups, activity) => {
        // const date = moment(activity.createdAt).format("DD-MM-YYYY[T]HH:mm a");
        const keyDate = moment(activity.createdAt).format("DD-MM-YYYY");
        if (!groups[keyDate]) {
          groups[keyDate] = [];
        }
        groups[keyDate].push(activity);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map(date => {
        return {
          date,
          activity: groups[date]
        };
      });

      return { status: true, data: groupArrays };
    } catch (e) {
      return {
        status: false
      };
    }
  };
  const doGetPlacementForActivity = (activityarr, pointer = "left") => {
    let currentPointer = pointer;
    let dataArr = [...activityarr];
    let currentProject,
      currentWorkspace = "";
    if (activityarr?.length > 0) {
      activityarr.map((activity, index, array) => {
        if (index == 0) {
          dataArr[0] = { ...dataArr[0], position: currentPointer };
          currentProject = activity.project;
          currentWorkspace = activity.business;
        }
        if (index > 0) {
          if (
            currentProject &&
            activity.project &&
            currentProject.id == activity.project.id
          ) {
            dataArr[index] = { ...dataArr[index], position: currentPointer };
          } else {
            currentProject = activity.project;
            currentPointer = currentPointer == "left" ? "right" : "left";
            dataArr[index] = { ...dataArr[index], position: currentPointer };
          }
        }
      });
      return dataArr;
    }
  };
  const getDataToArray = data => {
    if (data) {
      let dataArr = [...data];

      return dataArr;
    }
  };

  const getDayType = givenDate => {
    try {
      let formattedDate = moment(givenDate, "DD-MM-YYYY[T]HH:mm a");
      if (isTodayFunc(formattedDate)) {
        return <IntlMessages id={"globalactivity.today"} />;
      }
      if (isYesterdayFunc(formattedDate)) {
        return <IntlMessages id={"globalactivity.yesterday"} />;
      }
      if (isTommorrowFunc(formattedDate)) {
        return <IntlMessages id={"globalactivity.tommorrow"} />;
      }
      return moment(formattedDate).format("DD MMM YYYY");
    } catch (e) {}
  };
  function isTodayFunc(momentDate) {
    let TODAY = moment()
      .clone()
      .startOf("day");

    return momentDate.isSame(TODAY, "d");
  }
  function isYesterdayFunc(momentDate) {
    let YESTERDAY = moment()
      .clone()
      .subtract(1, "days")
      .startOf("day");
    return momentDate.isSame(YESTERDAY, "d");
  }
  function isTommorrowFunc(momentDate) {
    let TOMMORROW = moment()
      .clone()
      .add(1, "days")
      .startOf("day");
    return momentDate.isSame(TOMMORROW, "d");
  }
  function isWithinAWeekFunc(momentDate) {
    let A_WEEK_OLD = moment()
      .clone()
      .subtract(7, "days")
      .startOf("day");

    return momentDate.isAfter(A_WEEK_OLD);
  }
  const getMessageType = item => {
    if (item.redirection_type === "task") {
      return (
        <>
          This task belongs to{" "}
          {item.business.title ? <b>{item.business.title}</b> : "different"}{" "}
          workspace. Switch the workspace to see the task details now.
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "project") {
      return (
        <>
          This project belongs to{" "}
          {item.business.title ? <b>{item.business.title}</b> : "different"}{" "}
          workspace. Switch the workspace to see the project details now.
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "business") {
      return (
        <>
          Switch the workspace to see the{" "}
          {item.business.title ? <b>{item.business.title}</b> : "different"}{" "}
          workspace details now.
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "taskcomment") {
      return (
        <>
          This task comment belongs to{" "}
          {item.business.title ? <b>{item.business.title}</b> : "different"}{" "}
          workspace. Switch the workspace to see the task comment details now.
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "projectcomment") {
      return (
        <>
          This project comment belongs to{" "}
          {item.business.title ? <b>{item.business.title}</b> : "different"}{" "}
          workspace. Switch the workspace to see the project comment details
          now.
        </>
      );
    }
  };
  const isSame_Workspace_Project_time = (currentData, index, array) => {
    try {
      let prevRecord = array[index - 1];
      const isSameWorkspace =
        currentData?.business &&
        prevRecord?.business &&
        currentData?.business.id == prevRecord.business.id;
      const isSameProject =
        currentData?.project &&
        prevRecord?.project &&
        currentData?.project.id == prevRecord.project.id;
      if (index > 0 && isSameWorkspace && isSameProject) {
        return true;
      }
      return false;
    } catch (e) {
      // console.log(e)
      return false;
    }
  };
  const ActivitiesList = ({ activity }) => {
    if (activity.length > 0) {
      return (
        <>
          {activity &&
            activity.map((dateActivity, index, dateActivityarray) => {
              let { activity, date } = dateActivity;
              let dayType = getDayType(date);

              return (
                <>
                  <div
                    style={{
                      fontSize: 18,
                      color: "black",
                      fontWeight: "600",
                      marginBottom: "5px"
                    }}
                  >
                    {dayType}
                  </div>

                  {activity &&
                    activity.map((singleActivityData, i, arr) => {
                      let isSameStatus = isSame_Workspace_Project_time(
                        singleActivityData,
                        i,
                        arr
                      );
                      if (singleActivityData.position == "left") {
                        return (
                          <>
                            <SingleActivityLeftView
                              handleRedirection={handleRedirection}
                              activity={singleActivityData}
                              isHeaderVisible={!isSameStatus}
                            />
                          </>
                        );
                      } else {
                        return (
                          <SingleActivityRightView
                            handleRedirection={handleRedirection}
                            activity={singleActivityData}
                            isHeaderVisible={!isSameStatus}
                          />
                        );
                      }
                    })}
                </>
              );
            })}
        </>
      );
    }
  };
  return (
    <>
      <div
        style={{
          height: "100vh"
        }}
      >
        <div className="row task-activity">
          <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
          <div
            className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"
            style={{
              backgroundColor: "white",
              width: "100%",
              height: "100%",
              textAlign: "center",
              padding: "0px 0px",
              marginBottom: "260px",
              marginTop: "50px",
              boxShadow: "0px 0px 6px #00000029"
            }}
          >
            {isLoader ? (
              <NewTaskActivityShimmer />
            ) : (
              <>
                {" "}
                <div
                  style={{
                    fontSize: 26,
                    color: "black",
                    fontWeight: "600",
                    padding: "20px 0px",
                    borderBottom: "1px solid #ebebeb"
                  }}
                >
                  {<IntlMessages id={"globalactivity.latestactivity"} />}
                </div>
                <div style={{ padding: "15px 40px" }}>
                  {activities && activities?.length ? (
                    <>
                      <ActivitiesList activity={activities} />

                      {hasMorePages && (
                        <div
                          style={{
                            color: props.PRIMARY_COLOR,
                            fontSize: 14,
                            textDecoration: isNextPageLoader
                              ? "none"
                              : "underline",
                            textAlign: "center",
                            cursor: isNextPageLoader
                              ? "not-allowed"
                              : "pointer",
                            paddingBottom: 10
                          }}
                        >
                          {isNextPageLoader ? (
                            <LinearLoader />
                          ) : (
                            <div
                              style={{
                                height: 100,
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: "2.09rem"
                              }}
                              onClick={() =>
                                hasMorePages ? fetchActivity(hasMorePages) : {}
                              }
                            >
                              <IntlMessages id={"globalsearchbar.viewmore"} />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        height: "75vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <div>
                        <img
                          src={searchNotFound}
                          style={{
                            width: "400px",
                            height: "200px"
                          }}
                        />
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "20px",
                            marginBottom: "70px",
                            color: "#808080"
                          }}
                        >
                          {" "}
                          <IntlMessages id={"globalactivity.noactivities"} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
      </div>
      <ChangeWorkspaceAlert
        loader={isAlertActionLoader}
        action="Switch Workspace"
        message={isAlertAction.status && getMessageType(isAlertAction.data)}
        PRIMARY_COLOR={props.PRIMARY_COLOR}
        show={isAlertAction.data && isAlertAction.status}
        onConfirm={() => {
          doSwitchBusiness();
        }}
        onCancel={() => setIsAlertAction({ id: "", status: false })}
      />
    </>
  );
};
const mapStateToProps = state => {
  return {
    ...state,
    PRIMARY_COLOR: state.themeChanger.topbarTheme.buttonColor
  };
};
export default connect(mapStateToProps, null)(TaskActivity);
