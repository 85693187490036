import React, { useEffect, useRef, useState } from "react";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import { allPriority, getPriorityPath, getPriorityValue } from "constatnt";
import moment from "moment";
import { connect } from "react-redux";
import PriorityPopover from "components/priority/priorityPopover";
import Select from "react-select";
import DueDatePicker from "components/DatePickers/DueDatePicker";
import Loader from "components/loader/Loader";
import chroma from "chroma-js";
import DatePicker from "react-datepicker";
import BusinessListShimmer from "components/shimmer/BusinessListShimmer.jsx";
import NewTaskDescriptionWrapper from "components/newTask/NewTaskDescriptionWrapper.style";
import TaskDescriptionEditor from "components/newTask/TaskDescriptionEditor";
import { basecamp, threeDot_Icon } from "helper/constant";
import { ReactComponent as RepeatSvgIcon } from "assets/images/recurrenceIcon.svg";
import TaskTitleCard from "components/TaskDetails/TaskTitleCard.jsx";
import TaskDetailsPopoverMenu from "components/TaskDetails/TaskDetailsPopoverMenu";
import { EDIT_ACTION_CONSTANT } from "./Constant";
import { iconTag } from "helper/constant";
import IconTint from "react-icon-tint";
import { getColorCode } from "components/projects/utils/color";
import Shimmer from "components/shimmer/Shimmer";
import TaskDetailsActivityModal from "components/TaskDetails/TaskDetailsActivityModal.jsx";
import TaskDetailsCardDropDownWrapper from "./TaskDetailsCardDropDownWrapper.style";
import { repeatTypeOptions } from "util/data/taskDetailsutilData";
import LabelsPopover from "components/Labels/LabelsPopover";
import id from "date-fns/esm/locale/id/index.js";
import "assets/css/taskdetailspopover.css";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import { de, nl } from "date-fns/locale";
const TaskDetailsCard = props => {
  const {
    taskDetails,
    isEditTask,
    setIsEditTask,
    updateTaskDetails,
    updateIsCompleted,
    isAssignTaskModal,
    setIsAssignTaskModal,
    setIsAddChecklist,
    showNotifications,
    doArchiveTask,
    openCustomAlert,
    AllowAddPreviousDateTask,
    topbarTheme,
    isFetchDetailsLoader,
    collaboratorListArr,
    PRIMARY_COLOR,
    updateTaskPriority,
    updateTaskSchedule,
    updateTaskLabels,
    updateTaskLabelName
  } = props;
  const titleRef = useRef(null);
  const [PriorityInput, setPriorityInput] = useState("no_priority");
  const [isPriority, setIsPriority] = useState(false);
  const [labelsInput, setLabelsInput] = useState([]);
  const [isLabels, setIsLabels] = useState(false);
  const [descriptionInput, setDescriptionInput] = useState("");
  const [dateInput, setDateInput] = useState("");
  const [isDatePicker, setIsDatePicker] = useState(false);
  const [isUpdateLoader, setIsUpdateLoader] = useState(false);
  const [taskTitleInput, setTaskTitleInput] = useState("");
  const [isTaskTitleEdit, setIsTaskTitleEdit] = useState(false);
  const [isTaskDescriptionEdit, setIsTaskDescriptionEdit] = useState(false);
  const [isTaskDetailsActivityModal, setIsTaskDetailsActivityModal] = useState(
    false
  );
  const [isTaskDetailsPopover, setIsTaskDetailsPopover] = useState(false);
  const [repeatDate, setRepeatDate] = useState("");
  const [isRepeatDropdownMenuOpen, setIsRepeatDropdownMenuOpen] = useState(
    false
  );
  const [isRepeatDatePicker, setIsRepeatDatePicker] = useState(false);
  const CONSTANTS_RECURRENCE = {
    forever: "forever",
    until: "until"
  };
  const [recurrenceType, setRecurrenceType] = useState("forever");
  const [repeatTypeValue, setRepeatTypeValue] = useState(repeatTypeOptions[0]);
  const [locale, setLocale] = useState();

  useEffect(() => {
    if (taskDetails) {
      setPriorityInput(taskDetails.priority);
      setDescriptionInput(taskDetails.description || "");
      setDateInput(taskDetails.scheduledAt || "");
      setLabelsInput(taskDetails.labels || []);
      setTaskTitleInput(taskDetails.title);
      setRepeatTypeValue(getRepeatTypeOption(taskDetails.repeatType));
      setRecurrenceType(taskDetails.repeatTill ? "until" : "forever");
      setRepeatDate(
        taskDetails.repeatTill ? new Date(taskDetails.repeatTill) : ""
      );
    }
    if (isEditTask && isTaskTitleEdit) {
      titleRef.current.focus();
      titleRef.current.select();
    }
    if (props.language.locale == "de") {
      setLocale(de);
    } else if (props.language.locale == "nl") {
      setLocale(nl);
    } else {
      setLocale(props.language.locale);
    }

    return () => {
      setPriorityInput("no_priority");
      setDescriptionInput("");
      setDescriptionInput("");
      setDateInput("");
      setLabelsInput([]);
      setRepeatTypeValue(repeatTypeOptions[0]);
      // setIsTaskDetailsActivityModal(false);
      // setIsTaskDescriptionEdit(false);
      setIsTaskDetailsPopover(false);
    };
  }, [isEditTask]);
  const getRepeatTypeOption = reqType => {
    if (reqType) {
      let filterData = repeatTypeOptions.filter(type => type.value === reqType);
      if (filterData) {
        return filterData[0];
      }

      return false;
    }
  };

  const onChangeTaskLabel = data => {
    setLabelsInput(data);
    updateTaskLabels(data, handleRespone);
  };
  const ids = labelsInput && labelsInput.map(a => a.id);

  const selectLabelHandler = label => {
    if (labelsInput && ids && ids.includes(label.id)) {
      var arr = [...labelsInput];
      arr &&
        arr.map((data, i) => {
          if (data.id == label.id) {
            return arr.splice(i, 1);
          }
          return;
        });
      onChangeTaskLabel(arr);
    } else onChangeTaskLabel([...labelsInput, label]);
  };
  // const toggleLabelSelection = () => {
  //   if (updateTask) {
  //     toggleAction();
  //   }
  //   setLabelSelection(!labelSelection);
  // };

  const toggleTaskDetailsMenu = () => {
    setIsTaskDetailsPopover(false);
  };
  const toggleTaskDetailsModal = () => {
    setIsEditTask(false);
    setIsTaskDetailsActivityModal(false);
  };
  const handleUpdateTaskDetails = () => {
    let payload = {
      priority: PriorityInput,
      description: descriptionInput ? descriptionInput : "",
      scheduledAt: dateInput ? dateInput : "",
      title: taskTitleInput ? taskTitleInput : taskDetails.title
    };
    let data = getRepeatDates();

    if (data) {
      payload = { ...payload, ...data };
    }
    // taskDetails?.repeatType && setRepeatTypeValue(taskDetails.repeatType)
    // taskDetails?.repeatTill &&setRecurrenceType(taskDetails.repeatTill?"until":'forever')
    // taskDetails?.repeatTill && setRepeatDate(taskDetails.repeatTill?new Date(taskDetails.repeatTill):'forever')

    setIsUpdateLoader(true);

    updateTaskDetails(payload, handleRespone);
  };
  const handleMenuAction = action => {
    handleEditTask(action);
  };
  const doUpdateTaskPriority = priority => {
    setPriorityInput(priority);
    !isEditTask && updateTaskPriority(priority, handleRespone);
  };
  const doUpdateLabelsToTask = labels => {
    let labelsArr = taskDetails.labels;
    labelsArr.map((data, i) => {
      let status = false;
      if (data.id == labels.id) {
        labelsArr[i] = labels;
        status = true;
      }
      if (status == true) {
        updateTaskLabelName(labelsArr);
      }
    });
  };
  const getRepeatDates = () => {
    let payload = {
      repeatTill: "",
      repeatType: ""
    };

    if (
      dateInput &&
      repeatTypeValue &&
      repeatTypeValue.value !== repeatTypeOptions[0].value
    ) {
      if (
        repeatDate &&
        repeatDate !== null &&
        repeatDate !== "" &&
        repeatDate !== undefined
      ) {
        payload.repeatTill = repeatDate;
        payload.repeatType = repeatTypeValue.value;
      } else {
        payload.repeatTill = null;
        payload.repeatType = repeatTypeValue.value;
      }
    } else {
      payload.repeatTill = null;
      payload.repeatType = repeatTypeOptions[0].value;
    }
    return payload;
  };
  const doUpdateTaskScheduleDate = updatedDate => {
    setDateInput(updatedDate);
    updateTaskSchedule(updatedDate, Response => {
      if (Response.status == "Ok") {
        handleToggleDatePicker();
        setIsUpdateLoader(false);
        setIsEditTask(false);

        showNotifications(
          <IntlMessages id={"task-details.task_updated_successfully"} />,
          "success"
        );
      } else {
        setIsUpdateLoader(false);
        showNotifications();
      }
    });
  };
  const handleEditTask = action => {
    if (EDIT_ACTION_CONSTANT.assign == action) {
      setIsAssignTaskModal(!isAssignTaskModal);
    } else if (EDIT_ACTION_CONSTANT.archiveTask == action) {
      doArchiveTask();
    } else if (EDIT_ACTION_CONSTANT.deleteTask == action) {
      openCustomAlert(true, "delete", "task", "", taskDetails.title);
    } else if (EDIT_ACTION_CONSTANT.activity == action) {
      setIsTaskTitleEdit(false);
      setIsTaskDetailsActivityModal(true);
    } else if (EDIT_ACTION_CONSTANT.prirority == action) {
      setPriorityInput(taskDetails.priority);
      setIsPriority(true);
    } else if (EDIT_ACTION_CONSTANT.schedule == action) {
      setDateInput(taskDetails.scheduledAt || "");
      setIsDatePicker(true);
      !isEditTask && setIsEditTask(false);
    } else if (EDIT_ACTION_CONSTANT.labels == action) {
      setIsLabels(!isLabels);
      setLabelsInput(taskDetails.labels);
      // setDateInput(taskDetails.scheduledAt || "");
      // !isEditTask && setIsEditTask(false);
    } else if (!isEditTask) {
      !isEditTask && setIsEditTask(!isEditTask);
      !isEditTask && setTaskTitleInput(taskDetails?.title);
      setRepeatTypeValue(getRepeatTypeOption(taskDetails?.repeatType));
      setRecurrenceType(taskDetails?.repeatTill ? "until" : "forever");
      setRepeatDate(
        taskDetails?.repeatTill ? new Date(taskDetails?.repeatTill) : ""
      );
      if (EDIT_ACTION_CONSTANT.checklist == action) {
        setIsEditTask(false);
        setIsAddChecklist(true);
        setIsTaskTitleEdit(false);
        setIsTaskDescriptionEdit(false);
        // setIsDatePicker(true)
      }
      if (EDIT_ACTION_CONSTANT.prirority == action) {
        setIsPriority(true);
        setIsTaskTitleEdit(false);
        setIsTaskDescriptionEdit(false);
      }
      if (EDIT_ACTION_CONSTANT.repeat == action) {
        setIsTaskTitleEdit(false);
        setIsTaskDescriptionEdit(false);
        setRepeatTypeValue(getRepeatTypeOption(taskDetails.repeatType));
        setRecurrenceType(taskDetails.repeatTill ? "until" : "forever");
        setRepeatDate(
          taskDetails.repeatTill ? new Date(taskDetails.repeatTill) : "forever"
        );
      }
      if (EDIT_ACTION_CONSTANT.labels == action) {
        // setIsPriority(true)
        setIsTaskTitleEdit(false);
        setIsTaskDescriptionEdit(false);
      }
      // if (EDIT_ACTION_CONSTANT.schedule == action) {
      //   // setIsPriority(true)
      //   setIsTaskTitleEdit(false);
      //   setIsTaskDescriptionEdit(false);
      //   setIsDatePicker(true);
      // }

      if (EDIT_ACTION_CONSTANT.attachment == action) {
        props.history.push(`/task-attachments/${taskDetails.id}`);
        setIsTaskTitleEdit(false);
        setIsTaskDescriptionEdit(false);
      }
      if (EDIT_ACTION_CONSTANT.title == action) {
        setIsTaskTitleEdit(true);
        setIsTaskDescriptionEdit(false);
      }
      if (EDIT_ACTION_CONSTANT.description == action) {
        setIsTaskTitleEdit(false);
        setTaskTitleInput(taskDetails.title);
        setIsTaskDescriptionEdit(true);
      }
    }
  };
  const handleToggleDatePicker = () => {
    if (isDatePicker) {
      setIsDatePicker(!isDatePicker);
    }
    if (!isDatePicker) {
      setDateInput(dateInput ? dateInput : taskDetails.scheduledAt);
      !isEditTask
        ? handleEditTask(EDIT_ACTION_CONSTANT.schedule)
        : setIsDatePicker(!isDatePicker);
    }
  };
  const handleDiscardChanges = () => {
    setIsUpdateLoader(false);
    setIsEditTask(false);
    setPriorityInput("no_priority");
    setDescriptionInput("");
    setDateInput("");
    setLabelsInput([]);
    setTaskTitleInput("");
    taskDetails?.repeatType && setRepeatTypeValue(taskDetails.repeatType);
    taskDetails?.repeatTill &&
      setRecurrenceType(taskDetails.repeatTill ? "until" : "forever");
    taskDetails?.repeatTill &&
      setRepeatDate(
        taskDetails.repeatTill ? new Date(taskDetails.repeatTill) : "forever"
      );
  };
  const handleRespone = Response => {
    if (Response.status == "Ok") {
      setIsUpdateLoader(false);
      setIsEditTask(false);
      showNotifications(
        <IntlMessages id={"task-details.task_updated_successfully"} />,
        "success"
      );
    } else {
      setIsUpdateLoader(false);
      showNotifications();
    }
  };

  const handleChangerepeatDate = date => setRepeatDate(new Date(date));

  const repeatTypeSelectStyle = {
    control: (base, state) => ({
      ...base,
      background: "#f8f8f8",
      // match with the menu
      borderRadius: 5,
      // Overwrittes the different states of border
      borderColor: "#ebebeb",
      // Removes weird border around container
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "#ebebeb"
      }
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 5,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? topbarTheme.buttonColor
          : isFocused
          ? `${topbarTheme.buttonColor}30`
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast("grey", "white") > 2
            ? "white"
            : "black"
          : "#808080",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6")
        }
      };
    },
    singleValue: styles => ({ ...styles, color: "black" })
  };

  const handleChange = repeatTypeOptions => {
    [repeatTypeOptions].map(data => {
      setRepeatTypeValue(data);
      setIsRepeatDropdownMenuOpen(false);
      return;
    });
    if (!isEditTask) {
      handleEditTask(EDIT_ACTION_CONSTANT.repeat);
    }
    setIsRepeatDropdownMenuOpen(false);
  };

  const LabelsComponent = labelsArr => {
    let sortLabels = [];
    sortLabels =
      labelsArr && labelsArr.sort((a, b) => a.name.localeCompare(b.name));
    return (
      <>
        {sortLabels &&
          sortLabels
            .sort((a, b) => a.name < b.name)
            .map((item, index) => {
              return (
                <>
                  <div
                    className="my-day-label flex-x  fs-14 nevy--text align-center overflow-hidden"
                    style={{
                      marginRight: 10
                    }}
                  >
                    <div
                      key={index}
                      // onClick={() => toggleLabelSelection()}
                      style={{
                        height: 20,
                        width: 18,
                        marginRight: 5
                      }}
                    >
                      <IconTint
                        src={iconTag}
                        style={{
                          position: "relative",
                          top: "1px",
                          height: "18",
                          width: "18"
                        }}
                        color={getColorCode(item.color)}
                      />
                    </div>
                    {/* <i
                                        className="fa fa-tag"
                                        style={{
                                          color: getColorCode(item.color),
                                          position: "relative",
                                          top: "1px"
                                        }}
                                      /> */}
                    <span
                      style={{
                        // color: getColorCode(item.color),
                        color: "black",
                        paddingLeft: 5,
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        textOverflow: " ellipsis",
                        overflow: "hidden"
                      }}
                    >
                      <span>{item.name}</span>
                    </span>
                  </div>
                </>
              );
            })}
      </>
    );
  };
  const GetHtmlTag = description => {
    if (
      description == "" ||
      description == null ||
      description == "null" ||
      description == undefined ||
      description == "<p><br></p>"
    ) {
      return (
        <div
          onClick={() => {
            handleEditTask(EDIT_ACTION_CONSTANT.description);
            setIsTaskDescriptionEdit(true);
          }}
          style={{
            backgroundColor:
              description !== null ||
              description !== "null" ||
              description != "<p><br></p>"
                ? "#F8F8F8"
                : "White",
            border:
              description !== null ||
              description !== "null" ||
              description != "<p><br></p>"
                ? "1px solid #EBEBEB"
                : "0px solid",
            padding: "6px 12px",
            borderRadius: 4,
            cursor: "pointer",
            minHeight: 40,
            color: "grey",
            fontSize: 14,
            whiteSpace: "pre-wrap",
            wordBreak: "break-all"
          }}
        >
          <IntlMessages id={"task-details.extradetails"} />
        </div>
      );
    }
    return (
      <div
        onClick={() => {
          handleEditTask(EDIT_ACTION_CONSTANT.description);
          setIsTaskDescriptionEdit(true);
        }}
        dangerouslySetInnerHTML={{
          __html:
            description && description !== null && description !== "null"
              ? description
              : ""
        }}
        style={{
          backgroundColor:
            description !== null ||
            description !== "null" ||
            description != "<p><br></p>"
              ? "#F8F8F8"
              : "White",
          border:
            description !== null ||
            description !== "null" ||
            description != "<p><br></p>"
              ? "1px solid #EBEBEB"
              : "0px solid",
          padding: "6px 12px",
          display: "flex",
          alignItems: "center",
          borderRadius: 4,
          cursor: "pointer",
          minHeight: 40,
          color: "grey",
          // display: "flex",
          // alignItems: "center",
          fontSize: 14,
          whiteSpace: "pre-wrap"
        }}
      ></div>
    );
  };

  const onChangeDescription = data => setDescriptionInput(data);

  const AssignPlaceHolder = taskAssignData => {
    const taskAssign = taskAssignData.taskAssign;
    if (taskAssign.thumbnail || taskAssign.url) {
      return (
        <img
          title={taskAssign.name ? taskAssign.name : taskAssign.email}
          id="userAssigned"
          src={taskAssign.thumbnail ? taskAssign.thumbnail : null}
          alt=""
          style={{
            height: 20,
            width: 20,
            marginRight: 2,
            borderRadius: "50%",
            border: "1px solid white"
          }}
        ></img>
      );
    } else {
      return (
        <div
          title={taskAssign?.name ? taskAssign.name : taskAssign.email}
          // id="userAssigned"
          style={profileBackground(
            taskAssign?.name ? taskAssign.name : taskAssign.email,
            20,
            20,
            12
          )}
        >
          {taskAssign?.name
            ? taskAssign.name[0].toUpperCase()
            : taskAssign.email[0].toUpperCase()}
        </div>
      );
    }
  };
  const Placeholder = ({ text, onClick }) => {
    if (text) {
      return (
        <div
          style={{
            fontSize: 14,
            color: "#808080",
            font: "Segoe UI",
            cursor: "pointer"
          }}
          title={InjectPlaceHolderMessage(
            "task-details.editdetails",
            props?.LanguageSwitcher?.language?.languageId
          )}
          onClick={e => (onClick ? onClick(e) : {})}
        >
          {text}
        </div>
      );
    }
  };

  return (
    <>
      {isFetchDetailsLoader ? (
        <>
          <div
            style={{
              height: 70,
              overflow: "hidden",
              marginTop: 15,
              marginLeft: 30
            }}
          >
            <BusinessListShimmer />
          </div>
          <Shimmer />
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <TaskTitleCard
              titleRef={titleRef}
              handleEditTask={handleEditTask}
              taskTitleInput={taskTitleInput}
              setTaskTitleInput={setTaskTitleInput}
              isEditTask={isEditTask}
              setIsEditTask={setIsEditTask}
              taskTitle={taskDetails.title}
              taskArchive={taskDetails?.isArchived}
              taskIsCompleted={taskDetails.isCompleted}
              updateIsCompleted={() => updateIsCompleted(() => {})}
              handleUpdateTaskDetails={() => {
                handleUpdateTaskDetails();
              }}
            />
            <div
              id="popover"
              className="gray-hover-inside"
              onClick={() => {
                setIsTaskDetailsPopover(!isTaskDetailsPopover);
              }}
            >
              <img
                alt=""
                src={threeDot_Icon}
                style={{
                  height: "16px",
                  width: "36px",
                  // position: "absolute",
                  // right: "30px",
                  cursor: "pointer",
                  marginTop: "11px",
                  padding: "5px"
                }}
              ></img>
            </div>
            {/* <div
              id="popover"
              className="hoverEffectpopover"
              onClick={() => {
                setIsTaskDetailsPopover(!isTaskDetailsPopover);
              }}
            >
              <img
                src={threeDot_Icon}
                alt=""
                style={{
                  height: "16px",
                  width: "36px",
                  // position: "absolute",
                  // right: "30px",
                  cursor: "pointer",
                  padding: "5px"
                }}
              ></img>
            </div> */}
          </div>
          <div
            style={{
              borderTop: "1px solid #EBEBEB",
              // borderBottom: "1px solid #EBEBEB",
              padding: "20px 0px "
            }}
          >
            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left"
                // margin: "0px 40px",
                // padding: "8px 0px "
              }}
            >
              {/* <div
                className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 TaskDetailsCardTitleName"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontWeight: "600",
                  textAlign: "center",
                  padding: "0px"
                }}
              >
                <IntlMessages id={"task-details.taskdetailsbasecamp"} />
              </div> */}
              {/* <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12">
                {taskDetails.basecampTodoId != null ||
                  taskDetails.basecampTodoId != undefined ? (
                  <div>
                    {" "}
                    <img
                      src={basecamp}
                      style={{
                        height: "19px",
                        width: "19px",
                        borderRadius: "5px"
                      }}
                    ></img>
                  </div>
                ) : (
                  ""
                )}
              </div> */}
              {/* <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12 "></div> */}
            </div>

            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                // margin: "0px 40px",
                padding: "8px 0px "
              }}
            >
              <div
                className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 TaskDetailsCardTitleName"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontWeight: "600",
                  textAlign: "center",
                  padding: "0px"
                }}
              >
                <IntlMessages id={"task-details.taskdetailspage"} />
              </div>
              <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12">
                <div onClick={() => setIsTaskDescriptionEdit(false)}>
                  {taskDetails.taskAssign &&
                  taskDetails.taskAssign.length > 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          width: "max-content"
                        }}
                        onClick={() => {
                          handleEditTask(EDIT_ACTION_CONSTANT.assign);
                        }}
                        title="Edit Assignee"
                      >
                        {taskDetails.taskAssign &&
                          taskDetails.taskAssign.map((ta, index) => {
                            return (
                              <div key={index} className=" plr-2">
                                <div
                                  style={{
                                    padding: "4px 8px",
                                    display: "flex",
                                    borderRadius: 40,
                                    backgroundColor: "#F8F8F8",
                                    border: "1px solid #EBEBEB",
                                    alignItems: "center"
                                  }}
                                  title={ta.name}
                                >
                                  <AssignPlaceHolder taskAssign={ta} />
                                  <span
                                    className="fs-14"
                                    style={{ paddingLeft: 2 }}
                                  >
                                    {ta ? ta.name : ""}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  ) : (
                    <div
                      onClick={() => {
                        handleEditTask(EDIT_ACTION_CONSTANT.assign);
                      }}
                      style={{ cursor: "pointer", width: "max-content" }}
                      title={InjectPlaceHolderMessage(
                        "task-details.edit",
                        props?.LanguageSwitcher?.language?.languageId
                      )}
                    >
                      <Placeholder
                        text={InjectPlaceHolderMessage(
                          "task-details.assigntask",
                          props?.LanguageSwitcher?.language?.languageId
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12 "></div> */}
            </div>

            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                // margin: "0px 40px",
                padding: "8px 0px "
              }}
            >
              <div
                className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 TaskDetailsCardTitleName"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontWeight: "600",
                  padding: "0px"
                }}
              >
                <IntlMessages id={"task-details.priority"} />
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"
                onClick={() => setIsTaskDescriptionEdit(false)}
              >
                {taskDetails.priority ? (
                  <span
                    id="priority-task-details"
                    className="fs-14"
                    onClick={() =>
                      handleEditTask(EDIT_ACTION_CONSTANT.prirority)
                    }
                    style={{ cursor: "pointer" }}
                    title={InjectPlaceHolderMessage(
                      "task-details.edit",
                      props?.LanguageSwitcher?.language?.languageId
                    )}
                  >
                    <img
                      src={getPriorityPath(
                        isEditTask ? PriorityInput : taskDetails.priority
                      )}
                      height="17"
                      width="18"
                      className=""
                      alt="Priority"
                      style={{
                        position: "relative",
                        marginRight: 8
                      }}
                    />
                    {getPriorityValue(
                      isEditTask ? PriorityInput : taskDetails.priority
                    )}
                  </span>
                ) : (
                  <span
                    id="priority-task-details"
                    onClick={() => {
                      setIsPriority(!isPriority);
                    }}
                  >
                    <Placeholder text="Select priority" />
                  </span>
                )}
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                padding: "8px 0px "
              }}
            >
              <div
                className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 TaskDetailsCardTitleName"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontWeight: "600",
                  padding: "0px"
                }}
              >
                <IntlMessages id={"task-details.label"} />
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"
                onClick={() => {
                  handleEditTask(EDIT_ACTION_CONSTANT.labels);
                  // editLabels()
                }}
              >
                <div
                  id="Labels-Modal"
                  style={{ fontSize: 14, cursor: "pointer", width: "42%" }}
                >
                  {" "}
                </div>
                {taskDetails.labels && taskDetails.labels?.length > 0 ? (
                  <>
                    <span
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        overflow: "hidden",
                        height: "45px"
                      }}
                    >
                      {LabelsComponent(taskDetails.labels)}
                    </span>
                  </>
                ) : (
                  <span>
                    <Placeholder
                      text={InjectPlaceHolderMessage(
                        "task-details.nolabel",
                        props?.LanguageSwitcher?.language?.languageId
                      )}
                    />
                  </span>
                )}
              </div>
            </div>
            {/* <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                padding: "8px 0px "
              }}
            >
              <div
                className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 TaskDetailsCardTitleName"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontWeight: "600",
                  padding: "0px"
                }}
              >
                Labels
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"
                onClick={() => setIsTaskDescriptionEdit(false)}
              >
                {taskDetails.labels && taskDetails.labels?.length > 0 ? (
                  <span
                    id="labels-task-details"
                    onClick={() => {
                      setIsLabels(!!isLabels);
                    }}
                    className="fs-14"
                    onClick={() => handleEditTask()}
                    style={{ cursor: "pointer", display: "flex" }}
                    title="edit"
                  >
                    {LabelsComponent(taskDetails.labels)}
                  </span>
                ) : (
                  <span
                    id="labels-task-details"
                    onClick={() => {
                      setIsLabels(!!isLabels);
                    }}
                  >
                    <Placeholder text="Select Labels" />
                  </span>
                )}
              </div>
            </div> */}
            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                padding: "8px 0px "
              }}
            >
              <div
                className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 TaskDetailsCardTitleName"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontWeight: "600",
                  padding: "0px"
                }}
                title={InjectPlaceHolderMessage(
                  "task-details.changetheschedule",
                  props?.LanguageSwitcher?.language?.languageId
                )}
                onClick={() => {
                  handleEditTask(EDIT_ACTION_CONSTANT.description);
                }}
              >
                <IntlMessages id={"task-details.dueon"} />
              </div>
              <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12">
                <div
                  onClick={() => {
                    handleEditTask(EDIT_ACTION_CONSTANT.schedule);
                    setIsTaskDescriptionEdit(false);
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: 14,
                    width: "max-content",
                    display: "flex"
                  }}
                  title={InjectPlaceHolderMessage(
                    "task-details.changetheschedule",
                    props?.LanguageSwitcher?.language?.languageId
                  )}
                >
                  {taskDetails.scheduledAt &&
                    taskDetails.repeatType !== repeatTypeOptions[0].value && (
                      <RepeatSvgIcon
                        style={{
                          fill: "green",
                          height: "20px",
                          width: "20px"
                        }}
                      />
                    )}
                  <div style={{ paddingLeft: 2 }}>
                    <DueDatePicker
                      AllowAddPreviousDateTask={AllowAddPreviousDateTask}
                      dueDate={isEditTask ? dateInput : taskDetails.scheduledAt}
                      onUpdateDate={doUpdateTaskScheduleDate}
                      toggle={handleToggleDatePicker}
                      isOpen={isDatePicker}
                      PRIMARY_COLOR={PRIMARY_COLOR}
                    />
                  </div>
                </div>
              </div>
            </div>
            {taskDetails.scheduledAt || (dateInput && isEditTask) ? (
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "left",
                  padding: "8px 0px "
                }}
              >
                <div
                  className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 TaskDetailsCardTitleName"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    fontWeight: "600",
                    padding: "0px"
                  }}
                  title={InjectPlaceHolderMessage(
                    "task-details.edit",
                    props?.LanguageSwitcher?.language?.languageId
                  )}
                >
                  <IntlMessages id={"task-details.repeat"} />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12">
                  <div
                    style={{
                      cursor: "pointer",
                      fontSize: 14,
                      width: "100%"
                    }}
                  >
                    {!isEditTask &&
                    taskDetails?.repeatType !== repeatTypeOptions[0] &&
                    taskDetails.scheduledAt ? (
                      <div
                        title={InjectPlaceHolderMessage(
                          "task-details.changetheschedule",
                          props?.LanguageSwitcher?.language?.languageId
                        )}
                        onClick={() => {
                          handleEditTask(EDIT_ACTION_CONSTANT.repeat);
                          setIsRepeatDropdownMenuOpen(true);
                        }}
                      >
                        {taskDetails?.repeatType !==
                        repeatTypeOptions[0].value ? (
                          <>
                            {getRepeatTypeOption(taskDetails?.repeatType).label}
                            {taskDetails?.repeatTill
                              ? " until " +
                                moment(taskDetails?.repeatTill).format(
                                  "MMM DD YYYY"
                                )
                              : ""}
                          </>
                        ) : (
                          <div
                            onClick={() => {
                              setIsRepeatDropdownMenuOpen(true);
                            }}
                          >
                            Don't repeat
                          </div>
                        )}
                      </div>
                    ) : (
                      <TaskDetailsCardDropDownWrapper>
                        <div
                          onClick={() => {
                            setIsRepeatDropdownMenuOpen(
                              !isRepeatDropdownMenuOpen
                            );
                          }}
                        >
                          <Select
                            onBlur={() => setIsRepeatDropdownMenuOpen(false)}
                            menuIsOpen={isRepeatDropdownMenuOpen}
                            defaultValue={repeatTypeValue}
                            options={repeatTypeOptions}
                            styles={repeatTypeSelectStyle}
                            onChange={handleChange}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                          />
                        </div>
                        {repeatTypeValue &&
                          repeatTypeValue.value !==
                            repeatTypeOptions[0].value && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column"
                              }}
                            >
                              <div
                                className="pretty p-default p-round"
                                style={{ margin: "15px 0px ", color: "black" }}
                              >
                                <input
                                  type="radio"
                                  checked={
                                    recurrenceType ==
                                    CONSTANTS_RECURRENCE?.forever
                                  }
                                  name="repeat-type-radio"
                                  style={{ width: "75px", height: 20 }}
                                  value={CONSTANTS_RECURRENCE.forever}
                                  onChange={e => {
                                    setRecurrenceType(
                                      CONSTANTS_RECURRENCE.forever
                                    );
                                    setRepeatDate("");
                                  }}
                                />
                                <div className="state">
                                  <label>Forever</label>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <div
                                  // onClick={() => {

                                  //   setRepeatDate(new Date());
                                  // }}
                                  className="pretty p-default p-round"
                                  style={{
                                    width: "65px",
                                    margin: 0,
                                    height: 14
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="repeat-type-radio"
                                    checked={
                                      recurrenceType ==
                                      CONSTANTS_RECURRENCE?.until
                                    }
                                    style={{ color: "black" }}
                                    value={CONSTANTS_RECURRENCE.until}
                                    onChange={e => {
                                      setRecurrenceType(
                                        CONSTANTS_RECURRENCE?.until
                                      );
                                      setRepeatDate(
                                        new Date(taskDetails.scheduledAt)
                                      );
                                    }}
                                  />
                                  <div
                                    className="state"
                                    style={{
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    <label>Until</label>
                                  </div>
                                </div>
                                <TaskDetailsCardDropDownWrapper {...props}>
                                  <DatePicker
                                    isOpen={isRepeatDatePicker}
                                    onClickOutside={() =>
                                      setIsRepeatDatePicker(false)
                                    }
                                    placeholderText="Pick a date..."
                                    minDate={
                                      taskDetails.scheduledAt
                                        ? new Date(taskDetails.scheduledAt)
                                        : false
                                    }
                                    id="repeatDate"
                                    name="repeatDate"
                                    value={repeatDate}
                                    disabledKeyboardNavigation
                                    onInputClick={() => {
                                      setRecurrenceType(
                                        CONSTANTS_RECURRENCE?.until
                                      );
                                      if (!repeatDate) {
                                        setRepeatDate(
                                          taskDetails.scheduledAt
                                            ? new Date(taskDetails.scheduledAt)
                                            : ""
                                        );
                                      }
                                    }}
                                    selected={repeatDate}
                                    onChange={handleChangerepeatDate}
                                    dateFormat="dd MMM yyyy"
                                    className="custom-datepicker"
                                    calendarClassName="custom-calender-class"
                                    locale={locale}
                                  />
                                </TaskDetailsCardDropDownWrapper>
                              </div>
                            </div>
                          )}
                      </TaskDetailsCardDropDownWrapper>
                    )}
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12 "></div>
              </div>
            ) : (
              ""
            )}
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "left",
                padding: "8px 0px 8px 0px"
              }}
            >
              <div
                className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 TaskDetailsCardTitleDescription"
                style={{
                  display: "flex",
                  alignItems: "self-start",
                  justifyContent: "flex-end",
                  fontWeight: "600",
                  padding: "0px"
                }}
              >
                <IntlMessages id={"task-details.description_placeholder"} />
              </div>
              <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12">
                {(!isEditTask &&
                  (taskDetails.description == "" ||
                    taskDetails.description == " " ||
                    taskDetails.description == "<p><br></p>" ||
                    taskDetails.description == "<p><br></p><p><br></p>")) ||
                (!isEditTask && taskDetails.description == null) ||
                (!isEditTask && taskDetails.description == "null") ? (
                  <div>
                    <button
                      className="dialog-chip "
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        fontWeight: "100",
                        backgroundColor: "#F8F8F8",
                        border: "1px solid #EBEBEB",
                        textAlign: "left",
                        cursor: "text",
                        color: "grey",
                        borderRadius: 5,
                        padding: "6px 12px",
                        marginTop: 0,
                        minHeight: 40
                      }}
                      onClick={() =>
                        handleEditTask(EDIT_ACTION_CONSTANT.description)
                      }
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#808080"
                        }}
                      >
                        {" "}
                        <IntlMessages id={"task-details.extradetails"} />
                      </span>
                    </button>
                  </div>
                ) : (
                  <div
                    title={InjectPlaceHolderMessage(
                      "task-details.edit",
                      props?.LanguageSwitcher?.language?.languageId
                    )}
                    onClick={() =>
                      handleEditTask(EDIT_ACTION_CONSTANT.description)
                    }
                  >
                    {!isEditTask ? (
                      <NewTaskDescriptionWrapper backgroundColor="#F8F8F8">
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              taskDetails.description &&
                              taskDetails.description !== null &&
                              taskDetails.description !== "null"
                                ? taskDetails.description
                                : ""
                          }}
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            // backgroundColor: "#F8F8F8",
                            // border: "1px solid #EBEBEB",
                            textAlign: "left",
                            cursor: "pointer",
                            color: "black",
                            borderRadius: 5,
                            padding: "1px 0px 0px 2px",
                            marginTop: 0,
                            whiteSpace: "pre-wrap",
                            fontSize: 14,
                            wordBreak: "break-all"
                          }}
                        ></div>
                      </NewTaskDescriptionWrapper>
                    ) : (
                      <>
                        {isTaskDescriptionEdit != true ? (
                          <>{GetHtmlTag(taskDetails.description)}</>
                        ) : (
                          <NewTaskDescriptionWrapper backgroundColor="#F8F8F8">
                            <TaskDescriptionEditor
                              collaboratorsList={collaboratorListArr || []}
                              setIsFocus={flag => {}}
                              inputValue={descriptionInput}
                              onChange={onChangeDescription}
                            />
                          </NewTaskDescriptionWrapper>
                        )}
                      </>
                    )}
                    {/* {taskDetails.description ? (
            <div
              dangerouslySetInnerHTML={{
                __html: taskDetails.description
              }}
            ></div>
          ) : (
            "Add extra details or attach a file..."
          )} */}
                  </div>
                )}
              </div>
              <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12 "></div>
            </div>

            {isEditTask && (
              <div className="row" style={{ paddingTop: 10 }}>
                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12"></div>
                <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12">
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {isUpdateLoader ? (
                      <div className="mr-10">
                        <Loader height="23px" width="22px" />{" "}
                      </div>
                    ) : (
                      <button
                        disabled={isUpdateLoader}
                        className="btn mr-10"
                        style={{
                          boxShadow: "none",
                          borderRadius: "17px",
                          backgroundColor: topbarTheme.buttonColor,
                          color: "white",
                          cursor: isUpdateLoader ? "not-allowed" : "pointer"
                        }}
                        onClick={() => {
                          handleUpdateTaskDetails();
                        }}
                      >
                        <IntlMessages id={"task-details.savechanges"} />
                      </button>
                    )}
                    <button
                      className="btn btn"
                      style={{
                        boxShadow: "none",
                        borderRadius: "17px",
                        border: `1px solid ${topbarTheme.buttonColor}`,
                        color: topbarTheme.buttonColor
                      }}
                      onClick={handleDiscardChanges}
                    >
                      <IntlMessages id={"task-details.discardchanges"} />
                    </button>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12 "></div>
              </div>
            )}
            <PriorityPopover
              isOpen={isPriority}
              toggle={() => setIsPriority(!isPriority)}
              target={"priority-task-details"}
              selectedPriority={isPriority ? PriorityInput : "no_priority"}
              updatePriority={doUpdateTaskPriority}
              PRIMARY_COLOR={PRIMARY_COLOR}
            />
            <LabelsPopover
              isOpen={isLabels}
              toggle={() => setIsLabels(!isLabels)}
              target={"Labels-Modal"}
              selectedLabels={ids}
              selectLabelHandler={selectLabelHandler}
              updateLabelToParent={doUpdateLabelsToTask}
            />
          </div>
          <TaskDetailsPopoverMenu
            isArchived={taskDetails.isArchived}
            handleMenuAction={handleMenuAction}
            isTaskDetailsPopover={isTaskDetailsPopover}
            toggleTaskDetailsMenu={() => toggleTaskDetailsMenu()}
          />
          <TaskDetailsActivityModal
            isTaskDetailsActivityModal={isTaskDetailsActivityModal}
            toggleTaskDetailsModal={() => toggleTaskDetailsModal()}
            props={props}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher.language }
  };
};

export default connect(mapStateToProps, {})(TaskDetailsCard);
