/** @format */

import React, { useState, useEffect } from "react";
import TodolistWrapper from "./todolistWrapper.style";
import moment from "moment";
import IconTint from "react-icon-tint";
import TaskDialog from "components/scrumboard/TaskDialog";
import TaskMenu from "components/projects/Menus/TaskMenu";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import RoyTooltip from "components/common/RoyTooltip";
import { ReactComponent as RepeatSvgIcon } from "assets/images/recurrenceIcon.svg";
import { repeatTypeOptions } from "util/data/taskDetailsutilData";
import { deleteTodo } from "helper/services/todoService";
import CustomAlert from "util/CustomAlert";
import { connect } from "react-redux";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import {
  data_not_found,
  addnew,
  commentnew,
  iconTag,
  threeDot_Icon,
  AssignTaskImage,
  ListGridCheckImage,
  ListGridCircleImage,
  datesnewmodal
} from "helper/constant";
import { getColorCode } from "util/data/ProjectColors";
import {
  getPriorityPath,
  getPriorityValue,
  projectSocketRoomPrefix,
  todaySocketRoomPrefix
} from "constatnt";
import { allPriority } from "constatnt";
import { prioritySocketRoomPrefix, taskSocketRoomPrefix } from "constatnt";
import {
  dateFilterInit,
  joinRoom,
  leaveRoom,
  disconnect,
  priorityInit,
  updateTask
} from "services/socket";
import { dateFilterSocketRoomPrefix } from "constatnt";
import NewTask from "components/newTask/NewTask";
import TaskAssignee from "components/assignee/TaskAssignee";
import DatePickerNoInput from "components/DatePickers/DatePickerNoInput";
import PriorityPopover from "components/priority/priorityPopover";
import AssigneeDropdown from "components/newTask/AssigneeDropdown";
import { onChangeTaskAssign } from "helper/methods/QuickActions";
import { doGroupByproject } from "helper/methods/utilFunctions";
import SingleTaskView from "./SingleTaskView";
import IntlMessages from "util/intlMessages";

const TodayListView = props => {
  const [todo, SetTodo] = useState(null);
  const [taskModel, setTaskModel] = useState(false);
  const [activeTaskDetail, setActiveTaskDetail] = useState(null);
  const [taskMenu, setTaskMenu] = useState(false);
  const [activeTaskMenu, setActiveTaskMenu] = useState(null);
  const [istaskinput, setIsTaskinput] = useState({ status: false, id: "" });

  const [isCustomAlert, setCustomAlert] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [sectionalertMessage, setSectionAlertMessage] = useState(
    "Task will be deleted!"
  );

  const { isnewTask, setIsNewTask, topbarTheme } = props;
  const [isDataFlag, setIsDataFlag] = useState(false);
  const [projectArr, setProjectArr] = useState([]);
  const [taskProject, setTaskProject] = useState("");
  const [todayListName, setTodayListName] = useState("");
  const {
    doCloseQuickAction,
    doUpdateTaskPriority,
    doUpdateTaskScheduleDate,
    doUpdateTaskAssign,
    isQuickActionTask,
    setIsQuickActionTask
  } = props;
  const { isEditTask, setIsEditTask, updateTaskHandler } = props;
  useEffect(() => {
    if (props.todoList) {
      SetTodo(props.todoList);
      var getRegularTasks = props.todoList.filter(
        (data, i) => !data.isDeleted && !data.isArchived
      );
      let formattedData = doGroupByproject(getRegularTasks);
      SetTodo(formattedData);
    }
  }, [props.todoList]);

  const manipulateData = todoArr => {
    if (todoArr) {
      var dataArr = [];
      Promise.all(
        todoArr &&
          todoArr.map(todos => {
            var isDataFlag = false;
            dataArr.map(check => {
              if (check.name == todos.project.name) {
                isDataFlag = true;
              }
            });
            if (!isDataFlag) {
              dataArr.push({
                name: todos.project.name,
                color: todos.project.color
              });
            }
          })
      );
    }

    dataArr = [...new Set(dataArr)];

    setProjectArr(dataArr);
  };

  const updateisCompleted = async (projectId, todoId, e, data) => {
    props.updateIsCompleted(projectId, todoId, e, data);
  };
  const deleteTask = async (id, projectId) => {
    updateTaskHandler(
      {
        task_id: id.toString(),
        project_id: projectId?.toString(),
        isDeleted: true
      },
      false
    );
  };
  const doArchiveTask = async (id, projectId) => {
    updateTaskHandler(
      {
        task_id: id.toString(),
        project_id: projectId?.toString(),
        isArchived: true
      },
      false
    );
  };

  const removeTaskFromList = id => {
    const TodoArr = todo;

    TodoArr.map((data, i) => {
      if (data.id == id) {
        TodoArr.splice(i, 1);
      }
    });
    manipulateData(TodoArr);
    SetTodo(TodoArr);
  };
  const doOpenTaskDetail = data => {
    props.history.push(`/task-details/${data.id}`);
    //   setActiveTaskDetail(data);
    //   setTodoId(data.id);
    //   // Join task socket room
    //   joinRoom(
    //     `${taskSocketRoomPrefix}${data.id}`
    //   );
    //   setTaskModel(true);
  };
  const toggleTaskMenu = (target, taskData) => {
    if (taskMenu) {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(null);
      setActiveTaskDetail(null);
    } else {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(target);

      // joinRoom(`${taskSocketRoomPrefix}${taskData.id}`);
      setActiveTaskDetail(taskData);
    }
  };
  const getTodoStyle = id => {
    if (id == isQuickActionTask.taskId && isQuickActionTask.status) {
      return {
        display: "flex",
        alignItems: "flex-start",
        boxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        webkitBoxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        mozBoxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        border: "1px solid lightgray",
        transition: "box-shadow 0.3s ease-in-out"
      };
    }
    return {
      display: "flex",
      alignItems: "flex-start",
      transition: "box-shadow 0.3s ease-in-out"
    };
  };
  return (
    <div>
      <TodolistWrapper>
        <div className=" ">
          <div className="roe-card-style" style={{ marginTop: 15 }}>
            {props.isLabelToday ? (
              <div className="roe-card-header " style={{ paddingLeft: 0 }}>
                {" "}
                <span className="fs-14 demi-bold-text">
                  <span
                    style={{ fontWeight: 600, color: "black", fontSize: 14 }}
                  >
                    {" "}
                    {moment(new Date()).format(" DD MMM")} ·{" "}
                    {<IntlMessages id={"today.today"} />}
                  </span>
                  <small
                    className="fs-10"
                    style={{
                      marginLeft: 6
                    }}
                  >
                    {/* {moment(new Date()).format("ddd DD MMM")} */}
                  </small>
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          {todo && Object.keys(todo).length > 0 && props.todoList.length > 0 && (
            <div>
              <div
                style={{
                  padding: "10px 0px"
                }}
              >
                {Object.keys(todo).length &&
                  Object.keys(todo).map((projectKey, i) => {
                    return (
                      <>
                        <div
                          style={{
                            display: "flex"
                          }}
                        >
                          <div
                            className="pa-5 flex-x align-center"
                            id={`section-edit-${i}`}
                          >
                            <div className="flex-1">
                              <div className="fs-14 demi-bold-text flex-x align-center">
                                <i
                                  className="fa fa-circle fs-10 pr-10 fs-10"
                                  style={{
                                    color: getColorCode(
                                      todo[projectKey].project.color
                                    )
                                  }}
                                />
                                {todo[projectKey].project.name}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="todo-container flex-y"
                          style={{
                            backgroundColor: "white",
                            borderRadius: 10
                          }}
                        >
                          {todo[projectKey].task &&
                            todo[projectKey].task.map((data, i) => {
                              if (
                                isEditTask.status &&
                                isEditTask?.data?.id === data.id
                              ) {
                                return (
                                  <>
                                    <div
                                      style={{
                                        paddingTop: 5,
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        border: "1px solid #c6c2c2",
                                        position: "relative"
                                      }}
                                    >
                                      <NewTask
                                        updateTask
                                        action={isEditTask?.action || ""}
                                        toggleAction={() =>
                                          setIsEditTask({
                                            ...isEditTask,
                                            action: ""
                                          })
                                        }
                                        collaboratorList={
                                          props.collaboratorList
                                        }
                                        toggle={() => {
                                          setIsEditTask({
                                            status: false,
                                            data: ""
                                          });
                                          setIsTaskinput({
                                            status: false,
                                            id: null
                                          });
                                        }}
                                        taskData={isEditTask.data}
                                        // onKeyDown={handleKeyDown}
                                        addTaskHandler={updateTaskHandler}
                                        taskProject={taskProject}
                                        setTaskProject={data =>
                                          setTaskProject(data)
                                        }
                                      />
                                    </div>
                                  </>
                                );
                              }
                              return (
                                <SingleTaskView
                                  auth={props.auth}
                                  index={i}
                                  data={data}
                                  updateisCompleted={updateisCompleted}
                                  doOpenTaskDetail={doOpenTaskDetail}
                                  doCloseQuickAction={doCloseQuickAction}
                                  isQuickActionTask={isQuickActionTask}
                                  doUpdateTaskScheduleDate={
                                    doUpdateTaskScheduleDate
                                  }
                                  doUpdateTaskAssign={doUpdateTaskAssign}
                                  toggleTaskMenu={toggleTaskMenu}
                                  todoLength={todo[projectKey].task.length || 0}
                                />
                              );
                            })}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          )}
          <>
            {istaskinput.status ? (
              <>
                <div
                  style={{
                    border: "1px solid #c6c2c2",
                    borderRadius: 5,
                    marginTop: 15
                  }}
                >
                  <NewTask
                    defaultTodayDate
                    modal={istaskinput.status}
                    isProjectSelection
                    action={isEditTask?.action || ""}
                    toggleAction={() =>
                      setIsEditTask({ ...isEditTask, action: "" })
                    }
                    collaboratorList={[]}
                    toggle={() => {
                      setIsEditTask({ status: false, data: "" });
                      setIsTaskinput({ status: false, id: null });
                    }}
                    // taskData={isEditTask.data}
                    // onKeyDown={handleKeyDown}
                    addTaskHandler={props.addTaskHandler}
                    taskProject={taskProject}
                    setTaskProject={data => setTaskProject(data)}
                  />
                </div>
              </>
            ) : (
              <div
                className="list-name flex-x align-center  todo-topborder "
                style={{ margin: "20px 15px 30px 30px" }}
              >
                <div className="mr-10 flex-x icon cursor-pointer">
                  <img
                    src={addnew}
                    style={{
                      height: "15px",
                      width: "15px"
                    }}
                  ></img>
                </div>
                <div className="mr-0 flex-1  ">
                  <input
                    // value={taskinput}
                    onFocus={() => setIsTaskinput({ status: true, id: "" })}
                    // onChange={e => {
                    //   setTaskinput(e.target.value);
                    //   setIsTaskinput(false);
                    //   setIsTaskinput({ status: true, id: props.sectionId });
                    // }}
                    className="task-add-input fs-14 cursor-pointer"
                    type="text"
                    placeholder={InjectPlaceHolderMessage(
                      "today.newtask",
                      props?.LanguageSwitcher?.language?.languageId
                    )}
                    // onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            )}
          </>
        </div>
      </TodolistWrapper>

      {activeTaskDetail && (
        <TaskDialog
          taskType={"date_filter"}
          taskModel={taskModel}
          priorityId={props.priorityId}
          collaboratorList={props.collaboratorList}
          setTaskModel={() => {
            setTaskModel(!taskModel);
            setActiveTaskDetail(null);
          }}
          showTaskAssign={true}
          taskDetail={activeTaskDetail}
          projectId={activeTaskDetail.project.id}
          projectName={activeTaskDetail.project.name}
          todoId={activeTaskDetail.id}
          updateTodoList={newTodo => props.refreshTodoList(newTodo)}
          dummyData={props.dummyData || false}
          showNotifications={props.showNotifications}
          currentPriorityId={props.currentPriorityId}
          sectionId={activeTaskDetail && activeTaskDetail.section?.id}
          popTask={todoId =>
            props.hasOwnProperty("isPriorityComponent") &&
            props.removeTaskFromList(todoId)
          }
          pushTask={taskData =>
            props.hasOwnProperty("isPriorityComponent") &&
            props.pushTask(taskData)
          }
        />
      )}
      <TaskMenu
        projectTask
        isMenu={taskMenu}
        toggleMenu={() => toggleTaskMenu()}
        viewTaskDetails={() => {
          // setActiveTaskDetail({ ...activeTaskDetail, action: "taskactivity" });
          // setTaskModel(true);
          doOpenTaskDetail(activeTaskDetail);
          setTaskMenu(false);
          setActiveTaskMenu("");
        }}
        doArchiveTask={() =>
          doArchiveTask(activeTaskDetail?.id, activeTaskDetail.project.id)
        }
        target={activeTaskMenu}
        editLabels={() => {
          setIsEditTask({
            status: true,
            data: activeTaskDetail,
            action: "label"
          });
        }}
        editAssign={() => {
          if (activeTaskDetail && activeTaskDetail.id) {
            setIsQuickActionTask({
              status: true,
              taskId: activeTaskDetail.id,
              action: "assign",
              target: `taskmenu-assign-${activeTaskDetail.id}`,
              selectedData:
                activeTaskDetail && activeTaskDetail.taskAssign
                  ? { user: activeTaskDetail?.taskAssign }
                  : "",
              data: activeTaskDetail
            });
          }
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "taskassign"
          // });
        }}
        editPriority={() => {
          if (activeTaskDetail && activeTaskDetail.id) {
            setIsQuickActionTask({
              status: true,
              taskId: activeTaskDetail.id,
              action: "priority",
              target: `taskmenu-priority-${activeTaskDetail.id}`,
              selectedData: activeTaskDetail && activeTaskDetail.priority,
              data: activeTaskDetail
            });
          }
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "priority"
          // });
        }}
        editDate={() => {
          setIsQuickActionTask({
            status: true,
            taskId: activeTaskDetail.id,
            action: "schedule",
            target: `taskmenu-schedule-${activeTaskDetail.id}`,
            selectedData: activeTaskDetail && activeTaskDetail.scheduledAt,
            data: activeTaskDetail
          });
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "schedule"
          // });
        }}
        editTask={() => {
          if (taskMenu) {
            setIsEditTask({ status: true, data: activeTaskDetail });
          } else {
            setIsEditTask({ status: false, data: "" });
          }
          // setTaskModel(!taskModel);
          setTaskMenu(!taskMenu);
          // setActiveTaskDetail(null);
        }}
        deleteTask={id => {
          setTaskMenu(!taskMenu);
          setCustomAlert(true);
        }}
      />
      <CustomAlert
        CustomMessage={todayListName}
        action="delete"
        messageType="task"
        PRIMARY_COLOR={props.PRIMARY_COLOR}
        show={isCustomAlert}
        onConfirm={() => {
          deleteTask(activeTaskDetail?.id, activeTaskDetail?.project?.id);
          setCustomAlert(false);
        }}
        onCancel={() => setCustomAlert(false)}
        alertMessage={sectionalertMessage}
      />
      {isQuickActionTask &&
        isQuickActionTask.status &&
        isQuickActionTask.action == "priority" && (
          <PriorityPopover
            isOpen={isQuickActionTask.status}
            toggle={doCloseQuickAction}
            target={
              isQuickActionTask.status && isQuickActionTask.target
                ? isQuickActionTask.target
                : "priority-task-details"
            }
            selectedPriority={
              isQuickActionTask?.status && isQuickActionTask.selectedData
                ? isQuickActionTask.selectedData
                : "no_priority"
            }
            updatePriority={doUpdateTaskPriority}
            PRIMARY_COLOR={props.PRIMARY_COLOR}
          />
        )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    businessId: state.auth.user?.businessId,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, null)(TodayListView);
