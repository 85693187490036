import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import {
  EditProjectMoreOptions,
  attachmentnew,
  AddListMoreOptions,
  ViewAsListMoreOptions,
  gridMoreOptions,
  ViewArchivedItemsMoreOptions,
  logoutHeaderPopoverImage,
  taskActivity,
  AddToFavouriteMoreOptions,
  ArchiveProjectMoreOptions
} from "helper/constant";
import IntlMessages from "util/intlMessages";
const MoreOptionsMenu = ({
  moreProjectOptions,
  setMoreProjectOptions,
  toggleEditProject,
  viewMode,
  updateViewOptions,
  setSectionCardFlag,
  deleteProject,
  leaveProject,
  isOwner,
  isFavorite,
  toggleProjectSideMenu,
  updateFavorite,
  updateArchiveProject,
  isArchived,
  doOpenAttachment,
  doOpenProjectActivity
}) => {
  return (
    <div>
      <Popover
        trigger="legacy"
        className="roy-menu"
        hideArrow={true}
        innerClassName="roy-inner-content"
        placement="bottom-end"
        target="popover"
        isOpen={moreProjectOptions}
        toggle={() => setMoreProjectOptions()}
      >
        <PopoverBody style={{ padding: 15 }}>
          <div
            className="roy-menu-list"
            style={{ display: "flex", alignItems: "center" }}
            onClick={e => {
              e.stopPropagation();
              setMoreProjectOptions();
              toggleEditProject();
            }}
          >
            <img
              alt=""
              src={EditProjectMoreOptions}
              height="16px"
              width="18px"
              style={{ marginRight: 15 }}
            />
            {<IntlMessages id={"project.edit_project"} />}
          </div>

          <div
            style={{ display: "flex", alignItems: "center" }}
            className="roy-menu-list"
            onClick={updateViewOptions}
          >
            {viewMode && viewMode !== null && viewMode === "list" ? (
              <img
                alt=""
                src={gridMoreOptions}
                height="17px"
                width="18px"
                style={{ marginRight: 15 }}
              />
            ) : (
              <img
                alt=""
                src={ViewAsListMoreOptions}
                height="16px"
                width="17px"
                style={{ marginRight: 15 }}
              />
            )}
            {viewMode && viewMode !== null && viewMode === "list" ? (
              <IntlMessages id={"project.viewas_grid"} />
            ) : (
              <IntlMessages id={"project.viewas_list"} />
            )}
          </div>

          <div
            style={{ display: "flex", alignItems: "center" }}
            className="roy-menu-list"
            onClick={() => setSectionCardFlag()}
          >
            <img
              alt=""
              src={AddListMoreOptions}
              height="19px"
              width="23px"
              style={{ marginRight: 11 }}
            />
            {<IntlMessages id={"project.add_list"} />}
          </div>
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="roy-menu-list"
            onClick={updateFavorite}
          >
            <img
              alt=""
              src={AddToFavouriteMoreOptions}
              height="16px"
              width="18px"
              style={{ marginRight: 15 }}
            />
            {isFavorite ? (
              <IntlMessages id={"project.removefrom_favourite"} />
            ) : (
              <IntlMessages id={"project.addto_favourite"} />
            )}
          </div>
          {isOwner && (
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="roy-menu-list"
              onClick={updateArchiveProject}
            >
              <img
                alt=""
                src={ArchiveProjectMoreOptions}
                height="16px"
                width="18px"
                style={{ marginRight: 15 }}
              />
              {isArchived ? (
                <IntlMessages id={"project.unarchive_project"} />
              ) : (
                <IntlMessages id={"project.archive_project"} />
              )}
            </div>
          )}
          <div
            className="roy-menu-list"
            style={{ display: "flex", alignItems: "center" }}
            onClick={e => {
              e.stopPropagation();
              doOpenAttachment();
            }}
          >
            <img
              alt=""
              src={attachmentnew}
              height="16px"
              width="18px"
              style={{ marginRight: 15 }}
            />
            <IntlMessages id={"project.view_attchements"} />
          </div>
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="roy-menu-list"
            onClick={toggleProjectSideMenu}
          >
            <img
              alt=""
              src={ViewArchivedItemsMoreOptions}
              height="16px"
              width="18px"
              style={{ marginRight: 15 }}
            />
            <IntlMessages id={"project.view_archived"} />
          </div>
          <div
            className="roy-menu-list"
            style={{ display: "flex", alignItems: "center" }}
            onClick={e => {
              e.stopPropagation();
              doOpenProjectActivity();
            }}
          >
            <img
              alt=""
              src={taskActivity}
              height="16px"
              width="18px"
              style={{ marginRight: 15 }}
            />
            <IntlMessages id={"project.viewprojects_activity"} />
          </div>

          {/* <hr /> */}
          {!isOwner && (
            <>
              {/* <hr /> */}
              <div
                className="roy-menu-list"
                // style={{ color: "red" }}
                onClick={() => leaveProject()}
              >
                <img
                  alt=""
                  src={logoutHeaderPopoverImage}
                  height="19px"
                  width="19px"
                  style={{ marginRight: 14 }}
                />
                {/* <i className="fa fa-sign-out" /> */}
                <IntlMessages id={"project.leave_project"} />
              </div>
            </>
          )}
          {/* <hr />
          <div className="roy-menu-list" onClick={() => deleteProject()}>
            Delete Project
          </div> */}
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default MoreOptionsMenu;
