import React, { useState } from "react";
import moment from "moment";
import "./style.css";
import CustomAlert from "util/CustomAlert";
const filePlaceholder = require("assets/images/file.png");

export default function AttachmentUI({
  attachmentComments,
  onDelete,
  PRIMARY_COLOR
}) {
  const [isDeleteAlert, setIsDeleteAlert] = useState({
    id: "",
    status: false
  });
  const handleDocumentDownload = async (url, name) => {
    const image = await fetch(url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      {attachmentComments.map((a, i) => {
        return (
          <div
            className="attachmentStyle"
            key={i}
            style={{
              padding: "10px 15px 10px 15px",
              cursor: "pointer",
              backgroundColor: "#FFFFFFB3",
              border: "1px solid #EBEBEB",
              borderBottom: "none",
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
              marginTop: "10px"
            }}
          >
            <div className="" align="left" style={{ display: "flex" }}>
              {a.commentMeta.mimetype == "image/png" ||
              a.commentMeta.mimetype == "image/jpg" ||
              a.commentMeta.mimetype == "image/jpeg" ? (
                <a href={a.commentMeta.url} target="_blank">
                  <img
                    src={a.commentMeta.url}
                    style={{
                      height: 60,
                      width: 60,
                      backgroundColor: "rgba(9, 30, 66, 0.04)",
                      objectFit: "cover"
                    }}
                  />
                </a>
              ) : (
                <a href={a.commentMeta.url} target="_blank">
                  <img
                    src={filePlaceholder}
                    style={{
                      height: 60
                      // margin: 10
                    }}
                  />
                </a>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingLeft: 10
                }}
                align="left"
              >
                <div>
                  <a
                    href={a.commentMeta.url}
                    target="_blank"
                    style={{
                      color: "black",
                      fontSize: 14,
                      fontFamily: "revert",
                      fontWeight: "600"
                    }}
                  >
                    {a.commentMeta.originalname}
                    <i
                      style={{
                        color: "black",
                        marginLeft: 5,
                        cursor: "pointer"
                      }}
                      className="fa fa-link fs-10"
                    ></i>
                  </a>
                  {/* <i
                    style={{
                      color: "grey",
                      marginLeft: 5,
                      cursor: "pointer"
                    }}
                    className="fas fa-trash fs-10"
                    onClick={() =>
                      setIsDeleteAlert({
                        id: a.id,
                        status: true
                      })
                    }
                  ></i> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <p
                    style={{
                      color: "#808080",
                      fontSize: 12
                    }}
                  >
                    {moment(a.createdAt).format("DD MMM YYYY") ==
                    moment().format("DD MMM YYYY")
                      ? `Added ${moment(a.createdAt).fromNow()} `
                      : `Added ${moment(a.createdAt).format("DD MMM YYYY")} `}
                    <span
                      style={{
                        color: "#808080",
                        fontSize: 12,
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        handleDocumentDownload(
                          a.commentMeta.url,
                          a.commentMeta.originalname
                        )
                      }
                    >
                      - <u>Download </u>
                    </span>
                    <span
                      style={{
                        // textDecoration:"underline",
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        setIsDeleteAlert({
                          id: a.id,
                          status: true
                        })
                      }
                    >
                      - <u>Delete</u>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <CustomAlert
        action="delete"
        messageType="attachment"
        show={isDeleteAlert.status}
        onConfirm={() => {
          isDeleteAlert.id && onDelete(isDeleteAlert.id);
          setIsDeleteAlert(false);
        }}
        PRIMARY_COLOR={PRIMARY_COLOR}
        onCancel={() => {
          setIsDeleteAlert({ id: "", status: false });
        }}
      />
    </>
  );
}
