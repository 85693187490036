import React, { useState } from "react";
import { Button } from "reactstrap";
import { getUserCharColor } from "helper/methods/PlaceholderColor";
import { projects, addnew } from "helper/constant";
import { projectsnew, HeaderDownArrowImage } from "helper/constant";
import AddProject from "components/projects/AddProject";
import "./BusinessSidebar.style.css";
import IntlMessages from "util/intlMessages";

const BusinessSidebar = ({
  business,
  handleEventClick,
  handleButtonClick,
  toggleAddProject,
  doOpenModalFromSidebar,
  handleGoTOProject,
  windowSize
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = index => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#fff",
          overflowY: "scroll",
          overflowX: "hidden",
          width: "100%",
          maxWidth: "250px",
          height: "100vh",
          top: "70px",
          scrollPadding: "auto",
          paddingBottom: "8rem",
          marginTop: 24,
          display: windowSize < 1010 ? "none" : "block"
        }}
      >
        <div className="d-flex justify-content-between  align-items-center  py-2 pl-2   ">
          <div style={{ fontWeight: 600 }}>
            <IntlMessages id={"header.workspace"} />
          </div>
          <div>
            <span
              className=" d-flex justify-content-center  align-items-center cursor-pointer "
              onClick={() => {
                doOpenModalFromSidebar(true, false);
              }}
            >
              <img
                src={addnew}
                alt=""
                className="add-workspace"
                style={{ padding: "5px", marginRight: 8 }}
              />
            </span>
          </div>
        </div>
        {business && Object.keys(business).length > 0 ? (
          <>
            {business?.map((result, i) => {
              return (
                <>
                  <div key={i}>
                    <div
                      className="d-flex justify-content-between  align-items-center mtb-5  workspace-list p-2 cursor-pointer "
                      onClick={() => handleClick(i)}
                      style={{
                        backgroundColor: "white",
                        color: "black"
                      }}
                    >
                      <div
                        className="align-items-center  d-flex "
                        style={{ width: "86%" }}
                      >
                        <div className="workspace-Avatar">
                          {result && result?.businessMeta ? (
                            <img
                              alt="workspace"
                              src={result?.businessMeta?.url}
                              height="24px"
                              width="24px"
                              style={{
                                borderRadius: "4px"
                              }}
                            />
                          ) : (
                            <div
                              className=" workspace-placeholder"
                              style={{
                                background: getUserCharColor(
                                  result?.title.charAt(0)
                                ),
                                height: "24px",
                                width: "24px",
                                fontSize: "20px",
                                borderRadius: "4px"
                              }}
                            >
                              {result && result?.title[0]?.toUpperCase()}
                            </div>
                          )}
                        </div>
                        <div
                          className="workspace-name   fs-15 demi-bold-text   text-truncate"
                          style={{
                            fontWeight: "bold"
                          }}
                        >
                          {result.title}
                        </div>
                      </div>
                      <div className="" style={{ paddingRight: "6px" }}>
                        {i === activeIndex ? (
                          <>
                            <div onClick={handleEventClick(result?.id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12.305"
                                height="6.99"
                                viewBox="0 0 12.305 6.99"
                                style={{
                                  transform: "rotate(180deg)"
                                }}
                              >
                                <g
                                  id="Group_261"
                                  data-name="Group 261"
                                  transform="translate(11.906 0.398) rotate(90)"
                                >
                                  <g
                                    id="Group_68"
                                    data-name="Group 68"
                                    transform="translate(0 0)"
                                  >
                                    <path
                                      id="Path_12809"
                                      data-name="Path 12809"
                                      d="M6.109,5.461.764.131A.448.448,0,1,0,.132.765L5.159,5.778.132,10.791a.448.448,0,0,0,.633.634L6.109,6.1a.448.448,0,0,0,0-.634Z"
                                      fill="#808080"
                                      stroke="#808080"
                                      strokeWidth="1"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12.305"
                                height="6.99"
                                viewBox="0 0 12.305 6.99"
                              >
                                <g
                                  id="Group_261"
                                  data-name="Group 261"
                                  transform="translate(11.906 0.398) rotate(90)"
                                >
                                  <g
                                    id="Group_68"
                                    data-name="Group 68"
                                    transform="translate(0 0)"
                                  >
                                    <path
                                      id="Path_12809"
                                      data-name="Path 12809"
                                      d="M6.109,5.461.764.131A.448.448,0,1,0,.132.765L5.159,5.778.132,10.791a.448.448,0,0,0,.633.634L6.109,6.1a.448.448,0,0,0,0-.634Z"
                                      fill="#808080"
                                      stroke="#808080"
                                      strokeWidth="1"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className=" d-flex ">
                    {i === activeIndex && (
                      <div
                        className=" d-flex flex-column"
                        style={{ marginLeft: "-0.2rem", width: "100%" }}
                      >
                        <>
                          {result.project.length <= 0 ? (
                            <>
                              <span
                                onClick={() => {
                                  toggleAddProject(result?.id);
                                }}
                                className="d-flex justify-content-start align-items-center p-2  fs-14 add-workspace cursor-pointer"
                                style={{
                                  marginLeft: "1.5rem"
                                }}
                              >
                                <img
                                  src={addnew}
                                  alt=""
                                  style={{
                                    padding: "5px"
                                  }}
                                />
                                <IntlMessages id={"project.addproject"} />
                              </span>
                            </>
                          ) : (
                            <>
                              {result.project.slice(0, 3).map((item, index) => {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      onClick={() => {
                                        handleGoTOProject(item.id);
                                      }}
                                      className="d-flex align-items-center p-2 mlr-5 cursor-pointer mtb-5  workspace-project"
                                      style={{ width: "100%" }}
                                    >
                                      <img
                                        height={18}
                                        width={18}
                                        src={projectsnew}
                                        alt=""
                                        className="ml-18 mr-10"
                                      />

                                      <p
                                        className="project-name message text-truncate fs-14 demi-bold-text"
                                        title={item?.name}
                                        style={{ position: "relative", top: 1 }}
                                      >
                                        {item?.name}
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          )}
                        </>

                        {result?.project?.length > 3 ? (
                          <>
                            <div className="d-flex justify-content-center ">
                              <button
                                className="border-0 bg-transparent text-primary "
                                style={{
                                  textDecoration: "underline",
                                  marginBottom: "1rem",
                                  fontSize: "12px"
                                }}
                                onClick={() => {
                                  handleButtonClick();
                                }}
                              >
                                <IntlMessages id={"project.showmore"} />
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </>
        ) : (
          <>
            <IntlMessages id={"globalworkspacelist.noworkspaces"} />
          </>
        )}
      </div>
    </>
  );
};

export default BusinessSidebar;
