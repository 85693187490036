import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import {
  assignnew,
  ListGridCheckImage,
  ListGridCircleImage,
  ProjectCollaboratorsCross
} from "helper/constant";
import RoyTooltip from "components/common/RoyTooltip";
import Shimmer from "components/shimmer/Shimmer";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import { getCollaboratorsList } from "helper/services/collboratorsServices";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import IntlMessages from "util/intlMessages";
const AssigneeDropdown = ({
  setUserAssigned,
  userAssigned,
  projectCollaborators,
  userSelectedProject,
  currentLoggedIn,
  isLoader,
  setIsLoader,
  setDropdownOpen,
  dropdownOpen,
  TargetId,
  PRIMARY_COLOR,
  isDisableDropDownIcon,
  projectId,
  businessId,
  setTaskMenu,
  language
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [collaboratorList, setCollaboratorsList] = useState([]);
  const [isCollabLoader, setIsCollabLoader] = useState(false);
  // useEffect(() => {
  //   if (dropdownOpen && !projectCollaborators?.length > 0 && !isLoader) {
  //     retrieveCollaboratsList();
  //   }
  //   if (projectCollaborators && projectCollaborators?.length > 0) {
  //     setCollaboratorsList([...projectCollaborators]);
  //   }

  //   return () => {};
  // }, [projectCollaborators, isLoader]);
  useEffect(() => {
    if (dropdownOpen) {
      if (userAssigned && userAssigned.user) {
        setSelectedIds(getIds(userAssigned.user));
      }
      if (projectCollaborators && projectCollaborators?.length > 0) {
        setCollaboratorsList([...projectCollaborators]);
      }
      if (dropdownOpen && !projectCollaborators?.length > 0 && !isLoader) {
        retrieveCollaboratsList();
      }
    }
    return () => {
      setCollaboratorsList([]);
      setIsCollabLoader(false);
      setSelectedIds([]);
    };
  }, [dropdownOpen, userAssigned]);

  const getIds = data => (data && data.length > 0 ? data.map(a => a.id) : []);
  const changeSelection = data => {
    if (data) {
      if (isSelecteduser(data?.user?.id)) {
        const filteredIds =
          selectedIds && selectedIds.filter(a => a !== data?.user?.id);
        setSelectedIds(filteredIds);
      } else {
        setSelectedIds(
          selectedIds && selectedIds?.length
            ? [...selectedIds, data.user.id]
            : [data.user.id]
        );
      }
    }
    // if (userAssigned && data?.user.id === userAssigned?.user?.id) {
    //   setUserAssigned(null);
    // } else setUserAssigned(data);
  };
  const retrieveCollaboratsList = async () => {
    if (projectId) {
      try {
        setIsCollabLoader(true);
        const result = await getCollaboratorsList(projectId);
        if (result) {
          if (result.error) {
          }
          if (result.data) {
            setCollaboratorsList([...result.data]);
          }
        }
        setIsCollabLoader(false);
      } catch (error) {
        setIsCollabLoader(false);
      }
    }
  };
  const handleAssignTask = () => {
    const filteredData = [];
    collaboratorList &&
      collaboratorList.map(a => {
        if (a.user) {
          if (selectedIds?.includes(a.user.id)) {
            filteredData.push(a.user);
          }
        }
      });

    // console.log({ user: [...filteredData] });

    setUserAssigned({ user: [...filteredData] });
    setDropdownOpen(!dropdownOpen);
  };
  const isSelecteduser = id => {
    if (id && selectedIds) {
      if (selectedIds?.includes(id)) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <Dropdown
      direction="down"
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
      style={{ display: "flex", alignItems: "center", position: "static" }}
    >
      <DropdownToggle
        // caret
        className="btn-no-padding"
        style={{
          backgroundColor: "transparent",
          border: 0,
          padding: "0px 0px 0px 0px",
          margin: "0px 0px 0px 0px"
          // padding: btnPadding || 12
        }}
      >
        {isDisableDropDownIcon ? (
          <div></div>
        ) : (
          <RoyTooltip
            id={TargetId || "Assign"}
            title={InjectPlaceHolderMessage(
              "today.assign",
              language?.language?.languageId
            )}
            placement={"top"}
            style={{ display: "flex" }}
          >
            <i id={TargetId || "Assign"}>
              <img
                alt=""
                src={assignnew}
                style={{
                  height: "15px",
                  width: "15px",
                  margin: "0px 0px 2px 0px"
                }}
              ></img>
            </i>
          </RoyTooltip>
        )}
      </DropdownToggle>
      <DropdownMenu
        onClick={e => {
          e.stopPropagation();
          setTaskMenu && setTaskMenu(false);
        }}
        style={{
          border: "0px",
          boxShadow: "0px 0px 15px #0000001A",
          borderRadius: "10px",
          minWidth: "300px"
        }}
      >
        <div
          onClick={e => {
            e.stopPropagation();
            setTaskMenu && setTaskMenu(false);
          }}
          className="text-muted "
          style={{
            padding: "5px 11px",
            borderBottom: "1px solid #EBEBEB",
            margin: "0px 4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div
            onClick={e => {
              e.stopPropagation();
              setTaskMenu && setTaskMenu(false);
            }}
            style={{ fontSize: 16, color: "#808080" }}
          >
            {<IntlMessages id={"today.assigntask"} />}
          </div>

          <div
            onClick={e => {
              e.stopPropagation();
              setTaskMenu && setTaskMenu(false);
            }}
          >
            <img
              alt=""
              src={ProjectCollaboratorsCross}
              style={{
                height: "12px",
                width: "12px",
                position: "absolute",
                top: 18,
                right: "15px",
                cursor: "pointer"
              }}
              onClick={e => {
                e.stopPropagation();
                setDropdownOpen(!dropdownOpen);
              }}
            ></img>
          </div>
        </div>
        {isCollabLoader || isLoader ? (
          <Shimmer />
        ) : (
          <>
            <div style={{ height: 260, overflow: "auto" }}>
              {!userSelectedProject && collaboratorList.length === 0 && (
                <div className="text-muted  fs-12" style={{ padding: 15 }}>
                  Select a project to get assignee
                </div>
              )}
              {collaboratorList && collaboratorList.length > 0 ? (
                collaboratorList.map((data, i) => {
                  if (data.requestStatus !== "SENT" && data.user !== null)
                    return (
                      <>
                        <div onClick={() => changeSelection(data)}>
                          <DropdownItem
                            onClick={e => e.preventDefault()}
                            toggle={false}
                            key={i}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                              padding: " 15px",
                              background:
                                userAssigned &&
                                userAssigned?.user?.id === data?.user?.id
                                  ? "#c6d1dc"
                                  : "transaparent"
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%"
                              }}
                            >
                              <div
                                className=" flex-x "
                                style={{
                                  height: 28,
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                {data.user?.userMeta ? (
                                  <img
                                    className="img-sm"
                                    height="30px"
                                    width="30px"
                                    style={{ borderRadius: "50%" }}
                                    src={data.user?.userMeta?.thumbnail}
                                    alt="profile"
                                  ></img>
                                ) : (
                                  <div
                                    style={profileBackground(
                                      data.user && data.user.name
                                        ? data.user.name
                                        : data.user.email,
                                      35,
                                      35,
                                      15
                                    )}
                                  >
                                    {data.user && data.user.name
                                      ? data.user.name.charAt(0).toUpperCase()
                                      : data.user.email.charAt(0).toUpperCase()}
                                  </div>
                                )}
                                <div
                                  className=" flex-1 "
                                  style={{
                                    fontSize: 14,
                                    paddingLeft: 10,
                                    color: "black"
                                  }}
                                >
                                  <div>
                                    <span
                                      style={{
                                        fontSize: 14,
                                        color: "black",
                                        fontWeight: "600"
                                      }}
                                    >
                                      {data.user.name}{" "}
                                      {currentLoggedIn &&
                                        currentLoggedIn == data?.user?.id && (
                                          <IntlMessages id={"project.you"} />
                                        )}
                                    </span>
                                    {/* <br /> */}
                                    <div
                                      className=" text-muted "
                                      style={{ fontSize: 14, color: "#808080" }}
                                    >{`${data?.user.email}`}</div>
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{ paddingLeft: 15 }}
                                // onClick={() => changeSelection(data)}
                              >
                                {isSelecteduser(data.user.id) ? (
                                  <img
                                    alt=""
                                    src={ListGridCheckImage}
                                    style={{
                                      height: 18,
                                      width: 18
                                    }}
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src={ListGridCircleImage}
                                    style={{
                                      height: 18,
                                      width: 18
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </DropdownItem>
                        </div>
                      </>
                    );
                })
              ) : (
                <div
                  style={{ padding: "0px 10px", fontSize: 12, color: "gray" }}
                >
                  <Shimmer />
                </div>
              )}
            </div>
            <div
              style={{
                display:
                  collaboratorList && collaboratorList.length > 0
                    ? "flex"
                    : "none",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Button
                className="btn btn-sm"
                style={{
                  backgroundColor: PRIMARY_COLOR,
                  lineSpacing: "1px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  display: "flex",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  padding: "5px 10px",
                  border: "0px solid ",
                  margin: "10px 15px 5px"
                }}
                onClick={() => handleAssignTask()}
                // onClick={() => doUpdateTaskAssign(data.user)}
              >
                {<IntlMessages id={"globalsearchbar.applybutton"} />}
              </Button>
            </div>
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    PRIMARY_COLOR: state.themeChanger.topbarTheme.buttonColor
  };
};

export default connect(mapStateToProps, null)(AssigneeDropdown);
