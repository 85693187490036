const EnglishStaticText = require("../../../src/languageProvider/locales/en_US.json");
const GermanStaticText = require("../../../src/languageProvider/locales/de_DE.json");
const DutchStaticText = require("../../../src/languageProvider/locales/nl_NL.json");

export default function InjectPlaceHolderMessage(data, language) {
  if (language == "german") {
    return GermanStaticText[data];
  } else if (language == "dutch") {
    return DutchStaticText[data];
  } else {
    return EnglishStaticText[data];
  }
}
