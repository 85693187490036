import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { getProject } from "helper/services/projectServices";
import Loader from "components/loader/Loader";
import { getColorCode } from "components/projects/utils/color";
import { moveSection } from "services/socket";
import { projectSocketRoomPrefix } from "constatnt";
import BusinessListShimmer from "components/shimmer/BusinessListShimmer";
import IntlMessages from "util/intlMessages";
const MoveListProject = props => {
  const {
    modal,
    setmodal,
    showNotifications,
    PRIMARY_COLOR,
    currentProjectId,
    sectionId
  } = props;
  const [projectsList, setProjectsList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isDefaultLoader, setIsDefaultLoader] = useState({
    id: "",
    status: false
  });
  useEffect(() => {
    if (modal) {
      fecthProject();
    }
    return () => {
      setIsLoader(false);
      setIsDefaultLoader({
        id: "",
        status: false
      });
      setProjectsList([]);
    };
  }, [modal]);

  const doMoveSection = async data => {
    try {
      if (data.sectionId && data.projectId) {
        setIsDefaultLoader({ status: true, id: data.projectId });
        let payload = {
          section_id: data?.sectionId,
          project_id: data?.projectId,
          roomname: `${projectSocketRoomPrefix}${currentProjectId}`
        };

        moveSection(payload, cb => {
          if (cb.status == "Ok") {
            setmodal(!modal);
            setIsDefaultLoader({ status: false, id: "" });
            showNotifications(
              <IntlMessages id={"project.listmovemessage"} />,
              "success"
            );
          } else {
            setmodal(!modal);
            setIsDefaultLoader({ status: false, id: "" });

            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */

            showNotifications();
          }
        });
      }
    } catch (e) {
      setIsDefaultLoader({ status: false, id: "" });
    }
  };
  const fecthProject = async () => {
    try {
      setIsLoader(true);
      const Response = await getProject();
      if (Response) {
        if (Response.data) {
          let data =
            Response.data &&
            Response.data.filter(
              project =>
                !project.isDeleted &&
                !project.isArchived &&
                parseInt(currentProjectId) !== project.id
            );

          setProjectsList([...data]);

          setIsLoader(false);
        }
        if (Response.error) {
          showNotifications();
          setIsLoader(false);
        }
      }
    } catch (e) {}
  };

  function onHoverStyle(div) {
    document.getElementById(div).style.background = "#f5f5f5";
  }
  function onMouseLeave(div) {
    document.getElementById(div).style.background = "#fff";
    // e.target.style.background = "#fff";
  }
  return (
    <div>
      {modal ? (
        <Modal
          size="md"
          isOpen={modal}
          toggle={() => setmodal(!modal)}
          centered
        >
          <ModalHeader toggle={() => setmodal(!modal)}>
            <IntlMessages id={"project.choose_project"} />
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                overflow: " scroll",
                height: " 370px"
              }}
            >
              {isLoader ? (
                <BusinessListShimmer width="50px" height="50px" />
              ) : (
                <>
                  {projectsList && projectsList?.length > 0 ? (
                    <div>
                      <table
                        style={{
                          width: "100%",
                          borderSpacing: "1em .5em",
                          padding: "0 2em 1em 0"
                        }}
                      >
                        <tbody>
                          {projectsList &&
                            projectsList.map((project, i) => {
                              if (parseInt(currentProjectId) !== project.id) {
                                return (
                                  <tr
                                    key={i}
                                    id={`divqw${i}`}
                                    onMouseEnter={() =>
                                      onHoverStyle(`divqw${i}`)
                                    }
                                    onMouseLeave={() =>
                                      onMouseLeave(`divqw${i}`)
                                    }
                                  >
                                    <td
                                      style={{
                                        width: 1,
                                        paddingBottom: 8,
                                        paddingTop: 8,
                                        cursor: "pointer"
                                      }}
                                    >
                                      {" "}
                                      <div
                                        style={{
                                          paddingTop: 10,
                                          paddingBottom: 8
                                        }}
                                      >
                                        <i
                                          class="fas fa-circle fs-12"
                                          style={{
                                            color: getColorCode(project.color)
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: 5,
                                        width: "100%",
                                        paddingBottom: 8,
                                        paddingTop: 8,
                                        cursor: "pointer"
                                      }}
                                    >
                                      <div
                                        className="mlr-5  "
                                        id="changeBusinessss"
                                      >
                                        <p className=" fs-14 fw-400  demi-bold-text">
                                          {project && project.name}
                                        </p>
                                      </div>
                                    </td>

                                    <td
                                      style={{
                                        paddingBottom: 8,
                                        paddingTop: 8
                                      }}
                                    >
                                      {" "}
                                      {isDefaultLoader.status &&
                                      isDefaultLoader.id === project.id ? (
                                        <Loader height="23px" width="18px" />
                                      ) : (
                                        <div
                                          className="task-icons plr-10"
                                          style={{ textAlign: "center" }}
                                        >
                                          <button
                                            className="btn button button-hover btn-sm"
                                            style={{
                                              border: `1px solid ${PRIMARY_COLOR}`,
                                              color: PRIMARY_COLOR
                                            }}
                                            onClick={() =>
                                              doMoveSection({
                                                projectId: project.id,
                                                sectionId: sectionId
                                              })
                                            }
                                          >
                                            <IntlMessages id={"project.move"} />
                                          </button>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: 50,
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center"
                      }}
                    >
                      No Project found
                    </div>
                  )}
                </>
              )}
            </div>
          </ModalBody>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};
export default MoveListProject;
