import React from "react";
import { Popover, PopoverBody } from "reactstrap";
const ArchivedMoreOptionsMenu = ({
  isOpen,
  toggleEditProject,
  isOwner,
  isArchived,
  popoverTarget,
  toggle,
  updateArchiveProject
}) => {
  return (
    <div>
      {isOpen ? (
        <Popover
          trigger="legacy"
          className="roy-menu"
          innerClassName="roy-inner-content"
          placement="bottom-start"
          target={popoverTarget}
          isOpen={isOpen}
          toggle={toggle}
        >
          <PopoverBody>
            {isOwner && (
              <div
                className="roy-menu-list"
                onClick={e => {
                  e.stopPropagation();
                  toggle();
                  updateArchiveProject();
                }}
              >
                {isArchived ? "Unarchive Project" : "Archive Project"}
              </div>
            )}

            {/* {!isOwner && (
            <>
              <div
                className="roy-menu-list"
                style={{ color: "red" }}
                onClick={() => leaveProject()}
              >
                <i className="fa fa-sign-out" />
                UnArchive Project
              </div>
            </>
          )} */}
            {/* <hr />
          <div className="roy-menu-list" onClick={() => deleteProject()}>
            Delete Project
          </div> */}
          </PopoverBody>
        </Popover>
      ) : (
        ""
      )}
    </div>
  );
};

export default ArchivedMoreOptionsMenu;
