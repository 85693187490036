/** @format */

import {
  getMyAssignments,
  getCreatedByMeAssignment
} from "helper/services/globalmyassignment";
import React, { useEffect, useState } from "react";
import ProjecttList from "./ProjecttList";
import Shimmer from "components/shimmer/Shimmer";
import { getIndexOf, getKeyIndexOf } from "helper/methods/utilFunctions";
import { doGroupByproject, objectToArray } from "helper/methods/utilFunctions";
import LinearLoader from "components/loader/LinearLoader";
import IntlMessages from "util/intlMessages";

const MyCompletedAssignments = props => {
  const { handleRedirection, isMyAssignments, updateIsVisibility } = props;
  useEffect(() => {
    fetchAssignments();
    return () => {
      setPageInfo({
        currentPage: 0,
        totalPages: 0
      });
      setCompletedMyAssignments({});
      setIsLoader(false);
      setIsCompletedListvisible(true);
    };
  }, []);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    totalPages: 0
  });
  const [completedMyAssignments, setCompletedMyAssignments] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [isNextPageLoader, setIsNextPageLoader] = useState(false);
  const [isCompletedListvisible, setIsCompletedListvisible] = useState(true);

  const fetchAssignments = isNextPage => {
    isMyAssignments
      ? fetchMyCompletedAssignments(isNextPage)
      : fetchMyStuffCompletedAssignments(isNextPage);
  };
  const fetchMyCompletedAssignments = async (isNextPage = false) => {
    try {
      !isNextPage && setIsLoader(true);
      let nextPage = pageInfo.currentPage + 1;
      isNextPage && setIsNextPageLoader(true);
      const { data, error } = await getMyAssignments(10, nextPage, true);
      if (data) {
        setPageInfo({
          currentPage: parseInt(data.currentPage),
          totalPages: data.totalPages,
          isLastPage: data.isLastPage
        });
        if (data.task?.length > 0) {
          updateIsVisibility && updateIsVisibility(true);
          let groupData = doGroupByproject(data.task);
          let arrayData = await objectToArray(groupData);
          if (groupData) {
            if (isNextPage) {
              setCompletedMyAssignments([
                ...completedMyAssignments,
                ...arrayData
              ]);
            } else {
              setCompletedMyAssignments([...arrayData]);
            }
            setIsLoader(false);
          }
        } else {
          if (parseInt(data.currentPage) == 1 && data?.task.length == 0) {
            updateIsVisibility && updateIsVisibility(false);
          }
          setCompletedMyAssignments([]);
        }
      }
      if (error) {
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoader(false);
      setIsNextPageLoader(false);
    }
  };
  const fetchMyStuffCompletedAssignments = async (isNextPage = false) => {
    try {
      !isNextPage && setIsLoader(true);
      let nextPage = pageInfo.currentPage + 1;
      isNextPage && setIsNextPageLoader(true);
      const { data, error } = await getCreatedByMeAssignment(
        10,
        nextPage,
        true
      );
      if (data) {
        setPageInfo({
          currentPage: parseInt(data.currentPage),
          totalPages: data.totalPages,
          isLastPage: data.isLastPage
        });
        if (data.task?.length > 0) {
          updateIsVisibility && updateIsVisibility(true);
          let groupData = doGroupByproject(data.task);
          let arrayData = await objectToArray(groupData);
          if (groupData) {
            if (isNextPage) {
              setCompletedMyAssignments([
                ...completedMyAssignments,
                ...arrayData
              ]);
            } else {
              setCompletedMyAssignments([...arrayData]);
            }
            setIsLoader(false);
          }
        } else {
          if (parseInt(data.currentPage) == 1 && data?.task.length == 0) {
            updateIsVisibility && updateIsVisibility(false);
          }
          setCompletedMyAssignments([]);
        }
      }
      if (error) {
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoader(false);
      setIsNextPageLoader(false);
    }
  };

  const doUpdateTaskStatus = (taskId, status, objectKey, givenIndex) => {
    try {
      let assignmentDataarr = [...completedMyAssignments];
      let isStatusUpdated = false;
      var index = getKeyIndexOf(
        completedMyAssignments,
        "project",
        objectKey.project.id
      );
      if (index !== -1) {
        let taskIndex = getIndexOf(assignmentDataarr[index].task, taskId);
        if (taskIndex !== -1) {
          assignmentDataarr[index].task[
            taskIndex
          ].isCompleted = !assignmentDataarr[index].task[taskIndex].isCompleted;
          isStatusUpdated = true;
        }
      }
      if (isStatusUpdated) {
        setCompletedMyAssignments([...assignmentDataarr]);
      }
      return 0;
    } catch (err) {
      // console.log(err)
    }
  };
  return (
    <div>
      {isCompletedListvisible ? (
        <>
          {isLoader ? (
            <>
              <div
                style={{
                  color: "black",
                  fontWeight: 600,
                  cursor: "pointer",
                  fontSize: 14
                }}
              >
                <IntlMessages id={"project.showingmycompleteassignment"} />
              </div>
              <Shimmer />
              <Shimmer />
            </>
          ) : (
            <>
              {completedMyAssignments && completedMyAssignments.length ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "30px 0px 30px 0px "
                    }}
                  >
                    <div
                      style={{
                        height: 1,
                        width: "100%",
                        borderBottom: "1px solid #dfe1e6"
                      }}
                    ></div>

                    <div
                      style={{
                        color: "black",
                        fontWeight: 400,
                        fontSize: 16,
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        padding: "0px 10px"
                      }}
                    >
                      <IntlMessages id={"project.mycompleteassignment"} />
                    </div>
                    <div
                      style={{
                        height: 1,
                        width: "100%",
                        borderBottom: "1px solid #dfe1e6"
                      }}
                    ></div>
                  </div>
                  {completedMyAssignments &&
                    completedMyAssignments.map((element, index) => {
                      return (
                        <ProjecttList
                          handleRedirection={handleRedirection}
                          project={element.project}
                          task={element.task}
                          index={index}
                          toggleTaskStatus={(id, status) => {
                            doUpdateTaskStatus(id, status, element, index);
                          }}
                        />
                      );
                    })}
                  {!pageInfo.isLastPage && (
                    <div
                      style={{
                        color: props.PRIMARY_COLOR,
                        fontSize: 14,
                        textDecoration: isNextPageLoader ? "none" : "underline",
                        textAlign: "center",
                        cursor: isNextPageLoader ? "not-allowed" : "pointer",
                        paddingBottom: 10
                      }}
                    >
                      {isNextPageLoader ? (
                        <LinearLoader />
                      ) : (
                        <div
                          style={{
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "2.09rem"
                          }}
                          onClick={() =>
                            !pageInfo.isLastPage ? fetchAssignments(true) : {}
                          }
                        >
                          View more
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              color: "#1b6ac9",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: 14
            }}
            onClick={() => {
              setIsCompletedListvisible(true);
              fetchAssignments();
            }}
          >
            Show my completed assignments…
          </div>
        </>
      )}
    </div>
  );
};

export default MyCompletedAssignments;
