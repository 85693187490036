import styled from "styled-components";

const WeekDatePickerWrapper = styled.div`
  .day {
    padding: 5px;
    border-bottom: 3px solid #fff;
  }
  .days li {
    paddingbottom: 5px !important;
  }
  .active,
  .day:hover {
    borderbackground-color: aliceblue;
    // border-radius:0.25rem;
    color: ${props => props.topbarTheme.buttonColor} !important;
    cursor: pointer;

    border-bottom: 3px solid ${props => props.topbarTheme.buttonColor};
  }
  .active:hover {
    color: ${props => props.topbarTheme.buttonColor} !important;
  }
  .hover-white-color:hover {
    color: white;
  }
`;

export default WeekDatePickerWrapper;
