import styled from "styled-components";

const AddProjectWorkspaceWrapper = styled.div`
  .form-control {
    border: ${propsaData =>
      propsaData.borderError == true
        ? "0px solid red !important"
        : "0px solid #e6e6e6 !important"};
    border-radius: 5px !important;
  }

  .form-control:focus {
    border: 0px solid #e6e6e6 !important;
  }
  .css-1m8a84f-control {
    border: 1px solid #e6e6e6 !important;
    border-radius: 5px !important;
  }
  .custom-datepicker {
    border: 1px solid #e6e6e6 !important;
    border-radius: 5px !important;
  }

  .quill {
    min-height: 95px !important;
    max-height: 200px !important;
  }
  .ql-editor {
    min-height: 95px !important;
    max-height: 200px !important;
  }
  .ql-toolbar.ql-snow {
    border: none !important;
  }
  .ql-container.ql-snow {
    border: none !important;
  }
  .ql-editor ql-blank {
    min-height: 95px !important;
    max-height: 200px !important;
  }
`;

export default AddProjectWorkspaceWrapper;
