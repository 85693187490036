import styled from "styled-components";

const WeekDatePickerWrapper = styled.div`
  .container {
  }
  .container:hover {
    background-color: #f7f7f7;

    cursor: pointer;
  }
`;

export default WeekDatePickerWrapper;
