import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";

const StyleSheet = {
  container: {
    border: "0px solid rgba(255, 255, 255, 1)",
    padding: "16px",
    borderRadius: "4px",
    marginTop: "15px"
  },
  circle: {
    height: "30px",
    width: "30px",
    borderRadius: "50%"
  },
  line: {
    width: "300px",
    height: "15px",
    alignSelf: "center"
  },
  line2: {
    width: "40px",
    height: "8px"
  }
};
class Analysis extends Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.container}>
          <Shimmer>
            <div className={classes.line} />
            <br />
            <div className={classes.line} />
          </Shimmer>
        </div>
      </>
    );
  }
}

export default injectSheet(StyleSheet)(Analysis);
