import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ProjectCollaboratorsCross } from "helper/constant";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import { getTaskActivity } from "helper/services/taskServices";
import moment from "moment";
import { connect } from "react-redux";
const ShareModal = ({
  isTaskDetailsActivityModal,
  toggleTaskDetailsModal,
  props
}) => {
  const [taskDetailActivityData, setTaskDetailActivityData] = useState();

  useEffect(() => {
    if (isTaskDetailsActivityModal) {
      fetchTaskActivity();
    }

    return () => {};
  }, [isTaskDetailsActivityModal]);

  const ActivityTypes = {
    TASK_ADD: "taskadd",
    TASK_ASSIGN: "taskassign",
    TASK_ASSIGN_REMOVE: "removeassign",
    NAME_CHANGE: "namechange",
    LABEL_CHANGE: "labelchange",
    PRIORITY_CHANGE: "prioritychange",
    CHECHED_CHANGE: "checkedchange",
    DUEDATE_CHANGE: "duedatechange",
    SUBTASK_ADD: "subtaskadd",
    SUBTASK_DELETE: "subtaskdelete",
    CHECKLIST_ADD: "checklistadd",
    CHECKLIST_UPDATE: "checklistupdate",
    CHECKLIST_DELETE: "checklistdelete",
    TASK_ARCHIVE: "archivedtask",
    TASK_UNARCHIVE: "unarchivedtask"
  };

  const fetchTaskActivity = async () => {
    try {
      if (props.taskDetails.id) {
        // pass type: 1 for task duedate and checked change activity only
        const Response = await getTaskActivity(props.taskDetails.id, 0);
        if (Response) {
          if (Response.error) {
            console.log("taskDetailActivityDatataskDetailActivityData");
          }
          if (Response.data) {
            setTaskDetailActivityData(Response.data[0]?.taskActivity);
          }
        }
      }
    } catch (e) {
      console.log("taskDetailActivityData");
      console.log(Response.data[0]?.taskActivity, "0000");
    }
  };

  const generateSentense = item => {
    if (item.activity_type == ActivityTypes.TASK_ADD) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          added new task <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.TASK_ASSIGN) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          assigned task to <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.TASK_ASSIGN_REMOVE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          removed <b>{item.value}</b> as task assignee
        </span>
      );
    } else if (item.activity_type == ActivityTypes.NAME_CHANGE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          changes task name from <b>{item.past_value}</b> to <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.LABEL_CHANGE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          added label <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.PRIORITY_CHANGE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          changed priority from <b>{item.past_value.replace("_", " ")} </b>
          to <b>{item.value.replace("_", " ")}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.CHECHED_CHANGE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          <b>
            {item.value == "completed"
              ? "completed this to-do"
              : "re-open this to-do"}
          </b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.DUEDATE_CHANGE) {
      if (!item.past_value) {
        return (
          <span className="fs-14 text-muted">
            {" "}
            added{" "}
            <span style={{ fontWeight: "bold", fontSize: 13 }}>
              {moment(item.value).format("DD MMM YYYY")}
            </span>{" "}
            due date
          </span>
        );
      } else if (item.activity_type == ActivityTypes.TASK_ARCHIVE) {
        return <span className="fs-14 text-muted"> archived task</span>;
      } else if (item.activity_type == ActivityTypes.TASK_UNARCHIVE) {
        return <span className="fs-14 text-muted"> unarchived task</span>;
      }
      return (
        <span className="fs-14 text-muted">
          {" "}
          changed due date from{" "}
          <span style={{ fontWeight: "bold", fontSize: 13 }}>
            {moment(item.past_value).format("DD MMM YYYY")}
          </span>{" "}
          to{" "}
          <span style={{ fontWeight: "bold", fontSize: 13 }}>
            {moment(item.value).format("DD MMM YYYY")}
          </span>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.SUBTASK_ADD) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          added new subtask <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.SUBTASK_DELETE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          deleted subtask <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.CHECKLIST_ADD) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          added new checklist <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.CHECKLIST_UPDATE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          has changed checklist title from <b>{item.past_value}</b> to{" "}
          <b>{item.value}</b>
        </span>
      );
    } else if (item.activity_type == ActivityTypes.CHECKLIST_DELETE) {
      return (
        <span className="fs-14 text-muted">
          {" "}
          deleted checklist <b>{item.value}</b>
        </span>
      );
    }
  };

  return (
    <Modal
      isOpen={isTaskDetailsActivityModal}
      toggle={() => toggleTaskDetailsModal()}
      size="lg"
      centered
    >
      <ModalHeader style={{ position: "relative", padding: "16px 25px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontSize: 17, color: "black", fontWeight: 600 }}>
            {" "}
            Task Details Activity
          </div>
          <div>
            <img
              alt=""
              src={ProjectCollaboratorsCross}
              style={{
                height: "18px",
                width: "15px",
                marginRight: "0px",
                marginTop: "0px",
                textAlign: "center",
                position: "absolute",
                top: 22,
                right: "20px",
                cursor: "pointer"
              }}
              onClick={() => toggleTaskDetailsModal()}
            ></img>
          </div>
        </div>
      </ModalHeader>

      <ModalBody
        style={{
          height: 400,
          padding: "0px 10px",
          margin: "0px 15px",
          overflow: "scroll",
          overflowX: "hidden"
        }}
      >
        {taskDetailActivityData?.map(data => {
          if (
            data.activity_type !== "archivedtask" &&
            data.activity_type !== "unarchivedtask"
          ) {
            return (
              <>
                <div className={"row"}>
                  <div
                    className={"col-lg-9 col-md-9 col-sm-9 col-xs-9"}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      minHeight: 50
                    }}
                  >
                    {data?.user ? (
                      <img
                        alt=""
                        src={data.user ? data.user.thumbnail : ""}
                        style={{
                          borderRadius: "50%",
                          height: 30,
                          width: 30,
                          border: "1px solid white"
                        }}
                      ></img>
                    ) : (
                      <div
                        title={data.name}
                        // id="userAssigned"
                        style={profileBackground(data?.name, 30, 30, 15)}
                      >
                        {data.name[0].toUpperCase()}
                      </div>
                    )}
                    <div style={{ paddingLeft: "10px" }}>
                      <div>
                        <span>
                          <b>{data.user.name}</b> {generateSentense(data)}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div
                    className={"col-lg-3 col-md-3 col-sm-3 col-xs-3"}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <p className="text-muted" style={{ fontSize: 14 }}>
                      <small>
                        {/* {moment(data.createdAt).format("DD MMM YYYY hh:mm a")} */}
                        {moment(data.createdAt).format("DD MMM YYYY") ==
                        moment().format("DD MMM YYYY")
                          ? `${moment(data.createdAt).fromNow()}`
                          : `${moment(data.createdAt).format(
                              "DD MMM YYYY hh:mm a"
                            )}`}
                      </small>
                    </p>
                  </div>
                </div>
              </>
            );
          }
        })}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, null)(ShareModal);
