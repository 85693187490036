/** @format */

import React, { useEffect, useState } from "react";
import { compose } from "redux";
import chroma from "chroma-js";
import DatePicker from "react-datepicker";

import Switch from "react-switch";
import AddProjectWrapper from "./AddProjectWrapper.style";
import DescriptionEditor from "components/business/DescriptionEdiotr";
import enhancer from "views/pages/authentication/enhancer/AddProjectEnhancer";
import DatepickerWrapper from "components/forms/alldatepickers/datepicker.style";
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import ColorPopover from "components/projects/ProjectColorDropdown";
import SelectWokspaceDropdown from "components/projects/SelectWokspaceDropdown";
import { createProject } from "helper/services/projectServices";
import { Alert } from "reactstrap";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import { list, gridicon } from "helper/constant";
import {
  ProjectColors,
  ProjectColorsGn,
  ProjectColorsDut
} from "./utils/color";
import { getBusinessProject } from "helper/services/businessServices";
import ToastMessage from "components/notifications/ToastMessage";
import { useToasts } from "react-toast-notifications";
import alertActions from "redux/globalAlert/actions";
import moment from "moment";
import AddProjectWorkspaceWrapper from "./AddProjectWorkspaceWrapper.style";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import { de, nl } from "date-fns/locale";

const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const AddProject = props => {
  const {
    modal,
    setmodal,
    updateRedux,
    history,
    topbarTheme,
    defaultWorkspaceId
  } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedColor, setSelectedColor] = useState();
  const [isUserSelectedColor, setIsUserSelectedColor] = useState(false);
  const [colorChange, setColorChange] = useState("grid");
  const [workspaceData, setWorkspaceData] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [startDate, setStartDate] = useState(moment(new Date()).toDate());
  const [endDate, setEndDate] = useState();
  // new Date().setDate(new Date().getDate() + 7)
  const [MinimumEndDate, setMinimumEndDate] = useState(new Date());
  const [projectDescription, setProjectDescription] = useState("");
  const [isProjectNameError, setisProjectNameError] = useState({
    status: false,
    message: ""
  });
  const [isWorkspaceRetrieved, setIsWorkspaceRetrieved] = useState(false);
  const [cheakVlaue, setCheakVlaue] = useState(false);
  const [borderError, setBorderError] = useState(false);
  const { addToast } = useToasts();
  const [locale, setLocale] = useState();
  const [options, setOption] = useState();

  const handleChangeStartDate = date => {
    setStartDate(date);
    // setEndDate(date ? date : new Date());
    setMinimumEndDate(date);
  };

  // const buttonStyle={width:"calc((0% - (100% - 1em)) - 8%)"}
  const buttonStyle = { width: "calc(-100% + 1em)" };
  const handleChangeEndDate = date => setEndDate(date);

  const colourStyles = {
    control: (base, state) => ({
      ...base,
      background: "#ffff",

      // match with the menu
      border: state.isDisabled
        ? null
        : state.isSelected
        ? "#909090"
        : state.isFocused
        ? "#f6f6f6"
        : null
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#909090"
          : isFocused
          ? "#f6f6f6"
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast("grey", "white") > 2
            ? "white"
            : "black"
          : "#808080",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6")
        }
      };
    },
    singleValue: styles => ({ ...styles, color: "black" })
  };

  useEffect(() => {
    if (modal) {
      retriveBusiness();
    }
    if (props.language.locale == "de") {
      setLocale(de);
      setOption(ProjectColorsGn);
      setSelectedColor(ProjectColorsGn[1].label);
    } else if (props.language.locale == "nl") {
      setLocale(nl);
      setOption(ProjectColorsDut);
      setSelectedColor(ProjectColorsDut[1].label);
    } else {
      setLocale(props.language.locale);
      setOption(ProjectColors);
      setSelectedColor(ProjectColors[1].label);
    }
    return () => {
      setIsDisabled(true);
      setVisible(false);
      setErrorMessage(null);
      setIsLoader(false);
      setIsUserSelectedColor(false);
      setProjectDescription("");
      // setSelectedColor(null)
    };
  }, [modal, props.language]);

  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        // status:action.alert.status, message:action.alert.message,action:'open_businesslist'

        props.open_workpsace_alert({
          status: true,
          message: "workspace"
        });
      }
      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
      }
    } catch (e) {}
  };

  const retriveBusiness = async () => {
    try {
      setIsWorkspaceRetrieved(true);
      const Response = await getBusinessProject();

      if (Response) {
        if (Response.data) {
          var businessArr = [];
          Response.data &&
            Response.data.map(a => {
              if (isDataUnique(businessArr, a.business)) {
                businessArr.push({
                  ...a.business,
                  value: a.business.title,
                  label: a.business.title,
                  businessMeta: a.business?.businessMeta || null
                });
              }
            });

          setWorkspaceData([...businessArr]);
          checkForDefaulWorkspace([...businessArr]);
          setIsWorkspaceRetrieved(false);
        }
        if (Response.error) {
          handleResponse(Response.error.message);
        }
      }
    } catch (e) {
      setIsWorkspaceRetrieved(true);
    }
  };

  const isDataUnique = (oldArray, newData) => {
    var isUnique = true;
    if (oldArray) {
      oldArray.map((subtask, i) => {
        if (subtask.id == newData.id) {
          isUnique = false;
        }
      });

      return isUnique;
    }
  };

  const { errors, touched, submitCount } = props;

  const ValidationError = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const checkForDefaulWorkspace = business => {
    if (defaultWorkspaceId) {
      business &&
        business.map(data => {
          if (data.id === defaultWorkspaceId) {
            setSelectedWorkspace({
              ...data,
              value: data.title,
              label: data.title
            });
          }
        });
    } else {
      business &&
        business.map(data => {
          if (data.id === props.auth.user.businessId) {
            setSelectedWorkspace({
              ...data,
              value: data.title,
              label: data.title
            });
          }
        });
    }
  };

  const handleProjectSubmit = async e => {
    e.preventDefault();
    let { values, handleSubmit } = props;
    setVisible(false);
    if (selectedWorkspace == null) {
      setBorderError(true);
    } else if (selectedWorkspace != null) {
      setBorderError(false);
    } else {
      setBorderError(false);
    }

    if (
      e.target.name.value &&
      selectedColor &&
      selectedWorkspace /* && values.name && values.workspace */
    ) {
      setIsLoader(true);
      try {
        const Response = await createProject({
          name: e.target.name.value,
          isFavorite: e.target.addtoFavorite.checked,
          // description: e.target.projectDescription.value,
          description: e.target.projectDescription?.value
            ? e.target.projectDescription.value
            : projectDescription,
          businessId: selectedWorkspace.id.toString(),
          startDate: moment(new Date(startDate)).toDate(),
          endDate: moment(new Date(endDate)).toDate(),
          color: selectedColor == "grau" ? "grey" : selectedColor,
          defaultView: e.target.View.value
        });
        if (Response) {
          setIsLoader(false);
          if (Response.error) {
            handleResponse(Response.error.message);
            setVisible(true);
            setErrorMessage(Response.error.message);
          } else if (Response.errors) {
            handleResponse(Response.errors.message);
            setVisible(true);
            setErrorMessage(Response.errors.message);
          } else {
            setVisible(false);
            if (Response.data) {
              // showNotifications(
              //   Response.message,
              //   `/project/${Response.data.id}`,
              //   " Go to project"
              // );
              if (props.auth.user.businessId == Response.data.business.id)
                addToast(
                  ToastMessage(
                    Response.message,
                    `/project/${Response?.data?.id}`,
                    <IntlMessages id={"globalactivity.gotoproject"} />,
                    data => history(data)
                  ),
                  {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 8000
                  }
                );
              else {
                addToast(ToastMessage(Response.message), {
                  appearance: "success",
                  autoDismiss: true,
                  autoDismissTimeout: 8000
                });
              }

              updateRedux(Response.data);
            }

            setmodal(!modal);
          }
        }
      } catch (e) {
        setIsLoader(false);
      }
    }
  };
  const handleIsFavourite = () => {
    setCheakVlaue(!cheakVlaue);
  };

  const handleProjectNameValidatiton = async e => {
    // const Response = await getBusinessProject();
    // const Response = await getBusinessProject();
    const validate = e.target.value.match(/^\S+\w{0,50}\S{0,}/g);
    if (
      e.target.value.length > 0 &&
      e.target.value.length < 51 &&
      validate !== null &&
      validate
    ) {
      setisProjectNameError({ status: false, message: null });
      setIsDisabled(false);
      return true;
    }
    if (e.target.value.length > 50) {
      setisProjectNameError({
        status: true,
        message: "Maximum 50 characters are allowed"
      });
      setIsDisabled(true);
    }
    if (e.target.value.length <= 0)
      setisProjectNameError({
        status: true,
        message: <IntlMessages id={"workspace.nameisrequired"} />
      });
    setIsDisabled(true);
    setVisible(false);
  };

  const handleResetDate = () => {
    setStartDate(moment(new Date()).toDate());
    setEndDate();
  };

  let propsaData = {
    ...props,
    selectedWorkspace,
    borderError
  };
  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        setmodal(!modal);
        setVisible(false);
        handleResetDate();
      }}
      centered
      autoFocus={false}
    >
      <form onSubmit={handleProjectSubmit}>
        {/* <ModalHeader toggle={() => setmodal(!modal)}> */}
        <ModalHeader>
          <div>
            {/* <span style={{ paddingRight: 10 }}>
              <i
                className="fa fa-circle fs-10 fs-14"
                style={{ color: getColorCode(selectedColor) }}
              ></i>
            </span> */}
            {/* Add Project */}
            <span
              style={{
                fontSize: 20,
                color: "black",
                fontWeight: "500",
                paddingLeft: "9px"
              }}
            >
              <IntlMessages id={"workspace.add_project"} />
            </span>
          </div>
        </ModalHeader>
        <Alert
          className="c-"
          style={{ backgroundColor: "RED ", color: "white" }}
          isOpen={visible}
          toggle={() => setVisible(false)}
        >
          {visible ? errorMessage : ""}
        </Alert>
        <ModalBody style={{ padding: "15px 25px" }}>
          <div className="form-group">
            {/* Name */}
            <label
              style={{
                fontSize: 14,
                color: "black",
                fontWeight: "500"
              }}
            >
              <IntlMessages id={"project.name"} />
            </label>

            <AddProjectWrapper>
              <input
                autoFocus
                type="text"
                className="form-control react-form-input"
                id="name"
                // onChange={e => {
                // e.target.value.length > 0
                //   ? setIsDisabled(false)
                //   : setIsDisabled(true);
                // }}
                name="projectName"
                placeholder={InjectPlaceHolderMessage(
                  "project.name",
                  props?.LanguageSwitcher?.language?.languageId
                )}
                onChange={handleProjectNameValidatiton}
              />

              <ValidationError field="name" />
            </AddProjectWrapper>
          </div>
          <div className="form-group mtb-15">
            {/* Description <span style={{ color: "#a0a4a9" }}>(optional)</span>{" "} */}
            <span
              style={{
                fontSize: 14,
                color: "black",
                fontWeight: "500"
              }}
            >
              <IntlMessages id={"today.description"} />{" "}
              <span style={{ color: "#a0a4a9" }}>
                {" "}
                <IntlMessages id={"project.description_optional"} />{" "}
              </span>{" "}
            </span>
            {/* <textarea
              id="projectDescription"
              className="form-control react-form-input"
              rows="2"
              placeholder="Our team organizes everything here."
            /> */}
            <AddProjectWorkspaceWrapper>
              <DescriptionEditor
                inputValue={projectDescription}
                onChange={data => setProjectDescription(data)}
                id="projectDescription"
                className="form-control react-form-input"
                rows="5"
                placeholder={InjectPlaceHolderMessage(
                  "globalworkspacelist.addworkspaceplaceholder",
                  props?.LanguageSwitcher?.language?.languageId
                )}
              />
            </AddProjectWorkspaceWrapper>
            {/* {isBusinessNameError.status && (
                    <span
                      style={{ color: "red", paddingLeft: 3 }}
                      className="fs-14"
                    >
                      {isBusinessNameError.message}
                    </span>
                  )} */}
          </div>

          <div className="form-group " style={{ margin: 0 }}>
            <Row>
              <Col className="col-lg-6 col-md-6 col-xl-6 col-sm-6 col-12 pb-15">
                <DatepickerWrapper {...props}>
                  <div className="roe-card-style">
                    {/* <div className="roe-card-header">Start date</div> */}
                    <span
                      style={{
                        fontSize: 14,
                        color: "black",
                        fontWeight: "500"
                      }}
                    >
                      <label className="roe-card-header">
                        {" "}
                        <IntlMessages id={"project.startdate"} />
                      </label>
                    </span>

                    <div className="roe-card-body">
                      <DatePicker
                        placeholderText={InjectPlaceHolderMessage(
                          "project.startdate",
                          props?.LanguageSwitcher?.language?.languageId
                        )}
                        selectsStart
                        minDate={new Date()}
                        id="startDate"
                        name="startDate"
                        value={startDate}
                        disabledKeyboardNavigation
                        selected={startDate}
                        // startDate={startDate}
                        // endDate={endDate}
                        onChange={handleChangeStartDate}
                        // dateFormat="MMM dd, yyyy "
                        dateFormat="dd MMM yyyy "
                        className="custom-datepicker"
                        calendarClassName="custom-calender-class"
                        isClearable
                        locale={locale}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </DatepickerWrapper>
              </Col>
              <Col className="col-lg-6 col-md-6 col-xl-6 col-sm-6 col-12 ">
                <DatepickerWrapper {...props}>
                  <div className="roe-card-style">
                    {/* <div className="roe-card-header">End date</div> */}
                    <span
                      style={{
                        fontSize: 14,
                        color: "black",
                        fontWeight: "500"
                      }}
                    >
                      <label className="roe-card-header">
                        <IntlMessages id={"project.enddate"} />
                      </label>
                    </span>
                    <div className="roe-card-body">
                      <DatePicker
                        placeholderText={InjectPlaceHolderMessage(
                          "project.enddate",
                          props?.LanguageSwitcher?.language?.languageId
                        )}
                        id="endDate"
                        name="endDate"
                        selectsEnd
                        minDate={startDate}
                        selected={endDate}
                        disabledKeyboardNavigation
                        // startDate={startDate}
                        // endDate={endDate}
                        value={endDate}
                        onChange={handleChangeEndDate}
                        // dateFormat="MMM dd, yyyy "
                        dateFormat="dd MMM yyyy "
                        className="custom-datepicker"
                        calendarClassName="custom-calender-class"
                        isClearable
                        locale={locale}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </DatepickerWrapper>
              </Col>
            </Row>
          </div>
          <div className="form-group fs-14 mb-15">
            {/* Choose workspace */}
            <label
              style={{
                fontSize: 14,
                color: "black",
                fontWeight: "500"
              }}
            >
              <IntlMessages id={"project.chooseworkspace"} />
            </label>
            <AddProjectWorkspaceWrapper {...propsaData}>
              {/* <Select
                styles={colourStyles}
                value={selectedWorkspace}
                placeholder="Select options"
                onChange={data => {
                  setSelectedWorkspace(data);
                }}
                options={workspaceData}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#E6E6E6"
                  }
                })}
                id="workspace"
                name="workspace"
                blurInputOnSelect
                noOptionsMessage={() => "No workspace found"}
              /> */}
              <ValidationError field="workspace" />

              <SelectWokspaceDropdown
                className="dropdown-workspace"
                isLoading={isWorkspaceRetrieved}
                options={workspaceData}
                selectedWorkspace={selectedWorkspace}
                changeSelectedWorkspace={data => {
                  setSelectedWorkspace(data);
                }}
                borderError={borderError}
              />
            </AddProjectWorkspaceWrapper>
          </div>

          <div className="form-group mtb-15 " style={{ zIndex: 0 }}>
            {/* Color */}
            <label
              style={{
                fontSize: 14,
                color: "black",
                fontWeight: "500"
              }}
            >
              <IntlMessages id={"project.color"} />
            </label>
            <ColorPopover
              options={options}
              selectedColor={selectedColor ? selectedColor : ""}
              changeColor={value => {
                setSelectedColor(value);
                setIsUserSelectedColor(true);
              }}
            />
          </div>
          <div className="form-group mtb-15">
            <label
              style={{
                fontSize: 14,
                color: "black",
                fontWeight: "500"
              }}
            >
              <IntlMessages id={"project.view"} />
            </label>
            <Row>
              <Col className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-xs-1">
                {" "}
              </Col>
              <Col
                className="col-lg-5 col-md-5 col-xl-5 col-sm-5 col-xs-5  "
                style={{
                  zIndex: "0",
                  display: "flex",
                  justifyContent: "end",
                  paddingRight: "25px"
                }}
              >
                <div
                  className="pretty p-icon p-round p-smooth  "
                  style={{
                    marginRight: "0px",
                    justifyContent: "end",
                    textAlign: "end"
                  }}
                >
                  <img
                    alt=""
                    type="radio"
                    src={list}
                    style={{
                      color: "#42526e",
                      height: "75%",
                      width: "100%",
                      // marginLeft: "40px",
                      // padding: "8px",
                      border:
                        colorChange == "list"
                          ? `1px solid   ${topbarTheme.buttonColor}`
                          : "",
                      borderRadius: "5px"
                    }}
                  ></img>
                  <input
                    style={{ width: "100%", height: "91%" }}
                    type="radio"
                    name="View"
                    id="View"
                    value="list"
                    onChange={e => {
                      setColorChange(e.target.value);
                    }}
                  />
                  {/* <div className="state p-success"> */}
                  <div
                    style={{
                      position: "relative",
                      top: "10px",
                      zIndex: "0 ",
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                      fontSize: "13px"
                      // left: "-2px"
                    }}
                  >
                    {colorChange == "list" ? (
                      <i
                        className="fas fa-check-circle"
                        style={{
                          color: topbarTheme.buttonColor
                        }}
                      ></i>
                    ) : (
                      <i
                        className="far fa-circle fa-1x"
                        style={{ color: "#bdc3c7" }}
                      ></i>
                    )}
                    {/* <i
                        className="icon fas fa-check"
                        style={{
                          backgroundColor: "#ff6b6b",
                          border: "1px solid #ff6b6b"
                        }}
                        onClick={() => {
                          setColorChange(true);
                           setColor(false);
                        }}
                      ></i> */}
                    {/* <label>List</label> */}
                    <span style={buttonStyle}>
                      <label
                        style={{
                          position: "relative",
                          top: "0px",
                          left: "5px"
                        }}
                      >
                        <IntlMessages id={"project.list"} />
                      </label>
                    </span>
                  </div>
                  {/* </div> */}
                </div>
              </Col>

              <Col
                className="col-lg-5 col-md-5 col-xl-5 col-sm-5 col-xs-5  "
                style={{
                  zIndex: "0",
                  display: "flex",
                  justifyContent: "start",
                  paddingLeft: "25px"
                }}
              >
                <div
                  className="pretty p-icon p-round p-smooth "
                  style={{
                    marginRight: "0px",
                    justifyContent: "start",
                    textAlign: "start"
                  }}
                >
                  <img
                    alt=""
                    type="radio"
                    src={gridicon}
                    style={{
                      color: "#42526e",
                      height: "75%",
                      width: "100%",
                      // paddingLeft: "8px",
                      // paddingTop: "8px",
                      border:
                        colorChange == "grid"
                          ? `1px solid   ${topbarTheme.buttonColor}`
                          : "",
                      // border:"1px solid  #dd4b39",
                      borderRadius: "5px"
                      // marginLeft: "8px"
                    }}
                  ></img>
                  <input
                    style={{ width: "100%", height: "91%" }}
                    type="radio"
                    name="View"
                    id="View"
                    value="grid"
                    defaultChecked
                    onChange={e => {
                      setColorChange(e.target.value);
                    }}
                  />

                  {/* <div className="state p-success" > */}
                  <div
                    style={{
                      position: "relative",
                      top: "10px",
                      // left: "8px",
                      zIndex: "0 ",
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "90%",
                      fontSize: "13px",
                      left: "0px"
                    }}
                  >
                    {colorChange == "grid" ? (
                      <i
                        className="fas fa-check-circle"
                        style={{
                          color: topbarTheme.buttonColor
                        }}
                      ></i>
                    ) : (
                      <i
                        className="far fa-circle fa-1x"
                        style={{ color: "#bdc3c7" }}
                      ></i>
                    )}
                    {/* <i
                        className="icon fas fa-check"
                        style={{
                          backgroundColor: "#ff6b6b",
                          border: "1px solid #ff6b6b"
                        }}
                      ></i> */}
                    {/* <label>Grid</label> */}
                    <span style={buttonStyle}>
                      <label
                        style={{
                          position: "relative",
                          top: "0px",
                          left: "5px"
                        }}
                      >
                        <IntlMessages id={"project.grid"} />
                      </label>
                    </span>
                  </div>
                </div>
                {/* </div> */}
              </Col>
              <Col className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-xs-1">
                {" "}
              </Col>
            </Row>
          </div>
          <div className="form-group mt-0 mb-0 ml-1">
            <span style={{ marginTop: "5px" }}>
              <Switch
                checked={cheakVlaue || false}
                onChange={handleIsFavourite}
                onColor={topbarTheme.buttonColor}
                // onHandleColor="#2693e6"
                handleDiameter={13}
                uncheckedIcon={false}
                checkedIcon={false}
                // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={19}
                width={32}
                className="react-switch"
                name="addtoFavorite"
                id="addtoFavorite"
              />
            </span>
            <span
              style={{
                position: "relative",
                top: "-4px",
                fontSize: "14px",
                color: "black",
                left: 8
              }}
            >
              <IntlMessages id={"project.addto_favourite"} />
            </span>

            {/* <div className="pretty p-switch p-fill">
              <input type="checkbox" name="addtoFavorite" id="addtoFavorite" />
              <div className="state">
                <label>Add to favorite</label>
                <span style={{ fontSize: 14 }}>
                  <label
                    style={{
                      position: "relative",
                      top: "-1px",
                      left: "0px"
                    }}
                  >
                    Add to favorite
                  </label>
                </span>
              </div>
            </div> */}
          </div>

          {/* <div className="form-group">
            <div className="">
              <label>View</label>
              <br />
              <div className="pretty p-default p-curve">
                <input
                  type="radio"
                  name="viewoption"
                  defaultChecked
                  id="viewoption"
                  value="list"
                />
                <div className="state p-primary-o">
                  <label className="fs-14 demi-bold-text">List</label>
                </div>
              </div>
              <div className="pretty p-default p-curve">
                <input
                  type="radio"
                  name="viewoption"
                  id="viewoption"
                  value="grid"
                />
                <div className="state p-primary-o">
                  <label className="fs-14 demi-bold-text">Grid</label>
                </div>
              </div>
            </div>
          </div> */}
          <div style={{ paddingTop: "15px", paddingBottom: "10px" }}>
            {isLoader ? (
              <Loader height="24px" width="20px" />
            ) : (
              <>
                <Button
                  className="button btn"
                  onClick={() => {
                    setmodal(!modal);
                    handleResetDate();
                  }}
                  style={{
                    background: "transparent",
                    color: "#000",
                    padding: "0px",
                    height: "42px",
                    width: "49%",
                    border: "1px solid #E6E6E6",
                    marginRight: "1%",
                    borderRadius: "5px"
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                      color: "black",
                      fontWeight: "500"
                    }}
                  >
                    <IntlMessages id={"project.cancel"} />
                  </span>
                </Button>

                <Button
                  type="submit"
                  className="btn"
                  disabled={isDisabled}
                  style={{
                    cursor: isDisabled ? "not-allowed" : "",
                    backgroundColor: topbarTheme.buttonColor,
                    border: "none",
                    height: "42px",
                    width: "49%",
                    marginLeft: "1%",
                    borderRadius: "5px"
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                      color: "white",
                      fontWeight: "500"
                    }}
                  >
                    <IntlMessages id={"project.add"} />
                  </span>
                </Button>
              </>
            )}
          </div>
        </ModalBody>
        {/* <ModalFooter>
          <Button
            className="button btn"
            onClick={() => setmodal(!modal)}
            style={{
              background: "transparent",
              border: 0,
              color: "#000",
              padding: "0px",
              padding: "12px"
            }}
          > */}
        {/* Cancel */}
        {/* <span style={{ fontSize: 14 }}>Cancel</span>
          </Button>
          {isLoader ? (
            <Loader height="24px" width="20px" />
          ) : (
            <Button
              type="submit"
              className="btn "
              disabled={isDisabled}
              style={{
                cursor: isDisabled ? "not-allowed" : "",
                border: "none",
                backgroundColor: isDisabled ? "gray" : topbarTheme.buttonColor,
                border: "none",
                width: "80px"
              }}
            > */}
        {/* {isLoader ? <Loader height="24px" width="20px" /> : "Add"} */}
        {/* <span style={{ fontSize: 14 }}>Add</span>
            </Button>
          )}
        </ModalFooter> */}
      </form>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    businessId: state.auth.user?.businessId,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher.language }
  };
};
export default compose(
  enhancer,
  connect(mapStateToProps, {
    open_workpsace_alert,
    open_project_alert,
    close_alert
  })
)(AddProject);
