export const handleRecentWorkspace = business => {
  let recent_workspace = getDataFromLocalStorage("recent_workspace");
  if (recent_workspace?.length == 0) {
    recent_workspace = [];
  }
  recent_workspace = handlePopData(recent_workspace);

  let isExist = false;
  recent_workspace &&
    recent_workspace.map((data, i) => {
      if (data.businessId == business.id) {
        isExist = true;
        recent_workspace[i] = {
          ...recent_workspace[i],
          businessId: business.id,
          businessName: business.title,
          businessMeta: business?.businessMeta
            ? business?.businessMeta?.thumbnail
            : null
        };
      }
    });
  if (!isExist) {
    recent_workspace.push({
      businessId: business.id,
      businessName: business.title,
      businessMeta: business?.businessMeta
        ? business?.businessMeta.thumbnail
        : null
    });
  }
  if (recent_workspace?.length > 2) {
    recent_workspace.splice(0, 0);
  }
  localStorage.setItem("recent_workspace", JSON.stringify(recent_workspace));
};
//   check if it has only two array
const handlePopData = recent_workspace => {
  let length = recent_workspace.length - 1;
  if (recent_workspace && recent_workspace?.length > 3) {
    let data = [recent_workspace[length - 1], recent_workspace[length]];
    return data;
  }
  if (recent_workspace && recent_workspace?.length > 2) {
    let data = [recent_workspace[length - 1], recent_workspace[length]];
    return data;
  }

  if (recent_workspace && recent_workspace?.length <= 2) {
    return recent_workspace;
  }
};
const getDataFromLocalStorage = key => {
  let retrieveData = JSON.parse(localStorage.getItem(key)) || [];
  return retrieveData;
};
