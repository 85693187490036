import updateProjectActions from "./actions";

const initState = {
  status: localStorage.getItem("ErrorAlert")
    ? localStorage.getItem("ErrorAlert")
    : []
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case updateProjectActions.DO_UPDATE_PROJECT:
      return {
        status: true
      };

    case updateProjectActions.CLOSE_UPDATE_PROJECT:
      return {
        status: false
      };

    default:
      return state;
  }
}
