/** @format */

import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";

const StyleSheet = {
  container: {
    border: "0px solid rgba(255, 255, 255, 1)",
    padding: "16px",
    width: "100%"
  },
  circle: {
    height: "48px",
    width: "48px",
    borderRadius: "50%",
    marginLeft: "16px",
    marginTop: "2px"
  },
  line: {
    width: "150px",
    height: "14px",
    alignSelf: "center",
    marginLeft: "16px",
    marginBottom: "16px"
  },
  line2: {
    width: "80%",
    height: "20px",
    alignSelf: "center",
    marginLeft: "80px",
    marginTop: "2px"
  },
  textline: {
    width: "31%",
    height: "20px",
    alignSelf: "center",
    margin: "20px 0px 22px 35%"
  }
};
class App extends Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="row">
          <Shimmer>
            <div className={classes.textline} />
          </Shimmer>
          <div
            className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 col-12"
            style={{
              paddingRight: 0,
              borderRight: "1px solid #EBEBEB",
              textAlign: "left"
            }}
          >
            <div className={classes.container}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>

            <div className={classes.container} style={{ visibility: "hidden" }}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>
            <div className={classes.container}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>

            <div className={classes.container} style={{ visibility: "hidden" }}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>

            <div className={classes.container}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>

            <div className={classes.container} style={{ visibility: "hidden" }}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>

            <div className={classes.container}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>

            <div className={classes.container} style={{ visibility: "hidden" }}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 col-12"
            style={{ paddingLeft: 0, textAlign: "left" }}
          >
            <div className={classes.container} style={{ visibility: "hidden" }}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>
            <div className={classes.container}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>

            <div className={classes.container} style={{ visibility: "hidden" }}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>

            <div className={classes.container}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>

            <div className={classes.container} style={{ visibility: "hidden" }}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>

            <div className={classes.container}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>

            <div className={classes.container} style={{ visibility: "hidden" }}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>

            <div className={classes.container}>
              <Shimmer>
                <div className={classes.circle} />
                <div className={classes.line} />
                <br />
                <div className={classes.line2} key={Math.random()} />
              </Shimmer>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default injectSheet(StyleSheet)(App);
