import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Please enter name")
      .toString(),
    workspace: Yup.string().required("Please choose workspace")
  }),
  mapPropsToValues: props => ({
    name: "",
    workspace: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
