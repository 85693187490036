import React from "react";
import IntlMessages from "util/intlMessages";

import { checked } from "helper/constant";
const LanguageSwitcher = props => {
  const { changeLanguage, config, selectedId, language, setLanguage } = props;

  return (
    <div className="themeChangeBlock">
      <label
        className=" fs-14 pa-15"
        style={{
          color: "black",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          margin: "0px 10px 0px 10px",
          borderBottom: "1px solid #EBEBEB"
        }}
      >
        <IntlMessages id="languageSwitcher.label" />
      </label>
      <div
        className="themeChangeBtnWrapper d-flex flex-row"
        style={{ padding: "20px 40px" }}
      >
        {config.options.map(option => {
          const { languageId, icon } = option;
          return (
            <div className="mr-3 p-2">
              <div
                style={{
                  padding: "0px 0px 10px 0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
              >
                <div
                  onClick={() => {
                    changeLanguage(languageId);
                    setLanguage(languageId);
                  }}
                  style={{
                    backgroundColor: "white",
                    height: 66,
                    width: 66,
                    border: "1px solid #EBEBEB",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: " 0px 0px 8px #00000014"
                  }}
                >
                  <div
                    style={{
                      height: 54,
                      width: 54,
                      border: "1px solid #EBEBEB",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        languageId == language ? "rgb(0, 0, 0)" : "",
                      WebkitBackdropFilter:
                        languageId == language ? "blur(5px)" : "",
                      backdropFilter: languageId == language ? "blur(5px)" : ""
                    }}
                  >
                    <img
                      className="rounded-circle"
                      height={54}
                      width={54}
                      src={process.env.PUBLIC_URL + icon}
                      alt="flag"
                      style={{ opacity: 0.8 }}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "absolute"
                      }}
                    >
                      {languageId == language && (
                        <img
                          src={checked}
                          style={{
                            height: 17,
                            width: 17
                          }}
                          alt="check"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "600",
                  fontSize: 13,
                  color: "black",
                  textAlign: "center",
                  textTransform: "capitalize"
                  // maxWidth:"90px"
                }}
              >
                {languageId}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
