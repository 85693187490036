import { getUserCharColor } from "helper/methods/PlaceholderColor";

export const getUserAvatarColor = (str = "U", s = 30, l = 60) => {
  var hash = 0;

  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;

  return "hsl(" + h + ", " + s + "%, " + l + "%)";
};
export const profileBackground = (
  str,
  height = 35,
  width = 35,
  fs = 14,
  border = "0px solid white"
) => {
  const style = {
    background: str ? getUserCharColor(str[0]) : "#DFE1E6",
    borderRadius: " 50%",
    width: height || "100px",
    height: width || "100px",
    fontWeight: "600",
    textAlign: " center",
    color: "white",
    fontSize: fs || "14px",
    display: " flex",
    justifyContent: "center",
    alignItems: " center",
    border: border || "1px solid white",
    objectFit: "cover",
    marginRight: "5px"
  };
  return style;
};

export const cardImage = (
  str,
  height = 300,
  width = 250,
  fs = 14,
  border = "0px solid white"
) => {
  const style = {
    background: str ? getUserCharColor(str[0]) : "#DFE1E6",

    width: height || "100px",
    height: width || "100px",
    fontWeight: "600",
    textAlign: " center",
    color: "white",
    fontSize: fs || "14px",
    display: " flex",
    justifyContent: "center",
    alignItems: " center",
    border: border || "1px solid white",
    objectFit: "cover"
  };
  return style;
};

export const notificationStyle = (str, height = 35, width = 35) => {
  const style = {
    borderRadius: "36%",
    border: "1 solid",
    width: height,
    background: getUserAvatarColor(str),
    height: width,
    objectFit: "fill",
    textAlign: "center",
    color: "#000000",
    fontSize: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };
  return style;
};
export const profileImage = {
  borderRadius: "50%",
  border: "1 solid",
  width: 35,
  height: 35,
  objectFit: "cover",
  textAlign: "center",
  background: "#e2e2e2",
  fontSize: 15,
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};
