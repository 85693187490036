import styled from "styled-components";

const TrixEditorWrapper = styled.div` 
  .trix-content {
    border: 0px solid !important;
    min-height: ${props =>
      props.isTrixDragEnter == true
        ? " 580px !important;"
        : "220px !important;"}
    padding-top: 15px !important;
    padding-left: 12px !important;
  }
  trix-toolbar .trix-button-group {
    margin: 0px !important;
  }
  trix-toolbar .trix-button-group {
    border: 0px solid !important;
  }
  trix-toolbar .trix-button {
    border: 0px solid !important;
  }
  trix-toolbar .trix-button-row {
    align-items: center !important;
    height: 36px !important;
    border-bottom: 1px solid #ebebeb !important;
    // border-bottom: ${props =>
      props.isTrixDragEnter == true
        ? "1px solid red !important"
        : "1px solid #ebebeb !important;"}
  }

  .trix-content *{
    // align-items: center !important
  }
  .trix-content img{
    max-width: 85% !important;
  }
  
  .attachment__progress{
    padding: 9px;
    width: 90%;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    width: 12rem !important;
    height: 2rem !important;
    z-index: 2 !important;
  }
  .trix-content .attachment--preview {
    text-align: initial !important;
  }
  .trix-content .attachment-gallery {
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap;
    position: relative; }
    .trix-content .attachment-gallery .attachment {
      // flex: 1 0 33%;
      // padding: 0 0.5em;
      max-width: 100%; 
    }
    .trix-content .attachment-gallery.attachment-gallery--2 .attachment, .trix-content .attachment-gallery.attachment-gallery--4 .attachment {
      flex-basis: 0 !important;
      max-width: 100%!important; 
    }
   


`;

export default TrixEditorWrapper;
