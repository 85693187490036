import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AddSectionCard from "components/projects/sections/AddSectionCard";
import Shimmer from "components/shimmer/Shimmer";
import RoyTooltip from "components/common/RoyTooltip";
import BodyWrapper from "./pages/wrapper/BodyWrapper.style";
import TodoList from "components/TodoListview/TodoListInbox";
import { addTodo, updateTodo } from "helper/services/todoService";
import { useToasts } from "react-toast-notifications";
import CustomNotification from "util/CustomNotification";

import { Input } from "reactstrap";
import {
  getProject,
  updateProject,
  getProjectById,
  deleteProjectSection
} from "helper/services/projectServices";
import { deleteSection, disconnect } from "../services/socket";
// import TodoBoard from "components/todos/todoboard/TodoBoard";
import ShareModal from "components/collaborators/ShareModal";
import EditProject from "components/projects/EditProject";
import Notifications, { notify } from "react-notify-toast";
import MoreOptionsMenu from "components/Inbox/Menu/MoreOptionsMenuInbox";
import SectionHeaderMenu from "components/projects/Menus/SectionHeaderMenu";
import { getColorCode } from "components/projects/utils/color";
import projectActions from "redux/projects/actions";
import CommentModal from "components/projects/comments/CommentModal";
import CustomAlert from "util/CustomAlert";
import ToastMessage from "components/notifications/ToastMessage";

import { inboxInit, joinRoom, leaveRoom, disconnec } from "services/socket";
import { projectSocketRoomPrefix } from "constatnt";
const { update_projects_existing } = projectActions;

const Inbox = props => {
  const { addToast } = useToasts();
  const [addSectionFlag, setSectionCardFlag] = useState(false);
  const [defaultView, SetDefaultView] = useState("List");
  const [project, setProject] = useState(null);
  const [section, AddSection] = useState(null);
  const [commentModal, setCommentModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [editProjectModal, setEditProjectModal] = useState(false);
  const [isLoader, SetIsLoader] = useState(false);
  const [moreProjectOptions, setMoreProjectOptions] = useState(false);
  const [sectionmenu, setSectionmenu] = useState(false);
  const [sectionmenuID, setSectionmenuID] = useState(null);
  const [editSectionToggle, SetEditSectionToggle] = useState(false);
  const [sectionEdiId, setSectionEditId] = useState(null);
  const [editSectionFlag, SetEditSectionFlag] = useState(false);
  const [editSectionData, SetEditSectionData] = useState(false);
  const [Inbox, setInbox] = useState(null);
  const [toastMessage, setToastMessage] = useState(null);
  const [toast, setToast] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [SearchLabelTodoQueryResult, setSearchLabelTodoQueryResult] = useState(
    []
  );
  const [isDeleteAlert, setIsDeleteAlert] = useState(false);
  const inboxRef = React.useRef(project);
  React.useEffect(() => {
    inboxRef.current = project;
  });
  useEffect(() => {
    const check = props.projects?.projects.filter(
      project => project.name == "Inbox"
    );

    setInbox(check[0]);
    if (Inbox) {
      fetchProjects(true);
      if (Inbox)
        inboxInit(
          addSectionCB,
          updateSectionCB,
          deleteSectionCB,
          addTaskCB,
          updateTaskCB,
          deleteTaskCB,
          props.auth.accessToken
        );
      handleSocketRoom();
    }
    return () => {
      setProject(null);
      setSectionmenuID(null);
      setInbox(null);

      if (Inbox) leaveRoom(`${projectSocketRoomPrefix}${Inbox.id}`);
      disconnect();
    };
  }, [Inbox]);
  const addSectionCB = data => {
    let projectData = inboxRef.current;
    if (projectData) {
      projectData.section.push(data);
      setProject({ ...projectData });
    }
  };
  const updateSectionCB = async data => {
    let projectData = inboxRef.current;
    if (projectData) {
      await Promise.all(
        projectData.section.map((s, i) => {
          if (s.id === data.id) {
            projectData.section[i] = data;
          }
        })
      );
      setProject({ ...projectData });
    }
  };

  const deleteSectionCB = async data => {
    let projectData = inboxRef.current;
    if (projectData) {
      await Promise.all(
        projectData.section.map((s, i) => {
          if (s.id === data.id) {
            projectData.section.splice(i, 1);
            setProject({ ...projectData });
          }
        })
      );
    }
  };

  const addTaskCB = async data => {
    let projectData = inboxRef.current;
    if (projectData) {
      await Promise.all(
        projectData.section.map((s, i) => {
          if (data.section) {
            if (s.id == data.section.id) {
              s.task.push(data);
            }
          } else {
            if (!s.id) {
              s.task.push(data);
            }
          }
        })
      );
      setProject({ ...projectData });
    }
  };

  const deleteTaskCB = async data => {
    let projectData = inboxRef.current;
    if (projectData) {
      await Promise.all(
        projectData.section.map((s, i) => {
          return s.task.map((t, j) => {
            if (t.id == data.id) {
              projectData.section[i].task.splice(j, 1);
            }
          });
        })
      );
      setProject({ ...projectData });
    }
  };
  const handleSocketRoom = () => {
    // Join room
    joinRoom(`${projectSocketRoomPrefix}${Inbox.id}`);
  };

  const refreshTodoList = newTodo => {
    let todoArr = inboxRef.current;

    todoArr.section.map((item, SectionI) => {
      item.task.map((task, taskI) => {
        if (task.id == newTodo.id) {
          todoArr.section[SectionI].task[taskI] = newTodo;
        }
      });
    });
    setProject({ ...todoArr });
  };

  const updateTaskCB = async data => {
    let projectData = inboxRef.current;
    refreshTodoList(data);
    // if (projectData) {
    //   await Promise.all(
    //     projectData.map((t, i) => {
    //         if (t.id == data.id) {
    //
    //           projectData[i] = {...projectData[i],...data};
    //         }
    //     })
    //   );
    //   setLabelTodo([ ...projectData ]);
    //
    // }
  };
  // const showNotifications = message => {
  //   setToast(true);
  //   setToastMessage(message ? message : "something went wrong");
  //   setTimeout(() => {
  //     setToast(false);
  //     setToastMessage("");
  //   }, 2500);
  // };
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const fetchProjects = async isLoader => {
    SetIsLoader(isLoader);
    const Response = await getProjectById(Inbox.id, props.auth.accessToken);
    if (Response) {
      if (Response.error) {
        {
          showNotifications();
        }
      }
      if (Response.data) {
        Response.data.section.sort((a, b) => {
          return parseInt(a.orderId) - parseInt(b.orderId);
        });
        setProject(Response.data);
        SetDefaultView(Response.data.defaultView == "grid" ? "Grid" : "List");
        SetIsLoader(false);
      }
    }
  };

  const { sidebarTheme } = props;

  const updateProjectToRedux = data => {
    props.update_projects_existing({ projects: data });
  };

  // const deleteSection = async () => {
  //   try {
  //     const Response = await deleteProjectSection(
  //       {
  //         title: editSectionData.title,
  //         orderId: editSectionData.orderId,
  //         isDeleted: true
  //       },
  //       editSectionData.id
  //     );
  //     if (Response.error) {
  //       showNotifications();
  //     }
  //     if (Response.data) {
  //       var section = project.section;
  //       section.map((data, i) => {
  //         if (data.id === Response.data.id) {
  //           section.splice(i, 1);
  //           setProject({ ...project, section: section });
  //         }
  //       });
  //       showNotifications("Section deleted");
  //     }
  //   } catch (e) {
  //     showNotifications();
  //   }
  // };

  const doDeleteSection = async () => {
    try {
      let payload = {
        title: editSectionData.title,
        orderId: editSectionData.orderId,
        isDeleted: true,
        section_id: editSectionData.id.toString(),
        roomname: Inbox && `${projectSocketRoomPrefix}${Inbox.id}`
      };

      deleteSection(payload, cb => {
        if (cb.status == "Ok") {
        } else {
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */

          showNotifications();
        }
      });
    } catch (e) {}
  };
  const handleSearch = query => {
    /* fetching the input and checking if exist in tasklistarray */
    let filteredTodos;
    let projectSection = project.section;
    if (!query) {
      setSearchLabelTodoQueryResult([]);
    } else {
      var result = project.section.filter(val =>
        val.task.some(k => k.title.toUpperCase().includes(query.toUpperCase()))
      );
      setSearchLabelTodoQueryResult([...result]);

      // project.section &&
      // projectSection.filter(section => {
      //   return {
      //     ...section,
      //     task:section.task.filter(task => {
      //       return (
      //         task.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
      //       );
      //     })
      //   };
      // });
      // result = data.filter(val=>val.inventory.some(k=>k.name.toUpperCase().includes(string.toUpperCase())));
      // setSearchLabelTodoQueryResult(projectSection);
    }
  };
  const updateViewOptions = async () => {
    try {
      if (project && project.id) {
        const view = project.defaultView == "list" ? "grid" : "list";
        const Response = await updateProject(
          {
            defaultView: view
          },
          project.id
        );
        if (Response) {
          if (Response.error) {
            showNotifications();
          }
          if (Response.data) {
            var projectData = project;

            projectData.defaultView = Response.data.defaultView;
            setProject(projectData);
            SetDefaultView(
              Response.data.defaultView == "grid" ? "Grid" : "List"
            );
          }
        }
      }
    } catch (e) {
      showNotifications();
    }
  };

  const updateIsCompleted = async (sectionId, todoId, checked) => {
    try {
      const Response = await updateTodo(
        {
          project_id: project && project.id.toString(),
          section_id: sectionId ? sectionId.toString() : "",
          isCompleted: checked
        },
        todoId
      );
      if (Response) {
        if (Response.error) {
          showNotifications();
        }
        if (Response.data) {
          let projectData = project;
          projectData.section.map((data, i) => {
            if (data.id == sectionId) {
              data.task.map((t, i) => {
                if (t.id == todoId) {
                  t.isCompleted = checked;
                }
              });
            }
          });
          setProject({ ...projectData });
        }
      }
    } catch (e) {
      showNotifications();
    }
  };

  return (
    <BodyWrapper {...props}>
      <div className="row">
        <div className="col-lg-2 col-md-2 col-sm-0 col-xs-0" />
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <Notifications />
          <div className="roe-card-style">
            <div className="roe-card-header">
              <div className="file-manager-toolbar flex-x">
                <div className="flex-1">
                  <span className="fs-14 demi-bold-text">
                    <i
                      className="text-muted fas fa-inbox pr-10 text-muted"
                      style={{
                        color:
                          project && project.color
                            ? getColorCode(project.color)
                            : "gray"
                      }}
                    ></i>{" "}
                    Inbox
                  </span>
                </div>
                <div className="" style={{ float: "right" }}>
                  {isSearch && (
                    <div className="searchStyle pos-relative">
                      <Input
                        autoFocus
                        defaultValue={searchInput || ""}
                        placeholder="Search tasks"
                        className="react-form-search-input"
                        onChange={e => handleSearch(e.target.value)}
                      />
                      <i
                        onClick={() => setIsSearch(false)}
                        className="text-muted fas fa-times close-search text-muted"
                      ></i>
                    </div>
                  )}
                  {!isSearch && (
                    <div className="flex center">
                      <div className="mr-1">
                        <RoyTooltip
                          id={`searchIcon`}
                          title={`Search tasks`}
                          placement="bottom"
                        >
                          <span
                            id="searchIcon"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSearchInput(null);
                              setSearchLabelTodoQueryResult(
                                project && project.section
                              );
                              setIsSearch(!isSearch);
                            }}
                            className="search-contant "
                          >
                            <i className="text-muted fas fa-search text-muted fs-14"></i>
                          </span>
                        </RoyTooltip>
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className="ml-15">
                  <span
                    onClick={() => {
                      updateViewOptions();
                    }}
                  >
                    <RoyTooltip
                      id={`viewMode`}
                      title={`View as ${defaultView}`}
                      placement="bottom"
                    ></RoyTooltip>
                    <div id="viewMode" className="cursor-pointer">
                      <i className="fas fa-th-large fs-14"></i>
                    </div>
                  </span>
                </div> */}
                <div className="ml-15">
                  <span onClick={() => setCommentModal(!commentModal)}>
                    <RoyTooltip
                      id={`Comments`}
                      title={"Comments"}
                      placement="bottom"
                    ></RoyTooltip>
                    <div id="Comments" className="cursor-pointer">
                      <i className="far fa-comment-alt fs-14 text-muted "></i>
                    </div>
                  </span>
                </div>

                <div
                  className="ml-15 "
                  id="popover"
                  onClick={() => setMoreProjectOptions(!moreProjectOptions)}
                >
                  <span>
                    <div id="moreoptions" className="cursor-pointer">
                      <i className="fas fa-ellipsis-h text-muted"></i>
                    </div>
                    <RoyTooltip
                      id={`moreoptions`}
                      title={"More options"}
                      placement="bottom"
                    ></RoyTooltip>{" "}
                    <MoreOptionsMenu
                      moreProjectOptions={moreProjectOptions}
                      setSectionCardFlag={() => {
                        setSectionCardFlag(!addSectionFlag);
                      }}
                      setMoreProjectOptions={() =>
                        setMoreProjectOptions(!moreProjectOptions)
                      }
                      toggleEditProject={() =>
                        setEditProjectModal(!editProjectModal)
                      }
                      viewMode={project && project.defaultView}
                      updateViewOptions={() => updateViewOptions()}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className=" mt-15">
            {isLoader ? (
              <div className=" roe-card-style mtb-5 todo-board">
                <Shimmer />
              </div>
            ) : (
              <>
                {" "}
                {project && project.section.length > 0 && !isSearch
                  ? project.section.map((data, i) => {
                      return (
                        <div
                          className=" roe-card-style mtb-5 todo-board"
                          key={i * 2333}
                        >
                          <div key={i * 2333}>
                            {editSectionToggle ? (
                              <div>Edit</div>
                            ) : (
                              data.orderId != 0 && (
                                <>
                                  <div
                                    className="pa-5 flex-x align-center"
                                    id={`section-edit-${i}`}
                                  >
                                    <div className="flex-1">
                                      <div className="fs-14 demi-bold-text">
                                        {data.title}
                                      </div>
                                    </div>
                                    <div className="pl-10">
                                      {data.orderId !== 0 && (
                                        <i
                                          id={`todomenu-${i}`}
                                          className="fas fa-ellipsis-h text-muted cursor-pointer"
                                          onClick={() => {
                                            if (sectionmenu) {
                                              setSectionmenu(!sectionmenu);
                                              setSectionmenuID(null);
                                            } else {
                                              setSectionmenu(!sectionmenu);
                                              setSectionmenuID(`todomenu-${i}`);
                                              SetEditSectionData(data);
                                            }
                                          }}
                                        >
                                          <SectionHeaderMenu
                                            isOpen={sectionmenu}
                                            toggle={() =>
                                              setSectionmenu(!sectionmenu)
                                            }
                                            setSectionmenuID={data =>
                                              sectionmenuID(data)
                                            }
                                            divid={`section-edit-${i}`}
                                            sectionmenuID={sectionmenuID}
                                            sectionEdiId={sectionEdiId}
                                            deleteSection={() =>
                                              setIsDeleteAlert(true)
                                            }
                                            SetEditSectionToggle={() => {
                                              SetEditSectionFlag(
                                                !editSectionFlag
                                              );
                                            }}
                                          />
                                        </i>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )
                            )}
                            <TodoList
                              PRIMARY_COLOR={props.topbarTheme.buttonColor}
                              key={i * 1233}
                              sectionId={data.id}
                              taskViewType="inbox"
                              projectId={project && project.id}
                              projectName={project && project.name}
                              sectionTitle={data.title}
                              todoList={data.task}
                              updateisCompleted={(
                                isCompleted,
                                projectId,
                                sectionId,
                                taskid
                              ) => {
                                let projectData = project;
                                projectData.section.map((data, i) => {
                                  if (data.id == sectionId) {
                                    data.task.map((t, i) => {
                                      if (t.id == taskid) {
                                        t.isCompleted = isCompleted;
                                      }
                                    });
                                  }
                                });
                                setProject(projectData);
                              }}
                              updateIsCompleted={(
                                sectionId,
                                todoId,
                                checked
                              ) => {
                                updateIsCompleted(sectionId, todoId, checked);
                              }}
                              updateSection={(
                                newTodo,
                                projectId,
                                sectionId
                              ) => {
                                let projectData = project;
                                projectData.section.map((data, i) => {
                                  if (data.id == sectionId) {
                                    data.task.push(newTodo);
                                  }
                                });
                                setProject(projectData);
                              }}
                              showNotifications={message =>
                                showNotifications(message)
                              }
                              collaboratorList={[]}
                              dummyData={false}
                            />
                          </div>
                        </div>
                      );
                    })
                  : ""}
                {project &&
                project.section.length > 0 &&
                isSearch &&
                SearchLabelTodoQueryResult
                  ? SearchLabelTodoQueryResult.map((data, i) => {
                      return (
                        <div
                          className=" roe-card-style mtb-5 todo-board"
                          key={i * 2333}
                        >
                          <div key={i * 2333}>
                            {editSectionToggle ? (
                              <div>Edit</div>
                            ) : (
                              data.orderId != 0 && (
                                <>
                                  <div
                                    className="pa-5 flex-x align-center"
                                    id={`section-edit-${i}`}
                                  >
                                    <div className="flex-1">
                                      <div className="fs-14 demi-bold-text">
                                        {data.title}
                                      </div>
                                    </div>
                                    <div className="pl-10">
                                      {data.orderId !== 0 && (
                                        <i
                                          id={`todomenu-${i}`}
                                          className="text-muted fas fa-ellipsis-h cursor-pointer"
                                          onClick={() => {
                                            if (sectionmenu) {
                                              setSectionmenu(!sectionmenu);
                                              setSectionmenuID(null);
                                            } else {
                                              setSectionmenu(!sectionmenu);
                                              setSectionmenuID(`todomenu-${i}`);
                                              SetEditSectionData(data);
                                            }
                                          }}
                                        >
                                          <SectionHeaderMenu
                                            isOpen={sectionmenu}
                                            toggle={() =>
                                              setSectionmenu(!sectionmenu)
                                            }
                                            setSectionmenuID={data =>
                                              sectionmenuID(data)
                                            }
                                            divid={`section-edit-${i}`}
                                            sectionmenuID={sectionmenuID}
                                            sectionEdiId={sectionEdiId}
                                            deleteSection={() =>
                                              doDeleteSection()
                                            }
                                            SetEditSectionToggle={() => {
                                              SetEditSectionFlag(
                                                !editSectionFlag
                                              );
                                            }}
                                          />
                                        </i>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )
                            )}
                            <TodoList
                              PRIMARY_COLOR={props.topbarTheme.buttonColor}
                              key={i * 1233}
                              sectionId={data.id}
                              projectId={project && project.id}
                              projectName={project && project.name}
                              todoList={data.task}
                              updateisCompleted={(
                                isCompleted,
                                projectId,
                                sectionId,
                                taskid
                              ) => {
                                let projectData = project;
                                projectData.section.map((data, i) => {
                                  if (data.id == sectionId) {
                                    data.task.map((t, i) => {
                                      if (t.id == taskid) {
                                        t.isCompleted = isCompleted;
                                      }
                                    });
                                  }
                                });
                                setProject(projectData);
                              }}
                              updateIsCompleted={(
                                sectionId,
                                todoId,
                                checked
                              ) => {
                                updateIsCompleted(sectionId, todoId, checked);
                              }}
                              updateSection={(
                                newTodo,
                                projectId,
                                sectionId
                              ) => {
                                let projectData = project;
                                projectData.section.map((data, i) => {
                                  if (data.id == sectionId) {
                                    data.task.push(newTodo);
                                  }
                                });
                                setProject(projectData);
                              }}
                              showNotifications={showNotifications}
                              collaboratorList={[]}
                              dummyData={false}
                            />
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </>
            )}
            <AddSectionCard
              PRIMARY_COLOR={props.topbarTheme.buttonColor}
              totalsection={project && project.section.length}
              projectId={project && project.id}
              project={project}
              // New Section
              addSectionFlag={addSectionFlag}
              setSectionCardFlag={() => setSectionCardFlag(!addSectionFlag)}
              addSection={data => {
                let projectData = {
                  ...project
                };
                projectData.section.push(data);
                setProject(projectData);
              }}
              // Edit Section
              projectData={project}
              UpdateProjectData={data => {
                setProject(data);
              }}
              editSectionData={editSectionData}
              editSectionFlag={editSectionFlag}
              ToggleEditSection={() => {
                SetEditSectionFlag(!editSectionFlag);
                setSectionCardFlag(false);
              }}
              showNotifications={showNotifications}
            />
          </div>
        </div>
        <CommentModal
          isOpen={commentModal}
          projectId={project && project.id}
          name={"Inbox"}
          toggle={() => setCommentModal(!commentModal)}
          toggleCommentModal={() => setCommentModal(!commentModal)}
          showNotifications={showNotifications}
        />

        <EditProject
          modal={editProjectModal}
          setEditProjectModal={() => setEditProjectModal(!editProjectModal)}
          project={project}
          setProject={data => setProject(data)}
          updateProjectToRedux={data => updateProjectToRedux(data)}
          showNotifications={showNotifications}
          languageName={
            editProjectModal && props.LanguageSwitcher.language.languageId
          }
        />
        <CustomNotification
          show={toast}
          message={toastMessage}
          closeNotification={() => setToast(false)}
        />
        <CustomAlert
          action="delete"
          messageType="section"
          show={isDeleteAlert}
          PRIMARY_COLOR={props.topbarTheme.buttonColor}
          onConfirm={() => {
            doDeleteSection();
            setIsDeleteAlert(false);
          }}
          onCancel={() => setIsDeleteAlert(false)}
        />
        <div className="col-lg-2 col-md-2 col-sm-0 col-xs-0" />
      </div>
    </BodyWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher.language }
  };
};

export default connect(mapStateToProps, { update_projects_existing })(Inbox);
