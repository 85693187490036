import React, { useEffect, useState } from "react";
import "./project.style.css";
import ToastMessage from "components/notifications/ToastMessage";
import ChangeWorkspaceAlert from "util/ChangeWorkspaceAlert";
import { withRouter } from "react-router";
import { handleSwitchWorkspace } from "util/FetchProjectAndLabel";
import { compose } from "redux";
import { connect } from "react-redux";
import participantEmailEnhance from "./pages/authentication/enhancer/participantEmailEnhance";
import projectActions from "redux/projects/actions";
import labelActions from "redux/labels/actions";
import authActions from "redux/auth/actions";
import updateProjectActions from "redux/projectAction/actions";
import alertActions from "redux/globalAlert/actions";
import MyAssignmentsHeader from "MyAssignments/MyAssignmentsHeader";
import MyAssignments from "MyAssignments/MyAssignments";
import StuffAssignments from "MyAssignments/StuffAssignments";
import { useToasts } from "react-toast-notifications";
import IntlMessages from "util/intlMessages";
const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const { do_update_project, close_update_project } = updateProjectActions;
const { update_token, update_business } = authActions;
const {
  set_projects,
  update_Project,
  update_projects_existing,
  remove_Project
} = projectActions;

const { set_labels } = labelActions;
const GlobalMyAssignments = props => {
  const [myAssignmentsData, setMyAssignmentsData] = useState([]);
  const [overdueTaskGroupDataTotime, setOverdueTaskGroupDataTotime] = useState(
    []
  );
  const tabs = ["My_Assignments", "Stuff_I’ve_assigned"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [isAlertActionLoader, setIsAlertActionLoader] = useState(false);
  const [isAlertAction, setIsAlertAction] = useState({
    status: false,
    data: ""
  });
  const { addToast } = useToasts();
  const toggletab = id => {
    setActiveTab(id);
  };

  useEffect(() => {
    return () => {
      setMyAssignmentsData([]);
      setActiveTab(tabs[0]);
      setIsAlertAction({
        status: false,
        data: ""
      });
      setIsAlertActionLoader(false);
    };
  }, []);
  const getMessageType = item => {
    if (item.redirection_type === "task") {
      return (
        <>
          <IntlMessages id={"notification.taskbelongsto"} />
          {item.business.title ? (
            <b>{item.business.title}</b>
          ) : (
            "different"
          )}{" "}
          <IntlMessages id={"notification.switchworkspacetaskdetails"} />
          <IntlMessages id={"notification.task"} />
          <IntlMessages id={"notification.details_now"} />
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "project") {
      return (
        <>
          <IntlMessages id={"notification.projectbelongs"} />
          {item.business.title ? (
            <b>{item.business.title}</b>
          ) : (
            <IntlMessages id={"notification.different"} />
          )}{" "}
          <IntlMessages id={"notification.switchworkspaceprojectdetails"} />
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "business") {
      return (
        <>
          <IntlMessages id={"notification.switchtheworkspacetoseethe"} />
          {item.business.title ? (
            <b>{item.business.title}</b>
          ) : (
            <IntlMessages id={"notification.different"} />
          )}{" "}
          <IntlMessages id={"workspace.workspace"} />
          <IntlMessages id={"notification.details_now"} />
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "taskcomment") {
      return (
        <>
          <IntlMessages id={"notification.taskcommentw"} />
          {item.business.title ? (
            <b>{item.business.title}</b>
          ) : (
            <IntlMessages id={"notification.different"} />
          )}{" "}
          <IntlMessages id={"notification.switchworkspacetaskcommentdetails"} />
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "projectcomment") {
      return (
        <>
          <IntlMessages id={"notification.projectcomment"} />
          {item.business.title ? (
            <b>{item.business.title}</b>
          ) : (
            <IntlMessages id={"notification.different"} />
          )}{" "}
          <IntlMessages
            id={"notification.switchworkspaceprojectcommentdetails"}
          />
        </>
      );
    }
  };
  const doSwitchBusiness = async () => {
    if (isAlertAction.status) {
      setIsAlertActionLoader(true);
      const response = await handleSwitchWorkspace(
        isAlertAction.data.business.id,
        id => handleRedirection(isAlertAction.data, id),
        showNotifications
      );

      if (response.status) {
        setIsAlertActionLoader(false);
      } else {
        console.error(response.error);
      }
    }
  };
  const handleRedirection = (item, newBusinessId) => {
    if (item.redirection_type && item.redirection_type === "task") {
      if (item.project.id && item.task.id && item.business.id) {
        if (
          item.business.id == props.auth.user.businessId ||
          item.business.id == newBusinessId
        ) {
          return props.history.push(`/task-details/${item.task.id}`);
        } else {
          setIsAlertAction({ status: true, data: item });
        }
      }
      return false;
    }
    if (item.redirection_type && item.redirection_type === "taskcomment") {
      if (item.project.id && item.task.id && item.business.id) {
        if (
          item.business.id == props.auth.user.businessId ||
          item.business.id == newBusinessId
        ) {
          return props.history.push(`/task-details/${item.task_id}`);
        } else {
          setIsAlertAction({ status: true, data: item });
        }
      }
      return false;
    }
    if (item.redirection_type && item.redirection_type === "business") {
      if (item.business.id) {
        if (
          item.business.id == props.auth.user.businessId ||
          item.business.id == newBusinessId
        ) {
          return props.history.push(`/business/${item.business.id}`);
        } else {
          setIsAlertAction({ status: true, data: item });
        }
      }
      return false;
    }
    if (item.redirection_type && item.redirection_type === "project") {
      if (item.project.id) {
        if (
          item.business.id == props.auth.user.businessId ||
          item.business.id == newBusinessId
        ) {
          return props.history.push(`/project/${item.project.id}`);
        } else {
          setIsAlertAction({ status: true, data: item });
        }
      }
      return false;
    }
  };

  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  // need to make own member tab for projects
  const handleActiveTabs = tabId => {
    if (tabId == "My_Assignments") {
      return (
        <MyAssignments
          handleRedirection={handleRedirection}
          showNotifications={showNotifications}
          PRIMARY_COLOR={props.PRIMARY_COLOR}
          myAssignmentsData={myAssignmentsData}
          setMyAssignmentsData={setMyAssignmentsData}
        />
      );
    }
    if (tabId == "Stuff_I’ve_assigned") {
      return (
        <StuffAssignments
          overdueTaskGroupDataTotime={overdueTaskGroupDataTotime}
          setOverdueTaskGroupDataTotime={setOverdueTaskGroupDataTotime}
          handleRedirection={handleRedirection}
          showNotifications={showNotifications}
          PRIMARY_COLOR={props.PRIMARY_COLOR}
        />
      );
    }
  };
  return (
    <div>
      <div style={{ height: "95vh" }}>
        <MyAssignmentsHeader
          tabs={tabs}
          activeTab={activeTab}
          toggletab={id => toggletab(id)}
          activeUserName={props?.auth?.user}
        />
        <div style={{ width: "100%" }}>
          <div className="container" style={{ maxWidth: "100%" }}>
            <div className="row" style={{ paddingBottom: "10%" }}>
              <div className="col-lg-1 col-md-2 col-sm-0 col-xs-0" />

              <div
                className="col-lg-10 col-md-8 col-sm-12 col-xs-12 "
                style={{ padding: "30px 10px" }}
              >
                {handleActiveTabs(activeTab)}
              </div>
              <div className="col-lg-1 col-md-2 col-sm-0 col-xs-0" />
            </div>
          </div>
        </div>
      </div>
      <ChangeWorkspaceAlert
        loader={isAlertActionLoader}
        action="Switch Workspace"
        message={isAlertAction.status && getMessageType(isAlertAction.data)}
        PRIMARY_COLOR={props.PRIMARY_COLOR}
        show={isAlertAction.status || false}
        onConfirm={() => {
          doSwitchBusiness();
        }}
        onCancel={() => setIsAlertAction({ id: "", status: false })}
      />
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state,
    PRIMARY_COLOR: state.themeChanger.topbarTheme.buttonColor
  };
};

export default compose(
  withRouter,
  participantEmailEnhance,
  connect(mapStateToProps, {
    update_Project,
    update_token,
    set_projects,
    set_labels,
    update_business,
    update_projects_existing,
    remove_Project,
    open_workpsace_alert,
    open_project_alert,
    close_alert,
    do_update_project,
    close_update_project
  })
)(GlobalMyAssignments);
