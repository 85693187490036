import styled from "styled-components";

const AddProjectWrapper = styled.div`
  .form-control {
    border: 1px solid #e6e6e6 !important;
    border-radius: 5px !important;
  }
  .form-control:focus {
    border: 1px solid #e6e6e6 !important;
  }
  .css-1m8a84f-control {
    border: 1px solid #e6e6e6 !important;
    border-radius: 5px !important;
  }
  .custom-datepicker {
    border: 1px solid #e6e6e6 !important;
    border-radius: 5px !important;
  }
`;

export default AddProjectWrapper;
