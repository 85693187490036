import { store } from "../../redux/store";
export default function handleResponse(response) {
  if (response) {
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        store.dispatch({
          type: "LOGOUT"
        });
        localStorage.removeItem("persist:root");
        localStorage.removeItem("recent_workspace");
        window.location.replace("/login");
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        //Logout from here

        const error =
          (response && response.data.errors.message) || response.statusText;

        return Promise.reject(error);
      }
    }
  }
  return response;
}
