import styled from "styled-components";

const HomePageTitleWrapper = styled.div`
  .roy-menu-icon {
    color: white;
  }
  &:hover {
    span {
      color: ${props => props.topbarTheme.backgroundColor};
    }
    background-color: #f4f5f7;
    border-radius: 5px;
  }
`;
export default HomePageTitleWrapper;
