import React, { useState } from "react";
import RoyTooltip from "components/common/RoyTooltip";
import Loader from "components/loader/Loader";
import IntlMessages from "util/intlMessages";
const ResendInvitation = ({ id, doResendInvitation, PRIMARY_COLOR }) => {
  const [isLoader, setisLoader] = useState(false);
  const [isInvitationSent, setisInvitationSent] = useState(false);
  const [isShowCheck, setIsShowCheck] = useState(false);
  const startLoader = () => setisLoader(true);
  const stopLoader = () => setisLoader(false);
  const doUpdateHasInvitationSent = state => {
    setisInvitationSent(state);
    if (state) {
      showCheck();
    }
  };
  const showCheck = () => {
    setIsShowCheck(true);
    setTimeout(() => {
      setIsShowCheck(false);
    }, 4000);
    // <i className="fa fa-check " />
  };
  if (id) {
    return (
      <div>
        {isLoader ? (
          <>
            <Loader height="24px" width="20px" />
          </>
        ) : (
          <>
            {isInvitationSent ? (
              <>
                {isShowCheck && (
                  <i
                    className="fa fa-check "
                    style={{
                      transition: "0.3s ease-in-out",
                      color: "#28a745"
                    }}
                  />
                )}
              </>
            ) : (
              <RoyTooltip
                id={"resend-" + id}
                title={<IntlMessages id={"workspace.resendinvitation"} />}
              >
                <small
                  id={"resend-" + id}
                  className="  pt-2 cursor-pointer  member-remove "
                  style={{
                    color: `${PRIMARY_COLOR}`,
                    paddingRight: 0
                  }}
                  onClick={() =>
                    doResendInvitation(
                      id,
                      startLoader,
                      stopLoader,
                      doUpdateHasInvitationSent
                    )
                  }
                >
                  <span className="hover-underline">
                    <IntlMessages id={"workspace.resendinvitation"} />
                  </span>
                </small>
              </RoyTooltip>
            )}
          </>
        )}
      </div>
    );
  }
};

export default ResendInvitation;
