import { language } from "./index";

import englishLang from "assets/images/usaflag.png";
// import germanyLang from "assets/images/Flag_of_Germany.svg";
import germanyLang from "assets/images/Flag_of_Germany.png";
import dutchLang from "assets/images/Flag_of_the_Netherlands.svg.png";

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: englishLang
    },
    {
      languageId: "german",
      locale: "de",
      text: "German",
      icon: germanyLang
    },
    {
      languageId: "dutch",
      locale: "nl",
      text: "dutch",
      icon: dutchLang
    }
  ]
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
