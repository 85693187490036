export const ProjectColors = [
  { value: "amber", label: "amber", color: "#FFBF00" },
  { value: "grey", label: "grey", color: "#808080" },
  { value: "blue", label: "blue", color: "#0000FF" },
  { value: "pink", label: "pink", color: "#FFC0CB" },
  { value: "purple", label: "purple", color: "#800080" },
  { value: "deepPurple", label: "deepPurple", color: "#301934" },
  { value: "indigo", label: "indigo", color: "#4B0082" },
  { value: "lightBlue", label: "lightBlue", color: "#ADD8E6" },
  { value: "cyan", label: "cyan", color: "#00FFFF" },
  { value: "teal", label: "teal", color: "#008080" },
  { value: "lightGreen", label: "lightGreen", color: "#90EE90" },
  { value: "lime", label: "lime", color: "#BFFF00" },
  { value: "yellow", label: "yellow", color: "#FFFF00" },

  { value: "red", label: "red", color: "#FF0000" },
  { value: "orange", label: "orange", color: "#FFA500" },
  { value: "brown", label: "brown", color: "#A52A2A" },
  { value: "blueGrey", label: "blueGrey", color: "#6699cc" },
  { value: "black", label: "black", color: "#000000" }
];
export const getColorCode = colorname => {
  let colorcode = null;
  ProjectColors.filter(data => {
    if (data.value === colorname) {
      colorcode = data.color;
      return data.color;
    }
  });
  return colorcode;
};
