import React, { useState, useEffect } from "react";
import TodolistWrapper from "./todolistWrapper.style";
import moment from "moment";
import { commentnew } from "helper/constant";
import TaskDialog from "components/scrumboard/TaskDialog";
import Assignee from "components/assignee";
import {
  addTask,
  updateTask,
  deleteTask,
  joinRoom
} from "../../services/socket";
import { projectSocketRoomPrefix, taskSocketRoomPrefix } from "constatnt";
import TaskMenu from "components/projects/Menus/TaskMenu";
import CustomAlert from "util/CustomAlert";
import { getColorCode } from "components/projects/utils/color";
import IntlMessages from "util/intlMessages";
const TodoList = props => {
  const [taskinput, setTaskinput] = useState("");
  const [sectionId, setSectionId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [todoId, setTodoId] = useState(null);
  const [todo, SetTodo] = useState([]);
  const [taskModel, setTaskModel] = useState(false);
  const [activeTaskDetail, setActiveTaskDetail] = useState(null);
  const [collaboratorList, setCollaboratorList] = useState([]);
  const [taskMenu, setTaskMenu] = useState(false);
  const [activeTaskMenu, setActiveTaskMenu] = useState(null);
  const [isCustomAlert, setCustomAlert] = useState(false);
  const [sectionalertMessage, setSectionAlertMessage] = useState(
    "Task will be deleted!"
  );
  useEffect(() => {
    if (props.todoList) {
      setSectionId(props.sectionId);
      setProjectId(props.projectId);
      setProjectId(props.projectId);
      SetTodo(props.todoList);
      setCollaboratorList(props.collaboratorList);
      if (taskModel && activeTaskDetail) {
        const dataArr = [...props.todoList];

        // props.todoList && props.todoList.filter((data, i) => data.isDeleted == false);
        for (let i = 0; i < dataArr.length; i++) {
          if (activeTaskDetail.id == dataArr[i].id) {
            setActiveTaskDetail(dataArr[i]);
          }
        }
        // dataArr && dataArr.map(data,i=>{
        //   if(data.id==activeTaskDetail.id){
        //

        //   }
        // })
      }
    }
    handleChangeProps();
  }, [props]);

  const handleChangeProps = async () => {
    if (activeTaskDetail) {
      await Promise.all(
        todo.map((t, i) => {
          if (t.id == activeTaskDetail.id) {
            setActiveTaskDetail(t);
          }
        })
      );
    }
  };

  const updateisCompleted = async (e, todoId) => {
    try {
      let payload = {
        project_id: props.projectId.toString(),
        isCompleted: e.target.checked,
        roomname:
          props.projectId && `${projectSocketRoomPrefix}${props.projectId}`,
        task_id: todoId.toString()
      };
      updateTask(payload, cb => {
        if (cb.status == "Ok") {
        } else {
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
          props.showNotifications();
        }
      });
    } catch (e) {}
  };
  const handleKeyDown = e => {
    if (e.keyCode == 13) {
      addTaskHandler();
    }
  };

  const addTaskHandler = async e => {
    try {
      if (taskinput) {
        let payload = {
          title: taskinput,
          project_id: props.projectId.toString(),
          label_ids: "",
          section_id: props.sectionId ? props.sectionId.toString() : "",

          roomname: `${projectSocketRoomPrefix}${props.projectId}`
        };

        addTask(payload, cb => {
          if (cb.status == "Ok") {
            setTaskinput("");
          } else {
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
            props.showNotifications();
          }
        });
      }
    } catch (e) {}
  };

  const doDeleteTask = async (id, projectId) => {
    try {
      let payload = {
        project_id: projectId.toString(),
        isDeleted: true,
        task_id: id.toString(),
        roomname: `${projectSocketRoomPrefix}${projectId}`
      };
      deleteTask(payload, cb => {
        if (cb.status == "Ok") {
        } else {
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
          props.showNotifications();
        }
      });
    } catch (e) {}

    // const Response = await deleteTodo(id, projectId);
    // if (Response) {
    //   if (Response.error) {
    //
    //   }
    //   if (Response.data) {
    //
    //     if (Response.data.isDeleted) {
    //       removeTaskFromList(Response.data.id);
    //       props.showNotifications("Task deleted");
    //     }
    //   }
    // }
  };

  const refreshTodoList = newTodo => {
    let todoArr = todo;
    todoArr.map((item, index) => {
      if (item.id == newTodo.id) {
        todoArr[index] = newTodo;
      }
    });
    SetTodo(todoArr);
  };

  const removeTaskFromList = id => {
    const Arr = todo;

    Arr.map((data, i) => {
      if (data.id == id) {
        Arr.splice(i, 1);
      }
    });
    SetTodo(Arr);
  };
  const toggleTaskMenu = (target, taskData) => {
    if (taskMenu) {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(null);
      setActiveTaskDetail(null);
    } else {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(target);
      setActiveTaskDetail(taskData);
    }
  };
  return (
    <div>
      <TodolistWrapper>
        <div className="todo-container flex-y">
          {todo &&
            todo.map((data, i) => {
              return (
                <div
                  key={i}
                  className="flex-x my-todo align-center plr-10 ptb-5"
                  style={{ borderTop: "1px solid #f0f0f0" }}
                >
                  <div>
                    <div className="mt-1 pretty p-icon p-smooth">
                      <input
                        type="checkbox"
                        checked={data.isCompleted}
                        onChange={e => updateisCompleted(e, data.id)}
                      />
                      <div className="state p-info-o">
                        <i className="icon fas fa-check"></i>
                        <label></label>
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex-1"
                    onClick={() => {
                      setActiveTaskDetail(data);
                      setTodoId(data.id);
                      joinRoom(`${taskSocketRoomPrefix}${data.id}`);
                      setTaskModel(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div>
                      <span
                        className={data.isCompleted ? " fs-14" : "fs-14"}
                        style={data.isCompleted ? { color: "#767678" } : {}}
                      >
                        <span
                          style={data.isCompleted ? { width: "100%" } : {}}
                        ></span>
                        {data.title}
                      </span>
                    </div>
                    <div className="flex-x flex-wrap align-center fs-14">
                      {data.labels &&
                        data.labels.map((item, index) => {
                          return (
                            <>
                              <div className="my-day-label flex-x mr-6 fs-14 nevy--text align-center overflow-hidden">
                                <i
                                  className="fa fa-tag"
                                  style={{ color: getColorCode(item.color) }}
                                />
                                <span
                                  style={{
                                    color: getColorCode(item.color),
                                    paddingLeft: 5
                                  }}
                                >
                                  {item.name}
                                </span>
                              </div>
                            </>
                          );
                        })}
                      <div
                        className={`my-day-label flex-x mr-6 fs-14 nevy--text align-center overflow-hidden ${
                          data.labels
                            ? data.labels.length > 0
                              ? "label"
                              : ""
                            : ""
                        }`}
                      >
                        {/* <i
                          className="fa fa-comment-dots"
                          style={{ color: "grey" }}
                        /> */}
                        <img
                          src={commentnew}
                          style={{
                            height: "15px",
                            width: "15px",
                            marginRight: "0px",
                            marginTop: "0px",
                            textAlign: "center"
                          }}
                        ></img>
                        <span style={{ color: "grey", paddingLeft: 5 }}>
                          {data.commentCount}
                        </span>
                      </div>
                      {data.scheduledAt && (
                        <div className="due-date mr-6 label">
                          <IntlMessages id={"task-details.dueon"} />
                          &nbsp;
                          {moment(new Date(data.scheduledAt)).format(
                            "DD MMM YYYY"
                          )}
                        </div>
                      )}
                    </div>{" "}
                    {data.taskAssign && (
                      <Assignee
                        thumbnail={
                          data.taskAssign.thumbnail
                            ? data.taskAssign.thumbnail
                            : null
                        }
                        name={
                          data.taskAssign.name
                            ? data.taskAssign.name
                            : data.taskAssign.email &&
                              data.taskAssign.email.split("@")[0]
                        }
                      />
                    )}
                  </div>
                  <div
                    id={`taskmenu-${data.id}`}
                    onClick={() => toggleTaskMenu(`taskmenu-${data.id}`, data)}
                    className="cursor-pointer"
                  >
                    <i
                      className="fas fa-ellipsis-h"
                      style={{ color: "grey" }}
                    ></i>
                  </div>
                </div>
              );
            })}
          <div className="list-name flex-x align-center pa-15 todo-topborder">
            <div className="mr-10 flex-x icon">
              <i onClick={addTaskHandler} className="material-icons">
                add
              </i>
            </div>
            <div className="mr-10 flex-1">
              <input
                value={taskinput}
                onChange={e => setTaskinput(e.target.value)}
                className="task-add-input fs-14 fill-width"
                type="text"
                placeholder="New Task"
                onKeyDown={handleKeyDown}
              />
            </div>
            {taskinput !== "" && (
              <div
                className="fs-14 demi-bold-text  cursor-pointer"
                style={{
                  color: props.PRIMARY_COLOR ? props.PRIMARY_COLOR : "#ff6b6b"
                }}
                onClick={addTaskHandler}
              >
                ADD
              </div>
            )}
          </div>
          {/* <div className="backlines flex-1"></div> */}
        </div>
      </TodolistWrapper>

      {activeTaskDetail && (
        <TaskDialog
          taskModel={taskModel}
          setTaskModel={() => {
            setTaskModel(!taskModel);
            setActiveTaskDetail(null);
          }}
          taskDetail={activeTaskDetail}
          updateActiveTaskDetail={data => setActiveTaskDetail(data)}
          projectId={props.projectId}
          sectionId={props.sectionId}
          sectionTitle={props.sectionTitle}
          todoId={todoId}
          updateTodoList={newTodo => refreshTodoList(newTodo)}
          collaboratorList={collaboratorList}
          showNotifications={() => props.showNotifications()}
        />
      )}
      <TaskMenu
        isMenu={taskMenu}
        toggleMenu={() => toggleTaskMenu()}
        target={activeTaskMenu}
        editTask={() => {
          setTaskModel(!taskModel);
          setTaskMenu(!taskMenu);
          // setActiveTaskDetail(null);
        }}
        deleteTask={id => {
          setTaskMenu(!taskMenu);
          setCustomAlert(true);
        }}
      />
      <CustomAlert
        action="delete"
        messageType="task"
        PRIMARY_COLOR={props.PRIMARY_COLOR}
        show={isCustomAlert}
        onConfirm={() => {
          doDeleteTask(activeTaskDetail.id, projectId);
          setCustomAlert(false);
        }}
        onCancel={() => setCustomAlert(false)}
        alertMessage={sectionalertMessage}
      />
    </div>
  );
};

export default TodoList;
