import React, { useEffect, useState } from "react";
import _ from "lodash";
import { profileBackground } from "helper/methods/getUserAvatarColor";
function ReactionsEmoji(props) {
  const [userReactionsArr, setUserReactionsArr] = useState([]);
  const [userEmojiList, setUserEmojiList] = useState([]);
  const [userPopover, setUserPopover] = useState({
    id: "",
    status: false,
    data: []
  });
  const {
    userReactions,
    getCodeForEmoji,
    handleRemoveCommentReactions,
    addCommentReactions,
    cId
  } = props;
  var idd = cId || 0;
  useEffect(() => {
    if (userReactions) {
      setUserReactionsArr([...userReactions]);
      getEmoji();
      idd = cId;
    }
    return () => {
      setUserReactionsArr([]);
    };
  }, [userReactions]);

  const getEmoji = () => {
    var emojis = [];
    userReactions.map(data => {
      emojis.push(data.emojiname);
    });
    var uniqEmoji = _.uniqBy(emojis, function(o) {
      return o;
    });
    setUserEmojiList(uniqEmoji);
  };

  const getCount = name => {
    var count = 0;
    var reactionsArr = userReactionsArr;
    reactionsArr.map((data, i) => {
      if (data.emojiname == name) {
        count = count + 1;
      }
    });

    return count;
  };
  const updateEmojiList = data => {
    var isPersent = false;
    if (data) {
      userReactionsArr.map(emoji => {
        if (
          emoji.emojiname == data.emojiname &&
          emoji.user.id == props.user.id &&
          props.user.name == emoji.user.name
        ) {
          isPersent = true;
          removeEmoji(emoji);
          // if user exist them remove user with emoji name object
        }
      });
      if (!isPersent) {
        addCommentReactions(data.emojiname);
      }
    }
  };
  const removeEmoji = async data => {
    if (data) {
      handleRemoveCommentReactions(data.id);
    }
  };
  return (
    <div
      className={"row"}
      style={{
        width: "100%",
        paddingBottom: -10,
        display: "flex",
        flexWrap: "wrap"
      }}
    >
      {userReactionsArr.map((data, i) => {
        return (
          <>
            <div
              key={i}
              title={data.user.name}
              id={"Popover-" + i}
              onClick={() => updateEmojiList(data)}
              style={{
                width: 65,
                marginTop: 5,
                marginRight: "8px",
                cursor: "pointer",
                border: "1px solid #ebebeb",
                borderRadius: 20,
                backgroundColor: "#f8f8f8"
              }}
            >
              <span
                className="pr-2  pa-5"
                style={{
                  background: "#f8f8f8",
                  fontSize: 12,
                  height: 30,
                  borderRadius: 12,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {
                  <>
                    {data.user?.url && data.user?.url ? (
                      <img
                        alt=""
                        style={{
                          borderRadius: " 50%",
                          width: 20,
                          height: 20,
                          fontWeight: "600",
                          textAlign: " center",
                          color: "white",
                          fontSize: 12,
                          display: " flex",
                          justifyContent: "center",
                          alignItems: " center"
                        }}
                        src={data.user.url}
                      ></img>
                    ) : (
                      <span
                        style={profileBackground(data.user.name, 20, 20, 12)}
                      >
                        {data.user.name &&
                          data.user.name.charAt(0).toUpperCase()}
                      </span>
                    )}
                  </>
                }
                <span style={{ fontSize: 16, marginLeft: "6px" }}>
                  {" "}
                  {getCodeForEmoji(data.emojiname)}
                </span>
              </span>
            </div>
          </>
        );
      })}
    </div>
  );
}

export default ReactionsEmoji;
