import alertActions from "./actions";

const initState = {
  alert: localStorage.getItem("ErrorAlert")
    ? localStorage.getItem("ErrorAlert")
    : []
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case alertActions.OPEN_WORKPSACE_ALERT:
      return {
        ...state,
        alert: {
          status: action.alert.status,
          message: action.alert.message,
          action: "open_businesslist"
        }
      };
    case alertActions.OPEN_PROJECT_ALERT:
      return {
        ...state,
        alert: {
          status: action.alert.status,
          message: action.alert.message,
          action: "today"
        }
      };

    case alertActions.CLOSE_ALERT:
      return {
        ...state,
        alert: { status: false, message: "", action: "" }
      };

    default:
      return state;
  }
}
