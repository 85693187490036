import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import EditBusinessModal from "components/business/AddBusiness";
import WorkspaceHeader from "components/WorkspaceDetails/WorkspaceHeader";
import "./business.style.css";
import participantEmailEnhance from "./pages/authentication/enhancer/participantEmailEnhance";
import MemberTab from "components/WorkspaceDetails/MemberTab";
import ProjectTab from "components/WorkspaceDetails/ProjectTab";
import { swicthBusiness } from "helper/services/businessServices";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import {
  getWorkspaceDetailsByid,
  toggleArchive
} from "helper/services/businessServices";
import AddProject from "components/projects/AddProject";
import {
  checkforunique,
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import { sidebarData } from "util/data/sidebar";
import { getProject } from "helper/services/projectServices";
import { getLables } from "helper/services/projectServices";
import projectActions from "redux/projects/actions";
import labelActions from "redux/labels/actions";
import authActions from "redux/auth/actions";
import SettingsTab from "components/WorkspaceDetails/SettingsTab";
import alertActions from "redux/globalAlert/actions";
import { removeCollaborators } from "helper/services/businessCollaboratorsServices";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const {
  update_recent_Project,
  set_projects,
  update_Project,
  update_projects_existing
} = projectActions;
const { set_labels } = labelActions;

const { update_token, update_business, update_business_archive } = authActions;
const WorkspaceDetails = props => {
  const { addToast } = useToasts();
  const tabs = ["project", "member", "settings"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [workspaceDetails, setWorkspaceDetails] = useState("");
  const [projectArr, setProjectArr] = useState([]);
  const [isDefaultBusiness, setIsDefaultBusiness] = useState(false);
  const [isArchiveLoader, setIsArchiveloader] = useState(false);
  const [isRemoveLoader, setIsRemoveLoader] = useState(false);
  const [collaboratorArr, setCollaboratorArr] = useState([]);
  const [isAddProject, setIsAddProject] = useState(false);
  const [isEditBusiness, setIsEditBusiness] = useState(false);
  const [redirectMessage, setRedirectMessage] = useState(false);
  const [redirectLink, setRedirectLink] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [collaboratorationId, setCollaboratorationId] = useState(null);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Something went wrong");
  const [archivedProjectArr, setArchivedProjectArr] = useState([]);
  const [isLoader, setIsloader] = useState(false);
  const { id } = props.match.params;
  const histroy = useHistory();
  // const id = props?.auth?.user?.businessId;

  useEffect(() => {
    fetchWorkspaceDetails(id);
    return () => {
      setActiveTab(tabs[0]);
      setWorkspaceDetails("");
      setProjectArr([]);
      setIsDefaultBusiness(false);
      setIsArchiveloader(false);
      setCollaboratorArr([]);
      setIsAddProject(false);
      setIsEditBusiness(false);
      setRedirectMessage(false);
      setRedirectLink(false);
      setIsOwner(false);
      setToast(false);
      setCollaboratorationId(null);
      setToastMessage("Something went wrong");
      setArchivedProjectArr([]);
      setIsloader(false);
    };
  }, [id]);
  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        // status:action.alert.status, message:action.alert.message,action:'open_businesslist'

        if (props.auth?.user?.businessId == id) {
          props.update_business({
            id: null,
            businessName: null,
            businessMeta: null
          });
        }
        props.open_workpsace_alert({
          status: true,
          message: "workspace"
        });
      }
      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
      }
    } catch (e) {}
  };
  const fetchProject = async token => {
    const Response = await getProject(
      token
        ? token
        : props.auth.accessToken || props.history.location.state.token
    );
    if (Response) {
      props.set_projects({ projects: Response.data });

      UpdateSidebarProject(Response.data);
    }
  };
  const updateBusinessData = data => {
    if (data) {
      setWorkspaceDetails({ ...workspaceDetails, ...data });
    }
  };
  const isBusinessOwnerAndCollaborationId = collaboratorArr => {
    let status = false;
    if (collaboratorArr) {
      collaboratorArr.map(data => {
        if (
          data &&
          data.role.toLowerCase() == "owner" &&
          data.user.id == props.auth.user.id
        ) {
          status = true;
          setCollaboratorationId(data.id);
          setIsOwner(true);
        }
        if (data && data.user && data.user?.id == props.auth?.user?.id) {
          setCollaboratorationId(data.id);
        }
      });
    }
    setIsOwner(status);
  };
  const fetchLabels = async token => {
    try {
      const Response = await getLables(
        token
          ? token
          : props.auth.accessToken || props.history.location.state.token
      );
      if (Response) {
        if (Response.data) {
          props.set_labels({ labels: Response.data });
          updateSidebarLabelToSidebar(Response.data);
          // sidebarData.map((data, i) => {
          //   if (data.name === "sidebar.labels") {
          //     Response.data.map((a, i) => {
          //       if (!checkforunique(data.child, a)) {
          //         data.child.push({
          //           id: a.id,
          //           listname: a.name,
          //           routepath: `/label/${a.id}`,
          //           color: a.color,
          //         });
          //       }
          //     });
          //   }
          // });
        }
        if (Response.error) {
          handleResponse(Response.error.message);
        }
      }
    } catch (e) {}
  };
  const UpdateSidebarProject = ResponseData => {
    updateSidebarProjectToSidebar(ResponseData);
    // sidebarData.map((data, i) => {

    //   if (data.name === "sidebar.projects") {
    //     ResponseData.map((a, i) => {
    //       if (!checkforunique(data.child, a)) {
    //         if (a.name !== "Inbox" && a.isDeleted === false) {
    //           data.child.push({
    //             id: a.id,
    //             listname: a.name,
    //             routepath: `/project/${a.id}`,
    //             color: a.color,
    //           });
    //         }
    //       }
    //     });
    //   }
    // });
  };

  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const updateProjectToRedux = data => {
    props.update_Project(data);
  };
  const pushProject = async data => {
    if (workspaceDetails.id === data.business.id) {
      if (data) {
        await setProjectArr([...projectArr, data]);
      }
    }
  };
  const pushTounArchivedProject = async data => {
    if (data) {
      var archive = [...archivedProjectArr];
      const dataArr =
        archive && archive.filter(archived => archived.id === data.id);
      setArchivedProjectArr([
        ...archivedProjectArr.filter(archived => archived.id !== data.id)
      ]);
      setProjectArr([...projectArr, dataArr[0]]);
    }
  };
  const pushToArchivedProject = async data => {
    if (data) {
      var archive = [...projectArr];
      const dataArr =
        archive && archive.filter(archived => archived.id === data.id);
      console.log([...archivedProjectArr, dataArr]);
      setProjectArr([
        ...projectArr.filter(archived => archived.id !== data.id)
      ]);
      setArchivedProjectArr([...archivedProjectArr, dataArr[0]]);
    }
  };
  const checkDefaulBusiness = businessId => {
    if (props?.auth?.user?.businessId === businessId) {
      setIsDefaultBusiness(true);

      return true;
    }
    setIsDefaultBusiness(false);
    return false;
  };
  const fetchWorkspaceDetails = async (id, isLoading = true) => {
    if (id) {
      try {
        setIsloader(isLoading);
        const response = await getWorkspaceDetailsByid(id);
        if (response) {
          if (response.data) {
            await setWorkspaceDetails(response.data);
            await checkDefaulBusiness(response?.data?.id);
            await setCollaboratorArr(response.data.collaborator);
            await filterArchiveProjects(
              response.data.project,
              response?.data?.isArchived
            );
            props.update_business_archive(response?.data?.isArchived);
            await isBusinessOwnerAndCollaborationId(response.data.collaborator);
            if (response) {
              props.set_projects({ projects: response.data?.project });
              updateSidebarProjectToSidebar(response.data?.project);
              histroy.replace({ pathname: `/business/${id}` });
            }

            setIsloader(false);
          }
          if (response.error) {
            handleResponse(response.error.message);
            setIsloader(false);
            showNotifications();
          }
        }
      } catch (e) {
        setIsloader(false);

        showNotifications();
      }
    }
  };

  const updateProjectandLabel = async token => {
    fecthProject(token);
    fetchLabels(token);
  };

  const fecthProject = async token => {
    const Response = await getProject(token);
    if (Response) {
      props.set_projects({ projects: Response.data });
      updateSidebarProjectToSidebar(Response.data);
    }
  };
  const updateToken = (token, businessName, businessMeta, id) => {
    props.update_token(token);

    props.update_business({
      id: id,
      businessName: businessName,
      businessMeta: businessMeta
    });
    props.update_business({ businessId: id, businessName, businessMeta });
  };
  const handleGoTOProject = async projectId => {
    if (isDefaultBusiness) {
      props.history.push(`/project/${projectId}`);
    } else {
      await handleSwitchBusiness(id);
      props.history.push(`/project/${projectId}`);
    }
  };
  const handleSwitchBusiness = async id => {
    try {
      const Response = await swicthBusiness(id);
      if (Response) {
        if (Response.data) {
          await updateToken(
            Response.data.token,
            Response.data.business.title,
            Response.data.business?.businessMeta
              ? Response.data.business?.businessMeta
              : null,
            Response.data.business.id
          );

          await props.handleBusinessJoinRoom(
            props.auth.user.businessId,
            Response.data.business.id,
            Response.data.token
          );
          props.update_recent_Project({
            project_id: ""
          });
          updateProjectandLabel(Response.data.token);
        }
        if (Response.error) {
          handleResponse(Response.error.message);
        }
      }
    } catch (e) {}
  };
  const archiveBusiness = async () => {
    if (id) {
      try {
        setIsArchiveloader(true);
        setArchivedProjectArr([]);

        const response = await toggleArchive(id);
        if (response) {
          console.log(response);
          if (response.message) {
            setIsArchiveloader(false);
            fetchWorkspaceDetails(id, false);

            if (props?.auth?.user?.businessId == id) {
              props.update_business_archive(!workspaceDetails.isArchived);
            }
          }
          if (response.error) {
            handleResponse(response.error.message);
            setIsArchiveloader(false);
            showNotifications();
          }
        }
      } catch (e) {
        setIsArchiveloader(false);
        showNotifications();
      }
    }
  };
  const filterArchiveProjects = (projects, isArchived) => {
    if (isArchived) {
      setProjectArr([...projects]);
    } else {
      var archivedProjectArr,
        notArchivedProjectAr = [];
      archivedProjectArr =
        projects && projects.filter(project => project.isArchived);
      notArchivedProjectAr =
        projects && projects.filter(project => !project.isArchived);
      setArchivedProjectArr([...archivedProjectArr]);
      setProjectArr([...notArchivedProjectAr]);
    }
  };

  const updateExistingProjectToRedux = data => {
    props.update_projects_existing({ projects: data });
  };

  const handleLeaveWorkpsace = async () => {
    if (collaboratorationId) {
      try {
        setIsRemoveLoader({
          id: collaboratorationId,
          status: true
        });
        const Response = await removeCollaborators({
          collaboration_id: collaboratorationId
        });
        if (Response) {
          if (Response.error) {
            handleResponse(Response.error.message);
            showNotifications();
            setIsRemoveLoader({
              id: "",
              status: false
            });
          }
          if (Response.message) {
            setIsRemoveLoader({
              id: "",
              status: false
            });
            showNotifications(Response.message, "success");
            var checkArr = [...collaboratorArr];

            checkArr.map((data, i) => {
              if (data.id == collaboratorationId) {
                checkArr.splice(i, 1);
              }
            });

            setCollaboratorArr([...checkArr]);

            if (!isOwner) {
              if (props.auth?.user?.businessId == id) {
                props.update_business({
                  id: null,
                  businessName: null,
                  businessMeta: null
                });
              }
              props.open_workpsace_alert({
                status: true,
                message: "workspace"
              });
            }
            setIsRemoveLoader({
              id: "",
              status: false
            });
          }
        }
      } catch (e) {
        setIsRemoveLoader({
          id: "",
          status: false
        });
      }
    }
  };

  const handleActiveTabs = tabId => {
    if (tabId == "project") {
      return (
        <ProjectTab
          isLoader={isLoader}
          projectArr={projectArr}
          isOwner={isOwner}
          topbarTheme={props.topbarTheme}
          toggleAddProject={() => setIsAddProject(!isAddProject)}
          archivedProjectArr={archivedProjectArr}
          pushTounArchivedProject={pushTounArchivedProject}
          pushToArchivedProject={pushToArchivedProject}
          showNotifications={showNotifications}
          handleGoTOProject={handleGoTOProject}
          updateProjectToRedux={updateExistingProjectToRedux}
        />
      );
    }
    if (tabId == "member") {
      return (
        <MemberTab
          workspaceName={workspaceDetails.title}
          isLoader={isLoader}
          isOwner={isOwner}
          topbarTheme={props.topbarTheme}
          {...props}
          id={id}
          currentLoggedInUser={props?.auth?.user}
          collaboratorArr={collaboratorArr}
          setCollaboratorArr={setCollaboratorArr}
          handleResponse={handleResponse}
          open_workpsace_alert={() =>
            props.open_workpsace_alert({
              status: true,
              message: "workspace"
            })
          }
        />
      );
    }
    if (tabId == "settings") {
      return (
        <SettingsTab
          workspaceDetails={workspaceDetails}
          isLoader={isArchiveLoader}
          id={id}
          isOwner={isOwner}
          isArchived={workspaceDetails?.isArchived}
          showNotifications={showNotifications}
          archiveBusiness={archiveBusiness}
          handleResponse={handleResponse}
          leaveWorkpace={handleLeaveWorkpsace}
          isRemoveLoader={isRemoveLoader.status}
          topbarTheme={props.topbarTheme}
        />
      );
    }
  };

  const toggletab = id => {
    setActiveTab(id);
  };
  return (
    <div>
      <WorkspaceHeader
        id={id}
        archiveBusiness={archiveBusiness}
        isLoader={isLoader}
        isArchiveLoader={isArchiveLoader}
        workspaceDetails={workspaceDetails}
        tabs={tabs}
        activeTab={activeTab}
        isOwner={isOwner}
        toggletab={id => toggletab(id)}
        toggleEditBusiness={() => setIsEditBusiness(!isEditBusiness)}
      />
      <div style={{ width: "100%", background: "white" }}>
        <div className="container">
          <div className="row" style={{ paddingBottom: "0%" }}>
            <div
              className="col-lg-1 col-md-1 col-sm-0 col-xs-0"
              style={{ background: "white", paddingBottom: "10%" }}
            />
            <div
              className="col-lg-10 col-md-10 col-sm-12 col-xs-12 "
              style={{ padding: 10, background: "white", paddingBottom: "10%" }}
            >
              {handleActiveTabs(activeTab)}
            </div>
            <div
              className="col-lg-1 col-md-1 col-sm-0 col-xs-0"
              style={{ background: "white", paddingBottom: "10%" }}
            />
          </div>
        </div>
      </div>
      <AddProject
        modal={isAddProject}
        setmodal={() => setIsAddProject(!isAddProject)}
        showNotifications={showNotifications}
        history={route => props.history.push(route)}
        updateRedux={data => {
          pushProject(data);
          if (props.auth.user.businessId === data.business.id) {
            updateProjectToRedux(data);
          }
        }}
        UpdateSidebarProject={data => {
          UpdateSidebarProject(data);
        }}
        checkforunique={(datachild, toCheckValue) =>
          checkforunique(datachild, toCheckValue)
        }
        defaultWorkspaceId={workspaceDetails?.id}
        sidebarData={sidebarData}
      />
      <EditBusinessModal
        status={"update"}
        businessData={workspaceDetails}
        modal={isEditBusiness}
        setmodal={() => setIsEditBusiness(!isEditBusiness)}
        updateBusinessData={data => updateBusinessData(data)}
        updateToken={(token, businessName, businessMeta, id) => {
          if (checkDefaulBusiness(id)) {
            props.update_token(token);

            props.update_business({
              id: id,
              businessName: businessName,
              businessMeta: businessMeta
            });
          }
        }}
        showNotifications={(message, status) =>
          showNotifications(message, status)
        }
      />
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default compose(
  withRouter,
  participantEmailEnhance,
  connect(mapStateToProps, {
    update_Project,
    update_projects_existing,
    update_token,
    set_projects,
    set_labels,
    update_business,
    update_business_archive,
    open_workpsace_alert,
    open_project_alert,
    close_alert,
    update_recent_Project
  })
)(WorkspaceDetails);
