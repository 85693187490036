import React, { useState, useRef, useEffect } from "react";
import RoyTooltip from "components/common/RoyTooltip";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import classNames from "classnames";
import { allPriority } from "constatnt";
import { connect } from "react-redux";
import IconTint from "react-icon-tint";
import {
  invite,
  cross,
  TaskDialogAssignTaskImage,
  TaskDialogCrossImage,
  singleCheckIcon,
  NewTaskPriorityCross
} from "helper/constant";
import { getColorCode } from "components/projects/utils/color";
import style from "./style.css";
import LabelsPopover from "components/Labels/LabelsPopover";
const DialogHeaderActions = ({
  PRIMARY_COLOR,
  setTaskModel,
  selectedLabels,
  selectLabelHandler,
  selectPriorityHandler,
  labelArr,
  taskPriority,
  openTaskCommentModal,
  openAssignTaskModal,
  label,
  openTaskActivity,
  showTaskAssign,
  openPrioritySelection,
  togglePrioritySelection,
  openShareSelection,
  toggleShareSelection,
  openLabelSelection,
  toggleLabelSelection,
  sharelink,
  openAddLabel,
  topbarTheme,
  toggleOpenNewModal,
  updateLabelToParent
}) => {
  const ids = selectedLabels && selectedLabels.map(a => a.id);
  const [copied, setCopied] = useState(false);

  const inputRef = useRef("");

  // useEffect(() => {
  //   if(inputRef && inputRef.current && openShareSelection){
  //     inputRef.current.focus();
  //
  //     inputRef.current.select();
  //   }
  // },[inputRef])

  const handleClick = () => {
    inputRef.current.select();
  };

  return (
    <div className="icons " style={{ display: "inline-flex", marginTop: 2 }}>
      {/* <RoyTooltip id="activity" title="Activity">
        <i
          id="activity"
          className="fas fa-history"
          onClick={() => openTaskActivity()}
        ></i>
      </RoyTooltip> */}

      {showTaskAssign && (
        <span
          style={{
            height: 26,
            // width: 98,
            backgroundColor: PRIMARY_COLOR,
            borderRadius: 5,
            display: "flex",
            textAlign: "start",
            alignItems: "baseline",
            padding: "0px 10px"
          }}
        >
          <RoyTooltip id="Assigntask" title="Assign task">
            <i
              id="Assigntask"
              style={{ marginLeft: "0px" }}
              // className="fas fa-user-plus"
              onClick={() => openAssignTaskModal()}
            >
              <img
                id="Assigntask"
                src={TaskDialogAssignTaskImage}
                height="14"
                width="16"
                // style={{ marginLeft: "-4px" }}
                className=" "
                alt="Labels"
              />
            </i>
          </RoyTooltip>
          <span
            onClick={() => openAssignTaskModal()}
            style={{
              height: 26,
              fontSize: "12px",
              color: "white",
              marginLeft: -6,
              fontWeight: "600",
              cursor: "pointer"
            }}
          >
            {" "}
            Assign Task
          </span>
        </span>
      )}

      {/* <RoyTooltip id="comment" title="Comment">
        <i
          id="comment"
          className="fa fa-comment-alt"
          onClick={() => openTaskCommentModal()}
        ></i>
      </RoyTooltip> */}
      {!label && (
        <RoyTooltip id="Labels" title="Labels">
          <span className="more_icon" id="Labels">
            <LabelsPopover
              updateLabelToParent={updateLabelToParent}
              isOpen={openLabelSelection}
              placement={"bottom"}
              target="UncontrolledPopover"
              toggle={() => toggleLabelSelection(!openLabelSelection)}
              selectedLabels={ids}
              selectLabelHandler={selectLabelHandler}
            />
          </span>
        </RoyTooltip>
      )}
      <RoyTooltip id="priority" title="Priority">
        <span className="more_icon" id="priority">
          {/* <i className="fas fa-flag" id="PUncontrolledPopover"></i> */}
          <UncontrolledPopover
            isOpen={openPrioritySelection}
            trigger="legacy"
            className="roy-menu"
            innerClassName="roy-inner-content"
            placement="bottom-end"
            target="PUncontrolledPopover"
            toggle={() => togglePrioritySelection()}
          >
            <PopoverBody style={{ minWidth: "220px" }}>
              <div
                className="float-right"
                onClick={() => togglePrioritySelection()}
              >
                <img
                  src={NewTaskPriorityCross}
                  style={{
                    height: "10px",
                    width: "10px",
                    marginRight: "5px",
                    marginTop: "6px"
                  }}
                ></img>
                {/* <i className="fa fa-times text-muted" /> */}
              </div>
              <div
                className="fs-14 text-center border-bottom"
                style={{
                  padding: "4px 4px 10px 4px",
                  color: "#808080",
                  borderBottom: "1px solid #ebebeb"
                }}
              >
                Priority
              </div>
              {allPriority &&
                allPriority.map((priority, i) => {
                  return (
                    //   <div
                    //   className="roy-menu-list"
                    //   key={priority.value}
                    //   id="PUncontrolledPopover"
                    //   onClick={() => {
                    //     // selectPriorityHandler(priority.name);
                    //     onChangeTaskPriority(priority.name);
                    //     togglePrioritySelection();
                    //   }}
                    // >
                    //   <div className="label-list">
                    //     <div>
                    //       <img
                    //         src={priority.path}
                    //         height="16"
                    //         width="16"
                    //         className=""
                    //         alt={priority.name}
                    //         style={{
                    //           cursor: "pointer",
                    //           color: priority.color
                    //         }}
                    //       />
                    //     </div>
                    //     <div style={{ marginLeft: 10 }}>
                    //       {priority.value}
                    //     </div>
                    //     {priority.name == taskPriority && (
                    //       <div>
                    //         <i
                    //           className={classNames(
                    //             "fas fa-check-double",
                    //             "ml-15",
                    //             `${priority.color}--text`
                    //           )}
                    //           style={{ color: priority.color }}
                    //         ></i>
                    //       </div>
                    //     )}
                    //   </div>
                    // </div>
                    <div
                      className="roy-menu-list"
                      key={priority.value}
                      id="PUncontrolledPopover"
                      onClick={() => {
                        selectPriorityHandler(priority.name);
                        togglePrioritySelection();
                      }}
                    >
                      <div
                        className="label-list"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <img
                              src={priority.path}
                              height="20"
                              width="16"
                              className=""
                              alt={priority.name}
                              style={{
                                cursor: "pointer",
                                color: priority.color
                              }}
                            />
                          </div>
                          <div style={{ marginLeft: 10 }}>{priority.value}</div>
                        </div>
                        {priority.name == taskPriority && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <IconTint
                              maxHeight="15"
                              maxWidth="15"
                              src={singleCheckIcon}
                              color={topbarTheme.buttonColor}
                            />
                            {/* <img
                              src={singleCheckIcon}
                              className={classNames("mlr-15")}
                            ></img> */}
                            {/* <i
                              className={classNames(
                                "fas fa-check-double",
                                "ml-15",
                                `${priority.color}--text`
                              )}
                              style={{ color: priority.color }}
                            ></i> */}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </PopoverBody>
          </UncontrolledPopover>
        </span>
      </RoyTooltip>

      <RoyTooltip id="link" title="link">
        <span className="more_icon" id="link">
          {/* <i className="fa fa-link" id="LUncontrolledPopover"></i> */}
          <UncontrolledPopover
            // isOpen={openShareSelection}
            trigger="legacy"
            className="roy-menu"
            placement="bottom-end"
            target="LUncontrolledPopover"
            // toggle={() =>toggleShareSelection()}
          >
            <PopoverBody
              style={{ minWidth: "400px !important", width: "290px" }}
              className="roy-menu "
            >
              <div className="roy-inner-content ">
                <input
                  className="Input-Focus"
                  autoFocus
                  onFocus={e => e.target.select()}
                  ref={inputRef}
                  value={sharelink}
                  // disabled
                  style={{
                    width: "94%"
                  }}
                  onClick={handleClick}
                />
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      cursor: "pointer",
                      // fontSize: "11.2px",
                      fontSize: "14px",
                      color: "black",
                      marginTop: "5px"
                    }}
                    // className={"underlinetextStyle"}
                    onClick={() => {
                      const el = document.createElement("textarea");
                      el.value = sharelink;
                      document.body.appendChild(el);
                      el.select();
                      document.execCommand("copy");
                      document.body.removeChild(el);
                      setCopied(true);
                      setTimeout(() => {
                        setCopied(false);
                      }, 2000);
                    }}
                  >
                    {copied ? "Copied" : "Copy"}
                  </p>

                  <a
                    // className={"underlinetextStyle"}
                    href={sharelink}
                    target={"_blank"}
                    style={{
                      marginLeft: 12,
                      cursor: "pointer",
                      fontSize: "14px",
                      color: "black",
                      marginTop: "5px",
                      textDecoration: "none"
                    }}
                  >
                    <p>Preview</p>
                  </a>
                </div>
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </span>
      </RoyTooltip>

      <RoyTooltip id="close" title="Close">
        <i
          id="close"
          // className="fas fa-times"
          onClick={() => setTaskModel()}
        >
          <img
            id="close"
            src={TaskDialogCrossImage}
            height="16"
            width="19"
            style={{ marginRight: "-11px", marginLeft: "14px" }}
            alt="Labels"
          />
        </i>
      </RoyTooltip>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps)(DialogHeaderActions);
