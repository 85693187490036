/** @format */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import UserProfileWrapper from "./UserProfileWrapper.style";
import { profile, editProfilePic, deleteProfile } from "helper/constant";
import {
  getUser,
  updareUserProfile,
  updatePassword
} from "helper/services/authServices";
import ImageUploading from "react-images-uploading";
import CustomNotification from "util/CustomNotification";
import AuthActions from "redux/auth/actions";
import enhancer1 from "./enhancer/changePassword";
import Notifications, { notify } from "react-notify-toast";
import Loader from "components/loader/Loader";
import { disconnect } from "services/socket";
import IntlMessages from "util/intlMessages";
import languageActions from "redux/languageSwitcher/actions";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";

const { update_User, update_User_details, logout } = AuthActions;
const { changeLanguage } = languageActions;
const UserProfile = props => {
  const { addToast } = useToasts();
  const [name, setName] = useState("");
  const [email, setEmail] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [imageFlage, setImageFlag] = useState(false);
  const [images, setImages] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isPasswordLoader, setIsPasswordLoader] = useState(false);
  const [errorNameField, setErrorNameField] = useState(false);
  const [errorDesignationField, setErrorDesignationField] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDeleteProfilePic, setIsDeleteProfilePic] = useState(false);
  const [confirm, setConfirmPassword] = useState("");
  useEffect(() => {
    setIsDisabled(true);
    // setIsLoader(true);
    fetchUser();
  }, []);
  // const SetConfirmPasswordValidation = (e) => {
  // 	setConfirmPassword(e.target.value);
  // };
  // console.log(confirm.length);
  const fetchUser = async () => {
    const Response = await getUser();

    if (Response) {
      props.changeLanguage(Response.user.userSetting.language);
      if (Response.error) {
      } else {
        setName(Response.user.name);
        setEmail(Response.user.email);
        setDesignation(Response.user.designation);
        if (Response.user.userMeta) {
          setSelectedImage({
            data_url: Response.user.userMeta.thumbnail
          });
          setIsDeleteProfilePic(false);
        }

        setIsLoader(false);
        setIsDisabled(false);
      }
    }
  };
  const onImageChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
    setSelectedImage(imageList[0]);
    setImageFlag(true);
    setIsDeleteProfilePic(false);
  };
  const isBothPasswordValid = (oldPassword, confirmPassword, newPassword) => {
    let status = {
      status: false,
      message:
        "New password and confirm new password must be at least 8 characters long"
    };
    if (confirmPassword && newPassword && oldPassword) {
      if (confirmPassword === newPassword) {
        if (confirmPassword !== oldPassword) {
          status = { status: true, message: "ok" };
        } else {
          status = {
            status: false,
            message: "Old password and new password must not be same"
          };
        }
      } else {
        status = {
          status: false,
          message:
            "New password and confirm new password must be same to change password "
        };
      }
    }
    return status;
  };
  const handlechangePassword = async e => {
    e.preventDefault();
    let { values, handleSubmit } = props;
    let isPasswordValidstatus = isBothPasswordValid(
      values.oldPassword,
      values.confirmPassword,
      values.newPassword
    );

    if (isPasswordValidstatus.status) {
      try {
        setIsPasswordLoader(true);
        const changePasswordResponse = await updatePassword({
          old_password: values.oldPassword,
          new_password: values.newPassword
        });
        if (changePasswordResponse) {
          if (changePasswordResponse.errors) {
            setIsPasswordLoader(false);
            showNotifications(changePasswordResponse.errors.message, "error");
          } else if (changePasswordResponse.error) {
            setIsPasswordLoader(false);
            showNotifications(changePasswordResponse.error.message, "error");
          } else {
            setIsPasswordLoader(false);
            showNotifications("New Password Updated", "success");
            setTimeout(() => {
              localStorage.removeItem("persist:root");
              props.logout();
              localStorage.removeItem("recent_workspace");
              disconnect();
            }, 200);
          }
        }
      } catch (e) {
        setIsPasswordLoader(false);
      }
    } else {
      showNotifications(isPasswordValidstatus.message, "error");
    }

    handleSubmit();
  };

  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  // const showNotifications = message => {
  //   setToast(true);
  //   setToastMessage(message ? message : "something went wrong");
  //   setTimeout(() => {
  //     setToast(false);
  //     setToastMessage("");
  //   }, 2500);
  // };
  const handleUpdateProfile = async e => {
    e.preventDefault();
    let values = e.target;
    try {
      if (values.name.value) {
        setErrorNameField(false);
        setErrorDesignationField(false);
        setIsLoader(true);

        let ProfilePicture;
        if (selectedImage && imageFlage) {
          ProfilePicture = { profilePic: selectedImage.file };
        } else {
          ProfilePicture = { profilePic: "" };
        }
        const Response = await updareUserProfile({
          name: values.name.value,
          designation: values.designation.value,
          profilePic: ProfilePicture.profilePic,
          isDeleteProfilePic: isDeleteProfilePic
        });
        if (Response) {
          if (Response.error) {
            showNotifications(Response.error.message, "error");
            setIsLoader(false);
          }
          if (Response.errors) {
            showNotifications(Response.errors.message, "error");
            setIsLoader(false);
          } else {
            props.update_User_details({
              name: Response.user.name,
              userMeta: Response.user.userMeta
            });

            showNotifications("Profile Updated", "success");
            setIsLoader(false);
          }
        }
      } else {
        setErrorNameField(true);
        setErrorDesignationField(true);
        ErrorUserProfile();
      }
    } catch (e) {
      setIsLoader(false);
    }
  };

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;

  const ErrorUserProfile = () => {
    return (
      <span className={props.class ? props.class : "error-msg"}>
        <IntlMessages id={"profile.nameerror"} />
      </span>
    );
  };
  const ErrorUserProfileDesignation = () => {
    return (
      <span className={props.class ? props.class : "error-msg"}>
        <IntlMessages id={"profile.designationerror"} />
      </span>
    );
  };
  const ErrorComponment = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  return (
    <div className="row">
      {/* <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12" /> */}
      {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> */}
      <div className="col-lg-2 col-md-2 col-sm-0 col-xs-0" />
      <div
        className="col-lg-8 col-md-8 col-sm-12 col-xs-12"
        style={{
          borderRadius: "10px",
          // background: "#FFFFFF",
          marginTop: "25px",
          marginBottom: "18px",
          minHeight: "90vh"
        }}
      >
        <Notifications />

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div
              className="plr-15"
              style={{
                //marginTop: "37px",
                // marginRight: "50px",
                // marginLeft: "50px",
                marginBottom: "37px"
              }}
            >
              <div>
                <div
                  className="introduction"
                  style={{ paddingTop: "0px", color: "black" }}
                >
                  {" "}
                  {<IntlMessages id={"profile.profile"} />}
                </div>
                <div
                  className="mb-30 theme-color"
                  style={{
                    backgroundColor: "white",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    borderRadius: "5px"
                  }}
                >
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ptb-15 ptb-40 roe-card-style mb-30">
                    <form onSubmit={handleUpdateProfile}>
                      <div className=" profile-image">
                        <ImageUploading
                          value={images}
                          onChange={onImageChange}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper  profile-image">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <div>
                                  <img
                                    style={{ objectFit: "cover" }}
                                    src={
                                      selectedImage && selectedImage.data_url
                                        ? selectedImage.data_url
                                        : profile
                                    }
                                    onClick={onImageUpload}
                                    id="profilePicture"
                                    alt="icon"
                                    height="100px"
                                  />
                                  {imageList.length > 0 &&
                                    imageList.map((image, index) => (
                                      <div key={index} className="image-item">
                                        <div className="image-item__btn-wrapper">
                                          <a
                                          // onClick={() => onImageUpdate(index)}
                                          //style={{ padding: 10 }}
                                          >
                                            <img
                                              onClick={() =>
                                                onImageUpdate(index)
                                              }
                                              src={editProfilePic}
                                              style={{
                                                height: 22,
                                                width: 22,
                                                marginTop: "-30px",
                                                marginLeft: 25,
                                                cursor: "pointer"
                                              }}
                                            />
                                          </a>
                                          <a
                                          // onClick={() => {
                                          //   setIsDeleteProfilePic(true);
                                          //   onImageRemove(index);
                                          // }}
                                          >
                                            <img
                                              onClick={() => {
                                                setIsDeleteProfilePic(true);
                                                onImageRemove(index);
                                              }}
                                              src={deleteProfile}
                                              style={{
                                                height: 22,
                                                width: 22,
                                                marginLeft: 5,
                                                marginTop: "-30px",
                                                cursor: "pointer"
                                              }}
                                            />
                                            {/* <i className="fa fa-trash text-muted" /> */}
                                          </a>
                                        </div>
                                      </div>
                                    ))}
                                  {imageList.length == 0 && (
                                    <div key={1} className="image-item">
                                      <div className="image-item__btn-wrapper">
                                        <a
                                        // onClick={onImageUpload}
                                        //style={{ padding: 10 }}
                                        >
                                          <img
                                            onClick={onImageUpload}
                                            src={editProfilePic}
                                            style={{
                                              height: 20,
                                              width: 22,
                                              marginTop: "-31px",
                                              alignSelf: "center",
                                              marginLeft:
                                                imageList.length == 0 &&
                                                selectedImage == null
                                                  ? 39
                                                  : 25,
                                              cursor: "pointer"
                                            }}
                                            //style={{ height: "22px", marginTop: "-22px" }}
                                          />
                                          {/* <i className="fa fa-edit text-muted" /> */}
                                        </a>
                                        {imageList.length === 0 &&
                                          selectedImage && (
                                            <a
                                              onClick={() => {
                                                setSelectedImage(null);
                                                setIsDeleteProfilePic(true);
                                              }}
                                            >
                                              <img
                                                src={deleteProfile}
                                                style={{
                                                  height: "20px",
                                                  width: 22,
                                                  marginLeft: 5,
                                                  marginTop: "-11px",
                                                  position: "absolute",
                                                  cursor: "pointer"
                                                }}
                                              />
                                              {/* <i className="fa fa-trash text-muted" /> */}
                                            </a>
                                          )}
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div
                                  className="workspace-details-contents"
                                  style={{ marginLeft: 35, width: "100%" }}
                                >
                                  <div
                                    className="form-group"
                                    style={{ margin: 0 }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 14,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      <label>
                                        <IntlMessages id={"profile.name"} />
                                      </label>
                                    </span>
                                    {/* <UserProfileWrapper> */}
                                    <input
                                      type="text"
                                      id="name"
                                      disabled={isDisabled}
                                      className="form-control react-form-input "
                                      placeholder={
                                        InjectPlaceHolderMessage(
                                          `profile.enter`,
                                          props?.LanguageSwitcher?.language
                                            ?.languageId
                                        ) +
                                        " " +
                                        InjectPlaceHolderMessage(
                                          `profile.name`,
                                          props?.LanguageSwitcher?.language
                                            ?.languageId
                                        )
                                      }
                                      // defaultValue={name}
                                      value={name}
                                      onChange={e => {
                                        if (e.target.value) {
                                          setErrorNameField(false);
                                          setName(e.target.value);
                                        } else {
                                          setErrorNameField(true);
                                          setName(e.target.value);
                                        }
                                      }}
                                    />

                                    {/* </UserProfileWrapper> */}
                                    {errorNameField ? (
                                      <ErrorUserProfile field="name" />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className="form-group"
                                    style={{ margin: "10px 0px 0px 0px " }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 14,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      <label>
                                        <IntlMessages id={"profile.email"} />
                                      </label>
                                    </span>
                                    <UserProfileWrapper>
                                      <input
                                        type="email"
                                        disabled
                                        style={{
                                          backgroundColor: "white !important"
                                        }}
                                        className="form-control react-form-input"
                                        aria-describedby="emailHelp"
                                        defaultValue={email}
                                        placeholder={
                                          InjectPlaceHolderMessage(
                                            `profile.enter`,
                                            props?.LanguageSwitcher?.language
                                              ?.languageId
                                          ) +
                                          " " +
                                          InjectPlaceHolderMessage(
                                            `profile.email`,
                                            props?.LanguageSwitcher?.language
                                              ?.languageId
                                          )
                                        }
                                      />
                                    </UserProfileWrapper>
                                  </div>
                                  <div
                                    className="form-group"
                                    style={{ margin: "10px 0px 0px 0px " }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 14,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      <label>
                                        <IntlMessages
                                          id={"profile.designation"}
                                        />

                                        <span
                                          style={{
                                            fontSize: 12,
                                            color: "#808080",
                                            fontWeight: 600
                                          }}
                                        >
                                          (
                                          <IntlMessages
                                            id={"profile.Optional"}
                                          />
                                          )
                                        </span>
                                      </label>
                                    </span>
                                    <input
                                      type="text"
                                      id="designation"
                                      disabled={isDisabled}
                                      className="form-control react-form-input "
                                      placeholder={
                                        InjectPlaceHolderMessage(
                                          `profile.enter`,
                                          props?.LanguageSwitcher?.language
                                            ?.languageId
                                        ) +
                                        " " +
                                        InjectPlaceHolderMessage(
                                          `profile.designation`,
                                          props?.LanguageSwitcher?.language
                                            ?.languageId
                                        )
                                      }
                                      defaultValue={designation}
                                      onChange={e => {
                                        if (e.target.value) {
                                          setErrorDesignationField(false);
                                          setDesignation(e.target.value);
                                        } else {
                                          setErrorDesignationField(true);
                                          setDesignation(e.target.value);
                                        }
                                      }}
                                    />
                                    {/* {errorDesignationField ? (
                                      <ErrorUserProfileDesignation field="designation" />
                                    ) : (
                                      ""
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                      {/* <div className="form-group">
                  <span style={{ fontSize: 14, fontWeight: "bold" }}>
                    <label>Name</label>
                  </span>
                  <input
                    style={{ border: "1px solid #E6E6E6 !important" }}
                    type="text"
                    id="name"
                    disabled={isDisabled}
                    className="form-control react-form-input"
                    placeholder="Enter name"
                    defaultValue={name}
                    onChange={e => {
                      if (e.target.value) {
                        setErrorNameField(false);
                        setName(e.target.value);
                      } else {
                        setErrorNameField(true);
                        setName(e.target.value);
                      }
                
                    }}
                  />
                  {errorNameField ? <ErrorUserProfile field="name" /> : ""}
                </div>
                <div className="form-group">
                  <span style={{ fontSize: 14, fontWeight: "bold" }}>
                    <label>Email</label>
                  </span>
                  <input
                    style={{ border: "1px solid #E6E6E6 !important" }}
                    type="email"
                    disabled
                    style={{ backgroundColor: "#ededed" }}
                    className="form-control react-form-input"
                    aria-describedby="emailHelp"
                    defaultValue={email}
                    placeholder="Enter email"
                  />
                </div> */}

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "20px"
                        }}
                      >
                        <UserProfileWrapper>
                          {isLoader ? (
                            <Loader height={"25px"} width={"20px"} />
                          ) : (
                            <button
                              type="submit"
                              // disabled={name?.length != 0 && name !== null  ? false : true}
                              disabled={name?.length == 0 ? true : false}
                              className="button btn "
                              style={{
                                width: "260px",
                                color: "white",
                                backgroundColor:
                                  name?.length != 0 && name !== null
                                    ? ""
                                    : "grey"
                              }}
                            >
                              <IntlMessages id={"profile.savebutton"} />
                            </button>
                          )}
                        </UserProfileWrapper>
                      </div>
                    </form>
                  </div>
                </div>
                <div>
                  <div
                    className="introduction mtb-6"
                    style={{
                      font: "normal normal bold 15px/20px Segoe UI",
                      color: "black"
                    }}
                  >
                    <IntlMessages id={"profile.password"} />
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ptb-35 plr-35  roe-card-style"
                    style={{ backgroundColor: "white" }}
                  >
                    <form onSubmit={handlechangePassword}>
                      <div className="form-group">
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          <label>
                            <IntlMessages id={"profile.oldpassword"} />
                          </label>
                        </span>
                        <input
                          style={{ border: "1px solid #E6E6E6 !important" }}
                          type="password"
                          id="oldPassword"
                          className="form-control react-form-input"
                          placeholder={InjectPlaceHolderMessage(
                            "profile.oldpassword",
                            props?.LanguageSwitcher?.language?.languageId
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorComponment field="oldPassword" />
                      </div>
                      <div className="form-group">
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          <label>
                            <IntlMessages id={"profile.newpassword"} />
                          </label>
                        </span>
                        <input
                          style={{ border: "1px solid #E6E6E6 !important" }}
                          type="password"
                          id="newPassword"
                          className="form-control react-form-input"
                          placeholder={InjectPlaceHolderMessage(
                            "profile.newpassword",
                            props?.LanguageSwitcher?.language?.languageId
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorComponment field="newPassword" />
                      </div>

                      <div className="form-group">
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          <label>
                            <IntlMessages id={"profile.confirmnewpassword"} />
                          </label>
                        </span>
                        <input
                          style={{ border: "1px solid #E6E6E6 !important" }}
                          type="password"
                          id="confirmPassword"
                          className="form-control react-form-input"
                          placeholder={InjectPlaceHolderMessage(
                            "profile.confirmnewpassword",
                            props?.LanguageSwitcher?.language?.languageId
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorComponment field="confirmPassword" />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <UserProfileWrapper>
                          {isPasswordLoader ? (
                            <Loader height={"25px"} width={"20px"} />
                          ) : (
                            <button
                              style={{
                                width: "260px",
                                marginTop: "20px",
                                color: "white",
                                backgroundColor:
                                  values.oldPassword?.length >= 8 &&
                                  values.newPassword?.length >= 8 &&
                                  values.confirmPassword?.length >= 8
                                    ? ""
                                    : "grey"
                              }}
                              type="submit"
                              className="button btn"
                              disabled={
                                values.oldPassword?.length >= 8 &&
                                values.newPassword?.length >= 8 &&
                                values.confirmPassword?.length >= 8
                                  ? false
                                  : true
                              }
                            >
                              <IntlMessages id={"profile.updatepassword"} />
                            </button>
                          )}
                        </UserProfileWrapper>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CustomNotification
          show={toast}
          message={toastMessage}
          closeNotification={() => setToast(false)}
        />
      </div>
      <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    LanguageSwitcher: { ...state.LanguageSwitcher }
  };
};
export default compose(
  withRouter,
  enhancer1,
  connect(mapStateToProps, {
    update_User,
    update_User_details,
    logout,
    changeLanguage
  })
)(UserProfile);
