import React, { useState } from "react";
import CustomAlert from "util/CustomAlert";
import Loader from "components/loader/Loader";
import IntlMessages from "util/intlMessages";
const SettingsTab = props => {
  const {
    archiveBusiness,
    isArchived,
    isOwner,
    isLoader,
    leaveWorkpace,
    isRemoveLoader,
    workspaceDetails
  } = props;
  const [isActionAlert, setActionAlert] = useState({
    status: false,
    action: 0
  });
  return (
    <div>
      <div className="tab-header">
        <div className="project-header-heading">{/*Settings*/}</div>
      </div>

      <div>
        {/*         
      <Row>
             <Col className="col-lg-9 col-md-11 col-sm-12 col-xs-12">
             </Col>

<Col className="col-lg-3 col-md-1 col-sm-12 col-xs-12"> */}
        {isOwner && (
          <div
            className="project-active-workspace  flex-x "
            style={{
              borderBottom: "1px solid #E6E6E6",
              paddingBottom: "20px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <div className="flex-1">
              <div className="mr-10 flex-1">
                <p
                  className=" fs-15 demi-bold-text"
                  style={{ fontWeight: 500, color: "black" }}
                >
                  {" "}
                  {isArchived ? (
                    <IntlMessages id={"workspace.unarchiveworkspace"} />
                  ) : (
                    <IntlMessages id={"workspace.archiveworkspace"} />
                  )}
                </p>
                <small
                  className="text-muted fs-14"
                  style={{ color: "#808080" }}
                >
                  {" "}
                  {isArchived ? (
                    <IntlMessages id={"workspace.archive_description1"} />
                  ) : (
                    <IntlMessages id={"workspace.archive_description"} />
                  )}
                </small>
              </div>
            </div>

            <div className="project-active-workspace-button ml-10 fs-14">
              <>
                {isLoader ? (
                  <Loader height="20px" width="16px" />
                ) : (
                  <button
                    type="button"
                    class="btn btn"
                    style={{
                      textDecoration: "none",
                      border: "1px solid #FF4331",
                      color: "#FF4331",
                      width:
                        props?.LanguageSwitcher?.language?.languageId ==
                        "english"
                          ? "200px"
                          : "203px",
                      height: "36px",
                      margin: "0px"
                    }}
                    onClick={() =>
                      setActionAlert({ status: true, action: "archive" })
                    }
                  >
                    {isArchived ? (
                      <IntlMessages id={"workspace.unarchiveworkspace"} />
                    ) : (
                      <IntlMessages id={"workspace.archiveworkspace"} />
                    )}
                  </button>
                )}
              </>
            </div>
          </div>
        )}

        {/* </Col>

</Row> */}
        {!isOwner && (
          <div
            className="flex-x"
            style={{
              paddingTop: 35,
              borderBottom: "1px solid #E6E6E6",
              paddingBottom: "20px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <div className="mr-10 flex-1">
              <p
                className=" fs-15 demi-bold-text"
                style={{ fontWeight: 500, color: "black" }}
              >
                <IntlMessages id={"workspace.leaveworkpsace"} />
              </p>
              <small className="text-muted fs-14" style={{ color: "#808080" }}>
                <IntlMessages id={"workspace.afterleaving"} />
              </small>
            </div>

            <div
              className="fs-14"
              onClick={() => setActionAlert({ status: true, action: "delete" })}
            >
              <div style={{ paddingTop: 0 }}>
                {isRemoveLoader ? (
                  <Loader height="20px" width="16px" />
                ) : (
                  <button
                    type="button"
                    // class="btn btn-outline-danger"
                    class="btn btn"
                    style={{
                      textDecoration: "none",
                      border: "1px solid #FF4331",
                      color: "#FF4331",
                      width: "200px",
                      height: "36px"
                    }}
                  >
                    <IntlMessages id={"workspace.leaveworkpsace"} />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <CustomAlert
        CustomMessage={workspaceDetails.title}
        PRIMARY_COLOR={props.topbarTheme.buttonColor}
        action={
          isActionAlert.action == "archive"
            ? isArchived
              ? "unarchive"
              : "archive"
            : "leave"
        }
        messageType="Workpsace"
        show={isActionAlert.status}
        onConfirm={() => {
          if (isActionAlert.action == "archive") {
            archiveBusiness();
            setActionAlert({ status: false, action: 0 });
          } else {
            leaveWorkpace();
            setActionAlert({ status: false, action: 0 });
          }
        }}
        // PRIMARY_COLOR={props.topbarTheme.buttonColor}
        onCancel={() => {
          setActionAlert({ status: false, action: 0 });
        }}
        confirmMessage={
          isActionAlert.action == "archive"
            ? isArchived
              ? "unarchive"
              : "archive"
            : "leave"
        }
      />
    </div>
  );
};

export default SettingsTab;
