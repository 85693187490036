import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TaskCommentList from "components/scrumboard/TaskactivityList";
import {
  profileBackground,
  profileImage
} from "helper/methods/getUserAvatarColor";
import {
  getTaskcomments,
  addcomment,
  deleteComment
} from "helper/services/taskServices";
import moment from "moment";
import GlobalWrapper from "./wrapper.style";
const TaskCommentModal = ({ isOpen, toggle, taskId, projectId, name }) => {
  const [taskComments, setTaskComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [isLoader, setIsloader] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (isOpen) {
      fetchComments();
    }
  }, [isOpen]);
  const AddComments = async e => {
    // e.preventDefault();
    if (e) {
      e.preventDefault();
    }
    if (newComment || fileToUpload) {
      const Response = await addcomment({
        attachment: fileToUpload,
        comment: newComment,
        taskId,
        projectId
      });
      if (Response) {
        if (Response.error) {
        } else {
          var comment = { taskComments };
          // comment[0].projectComment.push(Response.data);

          setNewComment(null);
          document.getElementById("commentInput").value = "";
          setFileToUpload(null);
          fetchComments();
        }
      }
    }
  };
  const Attachment = {
    border: "1 solid",
    width: 42,
    height: 41,
    objectFit: "fill",
    textAlign: "center"
  };
  const profileImage = {
    borderRadius: "50%",
    border: "1 solid",
    width: 42,
    height: 41,
    objectFit: "fill",
    textAlign: "center"
  };

  const InCap = {
    lineHeight: 2,
    fontSize: 20,
    color: "white",
    textAlign: "center"
  };
  const fetchComments = async () => {
    // const messagesEndRef = useRef(null)
    try {
      if (taskId) {
        const Response = await getTaskcomments(taskId);
        if (Response) {
          if (Response.error) {
          } else {
            let unsortedComments =
              Response.data.length > 0 && Response.data[0].taskComment;

            unsortedComments.sort((a, b) => a.id - b.id);

            //   Response.data.length > 0 && Response.data[0].taskComment
            setTaskComments(unsortedComments);
            window.scroll({
              top: document.body.offsetHeight,
              left: 0,
              behavior: "smooth"
            });

            // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })

            // document.getElementById("commentView").scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    } catch (e) {}
  };
  const handleDeleteComment = async commentId => {
    if (taskId && commentId) {
      const Response = await deleteComment(
        {
          project_id: projectId,
          task_id: taskId
        },
        commentId
      );
      if (Response) {
        if (Response.error) {
        }
        if (Response.message) {
          let comments = taskComments;
          comments.map((data, i) => {
            if (data.id == commentId) {
              comments.splice(i, 1);
            }
          });
          setTaskComments(comments);
          fetchComments();
        }
      }
    }
  };
  const checkIsOpen = () => {
    if (isOpen) {
      fetchComments();
    }
    return isOpen;
  };
  return (
    <div>
      <GlobalWrapper>
        <Modal isOpen={isOpen} toggle={() => toggle()} size="xl" centered>
          <ModalHeader toggle={() => toggle()}>{name}'s comments</ModalHeader>
          <ModalBody>
            <div
              className="container list-container div-fixed"
              style={{
                height: "460px",
                overflow: "auto"
              }}
              id="commentView"
              ref={messagesEndRef}
            >
              {taskComments
                ? taskComments.map((data, i) => {
                    return (
                      <div className="ptb-10 ma-0 flex-x align-center" key={i}>
                        <div className="">
                          <div
                            style={profileBackground(
                              data.commentBy.name && data.commentBy.name
                            )}
                          >
                            {data.commentBy.name &&
                              data.commentBy.name.charAt(0).toUpperCase()}
                          </div>
                        </div>
                        <div className="mlr-10 flex-1">
                          <p className="fs-14 demi-bold-text">
                            {data.commentBy.name}
                            <span style={{ paddingLeft: 5, color: "grey" }}>
                              {moment(data.createdAt).format(
                                "DD MMM YYYY HH:MM"
                              )}
                            </span>
                          </p>
                          <p className="text-muted">
                            {data.comment &&
                              data.comment !== "null" &&
                              data.comment}
                          </p>

                          {data.commentMeta && data.commentMeta !== null && (
                            <div>
                              <a href={data.commentMeta.url} target="_blank">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  <i
                                    className=" fas fa-paperclip"
                                    style={{ paddingRight: 5 }}
                                  />
                                  <p>{data.commentMeta.originalname}</p>
                                </div>
                              </a>
                            </div>
                          )}
                        </div>
                        <div className="task-icons">
                          <i
                            onClick={() => handleDeleteComment(data.id)}
                            className="fas fa-trash"
                          ></i>
                        </div>
                      </div>
                    );
                  })
                : ""}
              {/* <div /> */}
            </div>
            <form onSubmit={e => AddComments(e)}>
              <div
                style={{
                  border: "1px solid"
                }}
              >
                <div
                  className="text-area-block"
                  style={{ display: "flex", height: 38 }}
                >
                  <input
                    placeholder="Write something here..."
                    defaultValue={newComment || " "}
                    className="form-control message-text-area border-bottom-radius"
                    style={{
                      width: "100% !important",
                      display: "block",

                      border: 0,

                      paddingRight: 10
                    }}
                    id="commentInput"
                    onChange={e => setNewComment(e.target.value)}
                  ></input>

                  <span
                    style={{
                      padding: 8
                    }}
                  >
                    <label htmlFor="commentsAttachment">
                      <i className=" fas fa-paperclip fs-14"></i>
                    </label>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id="commentsAttachment"
                      name="commentsAttachment"
                      onChange={e => {
                        setFileToUpload(e.target.files[0]);
                      }}
                    />
                  </span>

                  <button
                    className="send-button c-btn border-bottom-radius"
                    type="submit"
                  >
                    <i className="fas fa-paper-plane"></i>
                  </button>
                </div>
                {fileToUpload && fileToUpload !== null && (
                  <div>
                    <label
                      style={{
                        padding: 10
                      }}
                    >
                      {fileToUpload.name}
                    </label>
                    <i
                      className=" fas fa-trash"
                      onClick={() => setFileToUpload(null)}
                    />
                  </div>
                )}
              </div>
            </form>
          </ModalBody>
        </Modal>
      </GlobalWrapper>
    </div>
  );
};

export default TaskCommentModal;
