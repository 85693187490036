import styled from "styled-components";

const TaskCommentCardWrapper = styled.div`
  .quill {
    min-height: 95px !important;
    max-height: 200px !important;
    border-radius: 4px !important;
    border: 1px solid #ebebeb !important;
  }
  .ql-editor {
    min-height: 110px !important;
    max-height: 165px !important;
  }
  .ql-toolbar.ql-snow {
    border: none !important;
  }
  .ql-container.ql-snow {
    border: none !important;
  }
  .ql-editor ql-blank {
    min-height: 95px !important;
    max-height: 200px !important;
  }
  .ql-toolbar {
    background-color: white !important;
  }
  .ql-editor {
    background-color: #f8f8f8 !important;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 30px;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 30px;
  }
  figure {
    display: inline-block;
    margin-block-start: 7px;
    margin-block-end: 0em;
    margin-inline-start: 10px;
    margin-inline-end: 0px;
  }
  pre {
    background-color: #eee;
    font-size: 0.9em;
    padding: 0.5em;
    padding-top: 0.7em;
    margin-bottom: 0em;
  }
`;

export default TaskCommentCardWrapper;
