import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import { CardTaskCalenderImage } from "helper/constant";
const ProjectDetailsPopover = ({
  isOpen,
  toggle,
  projectName,
  startsDate,
  endsDate
}) => {
  React.useEffect(() => {}, []);

  return (
    <div>
      <Popover
        trigger="legacy"
        className="roy-menu"
        innerClassName="roy-inner-content"
        placement="bottom-end"
        target="Upopover"
        hideArrow
        isOpen={isOpen}
        toggle={toggle}
      >
        <PopoverBody>
          <div
            title={projectName}
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #EBEBEB",
              padding: "10px 15px"
            }}
          >
            <i
              className="fa fa-circle fs-10 pr-15"
              style={{ color: "black" }}
            />{" "}
            <div
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: 170,
                display: "inline-block",
                whiteSpace: "nowrap"
              }}
            >
              {" "}
              {projectName}
            </div>
          </div>
          <div style={{ padding: "15px 10px" }}>
            <div style={{ display: "flex", alignItems: "center", padding: 5 }}>
              <img
                alt=""
                src={CardTaskCalenderImage}
                style={{
                  height: "16px",
                  width: "15px",
                  marginRight: 10
                }}
              ></img>
              Starts: {startsDate}
            </div>
            <div style={{ display: "flex", alignItems: "center", padding: 5 }}>
              <img
                alt=""
                src={CardTaskCalenderImage}
                style={{
                  height: "16px",
                  width: "15px",
                  marginRight: 10
                }}
              ></img>
              Ends: {endsDate}
            </div>
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default ProjectDetailsPopover;
