import React, { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { getColorCode, ProjectColors } from "components/projects/utils/color";
import classNames from "classnames";
import ShimmerLoader from "components/shimmer/BusinessListShimmer";
import IntlMessages from "util/intlMessages";
const ProjectDropdown = ({
  setSelectedProject,
  selectedProject,
  type,
  projectData,
  isLoader
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState([]);

  const changeSelection = (workspace, project) => {
    setSelectedProject({
      workspace,
      project
    });
  };
  const handleSearch = query => {
    if (!query) {
      setSearchQuery(projectData);
    }
    if (query) {
      try {
        var result =
          projectData &&
          projectData.filter(val =>
            val.name.toUpperCase().includes(query.toUpperCase())
          );

        setSearchQuery([...result]);
      } catch (e) {}
    }
  };
  return (
    <ButtonDropdown
      style={{ width: "100%" }}
      isOpen={dropdownOpen}
      className="c-outline-light"
      toggle={() => setDropdownOpen(!dropdownOpen)}
    >
      <DropdownToggle
        // caret
        style={{
          backgroundColor: "transparent",
          width: "100%",
          textTransform: "capitalize",
          border: "1px solid #d8d8d8",
          borderRadius: 6,
          fontSize: 14,
          color: "grey",
          padding: 0,
          height: 37,
          paddingLeft: 8,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          textAlign: "left"
        }}
        className={"form-control"}
      >
        <div
          className="flex-x"
          style={{ paddingRight: 6, color: "#808080", fontSize: "14px" }}
        >
          <div className="flex-1">
            {projectData ? (
              <>
                {selectedProject ? (
                  <>
                    {" "}
                    <svg
                      data-svgs-path="sm1/calendar_small.svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="grey"
                        fill-rule="nonzero"
                        d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1.25 7a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5zm.75-5a.5.5 0 1 1 0 1h-7a.5.5 0 0 1 0-1h7z"
                      ></path>
                    </svg>{" "}
                    {selectedProject.workspace.title} /{" "}
                    <i
                      className="fa fa-circle fs-10  grey--text"
                      style={{
                        color: getColorCode(selectedProject.project.color),
                        paddingRight: "8px"
                      }}
                    />
                    {selectedProject && selectedProject.project?.name}
                  </>
                ) : (
                  <IntlMessages id={"globalquickaddactions.selectproject1"} />
                )}
              </>
            ) : (
              <IntlMessages id={"globalquickaddactions.selectproject1"} />
            )}
          </div>
          {/* <div className="flex-x">
            <i className="fa fa-angle-down fs-20" style={{ paddingRight: 8 }} />
          </div> */}
        </div>
      </DropdownToggle>
      <DropdownMenu>
        {isLoader ? (
          <div>
            <ShimmerLoader />
          </div>
        ) : (
          <div style={{ height: 250, overflow: "scroll" }}>
            {projectData &&
              projectData.map((workspace, i) => {
                return (
                  <div style={{ width: 456 }}>
                    <div
                      style={{
                        textTransform: "capitalize",
                        paddingLeft: 5
                      }}
                    >
                      <svg
                        data-svgs-path="sm1/calendar_small.svg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="grey"
                          fill-rule="nonzero"
                          d="M12 2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8zm0 1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1.25 7a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5zm.75-5a.5.5 0 1 1 0 1h-7a.5.5 0 0 1 0-1h7z"
                        ></path>
                      </svg>{" "}
                      {workspace.title}{" "}
                      {workspace &&
                        selectedProject &&
                        selectedProject.project.id == workspace.id && (
                          <i
                            className={classNames(
                              "fas fa-check-double",
                              "ml-15"
                            )}
                          ></i>
                        )}
                    </div>
                    {workspace &&
                    workspace.project &&
                    workspace.project.length > 0 ? (
                      workspace.project.map((project, j) => {
                        return (
                          <DropdownItem
                            key={j}
                            onClick={() => changeSelection(workspace, project)}
                          >
                            <div
                              style={{
                                textTransform: "capitalize",
                                paddingLeft: 5
                              }}
                              className="flex-x"
                            >
                              <div className="flex-1">
                                <i
                                  className="fa fa-circle  fs-8  grey--text"
                                  style={{
                                    color: getColorCode(project.color),
                                    paddingRight: "8px"
                                  }}
                                />
                                {project.name}
                              </div>
                              <div>
                                {workspace &&
                                  selectedProject &&
                                  selectedProject.project.id == project.id && (
                                    <i
                                      className={classNames(
                                        "fas fa-check-double"
                                      )}
                                    ></i>
                                  )}
                              </div>
                            </div>
                          </DropdownItem>
                        );
                      })
                    ) : (
                      <div
                        style={{ paddingLeft: 25 }}
                        className="text-muted fs-12"
                      >
                        No project in {workspace?.title}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default ProjectDropdown;
