import { swicthBusiness } from "helper/services/businessServices";
import {
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import { getProject, getLables } from "helper/services/projectServices";
import { handleRecentWorkspace } from "util/RecentWorkspace";
import { store } from "redux/store";
const updateProjectandLabel = async token => {
  fecthProject(token);
  fetchLabels(token);
};
const updateToken = (token, businessName, businessMeta, id) => {
  store.dispatch({
    type: "UPDATE_TOKEN",
    token
  });
  // props.update_token(token);
  store.dispatch({
    type: "DEFAULT_BUSINESS",
    id: id,
    businessName: businessName,
    businessMeta: businessMeta
  });
  // props.default_business({
  //   id: id,
  //   businessName: businessName,
  //   businessMeta: businessMeta
  // });
  fecthProject(token);
  fetchLabels(token);

  store.dispatch({
    type: "UPDATE_BUSINESS",
    business: {
      businessId: id,
      businessName,
      businessMeta
    }
  });
  // props.update_business({ businessId: id, businessName, businessMeta });
};

export const handleSwitchWorkspace = async (
  id,
  handleRedirectionCB,
  showNotifications,
  currentData
) => {
  try {
    const Response = await swicthBusiness(id);
    if (Response) {
      if (Response.data) {
        await updateToken(
          Response.data.token,
          Response.data.business.title,
          Response.data.business?.businessMeta
            ? Response.data.business?.businessMeta
            : null,
          Response.data.business.id
        );
        handleRecentWorkspace(Response.data.business);
        handleRedirectionCB(Response.data.business.id);
        updateProjectandLabel(Response.data.token);
        store.dispatch({
          type: "UPDATE_RECENT_PROJECT",
          project_id: ""
        });
        // props.update_recent_Project({
        //   project_id: ""
        // });
        return {
          status: true
        };
      }
      if (Response.error) {
        showNotifications(Response.error.message, "error");
        return {
          status: false,
          error: Response.error
        };
      }
    }
  } catch (e) {
    return {
      status: false,
      error: e
    };
  }
};
const fetchLabels = async token => {
  try {
    const Response = await getLables(token);
    if (Response) {
      if (Response.data) {
        store.dispatch({
          type: "SET_LABELS",
          labels: Response.data
        });
        // props.set_labels({ labels: Response.data });
        updateSidebarLabelToSidebar(Response.data);
      }
      if (Response.error) {
      }
    }
  } catch (e) {}
};

const fecthProject = async token => {
  const Response = await getProject(token);
  if (Response) {
    store.dispatch({
      type: "SET_PROJECTS",
      projects: Response.data
    });
    // props.set_projects({ projects: Response.data });
    updateSidebarProjectToSidebar(Response.data);
  }
};
// store.dispatch({
//   type: "LOGOUT"
// });
