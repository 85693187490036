/** @format */

import React, { useEffect, useState } from "react";
import TaskAllAttachmentsImage from "components/TaskDetails/TaskAllAttachmentsImage";
import { getAttachment } from "helper/services/projectServices";
import ToastMessage from "components/notifications/ToastMessage";
import { useToasts } from "react-toast-notifications";
import { connect } from "react-redux";
import { searchNotFound } from "helper/constant";
import OneLineShimmer from "components/shimmer/OneLineShimmer.jsx";
import AllAttachmentsShimmer from "components/shimmer/AllAttachmentsShimmer.jsx";
import alertActions from "redux/globalAlert/actions";
import projectActions from "redux/projects/actions";
import TaskAlert from "components/TaskDetails/Alerts/TaskAlert";
import LinearLoader from "components/loader/LinearLoader";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";

const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;
const { update_projects_existing, remove_Project } = projectActions;
const ProjectAttachments = props => {
  const { addToast } = useToasts();
  const [attachments, setAttachments] = useState([]);
  const [isFetchingAttachment, setIsFetchingAttachment] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [projectAttachment, setProjectAttachment] = useState("");
  const [project, setProject] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryResult, setSearchQueryResult] = useState("");
  const [isTaskAlert, setIsTaskAlert] = useState(false);
  const tabs = ["task", "project"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [pageInfo, setPageInfo] = useState({
    task: {
      currentPage: 0,
      totalPages: 0,
      isLastPage: false,
      isInitiated: false,
      isNextPageLoader: false
    },
    project: {
      currentPage: 0,
      totalPages: 0,
      isLastPage: false,
      isInitiated: false,
      isNextPageLoader: false
    }
  });
  const toggletab = id => {
    setSearchQuery("");
    setActiveTab(id);
    if (!pageInfo[id].isInitiated) {
      retrieveAttachments(id);
    }
  };

  const projectId = props.match.params.id;
  useEffect(() => {
    retrieveAttachments(activeTab);
    return () => {
      setAttachments([]);
      setIsFetchingAttachment(false);
      setPageInfo({
        task: {
          currentPage: 0,
          totalPages: 0,
          isLastPage: false,
          isInitiated: false,
          isNextPageLoader: false
        },
        project: {
          currentPage: 0,
          totalPages: 0,
          isLastPage: false,
          isInitiated: false,
          isNextPageLoader: false
        }
      });
    };
  }, []);
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };

  const getPageInfo = (id = projectId, type) => {
    let payload = {};
    payload = { id };
    let activetabId = type || activeTab;
    if (activetabId === tabs[0]) {
      payload = {
        ...payload,
        ...pageInfo.task,
        type: "task"
      };
    }
    if (activetabId === tabs[1]) {
      payload = {
        ...payload,
        ...pageInfo.project,
        type: "project"
      };
    }
    return payload;
  };

  const retrieveAttachments = async (type, isNextPage = false) => {
    try {
      let payload = getPageInfo(projectId, type);
      let nextpage = payload.currentPage + 1;
      let limit = 6;

      if (nextpage) {
        if (type === tabs[0]) {
          setPageInfo({
            ...pageInfo,
            task: {
              ...pageInfo.task,
              isNextPageLoader: true
            }
          });
        }
        if (type === tabs[1]) {
          setPageInfo({
            ...pageInfo,
            project: {
              ...pageInfo.project,
              isNextPageLoader: true
            }
          });
        }
      }
      !isNextPage && setIsFetchingAttachment(true);

      const { data, error } = await getAttachment(
        payload.id,
        payload.type,
        nextpage,
        limit
      );
      if (error) {
        setIsFetchingAttachment(false);
        showNotifications();
      }
      if (data) {
        setProject(data?.project);
        if (type === "project") {
          setProjectAttachment(
            isNextPage
              ? [...projectAttachment, ...data.projectAttachment]
              : [...data.projectAttachment]
          );
          setPageInfo({
            ...pageInfo,
            project: {
              ...pageInfo.project,
              currentPage: parseInt(data.currentPage),
              totalPages: data.totalPages,
              isLastPage: data.isLastPage,
              isInitiated: nextpage === 1 ? true : pageInfo.project.isInitiated,
              isNextPageLoader: false
            }
          });
        }
        if (type === "task") {
          setAttachments(
            isNextPage
              ? [...attachments, ...data.taskAttachment]
              : [...data.taskAttachment]
          );
          setPageInfo({
            ...pageInfo,
            task: {
              ...pageInfo.task,
              currentPage: parseInt(data.currentPage),
              totalPages: data.totalPages,
              isLastPage: data.isLastPage,
              isInitiated: nextpage === 1 ? true : pageInfo.task.isInitiated,
              isNextPageLoader: false
            }
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetchingAttachment(false);
    }
  };
  const handleSearch = e => {
    setSearchQuery(e.target.value);
    const searchquerydata = e.target.value.trim();
    if (searchquerydata && searchquerydata?.length > 0) {
      setIsSearch(true);
      let filteredTodos;
      let attachmentsarr =
        activeTab === tabs[0] ? attachments : projectAttachment;
      filteredTodos = attachmentsarr.filter(tasks => {
        return (
          tasks.originalname
            .toLowerCase()
            .indexOf(searchquerydata.toLowerCase()) !== -1
        );
      });

      setSearchQueryResult(filteredTodos);
    }
    if (!searchquerydata) {
      setIsSearch(false);
      setSearchQueryResult(null);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
      <div
        className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          padding: 15
        }}
      >
        {!isFetchingAttachment ? (
          <>
            {project && (
              <>
                <span
                  title={project.title}
                  style={{
                    textDecoration: "underline",
                    color: "black",
                    cursor: "pointer",
                    maxWidth: 300,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "inline-block"
                  }}
                  onClick={() => props.history.push(`/project/${project.id}`)}
                >
                  {project && project.title}
                </span>
              </>
            )}
            &nbsp; &gt;&nbsp;
            <span
              style={{
                textDecoration: "underline",
                color: "black",
                maxWidth:
                  project && project.id && project.title ? "35%" : "75%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "inline-block"
              }}
            >
              <IntlMessages id={"project.attachments"} />
            </span>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%"
            }}
          >
            <div
              style={{
                textAlign: "center",
                width: "80%"
              }}
            >
              <OneLineShimmer />
            </div>
          </div>
        )}
      </div>
      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>

      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
      <div
        className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"
        style={{
          width: "100%",
          minHeight: "70vh",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px 0px",
          marginBottom: "100px"
        }}
      >
        <div style={{ textAlign: "center" }}>
          <ul className="ul-no-margin">
            <li
              style={{ marginLeft: 0 }}
              className={`workspace-action-nav-items ${
                activeTab === tabs[0] ? "tab-active " : ""
              }`}
              onClick={() => toggletab(tabs[0])}
            >
              <span style={{ fontWeight: 500 }}>
                <div>
                  <IntlMessages id={"project.taskattachments"} />{" "}
                </div>
              </span>
            </li>
            <li
              className={`workspace-action-nav-items ${
                activeTab === tabs[1] ? "tab-active " : ""
              }`}
              onClick={() => toggletab(tabs[1])}
            >
              <span style={{ fontWeight: 500 }}>
                <div>
                  <IntlMessages id={"project.projectattachments"} />
                </div>
              </span>
            </li>
          </ul>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            boxShadow: "0px 3px 6px #00000029"
          }}
        >
          {isFetchingAttachment ? (
            <AllAttachmentsShimmer />
          ) : (
            <div
              className="TaskDetailsContainer"
              style={{ margin: "0px 65px", paddingTop: 1 }}
            >
              <div className="add-comment-input-block mt-40">
                <input
                  autoFocus
                  className="add-subtask-input inputStyle"
                  name="checklistTitle"
                  rows="1"
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#F5F5F5"
                  }}
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder={InjectPlaceHolderMessage(
                    "project.search_in_all_the_files_posted_in_this_thread",
                    props?.LanguageSwitcher?.language?.languageId
                  )}
                />
              </div>
              <div
                className="row"
                style={{
                  margin: "30px 0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start"
                }}
              >
                {isSearch && (
                  <>
                    {searchQueryResult && searchQueryResult?.length > 0 ? (
                      <>
                        <div className="row">
                          {searchQueryResult.map(comment => {
                            return (
                              <>
                                <div
                                  className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12"
                                  style={{
                                    margin: "5px 0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                  }}
                                >
                                  <TaskAllAttachmentsImage
                                    pageType={
                                      comment?.taskComment ? "task" : "project"
                                    }
                                    handleRedirect={() => {
                                      if (comment?.taskComment) {
                                        props.history.push(
                                          `/task-details/${comment?.taskComment.task.id}`
                                        );
                                      } else {
                                        props.history.push(
                                          `/project/${comment?.projectComment.project.id}`,
                                          { commentModal: true }
                                        );
                                      }
                                    }}
                                    comment={{
                                      commentMeta: {
                                        url: comment.url,
                                        originalname: comment.originalname,
                                        size: comment.size,
                                        mimetype: comment.mimetype,
                                        location: comment.location,
                                        id: comment.id,
                                        createdAt: comment.createdAt,
                                        bucket: comment.bucket
                                      },
                                      commentedAt: {
                                        ...comment.createdAt
                                      },
                                      commentBy: comment?.taskComment
                                        ? { ...comment?.taskComment.commentBy }
                                        : {
                                            ...comment?.projectComment.commentBy
                                          }
                                    }}
                                  />
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          padding: 50,
                          display: "flex",
                          justifyContent: "center",
                          color: "#000",
                          wordBreak: "break-all"
                        }}
                      >
                        {<IntlMessages id={"project.Notfindsearch"} />}{" "}
                        {searchQuery}
                      </div>
                    )}
                  </>
                )}
                {!isSearch && activeTab === tabs[0] && (
                  <>
                    {" "}
                    {attachments.length > 0 ? (
                      <>
                        <div className="row">
                          {attachments.map(comment => {
                            return (
                              <>
                                <div
                                  className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12"
                                  style={{
                                    margin: "5px 0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                  }}
                                >
                                  <TaskAllAttachmentsImage
                                    pageType="task"
                                    handleRedirect={() => {
                                      if (comment?.taskComment) {
                                        props.history.push(
                                          `/task-details/${comment?.taskComment.task.id}`
                                        );
                                      }
                                    }}
                                    isFetchingAttachment={isFetchingAttachment}
                                    comment={{
                                      commentMeta: {
                                        url: comment.url,
                                        originalname: comment.originalname,
                                        size: comment.size,
                                        mimetype: comment.mimetype,
                                        location: comment.location,
                                        id: comment.id,
                                        createdAt: comment.createdAt,
                                        bucket: comment.bucket
                                      },
                                      commentedAt: comment.createdAt,
                                      commentBy: {
                                        ...comment?.taskComment.commentBy
                                      }
                                    }}
                                  />
                                </div>
                              </>
                            );
                          })}
                        </div>
                        {!pageInfo.task.isLastPage && (
                          <div className="col-12 text-center">
                            <div
                              style={{
                                color: props.PRIMARY_COLOR,
                                fontSize: 14,
                                textDecoration: pageInfo.task.isNextPageLoader
                                  ? "none"
                                  : "underline",
                                textAlign: "center",
                                cursor: pageInfo.task.isNextPageLoader
                                  ? "not-allowed"
                                  : "pointer",
                                paddingBottom: 10
                              }}
                            >
                              {pageInfo.task.isNextPageLoader ? (
                                <LinearLoader />
                              ) : (
                                <div
                                  style={{
                                    height: 100,
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "2.09rem"
                                  }}
                                  onClick={() =>
                                    !pageInfo.task.isLastPage
                                      ? retrieveAttachments(tabs[0], true)
                                      : {}
                                  }
                                >
                                  <IntlMessages id={"project.Viewmore"} />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          display: "block",
                          margin: "210px auto ",
                          width: "100%",
                          height: "100%",
                          textAlign: "center"
                        }}
                      >
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <img
                              alt="not found"
                              src={searchNotFound}
                              height="230px"
                              width="450px"
                            />
                            <div style={{ marginTop: "0px" }}>
                              {" "}
                              {activeTab === tabs[0] ? (
                                <IntlMessages id={"Task"} />
                              ) : (
                                <IntlMessages id={"Project"} />
                              )}{" "}
                              <IntlMessages
                                id={"project.relatedattchmentshowhere"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {!isSearch && activeTab === tabs[1] && (
                  <>
                    {" "}
                    {projectAttachment.length > 0 ? (
                      <>
                        <div className="row">
                          {projectAttachment.map(comment => {
                            return (
                              <>
                                <div
                                  className=" col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xs-1 col-12"
                                  style={{
                                    margin: "5px 0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                  }}
                                >
                                  <TaskAllAttachmentsImage
                                    pageType="project"
                                    handleRedirect={() => {
                                      if (comment?.projectComment) {
                                        props.history.push(
                                          `/project/${comment?.projectComment.project.id}`,
                                          { commentModal: true }
                                        );
                                      }
                                    }}
                                    isFetchingAttachment={isFetchingAttachment}
                                    comment={{
                                      commentMeta: {
                                        url: comment.url,
                                        originalname: comment.originalname,
                                        size: comment.size,
                                        mimetype: comment.mimetype,
                                        location: comment.location,
                                        id: comment.id,
                                        createdAt: comment.createdAt,
                                        bucket: comment.bucket
                                      },
                                      commentedAt: comment.createdAt,
                                      commentBy: {
                                        ...comment?.projectComment.commentBy
                                      }
                                    }}
                                  />
                                </div>
                              </>
                            );
                          })}
                        </div>
                        {!pageInfo.project.isLastPage && (
                          <div className="col-12 text-center">
                            <div
                              style={{
                                color: props.PRIMARY_COLOR,
                                fontSize: 14,
                                textDecoration: pageInfo.project
                                  .isNextPageLoader
                                  ? "none"
                                  : "underline",
                                textAlign: "center",
                                cursor: pageInfo.project.isNextPageLoader
                                  ? "not-allowed"
                                  : "pointer",
                                paddingBottom: 10
                              }}
                            >
                              {pageInfo.project.isNextPageLoader ? (
                                <LinearLoader />
                              ) : (
                                <div
                                  style={{
                                    height: 100,
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "2.09rem"
                                  }}
                                  onClick={() =>
                                    !pageInfo.project.isLastPage
                                      ? retrieveAttachments(tabs[1], true)
                                      : {}
                                  }
                                >
                                  View more
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          display: "block",
                          margin: "210px auto ",
                          width: "100%",
                          height: "100%",
                          textAlign: "center"
                        }}
                      >
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <img
                              alt="not found"
                              src={searchNotFound}
                              height="230px"
                              width="450px"
                            />
                            <div style={{ marginTop: "0px" }}>
                              {" "}
                              {activeTab === tabs[0] ? (
                                <IntlMessages id={"Task"} />
                              ) : (
                                <IntlMessages id={"Project"} />
                              )}{" "}
                              <IntlMessages
                                id={"project.relatedattchmentshowhere"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <TaskAlert
        show={isTaskAlert}
        onConfirm={() => {
          setIsTaskAlert(!isTaskAlert);
          props.history.push("/today");
        }}
        onCancel={() => {
          setIsTaskAlert(!isTaskAlert);
        }}
        PRIMARY_COLOR={props.PRIMARY_COLOR}
      />
      <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state,
    businessId: state.auth.user?.businessId,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    PRIMARY_COLOR: state.themeChanger.topbarTheme.buttonColor
  };
};

export default connect(mapStateToProps, {
  open_workpsace_alert,
  open_project_alert,
  close_alert,
  update_projects_existing,
  remove_Project
})(ProjectAttachments);
