const fileDropActions = {
  ONFILEDRAG: "ON_FILE_DRAG",
  ONFILEDRAGLEAVE: "ON_FILE_DRAG_LEAVE",
  ONFILEDROP: "ON_FILE_DROP",
  ENABLE_FILE_DROP: "ENABLE_FILE_DROP",
  DISABLE_FILE_DROP: "DISABLE_FILE_DROP",

  onFileDragEvent: data => {
    return {
      type: fileDropActions.ONFILEDRAG
    };
  },
  onFileDragLeaveEvent: data => {
    return {
      type: fileDropActions.ONFILEDRAGLEAVE
    };
  },
  onFileDropEvent: data => {
    return {
      type: fileDropActions.ONFILEDROP,
      isDrag: false,
      fileName: { Filetype: data }
    };
  },

  doEnableFileDropEvent: data => {
    return {
      type: fileDropActions.ENABLE_FILE_DROP
    };
  },
  doDisableFileDropEvent: data => {
    return {
      type: fileDropActions.DISABLE_FILE_DROP
    };
  }
};

export default fileDropActions;
