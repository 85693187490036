import { labelsnew } from "helper/constant";
import { filternew, taskActivity } from "helper/constant";
import { projectsnew, PtrojectPopOverFavouriteImage } from "helper/constant";
import { upcomingnew, todayIcon } from "helper/constant";

export var sidebarData = [
  // {
  //   name: "sidebar.dashboard",
  //   routepath: "/dashboard",
  //   iconClass: "fas fa-chalkboard"
  // },
  // {
  //   name: "sidebar.inbox",
  //   routepath: "/inbox",
  //   iconClass: "fas fa-inbox"
  // },
  {
    name: "sidebar.today",
    routepath: "/today",
    iconClass: "far fa-calendar-alt",
    src: todayIcon
  },
  {
    name: "sidebar.upcoming",
    routepath: "/upcoming",
    iconClass: "fa fa-calendar",
    src: upcomingnew
  },
  {
    name: "sidebar.favourites",
    iconClass: "fa fa-circle fs-10",

    child: [
      // {
      //   listname: "sidebar.projects",
      //   routepath: "/projects",
      //   shortname: "RF"
      // }
    ],
    src: PtrojectPopOverFavouriteImage
  },
  {
    name: "sidebar.projects",
    iconClass: "fa fa-circle fs-10",

    child: [
      // {
      //   listname: "sidebar.projects",
      //   routepath: "/projects",
      //   shortname: "RF"
      // }
    ],
    src: projectsnew
  },
  {
    name: "sidebar.labels",
    iconClass: "fa fa-tag",

    child: [
      // {
      //   listname: "sidebar.regularforms",
      //   routepath: "/project/board/2",
      //   shortname: "RF"
      // }
    ],
    src: labelsnew
  },
  {
    src: filternew,
    name: "sidebar.filters",
    iconClass: "fa fa-filter",

    child: [
      {
        listname: "Priority 1",
        routepath: "/priority/1",
        shortname: "P1",
        color: "red"
      },
      {
        listname: "Priority 2",
        routepath: "/priority/2",
        shortname: "P2",
        color: "orange"
      },
      {
        listname: "Priority 3",
        routepath: "/priority/3",
        shortname: "P3",
        color: "blue"
      },
      {
        listname: "Priority 4",
        routepath: "/priority/4",
        shortname: "P4",
        color: "green"
      }
    ]
  },
  {
    name: "sidebar.activity",
    routepath: `/activity`,
    iconClass: "far fa-calendar-alt",
    src: taskActivity
  }
];

// Comments:::::::

//  If you want one level child then look below example

/*
  {
    name: 'sidebar.forms',
    iconClass: 'fa fa-circle fs-10',
    child: [
      {
        listname: 'sidebar.regularforms',
        routepath: '/regularform',
        shortname: 'RF'
      }
    ]
  }
*/

//  If you want Second level child then look below example

/*
   {
      name: 'sidebar.pages',
      iconClass: 'fas fa-print',
      child: [
        {
          listname: 'sidebar.authentication',
          iconClass: 'fas fa-user',
          child: [
            {
              listname: 'sidebar.login',
              routepath: '/login',
              shortname: 'L'
            },
          ]
        }
      ]
    }
*/

export const HorizontalSidebarData = [
  {
    name: "sidebar.intro",
    routepath: "/inbox",
    iconClass: "fas fa-chalkboard"
  }
];

// ### For Horizontal sidebar

//     <!-- Basics -->
//         {
//             name: "sidebar.single",
//             iconClass: "fab fa-stripe-s",
//             routepath: "/single"
//         }
//     <!-- One Level -->
//         {
//             name: "sidebar.onelevel",
//             iconClass: "fas fa-expand",
//             child: [
//                 {
//                     name: "sidebar.example",
//                     routepath: "/ex",
//                 }
//             ]
//         }
//     <!-- Second level -->
//         {
//             name: "sidebar.secondlevel",
//             iconClass: "fas fa-expand",
//             child: [
//                 {
//                     name: "sidebar.example",
//                     iconClass: "fas fa-plus",
//                     child: [
//                         {
//                             name: "sidebar.example1",
//                             routepath: "/ex1",
//                         },
//                         {
//                             name: "sidebar.example2",
//                             routepath: "/ex2",
//                         }
//                     ]
//                 }
//             ]
//         }

export const updateSidebarProjectToSidebar = ResponseData => {
  sidebarData.map((data, i) => {
    if (data.name === "sidebar.projects") {
      return (
        ResponseData &&
        ResponseData?.map((a, i) => {
          if (!checkforunique(data.child, a)) {
            if (a.name !== "Inbox" && a.isDeleted === false) {
              data.child.push({
                id: a.id,
                listname: a.name,
                routepath: `/project/${a.id}`,
                color: a.color
              });
            }
          }
        })
      );
    }
  });
};

export const updateSidebarLabelToSidebar = ResponseData => {
  sidebarData.map((data, i) => {
    if (data.name === "sidebar.labels") {
      ResponseData.map((a, i) => {
        data.child = [];
        if (!checkforunique(data.child, a)) {
          data.child.push({
            id: a.id,
            listname: a.name,
            routepath: `/label/${a.id}`,
            color: a.color
          });
        }
      });
    }
  });
};

export const checkforunique = (datachild, toCheckValue) => {
  datachild.map((data, i) => {
    if (data.id === toCheckValue.id) {
      return true;
    }
    return false;
  });
};
