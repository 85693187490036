import {
  LockScreen,
  Login,
  Register,
  GoogleAuth,
  Error400,
  ForgotPassword,
  Error500,
  EmailVerification
} from "./../views/pages/index";
import Landing from "views/pages/landing/index";
const publicRoutesLogin = [
  { path: "/", component: Landing },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/email-verification/:token", component: EmailVerification }
  // { path: "/register/:email", component: Register },
];

export default publicRoutesLogin;
