import React, { useEffect, useState } from "react";
import { toggleTaskStatus } from "helper/services/taskServices";
import {
  commentnew,
  ListGridCircleImage,
  ListGridCheckImage,
  iconTag,
  datesnewmodal
} from "helper/constant";
import { getColorCode } from "util/data/ProjectColors";
import IconTint from "react-icon-tint";
import { ReactComponent as RepeatSvgIcon } from "assets/images/recurrenceIcon.svg";
import TaskAssignee from "components/assignee/TaskAssignee";
import profileModelAction from "redux/profileModel/actions";
import { repeatTypeOptions } from "util/data/taskDetailsutilData";
import { getPriorityPath } from "constatnt";
import { allPriority } from "constatnt";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
const { open_profile_modal } = profileModelAction;
const SingleTaskList = props => {
  const { task, handleRedirection, doToggleTaskStatus, i, todoLength } = props;
  const { addToast } = useToasts();
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    return () => {
      setIsLoader(false);
    };
  }, []);
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };

  const doUpdateTaskStatus = async id => {
    if (id) {
      try {
        setIsLoader(true);
        const { message, error } = await toggleTaskStatus(id);
        if (message) {
          if (message == "Task Completed") {
            doToggleTaskStatus && doToggleTaskStatus(id, true);
          } else {
            doToggleTaskStatus && doToggleTaskStatus(id, false);
          }
        }
        if (error) {
          showNotifications();
        }
      } catch (e) {
      } finally {
        setIsLoader(false);
      }
    }
  };
  if (task) {
    return (
      <div
        key={i}
        className="flex-x  plr-30  roe-card-style todo-board"
        style={{
          background: "white",
          borderTopRightRadius: i == 0 ? "10px" : "0px",
          borderTopLeftRadius: i == 0 ? "10px" : "0px",
          borderBottomRightRadius: todoLength - 1 == i ? "10px" : "0px",
          borderBottomLeftRadius: todoLength - 1 == i ? "10px" : "0px",
          paddingTop: i == 0 ? "20px" : "0px",
          paddingBottom: todoLength - 1 == i ? "20px" : "0px"
        }}
      >
        <div
          className="flex-1"
          style={{
            display: "flex",
            cursor: "pointer",
            paddingBottom: todoLength - 1 == i ? "0px" : "10px",
            paddingTop: i == 0 ? "0px" : "10px",
            borderBottom: todoLength - 1 == i ? "" : "1px solid #ebebeb"
          }}
        >
          <div
            title={task.isCompleted ? "open this task" : "complete this task"}
            //   className=" pretty p-icon p-round p-smooth"
            style={{
              marginRight: 10,
              display: "flex",
              alignItems: "start"
            }}
            onClick={() => doUpdateTaskStatus(task.id)}
          >
            <img
              alt="task status"
              src={
                isLoader || task.isCompleted
                  ? ListGridCheckImage
                  : ListGridCircleImage
              }
              style={{
                height: 16,
                width: 16,
                opacity: isLoader ? 0.5 : 1,
                cursor: "pointer",
                marginTop: 6
              }}
            ></img>
          </div>
          <div className="flex-x" style={{ cursor: "pointer" }}>
            {/* <div className="flex-x">
            <div className="flex-1">
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <div>
                 
                </div>
              </div>
            </div>
          </div> */}

            <div className="flex-x flex-wrap align-center fs-14">
              <div
                className="flex-1"
                style={{
                  display: "inline-flex",
                  flexWrap: "wrap"
                }}
              >
                <span
                  className="All_Task_Title fs-14"
                  style={{
                    maxWidth: "35vw",
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 500,
                    margin: "3px 25px  3px 0px",
                    wordBreak: "break-word",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    lineClamp: 1,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    WebkitLineClamp: 1,
                    WebkitFontSmoothing: "antialiased"
                  }}
                  onClick={() =>
                    handleRedirection &&
                    handleRedirection({
                      redirection_type: "task",
                      project: task.project,
                      business: task.project.business,
                      task
                    })
                  }
                >
                  {/* {data.title} */}
                  {task.title}
                </span>
                {task && task.priority && task.priority != "no_priority" && (
                  <>
                    {allPriority.map(item => {
                      if (item.name == task.priority) {
                        return (
                          <>
                            <div
                              style={{
                                margin: "3px 25px  3px 0px"
                                // marginTop: 10
                              }}
                              onClick={() =>
                                handleRedirection &&
                                handleRedirection({
                                  redirection_type: "task",
                                  project: task.project,
                                  business: task.project.business,
                                  task
                                })
                              }
                            >
                              <img
                                src={getPriorityPath(task.priority)}
                                height="17"
                                width="18"
                                className=""
                                alt="Priority"
                                style={{
                                  position: "relative",
                                  top: "-2px"
                                }}
                              />
                              <span
                                style={{
                                  cursor: "pointer",
                                  padding: "0px 6px",
                                  color: "gray"
                                }}
                              >
                                {item.value}
                              </span>
                            </div>
                          </>
                        );
                      }
                    })}
                  </>
                )}

                {task.labels &&
                  task.labels.map((item, index) => {
                    return (
                      <>
                        <div
                          style={{
                            margin: "3px 25px  3px 0px"
                            // marginTop: 10
                          }}
                          onClick={() =>
                            handleRedirection &&
                            handleRedirection({
                              redirection_type: "task",
                              project: task.project,
                              business: task.project.business,
                              task
                            })
                          }
                        >
                          <div
                            className="my-day-label flex-x mr-6 fs-14 nevy--text align-center overflow-hidden"
                            key={index}
                          >
                            <IconTint
                              src={iconTag}
                              style={{
                                position: "relative",
                                top: "1px",
                                height: "18",
                                width: "18"
                              }}
                              color={getColorCode(item.color)}
                            />

                            <span
                              style={{
                                color: "black",
                                paddingLeft: 5,
                                maxWidth: "300px",
                                whiteSpace: "nowrap",
                                textOverflow: " ellipsis",
                                overflow: "hidden"
                              }}
                            >
                              {item.name}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}

                <div
                  style={{
                    margin: "3px 25px  3px 0px"
                    // marginTop: 10
                  }}
                  onClick={() =>
                    handleRedirection &&
                    handleRedirection({
                      redirection_type: "task",
                      project: task.project,
                      business: task.project.business,
                      task
                    })
                  }
                >
                  <img
                    alt="task comment"
                    src={commentnew}
                    style={{
                      height: "18px",
                      width: "15px",
                      marginRight: "5px",
                      textAlign: "center"
                    }}
                  ></img>
                  <span
                    style={
                      {
                        // marginTop: 10
                      }
                    }
                  >
                    {task.commentCount}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                  onClick={() =>
                    handleRedirection &&
                    handleRedirection({
                      redirection_type: "task",
                      project: task.project,
                      business: task.project.business,
                      task
                    })
                  }
                >
                  {task.scheduledAt && (
                    <img
                      alt=""
                      src={datesnewmodal}
                      height="15px"
                      width="15px"
                      style={{ marginRight: 5 }}
                    />
                  )}
                  {task.scheduledAt &&
                    task.repeatType !== repeatTypeOptions[0].value && (
                      <RepeatSvgIcon
                        style={{
                          marginRight: 5,
                          fill: "green",
                          height: "16px",
                          width: "15px"
                        }}
                      />
                    )}
                  {task.scheduledAt && (
                    <span
                      className="due-date  label"
                      style={{ margin: "3px 25px  3px 0px" }}
                    >
                      <IntlMessages id={"task-details.dueon"} />
                      &nbsp;
                      {moment(new Date(task.scheduledAt)).format("DD MMM YYYY")}
                    </span>
                  )}
                </div>
                <div
                  style={{
                    display: "contents",
                    alignItems: "center"
                    // marginTop: 10
                  }}
                >
                  {task.taskAssign &&
                    task.taskAssign.map(item => (
                      <span
                        onClick={() => {
                          props.open_profile_modal({ profile: item });
                        }}
                      >
                        <TaskAssignee taskAssign={item} />
                      </span>
                    ))}
                </div>
                {/* Self assigned task - Frontend */}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return "";
};

export default connect(null, { open_profile_modal })(SingleTaskList);
