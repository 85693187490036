import React, { useState, useEffect } from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { updateLabel } from "helper/services/labelsServices";
import { Alert } from "reactstrap";
import { ProjectColors } from "components/projects/utils/color";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import ColorDropdown from "components/projects/ColorDropdown";
import { updateBusiness } from "services/socket";
import { businessSocketRoomPrefix } from "constatnt";
const EditLabel = ({
  modal,
  setmodal,
  project,
  setEditLabelModal,
  setProject,
  updateLabelToRedux,
  topbarTheme,
  businessId
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedColor, setSelectedColor] = useState(ProjectColors[0].value);
  const getcolor = colorname => {
    // let color = null;
    // ProjectColors.filter((data, i) => {
    //   if (data.label == colorname) {
    //     color = data;
    //     return data;
    //   }
    // });
    // return color;

    const filteredColors = ProjectColors.filter(data => {
      return data.label === colorname;
    });

    if (filteredColors.length > 0) {
      const color = filteredColors[0];
      return color;
      // Now, 'color' holds the first element that matches the condition.
      // You can use 'color' as needed.
    } else {
      // Handle the case when no matching element is found.
    }
  };

  useEffect(() => {
    if (modal) {
      if (project) {
        setSelectedColor(project.color);
      }
    }
  }, [modal]);
  const handleLabelSubmit = async e => {
    e.preventDefault();
    setVisible(false);
    if (e.target.name.value && project && project.id) {
      setIsLoader(true);
      try {
        const Response = await updateLabel(
          {
            name: e.target.name.value,
            color: selectedColor,
            isFavorite: false
          },
          project.id
        );
        if (Response) {
          setIsLoader(false);
          if (Response.error) {
            setVisible(true);
            setErrorMessage(Response.error.message);
          } else if (Response.errors) {
            setVisible(true);
            setErrorMessage(Response.errors.message);
          } else {
            setVisible(false);
            if (Response.data) {
              setProject(Response.data);
              let payload = {
                roomname: `${businessSocketRoomPrefix}${businessId}`
              };
              updateBusiness(payload, cb => {
                if (cb.status == "Ok") {
                }
              });
              updateLabelToRedux(Response.data);
              setEditLabelModal();
              // sidebarData.map((data, i) => {
              //   if (data.name === "sidebar.projects") {
              //     data.child.map(childdata => {
              //       if (childdata.id === Response.data.id) {
              //         childdata.listname = Response.data.name;
              //         childdata.color = Response.data.color;
              //       }
              //     });
              //   }
              // });
            }
          }
        }
      } catch (e) {
        setIsLoader(false);
      }
    }
  };
  return (
    <Modal
      isOpen={modal}
      //  toggle={() => setEditLabelModal()}
      centered
    >
      <form onSubmit={handleLabelSubmit}>
        <ModalHeader toggle={() => setEditLabelModal()}>Edit Label</ModalHeader>
        <Alert
          className="c-"
          style={{ backgroundColor: "RED ", color: "white" }}
          isOpen={visible}
          toggle={() => setVisible(false)}
        >
          {visible ? errorMessage : ""}
        </Alert>
        <ModalBody>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              // onChange={(e) => {
              //   e.target.value.length > 0
              //     ? setIsDisabled(false)
              //     : setIsDisabled(true);
              // }}
              placeholder="Name"
              defaultValue={project && project.name}
            />
          </div>
          <div className="form-group">
            <label>Color</label>
            <ColorDropdown
              options={ProjectColors}
              id="projectcolor"
              value={selectedColor}
              changeColor={value => setSelectedColor(value)}
            />
          </div>

          <br />
          {/* <div className="">
            <label>View</label>
            <br />
            <div className="pretty p-default p-curve">
              <input
                type="radio"
                name="viewoption"
                defaultChecked={
                  project && project.defaultView == "list" ? true : false
                }
                id="viewoption"
                value="list"
              />
              <div className="state p-primary-o">
                <label className="fs-14 demi-bold-text">List</label>
              </div>
            </div>
            <div className="pretty p-default p-curve">
              <input
                type="radio"
                name="viewoption"
                id="viewoption"
                defaultChecked={
                  project && project.defaultView == "list" ? false : true
                }
                value="grid"
              />
              <div className="state p-primary-o">
                <label className="fs-14 demi-bold-text">Grid</label>
              </div>
            </div>
          </div> */}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => setEditLabelModal()}
            style={{ background: "#FFF", border: 0, color: "#000" }}
          >
            Cancel
          </Button>
          {isLoader ? (
            <Loader height="24px" width="20px" />
          ) : (
            <Button
              type="submit"
              className="button btn"
              style={{
                backgroundColor: topbarTheme.buttonColor,
                border: "none"
              }}
              disabled={isDisabled}
            >
              Update
            </Button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    businessId: state.auth.user.businessId,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, null)(EditLabel);
