import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import ReactQuill, { Quill } from "react-quill";
import { connect } from "react-redux";
import Radium from "radium";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import { PRIMARY_COLOR } from "constatnt";
import MentionPicker from "components/projects/comments/MentionPickerComment";
import CommentEditorWrapper from "components/projects/comments/Input/CommentEditorWrapper";
import IndexEditorWrapper from "./CustomEditorWrapper.style";
import CustomEditorWrapper from "./CustomEditorWrapper.style";
import Loader from "components/loader/Loader";
import is from "date-fns/locale/is/index";
import ImageResize from "quill-image-resize-module-react";
import ImageCompress from "quill-image-compress";
import IntlMessages from "util/intlMessages";

// Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/imageCompress", ImageCompress);

var userCollabList = [];
const hashValues = [];

function CustomEditor({
  value,
  placeholder,
  onChangeValue,
  toggleInput,
  topbarTheme,
  collaboratorsList,
  handleSubmitData,
  isLoaderButton,
  onConfirmText
}) {
  // in some component where the editor is used
  const [description, setDescription] = useState("");
  const [isEmptySpace, setIsEmptySpace] = useState(false);

  useEffect(() => {
    if (collaboratorsList) {
      var collabs = [];
      collaboratorsList.map((data, i) => {
        if (data.user) {
          collabs.push({
            id: data.user.id,
            username: data.user.name,
            value: data.user.name
          });
        }
      });
      userCollabList = collabs;

      // userCollabList = collaboratorsListArr;
      // ReactQuill.getEditor().focus();
    }
    return () => {};
  }, []);
  // Autofocus method
  const useFocusAndSetRef = ref => {
    ref = useCallback(
      node => {
        if (node !== null) {
          ref.current = node; // it is not done on it's own
          const len = value.length;
          const selection = { index: len, length: len };
          node.setEditorSelection(node.editor, selection);
        }
      },
      [ref]
    );

    return ref;
  };

  let editorRef;
  editorRef = useFocusAndSetRef(editorRef);
  const handleSubmit = () => {
    if (!isLoaderButton && isEmptySpace) {
      handleSubmitData(description);
    }
  };
  const handleKeyDown = e => {
    if (e.shiftKey && e.keyCode == 13) {
      handleSubmit();
    }
  };
  // async function imageHandler() {
  //   const input = document.createElement('input');

  //   input.setAttribute('type', 'file');
  //   input.setAttribute('accept', 'image/*');
  //   input.click();

  //   input.onchange = async () => {
  //     var file = input.files[0];
  //     var formData = new FormData();

  //     formData.append('image', file);

  //     var fileName = file.name;

  //     // const res = await this.uploadFiles(file, fileName, quillObj);
  //   };
  // }
  return (
    <>
      <CustomEditorWrapper>
        <div className="mt-10 quill-editor">
          <ReactQuill
            // handlers={{
            //   image: imageHandler()
            // }}
            ref={editorRef}
            autoFocus
            value={value}
            onChange={(content, delta, source, editor) => {
              onChangeValue(content);
              setDescription(content);
              if (editor.getText().match(/^\s+$/) == null) {
                setIsEmptySpace(true);
              } else {
                setIsEmptySpace(false);
              }
            }}
            theme={"snow"}
            placeholder={placeholder}
            modules={CustomEditor.modules}
            formats={CustomEditor.formats}
            onKeyDown={e => handleKeyDown(e)}
            bounds={".app"}
            // onBlur={(e, s, delta, sss) => {
            //   // if source s==silent then allow to copy else if it is user than toggle input
            //   if (s !== "silent") {
            //     toggleInput();
            //   }
            // }}
          />
        </div>
      </CustomEditorWrapper>
      <div style={{ margin: "10px 0px", display: "flex" }}>
        {isLoaderButton ? (
          <Loader height={25} width={20} />
        ) : (
          <>
            <button
              className="c-btn"
              style={{
                fontWeight: "100",
                border: "1px solid #dedede",
                textAlign: "center",
                color: "#fff",
                // backgroundColor:
                //   value && value.length > 0 && isEmptySpace
                //     ? topbarTheme.buttonColor
                //     : topbarTheme.buttonColor,
                backgroundColor: topbarTheme.buttonColor,
                boxShadow: "none",
                borderRadius: 19
              }}
              // disabled={value.length > -1 && value != "<p><br></p>" ? false : true}
              disabled={value.length > 0 && isEmptySpace ? false : true}
              onClick={handleSubmit}
            >
              {onConfirmText ? (
                onConfirmText
              ) : (
                <IntlMessages id={"profile.savebutton"} />
              )}
            </button>
          </>
        )}

        <button
          className="btn btn"
          style={{
            boxShadow: "none",
            marginLeft: 10,
            border: `1px solid ${topbarTheme.buttonColor}`,
            color: topbarTheme.buttonColor,
            borderRadius: 17
          }}
          onClick={() => {
            toggleInput();
            onChangeValue("");
            setDescription("");
          }}
        >
          <IntlMessages id={"project.cancel"} />
        </button>
      </div>
    </>
  );
}
// export default CardTask;
CustomEditor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike", "blockquote", "link"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["image"],
    [
      {
        // adding new color increase width in style.css in current directry for to keep in one row otherwise it will move to next row
        color: ["#F00", "#0F0", "#00F", "#000" /* "color-picker" */]
      }
    ]
  ],
  imageCompress: {
    keepImageTypes: ["image/jpeg", "image/png"], // default
    quality: 0.7, // default
    maxWidth: 300, // default
    maxHeight: 500, // default
    debug: true, // default
    suppressErrorLogging: false // default
  },

  //   imageResize: {
  //     parchment: Quill.import('parchment'),
  //     modules: ['Resize', 'DisplaySize','Toolbar']
  //  },

  clipboard: {
    matchVisual: false
  },
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    source: function(searchTerm, renderList, mentionChar) {
      let values;
      if (mentionChar === "@") {
        values = userCollabList;
      }
      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    }
  }
};
// CustomEditor.keyboard({
//   key: 'B',
//   shortKey: true
// }, function(range, context) {
//   CustomEditor.formatText(range, 'bold', true);
// });

// addBinding may also be called with one parameter,
// in the same form as in initialization
// CustomEditor.keyboard.addBinding({ key: 'B', shiftKey: true }, handler);

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */

CustomEditor.formats = [
  "autofocus",
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "mention",
  "color"
];

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {})(Radium(CustomEditor));
