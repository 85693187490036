import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import ChangeWorkspaceAlert from "util/ChangeWorkspaceAlert";
import { HeaderDownArrowImage } from "helper/constant";
import {
  getBusinessList,
  swicthBusiness
} from "helper/services/businessServices";
import {
  sidebarData,
  checkforunique,
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import { getProject, getLables } from "helper/services/projectServices";
import { handleRecentWorkspace } from "util/RecentWorkspace";

import { withRouter } from "react-router-dom";
import labelActions from "redux/labels/actions";
import projectActions from "redux/projects/actions";
import businessActions from "redux/business/actions";
import AuthActions from "redux/auth/actions";
import FavouritesPopover from "./FavouritesPopover";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import IntlMessages from "util/intlMessages";

const { update_token, update_business } = AuthActions;
const { default_business } = businessActions;
const { set_labels, update_Label } = labelActions;
const { set_projects, update_recent_Project } = projectActions;
const GlobalFavouritesSelection = props => {
  const { addToast } = useToasts();
  const {
    history,
    toggleFavourites,
    PRIMARY_COLOR,
    setIsFavouritesSidebar,
    isFavouritesSidebar
  } = props;
  const [isFavourites, setIsFavourites] = useState(false);
  const [isAlertAction, setIsAlertAction] = useState({
    status: false,
    id: "",
    isTempClosePopover: false
  });
  const [isAlertActionLoader, setIsAlertActionLoader] = useState(false);
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const updateProjectandLabel = async token => {
    fecthProject(token);
    fetchLabels(token);
  };
  const updateToken = (token, businessName, businessMeta, id) => {
    props.update_token(token);
    props.default_business({
      id: id,
      businessName: businessName,
      businessMeta: businessMeta
    });
    fecthProject(token);
    fetchLabels(token);
    // updateUser(token);
    props.update_business({ businessId: id, businessName, businessMeta });
  };
  const fetchLabels = async token => {
    try {
      const Response = await getLables(token);
      if (Response) {
        if (Response.data) {
          props.set_labels({ labels: Response.data });
          updateSidebarLabelToSidebar(Response.data);
        }
        if (Response.error) {
        }
      }
    } catch (e) {}
  };

  const fecthProject = async token => {
    const Response = await getProject(token);
    if (Response) {
      props.set_projects({ projects: Response.data });
      updateSidebarProjectToSidebar(Response.data);
    }
  };
  const selectBusiness = async id => {
    try {
      setIsAlertActionLoader(true);
      const Response = await swicthBusiness(id);
      if (Response) {
        if (Response.data) {
          await updateToken(
            Response.data.token,
            Response.data.business.title,
            Response.data.business?.businessMeta
              ? Response.data.business?.businessMeta
              : null,
            Response.data.business.id
          );
          handleRecentWorkspace(Response.data.business);

          updateProjectandLabel(Response.data.token);
          props.update_recent_Project({
            project_id: isAlertAction.data.id
          });
          setIsAlertAction({
            id: "",
            status: false,
            isTempClosePopover: false
          });
          props.history.push(`/project/${isAlertAction.data.id}`);
          toggle();
          // handleRedirection(isAlertAction.data);
        }
        if (Response.error) {
          showNotifications(Response.error.message, "error");
          setIsAlertAction({
            id: "",
            status: false,
            isTempClosePopover: false
          });
        }
        setIsAlertActionLoader(false);
      }
    } catch (e) {
      console.log(e);

      setIsAlertActionLoader(false);
    }
  };
  const toggle = () => {
    setIsFavourites(false);
  };
  const handleRedirection = data => {
    const projectId = data.id,
      businessId = data.business.id;

    if (props.auth.user.businessId == businessId) {
      props.history.push(`/project/${projectId}`);
      setIsAlertAction({ data: "", status: false, isTempClosePopover: false });
      toggle();
    } else {
      setIsAlertAction({ status: true, data: data, isTempClosePopover: true });
    }
  };

  const getMessageType = item => {
    return (
      <>
        <IntlMessages id={"notification.projectbelongs"} />
        {item.business.title ? <b>{item.business.title}</b> : "different"}{" "}
        <IntlMessages id={"notification.switchworkspaceprojectdetails"} />
      </>
    );
  };
  return (
    <>
      <div className="Workspace-name-header pl-33 ">
        <div
          id="global-header-favourites"
          style={{
            backgroundColor: "rgb(255 255 255 / 18%)",
            width: "120px",
            height: "36px",
            display: "flex",
            borderRadius: "5px",
            cursor: "pointer"
          }}
          onClick={() => {
            setIsFavourites(!isFavourites);
          }}
        >
          <span
            style={{
              display: "inline-block",
              alignItems: "center",
              padding: "6px 12px",
              color: "white",
              fontSize: "14px",
              fontWeight: 600,
              width: 95,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            <IntlMessages id={"header.favourites"} />
          </span>

          <span
            style={{
              display: "flex",
              alignItems: "center",
              color: "white",
              fontSize: "14px",
              fontWeight: 600,
              paddingLeft: 0
            }}
          >
            <img
              src={HeaderDownArrowImage}
              style={{
                height: 13,
                width: 13
              }}
            ></img>
          </span>
        </div>
      </div>
      <FavouritesPopover
        handleRedirection={handleRedirection}
        isAlertAction={isAlertAction}
        setIsAlertAction={setIsAlertAction}
        isAlertActionLoader={isAlertActionLoader}
        setIsAlertActionLoader={setIsAlertActionLoader}
        toggleFavourites={toggleFavourites}
        PRIMARY_COLOR={PRIMARY_COLOR}
        history={history}
        isOpen={isFavourites || isFavouritesSidebar}
        toggle={() => {
          setIsFavourites(!isFavourites);
        }}
        target={"global-header-favourites"}
        // currentWorkspaceId={props?.auth ? props?.auth.user.businessId : ""}
        setIsFavouritesSidebar={setIsFavouritesSidebar}
        isFavouritesSidebar={isFavouritesSidebar}
        setIsFavourites={setIsFavourites}
        isFavourites={isFavourites}
      />
      <ChangeWorkspaceAlert
        loader={isAlertActionLoader}
        action="Switch Workspace"
        message={isAlertAction.status && getMessageType(isAlertAction.data)}
        PRIMARY_COLOR={PRIMARY_COLOR}
        show={isAlertAction.data && isAlertAction.status}
        onConfirm={() => {
          isAlertAction.status &&
            selectBusiness(isAlertAction.data.business.id);
        }}
        onCancel={() =>
          setIsAlertAction({ id: "", status: false, isTempClosePopover: false })
        }
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    PRIMARY_COLOR: state.themeChanger.topbarTheme.buttonColor
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, {
    update_token,
    update_business,
    default_business,
    set_labels,
    update_Label,
    set_projects,
    update_recent_Project
  })
)(GlobalFavouritesSelection);
