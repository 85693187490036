/** @format */

import moment from "moment";
import React, { useState, useEffect } from "react";
import DatepickerWrapper from "./datepicker.style";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import Loader from "components/loader/Loader";
import CustomAlert from "util/CustomAlert";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import {
  alarmnew,
  labelnew,
  assignnew,
  singleCheckIcon
} from "helper/constant";
import "./modal.style.css";
import "./style.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledPopover,
  PopoverBody
} from "reactstrap";
import Switch from "react-switch";
import NewTaskDescription from "./NewTaskDescription";
import "./quilleditor.style.css";
import classNames from "classnames";
import {
  allPriority,
  projectSocketRoomPrefix,
  getPriorityPath
} from "constatnt";
import NewTaskInput from "./NewTaskInput";
import { getColorCode } from "components/projects/utils/color";
import { connect } from "react-redux";
import RoyTooltip from "components/common/RoyTooltip";
import WorkspaceNestedItem from "./WorkspaceNestedItem";
import AssigneeDropdown from "./AssigneeDropdown";
import { getBusinessProject } from "helper/services/businessServices";
import AddLabel from "components/Labels/AddLabel";
import { sidebarData } from "util/data/sidebar";
import labelActions from "redux/labels/actions";
import { useToasts } from "react-toast-notifications";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import ToastMessage from "../notifications/ToastMessage";
import AddProject from "components/projects/AddProject";
import {
  checkforunique,
  updateSidebarProjectToSidebar
} from "util/data/sidebar";
import projectActions from "redux/projects/actions";
import ApiCalendar from "react-google-calendar-api";
import { addTaskInit, joinRoom, projectInit, addTask } from "services/socket";
import LabelsPopover from "components/Labels/LabelsPopover";
import NewTaskDescriptionWrapper from "./NewTaskDescriptionWrapper.style";
import NewTaskInputWrapper from "./NewTaskInputWrapper.style";
import { randomUUID } from "helper/methods";
import IntlMessages from "util/intlMessages";
import { de } from "date-fns/locale";
import { nl } from "date-fns/locale";

const { update_Label } = labelActions;
const { update_Project, update_recent_Project } = projectActions;
const NewTask = props => {
  const {
    modal,
    setmodal,
    showNotifications,
    refreshTodoList,
    defaultTodayDate,
    defaultUpcommingDate
  } = props;
  const { AllowAddPreviousDateTask } = props?.auth?.user?.userSetting
    ? props.auth.user.userSetting
    : "";
  const [newTaskInput, setNewTaskInput] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isLoaderWorkspace, setIsLoaderWorkspace] = useState(false);
  const [newTaskDescription, setNewTaskDescription] = useState("");
  const [prioritySelection, setPrioritySelection] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [isWorkspaceError, setIsWorkspaceError] = useState(false);
  const [userAssigned, setUserAssigned] = useState(null);
  const [isTaskAssignDropdown, setIsTaskAssignDropdown] = useState(false);

  const [labelSelection, setLabelSelection] = useState(false);
  const [taskPriority, setTaskPriority] = useState("no_priority");
  const [taskLabels, setTaskLabels] = useState([]);
  const [dueDate, setDueDate] = useState(null);
  const [dueDateOnChangeSet, setDueDateOnChangeSet] = useState(null);

  const [workspaceData, setWorkspaceData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [remindersAt, setRemindersAt] = useState("");
  const [remindersAtOnChangeSet, setRemindersAtOnChangeSet] = useState("");

  const [fetchingProjectCollab, setFetchingProjectCollab] = useState(false);
  const [syncGoogle, setSyncGoogle] = useState(false);
  const [defaultWorkspaceId, setDefaultWorkspaceId] = useState("");
  const [isCustomAlert, setIsCustomAlert] = useState(false);

  const [isNewTaskModleClick, setIsNewTaskModleClick] = useState(false);
  const todayDate = new Date();
  const sectionDummyData = [
    {
      id: 2112,
      title: "Done"
    },
    {
      id: 2111,
      title: "Someday"
    },
    {
      id: 2110,
      title: "In Progress"
    },
    {
      id: 2109,
      title: "Waiting On"
    },
    {
      id: 2108,
      title: "To-Do"
    }
  ];
  // const [task, setTask] = useState();
  const [locale, setLocale] = useState();
  const [addLabelModal, setAddLabelModal] = useState(false);
  const [projectModal, setProjectModal] = useState(false);
  const [labelsArr, setLabelsArr] = useState([]);
  const [projectCollaborators, setProjectCollaborators] = useState([]);
  const [googleCalendarModal, setGoogleCalendarModal] = useState({
    status: false,
    data: ""
  });

  const [isDueDateCalender, setIsDueDateCalender] = useState(false);

  const [isRemindersDateCalender, setIsRemindersDateCalender] = useState(false);
  const [isRemindersDateAtCalender, setIsRemindersDateAtCalender] = useState(
    false
  );

  const getRoundOfMinute = (givenDate = new Date()) => {
    try {
      const start = moment(givenDate);
      const remainder = 15 - (start.minute() % 15);
      const dateTime = moment(start).add(remainder, "minutes");

      return new Date(dateTime);
    } catch (E) {
      console.log(E);
    }
  };
  const { addToast } = useToasts();
  const handleAuthGoogle = async (event, name) => {
    if (name === "sign-in") {
      ApiCalendar.handleAuthClick();
    } else if (name === "sign-out") {
      ApiCalendar.handleSignoutClick();
    }
  };
  const SyncTasktoGoogle = async taskData => {
    // list upcomming
    // if (ApiCalendar.sign)
    //   ApiCalendar.listUpcomingEvents(10).then(({ result }) => {
    //
    //   });
    if (taskData)
      var eventFromNow = {
        summary: taskData.title.toString(),
        description: taskData.description.toString(),
        start: {
          dateTime: moment(new Date(taskData.scheduledAt)).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          }),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        end: {
          dateTime: moment(new Date(taskData.scheduledAt)).set({
            hour: 24,
            minute: 0,
            second: 0,
            millisecond: 0
          }),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      };
    // ApiCalendar.setCalendar()
    ApiCalendar.createEvent(eventFromNow)
      .then(result => {
        if (result.error) {
          addToast(ToastMessage(result.error.message), {
            appearance: "error",
            autoDismiss: true
          });
        }
        addToast(ToastMessage("Sync to Google"), {
          appearance: "success",
          autoDismiss: true
        });
        return true;
      })
      .catch(error => {
        addToast(
          ToastMessage(
            <IntlMessages id={"task-details.something_went_wrong"} />
          ),
          {
            appearance: "error",
            autoDismiss: true
          }
        );
      });
  };
  const { topbarTheme, labels } = props;

  useEffect(() => {
    if (modal) {
      setNewTaskDescription("");
      defaultTodayDate && setDueDate(new Date());
      if (defaultUpcommingDate) {
        setDueDateOnChangeSet(new Date(defaultUpcommingDate));
        setDueDate(new Date(defaultUpcommingDate));
      }
      retriveBusinessProjects();
    }
    if (props.language.locale == "de") {
      setLocale(de);
    } else if (props.language.locale == "nl") {
      setLocale(nl);
    } else {
      setLocale(props.language.locale);
    }

    return () => {
      setDueDateOnChangeSet("");
      setDefaultWorkspaceId("");
      setNewTaskInput("");
      setIsLoader("");
      setIsTaskAssignDropdown(false);
      // setNewTaskDescription("");
      setPrioritySelection(false);
      setSelectedWorkspace("");
      setSelectedProject("");
      setUserAssigned("");
      setDueDate("");
      setLabelSelection(false);
      setTaskPriority("no_priority");
      setTaskLabels("");
      setWorkspaceData("");
      setProjectData("");
      setIsWorkspaceError("");
      setRemindersAt("");
      setLabelsArr([]);
      setSyncGoogle(false);
      setGoogleCalendarModal({ status: false, data: "" });
      setProjectCollaborators([]);
    };
  }, [modal, props.language.locale]);

  const ids = taskLabels && taskLabels.map(a => a.id);
  const onChangeNewTask = data => {
    setNewTaskInput(data);
  };
  const retriveBusinessProjects = async () => {
    try {
      setIsLoaderWorkspace(true);
      const Response = await getBusinessProject();
      if (Response) {
        if (Response.data) {
          let businessArr = [];
          await Promise.all(
            Response.data &&
              Response.data.map(a => {
                businessArr.push(a.business);
                handleDefaultProjectSelection(a.business);
              })
          );

          businessArr =
            businessArr &&
            businessArr.sort(data => {
              if (props.auth?.user?.businessId === data.id) return -1;
              else if (props.auth?.user?.businessId !== data.id) return 1;
              else return 0;
            });

          setWorkspaceData([...businessArr]);
        }
        if (Response.error) {
        }
        setIsLoaderWorkspace(false);
      }
    } catch (e) {
      setIsLoaderWorkspace(false);
    }
  };
  const getSortedFirstIndex = section => {
    let data = section && section.sort((a, b) => a.id - b.id);

    return data;
  };
  const handleDefaultProjectSelection = business => {
    if (props.auth.user.businessId === business.id) {
      if (business.project && business.project?.length > 0) {
        if (
          props.projects.recent_project.project_id &&
          props.projects.recent_project.project_id !== ""
        ) {
          let status = false;
          business.project &&
            business.project.map(project => {
              if (
                project.id ===
                parseInt(props.projects?.recent_project.project_id)
              ) {
                status = true;

                let selectedprojectData = "";

                selectedprojectData = {
                  workspace: {
                    ...business,
                    value: business.title,
                    label: business.title
                  },
                  project: {
                    ...project,
                    value: project.name,
                    label: project.name
                  }
                };
                setProjectCollaborators([...project.collaborator]);
                if (project && project.section.length > 0) {
                  let sectionArr = getSortedFirstIndex(project.section);
                  selectedprojectData = {
                    ...selectedprojectData,
                    section: {
                      ...sectionArr[0],
                      value: sectionArr[0].title,
                      label: sectionArr[0].title
                    }
                  };
                }

                setSelectedProject(selectedprojectData);
              }
            });
          if (!status) {
            let selectedprojectData = "";
            selectedprojectData = {
              workspace: {
                ...business,
                value: business.title,
                label: business.title
              }
            };
            if (business.project?.length > 0) {
              selectedprojectData = {
                ...selectedprojectData,
                project: {
                  ...business.project[0],
                  value: business.project[0].name,
                  label: business.project[0].name
                }
              };
              setProjectCollaborators([...business.project[0].collaborator]);
            }
            if (business.project[0].section.length > 0) {
              let sectionArr = getSortedFirstIndex(business.project[0].section);
              selectedprojectData = {
                ...selectedprojectData,
                section: {
                  ...sectionArr[0],
                  value: sectionArr[0].title,
                  label: sectionArr[0].title
                }
              };
            }

            setSelectedProject(selectedprojectData);
          }
        } else {
          let selectedprojectData = "";
          selectedprojectData = {
            workspace: {
              ...business,
              value: business.title,
              label: business.title
            }
          };
          if (business.project?.length > 0) {
            selectedprojectData = {
              ...selectedprojectData,
              project: {
                ...business.project[0],
                value: business.project[0].name,
                label: business.project[0].name
              }
            };
            setProjectCollaborators([...business.project[0].collaborator]);
          }

          if (business.project[0]?.section.length > 0) {
            let sectionArr = getSortedFirstIndex(business.project[0].section);
            selectedprojectData = {
              ...selectedprojectData,
              section: {
                ...sectionArr[0],
                value: sectionArr[0].title,
                label: sectionArr[0].title
              }
            };
          }

          setSelectedProject(selectedprojectData);
        }
      }
    }
  };
  const onChangeDescription = data => {
    setNewTaskDescription(data);
  };
  const togglePrioritySelection = () => {
    setPrioritySelection(!prioritySelection);
  };

  const updateProjectToRedux = data => {
    props.update_Project(data);
  };
  const addNewProjectToWorkspaceArr = async newProject => {
    var arr = [...workspaceData];
    (await arr) &&
      arr.map((business, i) => {
        if (business.id == newProject?.business?.id) {
          var projectArr = arr[i].project.length > 0 ? [...arr[i].project] : [];
          projectArr.push({
            collaborator: [],
            ...newProject
          });
          return (arr[i].project = [...projectArr]);
        }
      });

    setWorkspaceData([...arr]);
  };
  const handlePostAddProject = data => {
    if (props?.auth?.user?.businessId === data.business.id) {
      updateProjectToRedux(data);
    }
    addNewProjectToWorkspaceArr(data);
    selectProjectandSection(data);
  };
  const selectProjectandSection = data => {
    if (data) {
      try {
        let sectionArr = getSortedFirstIndex(data.section);
        setSelectedProject({
          workspace: data.business,
          project: {
            ...data,
            value: data.name,
            title: data.title
          },
          section: {
            ...sectionArr[0],
            value: sectionArr[0].title,
            title: sectionArr[0].title
          }
        });
      } catch (e) {}
    }
  };
  const ScheduleDateCalendar = props => {
    const { className, children } = props;
    return (
      <div>
        <CalendarContainer>
          <div></div>
          <div style={{ position: "relative" }} className={className}>
            {children}
          </div>
          <div style={{ paddingLeft: 0, display: "block" }}>
            <div
              style={{
                // textDecoration: "underline",
                // cursor: "pointer"
                display: "flex",
                // justifyContent: "center",
                alignContent: "center",
                backgroundColor: "white",
                // textDecoration: "underline",
                cursor: "pointer",
                borderBottom: "1px solid #aeaeae",
                borderRight: "1px solid #aeaeae",
                borderLeft: "1px solid #aeaeae",
                borderTop: "none !important",
                borderBottomLeftRadius: "0.3rem",
                borderBottomRightRadius: "0.3rem",
                fontSize: 12,
                border: "0px solid",
                marginTop: "-7px",
                padding: "10px",
                color: "black"
              }}
            >
              <div style={{ paddingRight: 6 }}>
                <div
                  onClick={() => {
                    updateDueDate("");
                    onChangeDueDate("");
                    setIsDueDateCalender(!isDueDateCalender);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    fontSize: 14,
                    padding: 6,
                    borderRadius: 4,
                    border: "1px solid #E6E6E6"
                  }}
                >
                  <IntlMessages id={"today.removedate"} />
                </div>
              </div>
              <div
                onClick={() => {
                  updateDueDate(
                    dueDateOnChangeSet ? dueDateOnChangeSet : getRoundOfMinute()
                  );
                  // setDueDate(new Date());
                  setIsDueDateCalender(!isDueDateCalender);
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "30%",
                  fontSize: 14,
                  background: topbarTheme.buttonColor,
                  padding: 6,
                  borderRadius: 4,
                  color: "#FFF"
                }}
              >
                <IntlMessages id={"globalsearchbar.applybutton"} />
              </div>
            </div>
          </div>
        </CalendarContainer>
      </div>
    );
  };
  const TaskDueDate = React.forwardRef(({ value, onClick }, ref) => (
    <>
      <RoyTooltip
        id="DueDate"
        title={InjectPlaceHolderMessage(
          "today.due_date",
          props?.LanguageSwitcher?.language?.languageId
        )}
      >
        <div className="" style={{}}>
          <Button
            id="DueDate"
            onClick={onClick}
            ref={ref}
            className="c-btns  border c-outline-light  plr-10"
            style={{
              height: "26px",
              backgroundColor: "#FFF !important",
              border: "1px solid !important",
              borderColor: "rgba(0,0,0,.4) !important",
              boxShadow: "none",
              paddingLeft: "10px",
              display: "flex !important",
              justifyContent: "center",
              alignItems: "center",
              padding: 0
              // minWidth: 100
            }}
          >
            <span onClick={() => setIsDueDateCalender(!isDueDateCalender)}>
              {dueDate == "" ? (
                <IntlMessages id={"task-details.schedule"} />
              ) : (
                moment(dueDate).format("DD MMM hh:mm a")
              )}
            </span>
          </Button>
        </div>
      </RoyTooltip>
    </>
  ));

  const onChangeDueDate = async date => {
    setDueDateOnChangeSet(date);
  };

  const updateDueDate = date => {
    setDueDate(date);
    // setIsDueDateCalender(!isDueDateCalender);
    //
  };

  const onChangeRemindersAt = async date => {
    setRemindersAtOnChangeSet(date);
  };
  const updateRemindersAt = date => {
    setRemindersAt(date);
    // setIsRemindersDateCalender(!isRemindersDateCalender);
  };

  const checkUserAssignedPresentInNewProject = (
    assignedUser,
    projectCollaborators
  ) => {
    let status = false;
    if (projectCollaborators && assignedUser) {
      projectCollaborators &&
        projectCollaborators.map(user => {
          if (user.user.id === assignedUser.user.id) {
            status = true;
          }
        });
      return status;
    }
  };
  const handleWorskpaceProjectSelection = data => {
    if (data) {
      if (
        data?.workspace?.id !== selectedProject?.workspace?.id &&
        selectedProject?.project
      ) {
        // remove all changes done by user label,date,reminder,priority,assign user
        // showNotifications(
        //   "You've changed Workspace ,all changes done like label,date,reminder,priority,assign user no longer available",
        //   "success"
        // );
        if (
          !checkUserAssignedPresentInNewProject(
            userAssigned,
            data.project.collaborator
          )
        ) {
          setUserAssigned(null);
        }

        setTaskPriority("no_priority");
        setTaskLabels([]);
        setDueDate("");
        setRemindersAt("");
        setSyncGoogle(false);
      }

      setFetchingProjectCollab(true);

      setSelectedProject(data);
      if (data.project) {
        setLabelsArr([...data.workspace.label]);
        setProjectCollaborators([...data.project.collaborator]);
      }
      setFetchingProjectCollab(false);
    }
  };

  const RemindersAtDatePicker = React.forwardRef(({ value, onClick }, ref) => (
    <>
      <div onClick={onClick} ref={ref}>
        <RoyTooltip
          id="reminders"
          title={InjectPlaceHolderMessage(
            "today.reminder",
            props?.LanguageSwitcher?.language?.languageId
          )}
        >
          <img
            onClick={() => setIsRemindersDateCalender(!isRemindersDateCalender)}
            id="reminders"
            src={alarmnew}
            height="16"
            width="16"
            alt="Reminder"
            style={{ cursor: "pointer", position: "relative", top: -1 }}
          />
        </RoyTooltip>
      </div>
    </>
  ));
  const UpdateRemainderDateChanges = ({ className, children }) => {
    return (
      <div>
        <CalendarContainer
        // className={className + " custom-calender-class-"}
        // style={{ display: "grid !important" }}
        >
          <div></div>
          <div style={{ position: "relative" }} className={className}>
            {children}
          </div>
          <div
            style={{
              // display: "block",
              // paddingLeft: 70,
              // textDecoration: "underline",
              // cursor: "pointer",
              display: "flex",
              // justifyContent: "center",
              alignContent: "center",
              backgroundColor: "white",
              // textDecoration: "underline",
              cursor: "pointer",
              borderBottom: "1px solid #aeaeae",
              borderRight: "1px solid #aeaeae",
              borderLeft: "1px solid #aeaeae",
              borderTop: "none !important",
              borderBottomLeftRadius: "0.3rem",
              borderBottomRightRadius: "0.3rem",
              border: "0px solid",
              fontSize: 12,
              marginTop: "-7px",
              padding: "10px",
              color: "black"
            }}
          >
            <div style={{ paddingRight: 6 }}>
              <div
                onClick={() => {
                  updateRemindersAt("");
                  setIsRemindersDateCalender(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  fontSize: 14,
                  padding: 6,
                  borderRadius: 4,
                  border: "1px solid #E6E6E6"
                }}
              >
                <IntlMessages id={"today.removedate"} />
              </div>
            </div>
            <div
              onClick={() => {
                updateRemindersAt(
                  remindersAtOnChangeSet
                    ? remindersAtOnChangeSet
                    : getRoundOfMinute()
                );
                setIsRemindersDateCalender(false);
                setIsRemindersDateAtCalender(false);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                width: "30%",
                fontSize: 14,
                background: topbarTheme.buttonColor,
                padding: 6,
                borderRadius: 4,
                color: "#FFF"
              }}
            >
              <IntlMessages id={"globalsearchbar.applybutton"} />
            </div>
          </div>
        </CalendarContainer>
      </div>
    );
  };
  const RemindersAtDatePickerButton = React.forwardRef(
    ({ value, onClick }, ref) => (
      <>
        {remindersAt && (
          <div onClick={onClick} ref={ref} className="flex-x">
            <RoyTooltip
              id="reminders"
              title={InjectPlaceHolderMessage(
                "today.reminder",
                props?.LanguageSwitcher?.language?.languageId
              )}
            >
              <button
                className="btn  border c-outline-light plr-10 btn btn-secondary"
                style={{
                  fontSize: "14px",
                  height: "26px",
                  backgroundColor: "#FFF !important",
                  border: "1px solid !important",
                  borderColor: "rgba(0,0,0,.4) !important",
                  boxShadow: "none",
                  paddingLeft: "10px",
                  display: "flex !important",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 0
                }}
              >
                <span
                  onClick={() =>
                    setIsRemindersDateAtCalender(!isRemindersDateAtCalender)
                  }
                >
                  {remindersAt ? (
                    <>
                      <IntlMessages id={"today.reminderat"} />{" "}
                      {moment(remindersAt).format("DD MMM h:mm a")}
                    </>
                  ) : (
                    "Remider"
                  )}
                </span>
              </button>
            </RoyTooltip>
          </div>
        )}
      </>
    )
  );

  const onChangeSyncGoogle = () => {
    if (!syncGoogle) {
      checkIsGoogleAuthenticated();
    }
    setSyncGoogle(!syncGoogle);
  };
  const checkIsGoogleAuthenticated = async () => {
    const response = await ApiCalendar.getBasicUserProfile();
    if (!response) {
      handleAuthGoogle("", "sign-in");
    }
  };
  const handleAddnewTask = async e => {
    e.preventDefault();
    if (
      selectedProject &&
      selectedProject.workspace &&
      selectedProject.workspace.id &&
      selectedProject.project &&
      selectedProject.project.id &&
      selectedProject.section &&
      selectedProject.section.id
    ) {
      if (
        newTaskInput &&
        newTaskInput.length > 0 &&
        selectedProject.workspace &&
        selectedProject &&
        selectedProject.section &&
        selectedProject.project
      ) {
        try {
          addTaskInit(props.auth.accessToken);
          joinRoom(`${projectSocketRoomPrefix}${selectedProject.project.id}`);

          const ids = taskLabels && taskLabels.map(a => a.id);

          setIsLoader(true);
          let payload = {
            tempId: randomUUID(),
            title: newTaskInput,
            description: newTaskDescription,
            project_id:
              selectedProject && selectedProject.project.id.toString(),
            label_ids: ids.toString(),
            section_id:
              selectedProject && selectedProject.section.id.toString(),
            priority: taskPriority ? taskPriority : "no_priority",
            // scheduledAt: dueDate ? dueDate : null,
            reminderAt: remindersAt ? remindersAt : null,
            roomname: `${projectSocketRoomPrefix}${selectedProject.project.id}`
          };

          let userArr = userAssigned?.hasOwnProperty("user")
            ? userAssigned["user"]
            : [];
          let userIdArr = [];
          if (userArr.length > 0) {
            await Promise.all(
              userArr.map(item => {
                if (item) {
                  userIdArr.push(item.id);
                }
              })
            );
          }
          let userIds = userIdArr.join(",");
          payload = { ...payload, user_id: userIds };
          if (dueDate && dueDate != null && dueDate != "") {
            payload = { ...payload, scheduledAt: dueDate ? dueDate : "" };
          }

          addTask(payload, cb => {
            if (cb.status == "Ok") {
              if (refreshTodoList) {
                refreshTodoList();
              }
              if (
                props.forUpcomming &&
                payload.scheduledAt &&
                moment(defaultUpcommingDate).diff(
                  payload.scheduledAt,
                  "days"
                ) !== 0
              ) {
                props.showNotificationsActionable &&
                  props.showNotificationsActionable(
                    payload.scheduledAt,
                    <IntlMessages id={"upcoming.taskaddedgototask"} />
                  );
              } else {
                addToast(
                  ToastMessage(<IntlMessages id={"today.taskadded"} />),
                  {
                    appearance: "success",
                    autoDismiss: true
                  }
                );
              }
              if (
                payload?.scheduledAt &&
                payload?.scheduledAt != null &&
                syncGoogle
              ) {
                SyncTasktoGoogle(payload);
              }
              setIsLoader(false);
              setNewTaskInput("");

              setmodal(!modal);
            } else {
              setIsLoader(false);
              addToast(
                ToastMessage(
                  <IntlMessages id={"task-details.something_went_wrong"} />
                ),
                {
                  appearance: "error",
                  autoDismiss: true
                }
              );
              setmodal(!modal);
            }
          });

          // const Result = await addTodo({
          //   title: newTaskInput,
          //   description: newTaskDescription,
          //   project_id: selectedProject && selectedProject.project.id.toString(),
          //   label_ids: ids.toString(),
          //   section_id: selectedProject && selectedProject.section.id.toString(),
          //   priority: taskPriority ? taskPriority : "no_priority",
          //   scheduledAt: dueDate ? dueDate : null,
          //   reminderAt: remindersAt ? remindersAt : null
          // });

          // if (Result) {
          //   setIsLoader(false);
          //   if (Result.error) {    addToast(ToastMessage(Result.error.message), {
          //       appearance: "error",
          //       autoDismiss: true
          //     });
          //   }
          //   if (Result.data) {
          //     if (syncGoogle && Result?.data?.scheduledAt) {
          //       SyncTasktoGoogle(Result.data);
          //     }

          //     setmodal(!modal);
          //
          //     if (
          //       props.auth.user.businessId == selectedProject?.workspace?.id &&
          //       selectedProject?.project?.id == Result?.data?.project?.id
          //     ) {
          //       addToast(
          //         ToastMessage(
          //           Result.message,
          //           `/project/${Result?.data?.project?.id}`,
          //           "Go to task",
          //           data => props.history.push(data)
          //         ),
          //         {
          //           appearance: "success",
          //           autoDismiss: true,
          //           autoDismissTimeout: 8000
          //         }
          //       );
          //     } else {
          //       addToast(ToastMessage(Result.message), {
          //         appearance: "success",
          //         autoDismiss: true,
          //         autoDismissTimeout: 8000
          //       });
          //     }
          // }
          // }
        } catch (e) {
          addToast(ToastMessage(), {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 8000
          });
        }
      }
    } else {
      setIsWorkspaceError(true);
    }
  };
  const fetchProjects = async id => {
    if (id) {
      await Promise.all(
        workspaceData &&
          workspaceData.map((data, i) => {
            if (data.id == id) {
              setProjectData(data.project);
            }
          })
      );
    }
  };
  const selectLabelHandler = label => {
    if (taskLabels && ids && ids.includes(label.id)) {
      var arr = [...taskLabels];
      arr &&
        arr.map((data, i) => {
          if (data.id == label.id) {
            arr.splice(i, 1);
          }
        });

      setTaskLabels(arr);
    } else setTaskLabels([...taskLabels, label]);
  };
  const toggleLabelSelection = () => setLabelSelection(!labelSelection);

  return (
    <div>
      <Modal
        backdrop={false}
        isOpen={modal}
        // toggle={() => setmodal(!modal)}
        centered
        autoFocus={false}
        onClick={e => {
          setIsNewTaskModleClick(true);
        }}
      >
        <form onSubmit={handleAddnewTask}>
          <ModalBody>
            <div style={{ minHeight: 150 }}>
              <NewTaskInputWrapper>
                <div
                  style={{
                    border: "1px solid #e6e6e6",
                    borderRadius: 5,
                    marginBottom: 10,
                    paddingLeft: 10
                  }}
                >
                  <NewTaskInput
                    autoFocus={true}
                    inputValue={newTaskInput}
                    onChange={onChangeNewTask}
                    language={props?.LanguageSwitcher?.language?.languageId}
                  />
                </div>
              </NewTaskInputWrapper>
              <NewTaskDescriptionWrapper>
                <div style={{ border: "1px solid #e6e6e6 ", borderRadius: 5 }}>
                  <NewTaskDescription
                    inputValue={newTaskDescription}
                    onChange={onChangeDescription}
                    isNewTaskModleClick={isNewTaskModleClick}
                    setIsNewTaskModleClick={setIsNewTaskModleClick}
                    language={props?.LanguageSwitcher?.language?.languageId}
                  />
                </div>
              </NewTaskDescriptionWrapper>
              <div className="">
                <div className="">
                  <div className="pt-15">
                    <WorkspaceNestedItem
                      isLoader={isLoaderWorkspace}
                      toggleAddProject={id => {
                        setDefaultWorkspaceId(id);
                        setProjectModal(!projectModal);
                      }}
                      isError={isWorkspaceError}
                      toggleError={() => setIsWorkspaceError(true)}
                      disbleError={() => setIsWorkspaceError(false)}
                      PRIMARY_COLOR={topbarTheme.buttonColor}
                      selectedProject={selectedProject}
                      setSelectedProject={handleWorskpaceProjectSelection}
                      workspaceData={workspaceData && workspaceData}
                    />
                  </div>
                </div>
                {/* <ProjectDropdown
                  projectData={projectData && projectData}
                  selectedProject={selectedProject}
                  setSelectedProject={data => setSelectedProject(data)}
                /> */}
              </div>

              <div className=" pt-15">
                <div
                  className="flex-x"
                  id="Due date"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="flex-1 flex-x">
                    <DatepickerWrapper
                      {...props}
                      style={{
                        width: "10px !important",
                        paddingLeft: "10px !important"
                      }}
                    >
                      <DatePicker
                        open={isDueDateCalender}
                        onClickOutside={e =>
                          setIsDueDateCalender(!isDueDateCalender)
                        }
                        value={dueDateOnChangeSet}
                        selected={dueDateOnChangeSet}
                        onChange={onChangeDueDate}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        // timeCaption="time"

                        className="custom-datepicker pl-5"
                        calendarClassName="custom-calender-class"
                        placeholderText={"Schedule"}
                        // isClearable={true}
                        minDate={!AllowAddPreviousDateTask && new Date()}
                        customInput={<TaskDueDate />}
                        // calendarContainer={ScheduleDateCalendar}
                        locale={
                          locale
                          // props.language.locale == "de"
                          //   ? de
                          //   : props.language.locale
                        }
                      >
                        <CalendarContainer>
                          <div></div>
                          {/* <div style={{ position: "relative" }} className={className}>
                            {children}
                          </div> */}
                          <div style={{ paddingLeft: 0, display: "block" }}>
                            <div
                              style={{
                                width: "100%",
                                // textDecoration: "underline",
                                // cursor: "pointer"
                                display: "flex",
                                // justifyContent: "center",
                                alignContent: "center",
                                backgroundColor: "white",
                                // textDecoration: "underline",
                                cursor: "alias",
                                borderBottom: "1px solid #aeaeae",
                                borderRight: "1px solid #aeaeae",
                                borderLeft: "1px solid #aeaeae",
                                borderTop: "none !important",
                                borderBottomLeftRadius: "0.3rem",
                                borderBottomRightRadius: "0.3rem",
                                fontSize: 12,
                                border: "0px solid",
                                marginTop: "-7px",
                                padding: "10px",
                                color: "black"
                              }}
                            >
                              <div style={{ paddingRight: 8 }}>
                                <div
                                  onClick={() => {
                                    updateDueDate("");
                                    onChangeDueDate("");
                                    setIsDueDateCalender(!isDueDateCalender);
                                  }}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    fontSize: 14,
                                    padding: 6,
                                    cursor: "pointer",
                                    borderRadius: 4,
                                    border: "1px solid #E6E6E6"
                                  }}
                                >
                                  <IntlMessages id={"today.removedate"} />
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  updateDueDate(
                                    dueDateOnChangeSet
                                      ? dueDateOnChangeSet
                                      : getRoundOfMinute()
                                  );
                                  // setDueDate(new Date());
                                  setIsDueDateCalender(!isDueDateCalender);
                                }}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  width: "30%",
                                  fontSize: 14,
                                  cursor: "pointer",
                                  background: topbarTheme.buttonColor,
                                  padding: 6,
                                  borderRadius: 4,
                                  color: "#FFF"
                                }}
                              >
                                <IntlMessages
                                  id={"globalsearchbar.applybutton"}
                                />
                              </div>
                            </div>
                          </div>
                        </CalendarContainer>
                      </DatePicker>
                    </DatepickerWrapper>
                    {/* </Col> */}
                    {/* <Col className="col-lg-7 col-md-7 col-sm-7 col-xs-12 "> */}
                  </div>
                  {/* <button
                  // id="DueDate"
                  
                  // customInput={<ExampleCustomInput />}
                  className="btn  border c-outline-light plr-10 btn btn-secondary"
                  style={{
                          
                      fontSize: "14px"
                    }}
                    onClick={() => <ExampleCustomInput />}
                  >
                    Reminder at{" "}
                    {remindersAt == todayDate
                      ? "Today"
                      : moment(remindersAt).format("DD MMM h:mm a")}
                  </button> */}

                  <div
                    className="flex-x"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      className=""
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <AssigneeDropdown
                        dropdownOpen={isTaskAssignDropdown}
                        setDropdownOpen={setIsTaskAssignDropdown}
                        currentLoggedIn={props.auth?.user?.id}
                        userSelectedProject={
                          selectedProject &&
                          selectedProject.project &&
                          selectedProject.project.id &&
                          true
                        }
                        projectCollaborators={projectCollaborators}
                        userAssigned={userAssigned}
                        setUserAssigned={data => {
                          setUserAssigned(data);
                        }}
                      />
                    </div>
                    {/* {ids && ids.includes(project.id) && (
                                          <div>
                                            <i
                                              className={classNames(
                                                "fas fa-check-double",
                                                "ml-15",
                                              )}
                                            ></i>
                                          </div>
                                        )} */}

                    <RoyTooltip
                      id="Labels"
                      title={InjectPlaceHolderMessage(
                        "today.labels",
                        props?.LanguageSwitcher?.language?.languageId
                      )}
                    >
                      <div
                        className="more_icon"
                        id="Labels"
                        style={{ padding: "5px 5px 5px 20px" }}
                      >
                        <img
                          src={labelnew}
                          height="19"
                          width="19"
                          className=" "
                          alt="Labels"
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            top: -2
                          }}
                          onClick={() => setLabelSelection(!labelSelection)}
                        />
                        <LabelsPopover
                          isOpen={labelSelection}
                          toggle={() => setLabelSelection(!labelSelection)}
                          target={"Labels"}
                          selectedLabels={ids}
                          selectLabelHandler={selectLabelHandler}
                          placement={"bottom"}
                        />
                        {/* <i className="fa fa-tag pa-10" id="Labels" style={{ color: "gray" }} /> */}
                      </div>
                    </RoyTooltip>

                    <div className="" style={{ padding: "5px 5px 5px 15px" }}>
                      <RoyTooltip
                        id="priority"
                        title={InjectPlaceHolderMessage(
                          "today.priority",
                          props?.LanguageSwitcher?.language?.languageId
                        )}
                      >
                        <span id="priority">
                          <span>
                            <img
                              src={getPriorityPath(taskPriority)}
                              height="18"
                              width="18"
                              className=""
                              alt="Priority"
                              // style={{ cursor: "pointer",color: priority.color }}
                            />
                          </span>
                          <UncontrolledPopover
                            isOpen={prioritySelection}
                            trigger="legacy"
                            className="roy-menu"
                            innerClassName="roy-inner-content"
                            placement="bottom-end"
                            target="priority"
                            toggle={() => togglePrioritySelection()}
                          >
                            <PopoverBody style={{ minWidth: "170px" }}>
                              <div
                                className="float-right"
                                onClick={() => togglePrioritySelection()}
                              >
                                <i className="fa fa-times text-muted" />
                              </div>
                              <div
                                className="fs-14 text-center border-bottom"
                                style={{ padding: 4 }}
                              >
                                <IntlMessages id={"globalsearchbar.priority"} />
                              </div>

                              {allPriority &&
                                allPriority.map((priority, i) => {
                                  return (
                                    <div
                                      className="roy-menu-list"
                                      key={priority.value}
                                      id="PUncontrolledPopover"
                                      onClick={() => {
                                        // selectPriorityHandler(priority.name);
                                        setTaskPriority(priority.name);
                                        togglePrioritySelection();
                                      }}
                                    >
                                      <div className="label-list">
                                        <div>
                                          {/* <i
                                          className="fa fa-flag"
                                          style={{ color: priority.color }}
                                        /> */}
                                          {/* <svg
                                            data-svgs-path="sm1/priority_3.svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill={priority.color}
                                              fill-rule="nonzero"
                                              d="M5 13.777V19.5a.5.5 0 1 1-1 0V5a.5.5 0 0 1 .223-.416C5.313 3.857 6.742 3.5 8.5 3.5c1.113 0 1.92.196 3.658.776C13.796 4.82 14.53 5 15.5 5c1.575 0 2.813-.31 3.723-.916A.5.5 0 0 1 20 4.5V13a.5.5 0 0 1-.223.416c-1.09.727-2.519 1.084-4.277 1.084-1.113 0-1.92-.196-3.658-.776C10.204 13.18 9.47 13 8.5 13c-1.45 0-2.614.262-3.5.777z"
                                            ></path>
                                          </svg> */}
                                          <img
                                            src={priority.path}
                                            height="20"
                                            width="16"
                                            className=""
                                            alt={priority.name}
                                            style={{
                                              cursor: "pointer",
                                              color: priority.color
                                            }}
                                          />
                                        </div>
                                        <div style={{ marginLeft: 10 }}>
                                          {/* {priority.value} */}
                                          {<IntlMessages id={priority.value} />}
                                        </div>
                                        {priority.name == taskPriority && (
                                          <div>
                                            <img
                                              src={singleCheckIcon}
                                              className={classNames("mlr-15")}
                                            ></img>
                                            {/* <i
                                              className={classNames(
                                                "fas fa-check-double",
                                                "ml-15",
                                                `${priority.color}--text`
                                              )}
                                              style={{
                                                color: priority.color
                                              }}
                                            ></i> */}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </PopoverBody>
                          </UncontrolledPopover>
                        </span>
                      </RoyTooltip>
                    </div>
                    {/* <i className="fa fa-clock pa-10" style={{ color: "gray" }} /> */}
                    {dueDate && (
                      <div className="" style={{ padding: "5px 5px 5px 15px" }}>
                        <DatepickerWrapper {...props}>
                          <DatePicker
                            open={isRemindersDateCalender}
                            onClickOutside={e =>
                              setIsRemindersDateCalender(
                                !isRemindersDateCalender
                              )
                            }
                            value={remindersAtOnChangeSet || getRoundOfMinute()}
                            selected={remindersAtOnChangeSet}
                            onChange={onChangeRemindersAt}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            maxDate={dueDate}
                            disabledKeyboardNavigation
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="Time"
                            className="custom-datepicker"
                            calendarClassName="custom-calender-class"
                            placeholderText={"Reminders at"}
                            // isClearable={true}
                            minDate={new Date()}
                            customInput={<RemindersAtDatePicker />}
                            // calendarContainer={UpdateRemainderDateChanges}
                            locale={
                              locale
                              // props.language.locale == "de"
                              //   ? de
                              //   : props.language.locale
                            }
                          >
                            <CalendarContainer
                            // className={className + " custom-calender-class-"}
                            // style={{ display: "grid !important" }}
                            >
                              <div></div>
                              {/* <div style={{ position: "relative" }} className={className}>
                             {children}
                                </div> */}
                              <div
                                style={{
                                  width: "100%",
                                  // display: "block",
                                  // paddingLeft: 70,
                                  // textDecoration: "underline",
                                  // cursor: "pointer",
                                  display: "flex",
                                  // justifyContent: "center",
                                  alignContent: "center",
                                  backgroundColor: "white",
                                  // textDecoration: "underline",
                                  cursor: "alias",
                                  borderBottom: "1px solid #aeaeae",
                                  borderRight: "1px solid #aeaeae",
                                  borderLeft: "1px solid #aeaeae",
                                  borderTop: "none !important",
                                  borderBottomLeftRadius: "0.3rem",
                                  borderBottomRightRadius: "0.3rem",
                                  border: "0px solid",
                                  fontSize: 12,
                                  marginTop: "-7px",
                                  padding: "10px",
                                  color: "black"
                                }}
                              >
                                <div style={{ paddingRight: 8 }}>
                                  <div
                                    onClick={() => {
                                      updateRemindersAt("");
                                      setIsRemindersDateCalender(false);
                                    }}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignContent: "center",
                                      fontSize: 14,
                                      padding: 6,
                                      cursor: "pointer",
                                      borderRadius: 4,
                                      border: "1px solid #E6E6E6"
                                    }}
                                  >
                                    <IntlMessages id={"today.removedate"} />
                                  </div>
                                </div>
                                <div
                                  onClick={() => {
                                    updateRemindersAt(
                                      remindersAtOnChangeSet
                                        ? remindersAtOnChangeSet
                                        : getRoundOfMinute()
                                    );
                                    setIsRemindersDateCalender(false);
                                    setIsRemindersDateAtCalender(false);
                                  }}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    width: "30%",
                                    fontSize: 14,
                                    cursor: "pointer",
                                    background: topbarTheme.buttonColor,
                                    padding: 6,
                                    borderRadius: 4,
                                    color: "#FFF"
                                  }}
                                >
                                  <IntlMessages
                                    id={"globalsearchbar.applybutton"}
                                  />
                                </div>
                              </div>
                            </CalendarContainer>
                          </DatePicker>
                        </DatepickerWrapper>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {dueDate && remindersAt && (
                <div style={{ paddingTop: 10 }}>
                  <div className="">
                    <DatepickerWrapper {...props}>
                      <DatePicker
                        open={isRemindersDateAtCalender}
                        onClickOutside={e =>
                          setIsRemindersDateAtCalender(
                            !isRemindersDateAtCalender
                          )
                        }
                        value={remindersAtOnChangeSet}
                        selected={remindersAtOnChangeSet}
                        onChange={onChangeRemindersAt}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        maxDate={dueDate}
                        disabledKeyboardNavigation
                        dateFormat="MMMM d, yyyy h:mm aa"
                        timeCaption="Time"
                        className="custom-datepicker"
                        calendarClassName="custom-calender-class"
                        placeholderText={"Reminder at"}
                        // isClearable={true}
                        minDate={new Date()}
                        customInput={<RemindersAtDatePickerButton />}
                        // calendarContainer={UpdateRemainderDateChanges}
                        locale={
                          locale
                          // props.language.locale == "de"
                          //   ? de
                          //   : props.language.locale
                        }
                      >
                        <CalendarContainer
                        // className={className + " custom-calender-class-"}
                        // style={{ display: "grid !important" }}
                        >
                          <div></div>
                          {/* <div style={{ position: "relative" }} className={className}>
                             {children}
                                </div> */}
                          <div
                            style={{
                              width: "100%",
                              // display: "block",
                              // paddingLeft: 70,
                              // textDecoration: "underline",
                              // cursor: "pointer",
                              display: "flex",
                              // justifyContent: "center",
                              alignContent: "center",
                              backgroundColor: "white",
                              // textDecoration: "underline",
                              cursor: "alias",
                              borderBottom: "1px solid #aeaeae",
                              borderRight: "1px solid #aeaeae",
                              borderLeft: "1px solid #aeaeae",
                              borderTop: "none !important",
                              borderBottomLeftRadius: "0.3rem",
                              borderBottomRightRadius: "0.3rem",
                              border: "0px solid",
                              fontSize: 12,
                              marginTop: "-7px",
                              padding: "10px",
                              color: "black"
                            }}
                          >
                            <div style={{ paddingRight: 8 }}>
                              <div
                                onClick={() => {
                                  updateRemindersAt("");
                                  setIsRemindersDateCalender(false);
                                }}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  fontSize: 14,
                                  padding: 6,
                                  cursor: "pointer",
                                  borderRadius: 4,
                                  border: "1px solid #E6E6E6"
                                }}
                              >
                                <IntlMessages id={"today.removedate"} />
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                updateRemindersAt(
                                  remindersAtOnChangeSet
                                    ? remindersAtOnChangeSet
                                    : getRoundOfMinute()
                                );
                                setIsRemindersDateCalender(false);
                                setIsRemindersDateAtCalender(false);
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                width: "30%",
                                fontSize: 14,
                                background: topbarTheme.buttonColor,
                                padding: 6,
                                cursor: "pointer",
                                borderRadius: 4,
                                color: "#FFF"
                              }}
                            >
                              <IntlMessages
                                id={"globalsearchbar.applybutton"}
                              />
                            </div>
                          </div>
                        </CalendarContainer>
                      </DatePicker>
                    </DatepickerWrapper>
                  </div>
                </div>
              )}
              {/* As of now Google Calender sync closed */}
              {dueDate && dueDate != "" && false && (
                <div style={{ paddingTop: 10 }}>
                  {/* <div className="pretty p-switch p-fill">
                    <input
                      type="checkbox"
                      id="googleSync"
                      name="googleSync"
                      defaultChecked={syncGoogle}
                      onChange={e => {
                        onChangeSyncGoogle();
                      }}
                    />
                    <div className="state fs-14 text-muted"> */}
                  <Switch
                    defaultChecked={syncGoogle || false}
                    checked={syncGoogle || false}
                    onChange={onChangeSyncGoogle}
                    onColor={topbarTheme.buttonColor}
                    // onHandleColor="#2693e6"
                    handleDiameter={13}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={15}
                    width={32}
                    className="react-switch"
                    id="googleSync"
                    name="googleSync"
                  />
                  <label style={{ paddingLeft: 3 }}>
                    <IntlMessages id={"today.googlecalendar"} />
                  </label>
                  {/* </div> */}
                  {/* </div> */}
                </div>
              )}

              <div className="flex-x">
                {userAssigned &&
                  userAssigned?.hasOwnProperty("user") &&
                  userAssigned["user"].map(item => {
                    return (
                      <div className="react-form-input-new-task mt-10 ml-1">
                        {/* {[data].map(item => {
                          return ( */}
                        <RoyTooltip id="userAssigned" title={item.name}>
                          <div
                            onClick={() =>
                              setIsTaskAssignDropdown(!isTaskAssignDropdown)
                            }
                            style={{ cursor: "pointer", marginRight: "10px" }}
                          >
                            {/* <i className="fa fa-user pr-2 text-muted" /> */}
                            {item.userMeta && item.userMeta?.thumbnail ? (
                              <img
                                id="userAssigned"
                                src={
                                  item.userMeta.thumbnail
                                    ? item.userMeta.thumbnail
                                    : assignnew
                                }
                                style={{
                                  height: 20,
                                  width: 20,
                                  marginRight: "5px",
                                  marginBottom: "4px",
                                  borderRadius: "50%"
                                }}
                              ></img>
                            ) : (
                              <div
                                id="userAssigned"
                                style={profileBackground(
                                  item.name ? item.name : item.email,
                                  22,
                                  22,
                                  14
                                )}
                              >
                                {item.name
                                  ? item.name.charAt(0).toUpperCase()
                                  : item.email.charAt(0).toUpperCase()}
                              </div>
                            )}
                            {/* {userAssigned.user.name} */}
                          </div>
                        </RoyTooltip>

                        {/* ) */}
                        {/* })} */}
                      </div>
                    );
                  })}
                <div style={{ marginLeft: "0px" }}>
                  {taskLabels && taskLabels.length > 0 && (
                    <div style={{ marginTop: 10 }}>
                      <div
                        className="label-block-task"
                        style={{ maxWidth: "100%", marginTop: 0 }}
                      >
                        <div
                          className="all-labels"
                          style={{ justifyContent: "left" }}
                        >
                          {taskLabels &&
                            taskLabels
                              .sort(function(a, b) {
                                return a.name.length - b.name.length;
                              })
                              .map((e, i) => {
                                return (
                                  <>
                                    <div
                                      key={i}
                                      title={e.name}
                                      // id="UncontrolledPopover"
                                      onClick={() => toggleLabelSelection()}
                                      className={classNames(
                                        "label",
                                        "demi-bold-text"
                                      )}
                                      style={{
                                        backgroundColor: getColorCode(e.color),
                                        cursor: "pointer",
                                        padding: "2px 4px",
                                        fontWeight: 500
                                      }}
                                    >
                                      {/* <i className="fa fa-tag fs-12 pr-1"/> */}
                                      {/* <img
                                        src={iconTagWhite}
                                        height="16"
                                        width="16"
                                        className=" "
                                        alt=""
                                      /> */}
                                      <span className="plr-5">{e.name}</span>
                                    </div>
                                  </>
                                );
                              })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* <GoogleCalendarSyncButton
                modal={googleCalendarModal && googleCalendarModal.status}
                googleCalendarModal={googleCalendarModal}
                setmodal={() => setGoogleCalendarModal({ status: false })}
                toggle={() => {
                  setGoogleCalendarModal({ status: false });
                  setmodal(!modal);
                }}
              /> */}
            </div>
          </ModalBody>

          <ModalFooter style={{ justifyContent: "flex-start" }}>
            <>
              {isLoader ? (
                <Loader height="24px" width="20px" />
              ) : (
                <button
                  type="submit"
                  disabled={
                    newTaskInput &&
                    newTaskInput?.length > 0 &&
                    selectedProject &&
                    selectedProject.workspace &&
                    selectedProject.project &&
                    selectedProject.section
                      ? false
                      : true
                  }
                  className="btn "
                  style={{
                    backgroundColor: topbarTheme.buttonColor,
                    border: "none",
                    color: "#FFF",
                    cursor:
                      newTaskInput && newTaskInput?.length > 0
                        ? "pointer"
                        : "not-allowed"
                  }}
                >
                  <IntlMessages id={"today.addtask"} />
                </button>
              )}
              <button
                type="button"
                className="btn"
                style={{
                  background: "#F4F5F7",
                  border: 0,
                  color: "#000",
                  borderRadius: 4
                }}
                onClick={() => {
                  if (newTaskInput?.length > 0 && newTaskInput) {
                    setIsCustomAlert(true);
                  } else {
                    setmodal(!modal);
                  }
                }}
              >
                <IntlMessages id={"today.cancel"} />
              </button>
            </>
          </ModalFooter>
        </form>
        <AddProject
          modal={projectModal}
          setmodal={() => {
            setProjectModal(!projectModal);
          }}
          defaultWorkspaceId={defaultWorkspaceId}
          showNotifications={showNotifications}
          history={route => props.history.push(route)}
          updateRedux={handlePostAddProject}
          UpdateSidebarProject={data => {
            updateSidebarProjectToSidebar(data);
          }}
          checkforunique={(datachild, toCheckValue) =>
            checkforunique(datachild, toCheckValue)
          }
          sidebarData={sidebarData}
        />
        <AddLabel
          modal={addLabelModal}
          sidebarData={sidebarData}
          workspaceId={selectedProject?.workspace?.id}
          setmodal={() => {
            addLabelModal && setLabelSelection(!labelSelection);
            setAddLabelModal(!addLabelModal);
          }}
          updateLabelToRedux={data => {
            if (
              props.auth?.user?.businessId == selectedProject?.workspace?.id
            ) {
              props.update_Label(data);
            }

            var arr = [...workspaceData];
            workspaceData &&
              workspaceData.map((business, i) => {
                if (business.id == selectedProject?.workspace?.id) {
                  arr[i].label.push(data);
                }
              });
            setWorkspaceData([...arr]);
          }}
        />
        <CustomAlert
          show={isCustomAlert}
          onConfirm={() => {
            setmodal(false);
            setIsCustomAlert(false);
          }}
          onCancel={() => setIsCustomAlert(false)}
          action="discard"
          messageType={"changes"}
          confirmMessage={<IntlMessages id={"task-details.discardchanges"} />}
          PRIMARY_COLOR={props.topbarTheme.buttonColor}
        />
      </Modal>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher.language }
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    update_Label,
    update_Project,
    update_recent_Project
  })
)(NewTask);
