import styled from "styled-components";
const ScrumboardWrapper = styled.div`
  .scrum-board {
    position: absolute;
    display: flex;
    padding-left: 20px;
    background: #ffffff;
    overflow: auto;
    width: 99%;
    min-height: calc(100vh - 191px);
    padding-top: 10px;
    .board-card {
      margin: 8px;
    }
  }

  .board-toolbar {
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: white;
    @media (max-width: 575.98px) {
      margin-top: 15px;
    }
  }

  .board-card {
    // background-color: #f1f2f5;
    height: fit-content;
  }

  .column-title {
    padding: 8px;
  }

  .card-task-member {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .member {
      margin: 5px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .task-title,
  .column-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      white-space: nowrap;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .board-task-card {
    background-color: white;

    border-radius: 3px;
    border: 1px solid #dde4eb;
    cursor: pointer;
    margin-bottom: 10px;
    &:hover {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;
    }
    .task-cover {
      img {
        width: 100%;
        border-radius: 7px;
        margin: 3px 0;
        height: 127px;
        object-fit: cover;
      }
    }
    .status-block {
      display: flex;
      padding: 2px 8px;
      flex-wrap: wrap;
      .task-status {
        justify-content: center;
        text-align: center;
        height: 16px;
        padding-left: 0px 5px;
        font-weight: 600;
        font-size: 10px !important;
        opacity: 0.8;
        border-radius: 5px;
        max-width: 300px;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .more_icon {
      i {
        font-size: 14px;
        color: #6b778c;
        cursor: pointer;
      }
    }

    .task-options,
    .counts {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .icon-space {
        margin-left: 12px;
        font-size: 14px;
        color: #6b778c;
      }
      .count-space {
        margin-left: 4px;
        color: #afbcca;
        font-size: 12px;
      }
    }
  }

  .add-card-block {
    // background: white;
    min-width: 250px;
    padding: 10px;
    margin: 8px 20px 0 8px;
  }

  .board {
    position: relative;
    height: 150px;
    .overlay {
      border-radius: 6px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-color: ${props =>
        props.layoutTheme.themeName === "theme1"
          ? "#FAFAFA"
          : props.layoutTheme.backgroundColor};
    }
  }

  .board-name-input {
    width: 85%;
    border: 0;
    outline: 0;
    padding: 11px;
    font-size: 14px;
    text-align: center;
  }

  max-height: calc(100vh - 240px);
  .board-card-scroll {
    overflow: hidden;
    overflow-y: hidden;
    &:hover {
      overflow-y: scroll;
    }
  }

  .board-card-height {
    max-height: calc(100vh - 365px);
  }

  .board-title {
    color: #42526e;
  }

  .board-action {
    color: #42526e;
  }

  .scrum-container {
    overflow: auto;
    width: 100%;
  }

  .board-grid {
    .board-more-option {
      position: absolute;
      right: 8px;
      z-index: 9;
      top: 3px;
    }
    transition: all 1s;
    &:hover {
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
    }
  }

  .board-list-title {
    color: ${props => props.layoutTheme.textColor};
  }

  .board-type-head {
    color: ${props => props.layoutTheme.headingColor};
  }

  .transparent-button {
    background-color: transparent;
    border: 0;
    &:focus {
      outline: 0;
    }
  }
`;

export default ScrumboardWrapper;
