import styled from "styled-components";

const ProjectTabWrapper = styled.div`
  .input-search-group {
    border: none !important;
    background: #f5f5f5 !important;
  }
`;

export default ProjectTabWrapper;
