import Inbox from "views/Inbox";
import Upcomming from "views/Upcomming";
import Today from "views/Today";
import Labels from "views/Labels";
import Priority from "views/Priority";
import Scrumboard from "views/app/Scrumboard";
import UserProfile from "views/pages/authentication/UserProfile";
import Settings from "views/pages/Settings";
import Board from "components/scrumboard/Board";
import ProjectGrid from "views/ProjectGrid";
import ProjectDetails from "views/ProjectDetails";
import Notifications from "views/Notifications";
// import Dashboard from "views/Dashboard";
import TaskDetailsView from "views/TaskDetailsView";
import Business from "views/Business";
import NotFound from "views/NotFound";
import WorkspaceDetails from "views/WorkspaceDetails";
import TaskAllAttachments from "views/TaskAllAttachments";
import GlobalActivity from "views/GlobalActivity";
import GlobalMyAssignments from "views/GlobalMyAssignments";
import ProjectAttachments from "views/ProjectAttachments";
import ProjectActivity from "views/ProjectActivity";
import WorkSpaceActivitys from "views/WorkSpaceActivitys";
import AllBusiness from "views/AllBusiness";

const dashboardRoutes = [
  { path: "/inbox", component: Inbox },
  { path: "/upcoming", component: Upcomming },
  { path: "/today", component: Today },
  { path: "/home", component: AllBusiness },

  { path: "/projects", component: Scrumboard },
  { path: "/project/board/:id", component: Board },
  { path: "/label", component: Labels },
  { path: "/label/:id", component: Labels },
  { path: "/project/", component: ProjectGrid },
  { path: "/project/:id", component: ProjectGrid },
  { path: "/profile", component: UserProfile },
  { path: "/settings", component: Settings },
  { path: "/notifications", component: Notifications },
  // { path: "/dashboard", component: Dashboard },
  { path: "/priority/", component: Priority },
  { path: "/priority/:id", component: Priority },
  { path: "/project-details/:id", component: ProjectDetails },
  // { path: "/business/:id", component: Business }
  { path: "/business/:id", component: WorkspaceDetails },
  // { path: "*", component: NotFound },

  { path: "/task-details/:id", component: TaskDetailsView },
  { path: "/task-attachments/:id", component: TaskAllAttachments },
  { path: "/activity", component: GlobalActivity },
  { path: "/my-assignments", component: GlobalMyAssignments },
  { path: "/project-attachments/:id", component: ProjectAttachments },
  { path: "/project/:id/activity", component: ProjectActivity },
  { path: "/activity/:id", component: WorkSpaceActivitys }
];

export default dashboardRoutes;
