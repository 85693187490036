import React, { useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import PopoverBlock from "./PopOver";
import { getRedirectionStyle } from "./util/functions";
import ToastMessage from "components/notifications/ToastMessage";
import {
  friend1,
  friend2,
  friend3,
  people1,
  people2,
  people3,
  searchNotFound,
  NotificationHeaderIcon
} from "helper/constant";
import moment from "moment";

import Shimmer from "components/shimmer/Shimmer";
import { useToasts } from "react-toast-notifications";
import {
  getNotifications,
  acceptBusinessRequest,
  rejectBusinessRequest
} from "helper/services/notificationService";
import ChangeWorkspaceAlert from "util/ChangeWorkspaceAlert";
import Button from "components/button/Button";
import { swicthBusiness } from "helper/services/businessServices";
import {
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import { getProject, getLables } from "helper/services/projectServices";
import { connect } from "react-redux";
import labelActions from "redux/labels/actions";
import projectActions from "redux/projects/actions";
import businessActions from "redux/business/actions";
import AuthActions from "redux/auth/actions";
import { handleRecentWorkspace } from "util/RecentWorkspace";
import IntlMessages from "util/intlMessages";
const { update_token, update_business } = AuthActions;
const { default_business } = businessActions;
const { set_labels, update_Label } = labelActions;
const { set_projects } = projectActions;
const { update_Project } = projectActions;

function PopupNotifications(props) {
  const { addToast } = useToasts();
  const { PRIMARY_COLOR } = props;
  const [notifications, setNotifications] = useState([]);
  const [toast, setToast] = useState(false);
  const [load, setLoad] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [isAlertAction, setIsAlertAction] = useState({ status: false, id: "" });
  const [isAlertActionLoader, setIsAlertActionLoader] = useState(false);

  React.useEffect(() => {
    if (isNotification) {
      fetchNotification();
    }
  }, [isNotification]);

  // const showNotifications = message => {
  //   setToast(true);
  //   setToastMessage(message ? message : "Something went wrong");
  //   setTimeout(() => {
  //     setToast(false);
  //     setToastMessage("");
  //   }, 2500);
  // };
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  const fetchNotification = async (isLoading = true) => {
    try {
      setLoad(isLoading);

      const Response = await getNotifications(props.auth.accessToken);
      if (Response) {
        if (Response.error) {
          setLoad(false);
          showNotifications();
          return;
        }
        if (Response.data) {
          setLoad(false);
          setNotifications([...Response.data]);
        }
      }
    } catch (e) {
      setLoad(false);
      showNotifications();
    }
  };
  const doAcceptRequest = async (collabsId, invitationCode, title, id, i) => {
    try {
      setBtnLoad(true);
      const Response = await acceptBusinessRequest(collabsId, invitationCode);
      if (Response) {
        if (Response.error) {
          showNotifications();
          return;
        }
        if (Response.message) {
          const notificationArr = notifications;
          notificationArr &&
            notificationArr.map((data, index) => {
              if (data.id === id) {
                notificationArr[index].notification_type = "accepted";
                notificationArr[index].username = "You";
                showNotifications(Response.message, "success");
              }
            });
          fetchNotification(false);
          setNotifications([...notificationArr]);
          setBtnLoad(false);
        }

        if (!Response.data.id && !Response.data.title && !Response.data.color) {
          // props.update_Project({
          //   id: Response.data,
          //   title: title,
          // });
        }
        // props.update_Project(Response.data);
        setBtnLoad(false);
        // props.history.push(`project/${Response.data}`);
      }
    } catch (e) {
      setBtnLoad(false);

      showNotifications();
    }
  };

  const doRejectRequest = async (collabsId, invitationCode, id, i) => {
    try {
      setBtnLoad(true);
      const Response = await rejectBusinessRequest(collabsId, invitationCode);
      if (Response) {
        if (Response.error) {
          setBtnLoad(false);
          showNotifications();
          return;
        }

        if (Response.message) {
          const notificationArr = notifications;
          notificationArr &&
            notificationArr.map((data, index) => {
              if (data.id === id) {
                notificationArr[index].notification_type = "rejected";
                notificationArr[index].username = "You";
                setNotifications([...notificationArr]);
                setBtnLoad(false);
              }
            });

          await Promise.all(
            notificationArr.map((n, i) => {
              if (n.collaborator_id == collabsId) {
                notificationArr.splice(i, 1);
              }
            })
          );
          setNotifications([...notificationArr]);
        }
      }
    } catch (e) {
      setBtnLoad(false);
      showNotifications();
    }
  };

  const updateProjectandLabel = async token => {
    fecthProject(token);
    fetchLabels(token);
  };
  const updateToken = (token, businessName, businessMeta, id) => {
    props.update_token(token);
    props.default_business({
      id: id,
      businessName: businessName,
      businessMeta: businessMeta
    });
    fecthProject(token);
    fetchLabels(token);
    // updateUser(token);
    props.update_business({ businessId: id, businessName, businessMeta });
  };
  const fetchLabels = async token => {
    try {
      const Response = await getLables(token);
      if (Response) {
        if (Response.data) {
          props.set_labels({ labels: Response.data });
          updateSidebarLabelToSidebar(Response.data);
        }
        if (Response.error) {
        }
      }
    } catch (e) {}
  };

  const fecthProject = async token => {
    const Response = await getProject(token);
    if (Response) {
      props.set_projects({ projects: Response.data });
      updateSidebarProjectToSidebar(Response.data);
    }
  };
  const selectBusiness = async id => {
    try {
      setIsAlertActionLoader(true);
      const Response = await swicthBusiness(id);
      if (Response) {
        if (Response.data) {
          await updateToken(
            Response.data.token,
            Response.data.business.title,
            Response.data.business?.businessMeta
              ? Response.data.business?.businessMeta
              : null,
            Response.data.business.id
          );
          handleRecentWorkspace(Response.data.business);
          handleRedirection(isAlertAction.data, Response.data.business.id);
          updateProjectandLabel(Response.data.token);
          setIsAlertAction({ data: "", status: false });
        }
        if (Response.error) {
          showNotifications(Response.error.message, "error");
          setIsAlertAction({ id: "", status: false });
        }
        setIsAlertActionLoader(false);
      }
    } catch (e) {
      setIsAlertActionLoader(false);
    }
  };
  /* if project is not present then fetch projects api will be called and update sidebar */
  const isProjectPresentInSidebar = projectId => {
    if (projectId) {
      let projectArr = props.projects.projects;
      if (projectArr) {
        const isProjectPresent =
          projectArr && projectArr.filter(p => p.id === projectId);
        if (isProjectPresent && isProjectPresent?.length > 0) {
          return true;
        } else {
          fecthProject();
        }
      }
    }
  };
  const handleRedirection = (item, newBusinessId) => {
    if (item.redirection_type && item.redirection_type !== "rejected") {
      if (item.redirection_type === "task") {
        if (item.project_id && item.task_id && item.business_id) {
          if (
            item.business_id == props.auth.user.businessId ||
            item.business_id == newBusinessId
          ) {
            isProjectPresentInSidebar(item.project_id);
            return props.history.push(`/task-details/${item.task_id}`);
          } else {
            setIsAlertAction({ status: true, data: item });
          }
        }
        return false;
      }
      if (item.redirection_type === "taskcomment") {
        if (item.project_id && item.task_id && item.business_id) {
          if (
            item.business_id == props.auth.user.businessId ||
            item.business_id == newBusinessId
          ) {
            isProjectPresentInSidebar(item.project_id);
            return props.history.push(`/task-details/${item.task_id}`);
          } else {
            setIsAlertAction({ status: true, data: item });
          }
        }
        return false;
      }
      if (item.redirection_type === "business") {
        if (item.business_id) {
          if (
            item.business_id == props.auth.user.businessId ||
            item.business_id == newBusinessId
          ) {
            return props.history.push(`/business/${item.business_id}`);
          } else {
            setIsAlertAction({ status: true, data: item });
          }
        }
        return false;
      }
      if (item.redirection_type === "project") {
        if (item.project_id) {
          if (
            item.business_id == props.auth.user.businessId ||
            item.business_id == newBusinessId
          ) {
            isProjectPresentInSidebar(item.project_id);
            return props.history.push(`/project-details/${item.project_id}`);
          } else {
            setIsAlertAction({ status: true, data: item });
          }
        }
        return false;
      }
      if (item.redirection_type === "projectcomment") {
        if (item.project_id) {
          if (
            item.business_id == props.auth.user.businessId ||
            item.business_id == newBusinessId
          ) {
            isProjectPresentInSidebar(item.project_id);
            return props.history.push(`/project/${item.project_id}`, {
              commentModal: true
            });
          } else {
            setIsAlertAction({ status: true, data: item });
          }
        }
        return false;
      }
    }
  };
  const getMessageType = item => {
    if (item.redirection_type === "task") {
      return (
        <>
          <IntlMessages id={"notification.thistaskbelongsto"} />
          {item.business_title ? (
            <b>{item.business_title}</b>
          ) : (
            "different"
          )}{" "}
          <IntlMessages id={"notification.switchworkspacetaskdetails"} />
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "project") {
      return (
        <>
          <IntlMessages id={"notification.projectbelongs"} />{" "}
          {item.business_title ? <b>{item.business_title}</b> : "different"}{" "}
          <IntlMessages id={"notification.switchworkspaceprojectdetails"} />
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "business") {
      return (
        <>
          <IntlMessages id={"notification.switchtheworkspacetoseethe"} />
          {item.business_title ? (
            <b>{item.business_title}</b>
          ) : (
            "different"
          )}{" "}
          <IntlMessages id={"notification.workspacedetailsnow."} />
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "taskcomment") {
      return (
        <>
          <IntlMessages id={"notification.taskcomment"} />{" "}
          {item.business_title ? <b>{item.business_title}</b> : "different"}{" "}
          <IntlMessages id={"notification.switchworkspacetaskcommentdetails"} />
        </>
      );
    }
    if (item.redirection_type && item.redirection_type === "projectcomment") {
      return (
        <>
          <IntlMessages id={"notification.projectcomment"} />
          {item.business_title ? (
            <b>{item.business_title}</b>
          ) : (
            "different"
          )}{" "}
          <IntlMessages
            id={"notification.switchworkspaceprojectcommentdetails"}
          />
        </>
      );
    }
  };

  const generateSentense = (item, i) => {
    if (item.notification_type == "joined") {
      return (
        <PopoverBlock
          history={() => {
            handleRedirection(item);
            setIsNotification(!isNotification);
          }}
          notificationType={"joined"}
          people={people3}
          name={item.username}
          text={item.title}
          TextComponet={<IntlMessages id={"notification.joined"} />}
          created={moment(item.createdAt).format("DD MMM YY hh:mm a")}
        >
          <b
            style={getRedirectionStyle(
              item.redirection_type,
              item.redirection_id
            )}
            onClick={() =>
              handleRedirection(item.redirection_type, item.redirection_id)
            }
          >
            {item.title}
          </b>
        </PopoverBlock>
      );
    } else if (item.notification_type == "invited") {
      return (
        <div style={{ backgroundColor: "white", borderRadius: 5 }}>
          <PopoverBlock
            history={() => {
              handleRedirection(item);
              setIsNotification(!isNotification);
            }}
            notificationType={"invited"}
            people={people3}
            name={item.username}
            text={item.title}
            TextComponet={<IntlMessages id={"notification.invitedyouto"} />}
            created={moment(item.createdAt).format("DD MMM YY hh:mm a")}
          />
          {item.notification_type == "invited" && (
            <div style={{ marginBottom: 0, paddingBottom: 10 }}>
              <Button
                onClick={() => {
                  doAcceptRequest(
                    item.business_collaborator_id,
                    item.invitation_code,
                    item.title,
                    item.id,
                    i
                  );
                }}
                className="c-btn  c-outline-success"
                style={{ boxShadow: "none", margin: "0px 5px 0px 60px" }}
              >
                <IntlMessages id={"notification.accept"} />
              </Button>
              {"   "}
              <Button
                onClick={() => {
                  doRejectRequest(
                    item.business_collaborator_id,
                    item.invitation_code,
                    item.id,
                    i
                  );
                }}
                className="c-btn ma-5 c-outline-danger"
                style={{ boxShadow: "none" }}
              >
                <IntlMessages id={"notification.decline"} />
              </Button>
            </div>
          )}
        </div>
      );
    } else if (item.notification_type == "accepted") {
      return (
        <PopoverBlock
          history={() => {
            handleRedirection(item);
            setIsNotification(!isNotification);
          }}
          notificationType={"accepted"}
          people={people3}
          name={item.username}
          text={item.title}
          TextComponet={<IntlMessages id={"notification.Acceptedinvitation"} />}
          created={moment(item.createdAt).format("DD MMM YY hh:mm a")}
        />
      );
    } else if (item.notification_type == "rejected") {
      return (
        <PopoverBlock
          history={() => {
            handleRedirection(item);
            setIsNotification(!isNotification);
          }}
          notificationType={"declined"}
          people={people3}
          name={item.username}
          text={item.title}
          TextComponet={<IntlMessages id={"notification.Declinedinvitation"} />}
          created={moment(item.createdAt).format("DD MMM YY hh:mm a")}
        />
      );
    } else if (item.notification_type == "overdue") {
      return (
        <PopoverBlock
          history={() => {
            handleRedirection(item);
            setIsNotification(!isNotification);
          }}
          notificationType={"overdue"}
          people={people3}
          name={item.username}
          text={item.title}
          TextComponet={<IntlMessages id={"notification.overduetaskin"} />}
          created={moment(item.createdAt).format("DD MMM YY hh:mm a")}
        />
      );
    } else if (item.notification_type == "added") {
      return (
        <PopoverBlock
          history={() => {
            handleRedirection(item);
            setIsNotification(!isNotification);
          }}
          notificationType={"added"}
          people={people3}
          name={item.username}
          text={item.title}
          TextComponet={<IntlMessages id={"notification.hasaddedyou"} />}
          created={moment(item.createdAt).format("DD MMM YY hh:mm a")}
        >
          {item.title}
        </PopoverBlock>
      );
    } else if (item.notification_type == "mention") {
      return (
        <PopoverBlock
          history={() => {
            handleRedirection(item);
            setIsNotification(!isNotification);
          }}
          notificationType={"mention"}
          people={people3}
          name={item.username}
          text={item.title}
          TextComponet={<IntlMessages id={"notification.mentionedyou"} />}
          created={moment(item.createdAt).format("DD MMM YY hh:mm a")}
        >
          {item.title}
        </PopoverBlock>
      );
    } else if (item.notification_type == "assign") {
      return (
        <PopoverBlock
          history={() => {
            handleRedirection(item);
            setIsNotification(!isNotification);
          }}
          notificationType={"assign"}
          people={people3}
          name={item.username}
          text={item.title}
          TextComponet={<IntlMessages id={"notification.hasassigned"} />}
          created={moment(item.createdAt).format("DD MMM YY hh:mm a")}
        >
          {item.title}
        </PopoverBlock>
      );
    } else if (item.notification_type == "subtaskassign") {
      return (
        <PopoverBlock
          history={() => {
            handleRedirection(item);
            setIsNotification(!isNotification);
          }}
          notificationType={"assign"}
          people={people3}
          name={item.username}
          text={item.title + " checklist"}
          TextComponet={<IntlMessages id={"notification.aassignedtotask"} />}
          created={moment(item.createdAt).format("DD MMM YY hh:mm a")}
        >
          {item.title}
        </PopoverBlock>
      );
    }
  };
  return (
    <div>
      <div>
        <div className=" flex-1" style={{ paddingLeft: 10 }}>
          <button
            id="notification"
            className="top-header-icon"
            onClick={() => {
              // props.history.push("/notifications")
              setIsNotification(!isNotification);
            }}
          >
            <img src={NotificationHeaderIcon} height="20px" width="19px" />

            {props.unreadNotificationCount != 0 &&
              props.unreadNotificationCount != null && (
                <div
                  className="button-badge fs-11 demi-bold-text"
                  stle={{ background: "#FFF", color: PRIMARY_COLOR }}
                >
                  {props.unreadNotificationCount}
                </div>
              )}
          </button>
          <Popover
            style={{
              background: notifications.length > 0 ? "#f4f5f7" : "white",
              position: "absolute",
              right: "40px",
              boxShadow: "0px 0px 54px #0000001A",
              borderRadius: 6
            }}
            placement="bottom-start"
            target="notification"
            className="header-popover"
            trigger="legacy"
            hideArrow
            isOpen={isNotification}
            toggle={() => setIsNotification(!isNotification)}
          >
            <PopoverBody className="mail-popover-body">
              {load ? (
                <Shimmer />
              ) : (
                <>
                  <div className="fs-13 bold-text mb-0">
                    {props.unreadNotificationCount > 0 &&
                      `You have ${props.unreadNotificationCount} notifications.`}
                  </div>

                  <div
                    style={{ overflow: "hidden", height: "200", width: 270 }}
                  >
                    {notifications.length > 0 ? (
                      notifications.slice(0, 4).map((n, i) => {
                        if (i < 3) {
                          return generateSentense(n);
                        }
                      })
                    ) : (
                      <>
                        <div
                          style={{
                            padding: 20,
                            paddingTop: "50px",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            color: "#808080"
                          }}
                        >
                          <img
                            src={searchNotFound}
                            style={{
                              height: "100px"
                            }}
                          />
                        </div>
                        <div
                          style={{
                            height: "38px",
                            padding: 50,
                            paddingBottom: 85,
                            paddingTop: "0px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            // marginLeft: "-16px",
                            fontSize: 14,
                            color: "#808080"
                          }}
                        >
                          <div
                            style={{
                              color: "grey",
                              textAlign: "center",
                              paddingBottom: 10
                            }}
                          >
                            <IntlMessages
                              id={"notification.nonewnotification"}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </PopoverBody>

            <div
              className="fs-13 bold-text  text-center  hover-underline"
              style={{
                cursor: "pointer",
                padding: "5px 5px 15px 5px ",
                display: !notifications.length > 0 ? "none" : ""
              }}
              onClick={() => {
                props.history.push("/notifications", {
                  refresh: Math.random()
                });
                setIsNotification(!isNotification);
                props.setUnreadNotificationCount(0);
              }}
            >
              <IntlMessages id={"notification.viewall"} />
            </div>
          </Popover>
        </div>
      </div>
      <ChangeWorkspaceAlert
        loader={isAlertActionLoader}
        action="Switch Workspace"
        message={isAlertAction.status && getMessageType(isAlertAction.data)}
        PRIMARY_COLOR={props.topbarTheme.buttonColor}
        show={isAlertAction.data && isAlertAction.status}
        onConfirm={() => {
          isAlertAction.status &&
            selectBusiness(isAlertAction.data.business_id);
        }}
        onCancel={() => setIsAlertAction({ id: "", status: false })}
      />
    </div>
  );
}
const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {
  update_Project,
  update_token,
  update_business,
  default_business,
  set_labels,
  update_Label,
  set_projects
})(PopupNotifications);
