import React, { useState, useEffect } from "react";
import TodolistWrapper from "./todolistWrapper.style";
import moment from "moment";
import IconTint from "react-icon-tint";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import TaskDialog from "components/scrumboard/TaskDialog";
import TaskMenu from "components/projects/Menus/TaskMenu";
import RoyTooltip from "components/common/RoyTooltip";
import { deleteTodo } from "helper/services/todoService";
import CustomAlert from "util/CustomAlert";
import { data_not_found } from "helper/constant";
import { getColorCode } from "util/data/ProjectColors";
import DatePickerNoInput from "components/DatePickers/DatePickerNoInput";
import PriorityPopover from "components/priority/priorityPopover";
import AssigneeDropdown from "components/newTask/AssigneeDropdown";
import { onChangeTaskAssign } from "helper/methods/QuickActions";
import {
  commentnew,
  iconTag,
  threeDot_Icon,
  ListGridCheckImage,
  ListGridCircleImage,
  datesnewmodal
} from "helper/constant";
import {
  allPriority,
  prioritySocketRoomPrefix,
  taskSocketRoomPrefix
} from "constatnt";
import {
  getPriorityPath,
  getPriorityValue,
  projectSocketRoomPrefix
} from "constatnt";
import { joinRoom, leaveRoom, disconnect, priorityInit } from "services/socket";
import NewTask from "components/newTask/NewTask";
import { dateFilterSocketRoomPrefix } from "constatnt";
import TaskAssignee from "components/assignee/TaskAssignee";
import { repeatTypeOptions } from "util/data/taskDetailsutilData";
import { ReactComponent as RepeatSvgIcon } from "assets/images/recurrenceIcon.svg";
import SingleTaskView from "./SingleTaskView";
import { doGroupByproject } from "helper/methods/utilFunctions";
import IntlMessages from "util/intlMessages";
const PriorityListView = props => {
  const {
    doCloseQuickAction,
    doUpdateTaskPriority,
    doUpdateTaskScheduleDate,
    doUpdateTaskAssign,
    isQuickActionTask,
    setIsQuickActionTask,
    LanguageSwitcher
  } = props;

  const [todo, SetTodo] = useState(null);
  const [taskModel, setTaskModel] = useState(false);
  const [activeTaskDetail, setActiveTaskDetail] = useState(null);
  const [taskMenu, setTaskMenu] = useState(false);
  const [activeTaskMenu, setActiveTaskMenu] = useState(null);
  const [isCustomAlert, setCustomAlert] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [sectionalertMessage, setSectionAlertMessage] = useState(
    "Task will be deleted!"
  );
  const [taskProject, setTaskProject] = useState("");
  const { isEditTask, setIsEditTask, updateTaskHandler } = props;
  const [isDataFlag, setIsDataFlag] = useState(false);

  const [projectArr, setProjectArr] = useState([]);
  useEffect(() => {
    if (props.todoList) {
      if (props.todoList && props.todoList?.length) {
        const getRegularTasks =
          props.todoList &&
          props.todoList.filter(todo => !todo.isDeleted && !todo.isArchived);
        let formattedData = doGroupByproject(getRegularTasks);
        SetTodo(formattedData);
      }
      return () => {};
    }
  }, [props.todoList]);
  const activeTaskRef = React.useRef(activeTaskDetail);
  React.useEffect(() => {
    activeTaskRef.current = activeTaskDetail;
  });
  const getTodoStyle = (id, i) => {
    if (id == isQuickActionTask.taskId && isQuickActionTask.status) {
      return {
        display: "flex",
        alignItems: "flex-start",
        borderTopRightRadius: i == 0 ? "10px" : "0px",
        borderTopLeftRadius: i == 0 ? "10px" : "0px",
        borderBottomRightRadius: todo.length - 1 == i ? "10px" : "0px",
        borderBottomLeftRadius: todo.length - 1 == i ? "10px" : "0px",
        paddingTop: i == 0 ? "20px" : "0px",
        paddingBottom: todo.length - 1 == i ? "20px" : "0px",
        boxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        webkitBoxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        mozBoxShadow: "rgb(0 0 0 / 54%) 1px 4px 5px -2px",
        border: "1px solid lightgray",
        transition: "box-shadow 0.3s ease-in-out"
      };
    }
    return {
      display: "flex",
      alignItems: "flex-start",
      borderTopRightRadius: i == 0 ? "10px" : "0px",
      borderTopLeftRadius: i == 0 ? "10px" : "0px",
      borderBottomRightRadius: todo.length - 1 == i ? "10px" : "0px",
      borderBottomLeftRadius: todo.length - 1 == i ? "10px" : "0px",

      paddingTop: i == 0 ? "20px" : "0px",
      paddingBottom: todo.length - 1 == i ? "20px" : "0px",
      transition: "box-shadow 0.3s ease-in-out"
    };
  };

  const handleSocketRoom = () => {
    // Join room
    if (props.taskType == "priority") {
      joinRoom(
        `${prioritySocketRoomPrefix}${props.priorityId}_${props.businessId}`
      );
    } else joinRoom(`${dateFilterSocketRoomPrefix}${props.businessId}`);
  };

  const updateisCompleted = async (projectId, todoId, taskStatus, payload) => {
    props.updateIsCompleted(projectId, todoId, taskStatus, payload);
  };
  const deleteTask = async (id, projectId) => {
    updateTaskHandler(
      {
        task_id: id.toString(),
        project_id: projectId?.toString(),
        isDeleted: true
      },
      false
    );
  };
  const doArchiveTask = async (id, projectId) => {
    updateTaskHandler(
      {
        task_id: id.toString(),
        project_id: projectId?.toString(),
        isArchived: true
      },
      false
    );
  };

  const toggleTaskMenu = (target, taskData) => {
    if (taskMenu) {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(null);
      setActiveTaskDetail(null);
    } else {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(target);

      // joinRoom(`${taskSocketRoomPrefix}${taskData.id}`);
      setActiveTaskDetail(taskData);
    }
  };
  const doOpenTaskDetail = data => {
    props.history.push(`/task-details/${data.id}`);
    //   setActiveTaskDetail(data);
    //   setTodoId(data.id);
    //   // Join task socket room
    //   joinRoom(
    //     `${taskSocketRoomPrefix}${data.id}`
    //   );
    //   setTaskModel(true);
  };
  return (
    <div>
      <TodolistWrapper>
        <div className=" " style={{ marginBottom: 50 }}>
          {todo && Object.keys(todo).length > 0 && props.todoList.length > 0 ? (
            <div>
              {Object.keys(todo).length &&
                Object.keys(todo).map((projectKey, i) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: 5,
                          marginTop: 20
                        }}
                        className="pa-5 flex-x align-center"
                        id={`section-edit-${i}`}
                      >
                        <div className="flex-1">
                          <div className="fs-14 demi-bold-text flex-x align-center">
                            <i
                              className="fa fa-circle fs-10 pr-10 fs-10"
                              style={{
                                color: getColorCode(
                                  todo[projectKey].project.color
                                )
                              }}
                            />
                            <span
                              style={{
                                fontSize: 14,
                                color: "black",
                                fontWeight: 600
                              }}
                            >
                              {" "}
                              {todo[projectKey].project.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          className=""
                          style={{
                            display: "flex"
                          }}
                        ></div>
                        <div
                          className="todo-container flex-y"
                          style={{ backgroundColor: "white", borderRadius: 10 }}
                        >
                          {todo[projectKey].task &&
                            todo[projectKey].task.map((data, i) => {
                              return (
                                <>
                                  {isEditTask?.status &&
                                  isEditTask?.data?.id === data.id ? (
                                    <div
                                      style={{
                                        paddingTop: 5,
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        border: "1px solid #c6c2c2",
                                        position: "relative"
                                      }}
                                    >
                                      <NewTask
                                        updateTask
                                        action={isEditTask?.action || ""}
                                        toggleAction={() =>
                                          setIsEditTask({
                                            ...isEditTask,
                                            action: ""
                                          })
                                        }
                                        collaboratorList={
                                          props.collaboratorList
                                        }
                                        toggle={() => {
                                          setIsEditTask({
                                            status: false,
                                            data: ""
                                          });
                                        }}
                                        taskData={isEditTask.data}
                                        // onKeyDown={handleKeyDown}
                                        addTaskHandler={updateTaskHandler}
                                        taskProject={taskProject}
                                        setTaskProject={data =>
                                          setTaskProject(data)
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      <SingleTaskView
                                        auth={props.auth}
                                        index={i}
                                        data={data}
                                        getTodoStyle={getTodoStyle}
                                        todo={todo}
                                        updateisCompleted={updateisCompleted}
                                        doOpenTaskDetail={doOpenTaskDetail}
                                        doCloseQuickAction={doCloseQuickAction}
                                        isQuickActionTask={isQuickActionTask}
                                        doUpdateTaskScheduleDate={
                                          doUpdateTaskScheduleDate
                                        }
                                        doUpdateTaskAssign={doUpdateTaskAssign}
                                        toggleTaskMenu={toggleTaskMenu}
                                        todoLength={
                                          todo[projectKey].task.length || 0
                                        }
                                      />
                                      {/* task view commented start */}
                                      {/* <div
                                        key={i}
                                        className="flex-x my-todo align-center plr-30   roe-card-style  todo-board "
                                        style={getTodoStyle(data.id, i)}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flex: 1,
                                            cursor: "pointer",
                                            paddingBottom:
                                              todo.length - 1 == i
                                                ? "0px"
                                                : "10px",
                                            paddingTop: i == 0 ? "0px" : "10px",
                                            borderBottom:
                                              todo.length - 1 == i
                                                ? ""
                                                : "1px solid #ebebeb"
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex"
                                            }}
                                          >
                                            <div className=" pretty p-icon p-round p-smooth">
                                              <input
                                                type="checkbox"
                                                checked={data.isCompleted}
                                                onChange={e =>
                                                  updateisCompleted(
                                                    data.project.id,
                                                    data.id,
                                                    e
                                                  )
                                                }
                                              />
                                              {!data.isCompleted ? (
                                                <img
                                                  src={ListGridCircleImage}
                                                  style={{
                                                    height: 18,
                                                    width: 18
                                                    // marginRight: 5
                                                  }}
                                                ></img>
                                              ) : (
                                                <img
                                                  src={ListGridCheckImage}
                                                  style={{
                                                    height: 18,
                                                    width: 18
                                                    // marginRight: 5
                                                  }}
                                                ></img>
                                              )}
                                               
                                            </div>
                                          </div>
                                          <div
                                            className="flex-1"
                                            onClick={() => {
                                              // setActiveTaskDetail(data);

                                              activeTaskRef.current = data;
                                              doOpenTaskDetail(data);
                                              
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                             
                                            <div className="flex-x flex-wrap align-center fs-14">
                                              <span
                                                className="All_Task_Title fs-14"
                                                style={{
                                                  maxWidth: "50%",
                                                  color: "black",
                                                  fontSize: "14px",
                                                  paddingRight: "25px",
                                                  wordBreak: "break-word",
                                                  display: "-webkit-box",
                                                  WebkitBoxOrient: "vertical",
                                                  lineClamp: 1,
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  WebkitLineClamp: 1,
                                                  WebkitFontSmoothing:
                                                    "antialiased"
                                                }}
                                              >
                                                {data.title}
                                              </span>
                                              {data &&
                                                data.priority &&
                                                data.priority !=
                                                  "no_priority" && (
                                                  <>
                                                    {allPriority.map(item => {
                                                      if (
                                                        item.name ==
                                                        data.priority
                                                      ) {
                                                        return (
                                                          <>
                                                            <div
                                                              style={{
                                                                marginRight: 25
                                                              }}
                                                            >
                                                             
                                                              <img
                                                                src={getPriorityPath(
                                                                  data.priority
                                                                )}
                                                                height="17"
                                                                width="18"
                                                                className=""
                                                                alt="Priority"
                                                                style={{
                                                                  position:
                                                                    "relative"
                                                                   // top: "-2px"
                                                                }}
                                                              />
                                                              <span
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                  padding:
                                                                    "3px 6px",
                                                                  color: "gray"
                                                                }}
                                                              >
                                                                {item.value}
                                                              </span>
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    })}
                                                  </>
                                                )}
                                              {data.labels &&
                                                data.labels.map(
                                                  (item, index) => {
                                                    return (
                                                      <>
                                                        <div
                                                          style={{
                                                            marginRight: 25
                                                          }}
                                                        >
                                                          <div
                                                            className="my-day-label flex-x mr-6 fs-14 nevy--text align-center overflow-hidden"
                                                            key={index}
                                                          >
                                                            <IconTint
                                                              src={iconTag}
                                                              style={{
                                                                position:
                                                                  "relative",
                                                                top: "1px",
                                                                height: "18",
                                                                width: "18"
                                                              }}
                                                              color={getColorCode(
                                                                item.color
                                                              )}
                                                            />
                                                           
                                                            <span
                                                              style={{ 
                                                                color: "black",
                                                                paddingLeft: 5,
                                                                maxWidth:
                                                                  "300px",
                                                                whiteSpace:
                                                                  "nowrap",
                                                                textOverflow:
                                                                  " ellipsis",
                                                                overflow:
                                                                  "hidden", 
                                                              }}
                                                            >
                                                              {item.name}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              <div
                                                className={`my-day-label flex-x mr-0 fs-14 nevy--text align-center overflow-hidden ${
                                                  data.labels
                                                    ? data.labels.length > 0
                                                      ? "label"
                                                      : ""
                                                    : ""
                                                }`}
                                              >
                                              
                                                <div
                                                  style={{
                                                    marginRight: 25
                                                  }}
                                                >
                                                  <img
                                                  alt=""
                                                    src={commentnew}
                                                    style={{
                                                      height: "18px",
                                                      width: "15px",
                                                      marginRight: "0px",
                                                      marginTop: "0px",
                                                      textAlign: "center"
                                                    }}
                                                  ></img>
                                                  <span
                                                    style={{
                                                      color: "grey",
                                                      paddingLeft: 5
                                                    }}
                                                  >
                                                    {data.commentCount}
                                                  </span>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center"
                                                }}
                                              >
                                                {data.scheduledAt && (
                                                  <img
                                                    alt=""
                                                    src={datesnewmodal}
                                                    height="15px"
                                                    width="15px"
                                                    style={{ marginRight: 5 }}
                                                  />
                                                )}
                                                {data.scheduledAt &&
                                                  data.repeatType !==
                                                    repeatTypeOptions[0]
                                                      .value && (
                                                    <RepeatSvgIcon
                                                      style={{
                                                        marginRight: 5,
                                                        fill: "green",
                                                        height: "16px",
                                                        width: "15px"
                                                      }}
                                                    />
                                                  )}
                                                {data.scheduledAt && (
                                                  <div
                                                    className="due-date  label"
                                                    style={{
                                                      marginRight: 25
                                                    }}
                                                  >
                                                    Due on{" "}
                                                    {moment(
                                                      new Date(data.scheduledAt)
                                                    ).format("DD MMM YYYY")}
                                                  </div>
                                                )}
                                              </div>
                                              <div
                                                style={{
                                                  display: "contents",
                                                  alignItems: "center"
                                                }}
                                              >
                                                
                                                {data.taskAssign &&
                                                  data.taskAssign.map(item => (
                                                    <TaskAssignee
                                                      taskAssign={item}
                                                    />
                                                  ))}
                                              </div>
                                            </div>{" "}
                                          </div>
                                         
                                          <div
                                            style={{
                                              display: "flex",
                                              paddingTop: 7
                                            }}
                                            id={`taskmenu-${data.id}`}
                                            onClick={() => {
                                              setPriorityListViewName(
                                                data.title
                                              );
                                              toggleTaskMenu(
                                                `taskmenu-${data.id}`,
                                                data
                                              );
                                            }}
                                            className="cursor-pointer"
                                          >
                                            <img
                                            alt=""
                                              src={threeDot_Icon}
                                              style={{
                                                height: 6,
                                                width: 22
                                              }}
                                            ></img>
                                         
                                          </div>
                                          <div>
                                         
                                            <div
                                              id={`taskmenu-labels-${data.id}`}
                                            />
                                            <div
                                              id={`taskmenu-priority-${data.id}`}
                                            />

                                            {isQuickActionTask &&
                                              isQuickActionTask.status &&
                                              isQuickActionTask.action ==
                                                "assign" &&
                                              isQuickActionTask.target ==
                                                `taskmenu-assign-${data.id}` && (
                                                <>
                                                  <AssigneeDropdown
                                                    isDisableDropDownIcon
                                                    TargetId="assing-"
                                                    setDropdownOpen={() => {
                                                      doCloseQuickAction();
                                                    }}
                                                    businessId={
                                                      props.auth.user.businessId
                                                    }
                                                    projectId={
                                                      (isQuickActionTask.status &&
                                                        isQuickActionTask.data
                                                          .project?.id) ||
                                                      ""
                                                    }
                                                    dropdownOpen={
                                                      isQuickActionTask &&
                                                      isQuickActionTask.status
                                                    }
                                                    currentLoggedIn={
                                                      props.auth?.user?.id
                                                    }
                                                    projectCollaborators={
                                                      props.collaboratorList
                                                    }
                                                    userAssigned={
                                                      isQuickActionTask.selectedData
                                                        ? isQuickActionTask.selectedData
                                                        : ""
                                                    }
                                                    setUserAssigned={data => {
                                                      onChangeTaskAssign(
                                                        data,
                                                        doUpdateTaskAssign
                                                      );
                                                    
                                                    }}
                                                  />
                                                </>
                                              )}
                                            {isQuickActionTask &&
                                              isQuickActionTask.status &&
                                              isQuickActionTask.action ==
                                                "schedule" &&
                                              isQuickActionTask.target ==
                                                `taskmenu-schedule-${data.id}` && (
                                                <div
                                                  style={{
                                                    position: "relative"
                                                  }}
                                                >
                                                  <DatePickerNoInput
                                                  
                                                    dueDate={
                                                      isQuickActionTask.selectedData
                                                        ? isQuickActionTask.selectedData
                                                        : ""
                                                    }
                                                    onUpdateDate={
                                                      doUpdateTaskScheduleDate
                                                    }
                                                    toggle={doCloseQuickAction}
                                                    isOpen={
                                                      isQuickActionTask.status
                                                    }
                                                    PRIMARY_COLOR={
                                                      props.PRIMARY_COLOR
                                                    }
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div> */}
                                      {/* task view commented end */}
                                    </>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          ) : (
            <div
              style={{
                // height: "85vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "60px auto"
              }}
            >
              <div>
                <div
                  className="text-muted"
                  style={{
                    fontSize: 15,
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <img src={data_not_found} height="250" width="250" />
                  <br />
                </div>
                <div
                  className="text-muted"
                  style={{
                    fontSize: 14,
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      color: "black",
                      paddingTop: 20
                    }}
                  >
                    <IntlMessages id={"labels.no_tasks_filter"} />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </TodolistWrapper>

      {activeTaskDetail && (
        <TaskDialog
          taskType={props.taskType == "priority" ? "priority" : "date_filter"}
          taskModel={taskModel}
          priorityId={props.priorityId}
          collaboratorList={props.collaboratorList}
          setTaskModel={() => {
            setTaskModel(!taskModel);
            setActiveTaskDetail(null);
          }}
          taskDetail={activeTaskDetail}
          projectId={activeTaskDetail.project.id}
          projectName={activeTaskDetail.project.name}
          todoId={activeTaskDetail.id}
          updateTodoList={newTodo => props.refreshTodoList(newTodo)}
          dummyData={props.dummyData || false}
          showNotifications={props.showNotifications}
          currentPriorityId={props.currentPriorityId}
          sectionId={activeTaskDetail && activeTaskDetail.section?.id}
          popTask={todoId =>
            props.hasOwnProperty("isPriorityComponent") &&
            props.removeTaskFromList(todoId)
          }
          pushTask={taskData =>
            props.hasOwnProperty("isPriorityComponent") &&
            props.pushTask(taskData)
          }
        />
      )}
      <TaskMenu
        LanguageSwitcher={LanguageSwitcher}
        projectTask
        isMenu={taskMenu}
        toggleMenu={() => toggleTaskMenu()}
        viewTaskDetails={() => {
          // setActiveTaskDetail({ ...activeTaskDetail, action: "taskactivity" });
          // setTaskModel(true);
          doOpenTaskDetail(activeTaskDetail);
          setTaskMenu(false);
          setActiveTaskMenu("");
        }}
        doArchiveTask={() =>
          doArchiveTask(activeTaskDetail?.id, activeTaskDetail.project.id)
        }
        target={activeTaskMenu}
        editLabels={() => {
          setIsEditTask({
            status: true,
            data: activeTaskDetail,
            action: "label"
          });
        }}
        editAssign={() => {
          if (activeTaskDetail && activeTaskDetail.id) {
            setIsQuickActionTask({
              status: true,
              action: "assign",
              taskId: activeTaskDetail.id,
              target: `taskmenu-assign-${activeTaskDetail.id}`,
              selectedData:
                activeTaskDetail && activeTaskDetail.taskAssign
                  ? { user: activeTaskDetail?.taskAssign }
                  : "",
              data: activeTaskDetail
            });
          }
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "taskassign"
          // });
        }}
        editPriority={() => {
          if (activeTaskDetail && activeTaskDetail.id) {
            setIsQuickActionTask({
              status: true,
              action: "priority",
              taskId: activeTaskDetail.id,
              target: `taskmenu-priority-${activeTaskDetail.id}`,
              selectedData: activeTaskDetail && activeTaskDetail.priority,
              data: activeTaskDetail
            });
          }
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "priority"
          // });
        }}
        editDate={() => {
          setIsQuickActionTask({
            status: true,
            action: "schedule",
            taskId: activeTaskDetail.id,
            target: `taskmenu-schedule-${activeTaskDetail.id}`,
            selectedData: activeTaskDetail && activeTaskDetail.scheduledAt,
            data: activeTaskDetail
          });
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "schedule"
          // });
        }}
        editTask={() => {
          if (taskMenu) {
            setIsEditTask({ status: true, data: activeTaskDetail });
          } else {
            setIsEditTask({ status: false, data: "" });
          }
          // setTaskModel(!taskModel);
          setTaskMenu(!taskMenu);
          // setActiveTaskDetail(null);
        }}
        deleteTask={id => {
          setTaskMenu(!taskMenu);
          setCustomAlert(true);
        }}
      />
      <CustomAlert
        action="delete"
        messageType="task"
        PRIMARY_COLOR={props.PRIMARY_COLOR}
        show={isCustomAlert}
        onConfirm={() => {
          deleteTask(activeTaskDetail.id, activeTaskDetail.project.id);
          setCustomAlert(false);
        }}
        onCancel={() => setCustomAlert(false)}
        alertMessage={sectionalertMessage}
      />
      {isQuickActionTask &&
        isQuickActionTask.status &&
        isQuickActionTask.action == "priority" && (
          <PriorityPopover
            isOpen={isQuickActionTask.status}
            toggle={doCloseQuickAction}
            target={
              isQuickActionTask.status && isQuickActionTask.target
                ? isQuickActionTask.target
                : "priority-task-details"
            }
            selectedPriority={
              isQuickActionTask?.status && isQuickActionTask.selectedData
                ? isQuickActionTask.selectedData
                : "no_priority"
            }
            updatePriority={doUpdateTaskPriority}
            PRIMARY_COLOR={props.PRIMARY_COLOR}
          />
        )}
    </div>
  );
};

export default PriorityListView;
