import { withFormik } from "formik";
import React from "react";

import IntlMessages from "util/intlMessages";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .required(<IntlMessages id={"login.blankinputemail"} />)
      .trim()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email must have email@example.com"
      )
      .email(),
    password: Yup.string()
      .trim()
      .required(<IntlMessages id={"login.blankinputpassword"} />)
      .min(8, <IntlMessages id={"profile.oldpassworderror2"} />)
  }),
  mapPropsToValues: props => ({
    email: "",
    password: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
