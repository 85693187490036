import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  ListGridCircleImage as unCheckedImage,
  TaskChecklistCardDeleteImage as deleteItemImage,
  TaskChecklistCardEditPencilImage as editItemImage,
  subtaskItemChecked as checkedImage
} from "helper/constant";
const AdditemData = ({
  updateChecklistItem,
  openCustomAlert,
  updateIsCompletedSubTask,
  itemData,
  handleOpenSubtask,
  topbarTheme
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [input, setInput] = useState("");
  const [style, setStyle] = useState({ display: "none" });
  useEffect(() => {
    return () => {
      setIsEdit(false);
      setInput("");
    };
  }, []);
  const handleUpdateNewItem = () => {
    updateChecklistItem(input);
    setIsEdit(false);
    setInput("");
  };
  const handleEditChecklistItem = item => {
    setIsEdit(true);
    setInput(item.title);
  };

  const handleCancelUpdateNewItem = () => {
    setIsEdit(false);
    setInput("");
  };
  return (
    <div className="row">
      <div
        className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
        style={{
          padding: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingRight: "0px",
          marginBottom: isEdit ? 60 : 0
        }}
      >
        {/* <div>
          <img
            src={TaskChecklistCardItemImage}
            style={{
              height: 20,
              width: 20,
              marginRight: 25
            }}
          ></img>
        </div> */}

        <div onClick={updateIsCompletedSubTask}>
          {" "}
          <img
            alt=""
            src={itemData.isCompleted ? checkedImage : unCheckedImage}
            style={{
              height: 20,
              width: 20,
              cursor: "pointer"
            }}
          />
        </div>
      </div>
      <>
        <div
          className="col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12"
          style={{
            padding: "0px 10px 0px 0px",
            display: "flex",
            alignItems: "center"
          }}
          onMouseOver={e => {
            setStyle({ display: "block" });
          }}
          onMouseOut={e => {
            setStyle({ display: "none" });
          }}
        >
          {/* <div
            className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
            style={{
              padding: "0px",
              display: "flex",
              alignItems: "end",
              justifyContent: "flex-end",
              height: 24,
              paddingRight: "0px"
            }}
          >
            <div>
              <img
                src={TaskChecklistCardItemImage}
                style={{
                  height: 20,
                  width: 20,
                  marginRight: 25
                }}
              ></img>
            </div>

            subtaskId, checked, checklistId
            <IsItemCompleted 
                                isCompleted={itemData.isCompleted}
                                itemId={itemData.id}
                                checkListId={checklist.id} />
            <div onClick={updateIsCompletedSubTask}>
              {" "}
              <img
                src={itemData.isCompleted ? checkedImage : unCheckedImage}
                style={{
                  height: 20,
                  width: 20,
                  cursor: "pointer"
                }}
              />
            </div>
          </div> */}

          {isEdit ? (
            <div
              className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11"
              style={{
                padding: "0px 15px",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                // paddingLeft: "9px",
                margin: "10px 0px"
              }}
            >
              <div
                className="add-comment-input-block mt-10"
                style={{ width: "100%" }}
              >
                <input
                  // ref={textArea}
                  value={input}
                  autoFocus
                  className="add-subtask-input inputStyle"
                  name="checklistTitle"
                  rows="1"
                  onChange={e => setInput(e.target.value)}
                  style={{
                    border: "1px solid #EBEBEB",
                    backgroundColor: "#F8F8F8",
                    width: "100%"
                  }}
                  placeholder={"Item title"}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      /* checks if white space at starting of input present or not */
                      if (
                        !event.shiftKey &&
                        input.match(/^\S+\w{0,50}\S{0,}/g)
                      ) {
                        event.preventDefault();
                        handleUpdateNewItem();
                      }
                    }
                  }}
                />
                <div className="subtask-action" style={{ margin: "15px 0px" }}>
                  <>
                    <button
                      className="btn mr-10"
                      style={{
                        boxShadow: "none",
                        borderRadius: "17px",
                        backgroundColor: topbarTheme.buttonColor,
                        color: "white"
                      }}
                      disabled={
                        !input.match(/^\S+\w{0,50}\S{0,}/g) ? true : false
                      }
                      // style={{
                      //   backgroundColor: ChecklistInput.match(
                      //     /^\S+\w{0,50}\S{0,}/g
                      //   )
                      //     ? props.topbarTheme.buttonColor
                      //     : props.topbarTheme.buttonColor,
                      //   color: "#fff",
                      //   boxShadow: "none"
                      // }}
                      // disabled={
                      //   !ChecklistInput.match(
                      //     /^\S+\w{0,50}\S{0,}/g
                      //   )
                      //     ? true
                      //     : false
                      // }    `
                      onClick={handleUpdateNewItem}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn"
                      onClick={handleCancelUpdateNewItem}
                      style={{
                        boxShadow: "none",
                        borderRadius: "17px",
                        border: `1px solid ${topbarTheme.buttonColor}`,
                        color: topbarTheme.buttonColor,
                        width: "80px"
                      }}
                    >
                      Cancel
                    </button>
                  </>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11"
                style={{
                  padding: "0px 15px",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  // paddingLeft: "9px",
                  margin: "10px 0px"
                }}
              >
                <div
                  style={{
                    margin: "0px 15px 0px 0px ",
                    cursor: "pointer",
                    textOverflow: " ellipsis",
                    overflow: " hidden",
                    whiteSpace: " nowrap"
                  }}
                  onClick={() => handleOpenSubtask(itemData.id)}
                >
                  {" "}
                  {itemData.title}
                </div>
                <div
                  style={style}
                  onClick={() => handleEditChecklistItem(itemData)}
                >
                  <img
                    alt=""
                    src={editItemImage}
                    style={{
                      height: 17,
                      width: 17,
                      cursor: "pointer"
                    }}
                  ></img>{" "}
                </div>
              </div>
              <div
                className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  padding: 0
                }}
              >
                <div style={style} onClick={openCustomAlert}>
                  <img
                    alt=""
                    src={deleteItemImage}
                    style={{
                      height: 18,
                      width: 18,
                      cursor: "pointer"
                    }}
                  ></img>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
      </>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {})(AdditemData);
