import styled from "styled-components";

const HeaderWrapper = styled.div`

  .headerBack {
    height: 70px !important;
    background: ${props => props.topbarTheme.backgroundColor};
    padding: 5px 5px;
    // margin-left: -260px !important;
    // margin-left: ${props =>
      props.headerIconSet == false ? "-260px !important" : ""};
    // margin-right: ${props =>
      props.headerIconSet == true ? "260 !important" : ""};
    margin-left: -${props =>
      props.themeSetting.toolbarAlignValue === "below" && !props.mini
        ? props.drawerWidth
        : props.themeSetting.toolbarAlignValue === "below" && props.mini
        ? props.miniDrawerWidth === props.drawerWidth
          ? "80px"
          : props.miniDrawerWidth
        : "0px"};
    @media only screen and (max-width: 991.98px) {
      margin-left: 0px !important;
    }

    .drawer-handle-arrow {
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
    }
    .button-search {
      font-size: 12px !important;
      border: 0 !important;
      box-shadow: none !important;
      outline: none !important;
      &:focus {
        border: 0 !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }
    .mini-drawer-menu-icon {
      margin: 6px 0;
      justify-content: center;
      cursor: pointer;
      display: none;
      color: ${props => props.topbarTheme.textColor};
      @media only screen and (max-width: 991.98px) {
        display: block;
      }
      i {
        font-size: 20px;
        margin-right: 10px;
        position: relative;
        top: 2px;
      }
      .app-name {
        font-weight: 600;
      }
    }

    .input-search-group {
      border: none !important;
      background:#7a94b0 !important;
    }
    .search-input::placeholder{
      color: white !important;
      font-size: 14px !important;
    }

    .top-header-icon:hover {
      // background: #202020;
      background: transperant;
    }
    .form-control{
      color: white !important;
      font-size: 14px !important;
    }

   
    .top-header-profile-class {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

export default HeaderWrapper;
