/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getcomments, addcomment } from "helper/services/projectServices";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import CommentAttachment from "./CommentAttachment";
import CommentEditorInput from "components/projects/comments/Input/CommentEditorInput";
import moment from "moment";
import CustomAlert from "util/CustomAlert";
import GlobalWrapper from "./wrapper.style";
import BusinessListShimmer from "components/shimmer/BusinessListShimmer";
import { Progress } from "react-sweet-progress";
import "./comment.style.css";
import { FileDrop } from "react-file-drop";
import {
  projectCommentInit,
  joinRoom,
  leaveRoom,
  projectcommentRefresh,
  addProjectcomment,
  deleteProjectcomment
} from "services/socket";
import {
  threeDot_Icon,
  attachment,
  ProjectCollaboratorsCross
} from "helper/constant";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import MoreOptionsMenu from "components/sidebar/ProjectMoreOptions";
import CommentMenu from "components/projects/Menus/CommentMenu";
import CommentModalWrapper from "./CommentModalWrapper.style";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";

const CommentModal = props => {
  const {
    isOpen,
    toggleCommentModal,
    projectId,
    name,
    topbarTheme,
    showNotifications,
    handleResponse,
    PRIMARY_COLOR,
    collaboratorList
  } = props;
  const [projectComments, setProjectComments] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [newCommentisEmptySpace, setNewCommentIsEmptySpace] = useState(true);
  const [commentMenu, setCommentMenu] = useState(false);
  const [activeCommentMenu, setActiveCommentMenu] = useState(null);
  const [activeCommentDetail, setActiveCommentDetail] = useState(null);
  const [isCommentAddLoader, setIsCommentAddloader] = useState(false);
  const [isCommentUploadLoader, setIsCommentUploadLoader] = useState(false);
  const [attcahmentProgress, setUploadAttcahmentProgress] = useState(false);
  const [uploadAttcahment, setUploadAttcahment] = useState(false);
  const [cancelToken, setCancelToken] = useState(false);
  const [isCommentFetchLoader, setIsCommentFetchLoader] = useState(false);
  const [isDragEnter, setIsDragEnter] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isExceedAttachment, setIsExceedAttachment] = useState(false);
  const messagesEndRef = useRef(null);
  const [isDeleteAlert, setIsDeleteAlert] = useState({
    id: "",
    status: false
  });

  const scrollToBottom = () => {
    const scroll =
      messagesEndRef.current.scrollHeight - messagesEndRef.current.clientHeight;
    messagesEndRef.current.scrollTo(0, scroll);
  };
  const projectCommentRef = useRef(null);
  React.useEffect(() => {
    projectCommentRef.current = projectComments;
  });
  useEffect(() => {
    if (isOpen) {
      fetchComments(true);
      projectCommentInit(
        addCommentCB,
        deleteCommentCB,
        refreshCommentCB,
        props.auth.accessToken
      );
      handleSocketRoom();
    }
    return () => {
      setCommentMenu(false);
      setIsCommentAddloader(false);
      setProjectComments([]);
      setIsDragEnter(false);
      setIsDeleteAlert({
        status: false,
        id: ""
      });
      setNewCommentIsEmptySpace(true);
      leaveRoom(`project_comment_${projectId}`);
      setNewComment("");
      setFileToUpload(null);
      setUploadAttcahmentProgress(0);
      setIsCommentUploadLoader(false);
    };
  }, [isOpen]);
  const refreshCommentCB = data => {
    fetchComments();
  };

  const addCommentCB = data => {
    try {
      var comment = projectCommentRef.current;
      comment.push(data);
      setProjectComments([...comment]);
      // setNewComment("");
      scrollToBottom();
    } catch (e) {
      console.log(e);
    }
  };

  const toggleAttachmentLimitExceedError = () => {
    setIsExceedAttachment(true);
    setTimeout(() => {
      setIsExceedAttachment(false);
    }, 3000);
    showNotifications(
      <IntlMessages id={"task-details.filesizeerror"} />,
      +"error"
    );
  };

  const deleteCommentCB = data => {
    try {
      var commentArr = projectCommentRef.current;
      var updateComments =
        commentArr &&
        commentArr.filter(commentToDelete => commentToDelete.id !== data.id);

      setProjectComments([...updateComments]);
    } catch (e) {
      console.log(e);
    }
  };
  const handleSocketRoom = () => {
    // Join room
    joinRoom(`project_comment_${projectId}`);
  };

  const updateProgress = percentComplete => {
    console.log(percentComplete, "in");
    setUploadAttcahmentProgress(percentComplete);
    return 0;
  };
  const cancelRequest = () => {
    cancelToken.cancel();
    setFileToUpload(null);
    setIsCommentUploadLoader(false);
    setUploadAttcahmentProgress(false);
  };
  const AddComments = async (e, onDropUploadFile = null) => {
    // e.preventDefault();
    try {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      let commentData = newComment;
      // const trimmedComment = checkSpaceAtEnd(commentData)
      //   ? checkSpaceAtEnd(commentData)
      const trimmedComment = newComment;

      if (trimmedComment) {
        commentData = trimmedComment;
      }
      // setIsCommentAddloader(true);
      if (
        fileToUpload ||
        onDropUploadFile ||
        fileToUpload !== null ||
        onDropUploadFile !== null
      ) {
        let fileData = null;
        if (fileToUpload && fileToUpload !== null) {
          fileData = fileToUpload;
        }
        if (onDropUploadFile && onDropUploadFile !== null) {
          fileData = onDropUploadFile;
        }
        handleUploadAttachmentComment(fileData, commentData);
      } else {
        let payload = {
          roomname: `project_comment_${projectId}`,
          comment: commentData,
          project_id: projectId.toString()
        };
        addProjectcomment(payload, cb => {
          if (cb.status == "Ok") {
            setNewComment("");
            setFileToUpload(null);
            setIsCommentAddloader(false);
          } else {
            showNotifications();
          }
        });
      }
    } catch (e) {
      setIsCommentAddloader(false);
    }
  };
  const handleUploadAttachmentComment = async (fileData, commentData) => {
    try {
      setUploadAttcahment(fileData);
      setFileToUpload(null);
      const MaxFileSize = 20 * 1024 * 1024; /* 20 mb limit */
      if (fileData.size <= MaxFileSize) {
        setUploadAttcahmentProgress(1);
        setIsCommentUploadLoader(true);
        await scrollToBottom();

        const Response = await addcomment(
          {
            attachment: fileData,
            comment: null,
            projectId
          },
          updateProgress,
          cancelToken,
          data => setCancelToken(data)
        );
        setIsCommentUploadLoader(false);
        setUploadAttcahmentProgress(false);

        if (Response) {
          if (Response.error) {
            handleResponse(Response.error.message);

            showNotifications();
          } else {
            var comment = { projectComments };
            // comment[0].projectComment.push(Response.data);

            // setNewComment("");
            // document.getElementById("commentInput").value = "";
            setFileToUpload(null);
            projectcommentRefresh({
              roomname: `project_comment_${projectId}`
            });
            fetchComments();
          }
          setIsCommentAddloader(false);
        }
      } else {
        scrollToBottom();
        toggleAttachmentLimitExceedError();
      }
    } catch (E) {
      console.log(E);

      showNotifications();
    }
  };
  const onReactionAddInput = data => {
    setNewComment(prev => prev + "" + data);
  };
  const Attachment = {
    border: "1 solid",
    width: 42,
    height: 41,
    objectFit: "fill",
    textAlign: "center"
  };
  const profileImage = {
    borderRadius: "50%",
    border: "1 solid",
    width: 42,
    height: 41,
    objectFit: "fill",
    textAlign: "center"
  };
  const handleDropAttachment = (event, files) => {
    setFileToUpload(files);
    AddComments(event, files);
  };
  const fetchComments = async (isLoading = false) => {
    // const messagesEndRef = useRef(null)
    try {
      if (projectId) {
        setIsCommentFetchLoader(isLoading);
        const Response = await getcomments(projectId);
        if (Response) {
          if (Response.error) {
            handleResponse(Response.error.message);
            showNotifications();
          } else {
            let unsortedComments =
              Response.data.length > 0 && Response.data[0].projectComment;

            unsortedComments.sort((a, b) => a.id - b.id);
            setIsCommentFetchLoader(false);

            setProjectComments(unsortedComments);
            projectCommentRef.current = unsortedComments;
            window.scroll({
              top: document.body.offsetHeight,
              left: 0,
              behavior: "smooth"
            });

            scrollToBottom();
          }
          setIsCommentFetchLoader(false);
        }
      }
    } catch (e) {
      setIsCommentFetchLoader(false);
    }
  };
  const handleDeleteComment = async commentId => {
    if (projectId && commentId) {
      let payload = {
        roomname: `project_comment_${projectId}`,
        comment_id: commentId.toString(),
        project_id: projectId.toString()
      };
      deleteProjectcomment(payload, cb => {
        if (cb.status == "Ok") {
        } else {
          showNotifications();
        }
      });
    }
    // const Response = await deleteComment(
    //   {
    //     project_id: projectId
    //   },
    //   commentId
    // );
    // if (Response) {
    //   if (Response.error) {
    //     handleResponse(Response.error.message);
    //     showNotifications();
    //   }
    //   if (Response.message) {
    //     let comments = projectComments;
    //     comments.map((data, i) => {
    //       if (data.id == commentId) {
    //         comments.splice(i, 1);
    //       }
    //     });

    //     setProjectComments(comments);
    //     fetchComments();
    //     projectcommentRefresh({ roomname: `project_comment_${projectId}` });
    //   }
    // }
    // }
  };
  const checkIsOpen = () => {
    if (isOpen) {
      fetchComments();
    }
    return isOpen;
  };

  const toggleCommentMenu = (target, commentData) => {
    if (commentMenu) {
      setCommentMenu(!commentMenu);
      setActiveCommentMenu(null);
      setActiveCommentDetail(null);
    } else {
      setCommentMenu(!commentMenu);
      setActiveCommentMenu(target);
      setActiveCommentDetail(commentData);
    }
  };

  return (
    <div>
      <GlobalWrapper>
        <Modal
          // isOpen={isOpen}
          isOpen={isOpen}
          toggle={() => toggleCommentModal()}
          size="lg"
          centered
          autoFocus={false}
        >
          <ModalHeader
            style={{
              margin: "0px 9px",
              padding: " 20px 11px",
              borderBottom: "1px solid red"
            }}
          >
            <div className="fs-15 flex-x " style={{ fontWeight: "bold" }}>
              <div
                className="flex-1"
                style={{ fontWeight: "bold", color: "black", fontsize: 15 }}
              >
                {name}
                {<IntlMessages id={"project.s_comments"} />}
              </div>

              <img
                src={ProjectCollaboratorsCross}
                style={{
                  height: 14,
                  width: 12,
                  marginRight: "0px",
                  marginTop: "0px",
                  textAlign: "center",
                  position: "absolute",
                  top: 21,
                  right: 22,
                  cursor: "pointer"
                }}
                onClick={() => toggleCommentModal()}
                alt=""
              ></img>
            </div>
          </ModalHeader>
          <ModalBody style={{ padding: "20px ", paddingTop: 0 }}>
            {/* {isDragEnter && (
              <div
                className="text"
                style={{
                  color: "red"
                }}
              >
                Drop your file here
              </div>
            )} */}
            <FileDrop
              onFrameDragEnter={event => {
                setIsDragEnter(true);
                console.log(event);
              }}
              onFrameDragLeave={event => {
                setIsDragEnter(false);
              }}
              onFrameDrop={event => {
                setIsDragEnter(false);
              }}
              onDragOver={event => {}}
              onDragLeave={event => {}}
              onDrop={(files, event) => {
                handleDropAttachment(event, files[0]);
                /*  ** to upload multiple files  
                we have to make one array to store all files and there uploading token also progress to display at user end
                */
                // if (files && files?.length > 0) {
                //   files && Array.from(files).map((file) => {
                //     handleDropAttachment(event, file);
                //   })
                // }
                // AddComments(event,files[0]);
              }}
            >
              <div
                style={{ opacity: isExceedAttachment || isDragEnter ? 0 : 1 }}
              >
                <div
                  style={{
                    // border: isExceedAttachment ? "1px solid red" : "none",
                    padding: 1
                  }}
                >
                  <div
                    className=" div-fixed"
                    style={{
                      height: "460px",
                      overflow: "auto",
                      paddingTop: "0px",
                      marginBottom: "15px"
                    }}
                    id="commentView"
                    ref={messagesEndRef}
                  >
                    {isCommentFetchLoader && <BusinessListShimmer />}

                    {projectComments &&
                      projectComments.map((data, i) => {
                        return (
                          <div
                            className="ptb-15 ma-0 flex-x "
                            key={i}
                            style={{ borderBottom: "1px solid #EBEBEB" }}
                          >
                            <div className="">
                              <div
                                style={profileBackground(
                                  data.commentBy.name && data.commentBy.name,
                                  38,
                                  38,
                                  19
                                )}
                              >
                                {data.commentBy.name &&
                                  data.commentBy.name.charAt(0).toUpperCase()}
                              </div>
                            </div>
                            <div className="mlr-10 flex-1">
                              <p className="fs-15 demi-bold-text">
                                <span
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    color: "black"
                                  }}
                                >
                                  {" "}
                                  {data.commentBy.name}
                                </span>
                                <span
                                  //chenge color  #d8d8d8
                                  style={{
                                    color: "#808080",
                                    backgroundcolor: "white",
                                    padding: 5,
                                    borderradius: 5,
                                    fontSize: "12px",
                                    fontweight: 500
                                  }}
                                >
                                  {moment(data.createdAt).format(
                                    "DD MMM YYYY"
                                  ) == moment().format("DD MMM YYYY")
                                    ? `${moment(data.createdAt).fromNow()}`
                                    : `${moment(data.createdAt).format(
                                        "DD MMM YYYY hh:mm a"
                                      )}`}
                                </span>
                              </p>
                              {data.comment !== null &&
                                data.comment !== "null" &&
                                data.comment !== "<p><br></p>" && (
                                  <CommentModalWrapper>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        width: "100%",
                                        wordBreak: "break-all",
                                        color: "black",
                                        justifyContent: "center",
                                        alignItems: " center",
                                        whiteSpace: "pre-wrap"
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: data.comment
                                      }}
                                    />
                                  </CommentModalWrapper>
                                )}
                              {data.commentMeta &&
                                data.commentMeta !== null && (
                                  <CommentAttachment comment={data} />
                                )}
                            </div>
                            <div
                              id={`moreoptions-${i}`}
                              className="cursor-pointer"
                              style={{
                                height: 40,
                                display: "flex",
                                alignItems: "flex-start"
                              }}
                            >
                              {props.auth.user.id === data.commentBy.id && (
                                <div
                                  onClick={e => {
                                    e.stopPropagation();
                                    toggleCommentMenu(
                                      `commentMenu-${data.id}`,
                                      {
                                        projectId: projectId,
                                        commentId: data.id
                                      }
                                    );
                                  }}
                                  id={`commentMenu-${data.id}`}
                                >
                                  {/* <i className="fas fa-ellipsis-h"></i> */}
                                  <img
                                    src={threeDot_Icon}
                                    style={{
                                      marginBottom: "4px",
                                      height: 6,
                                      width: 22
                                    }}
                                  ></img>
                                </div>
                              )}
                            </div>
                            <MoreOptionsMenu />
                            {/* <div className="task-icons">
                        <i
                          onClick={() =>
                            setIsDeleteAlert({
                              id: data.id,
                              status: true
                            })
                          }
                          className="fas fa-trash"
                        ></i>
                      </div> */}
                          </div>
                        );
                      })}
                    {isCommentUploadLoader && (
                      <div
                        className="row ptb-15"
                        style={{ width: 270, marginLeft: 10 }}
                      >
                        <div
                          style={{
                            width: "100%",
                            paddingLeft: 2,
                            fontSize: 14,
                            color: "rgb(0, 0, 0)",
                            textDecoration: "underline"
                          }}
                        >
                          {uploadAttcahment &&
                            uploadAttcahment !== null &&
                            uploadAttcahment.name}
                        </div>
                        <div className="flex-x" style={{ width: "100%" }}>
                          <div style={{ width: "80%", display: "inline-flex" }}>
                            <Progress
                              symbolClassName="d-none"
                              theme={{
                                active: {
                                  color: topbarTheme.buttonColor
                                }
                              }}
                              percent={attcahmentProgress}
                            />
                            &nbsp;{attcahmentProgress}%
                          </div>
                          <div
                            style={{ cursor: "pointer", paddingLeft: 5 }}
                            onClick={() => cancelRequest()}
                          >
                            <i
                              className="fa fa-times-circle"
                              style={{
                                fontSize: 14,
                                color: "red"
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <form onSubmit={e => AddComments(e)}>
                  <div
                    style={{
                      border:
                        newComment?.length < 4000
                          ? "1px solid #E6E6E6"
                          : "1px solid red",
                      borderRadius: "5px",
                      minHeight: 50
                    }}
                  >
                    <div
                      className="flex-x"
                      style={{
                        padding: "10px 15px  10px 0px"
                        // display: "flex",alignItems: "baseline"
                      }}
                    >
                      <div style={{ width: "80%", display: "block" }}>
                        <div className="flex-1">
                          <CommentEditorInput
                            isEmptySpace={newCommentisEmptySpace}
                            setIsEmptySpace={setNewCommentIsEmptySpace}
                            id="commentInput"
                            value={newComment}
                            placeholder={"write something here..."}
                            onChangeValue={setNewComment}
                            collaboratorsList={
                              collaboratorList ? collaboratorList : []
                            }
                            handleSubmitData={data => AddComments()}
                          />
                          {/* <textarea
                      //  ref={messagesEndRef}
                      autoFocus
                      placeholder="Write something here..."
                      rows="5"
                      value={newComment}
                      className=" message-text-area border-bottom-radius comment-input-no-outline"
                      style={{
                        width: "100% !important",
                        border: 0,
                        outline: "none",
                        resize: "none",
                        minHeight: "80px",
                        padding: "0px 10px"
                      }}
                      id="commentInput"
                      onChange={e => setNewComment(e.target.value)}
                    /> */}
                        </div>

                        {/* <div> */}
                        {fileToUpload && fileToUpload !== null && (
                          <div>
                            <label
                              style={{
                                padding: 10
                              }}
                            >
                              {fileToUpload.name}
                            </label>
                            <i
                              className=" fas fa-trash"
                              style={{}}
                              onClick={() => setFileToUpload(null)}
                            />
                          </div>
                        )}
                      </div>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems:
                            newComment.length > 0 || fileToUpload !== null
                              ? "flex-end"
                              : "center"
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <label
                            htmlFor="commentsAttachment"
                            style={{ marginRight: "8px", marginBottom: 0 }}
                          >
                            {/* <i className=" fas fa-paperclip fs-14"></i> */}
                            <img
                              style={{ cursor: "pointer" }}
                              src={attachment}
                              height="20"
                              width="20"
                              // style={{
                              //   marginRight: "14px",
                              //   cursor: "pointer"
                              //   }}
                              //   className=" "
                              //   alt="Labels"
                            />
                          </label>
                          <input
                            style={{ display: "none", cursor: "pointer" }}
                            type="file"
                            id="commentsAttachment"
                            name="commentsAttachment"
                            onChange={e => {
                              if (e.target.files.length > 0) {
                                setFileToUpload(e.target.files[0]);
                                handleDropAttachment(e, e.target.files[0]);
                              }
                            }}
                          />

                          {isCommentAddLoader ? (
                            <div style={{ width: "120px" }}>
                              <Loader height="25px" width="30px" />
                            </div>
                          ) : (
                            <button
                              className="button btn"
                              type="submit"
                              style={{
                                // backgroundColor:
                                //   (newComment &&
                                //     newComment.length > 0 &&
                                //     newCommentisEmptySpace) ||
                                //   fileToUpload !== null
                                //     ? topbarTheme.buttonColor
                                //     : topbarTheme.buttonColor,
                                backgroundColor: topbarTheme.buttonColor,
                                color: "#FFFFFF",
                                padding: "6px 12px",
                                width:
                                  props?.LanguageSwitcher?.language?.locale !=
                                    "en" && "200px"
                              }}
                              disabled={
                                (newComment.length > 0 &&
                                  newCommentisEmptySpace) ||
                                fileToUpload !== null
                                  ? false
                                  : true
                              }
                            >
                              <IntlMessages
                                id={"task-details.add_comment_button"}
                              />

                              {/* <i className="fas fa-paper-plane"></i> */}
                            </button>
                          )}
                        </div>
                      </span>
                      {/* </div> */}
                    </div>
                  </div>
                </form>
              </div>

              <CustomAlert
                action="delete"
                messageType={InjectPlaceHolderMessage(
                  "notification.comment",
                  props?.LanguageSwitcher?.language?.languageId
                )}
                PRIMARY_COLOR={PRIMARY_COLOR}
                show={isDeleteAlert.id && isDeleteAlert.status}
                onConfirm={() => {
                  isDeleteAlert.status && handleDeleteComment(isDeleteAlert.id);
                  setIsDeleteAlert({ id: "", status: false });
                }}
                onCancel={() => setIsDeleteAlert({ id: "", status: false })}
              />
              <CommentMenu
                isMenu={commentMenu}
                toggleMenu={() => toggleCommentMenu()}
                target={activeCommentMenu}
                editComment={() => {
                  setNewComment(true);
                  setCommentMenu(false);
                  setActiveCommentMenu("");
                }}
                deleteComment={() => {
                  setIsDeleteAlert({
                    id: activeCommentDetail.commentId,
                    status: true
                  });

                  setCommentMenu(!commentMenu);
                }}
              />
            </FileDrop>
            {isExceedAttachment && (
              <ExceedLimit
                setIsExceedAttachment={setIsExceedAttachment}
                setIsDragEnter={setIsDragEnter}
                props={props}
              />
            )}
            {isDragEnter && (
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "40%",
                  fontSize: 16,
                  fontWeight: "600"
                }}
              >
                Drop Files to upload.
              </div>
            )}
          </ModalBody>
        </Modal>
      </GlobalWrapper>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

const ExceedLimit = ({ setIsExceedAttachment, setIsDragEnter, props }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "40%",
        left: props?.language?.language.languageId !== "english" ? "23%" : "38%"
      }}
    >
      <span
        style={{
          fontSize: 16,
          fontWeight: "600",
          color: "red"
        }}
      >
        <IntlMessages id={"task-details.filesizeerror"} />
      </span>
      {/* <span>({timer}s)</span>{" "} or */}
      <u
        style={{
          color: "black",
          cursor: "pointer",
          fontSize: 14,
          paddingLeft: 3
        }}
        onClick={() => {
          setIsExceedAttachment(false);
          setIsDragEnter(false);
        }}
      >
        Close
      </u>
    </div>
  );
};
export default connect(mapStateToProps)(CommentModal);
