import businessAction from "./actions";

const initState = {
  business: localStorage.getItem("business")
    ? localStorage.getItem("business")
    : null
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case businessAction.DEFAULT_BUSINESS:
      return {
        ...state,
        business: action.business
      };
    case businessAction.UPDATE_DEFAULT_BUSINESS:
      return {
        ...state,
        business: action.business
      };
    case businessAction.PARTICIPANT:
      return {
        ...state,
        participant: action.participant
      };
    case businessAction.UPDATE_PARTICIPANT:
      return {
        ...state,
        participant: action.participant
      };
    default:
      return state;
  }
}
