import React from "react";
import { getColorCode } from "components/projects/utils/color";
import IconTint from "react-icon-tint";
import { iconTag } from "helper/constant";
const LabelsComponent = props => {
  const { labelsArr } = props;
  let sortLabels = [];
  sortLabels =
    labelsArr && labelsArr.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <>
      {sortLabels &&
        sortLabels
          .sort((a, b) => a.name < b.name)
          .map((item, index) => {
            return (
              <>
                <div
                  className="my-day-label flex-x  fs-14 nevy--text align-center overflow-hidden"
                  style={{
                    margin: "3px 25px  3px 0px"
                  }}
                >
                  <div
                    style={{
                      height: 20,
                      width: 18,
                      marginRight: 5
                    }}
                  >
                    <IconTint
                      src={iconTag}
                      style={{
                        position: "relative",
                        top: "1px",
                        height: "18",
                        width: "18"
                      }}
                      color={getColorCode(item.color)}
                    />
                  </div>
                  {/* <i
                                        className="fa fa-tag"
                                        style={{
                                          color: getColorCode(item.color),
                                          position: "relative",
                                          top: "1px"
                                        }}
                                      /> */}
                  <span
                    style={{
                      // color: getColorCode(item.color),
                      color: "black",
                      paddingLeft: 5,
                      maxWidth: "300px",
                      whiteSpace: "nowrap",
                      textOverflow: " ellipsis",
                      overflow: "hidden"
                    }}
                  >
                    <span>{item.name}</span>
                  </span>
                </div>
              </>
            );
          })}
    </>
  );
};
export default LabelsComponent;
