import Enlang from "./entries/en-US";
import DeLang from "./entries/de_DE";
import NlLang from "./entries/nl_NL";
import { addLocaleData } from "react-intl";

const AppLocale = {
  en: Enlang,
  de: DeLang,
  nl: NlLang
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.de.data);
addLocaleData(AppLocale.nl.data);

export default AppLocale;
