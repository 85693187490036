import React, { useState } from "react";
import moment from "moment";
import ReactionsPopover from "components/projects/comments/ReactionsPopover";
import ReactionsEmoji from "../../projects/comments/ReactionsEmoji";
import { profileBackground } from "helper/methods/getUserAvatarColor";
function TaskCommentList({
  Activity,
  onDelete,
  addReactionsComment,
  user,
  handleRemoveCommentReactions,
  checkEmojiExist,
  lastActivity,
  currentLoggedInUser
}) {
  const [emojiArr, setEmojiArr] = useState([]);
  const Attachment = {
    border: "1 solid",
    width: 42,
    height: 41,
    objectFit: "fill",
    textAlign: "center"
  };
  //

  //
  //
  const profileImage = {
    borderRadius: "50%",
    border: "1 solid",
    width: 22,
    height: 22,
    objectFit: "cover",
    textAlign: "center",
    background: "#e2e2e2"
  };
  const checkisHTML = taskComments => {
    return taskComments.match(/(.|\n)*?>/g);
  };
  const getCodeForEmoji = unicode => {
    {
      const replacedCode = unicode.replace("U+", "0x");
      return String.fromCodePoint(replacedCode);
    }
  };
  const checkMentionExist = comment => {
    var endStringRegExNew = comment;
    endStringRegExNew = endStringRegExNew.replace(
      new RegExp(/([@A-Z])\w+/g),
      `<span style="color: red;">$&</span>`
    );
    return endStringRegExNew;
  };
  return (
    <div
      style={{
        borderBottom: lastActivity ? "none" : "1px solid #e8e8e8",
        paddingBottom: 10
      }}
    >
      <div
        className="pt-10 ma-0 flex-x align-center"
        // key={i}
        style={{ alignItems: "baseline" }}
      >
        <div style={{ position: "relative", left: "4px" }}>
          {Activity?.user?.url ? (
            <div>
              <img
                style={profileImage}
                src={Activity?.user?.url && Activity?.user?.url}
              />
            </div>
          ) : (
            <div
              style={profileBackground(
                Activity?.commentBy?.name && Activity?.commentBy?.name,
                22,
                22,
                12
              )}
            >
              {Activity.commentBy.name &&
                Activity.commentBy.name.charAt(0).toUpperCase()}
            </div>
          )}
        </div>
        <div className="mlr-14 flex-1">
          <div className="fs-14 demi-bold-text">
            <div
              style={{
                display: "flex",
                alignItems: "baseline"
              }}
            >
              <span
                style={{
                  // marginTop: "4px",
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "black"
                }}
              >
                {Activity.commentBy.name}
              </span>
              <span
                // className="text-muted"
                style={{
                  paddingLeft: "8px",
                  // marginTop: "4px",
                  color: "#808080",
                  fontSize: "12px"
                }}
              >
                <small>
                  {/* {moment(data.createdAt).format("DD MMM YYYY hh:mm a")} */}
                  {moment(Activity.createdAt).format("DD MMM YYYY") ==
                  moment().format("DD MMM YYYY")
                    ? `${moment(Activity.createdAt).fromNow()}`
                    : `${moment(Activity.createdAt).format(
                        "DD MMM YYYY hh:mm a"
                      )}`}
                </small>
              </span>
              <div style={{ paddingLeft: 5, width: "35px" }}>
                <ReactionsPopover
                  cId={Activity.id}
                  emojiArr={emojiArr}
                  setEmojiArr={data => setEmojiArr(data)}
                  addReactions={(unicode, id) => {
                    var check = checkEmojiExist(unicode, id);
                    {
                      check.status
                        ? handleRemoveCommentReactions(check.id, Activity.id)
                        : addReactionsComment(unicode, id);
                    }
                  }}
                />
              </div>
              {currentLoggedInUser &&
                currentLoggedInUser === Activity.commentBy.id && (
                  <u
                    onClick={() => onDelete(Activity.id)}
                    // className="fas fa-trash text-muted fs-14"
                    style={{
                      cursor: "pointer",
                      fontSize: "12px",
                      // marginTop: "5px",
                      paddingLeft: "1px",
                      color: "#808080",
                      width: "35px"
                    }}
                  >
                    Delete
                  </u>
                )}
            </div>
          </div>
          {checkisHTML(Activity.comment) ? (
            <div
              // className="text-muted"
              style={{
                overflowWrap: "break-word",
                wordWrap: "break-word",
                hyphens: "auto",

                //chenge style in comment box data   #d8d8d8
                fontSize: "13px",
                backgroundColor: "white",
                padding: "6px 12px",
                borderRadius: "5px",
                border: "1px solid #EBEBEB",
                color: "black"
              }}
              dangerouslySetInnerHTML={{
                __html:
                  Activity &&
                  Activity.comment !== null &&
                  Activity.comment !== "null"
                    ? Activity.comment
                    : ""
              }}
            ></div>
          ) : (
            <div
              className="text-muted"
              style={{
                overflowWrap: "break-word",
                wordWrap: "break-word",
                hyphens: "auto",
                color: "black"
              }}
              dangerouslySetInnerHTML={{
                __html:
                  Activity &&
                  Activity.comment !== null &&
                  Activity.comment !== "null"
                    ? checkMentionExist(Activity.comment)
                    : ""
              }}
            >
              {/* {Activity.comment} */}
            </div>
          )}
          {Activity.commentMeta && Activity.commentMeta !== null && (
            <div className={"fs-14"}>
              <a href={Activity.commentMeta.url} target="_blank">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <i
                    className=" fas fa-paperclip"
                    style={{ paddingRight: 5 }}
                  />
                  <p> {Activity.commentMeta.originalname}</p>
                </div>
              </a>
            </div>
          )}
        </div>

        {/* change data */}
      </div>
      {Activity.hasOwnProperty("commentEmoji") &&
        Activity.commentEmoji &&
        Activity.commentEmoji.length > 0 && (
          <div className=" " style={{ paddingLeft: "52px" }}>
            <ReactionsEmoji
              cID={Activity && Activity.id}
              userReactions={Activity.commentEmoji && Activity.commentEmoji}
              getCodeForEmoji={code => getCodeForEmoji(code)}
              user={user}
              handleRemoveCommentReactions={id => {
                handleRemoveCommentReactions(id, Activity.id);
              }}
              addCommentReactions={(unicode, id) => {
                addReactionsComment(unicode, Activity.id);
              }}
            />
            {/* {Activity.commentEmoji && Activity.commentEmoji.length > 0 && (
            <div className="plr-2">
              <ReactionsEmoji
                cId={Activity.id}
                emojiArr={emojiArr}
                setEmojiArr={data => setEmojiArr(data)}
                addReactions={(unicode, id) => {
                  addReactionsComment(unicode, id);
                }}
                iconType="plus"
              />
            </div>
          )} */}
          </div>
        )}
    </div>
  );
}

export default TaskCommentList;
