import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { HeaderDownArrowImage } from "helper/constant";

import { withRouter } from "react-router-dom";
import labelActions from "redux/labels/actions";
import projectActions from "redux/projects/actions";
import businessActions from "redux/business/actions";
import AuthActions from "redux/auth/actions";
import IntlMessages from "util/intlMessages";
const { update_token, update_business } = AuthActions;
const { default_business } = businessActions;
const { set_labels, update_Label } = labelActions;
const { set_projects, update_recent_Project } = projectActions;
const GlobalTaskActivitySelection = props => {
  const [isFavourites, setIsFavourites] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const { history, toggleFavourites } = props;

  useEffect(() => {
    setProjectList(props.projects.projects);
  });
  return (
    <>
      <div className="Workspace-name-header pl-33 ">
        <div
          //   id="global-header-favourites"
          style={{
            backgroundColor: "rgb(255 255 255 / 18%)",
            height: "36px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "5px",
            cursor: "pointer"
          }}
          onClick={() => {
            props.history.push(`/activity`);
          }}
        >
          <span
            style={{
              display: "inline-block",
              alignItems: "center",
              padding: "6px 12px",
              color: "white",
              fontSize: "14px",
              fontWeight: 600,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            <IntlMessages id={"header.activity"} />
          </span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, {
    update_token,
    update_business,
    default_business,
    set_labels,
    update_Label,
    set_projects,
    update_recent_Project
  })
)(GlobalTaskActivitySelection);
