import React, { useState, useEffect } from "react";
import chroma from "chroma-js";
import Switch from "react-switch";
import { updateProject, getProject } from "helper/services/projectServices";
import { Alert } from "reactstrap";
import AddProjectWrapper from "./AddProjectWrapper.style";
import ColorPopover from "components/projects/ProjectColorDropdown";
import {
  ProjectColors,
  ProjectColorsGn,
  ProjectColorsDut
} from "./utils/color";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import DescriptionEditor from "components/business/DescriptionEdiotr";
import { getBusinessProject } from "helper/services/businessServices";
import DatepickerWrapper from "components/forms/alldatepickers/datepicker.style";
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import { list, gridicon } from "helper/constant";
import alertActions from "redux/globalAlert/actions";
import projectActions from "redux/projects/actions";
import AddProjectWorkspaceWrapper from "./AddProjectWorkspaceWrapper.style";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import { de } from "date-fns/locale";
import { nl } from "date-fns/locale";
import moment from "moment";

const { remove_Project } = projectActions;
const { open_workpsace_alert, open_project_alert, close_alert } = alertActions;

const EditProject = props => {
  const {
    modal,
    setmodal,
    project,
    setProject,
    updateProjectToRedux,
    showNotifications,
    topbarTheme,
    defaultWorkspaceId,
    languageName
  } = props;
  const [isDisabled, setIsDisabled] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedColor, setSelectedColor] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [defaultView, setDefaultView] = useState("list");
  const [workspaceData, setWorkspaceData] = useState([]);
  const [MinimumEndDate, setMinimumEndDate] = useState(new Date());
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [isFavorite, setIsFavorites] = useState(false);
  const [description, setDescription] = useState("");
  const [projectName, setProjectName] = useState(false);
  const [locale, setLocale] = useState();
  const [options, setOption] = useState();
  const [isProjectNameError, seisProjectNameError] = useState({
    status: false,
    message: ""
  });
  const [isUserSelectedColor, setIsUserSelectedColor] = useState(false);
  const handleIsFavourite = () => {
    setIsFavorites(!isFavorite);
  };
  const handleResponse = message => {
    try {
      if (message == "NOT_WORKSPACE_COLLABORATOR") {
        // status:action.alert.status, message:action.alert.message,action:'open_businesslist'

        props.open_workpsace_alert({
          status: true,
          message: "workspace"
        });
      }
      if (message == "USER_NOT_PROJECT_COLABORATOR") {
        props.open_project_alert({
          status: true,
          message: "project"
        });
        if (project?.id) {
          props.remove_Project(project?.id);
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (modal) {
      if (project) {
        setProjectName(project?.name);

        setStartDate(
          project?.startDate
            ? moment(new Date(project?.startDate)).toDate()
            : startDate
        );
        setEndDate(
          project?.endDate
            ? moment(new Date(project?.endDate)).toDate()
            : endDate
        );
        languageName == "english"
          ? ProjectColors.filter((item, key) => {
              return item.value == project?.color
                ? setSelectedColor(item.label)
                : "";
            })
          : ProjectColorsGn.filter((item, key) => {
              return item.value == project?.color
                ? setSelectedColor(item.label)
                : "";
            });
        setIsFavorites(project?.isFavorite);
        setDescription(project?.description);
        setDefaultView(project?.defaultView ? project?.defaultView : "list");

        retriveBusiness();
      }
    }
    if (props.language.locale == "de") {
      setLocale(de);
      setOption(ProjectColorsGn);
      setSelectedColor(ProjectColorsGn[1].label);
    } else if (props.language.locale == "nl") {
      setLocale(nl);
      setOption(ProjectColorsDut);
      setSelectedColor(ProjectColorsDut[1].label);
    } else {
      setLocale(props.language.locale);
      setOption(ProjectColors);
      setSelectedColor(ProjectColors[1].label);
    }

    return () => {
      setIsFavorites(false);
      setIsErrorVisible(false);
      setIsDisabled(false);
      setErrorMessage(null);
      setIsLoader(false);
      setDefaultView("list");
      setProjectName(false);
      setDescription("");
    };
  }, [modal]);

  const getcolor = colorname => {
    let color = null;
    ProjectColors.filter((data, i) => {
      if (data.label == colorname) {
        color = data;
        return data;
      }
    });
    return color;
  };
  const colourStyles = {
    control: (base, state) => ({
      ...base,
      background: "#ffff",

      // match with the menu
      border: state.isDisabled
        ? null
        : state.isSelected
        ? "#909090"
        : state.isFocused
        ? "#f6f6f6"
        : null
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#909090"
          : isFocused
          ? "#f6f6f6"
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast("grey", "white") > 2
            ? "white"
            : "black"
          : "#808080",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6")
        }
      };
    },
    singleValue: styles => ({ ...styles, color: "black" })
  };

  const handleChangeStartDate = date => {
    setStartDate(date);
    // setEndDate();
    setMinimumEndDate(date);
  };

  const checkForDefaulWorkspace = business => {
    if (defaultWorkspaceId) {
      business &&
        business.map(data => {
          if (data.id === defaultWorkspaceId) {
            setSelectedWorkspace({
              ...data,
              value: data.title,
              label: data.title
            });
          }
        });
    } else {
      business &&
        business.map(data => {
          if (data.id === props.auth.user.businessId) {
            setSelectedWorkspace({
              ...data,
              value: data.title,
              label: data.title
            });
          }
        });
    }
  };

  const retriveBusiness = async () => {
    try {
      const Response = await getBusinessProject();
      if (Response) {
        if (Response.data) {
          var businessArr = [];
          Response.data &&
            Response.data.map(a => {
              businessArr.push({
                ...a.business,
                value: a.business.title,
                label: a.business.title
              });
              setWorkspaceData([...businessArr]);
              checkForDefaulWorkspace([...businessArr]);
              // if(a.business.id==props.auth.businessId){
              //   setSelectedWorkspace({
              //     ...a.business,
              //   value:a.business.title,
              //   label:a.business.title})
              // }
            });
        }
        if (Response.error) {
          handleResponse(Response.error.message);
        }
      }
    } catch (e) {}
  };
  const handleProjectNameValidatiton = e => {
    const validateName = () => {
      const validate = e.target.value.match(/^\S+\w{0,50}\S{0,}/g);
      if (
        e.target.value.length > 0 &&
        e.target.value.length < 51 &&
        validate !== null &&
        validate
      ) {
        seisProjectNameError({ status: false, message: null });
        return true;
      }
      if (e.target.value.length > 50) {
        seisProjectNameError({
          status: true,
          message: "Maximum 50 characters are allowed"
        });
        return false;
      }
      if (e.target.value.length <= 0)
        seisProjectNameError({
          status: true,
          message: <IntlMessages id={"workspace.nameisrequired"} />
        });
      return false;
    };
    setProjectName(e.target.value);
    setIsErrorVisible(false);
    validateName() ? setIsDisabled(false) : setIsDisabled(true);
  };
  const handleChangeEndDate = date => setEndDate(date);

  const handleResetDate = () => {
    setStartDate();
    setEndDate();
  };

  const handleProjectSubmit = async e => {
    e.preventDefault();
    setIsErrorVisible(false);

    if (
      e.target.name.value &&
      // e.target.viewoption.value &&
      project &&
      project.id
    ) {
      setIsLoader(true);
      try {
        const Response = await updateProject(
          {
            name: e.target.name.value,
            isFavorite: e.target.addtoFavorite.checked,

            // description: e.target.businessDescription.value,
            description: e.target.projectDescription?.value
              ? e.target.projectDescription.value
              : description,
            // businessId: selectedWorkspace.id.toString(),

            startDate: moment(new Date(startDate)).toDate(),
            endDate: moment(new Date(endDate)).toDate(),
            color: selectedColor == "grau" ? "grey" : selectedColor,
            defaultView: e.target.View.value
          },
          project.id
        );
        if (Response) {
          setIsLoader(false);
          if (Response.error) {
            handleResponse(Response.error.message);
            showNotifications();

            setIsErrorVisible(true);
            setErrorMessage(
              Response.error.message == "USER_NOT_PROJECT_COLABORATOR"
                ? "You are no longer part of this project"
                : Response.error.message
            );
          } else if (Response.errors) {
            handleResponse(Response.errors.message);
            showNotifications();
            setIsErrorVisible(true);

            setErrorMessage(
              Response.errors.message == "USER_NOT_PROJECT_COLABORATOR"
                ? "You are no longer part of this project"
                : Response.errors.message
            );
          } else {
            setIsErrorVisible(false);
            if (Response.data) {
              setProject(Response.data);
              // let payload = {
              //   roomname: `${businessSocketRoomPrefix}${businessId}`
              // };
              // updateBusiness(payload, cb => {
              //   if (cb.status == "Ok") {
              //   }
              // });
              updateProjectToRedux(Response.data);
              setmodal(!modal);
              showNotifications(Response.message, "success");
              // sidebarData.map((data, i) => {
              //   if (data.name === "sidebar.projects") {
              //     data.child.map(childdata => {
              //       if (childdata.id === Response.data.id) {
              //         childdata.listname = Response.data.name;
              //         childdata.color = Response.data.color;
              //       }
              //     });
              //   }
              // });
            }
          }
        }
      } catch (e) {
        setIsLoader(false);
      }
    }
  };

  return (
    <>
      {modal ? (
        <Modal
          isOpen={modal}
          toggle={() => {
            setmodal(!modal);
            setIsErrorVisible(false);
            handleResetDate();
          }}
          centered
        >
          <form onSubmit={handleProjectSubmit}>
            {/* <ModalHeader toggle={() => setmodal(!modal)}> */}
            <ModalHeader style={{ backgroundColor: "#ffffff" }}>
              <div>
                {/* <span style={{ paddingRight: 10 }}>
              <i
                className="fa fa-circle fs-10 fs-14"
                style={{ color: getColorCode(selectedColor) }}
              ></i>
            </span> */}
                {/* Add Project */}
                <span
                  style={{
                    fontSize: 20,
                    color: "black",
                    fontWeight: "500",
                    paddingLeft: "9px"
                  }}
                >
                  <IntlMessages id={"project.updateproject"} />
                </span>
              </div>
            </ModalHeader>
            <Alert
              className="c-"
              style={{ backgroundColor: "RED ", color: "white" }}
              isOpen={isErrorVisible}
              toggle={() => setIsErrorVisible(false)}
            >
              {isErrorVisible ? errorMessage : ""}
            </Alert>
            <ModalBody style={{ padding: "15px 25px" }}>
              <div className="form-group">
                {/* Name */}
                <label
                  style={{ fontSize: 14, color: "black", fontWeight: "500" }}
                >
                  <IntlMessages id={"project.name"} />
                </label>
                <AddProjectWrapper>
                  <input
                    autoFocus={true}
                    type="text"
                    className="form-control react-form-input"
                    id="name"
                    defaultValue={projectName}
                    placeholder="Name"
                    onChange={handleProjectNameValidatiton}
                  />
                </AddProjectWrapper>
              </div>
              <div className="form-group mtb-15">
                {/* Description <span style={{ color: "#a0a4a9" }}>(optional)</span>{" "} */}
                <span
                  style={{ fontSize: 14, color: "black", fontWeight: "500" }}
                >
                  <IntlMessages id={"today.description"} />
                  <span style={{ color: "#a0a4a9" }}>
                    <IntlMessages id={"project.description_optional"} />
                  </span>{" "}
                </span>
                {/* <textarea
                  id="businessDescription"
                  className="form-control react-form-input"
                  rows="2"
                  placeholder="Our team organizes everything here."
                  defaultValue={description || ""}
                /> */}
                <AddProjectWorkspaceWrapper>
                  <DescriptionEditor
                    inputValue={description}
                    onChange={data => setDescription(data)}
                    id="projectDescription"
                    className="form-control react-form-input"
                    rows="5"
                    placeholder={InjectPlaceHolderMessage(
                      "workspace.ourteam",
                      props?.LanguageSwitcher?.language?.languageId
                    )}
                  />
                </AddProjectWorkspaceWrapper>
                {/* {isBusinessNameError.status && (
                    <span
                      style={{ color: "red", paddingLeft: 3 }}
                      className="fs-14"
                    >
                      {isBusinessNameError.message}
                    </span>
                  )} */}
              </div>

              <div className="form-group " style={{ margin: 0 }}>
                <Row>
                  <Col className="col-lg-6 col-md-6 col-xl-6 col-sm-6 col-12 pb-15">
                    <DatepickerWrapper {...props}>
                      <div className="roe-card-style">
                        {/* <div className="roe-card-header">Start date</div> */}
                        <span
                          style={{
                            fontSize: 14,
                            color: "black",
                            fontWeight: "500"
                          }}
                        >
                          <label className="roe-card-header">
                            <IntlMessages id={"project.startdate"} />
                          </label>
                        </span>
                        <div className="roe-card-body">
                          <DatePicker
                            placeholderText={InjectPlaceHolderMessage(
                              "project.startdate",
                              props?.LanguageSwitcher?.language?.languageId
                            )}
                            selectsStart
                            minDate={new Date()}
                            id="startDate"
                            name="startDate"
                            value={startDate}
                            disabledKeyboardNavigation
                            selected={startDate}
                            // startDate={startDate}
                            // endDate={endDate}
                            onChange={handleChangeStartDate}
                            // dateFormat="MMM dd, yyyy "
                            dateFormat="dd MMM yyyy"
                            className="custom-datepicker"
                            calendarClassName="custom-calender-class"
                            isClearable
                            locale={locale}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </DatepickerWrapper>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-xl-6 col-sm-6 col-12 ">
                    <DatepickerWrapper {...props}>
                      <div className="roe-card-style">
                        {/* <div className="roe-card-header">End date</div> */}
                        <span
                          style={{
                            fontSize: 14,
                            color: "black",
                            fontWeight: "500"
                          }}
                        >
                          <label className="roe-card-header">
                            <IntlMessages id={"project.enddate"} />
                          </label>
                        </span>
                        <div className="roe-card-body">
                          <DatePicker
                            placeholderText={InjectPlaceHolderMessage(
                              "project.enddate",
                              props?.LanguageSwitcher?.language?.languageId
                            )}
                            id="endDate"
                            name="endDate"
                            selectsEnd
                            minDate={startDate}
                            selected={endDate}
                            // startDate={startDate}
                            // endDate={endDate}
                            disabledKeyboardNavigation
                            value={endDate}
                            onChange={handleChangeEndDate}
                            // dateFormat="MMM dd, yyyy "
                            dateFormat="dd MMM yyyy"
                            className="custom-datepicker"
                            calendarClassName="custom-calender-class"
                            isClearable
                            locale={locale}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </DatepickerWrapper>
                  </Col>
                </Row>
              </div>
              {/* <div className="form-group fs-14 mtb-15 cursor-not-allowed">
            
            <span style={{ fontSize: 14 }}>Choose workspace</span>
            <Select
              styles={colourStyles}
              value={selectedWorkspace}
              placeholder="Select options"
              isDisabled={true}
              onChange={data => {
                setSelectedWorkspace(data);
              }}
              options={workspaceData}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#989797"
                }
              })}
              blurInputOnSelect
              noOptionsMessage={() => "No workspace found"}
            />
          </div> */}

              <div className="form-group fs-14 mb-15 " style={{ zIndex: 0 }}>
                {/* Color */}
                <label
                  style={{ fontSize: 14, color: "black", fontWeight: "500" }}
                >
                  <IntlMessages id={"project.color"} />
                </label>
                {/* <ColorDropdown
                  options={ProjectColors}
                  id="projectcolor"
                  value={selectedColor && selectedColor}
                  changeColor={value => {
                    setSelectedColor(value);
                    setIsUserSelectedColor(true);
                  }}
                /> */}
                <ColorPopover
                  options={options}
                  id="projectcolor"
                  selectedColor={selectedColor && selectedColor}
                  changeColor={value => {
                    setSelectedColor(value);
                    setIsUserSelectedColor(true);
                  }}
                />
              </div>
              <div className="form-group mtb-15">
                <label
                  style={{ fontSize: 14, color: "black", fontWeight: "500" }}
                >
                  <IntlMessages id={"project.view"} />
                </label>
                <Row>
                  <Col className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-xs-1">
                    {" "}
                  </Col>
                  <Col
                    className="col-lg-5 col-md-5 col-xl-5 col-sm-5 col-xs-5  "
                    style={{
                      zIndex: "0",
                      display: "flex",
                      justifyContent: "end",
                      paddingRight: "25px"
                    }}
                  >
                    <div
                      className="pretty p-icon p-round p-smooth "
                      style={{
                        marginRight: "0px",
                        justifyContent: "end",
                        textAlign: "end"
                      }}
                    >
                      <img
                        alt=""
                        type="radio"
                        src={list}
                        style={{
                          color: "#42526e",
                          height: "75%",
                          width: "100%",
                          // padding: "8px",
                          border:
                            defaultView == "list"
                              ? `1px solid   ${topbarTheme.buttonColor}`
                              : "",
                          borderRadius: "5px"
                        }}
                      ></img>
                      <input
                        style={{ width: "100%", height: "91%" }}
                        type="radio"
                        name="View"
                        id="View"
                        value="list"
                        defaultChecked={defaultView == "list" ? true : false}
                        onChange={e => {
                          setDefaultView(e.target.value);
                        }}
                      />
                      {/* <div className="state p-success"> */}
                      <div
                        style={{
                          position: "relative",
                          top: "10px",
                          zIndex: "0 ",
                          display: "flex",
                          // justifyContent: "center",
                          width: "100%",
                          fontSize: "13px"
                          // left: "4px"
                        }}
                      >
                        {defaultView == "list" ? (
                          <i
                            className="fas fa-check-circle"
                            style={{ color: topbarTheme.buttonColor }}
                          ></i>
                        ) : (
                          <i
                            className="far fa-circle fa-1x"
                            style={{ color: "#bdc3c7" }}
                          ></i>
                        )}
                        {/* <i
                            className="icon fas fa-check"
                            style={{
                              backgroundColor: "#ff6b6b",
                              border:
                                defaultView == "list"
                                  ? "1px solid #ddd"
                                  : "#ff6b6b"
                            }}
                          ></i> */}
                        {/* <label>List</label> */}
                        <span style={{ fontSize: 14 }}>
                          <label
                            style={{
                              position: "relative",
                              top: "-1px",
                              left: "5px"
                            }}
                          >
                            <IntlMessages id={"project.list"} />
                          </label>
                        </span>
                      </div>
                    </div>
                    {/* </div> */}
                  </Col>
                  <Col
                    className="col-lg-5 col-md-5 col-xl-5 col-sm-5 col-xs-5  "
                    style={{
                      zIndex: "0",
                      display: "flex",
                      justifyContent: "start",
                      paddingLeft: "25px"
                    }}
                  >
                    <div
                      className="pretty p-icon p-round p-smooth  "
                      style={{
                        marginRight: "0px",
                        justifyContent: "start",
                        textAlign: "start"
                      }}
                    >
                      <img
                        alt=""
                        type="radio"
                        src={gridicon}
                        style={{
                          color: "#42526e",
                          height: "75%",
                          width: "100%",
                          // paddingLeft: "8px",
                          // paddingTop: "8px",
                          border:
                            defaultView == "grid"
                              ? `1px solid   ${topbarTheme.buttonColor}`
                              : "",
                          // border:"1px solid  #dd4b39",
                          borderRadius: "5px"
                          // marginLeft: "8px"
                        }}
                      ></img>
                      <input
                        style={{ width: "100%", height: "91%" }}
                        width="212px"
                        type="radio"
                        name="View"
                        id="View"
                        value="grid"
                        defaultChecked={defaultView == "grid" ? true : false}
                        onChange={e => {
                          setDefaultView(e.target.value);
                        }}
                      />

                      {/* <div className="state p-success"> */}
                      <div
                        style={{
                          position: "relative",
                          top: "10px",
                          // left: "8px",
                          zIndex: "0 ",
                          display: "flex",
                          // justifyContent: "center",
                          width: "100%",
                          fontSize: "13px"
                          // left: "-6px"
                        }}
                      >
                        {defaultView == "grid" ? (
                          <i
                            className="fas fa-check-circle"
                            style={{ color: topbarTheme.buttonColor }}
                          ></i>
                        ) : (
                          <i
                            className="far fa-circle fa-1x"
                            style={{ color: "#bdc3c7" }}
                          ></i>
                        )}
                        {/* <i
                            className="icon fas fa-check"
                            style={{
                              backgroundColor: "#ff6b6b",
                              border:
                                defaultView == "grid"
                                  ? "1px solid #ddd"
                                  : "#ff6b6b"
                            }}
                          ></i> */}
                        {/* <label>Grid</label> */}
                        <span style={{ fontSize: 14 }}>
                          <label
                            style={{
                              position: "relative",
                              top: "-1px",
                              left: "5px"
                            }}
                          >
                            <IntlMessages id={"project.grid"} />
                          </label>
                        </span>
                      </div>
                    </div>
                    {/* </div> */}
                  </Col>
                  <Col className="col-lg-1 col-md-1 col-xl-1 col-sm-1 col-xs-1">
                    {" "}
                  </Col>
                </Row>
              </div>
              <div className="form-group mt-0 mb-0 ml-1">
                <span style={{ marginTop: "5px" }}>
                  <Switch
                    checked={isFavorite}
                    defaultChecked={isFavorite}
                    onChange={handleIsFavourite}
                    onColor={topbarTheme.buttonColor}
                    // onHandleColor="#2693e6"
                    handleDiameter={13}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={19}
                    width={32}
                    className="react-switch"
                    name="addtoFavorite"
                    id="addtoFavorite"
                  />
                </span>
                <span
                  style={{
                    position: "relative",
                    top: "-4px",
                    left: "8px",
                    fontSize: "14px"
                  }}
                >
                  <IntlMessages id={"project.addtofavourite"} />
                </span>

                {/* <div className="pretty p-switch p-fill">
              <input
                type="checkbox"
                name="addtoFavorite"
                id="addtoFavorite"
                checked={isFavorite}
                onChange={e => setIsFavorites(e.target.checked)}
              />
              <div className="state">
          
                <span style={{ fontSize: 14 }}>
                  <label
                    style={{
                      position: "relative",
                      top: "-1px",
                      left: "3px"
                    }}
                  >
                    Add to favorite
                  </label>
                </span>
              </div>
            </div>
           */}
              </div>

              {/* <div className="form-group">
            <div className="">
              <label>View</label>
              <br />
              <div className="pretty p-default p-curve">
                <input
                  type="radio"
                  name="viewoption"
                  defaultChecked
                  id="viewoption"
                  value="list"
                />
                <div className="state p-primary-o">
                  <label className="fs-14 demi-bold-text">List</label>
                </div>
              </div>
              <div className="pretty p-default p-curve">
                <input
                  type="radio"
                  name="viewoption"
                  id="viewoption"
                  value="grid"
                />
                <div className="state p-primary-o">
                  <label className="fs-14 demi-bold-text">Grid</label>
                </div>
              </div>
            </div>
          </div> */}

              <div style={{ paddingTop: "15px", paddingBottom: "10px" }}>
                {isLoader ? (
                  <Loader height="24px" width="20px" />
                ) : (
                  <>
                    <Button
                      className="button btn"
                      onClick={() => {
                        setmodal(!modal);
                        handleResetDate();
                      }}
                      style={{
                        background: "transparent",
                        color: "#000",
                        padding: "0px",
                        height: "42px",
                        width: "49%",
                        border: "1px solid #E6E6E6",
                        marginRight: "1%",
                        borderRadius: "5px"
                      }}
                    >
                      <span
                        style={{
                          fontSize: 14,
                          color: "black",
                          fontWeight: "500"
                        }}
                      >
                        <IntlMessages id={"project.cancel"} />
                      </span>
                    </Button>
                    <Button
                      type="submit"
                      className="btn "
                      disabled={isDisabled}
                      style={{
                        cursor: isDisabled ? "not-allowed" : "",
                        backgroundColor: isDisabled
                          ? "gray"
                          : topbarTheme.buttonColor,
                        border: "none",
                        height: "42px",
                        width: "49%",
                        marginLeft: "1%",
                        borderRadius: "5px"
                      }}
                    >
                      <span
                        style={{
                          fontSize: 14,
                          color: "white",
                          fontWeight: "500"
                        }}
                      >
                        <IntlMessages id={"workspace.update"} />
                      </span>
                    </Button>
                  </>
                )}
              </div>
            </ModalBody>
            {/* <ModalFooter style={{ backgroundColor: "#ffffff" }}>
              <Button
                className="button btn"
                onClick={() => setmodal(!modal)}
                style={{
                  background: "transparent",
                  border: 0,
                  color: "#000",
                  padding: "0px",
                  padding: "12px"
                }}
              > */}
            {/* Cancel */}
            {/* <span style={{ fontSize: 14 }}>Cancel</span>
              </Button>
              {isLoader ? (
                <Loader height="24px" width="20px" />
              ) : (
                <Button
                  type="submit"
                  className="btn "
                  disabled={isDisabled}
                  style={{
                    cursor: isDisabled ? "not-allowed" : "",
                    border: "none",
                    backgroundColor: isDisabled
                      ? "gray"
                      : topbarTheme.buttonColor,
                    border: "none",
                    width: "80px"
                  }}
                > */}
            {/* {isLoader ? <Loader height="24px" width="20px" /> : "Add"} */}
            {/* <span style={{ fontSize: 14 }}>Update</span>
                </Button>
              )}
            </ModalFooter> */}
          </form>
        </Modal>
      ) : (
        ""
      )}{" "}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    businessId: state.auth?.user?.businessId,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher.language }
  };
};

export default connect(mapStateToProps, {
  open_workpsace_alert,
  open_project_alert,
  close_alert,
  remove_Project
})(EditProject);
