import React from "react";

import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";

const NewTaskInput = props => {
  const { autoFocus, inputValue, onChange, onKeyDown, language } = props;
  return (
    <div>
      <input
        autoFocus={autoFocus}
        value={inputValue || ""}
        onKeyDown={e => {
          if (e.key === " Enter") {
            onKeyDown();
          }
        }}
        className="form-control react-form-input-new-task"
        placeholder={InjectPlaceHolderMessage("today.tasktitle", language)}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
};

export default NewTaskInput;
