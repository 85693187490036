import React, { useState } from "react";
import { EmojiJson } from "util/data/EmojiJson";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import "./ReactionPopover.style.css";
import { ReactionsPopoverImage } from "helper/constant";
var temp = 0;
function ReactionsPopover({
  emojiArr,
  setEmojiArr,
  iconType,
  addReactions,
  cId
}) {
  const [isOpen, setIsOpen] = useState(false);
  const onSelect = code => {
    addReactions(getUnicode(code), temp);
    setTimeout(() => {
      setIsOpen(!isOpen);
    }, 100);
  };
  const getUnicode = data => {
    var codeString = data.toString();
    return codeString.replace("0x", "U+");
  };
  return (
    <>
      <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <DropdownToggle
          className="btn-no-padding"
          style={{
            backgroundColor: "transparent",
            border: 0,
            padding: "0px 0px 2px 0px"
            // padding: btnPadding || 12
          }}
        >
          {iconType == "plus" ? (
            <div className="" style={{ paddingTop: 4 }}>
              <div
                className={"border pa-0  text-muted "}
                id="reactions"
                onClick={e => {
                  temp = cId;
                }}
              >
                <i className={"fa fa-plus plr-10 "} />
              </div>
            </div>
          ) : (
            <div>
              <small className="fa-stack fa-1x" style={{ width: "30px" }}>
                {/* <i
              id="reactions"
              className={"far fa-smile text-muted pl-1 fs-14-xlsmall"}
              style={{ color: "grey" }}
              onClick={() => {
                temp = cId;
              }}
            /> */}
                <img
                  alt=""
                  id="reactions"
                  src={ReactionsPopoverImage}
                  height="14"
                  width="15"
                  style={{
                    marginRight: 10,
                    marginLeft: 4,
                    fontSize: 13
                    // position: "relative",
                    // top: "-2px"
                  }}
                  onClick={() => {
                    temp = cId;
                  }}
                />
              </small>
            </div>
          )}
        </DropdownToggle>
        <DropdownMenu
          style={{
            border: "0px",
            boxShadow: "0px 0px 15px #0000001A",
            borderRadius: 30,
            padding: "8px 13px"
          }}
        >
          <div className="flex-x">
            {EmojiJson.map((code, i) => {
              return (
                <div
                  style={{ maxWidth: "570px", cursor: "pointer", padding: 5 }}
                  key={i}
                  className=" roy-menu-list fs-14 "
                  onClick={() => onSelect(code.code)}
                >
                  {code.emoji}
                </div>
              );
            })}
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
}

export default ReactionsPopover;
