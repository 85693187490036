import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import "./alertstyle.css";
import { InfoIconsCoustomAlert } from "helper/constant";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IntlMessages from "./intlMessages";
export default function CustomAlertAction({
  show,
  onConfirm,
  onCancel,
  action,
  PRIMARY_COLOR,
  messageType,
  actionName,
  confirmMessage,
  isuserAction,
  projectDetailsName,
  workspaceDetailsTitle,
  taskTitleGrid,
  taskTitleList,
  sectionArrTitle,
  workspaceRemoveCollaborator,
  projectRemoveCollaborator,
  setProjectCollaboratorListName,
  CustomMessage
}) {
  //  user action for to check do we need to give user input to delete /or any other action
  const [isDisabled, setIsDisabled] = useState(isuserAction);
  return (
    <>
      <Modal
        isOpen={show}
        toggle={() => {
          onCancel();
          if (messageType == "list") {
            setIsDisabled(true);
          }
        }}
        // className={className}
        centered
        size="md"
      >
        <ModalHeader
          toggle={() => {
            onCancel();
            if (messageType == "list") {
              setIsDisabled(true);
            }
          }}
          style={{ paddingLeft: 28, paddingRight: "24px" }}
        >
          <span style={{ fontSize: 16, fontWeight: "600", color: "black" }}>
            <IntlMessages id={"workspace.areyousure"} />
          </span>
        </ModalHeader>
        <ModalBody style={{ padding: "16px 29px" }}>
          {isuserAction ? (
            <div style={{ padding: 12 }}>
              <div
                className="row fs-14"
                style={{
                  background: "#fcf1ef",
                  lineHeight: "1.25rem",
                  padding: "10px 10px 10px 0px",
                  margin: "0px 0px 0px 0px"
                }}
              >
                <div
                  className="col-1"
                  style={{
                    padding: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start"
                  }}
                >
                  {/* <i className="fa fa-info" style={{ color: "red" }}  /> */}
                  <img
                    src={InfoIconsCoustomAlert}
                    height="16"
                    width="21"
                    style={{ margin: "2px 0px 0px 0px " }}
                  />
                </div>

                <div className="col-11" style={{ padding: 0 }}>
                  <span style={{ fontWeight: "bold", padding: 5 }}>
                    <IntlMessages id={"workspace.areyousure"} />
                    <IntlMessages id={"workspace.areyouabout_permanently"} />
                    {action ? (
                      <>
                        {action === "delete" && (
                          <>
                            <IntlMessages id={"workspace.small_delete"} />
                          </>
                        )}
                        {action === "Remove" && (
                          <>
                            <IntlMessages id={"projectdetails.remove"} />
                          </>
                        )}
                        {action === "Leave" && (
                          <>
                            <IntlMessages id={"projectdetails.leave"} />
                          </>
                        )}
                      </>
                    ) : (
                      <IntlMessages id={"workspace.deleted"} />
                    )}
                    {CustomMessage ? (
                      `"${CustomMessage}"`
                    ) : (
                      <IntlMessages id={"workspace.this"} />
                    )}{" "}
                    {messageType ? (
                      messageType
                    ) : (
                      <IntlMessages id={"workspace.records"} />
                    )}
                  </span>
                  <div style={{ padding: 5, lineHeight: "1.36rem" }}>
                    <IntlMessages id={"workspace.Once"} />{" "}
                    {messageType ? (
                      messageType
                    ) : (
                      <IntlMessages id={"workspace.records"} />
                    )}
                    <IntlMessages id={"workspace.permanently_deleted"} />
                    <b>
                      {" "}
                      <IntlMessages id={"workspace.cannotberecovered"} />
                    </b>
                    <IntlMessages id={"workspace.Permanently_deleting"} />{" "}
                    {messageType ? messageType : " records"}
                    <IntlMessages id={"workspace.will"} />{" "}
                    <b>
                      <IntlMessages id={"workspace.immediately_delete"} />
                    </b>{" "}
                    <IntlMessages id={"workspace.its_task"} />
                    <b>
                      <IntlMessages id={"workspace.allrelated_resources"} />
                    </b>
                    <IntlMessages id={"workspace.including_attachments"} />
                  </div>
                </div>
              </div>

              <span
                className="fs-14"
                style={{ padding: " 10px 0px", display: "block" }}
              >
                <IntlMessages id={"workspace.following_confirm"} />
              </span>
              <div style={{ display: "block", paddingBottom: 20 }}>
                <span
                  style={{
                    paddingBottom: 1,
                    backgroundColor: "#ebebeb",
                    borderRadius: 3,
                    padding: 5
                  }}
                >
                  {actionName}
                </span>
                <br />
              </div>
              <input
                className="form-control react-form-input"
                onChange={e =>
                  setIsDisabled(
                    e.target.value.toString() == actionName &&
                      e.target.value?.length > 0
                      ? false
                      : true
                  )
                }
              />
            </div>
          ) : (
            <span className="text-muted">
              <IntlMessages id={"workspace.doyou_really"} />{" "}
              {action ? (
                <>
                  {action === "delete" && (
                    <>
                      <IntlMessages id={"workspace.small_delete"} />
                    </>
                  )}
                  {(action === "Remove" || action === "remove") && (
                    <>
                      <IntlMessages id={"projectdetails.remove"} />
                    </>
                  )}
                  {action === "Leave" && (
                    <>
                      <IntlMessages id={"projectdetails.leave"} />
                    </>
                  )}
                  {action === "discard" && (
                    <>
                      <IntlMessages id={"projectdetails.discard"} />
                    </>
                  )}
                  {action === "archive" && (
                    <>
                      <IntlMessages id={"workspace.archive"} />
                    </>
                  )}
                  {action === "unarchive" && (
                    <>
                      <IntlMessages id={"workspace.unarchive"} />
                    </>
                  )}
                  {action === "archive" && (
                    <>
                      <IntlMessages id={"workspace.archive"} />
                    </>
                  )}
                  {action === "unarchive" && (
                    <>
                      <IntlMessages id={"workspace.unarchive"} />
                    </>
                  )}
                </>
              ) : (
                <IntlMessages id={"workspace.small_delete"} />
              )}
              &nbsp;
              {// projectDetailsName ? (
              //   <b>{`${projectDetailsName}`}</b>
              // ) : workspaceDetailsTitle ? (
              //   <b>{`${workspaceDetailsTitle}`}</b>
              // ) : taskTitleGrid ? (
              //   <b>{`${taskTitleGrid}`}</b>
              // ) : taskTitleList ? (
              //   <b>{`${taskTitleList}`}</b>
              // ) : sectionArrTitle ? (
              //   <b>{`${sectionArrTitle}`}</b>
              // ) : workspaceRemoveCollaborator ? (
              //   <b>{`${workspaceRemoveCollaborator}`}</b>
              // ) :setProjectCollaboratorListName?"":
              //  projectRemoveCollaborator ? (
              //   <b>{`${projectRemoveCollaborator}`}</b>
              // )

              CustomMessage ? (
                <>
                  <b>{`${CustomMessage}`} </b>
                  {action === "archive" ||
                    (action === "unarchive" && (
                      <>
                        <IntlMessages id={"workspace.workspace"} />
                      </>
                    ))}
                </>
              ) : (
                <IntlMessages id={"workspace.this"} />
              )}
              {messageType != "collaborator" &&
              messageType != "Collaborator" ? (
                <>
                  {messageType === "task" && (
                    <>
                      <IntlMessages id={"notification.task"} />
                    </>
                  )}
                  {messageType === "section" && (
                    <>
                      <IntlMessages id={"notification.section"} />
                    </>
                  )}
                  {messageType === "item" && (
                    <>
                      <IntlMessages id={"notification.item"} />
                    </>
                  )}
                  {messageType === "attachment" && (
                    <>
                      <IntlMessages id={"notification.attachment"} />
                    </>
                  )}
                  {messageType === "changes" && (
                    <>
                      <IntlMessages id={"notification.changes"} />
                    </>
                  )}
                </>
              ) : (
                ""
              )}
              ?&nbsp;
              {action == "archive" || action == "unarchive" ? (
                ""
              ) : (
                <IntlMessages id={"workspace.process_cannot_undone"} />
              )}
            </span>
          )}
        </ModalBody>
        <ModalFooter style={{ paddingRight: "23px" }}>
          <Button
            className="button btn"
            onClick={() => {
              onCancel();
              if (messageType == "list") {
                setIsDisabled(true);
              }
            }}
            style={{
              background: "#FFF",
              border: 0,
              color: "gray"
            }}
          >
            <IntlMessages id={"task-details.cancel_button"} />
          </Button>{" "}
          <Button
            className="button btn"
            disabled={isDisabled}
            style={{
              // background: PRIMARY_COLOR ? PRIMARY_COLOR : "rgb(94, 124, 159)",
              cursor: isDisabled && isuserAction ? "not-allowed" : "pointer",
              background: "red",
              color: "#FFF",
              border: "none",
              textTransform: "capitalize"
            }}
            onClick={() => {
              onConfirm();
              if (messageType == "list") {
                setIsDisabled(true);
              }
            }}
          >
            {confirmMessage ? (
              confirmMessage
            ) : (
              <IntlMessages id={"project.delete"} />
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
