import React from "react";
import IconTint from "react-icon-tint";
import { iconTag } from "helper/constant";
export const LableIcon = ({ lableColor }) => {
  return (
    <div
      style={{
        height: "22px",
        width: "22px"
      }}
    >
      {lableColor && lableColor.length > 0 ? (
        <IconTint
          maxHeight="22"
          maxWidth="22"
          src={iconTag}
          style={{
            position: "relative",
            top: "1px",
            height: "18",
            width: "18"
          }}
          color={lableColor}
        />
      ) : (
        ""
      )}
    </div>
  );
};
