import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import { deleteIcon, DownloadAllAttachmentsImage } from "helper/constant";
import IntlMessages from "util/intlMessages";
const TaskCommentPopover = ({
  target,
  data,
  isOpen,
  isTaskCommentsPopover,
  toggle,
  deleteComment,
  isDownloadAttachemnt,
  handleDownload,
  isCurrentUser
}) => {
  React.useEffect(() => {}, [isOpen]);
  return (
    <div>
      {isOpen ? (
        <Popover
          trigger="legacy"
          className="roy-menu"
          innerClassName="roy-inner-content"
          placement="left-start"
          target={target}
          hideArrow={true}
          //   onScrollCapture={() => toggleTaskDetailsMenu()}
          isOpen={isOpen}
          toggle={() => toggle()}
          style={{
            position: "absolute",
            backgroundColor: "white",
            boxShadow: "0px 0px 15px #0000001a",
            left: -200,
            // width: "280px",
            borderRadius: 5
          }}
        >
          <PopoverBody style={{ width: 250, padding: 15 }}>
            {/* <div
              className="roy-menu-list flex-x"
              className=" flex-x"
              style={{
                padding: "10px 5px",
                color: "#000",
                display: "flex",
                alignItems: "center"
              }}
              onClick={e => {
                  e.stopPropagation();
                  editAssign();
                  toggleTaskDetailsMenu();
                  setTaskMenu(false);
                  setCustomAlert(true);
              }}
            >
              <div style={{ width: "18%", display: "flex" }} align="left">
                <img src={editPencilIcon} height="17px" width="18px" />
              </div>
              <span style={{ width: "80%" }}>Edit</span>
            </div> */}

            {isDownloadAttachemnt && (
              <div
                className="roy-menu-list flex-x"
                style={{
                  padding: "10px 5px",
                  color: "#000",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={e => {
                  e.stopPropagation();
                  handleDownload();
                  toggle();
                }}
              >
                <div style={{ width: "14%", display: "flex" }} align="left">
                  <img
                    alt=""
                    src={DownloadAllAttachmentsImage}
                    height="16px"
                    width="18px"
                  />
                </div>
                <span>Download Attachment</span>
              </div>
            )}
            {isCurrentUser && (
              <div
                className="roy-menu-list flex-x"
                style={{
                  padding: "10px 5px",
                  color: "#000",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={e => {
                  e.stopPropagation();
                  deleteComment(data.id);
                  toggle();
                }}
              >
                <div style={{ width: "14%", display: "flex" }} align="left">
                  <img alt="" src={deleteIcon} height="18px" width="18px" />
                </div>
                <span style={{ width: "80%" }}>
                  <IntlMessages id={"task-details.delete_popover memu"} />
                </span>
              </div>
            )}
          </PopoverBody>
        </Popover>
      ) : (
        ""
      )}
    </div>
  );
};

export default TaskCommentPopover;
