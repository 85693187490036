import React, { useEffect, useRef, useState } from "react";
import {
  inviteCollaborators,
  removeCollaborators
} from "helper/services/projectServices";
import { useToasts } from "react-toast-notifications";
import ChipInput from "material-ui-chip-input";
import ToastMessage from "components/notifications/ToastMessage";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import { bin } from "helper/constant";
import { edit } from "helper/constant";
import RoyTooltip from "components/common/RoyTooltip";
import profileModelAction from "redux/profileModel/actions";
import Loader from "components/loader/Loader";
import BusinessWrapper from "views/businessWrapper.style";
import CustomAlert from "util/CustomAlert";
import { inviteuser } from "helper/constant";
import chroma from "chroma-js";
import Select, { components } from "react-select";
import BusinessListShimmer from "components/shimmer/BusinessListShimmer";
import MemberWrapper from "./MemberWrapper.style";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
const { open_profile_modal, close_profile_modal } = profileModelAction;
const MemberTab = props => {
  const { addToast } = useToasts();
  const {
    id,
    collaboratorArr,
    setCollaboratorArr,
    isOwner,
    currentLoggedInUser,
    partcipantsArr,
    projectId,
    setParticipantsArr,
    removeAndRedirect,
    isLoader,
    handleResponse,
    projectName,
    language
  } = props;

  const [isAddParticipant, setIsAddParticipant] = useState(false);
  const [addParticipant, setAddParticipant] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isUserInput, setIsUserInput] = useState(false);
  const [isAddParticipantLoader, setIsAddParticipantLoader] = useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [dropdownInputValue, setDropdownInputValue] = useState("");
  const [inviteParticipants, setInviteParticipants] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [CheckEmailPresent, setCheckEmailPresent] = useState();
  const [isCheckEmailValidation, setIsCheckEmailValidation] = useState(false);
  const [projectRemoveCollaborator, setProjectRemoveCollaborator] = useState(
    ""
  );
  const participantsInputRef = useRef(null);
  const [isEditing, setEditing] = useState(false);

  const inputref = useRef(addParticipant);
  useEffect(() => {
    // inputref.current && inputref.current.focus();
    if (inputref.current) inputref.current = addParticipant;
  }, [inputref]);

  const [isRemoveLoader, setIsRemoveLoader] = useState({
    id: "",
    status: false
  });
  const [isDeleteAlert, setDeleteAlert] = useState({
    id: "",
    status: false
  });

  useEffect(() => {
    if (isEditing) {
      participantsInputRef.current.focus();
    }
  });

  const MultiValue = props => (
    <components.MultiValue {...props}>
      {props.data.chipLabel}
    </components.MultiValue>
  );

  const colourStyles = {
    control: (base, state) => ({
      ...base,
      background: "#ffff",
      border: 0,
      // This line disable the blue border
      boxShadow: 0,
      "&:hover": {
        border: 0
      }
      // match with the menu
      // border: state.isDisabled
      //   ? null
      //   : state.isSelected
      //   ? "#909090"
      //   : state.isFocused
      //   ? "#f6f6f6"
      //   : null
    }),
    // dropdownIndicator: base => ({ ...base, display: 'none' }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#909090"
          : isFocused
          ? "#f6f6f6"
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast("grey", "white") > 2
            ? "white"
            : "black"
          : "#808080",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6")
        }
      };
    },
    singleValue: styles => ({ ...styles, color: "black" })
  };

  const getEmailArray = collab => {
    var emailArray = [];
    collab.map(data => {
      emailArray.push(
        data?.user?.email
          ? data.user.email.toString().trim()
          : data.chipLabel.toString().trim()
      );
    });
    return emailArray;
  };

  const handleAddParticipant = async e => {
    if (inviteParticipants && inviteParticipants.length > 0) {
      setIsAddParticipantLoader(true);
      const response = await inviteCollaborators({
        project_id: projectId,
        recipient_email: getEmailArray(inviteParticipants)
      });
      if (response) {
        if (response.error) {
          handleResponse(response.error.message);
          showNotifications(response.error.message);
          setIsAddParticipantLoader(false);
        }
        if (response.message) {
          var newData = [...collaboratorArr];
          if (response?.data?.length > 0) {
            newData.push(response.data[0]);

            if (newData) {
              setCollaboratorArr([...newData]);
            }
          }
          setInviteParticipants([]);
          removeNewAddedCollabFromDropdownArr(inviteParticipants);
          setAddParticipant([]);
          setIsAddParticipantLoader(false);
          setIsAddParticipant(false);
          showNotifications(response.message, "success");
        }
      }
    }
  };
  const ParticipantType = (status, role) => {
    if (role.toLowerCase() == "owner") {
      return (
        <span
          style={{
            borderRadius: "15",
            color: "white",
            padding: "2px 10px",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: props.themeChanger.topbarTheme.buttonColor
              ? props.themeChanger.topbarTheme.buttonColor
              : "black"
          }}
          className="c-btn-round ma-5 fs-10"
        >
          <IntlMessages id={"projectdetails.owner"} />
        </span>
      );
    }
    if (
      role.toLowerCase() == "collaborator" &&
      status.toLowerCase() == "accepted"
    ) {
      // return (
      //   <span
      //     style={{
      //       borderRadius: "15",
      //       color: "white",
      //       padding: 6,
      // textAlign: "center",
      // justifyContent: "center"
      //       backgroundColor: "rgb(167, 167, 167)",
      //     }}
      //     className="c-btn-round ma-5 fs-10"
      //   >
      //     {role.toLowerCase()}
      //   </span>
      // );
    }
    if (
      role.toLowerCase() == "collaborator" &&
      status.toLowerCase() == "sent"
    ) {
      return (
        <span
          style={{
            borderRadius: "15",
            color: "#313131",
            padding: "2px 10px",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "rgb(222 222 222)"
          }}
          className="c-btn-round ma-5 fs-10"
        >
          <IntlMessages id={"projectdetails.request"} />{" "}
          <IntlMessages id={"projectdetails.sent"} />
        </span>
      );
    }
    if (
      role.toLowerCase() == "collaborator" &&
      status.toLowerCase() == "rejected"
    ) {
      return (
        <span
          style={{
            borderRadius: "15",
            color: "white",
            padding: "2px 10px",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "red"
          }}
          className="c-btn-round ma-5 fs-10"
        >
          <IntlMessages id={"projectdetails.rejected"} />
        </span>
      );
    }
  };

  // check user input value  checkEmailUnique is allready in present  or not
  const checkEmailUnique = emailid => {
    //
    //
    var flag = true;
    collaboratorArr &&
      collaboratorArr.map(data => {
        if (data.user && data.user.email == emailid) {
          flag = false;
        }
      });
    //
    return flag;
  };

  const ErrorEmailValidation = () => {
    return (
      <span
        className={props.class ? props.class : "error-msg"}
        style={{ fontWeight: 300 }}
      >
        <IntlMessages id={"projectdetails.email_validation"} />
      </span>
    );
  };

  const handlePushDataToDropdown = data => {
    var arr = [...partcipantsArr];

    arr.push({
      label: `${data.user.name} (${data.user.email})`,
      chipLabel: `${data.user.email} `,
      value: data.user.email
    });
    setParticipantsArr([...arr]);
  };
  const removeNewAddedCollabFromDropdownArr = inviteParticipants => {
    let participantsDataArr = [...partcipantsArr];
    participantsDataArr &&
      participantsDataArr.map(participant => {
        inviteParticipants &&
          inviteParticipants.map((inviteParticipant, i) => {
            if (inviteParticipant && inviteParticipant?.id == participant?.id) {
              return participantsDataArr.splice(i, 1);
            }
          });

        return;
      });
    setParticipantsArr(participantsDataArr);
  };
  const handleRemoveParticipant = async participantId => {
    if (participantId) {
      try {
        setIsRemoveLoader({
          id: participantId,
          status: true
        });
        const Response = await removeCollaborators({
          collaboration_id: participantId
        });
        if (Response) {
          if (Response.error) {
            handleResponse(Response.error.message);
            showNotifications();
            setIsRemoveLoader({
              id: "",
              status: false
            });
          }
          if (Response.message) {
            setIsRemoveLoader({
              id: "",
              status: false
            });
            showNotifications(Response.message, "success");
            var checkArr = [...collaboratorArr];

            checkArr.map((data, i) => {
              if (data.id == participantId) {
                handlePushDataToDropdown(data);
                // check if its current user logged in than redirect to view workspace

                if (data.user.id === currentLoggedInUser.id) {
                  removeAndRedirect();
                }

                checkArr.splice(i, 1);
              }
            });

            setCollaboratorArr([...checkArr]);
          }
        }
      } catch (e) {
        setIsRemoveLoader({
          id: "",
          status: false
        });
      }
    }
  };
  const handleChangeChip = chip => {
    if (!isEmailAlreadyParticipant(chip) && !isEmailExist(chip)) {
      if (addParticipant) {
        setIsSendButtonDisabled(false);
      } else {
        setIsSendButtonDisabled(true);
      }

      setIsUserInput(true);
      setAddParticipant([...addParticipant, chip]);
    } else if (isEmailAlreadyParticipant(chip)) {
      showNotifications(
        <IntlMessages id={"workspace.email_collaborator"} />,
        "error"
      );
    } else if (isEmailExist(chip)) {
      showNotifications(
        <IntlMessages id={"workspace.email_alreadypresent"} />,
        "error"
      );
    } else {
      showNotifications(
        <IntlMessages id={"workspace.email_collaborator"} />,
        "error"
      );
    }
  };
  const isEmailExist = chip => {
    var status = false;
    addParticipant.map(data => {
      if (data === chip) {
        return (status = true);
      }
    });
    return status;
  };
  const handleChipKeyUp = e => {
    // if (chipCount == 1 && e.key == "Enter" && enterCount == 1) {
    //   handleAddParticipant();
    // }
    // if (e.key == "Enter") {
    //   setEnterCount(enterCount + 1);
    // }
  };
  const isEmailValidate = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const handleEmailValidation = email => {
    setIsUserInput(true);

    let givenEmail = handleCharactersValidation(email);
    if (isEmailValidate(givenEmail)) {
      setIsEmailValid(true);
      handleChangeChip(givenEmail);
    } else {
      setIsEmailValid(false);
    }
  };
  const handleDeleteChip = (email, index) => {
    var emailArr = [...addParticipant];
    emailArr.splice(index, 1);

    setAddParticipant(emailArr);
  };

  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };

  // add new chip in inputvalue then check value is allready in present or not
  const handleAddChipValue = async emailid => {
    if (emailid && checkEmailValidations(emailid)) {
      const newChip = {
        chipLabel: emailid,
        label: emailid,
        value: emailid
      };

      // setInviteParticipants(
      //   inviteParticipants?.length > 0
      //     ? [...inviteParticipants, newChip]
      //     : [newChip]
      // );

      if (inviteParticipants) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
      // handleCharactersValidation(dropdownInputValue);

      let spaceremove = dropdownInputValue.substr(0, 1);
      if (spaceremove == " " || spaceremove == ",") {
        setDropdownInputValue("");
      }
      const start = 0;
      let end = dropdownInputValue.length;
      let isCheckEmailValidation = dropdownInputValue.substr(start, end);

      if (newChip.chipLabel == isCheckEmailValidation) {
        return (
          setDropdownInputValue(""),
          setInviteParticipants(
            inviteParticipants?.length > 0
              ? [...inviteParticipants, newChip]
              : [newChip]
          )
        );
      } else {
        return "no";
      }
    }
  };

  // remove spaces and comma which are entered by user tio generate chips
  // const handleCharactersValidation = email => {
  //   let givenEmail = email;
  //   if (removeComma(givenEmail)) {
  //     givenEmail = removeComma(email);
  //   }
  //   givenEmail = removeSpace(givenEmail);
  //   return givenEmail;
  // };

  // const removeSpace = email => {
  //   return email.replace(/\s/g, "");
  // };

  const handleCharactersValidation = email => {
    let givenEmail = email;
    if (removeComma(givenEmail)) {
      givenEmail = removeComma(email);
    }
    givenEmail = removeSpace(givenEmail);
    return givenEmail;
  };
  const removeSpace = email => {
    return email.replace(/\s/g, "");
  };
  const removeComma = email => {
    if (email.includes(",")) {
      const checkIndex = email.indexOf(",");
      if (checkIndex > -1) {
        let givenEmail = email;
        givenEmail = email.slice(checkIndex + 1, email.length);
        return givenEmail;
      }
      return 0;
    }
  };
  // space and comma key press then add new chip in chip input value

  const handleKeyDown = e => {
    if (isCheckEmailValidation == true) {
      if (e.keyCode == 32) {
        handleAddChipValue(CheckEmailPresent);
        setDropdownInputValue("");
      }
      if (e.keyCode == 188) {
        handleAddChipValue(CheckEmailPresent);

        setDropdownInputValue("");
      }
      if (e.keyCode == 13) {
        handleAddChipValue(CheckEmailPresent);
        setDropdownInputValue("");
      }
      if (e.keyCode == 9) {
        handleAddChipValue(CheckEmailPresent);
        setDropdownInputValue("");
        // setEditing(!Editor);
      }
    }
  };

  // checkEmailValidations
  const checkEmailValidations = email =>
    email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  // const removeComma = email => {
  //   if (email.includes(",")) {
  //     const checkIndex = email.indexOf(",");
  //     if (checkIndex > -1) {
  //       let givenEmail = email;
  //       givenEmail = email.slice(checkIndex + 1, email.length);
  //       return givenEmail;
  //     }
  //     return 0;
  //   }
  // };

  // handleNoOptionsMessage user on change in inputvalue
  const handleNoOptionsMessage = emailid => {
    if (emailid.length > 0) {
      let givenEmail = handleCharactersValidation(emailid).toLowerCase();
      if (checkEmailValidations(givenEmail)) {
        return (
          <>
            <div>
              {!checkEmailUnique(givenEmail)
                ? (setIsCheckEmailValidation(false),
                  (
                    <div className=" ptb-1">
                      <p
                        style={{ color: "red", fontSize: 14, fontWeight: 400 }}
                      >
                        {givenEmail}
                        <IntlMessages
                          id={"workspace.email_alreadycollaborator"}
                        />
                      </p>
                    </div>
                  ))
                : isEmailAlreadyParticipant(givenEmail) ||
                  isEmailAlreadyInviteParticipantst(givenEmail)
                ? (setIsCheckEmailValidation(false),
                  (
                    <div className=" ptb-1">
                      <p
                        style={{ color: "red", fontSize: 14, fontWeight: 400 }}
                      >
                        <IntlMessages id={"workspace.email_alreadypresent"} />
                      </p>
                    </div>
                  ))
                : (setIsCheckEmailValidation(true),
                  setCheckEmailPresent(givenEmail),
                  (
                    <div className=" ptb-1" style={{ fontSize: 14 }}>
                      <p>
                        <IntlMessages id={"workspace.multiple_email"} />
                      </p>
                    </div>
                  ))}
              {/* {!checkEmailUnique(givenEmail)
                ? (setIsCheckEmailValidation(false),
                  (
                    <div className=" ptb-1">
                      <p
                        style={{ color: "red", fontSize: 12, fontWeight: 400 }}
                      >
                        {givenEmail} already a collaborator
                      </p>
                    </div>
                  ))
                : isEmailAlreadyParticipant(givenEmail)
                ? (setIsCheckEmailValidation(false),
                  (
                    <div className=" ptb-1">
                      <p
                        style={{ color: "red", fontSize: 12, fontWeight: 400 }}
                      >
                        {givenEmail} Email is already present
                      </p>
                    </div>
                  ))
                : (setIsCheckEmailValidation(true),
                  setCheckEmailPresent(givenEmail),
                  (
                    <div className=" ptb-1">
                      <p>
                        press "comma" & "space" button below and add some email
                      </p>
                    </div>
                  ))

              <div className=" ptb-10 border-bottom">
                <p>
                  press “send” button below and we'll send email to this {givenEmail}{" "}
                email for an invitation.
              </p>
              </div>
              } */}
              {/* {isInvitationLoader ? (
                <div style={{ marginTop: 10, marginBottom: 10, float: "left" }}>
                  <Loader height="23px" width="20px" />
                </div>
              ) : !checkEmailUnique(emailid) && emailid ? <div style={{ marginTop: 10, marginBottom: 48 }}>
                <button
                  className="btn button"
                  type="button"
                  // onClick={checkEmailUnique(emailid) ? () => handleSendInvite(emailid) : ""}
                  style={{
                    backgroundColor: "grey",
                    color: "#FFF",
                    float: "left",
                    cursor: !checkEmailUnique(emailid) ? "not-allowed" : "",
                  }}
                >
                  Send invitation
                  </button>
              </div> : <div style={{ marginTop: 10, marginBottom: 48 }}>
                <button
                  className="btn button"
                  type="button"
                  // onClick={checkEmailUnique(emailid) ? () => handleSendInvite(emailid) : ""}
                  style={{
                    backgroundColor: PRIMARY_COLOR,
                    color: "#FFF",
                    float: "left",
                    cursor: !checkEmailUnique(emailid) ? "not-allowed" : "",
                  }}
                >
                  Send invitation
                  </button>
              </div>} */}
            </div>
          </>
        );
      } else {
        let spaceremove = dropdownInputValue.substr(0, 1);
        if (spaceremove == " " || spaceremove == ",") {
          setDropdownInputValue("");
        }
        return (
          <>
            <div
              className=" ptb-1"
              style={{ justifyContent: "center", fontSize: 14 }}
            >
              <p>
                <IntlMessages id={"workspace.noperson_collaborator"} />
              </p>
              <p>
                <IntlMessages id={"workspace.invite_email"} />
              </p>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <div className=" ptb-1" style={{ justifyContent: "center" }}>
            <p>
              <IntlMessages id={"projectdetails.nocollaborator"} />
            </p>
          </div>
        </>
      );
    }
  };
  // this function check InviteParticipantst  array to chek alrady in que or not
  const isEmailAlreadyInviteParticipantst = emailid => {
    const participant = inviteParticipants;
    var isExist = false;

    participant &&
      participant.map(data => {
        let str = data.chipLabel;
        str = str.slice(0, -1);

        if (str == emailid) {
          setIsCheckEmailValidation(true);
          return (isExist = true);
        }
      });

    return isExist;
  };
  const isEmailAlreadyParticipant = emailid => {
    const participant = inviteParticipants;
    var isExist = false;

    participant &&
      participant.map(data => {
        if (data.chipLabel && emailid == data.chipLabel) {
          setIsCheckEmailValidation(true);
          return (isExist = true);
        }
      });
    return isExist;
  };
  // const isEmailAlreadyParticipant = email => {
  //   const participant = collaboratorArr;
  //   var isExist = false;
  //   participant.map(data => {
  //     if (data.user && email == data.user.email) isExist = true;
  //     if (!data.user && data.requestedEmail == email) isExist = true;
  //   });

  //   return isExist;
  // };
  return (
    // <BusinessWrapper>
    <div>
      <div className="tab-header">
        <div className="project-header-heading">{/*Members*/}</div>
      </div>
      {isLoader ? (
        <BusinessListShimmer />
      ) : (
        <>
          {""}
          {collaboratorArr &&
            collaboratorArr.map((data, i) => {
              if (data && data?.role?.toLowerCase() == "owner") {
                return (
                  <div
                    className="flex-x ptb-10 border-bottom"
                    key={i}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                  >
                    <div>
                      {data?.user &&
                      data.user?.thumbnail &&
                      data.user?.thumbnail != "" ? (
                        <img
                          className="img-sm"
                          src={data.user?.thumbnail}
                          onClick={() => {
                            props.open_profile_modal({ profile: data.user });
                          }}
                          alt=""
                        />
                      ) : (
                        <div
                          style={profileBackground(
                            data.user
                              ? data.user.name
                              : data.requestedEmail
                              ? data.requestedEmail
                              : ""
                          )}
                          onClick={() => {
                            props.open_profile_modal({ profile: data.user });
                          }}
                        >
                          {data.user
                            ? data.user.name.charAt(0).toUpperCase()
                            : data.requestedEmail.charAt(0).toUpperCase()
                            ? data.requestedEmail.charAt(0).toUpperCase()
                            : ""}
                        </div>
                      )}
                    </div>
                    <div className="mlr-10 flex-1">
                      <p
                        className=" fs-14 demi-bold-text "
                        style={{ marginBottom: "-3px" }}
                      >
                        {data?.user?.name && data?.user?.name}{" "}
                        <small
                          className="text-muted bold-text text-muted"
                          style={{}}
                        >
                          {" "}
                          {/* {`(${data.role.toLowerCase()})`} */}
                          {ParticipantType(data.requestStatus, data.role)}
                        </small>
                      </p>
                      <small className="text-muted fs-12 " style={{}}>
                        {data?.user?.email ? data?.user?.email : " "}
                      </small>
                    </div>
                  </div>
                );
              }
            })}
          {collaboratorArr &&
            collaboratorArr.map((data, i) => {
              if (
                data.role.toLowerCase() !== "owner" &&
                data.requestStatus.toLowerCase() !== "rejected"
              ) {
                if (
                  data &&
                  data.requestStatus == "SENT" &&
                  data.requestedEmail
                ) {
                  return (
                    <div
                      className="flex-x ptb-10 border-bottom mt-9"
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <div
                          style={profileBackground(
                            data.user
                              ? data.user.name
                              : data.requestedEmail
                              ? data.requestedEmail
                              : ""
                          )}
                          onClick={() => {
                            props.open_profile_modal({ profile: data.user });
                          }}
                        >
                          {data.user
                            ? data.user.name.charAt(0).toUpperCase()
                            : data.requestedEmail
                            ? data.requestedEmail.charAt(0).toUpperCase()
                            : ""}{" "}
                        </div>
                      </div>
                      <div className="mlr-10 flex-1">
                        <p
                          className=" fs-14 demi-bold-text "
                          style={{ marginBottom: "-3px" }}
                        >
                          <small className="fs-14 demi-bold-text">
                            {data.requestedEmail
                              ? data.requestedEmail.substr(
                                  0,
                                  data.requestedEmail.indexOf("@")
                                )
                              : ""}{" "}
                            {/* {`(${data.role.toLowerCase()})`} */}
                            {ParticipantType(data.requestStatus, data.role)}
                          </small>
                        </p>
                        <small className="text-muted fs-12">
                          {data.requestedEmail ? data.requestedEmail : ""}
                        </small>
                      </div>
                      {data.requestStatus.toLowerCase() !== "sent" && (
                        <>
                          {isRemoveLoader.participantId == data.id &&
                          isRemoveLoader.status ? (
                            <Loader />
                          ) : (
                            <RoyTooltip id="remove" title="Remove collaborator">
                              <small
                                id="remove"
                                className="  pt-2 plr-5 cursor-pointer hover-underline underlinetextStyle"
                                style={{
                                  color: `${props.themeChanger.topbarTheme.buttonColor}`
                                }}
                                onClick={
                                  () =>
                                    setDeleteAlert({
                                      id: data.id,
                                      status: true
                                    })
                                  // handleRemoveParticipant(data.id)
                                }
                              >
                                <i className="fas fa-trash test-muted" />
                              </small>
                            </RoyTooltip>
                          )}
                        </>
                      )}
                    </div>
                  );
                }

                if (data && data.requestStatus == "SENT" && data.user) {
                  return (
                    <div
                      className="flex-x ptb-10 border-bottom mt-9"
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <div
                          style={profileBackground(
                            data.user
                              ? data.user.name
                              : data.requestedEmail
                              ? data.requestedEmail
                              : ""
                          )}
                          onClick={() => {
                            props.open_profile_modal({ profile: data.user });
                          }}
                        >
                          {data.user
                            ? data.user.name.charAt(0).toUpperCase()
                            : data.requestedEmail
                            ? data.requestedEmail.charAt(0).toUpperCase()
                            : ""}{" "}
                        </div>
                      </div>
                      <div className="mlr-10 flex-1">
                        <p
                          className=" fs-14 demi-bold-text "
                          style={{ marginBottom: "-3px" }}
                        >
                          {data?.user?.name && data?.user?.name}{" "}
                          <small
                            className="text-muted bold-text text-muted"
                            style={{}}
                          >
                            {" "}
                            {/* {`(${data.role.toLowerCase()})`} */}
                            {ParticipantType(data.requestStatus, data.role)}
                          </small>
                        </p>
                        <small className="text-muted fs-12 " style={{}}>
                          {data?.user?.email ? data?.user?.email : " "}
                        </small>
                      </div>
                      {data.requestStatus.toLowerCase() !== "sent" && (
                        <>
                          {isRemoveLoader.participantId == data.id &&
                          isRemoveLoader.status ? (
                            <Loader />
                          ) : (
                            <RoyTooltip id="remove" title="Remove collaborator">
                              <small
                                id="remove"
                                className="  pt-2 plr-5 cursor-pointer hover-underline underlinetextStyle"
                                style={{
                                  color: `${props.themeChanger.topbarTheme.buttonColor}`
                                }}
                                onClick={
                                  () =>
                                    setDeleteAlert({
                                      id: data.id,
                                      status: true
                                    })
                                  // handleRemoveParticipant(data.id)
                                }
                              >
                                <i className="fas fa-trash test-muted" />
                              </small>
                            </RoyTooltip>
                          )}
                        </>
                      )}
                    </div>
                  );
                }
                if (data && data.requestStatus == "ACCEPTED") {
                  return (
                    <div
                      className="flex-x ptb-10 border-bottom mt-9"
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        {data?.user &&
                        data.user?.thumbnail &&
                        data.user?.thumbnail != "" ? (
                          <img
                            className="img-sm"
                            src={data.user?.thumbnail}
                            onClick={() => {
                              props.open_profile_modal({ profile: data.user });
                            }}
                            alt=""
                          />
                        ) : (
                          <>
                            <div
                              style={profileBackground(
                                data.user
                                  ? data.user.name
                                  : data.requestedEmail
                                  ? data.requestedEmail
                                  : ""
                              )}
                              onClick={() => {
                                props.open_profile_modal({
                                  profile: data.user
                                });
                              }}
                            >
                              {data.user
                                ? data.user.name.charAt(0).toUpperCase()
                                : data.requestedEmail
                                ? data.requestedEmail.charAt(0).toUpperCase()
                                : ""}{" "}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="mlr-10 flex-1">
                        <p
                          className=" fs-14 demi-bold-text  "
                          style={{ marginBottom: "-3px" }}
                        >
                          {data?.user?.name && data?.user?.name}{" "}
                          <small
                            className="text-muted bold-text text-muted"
                            style={{}}
                          >
                            {" "}
                            {/* {`(${data.role.toLowerCase()})`} */}
                            {ParticipantType(data.requestStatus, data.role)}
                          </small>
                        </p>
                        <small className="text-muted fs-12 " style={{}}>
                          {data?.user?.email ? data?.user?.email : " "}
                        </small>
                      </div>
                      {data.requestStatus.toLowerCase() !== "sent" && (
                        <>
                          {" "}
                          {isRemoveLoader.participantId == data.id &&
                          isRemoveLoader.status ? (
                            <Loader />
                          ) : (
                            <RoyTooltip
                              id="remove"
                              title={`${
                                isOwner
                                  ? InjectPlaceHolderMessage(
                                      "archived.remove_collaborator",
                                      props?.LanguageSwitcher?.language
                                        ?.languageId
                                    )
                                  : // <IntlMessages id="archived.remove_collaborator" /> + 4444
                                    InjectPlaceHolderMessage(
                                      "project.leave_project",
                                      props?.LanguageSwitcher?.language
                                        ?.languageId
                                    )
                                // <IntlMessages id="project.leave_project" /> + 5555
                              } `}
                            >
                              <small
                                id="remove"
                                className="  pt-2 plr-5 cursor-pointer  member-remove "
                                style={{
                                  color: `${props.themeChanger.topbarTheme.buttonColor}`
                                }}
                                onClick={() => {
                                  setProjectRemoveCollaborator(data.user.name);
                                  // handleRemoveParticipant(data.id)
                                  setDeleteAlert({
                                    id: data.id,
                                    status: true
                                  });
                                }}
                              >
                                {/* <i className={`${isOwner ? 'fas fa-trash':'fas fa-trash'} test-muted fs-12`} /> */}
                                <span className="hover-underline">
                                  {isOwner ? (
                                    <IntlMessages id="projectdetails.remove" />
                                  ) : (
                                    currentLoggedInUser?.id ==
                                      data?.user.id && (
                                      <IntlMessages id="projectdetails.leave" />
                                    )
                                  )}
                                </span>
                              </small>
                            </RoyTooltip>
                          )}{" "}
                        </>
                      )}
                    </div>
                  );
                }
              }
            })}
          {/* {isOwner && ( */}

          {isAddParticipant ? (
            <>
              <h3
                className="mb-0 fs-14 header"
                style={{
                  marginTop: 20,
                  display: "flex",
                  alignItems: "flex-end",
                  marginLeft: 2
                }}
              >
                <IntlMessages id="workspace.addcollaborator(s)_button" />

                <RoyTooltip
                  id={`addParticipantsInfor`}
                  title="Press enter to add multiple email addresses"
                >
                  <i
                    className="fa fa-info-circle ml-2"
                    style={{ color: "grey" }}
                    id={`addParticipantsInfor`}
                  ></i>
                </RoyTooltip>
              </h3>
              <div
                className="pb-0 "
                style={{
                  borderBottom: "1px solid #dee2e6",
                  paddingTop: 10
                }}
              >
                {/* <ChipInput
                  ref={inputref}
                  value={addParticipant}
                  autoFocus
                  // helperText="Press enter to add multiple email addresses"
                  id="participantemail"
                  onKeyUp={handleChipKeyUp}
                  name="participantemail"
                  onBeforeAdd={handleEmailValidation}
                  onChange={chips => {
                    handleChangeChip(chips);
                  }}
                  onUpdateInput={e => {
                    if (e.target.value.length == 0 || e.target.value == " ") {
                      setIsEmailValid(true);
                    }
                  }}
                  clearInputValueOnChange={true}
                  onDelete={(chip, index) => {
                    handleDeleteChip(chip, index);
                  }}
                  // enter,comma,space to add new chips
                  newChipKeyCodes={[13, 32, 188,9]}
                  style={{
                    width: "100% "
                  }}
                  placeholder="example1@email.com, example2@email.com"
                /> */}
                <MemberWrapper>
                  <Select
                    // onBlur={e => {
                    //   participantsInputRef.current.focus();
                    // }}
                    ref={participantsInputRef}
                    className="style changes basic-multi-select"
                    // ref={inputref}
                    isMulti
                    styles={colourStyles}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#989797"
                      }
                    })}
                    closeMenuOnSelect={false}
                    placeholder={InjectPlaceHolderMessage(
                      "project.addcollaborator_placeholder",
                      props?.LanguageSwitcher?.language?.languageId
                    )}
                    id="participantemail"
                    name="participantemail"
                    value={inviteParticipants}
                    options={partcipantsArr}
                    onChange={e => {
                      setInviteParticipants(e);
                      if (e) {
                        setIsDisabled(false);
                      } else {
                        setIsDisabled(true);
                      }
                    }}
                    inputValue={dropdownInputValue}
                    onInputChange={e => setDropdownInputValue(e)}
                    classNamePrefix="select"
                    onKeyDown={handleKeyDown}
                    noOptionsMessage={e => handleNoOptionsMessage(e.inputValue)}
                    components={{ MultiValue }}
                  />
                </MemberWrapper>
                {!isEmailValid && isUserInput ? (
                  <ErrorEmailValidation field="name" />
                ) : (
                  ""
                )}
              </div>
              {isAddParticipantLoader ? (
                <div className="flex-x ptb-15 ">
                  <Loader />
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse"
                    }}
                  >
                    <div
                      className="ptb-5 plr-5 "
                      style={{ alignItems: "flex-end" }}
                    >
                      <button
                        className="btn button plr-5"
                        style={{
                          textTransform: "none ",
                          color: "white",
                          fontSize: "14px"
                        }}
                        disabled={!inviteParticipants?.length}
                        onClick={handleAddParticipant}
                      >
                        <IntlMessages id="workspace.addcollaborator" />
                      </button>
                    </div>
                    <div className="pa-5">
                      <button
                        className="btn btn-secondary plr-5"
                        style={{
                          textTransform: "none ",
                          color: "black",
                          background: "transparent",
                          border: 0,
                          fontSize: "14px"
                        }}
                        onClick={() => setIsAddParticipant(!isAddParticipant)}
                      >
                        <IntlMessages id="task-details.cancel_button" />
                      </button>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="text-center pt-40 pb-15">
              <button
                onClick={() => setIsAddParticipant(!isAddParticipant)}
                className="btn "
                style={{
                  backgroundColor: props.topbarTheme.buttonColor,
                  color: "#FFF",
                  width: 270
                }}
              >
                <img
                  src={inviteuser}
                  height="13px"
                  width="20px"
                  style={{ paddingBottom: 2, height: 13, width: 20 }}
                />{" "}
                <IntlMessages id="workspace.addcollaborator(s)_button" />
              </button>
            </div>
          )}
        </>
      )}
      <CustomAlert
        CustomMessage={isOwner ? projectRemoveCollaborator : projectName}
        action={isOwner ? "Remove" : "Leave"}
        confirmMessage={isOwner ? "Remove" : "Leave"}
        show={isDeleteAlert.status}
        onConfirm={() => {
          if (isDeleteAlert.id) {
            handleRemoveParticipant(isDeleteAlert.id);
            setDeleteAlert({ id: "", status: false });
          }
        }}
        PRIMARY_COLOR={props.topbarTheme.buttonColor}
        onCancel={() => {
          setDeleteAlert({ id: "", status: false });
        }}
        messageType={isOwner ? "Collaborator" : "project"}
      />
    </div>
    //   </BusinessWrapper>
  );
};

export default connect(null, { open_profile_modal })(MemberTab);
