import styled from "styled-components";

const QuickAddWapper = styled.div`
  .label:before {
    content: none !important;
  }
  .Input-Focus:focus {
    outline-color: #0079bf !important;
  }

  .roy-menu-quick-action popover-body,
  .bs-popover-auto {
    border: none !important;
    box-shadow: 0px 0px 15px 1px #0000001a !important;
  }
  roy-menu-quick-action popover-body,
  .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
  .bs-popover-bottom > .arrow::before {
    border: none !important;
  }
  .bs-popover-auto[x-placement^="bottom"],
  .bs-popover-bottom {
    max-width: 400px !important;
    top: 6px !important;
    left: 10px !important;
  }
  .roy-menu-quick-action {
    // width: 303px !important;
    .roy-menu-list {
      width: 310px;
      padding: 12px;
      border-radius: 6px;
      font-family: "muli-semi-bold";
      cursor: pointer;
      transition: all 0.2s;
      font-size: 14px;
      // letter-spacing: 1px;
      &:hover {
        background-color: #f4f5f7;
      }
      .single-list-icon {
        padding-right: 10px;
        color: #6c757d !important;
        font-size: 14px;
      }
      .single-list-title {
        font-size: 14px;
        color: #000000;
        font-weight: 600;
        font-style: normal;
      }
      .single-list-description {
        padding-left: 35px !important;
        color: #a2a2a2 !important;
        font-size: 13px;
        font-weight: 400;
        // word-spacing: 0.345em;
        line-height: 16px;
        margin: 4px 0 0 0;
        text-align: left;
        font: normal normal normal 13px/17px Segoe UI;
        letter-spacing: 0px;
        color: #a2a2a2;
        opacity: 1;
        // padding: 0;
      }
    }
  }
  .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::after {
    top: -16px !important;
    left: -26px !important;
    border-width: 4px 1.5rem 2.5rem 1.5rem !important;
  }
`;

export default QuickAddWapper;
