import React from "react";
import {
  profileBackground,
  profileImage
} from "helper/methods/getUserAvatarColor";
import {
  profilePlus,
  joined,
  overdueTask,
  declained,
  acceptInvitation,
  added,
  mentioned,
  assignNotification,
  reminder
} from "helper/constant";
const PopoverBlock = ({
  people,
  name,
  text,
  TextComponet,
  created,
  notificationType,
  history
}) => {
  const chooseBGColor = notificationType => {
    if (notificationType == "joined") {
      return "rgb(40 167 69 / 64%)";
    } else if (notificationType == "invited") {
      return "#a7b7c8";
    } else if (notificationType == "accepted") {
      return "#ffc107ad";
    } else if (
      notificationType == "rejected" ||
      notificationType == "declined"
    ) {
      return "rgb(220 53 69 / 90%)";
    } else {
      return "#ecba00";
    }
  };
  const getNotificationIcon = type => {
    if (type == "joined") {
      return (
        <>
          <img
            src={joined}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "invited") {
      return (
        <>
          <img
            src={profilePlus}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "accepted") {
      return (
        <img
          src={acceptInvitation}
          style={{
            alignSelf: "center",
            width: "40px"
          }}
        />
      );
    } else if (type == "rejected" || type == "declined") {
      return (
        <>
          <img
            src={declained}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "overdue") {
      return (
        <>
          <img
            src={overdueTask}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    }
    // else if (type == "mention") {
    //   return (
    //     <>
    //       <img
    //         src={overdueTask}
    //         style={{
    //           alignSelf: "center"
    //         }}
    //       />
    //     </>
    //   );
    // }
    else if (type == "added") {
      return (
        <>
          <img
            src={added}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "mention") {
      return (
        <>
          <img
            src={mentioned}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "reminder") {
      return (
        <>
          <img
            src={reminder}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    } else if (type == "assign") {
      return (
        <>
          <img
            src={assignNotification}
            style={{
              alignSelf: "center"
            }}
          />
        </>
      );
    }
  };
  return (
    <div
      className="mail-popover-block cursor-pointer"
      onClick={history}
      style={{ marginTop: "10px", paddingBottom: "5px" }}
    >
      <div className="flex-x">
        <div className="">
          {/* <img src={people} alt="people"  />
           */}
          <div
            style={{
              borderRadius: "25%",
              border: "1 solid",
              width: 41,
              // background: "#dfe1e6",
              height: 41,
              objectFit: "fill",
              textAlign: "center",
              color: "#6f6f6f",
              fontSize: 18,
              display: "flow-root"
              // paddingTop: "9px"
            }}
          >
            {" "}
            {getNotificationIcon(notificationType)}
          </div>
        </div>
        <div className="flex-1 " style={{ paddingLeft: "8px" }}>
          {/* change class name up className="flex-1 pl-2" */}
          {/* <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between"
            }}
          >
            <div
              className="fs-13 demi-bold-text"
              style={{ fontSize: 13, color: "#808080" }}
            >
              {name}
            </div>

          </div> */}
          <div
            className="fs-14 medium-text"
            style={{
              fontSize: 14,
              color: "#000000",
              fontWeight: 500,
              wordBreak: "break-word",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              lineClamp: 2,
              textOverflow: "ellipsis",
              overflow: "hidden",
              WebkitLineClamp: 2,
              WebkitFontSmoothing: "antialiased"
            }}
          >
            <b>{name}</b> {TextComponet} {text}
          </div>
          <div
            className="fs-10 medium-text"
            style={{ fontSize: 9, color: "#808080" }}
          >
            {created}
          </div>

          {/* <div className="fs-11 medium-text">
                        <button
                        style={{ 
                            lineHeight:0.5
                        }}
                        className="c-outline-success btn">Accept</button>{" "}
                        <button
                        style={{ 
                            lineHeight:0.5
                        }}
                        className="c-outline-danger btn"
                          >
                              Decline</button>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default PopoverBlock;
