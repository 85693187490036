export const generateLocalNotifications = (title, body) => {
  var n = new Notification(title, { body });
  var notificationURL = "https://app.workplate.nl";
  // Add an onclick event handler
  n.onclick = function() {
    // This code will run when the notification is clicked

    window.open(notificationURL, "_blank");
    // You can add your custom logic here
  };

  document.addEventListener("visibilitychange", function() {
    if (document.visibilityState === "visible") {
      // The tab has become visible so clear the now-stale Notification.
      n.close();
    }
  });
};
