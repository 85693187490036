import styled from "styled-components";

const ProjectCardWrapper = styled.div`
  .project-card-container {
    min-height: 124px;
    background: white;
    padding: 15px 15px 10px 15px;
    border-radius: 6px;
    box-shadow: rgb(90 113 208 / 11%) 0px 0px 0px 0px,
      rgb(167 175 183 / 25%) 0px 4px 16px 0px;
    // border: 1px solid rgb(221, 228, 235);
  }
  .project-icon {
    padding-top: 5px;
  }
  .text-overdue {
    display: flex;
    color: red;
  }
  .text-remaining {
    display: flex;
    color: green;
  }
  .text-due-today {
    display: flex;
    color: orange;
  }
  .project-title {
    display: inline-block;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 91%;
    font-weight: 600;
    font-size: 14px;
    color: #000;
    padding-left: 0px;
  }

  .project-description {
    padding-top: 5px;
    font-weight: 400;
    font-size: 14px;
    color: gray;
    padding-left: 0px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
`;

export default ProjectCardWrapper;
