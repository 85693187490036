import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import "./alertstyle.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
export default function CustomAlert({
  show,
  onConfirm,
  onCancel,
  action,
  PRIMARY_COLOR,
  messageType,
  confirmMessage
}) {
  return (
    <>
      <Modal
        isOpen={show}
        toggle={onCancel}
        // className={className}
        centered
        size="md"
      >
        {/* <ModalHeader toggle={onCancel}>Are you sure?</ModalHeader> */}
        <ModalBody>
          <span className="text-muted">
            You are no longer part of this{" "}
            {messageType ? messageType : "workspace"}
          </span>
        </ModalBody>
        <ModalFooter>
          <Button
            className="button btn"
            style={{
              background: PRIMARY_COLOR ? PRIMARY_COLOR : "#0073E6",
              color: "#FFF",
              border: "none"
            }}
            onClick={onConfirm}
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
