import React, { useState, useEffect } from "react";
import TodolistWrapper from "./todolistWrapper.style";
import TaskDialog from "components/scrumboard/TaskDialog";
import TaskMenu from "components/projects/Menus/TaskMenu";
import CustomAlert from "util/CustomAlert";
import PriorityPopover from "components/priority/priorityPopover";
import SingleTaskView from "./SingleTaskView";
import { data_not_found } from "helper/constant";
import NewTask from "components/newTask/NewTask";
import { doGroupByproject } from "helper/methods/utilFunctions";
import { getColorCode } from "util/data/ProjectColors";
import IntlMessages from "util/intlMessages";
const LabelTodoListView = props => {
  const {
    doCloseQuickAction,
    doUpdateTaskPriority,
    doUpdateTaskScheduleDate,
    doUpdateTaskAssign,
    isQuickActionTask,
    setIsQuickActionTask
  } = props;

  const [todo, SetTodo] = useState(null);
  const [taskModel, setTaskModel] = useState(false);
  const [activeTaskDetail, setActiveTaskDetail] = useState(null);
  const [taskMenu, setTaskMenu] = useState(false);
  const [activeTaskMenu, setActiveTaskMenu] = useState(null);
  const [isCustomAlert, setCustomAlert] = useState(false);
  const sectionalertMessage = "Task will be deleted!";

  const [taskProject, setTaskProject] = useState("");
  const { isEditTask, setIsEditTask, updateTaskHandler } = props;
  useEffect(() => {
    if (props.todoList && props.todoList?.length) {
      const getRegularTasks =
        props.todoList &&
        props.todoList.filter(todo => !todo.isDeleted && !todo.isArchived);
      let formattedData = doGroupByproject(getRegularTasks);
      SetTodo(formattedData);
    }
  }, [props.todoList]);

  const updateisCompleted = async (projectId, todoId, taskStatus, payload) => {
    props.updateIsCompleted(projectId, todoId, taskStatus, payload);
  };
  const deleteTask = async (id, projectId) => {
    updateTaskHandler(
      {
        task_id: id.toString(),
        project_id: projectId?.toString(),
        isDeleted: true
      },
      false
    );
  };
  const doArchiveTask = async (id, projectId) => {
    updateTaskHandler(
      {
        task_id: id.toString(),
        project_id: projectId?.toString(),
        isArchived: true
      },
      false
    );
  };

  const doOpenTaskDetail = data => {
    props.history.push(`/task-details/${data.id}`);
  };
  const toggleTaskMenu = (target, taskData) => {
    if (taskMenu) {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(null);
      setActiveTaskDetail(null);
    } else {
      setTaskMenu(!taskMenu);
      setActiveTaskMenu(target);
      setActiveTaskDetail(taskData);
    }
  };
  return (
    <div>
      <TodolistWrapper>
        <div className="" style={{ marginBottom: 50 }}>
          {todo && Object.keys(todo).length > 0 && props.todoList.length > 0 ? (
            <div>
              {Object.keys(todo).length &&
                Object.keys(todo).length &&
                Object.keys(todo).map((projectKey, i) => {
                  return (
                    <>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          marginBottom: 5,
                          marginTop: 20
                        }}
                      >
                        <div
                          className="pa-5 flex-x align-center"
                          id={`section-edit-${i}`}
                        >
                          <div className="flex-1">
                            <div
                              className=" demi-bold-text text-center"
                              style={{ alignItems: "center" }}
                            >
                              <i
                                className="fa fa-circle fs-10 pr-10 "
                                style={{
                                  color: getColorCode(
                                    todo[projectKey].project.color
                                  )
                                }}
                              />
                              <span
                                style={{
                                  fontSize: 14,
                                  color: "black",
                                  fontWeight: 600
                                }}
                              >
                                {" "}
                                {todo[projectKey].project.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div
                          className="todo-container flex-y"
                          style={{ backgroundColor: "white", borderRadius: 10 }}
                        >
                          {todo[projectKey].task &&
                            todo[projectKey].task.map((data, i) => {
                              if (
                                data.project.name ==
                                todo[projectKey].project.name
                              ) {
                                if (!data.isDeleted && !data.isArchived) {
                                  return (
                                    <>
                                      {isEditTask?.status &&
                                      isEditTask?.data?.id === data.id ? (
                                        <div
                                          style={{
                                            paddingTop: 5,
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            border: "1px solid #c6c2c2",
                                            position: "relative"
                                          }}
                                        >
                                          <NewTask
                                            updateTask
                                            action={isEditTask?.action || ""}
                                            toggleAction={() =>
                                              setIsEditTask({
                                                ...isEditTask,
                                                action: ""
                                              })
                                            }
                                            collaboratorList={
                                              props.collaboratorList
                                            }
                                            toggle={() => {
                                              setIsEditTask({
                                                status: false,
                                                data: ""
                                              });
                                            }}
                                            taskData={isEditTask.data}
                                            // onKeyDown={handleKeyDown}
                                            addTaskHandler={updateTaskHandler}
                                            taskProject={taskProject}
                                            setTaskProject={data =>
                                              setTaskProject(data)
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          <SingleTaskView
                                            auth={props.auth}
                                            index={i}
                                            data={data}
                                            todo={todo[projectKey].task}
                                            updateisCompleted={
                                              updateisCompleted
                                            }
                                            doOpenTaskDetail={doOpenTaskDetail}
                                            doCloseQuickAction={
                                              doCloseQuickAction
                                            }
                                            isQuickActionTask={
                                              isQuickActionTask
                                            }
                                            doUpdateTaskScheduleDate={
                                              doUpdateTaskScheduleDate
                                            }
                                            doUpdateTaskAssign={
                                              doUpdateTaskAssign
                                            }
                                            todoLength={
                                              todo[projectKey].task.length || 0
                                            }
                                            toggleTaskMenu={toggleTaskMenu}
                                          />
                                          {/* <div
                                        key={i}
                                        className="flex-x my-todo align-center plr-30   roe-card-style  todo-board "
                                        style={getTodoStyle(data.id, i)}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flex: 1,
                                            cursor: "pointer",
                                            paddingBottom:
                                              todo.length - 1 == i
                                                ? "0px"
                                                : "10px",
                                            paddingTop: i == 0 ? "0px" : "10px",
                                            borderBottom:
                                              todo.length - 1 == i
                                                ? ""
                                                : "1px solid #ebebeb"
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex"
                                            }}
                                          >
                                            <div className="  pretty p-icon p-round p-smooth"> 
                                              {!data.isCompleted ? (
                                                <img

                                                  src={ListGridCircleImage}
                                                  style={{
                                                    height: 18,
                                                    width: 18
                                                  }}
                                                ></img>
                                              ) : (
                                                <img
                                                  src={ListGridCheckImage}
                                                  style={{
                                                    height: 18,
                                                    width: 18
                                                  }}
                                                ></img>
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            className="flex-1"
                                            onClick={() => {
                                              doOpenTaskDetail(data); 
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                           
                                            <div className="flex-x flex-wrap align-center fs-14">
                                              <span
                                                className="All_Task_Title fs-14"
                                                style={{
                                                  maxWidth: "50%",
                                                  color: "black",
                                                  fontSize: "14px",
                                                  paddingRight: "25px",
                                                  wordBreak: "break-word",
                                                  display: "-webkit-box",
                                                  WebkitBoxOrient: "vertical",
                                                  lineClamp: 1,
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  WebkitLineClamp: 1,
                                                  WebkitFontSmoothing:
                                                    "antialiased"
                                                }}
                                              >
                                                {data.title}
                                              </span>
                                              {data &&
                                                data.priority &&
                                                data.priority !=
                                                  "no_priority" && (
                                                  <>
                                                    {allPriority.map(item => {
                                                      if (
                                                        item.name ==
                                                        data.priority
                                                      ) {
                                                        return (
                                                          <>
                                                            <div
                                                              style={{
                                                                marginRight: 25
                                                              }}
                                                            >
                                                           
                                                              <img
                                                                src={getPriorityPath(
                                                                  data.priority
                                                                )}
                                                                height="17"
                                                                width="18"
                                                                className=""
                                                                alt="Priority"
                                                                style={{
                                                                  position:
                                                                    "relative" 
                                                                }}
                                                              />
                                                              <span
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                  padding:
                                                                    "3px 6px",
                                                                  color: "gray"
                                                                }}
                                                              >
                                                                {item.value}
                                                              </span>
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    })}
                                                  </>
                                                )}

                                              {data.labels &&
                                                data.labels.map(
                                                  (item, index) => {
                                                    return (
                                                      <>
                                                        <div
                                                          style={{
                                                            marginRight: 25
                                                          }}
                                                        >
                                                          <div className="my-day-label flex-x mr-6 fs-14 nevy--text align-center overflow-hidden">
                                                            <IconTint
                                                              src={iconTag}
                                                              style={{
                                                                position:
                                                                  "relative",
                                                                top: "1px",
                                                                height: "18",
                                                                width: "18"
                                                              }}
                                                              color={getColorCode(
                                                                item.color
                                                              )}
                                                            />
                                                           
                                                            <span
                                                              style={{ 
                                                                color: "black",
                                                                paddingLeft: 5,
                                                                maxWidth:
                                                                  "300px",
                                                                whiteSpace:
                                                                  "nowrap",
                                                                textOverflow:
                                                                  " ellipsis",
                                                                overflow:
                                                                  "hidden", 
                                                              }}
                                                            >
                                                              {item.name}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                )}

                                              <div
                                                className={`my-day-label flex-x mr-0 fs-14 nevy--text align-center overflow-hidden ${
                                                  data.labels
                                                    ? data.labels.length > 0
                                                      ? "label"
                                                      : ""
                                                    : ""
                                                }`}
                                              >
                                             
                                                <div
                                                  style={{
                                                    marginRight: 25
                                                  }}
                                                >
                                                  <img
                                                    src={commentnew}
                                                    style={{
                                                      height: "18px",
                                                      width: "15px",
                                                      marginRight: "0px",
                                                      marginTop: "0px",
                                                      textAlign: "center"
                                                    }}
                                                  ></img>
                                                  <span
                                                    style={{
                                                      color: "grey",
                                                      paddingLeft: 5
                                                    }}
                                                  >
                                                    {data.commentCount}
                                                  </span>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center"
                                                }}
                                              >
                                                {data.scheduledAt && (
                                                  <img
                                                    alt=""
                                                    src={datesnewmodal}
                                                    height="15px"
                                                    width="15px"
                                                    style={{ marginRight: 5 }}
                                                  />
                                                )}
                                                {data.scheduledAt &&
                                                  data.repeatType !==
                                                    repeatTypeOptions[0]
                                                      .value && (
                                                    <RepeatSvgIcon
                                                      style={{
                                                        fill: "green",
                                                        height: "16px",
                                                        width: "15px"
                                                      }}
                                                    />
                                                  )}
                                                {data.scheduledAt && (
                                                  <div
                                                    className="due-date  label"
                                                    style={{
                                                      marginLeft: 5,
                                                      marginRight: 25
                                                    }}
                                                  >
                                                    Due on{" "}
                                                    {moment(
                                                      new Date(data.scheduledAt)
                                                    ).format("DD MMM YYYY")}
                                                  </div>
                                                )}
                                              </div>
                                              <div
                                                style={{
                                                  display: "contents",
                                                  alignItems: "center"
                                                }}
                                              >
                                               
                                                {data.taskAssign &&
                                                  data.taskAssign.map(item => (
                                                    <TaskAssignee
                                                      taskAssign={item}
                                                    />
                                                  ))}
                                              </div>
                                            </div>{" "}
                                          </div>
                                          <div
                                            style={{
                                              marginRight: 10
                                            }}
                                          ></div>
                                          <div
                                            style={{
                                              display: "flex",
                                              paddingTop: 7
                                            }}
                                            id={`taskmenu-${data.id}`}
                                            onClick={() => {
                                              setLabelsTodoListViewName(
                                                data.title
                                              );
                                              toggleTaskMenu(
                                                `taskmenu-${data.id}`,
                                                data
                                              );
                                            }}
                                            className="cursor-pointer"
                                          >
                                            <img
                                              src={threeDot_Icon}
                                              style={{
                                                height: 6,
                                                width: 22
                                              }}
                                            ></img>
                                         
                                          </div>
                                          <div>
                                           
                                            <div
                                              id={`taskmenu-labels-${data.id}`}
                                            />
                                            <div
                                              id={`taskmenu-priority-${data.id}`}
                                            />

                                            {isQuickActionTask &&
                                              isQuickActionTask.status &&
                                              isQuickActionTask.action ==
                                                "assign" &&
                                              isQuickActionTask.target ==
                                                `taskmenu-assign-${data.id}` && (
                                                <>
                                                  <AssigneeDropdown
                                                    isDisableDropDownIcon
                                                    TargetId="assing-"
                                                    setDropdownOpen={() => {
                                                      doCloseQuickAction();
                                                    }}
                                                    dropdownOpen={
                                                      isQuickActionTask &&
                                                      isQuickActionTask.status
                                                    }
                                                    businessId={
                                                      props.auth.user.businessId
                                                    }
                                                    projectId={
                                                      (isQuickActionTask.status &&
                                                        isQuickActionTask.data
                                                          .project?.id) ||
                                                      ""
                                                    }
                                                    currentLoggedIn={
                                                      props.auth?.user?.id
                                                    }
                                                    projectCollaborators={
                                                      props.collaboratorList
                                                    }
                                                    userAssigned={
                                                      isQuickActionTask.selectedData
                                                        ? isQuickActionTask.selectedData
                                                        : ""
                                                    }
                                                    setUserAssigned={data => {
                                                      onChangeTaskAssign(
                                                        data,
                                                        doUpdateTaskAssign
                                                      ); 
                                                    }}
                                                  />
                                                </>
                                              )}
                                            {isQuickActionTask &&
                                              isQuickActionTask.status &&
                                              isQuickActionTask.action ==
                                                "schedule" &&
                                              isQuickActionTask.target ==
                                                `taskmenu-schedule-${data.id}` && (
                                                <div
                                                  style={{
                                                    position: "relative"
                                                  }}
                                                >
                                                  <DatePickerNoInput
                                                    // AllowAddPreviousDateTask={AllowAddPreviousDateTask}
                                                    dueDate={
                                                      isQuickActionTask.selectedData
                                                        ? isQuickActionTask.selectedData
                                                        : ""
                                                    }
                                                    onUpdateDate={
                                                      doUpdateTaskScheduleDate
                                                    }
                                                    toggle={doCloseQuickAction}
                                                    isOpen={
                                                      isQuickActionTask.status
                                                    }
                                                    PRIMARY_COLOR={
                                                      props.PRIMARY_COLOR
                                                    }
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div> */}
                                        </>
                                      )}
                                    </>
                                  );
                                }
                              }
                            })}
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          ) : (
            <div
              style={{
                // height: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "60px auto"
              }}
            >
              <div>
                <div
                  className="text-muted"
                  style={{
                    fontSize: 15,
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <img src={data_not_found} height="250" width="250" />
                  <br />
                </div>
                <div
                  className="text-muted"
                  style={{
                    fontSize: 14,
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      color: "black",
                      paddingTop: 20
                    }}
                  >
                    <IntlMessages id={"labels.no_tasks_filter"} />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </TodolistWrapper>

      {activeTaskDetail && (
        <TaskDialog
          listType="project"
          taskModel={taskModel}
          dummyData={props.dummyData || false}
          setTaskModel={() => {
            setTaskModel(!taskModel);
            setActiveTaskDetail(null);
          }}
          taskType="label"
          labelId={props.labelId}
          taskDetail={activeTaskDetail}
          projectName={activeTaskDetail.project.name}
          isLabelComponent={true}
          popTask={id => props.removeTaskFromList(id)}
          pushTask={data => props.pushTask(data)}
          currentLabelId={props.currentLabelId}
          sectionId={activeTaskDetail && activeTaskDetail.section?.id}
          sectionTitle={activeTaskDetail && activeTaskDetail.section?.title}
          projectId={activeTaskDetail.project.id}
          todoId={activeTaskDetail.id}
          updateTodoList={newTodo => props.refreshTodoList(newTodo)}
          collaboratorList={props.collaboratorList}
          showNotifications={props.showNotifications}
        />
      )}
      <TaskMenu
        projectTask
        isMenu={taskMenu}
        toggleMenu={() => toggleTaskMenu()}
        viewTaskDetails={() => {
          doOpenTaskDetail(activeTaskDetail);
          // setActiveTaskDetail({ ...activeTaskDetail, action: "taskactivity" });
          // setTaskModel(true);
          setTaskMenu(false);
          setActiveTaskMenu("");
        }}
        doArchiveTask={() =>
          doArchiveTask(activeTaskDetail?.id, activeTaskDetail.project.id)
        }
        target={activeTaskMenu}
        editLabels={() => {
          setIsEditTask({
            status: true,
            data: activeTaskDetail,
            action: "label"
          });
        }}
        editAssign={() => {
          if (activeTaskDetail && activeTaskDetail.id) {
            setIsQuickActionTask({
              status: true,
              action: "assign",
              taskId: activeTaskDetail.id,
              target: `taskmenu-assign-${activeTaskDetail.id}`,
              selectedData:
                activeTaskDetail && activeTaskDetail.taskAssign
                  ? { user: activeTaskDetail?.taskAssign }
                  : "",
              data: activeTaskDetail
            });
          }
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "taskassign"
          // });
        }}
        editPriority={() => {
          if (activeTaskDetail && activeTaskDetail.id) {
            setIsQuickActionTask({
              status: true,
              action: "priority",
              taskId: activeTaskDetail.id,
              target: `taskmenu-priority-${activeTaskDetail.id}`,
              selectedData: activeTaskDetail && activeTaskDetail.priority,
              data: activeTaskDetail
            });
          }
          // setIsEditTask({
          //   status: true,
          //   data: activeTaskDetail,
          //   action: "priority"
          // });
        }}
        editDate={() => {
          setIsQuickActionTask({
            status: true,
            action: "schedule",
            taskId: activeTaskDetail.id,
            target: `taskmenu-schedule-${activeTaskDetail.id}`,
            selectedData: activeTaskDetail && activeTaskDetail.scheduledAt,
            data: activeTaskDetail
          });
        }}
        editTask={() => {
          if (taskMenu) {
            setIsEditTask({ status: true, data: activeTaskDetail });
          } else {
            setIsEditTask({ status: false, data: "" });
          }
          setTaskMenu(!taskMenu);
        }}
        deleteTask={id => {
          setTaskMenu(!taskMenu);
          setCustomAlert(true);
        }}
      />
      <CustomAlert
        action="delete"
        messageType="task"
        PRIMARY_COLOR={props.PRIMARY_COLOR}
        show={isCustomAlert}
        onConfirm={() => {
          deleteTask(activeTaskDetail.id, activeTaskDetail.project.id);
          setCustomAlert(false);
        }}
        onCancel={() => setCustomAlert(false)}
        alertMessage={sectionalertMessage}
      />
      {isQuickActionTask &&
        isQuickActionTask.status &&
        isQuickActionTask.action == "priority" && (
          <PriorityPopover
            isOpen={isQuickActionTask.status}
            toggle={doCloseQuickAction}
            target={
              isQuickActionTask.status && isQuickActionTask.target
                ? isQuickActionTask.target
                : "priority-task-details"
            }
            selectedPriority={
              isQuickActionTask?.status && isQuickActionTask.selectedData
                ? isQuickActionTask.selectedData
                : "no_priority"
            }
            updatePriority={doUpdateTaskPriority}
            PRIMARY_COLOR={props.PRIMARY_COLOR}
          />
        )}
    </div>
  );
};

export default LabelTodoListView;
