import projectModalAction from "./actions";

const initState = {
  projectModal: localStorage.getItem("projectModal")
    ? localStorage.getItem("projectModal")
    : {
        isOpen: false,
        project: ""
      }
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case projectModalAction.OPEN_PROJECT_MODAL:
      return {
        isOpen: true,
        project: action.project
      };

    case projectModalAction.CLOSE_PROJECT_MODAL:
      return {
        isOpen: false,
        project: ""
      };

    default:
      return state;
  }
}
