import React, { useState, useRef } from "react";
import RoyTooltip from "components/common/RoyTooltip";
import TaskComment from "components/scrumboard/TaskComment";
import { commentObject } from "components/scrumboard/boardHelper";
import { randomUUID } from "helper/methods";
import CustomAlert from "util/CustomAlert";
import "./style.css";
import {
  profileBackground,
  profileImage
} from "helper/methods/getUserAvatarColor";
import DatepickerWrapper from "components/forms/alldatepickers/datepicker.style";
import DatePicker from "react-datepicker";
import { useradd, TaskModalEditIcon } from "helper/constant";
import { more } from "helper/constant";
import { trash } from "helper/constant";
import SubtaskDueDate from "./SubtaskDueDate";
import moment from "moment";
import IntlMessages from "util/intlMessages";
const Subtask = ({
  subtask,
  deleteSubtaskHandler,
  changeStatusOfSubtaskHandler,
  addCommentHandler,
  assignSubTask,
  openAssignSubTaskModal,
  subTaskDetails,
  updateSubtask,
  projectTask,
  collaboratorList,
  PRIMARY_COLOR,
  updateDueDateSubtask
}) => {
  const [addCommentFlag, setAddCommentFlag] = useState(false);
  const [commentInput, setCommentInput] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [subtasktitle, setSubTaskTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState(
    "This action can not be undone!"
  );
  const [iseditTitle, setIsEditTitle] = useState(false);
  const [subTaskCustomMessage, setSubTaskCustomMessage] = useState("");
  const textArea = useRef(null);

  const [style, setStyle] = useState({ display: "none" });

  const closeAddingComment = () => {
    if (commentInput === "") {
      setAddCommentFlag(!addCommentFlag);
    }
  };
  const changeDueDate = (id, date) => {
    updateDueDateSubtask(id, date);
  };

  const addComment = () => {
    if (commentInput !== "") {
      commentObject["comment"] = commentInput;
      commentObject["id"] = randomUUID();
      commentObject["created"] = new Date();
      addCommentHandler({ ...commentObject }, subtask.id);
      setCommentInput("");
      textArea.current.focus();
    }
  };
  const subprofileBackground = {
    borderRadius: "50%",
    border: "1 solid",
    width: 18,
    background: "rgb(223, 225, 230)",
    height: 18,
    objectFit: "fill",
    textAlign: "center",
    lineHeight: 1,
    color: "rgb(23, 43, 77)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };
  const subInCap = {
    fontSize: 10,
    color: "white",
    textAlign: "center"
  };
  return (
    <>
      <div
        className="subtask-detail subtaskHover"
        onMouseOver={e => {
          setStyle({ display: "block" });
        }}
        onMouseOut={e => {
          setStyle({ display: "none" });
        }}
        style={{
          // margin: "5px 0px",
          padding: "10px 0px 10px 0px",
          borderRadius: 5
          // minHeight: 67
        }}
      >
        <div style={{ display: "flex", height: "26px" }}>
          <div className={"container"}>
            <div className={"row"}>
              <div
                className={"col-lg-9 col-md-8 col-sm-8 col-xs-9"}
                // chang classname up  className={"col-lg-10 col-md-9 col-ms-9 col-xs-12"}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <i
                      style={{
                        marginLeft: 0,
                        marginRight: 10,
                        backgroundColor: subtask.isCompleted
                          ? "white"
                          : "#D9D9D9",
                        color: subtask.isCompleted ? "#00D67F" : "#D9D9D9",
                        cursor: "pointer",
                        fontSize: 19,
                        borderRadius: "50%"
                      }}
                      id="subtaskstatus"
                      className=" fas fa-check-circle"
                      onClick={() =>
                        changeStatusOfSubtaskHandler(
                          subtask.id,
                          !subtask.isCompleted
                        )
                      }
                    ></i>
                  </div>
                  {iseditTitle ? (
                    <>
                      <div>
                        <input
                          type="text"
                          id="subtasktitle"
                          defaultValue={subtask.title}
                          className="form-control react-form-input pr-2 add-task-input"
                          aria-describedby="subtask"
                          placeholder="Title"
                          // ref={titleRef}
                          autoFocus
                          name="taskTitle"
                          style={{
                            border: "1px solid #e2e2e2",
                            ":focus": {
                              outline: "none",
                              paddingRight: 5
                            }
                          }}
                          onKeyDown={e => {
                            if (e.keyCode === 13) {
                              if (
                                subtask.id == subtasktitle.id &&
                                subtasktitle.title.match(/^\S+\w{0,50}\S{0,}/g)
                              ) {
                                updateSubtask(
                                  subtasktitle.title,
                                  subtasktitle.id
                                );
                                setIsEditTitle(false);
                              }
                            }
                          }}
                          onChange={e =>
                            setSubTaskTitle({
                              id: subtask.id,
                              title: e.target.value
                            })
                          }
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: 10,
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <button
                          className="c-btn mr-10"
                          style={{
                            backgroundColor:
                              subtasktitle.title.match(/^\S+\w{0,50}\S{0,}/g) &&
                              subtasktitle.title.length > 0
                                ? PRIMARY_COLOR
                                : "grey",
                            color: "#fff",
                            boxShadow: "none"
                          }}
                          disabled={
                            !subtasktitle.title.match(/^\S+\w{0,50}\S{0,}/g) &&
                            subtasktitle.title.length == 0
                              ? true
                              : false
                          }
                          onClick={e => {
                            if (subtask.id == subtasktitle.id) {
                              updateSubtask(
                                subtasktitle.title,
                                subtasktitle.id
                              );
                            }

                            setIsEditTitle(false);
                          }}
                        >
                          <IntlMessages id={"profile.savebutton"} />
                        </button>
                        <button
                          className="c-btn c-default"
                          style={{
                            boxShadow: "none"
                          }}
                          onClick={() => {
                            setIsEditTitle(false);
                            // setTitle(task.title);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  ) : (
                    <div
                      style={
                        subtask.isCompleted
                          ? {
                              textDecoration: "line-through",
                              fontStyle: "italic",
                              cursor: "pointer",
                              fontSize: 15,
                              color: "black",
                              display: "flex"
                            }
                          : {
                              cursor: "pointer",
                              fontSize: 15,
                              color: "black",
                              display: "flex"
                            }
                      }
                      onClick={() => {
                        setSubTaskTitle({
                          id: subtask.id,
                          title: subtask.title
                        });
                        setIsEditTitle(true);
                      }}
                    >
                      {subtask.title}
                      <div style={style}>
                        <img
                          src={TaskModalEditIcon}
                          height="12px"
                          width="13px"
                          style={{
                            color: "#ababab",
                            fontSize: 12,
                            paddingLeft: "5px !important",
                            marginLeft: 8
                          }}
                        />
                      </div>
                      {/* <i
                        className="plr-2 fa fa-edit editIcon"
                        style={{
                          color: "#ababab",
                          fontSize: 12,
                          paddingLeft: "5px !important",
                          marginLeft: 3
                        }}
                      ></i> */}

                      {/* <span className={"clickableList"}><i className="plr-1 fa fa-edit"/></span> */}
                    </div>
                  )}
                </div>
              </div>
              <div
                className={
                  "col-lg-3 col-md-4 col-sm-4 col-xs-3 responsivePadding"
                }

                // change class name upclassName={ "col-lg-2 col-md-3 col-ms-3 col-xs-12 responsivePadding"   }
                // style={{ display: "flex" , justifyContent: "flex-end"}}
              >
                <div className="subtask-options" style={{ display: "flex" }}>
                  <div
                    className="comment-count"
                    style={{
                      textDecoration: "none"
                    }}
                  ></div>

                  {/* <div>
                        {" "}
                        <RoyTooltip id="assignSubTask" title="Assign Sub Task">
                          <i
                            id="assignSubTask"
                            className="plr-1 fas fa-user-plus"
                            onClick={() => openAssignSubTaskModal(subtask)}
                          ></i>
                        </RoyTooltip>
                      </div> */}

                  {/* <div className="editIcon">
                    <RoyTooltip id="assignduedate" title="Assign Due Date">
                      <div style={{ display: "flex" }}>
                        <SubtaskDueDate
                          onChange={e => changeDueDate(subtask.id, e)}
                          selected={subtask.scheduledAt}
                          value={subtask.scheduledAt}
                        />
                         
                      </div>
                    </RoyTooltip>
                  </div> */}
                  <div className={"editIcon"} style={style}>
                    {/* change class name up className={"editIcon"}  */}
                    <RoyTooltip
                      id="assignsubtask"
                      title="Assign Sub Task"
                      style={{ display: "block" }}
                    >
                      {/* <i
                        id="assignsubtask"
                        style={{
                          color: "#ababab",
                          marginLeft:"4px",
                          backgroundColor: "#000c210a",
                          borderRadius:"8px",
                          paddingTop:"5px",
                          paddingBottom:"5px",
                        }}
                        className="plr-5 fas fa-user-plus fs-13"
                        onClick={() => openAssignSubTaskModal(subtask.id)}
                      ></i> */}
                      <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                        <img
                          src={useradd}
                          id="assignsubtask"
                          height="25px"
                          width="25px"
                          style={{
                            color: "#ababab",
                            marginLeft: "4px"
                            // backgroundColor: "#000c210a",
                            // borderRadius: "8px"
                          }}
                          className="plr-0 ptb-0 fas fa-user-plus fs-13"
                          // change class name   className="plr-1 fas fa-trash fs-10"
                          onClick={() => openAssignSubTaskModal(subtask)}
                        />
                      </span>
                    </RoyTooltip>
                    <RoyTooltip id="deletesubtask" title="Delete">
                      {/* <i
                        id="deletesubtask"
                        style={{
                          color: "#ababab",
                          marginLeft:"2px",
                          backgroundColor: "#000c210a",
                          borderRadius:"5px",
                          paddingTop:"5px",
                          paddingBottom:"5px",
                        }}
                        className="plr-5 fas fa-trash fs-13"
                        // change class name   className="plr-1 fas fa-trash fs-10"
                        onClick={() => setShowAlert(true)}
                      ></i> */}
                      <img
                        src={trash}
                        id="deletesubtask"
                        height="25px"
                        width="25px"
                        style={{
                          color: "#ababab"
                          // marginLeft: "1px",
                          // backgroundColor: "#000c210a",
                          // borderRadius: "8px",
                          // paddingLeft: "7px",
                          // paddingRight: "5px",
                        }}
                        className=" ptb-0 fas fa-user-plus fs-13"
                        // change class name   className="plr-1 fas fa-trash fs-10"
                        onClick={() => {
                          setShowAlert(true);
                          setSubTaskCustomMessage(subtask.title);
                        }}
                      />
                    </RoyTooltip>{" "}
                  </div>
                  {/* <div className={""} style={{ display: "flex" }}>
                    <span
                      className={"clickableList"}
                      onClick={() => setShowAlert(true)}
                    >
                      <i className="plr-1 fas fa-trash"/>
                    </span>
                    <span
                      className={"clickableList"}
                      onClick={() => openAssignSubTaskModal(subtask.id)}
                    >
                    <i className="plr-1 fas fa-user-plus"/>
                    </span>
                    <DatepickerWrapper>
                      <DatePicker
                        onChange={e => changeDueDate(subtask.id, e)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        timeCaption="time"
                        className="custom-datepicker fs-14"
                        calendarClassName="custom-calender-class"
                        placeholderText={"Schedule"}
                        // isClearable={true}
                        minDate={new Date()}
                        customInput={<ExampleCustomInput />}
                      />
                    </DatepickerWrapper>
                  </div>
                */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: 11,
            marginLeft: 43,
            marginTop: 2,
            color: "#ababab",

            display: "flex",
            alignItems: "center"
          }}
        >
          {/* {subtask.scheduledAt && (
            <div style={{ paddingRight: 5 }}>
              {moment(new Date(subtask.scheduledAt)).format("DD MMM YYYY")}
              
            </div>
          )} */}
          {subtask?.taskAssign && subtask?.taskAssign.length > 0
            ? subtask.taskAssign.map(ta => {
                return (
                  <div
                    style={{
                      paddingRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {ta.thumbnail ? (
                      <img
                        style={{
                          width: " 18px",
                          height: "18px",
                          borderRadius: "50%",
                          objectFit: "cover"
                        }}
                        src={ta.thumbnail}
                        alt="member"
                      />
                    ) : (
                      <div
                        style={profileBackground(
                          ta.name && ta.name,
                          18,
                          18,
                          12
                        )}
                      >
                        <span style={subInCap}>
                          {ta.name.charAt(0).toUpperCase()}
                        </span>
                      </div>
                    )}
                    <span
                      className="fs-11"
                      style={{
                        paddingLeft: 5
                      }}
                    >
                      {ta.name}
                    </span>
                  </div>
                );
              })
            : ""}
        </div>
        {/* {showComments && (
        <div className="comment-section">
          <div>
            {commentCount ? (
              subtask.comments.map(comment => {
                return <TaskComment key={comment.id} comment={comment} />;
              })
            ) : (
              <div className="no-subtask-info">
                <div>
                  <i className="plr-1 far fa-comment-alt"></i>
                </div>
                <div>There are No Comments shared</div>
              </div>
            )}
          </div>
          <div>
            {!addCommentFlag ? (
              <div
                className="add-comment-block"
                onClick={() => setAddCommentFlag(true)}
              >
                <i className="plr-1 fas fa-plus"></i>Add a new Comment
              </div>
            ) : (
              <div className="add-comment-input-block">
                <textarea
                  ref={textArea}
                  value={commentInput}
                  autoFocus
                  className="add-comment-input"
                  name="taskTitle"
                  rows="2"
                  onChange={e => setCommentInput(e.target.value)}
                  onBlur={closeAddingComment}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      if (!event.shiftKey) {
                        event.preventDefault();
                        addComment();
                      }
                    }
                  }}
                ></textarea>
                <div className="comment-action">
                  <button
                    className="c-btn c-success mr-10"
                    onClick={addComment}
                  >
                    Add Comment
                  </button>
                  <button
                    className="c-btn c-danger"
                    onClick={() => setAddCommentFlag(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )} */}
      </div>
      <CustomAlert
        CustomMessage={subTaskCustomMessage}
        action="delete"
        messageType="item"
        PRIMARY_COLOR={PRIMARY_COLOR}
        show={showAlert}
        onConfirm={() => {
          deleteSubtaskHandler(subtask.id);
          setShowAlert(false);
        }}
        onCancel={() => setShowAlert(false)}
        alertMessage={alertMessage}
      />
    </>
  );
};

export default Subtask;
