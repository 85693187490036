/** @format */

import auth from "./auth/reducer";
import projects from "./projects/reducer";
import business from "./business/reducer";
import labels from "./labels/reducer";
import themeChanger from "./themeChanger/reducer";
import LanguageSwitcher from "./languageSwitcher/reducer";
import language from "./languageSwitcher/reducer";
import themeSetting from "./themeSettings/reducer";
import scrumboard from "./scrumboard/reducer";
import projectModal from "./projectModal/reducer";
import ErrorAlert from "./globalAlert/reducer";
import updateProjectActions from "./projectAction/reducer";
import workspaceDropdownAction from "./workspaceDropdown/reducer";
import profileModelAction from "./profileModel/reducer";
import fileDropActions from "./Filedrop/reducer";
import InternetState from "./network/reducer";
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    projects,
    language,
    labels,
    business,
    ErrorAlert,
    projectModal,
    workspaceDropdownAction,
    profileModelAction,
    updateProjectActions,
    themeChanger,
    InternetState,
    LanguageSwitcher,
    themeSetting,
    scrumboard,
    fileDropActions,
    router: routerReducer,
    ...asyncReducers
  });

export default createReducer;
