import React, { useEffect, useCallback } from "react";
import "./style.css";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import Radium from "radium";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import DescriptionEditorWrapper from "./DescriptionEdiotrWrapper.style";
import "react-quill/dist/quill.core.css";
var atValues = [];

function TaskDescriptionEditor({
  inputValue,
  onChange,
  setIsFocus,
  placeholder,
  id,
  rows
}) {
  useEffect(() => {
    return () => {};
  }, []);
  // Autofocus method
  const useFocusAndSetRef = ref => {
    ref = useCallback(
      node => {
        if (node !== null) {
          ref.current = node; // it is not done on it's own
          const len = inputValue.length;
          const selection = { index: len, length: len };
          node.setEditorSelection(node.editor, selection);
        }
      },
      [ref]
    );

    return ref;
  };

  let editorRef;
  // editorRef = useFocusAndSetRef(editorRef);
  const handleKeyDown = e => {
    if (e.shiftKey && e.keyCode == 13) {
    }
  };
  return (
    <>
      <div
        className="mt-10 "
        style={{ border: "1px solid #EBEBEB", borderRadius: "3px" }}
      >
        <DescriptionEditorWrapper>
          <ReactQuill
            ref={editorRef}
            id={id}
            rows={rows || "3"}
            value={inputValue}
            onChange={(content, delta, source, editor) => {
              onChange(content);
            }}
            theme={"bubble"}
            placeholder={placeholder}
            modules={TaskDescriptionEditor.modules}
            formats={TaskDescriptionEditor.formats}
            onKeyDown={e => handleKeyDown(e)}
            bounds={".app"}
          />
        </DescriptionEditorWrapper>
      </div>
    </>
  );
}
// export default CardTask;
TaskDescriptionEditor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike", "blockquote", "link"],
    [{ list: "ordered" }, { list: "bullet" }]
    // [
    //   {
    //     color: ["#F00", "#0F0", "#00F", "#000", "#FFF", "color-picker"]
    //   }
    // ]
  ],
  clipboard: {
    matchVisual: false
  },
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    source: function(searchTerm, renderList, mentionChar) {
      let values;
      if (mentionChar === "@") {
        values = atValues;
      }
      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    }
  }
};
// TaskDescriptionEditor.keyboard({
//   key: 'B',
//   shortKey: true
// }, function(range, context) {
//   TaskDescriptionEditor.formatText(range, 'bold', true);
// });

// addBinding may also be called with one parameter,
// in the same form as in initialization
// TaskDescriptionEditor.keyboard.addBinding({ key: 'B', shiftKey: true }, handler);

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
TaskDescriptionEditor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "mention"
  // "color"
];

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {})(Radium(TaskDescriptionEditor));
