import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getBusinessList,
  swicthBusiness
} from "helper/services/businessServices";
import { HeaderDownArrowImage } from "helper/constant";
import {
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import { getProject, getLables } from "helper/services/projectServices";
import WorkspacePopover from "./WorkspacePopover";
import { withRouter } from "react-router-dom";
import labelActions from "redux/labels/actions";
import projectActions from "redux/projects/actions";
import businessActions from "redux/business/actions";
import AuthActions from "redux/auth/actions";
import { handleRecentWorkspace } from "util/RecentWorkspace";
import IntlMessages from "util/intlMessages";
const { update_token, update_business } = AuthActions;
const { default_business } = businessActions;
const { set_labels, update_Label } = labelActions;
const { set_projects, update_recent_Project } = projectActions;
const GlobalWorkspaceSelection = props => {
  const [isToggleAllowed, setIsToggleAllowed] = useState(true);
  const [isBusinessList, setIsBusinessList] = useState(false);
  const [isBusinessLoader, setIsBusinessLoader] = useState(false);
  const [businessListArr, setBusinessListArr] = useState([]);
  const [archivedbusinessListArr, setArchivedBusinessListArr] = useState([]);
  const [isShowArchived, setIsShowArchived] = useState(false);
  const [isDefaultLoader, setIsDefaultLoader] = useState({
    id: "",
    status: false
  });
  const {
    currentbusinessName,
    currentbusinessId,
    history,
    toggleNewBusiness
  } = props;
  useEffect(() => {
    if (isBusinessList) {
      retriveBusiness();
    }
  }, [isBusinessList]);
  const retriveBusiness = async () => {
    try {
      setIsBusinessLoader(true);
      const Response = await getBusinessList();
      if (Response) {
        if (Response.error) {
        }
        if (Response.data) {
          setIsBusinessLoader(false);
          // check if user have any one default busienss if yes than modal header have toggle option otherwise user cannot toggle
          if (
            Response.data?.some(
              a =>
                a.collaborator[0]?.isDefault &&
                props?.auth?.user?.businessId === a.id
            )
          ) {
            setIsToggleAllowed(true);
          } else {
            setIsToggleAllowed(false);
          }
          await filterArchiveBusiness(Response.data);
          handleRecentWorkspaceAccess(Response.data);
        }
      }
    } catch (e) {}
  };
  const handleRecentWorkspaceAccess = (businessListArr = []) => {
    // check if recent workspace access is availablein businessListArr if not
    let recent_workspace = JSON.parse(localStorage.getItem("recent_workspace"));
    if (businessListArr.length > 0 && recent_workspace.length > 0) {
      let workspaceIds =
        businessListArr && businessListArr.map(data => data.id);
      let fileteredRecentWorkspace =
        recent_workspace &&
        recent_workspace.filter(
          workspace => workspaceIds.indexOf(workspace.businessId) !== -1
        );
      localStorage.setItem(
        "recent_workspace",
        JSON.stringify(fileteredRecentWorkspace ? fileteredRecentWorkspace : [])
      );
      return;
    }
    if (recent_workspace.length > 0 && businessListArr.length === 0) {
      localStorage.setItem("recent_workspace", JSON.stringify([]));
    }
    return;
  };
  const filterArchiveBusiness = async businessArr => {
    try {
      if (businessArr?.length > 0) {
        let archivedBusinessArr = [];
        let unarchivedBusinessArr = [];
        await Promise.all(
          (archivedBusinessArr =
            businessArr && businessArr.filter(business => business.isArchived))
        );
        await Promise.all(
          (archivedBusinessArr =
            archivedBusinessArr &&
            archivedBusinessArr
              .sort(
                (a, b) =>
                  a.collaborator[0]?.isDefault - b.collaborator[0]?.isDefault
              )
              .reverse())
        );
        await Promise.all(
          (unarchivedBusinessArr =
            businessArr && businessArr.filter(business => !business.isArchived))
        );
        await Promise.all(
          (unarchivedBusinessArr =
            unarchivedBusinessArr &&
            unarchivedBusinessArr
              .sort(
                (a, b) =>
                  a.collaborator[0]?.isDefault - b.collaborator[0]?.isDefault
              )
              .reverse())
        );

        setBusinessListArr([...unarchivedBusinessArr]);
        setIsShowArchived(unarchivedBusinessArr?.length > 0 ? false : true);
        setArchivedBusinessListArr([...archivedBusinessArr]);
        checkForDefaulBusinessInArchive([...archivedBusinessArr]);
      } else {
        setBusinessListArr([]);
        setArchivedBusinessListArr([]);
      }
    } catch (e) {}
  };
  const checkForDefaulBusinessInArchive = async business => {
    var getDefault = [];
    if (business) {
      getDefault =
        business && business.filter(data => data.collaborator[0]?.isDefault);
    }

    if (getDefault && getDefault.length > 0) {
      setIsShowArchived(true);
    } else {
      setIsShowArchived(false);
    }
  };
  const updateProjectandLabel = async token => {
    fecthProject(token);
    fetchLabels(token);
  };
  const updateToken = (token, businessName, businessMeta, id) => {
    props.update_token(token);
    props.default_business({
      id: id,
      businessName: businessName,
      businessMeta: businessMeta
    });
    fecthProject(token);
    fetchLabels(token);
    // updateUser(token);
    props.update_business({ businessId: id, businessName, businessMeta });
  };
  const fetchLabels = async token => {
    try {
      const Response = await getLables(token);
      if (Response) {
        if (Response.data) {
          props.set_labels({ labels: Response.data });
          updateSidebarLabelToSidebar(Response.data);
        }
        if (Response.error) {
        }
      }
    } catch (e) {}
  };

  const fecthProject = async token => {
    const Response = await getProject(token);
    if (Response) {
      props.set_projects({ projects: Response.data });
      updateSidebarProjectToSidebar(Response.data);
    }
  };
  const selectBusiness = async id => {
    try {
      setIsDefaultLoader({
        id: id,
        status: true
      });

      const Response = await swicthBusiness(id);
      if (Response) {
        if (Response.data) {
          let businessArr = businessListArr;
          businessArr.map((data, i) => {
            if (data.id == Response.data.business.id) {
              businessArr[i].isDefault = Response.data.business.isDefault;
            } else {
              businessArr[i].isDefault = false;
            }
          });
          setBusinessListArr([...businessArr]);
          handleRecentWorkspace(Response.data.business);
          await updateToken(
            Response.data.token,
            Response.data.business.title,
            Response.data.business?.businessMeta
              ? Response.data.business?.businessMeta
              : null,
            Response.data.business.id
          );

          props.update_recent_Project({
            project_id: ""
          });
          setIsBusinessList(!isBusinessList);
          // await props.handleBusinessJoinRoom(
          //   props.auth.user.businessId,
          //   Response.data.business.id,
          //   Response.data.token
          // );

          updateProjectandLabel(Response.data.token);
          setIsDefaultLoader({
            id: "",
            status: false
          });
        }
        if (Response.error) {
          setIsDefaultLoader({
            id: "",
            status: false
          });
        }
      }
    } catch (e) {
      console.log(e);

      setIsDefaultLoader({
        id: "",
        status: false
      });
    }
  };
  return (
    <>
      <div className="Workspace-name-header pl-25 ">
        <div
          id="global-header-business"
          style={{
            backgroundColor: "rgb(255 255 255 / 18%)",
            maxWidth: "180px",
            minWidth: "130px",
            height: "36px",
            display: "flex",
            borderRadius: "5px",
            cursor: "pointer",
            justifyContent: "space-between"
          }}
          onClick={() => {
            setIsBusinessList(!isBusinessList);
          }}
        >
          <span
            style={{
              display: "inline-block",
              alignItems: "center",
              padding: "6px 12px",
              color: "white",
              fontSize: "14px",
              fontWeight: 600,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {/* Workspaces */}
            {currentbusinessName ? (
              currentbusinessName
            ) : (
              <IntlMessages id={"header.workspace"} />
            )}
          </span>

          <span
            style={{
              display: "flex",
              alignItems: "center",
              color: "white",
              fontSize: "14px",
              fontWeight: 600,
              // paddingLeft: 2
              marginRight: 11
            }}
          >
            <img
              alt=""
              src={HeaderDownArrowImage}
              style={{
                height: 13,
                width: 13
              }}
            ></img>
            {/* <i
              className="pl-2 fas fa-angle-down  "
              style={{
                margin: 0,
                position: "relative",
                top: "23%",
                transform: "translateY(-50%)"
              }}
            /> */}
          </span>
        </div>
      </div>
      <WorkspacePopover
        toggleNewBusiness={toggleNewBusiness}
        PRIMARY_COLOR={props.topbarTheme.buttonColor}
        swicthBusiness={selectBusiness}
        history={history}
        isShowArchived={isShowArchived}
        setIsShowArchived={setIsShowArchived}
        modal={isBusinessList}
        setmodal={() => setIsBusinessList(!isBusinessList)}
        target={"global-header-business"}
        businessListArr={businessListArr}
        archivedBusinessArr={archivedbusinessListArr}
        currentbusinessName={currentbusinessName}
        currentbusinessId={currentbusinessId}
        currentBusinessMeta={props?.auth?.user?.businessThumbnailImage || []}
        isBusinessLoader={isBusinessLoader}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, {
    update_token,
    update_business,
    default_business,
    set_labels,
    update_Label,
    set_projects,
    update_recent_Project
  })
)(GlobalWorkspaceSelection);
