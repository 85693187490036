/** @format */

import workspaceDropdownAction from "./actions";

const initialState = {
  isOpen: false
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case workspaceDropdownAction.OPEN_WORKSPACE_MODAL:
      return {
        ...state,
        isOpen: true
      };

    case workspaceDropdownAction.CLOSE_WORKSPACE_MODAL:
      return {
        ...state,
        isOpen: false
      };

    default:
      return state;
  }
}
