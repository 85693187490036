import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import IconTint from "react-icon-tint";
import DatePicker, { CalendarContainer } from "react-datepicker";
import Switch from "react-switch";
import { profileBackground } from "helper/methods/getUserAvatarColor";
// import DatePicker, { CalendarContainer } from "react-datepicker";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import Select from "react-select";
import {
  iconClock,
  iconTag,
  iconTagWhite,
  assignnew,
  editPencilIcon,
  singleCheckIcon,
  NewTaskPriorityCross
} from "helper/constant";
import "./modal.style.css";
import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";
import NewTaskDescription from "./NewTaskDescription";
import "./quilleditor.style.css";
import classNames from "classnames";
import AddProject from "components/projects/AddProject";
import {
  checkforunique,
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import {
  allPriority,
  projectSocketRoomPrefix,
  getPriorityPath,
  getPriorityNumber,
  getPriorityColorCode
} from "constatnt";
import NewTaskInput from "./NewTaskInput";
import { getColorCode } from "components/projects/utils/color";
import { connect } from "react-redux";
import RoyTooltip from "components/common/RoyTooltip";
import WorkspaceDropdown from "./WorkspaceDropdown";
import AssigneeDropdown from "./AssigneeDropdown";
import AddLabel from "components/Labels/AddLabel";
import { sidebarData } from "util/data/sidebar";
import labelActions from "redux/labels/actions";
import ApiCalendar from "react-google-calendar-api";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "../notifications/ToastMessage";
import WorkspaceNestedItem from "./WorkspaceNestedItem";
import DatepickerWrapper from "./datepicker.style";
import LabelsPopover from "components/Labels/LabelsPopover";
import NewTaskInputWrapper from "./NewTaskInputWrapper.style";
import NewTaskDescriptionWrapper from "./NewTaskDescriptionWrapper.style";
import { getBusinessProject } from "helper/services/businessServices";
import projectActions from "redux/projects/actions";
import { randomUUID } from "helper/methods";
import IntlMessages from "util/intlMessages";
import { de } from "date-fns/locale";
import { nl } from "date-fns/locale";

const { update_Project } = projectActions;
const { update_Label } = labelActions;
const NewTask = props => {
  const [prioritySelection, setPrioritySelection] = useState(false);
  const [labelSelection, setLabelSelection] = useState(false);
  const [addLabelModal, setAddLabelModal] = useState(false);
  // const [dueDate, setDueDate] = useState("");

  const [isScheduleDateCalendar, setIsScheduleDateCalendar] = useState(false);
  const [isRemainderDateCalendar, setIsRemainderDateCalendar] = useState(false);
  const [isRemainderDateAtCalendar, setIsRemainderDateAtCalendar] = useState(
    false
  );
  const [taskInput, setTaskinput] = useState("");
  const [taskDescription, setTaskdescription] = useState("");
  const [taskDueDate, setTaskduedate] = useState("");
  const [taskReminder, setTaskReminder] = useState();
  const [taskPriority, setTaskpriority] = useState("no_priority");
  const [taskAssigned, setTaskassigned] = useState("");
  const [taskLabel, setTaskLabel] = useState("");
  const [taskWorkspace, setTaskWorkspace] = useState("");
  const [isTaskAssignDropdown, setIsTaskAssignDropdown] = useState(false);
  const [isLoaderWorkspace, setIsLoaderWorkspace] = useState(false);
  const [defaultWorkspaceId, setDefaultWorkspaceId] = useState("");
  const [projectModal, setProjectModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [isWorkspaceError, setIsWorkspaceError] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [dueDateOnChangeSet, setDueDateOnChangeSet] = useState(null);
  const [remindersAtOnChangeSet, setRemindersAtOnChangeSet] = useState("");
  const [projectCollaborators, setProjectCollaborators] = useState([]);
  const [remindersAt, setRemindersAt] = useState();
  const [isNewTaskModleClick, setIsNewTaskModleClick] = useState(false);
  const [locale, setLocale] = useState();

  const todayDate = new Date();
  const newtaskEditorRef = useRef(null);
  const { addToast } = useToasts();
  const {
    modal,
    toggle,
    addTaskHandler,
    topbarTheme,
    onKeyDown,
    updateTask,
    action,
    taskProject,
    setTaskProject,
    toggleAction,
    taskData,
    isProjectSelection,
    defaultTodayDate,
    defaultUpcommingDate,
    updateLabelToParent,
    setIsBoardCardscroll,
    setIsGoogleCalenderData,
    setCalenderId,
    setSyncGoogle,
    syncGoogle,
    hasClearEnabledOnAddTask
  } = props;

  const { AllowAddPreviousDateTask } = props?.auth?.user?.userSetting
    ? props.auth.user.userSetting
    : false;
  useEffect(() => {
    defaultTodayDate && onChangeDueDate(new Date());
    defaultUpcommingDate && onChangeDueDate(new Date(defaultUpcommingDate));
    if (modal) {
      retriveBusinessProjects();
      scrollToBottom();
    }

    if (updateTask && taskData) {
      if (props.collaboratorList && props.collaboratorList?.length > 0) {
        setProjectCollaborators([...props.collaboratorList]);
      } else {
        retriveBusinessProjectsCollab(taskData?.project?.id);
      }
    } else {
      if (props.collaboratorList && props.collaboratorList?.length > 0) {
        setProjectCollaborators([...props.collaboratorList]);
      }
    }
    if (taskData) {
      toggleEditTask(taskData);
    }
    if (updateTask && action == "label") {
      setLabelSelection(true);
    }
    if (updateTask && action == "priority") {
      setTimeout(() => {
        setPrioritySelection(true);
      }, 500);
    }
    if (updateTask && action == "schedule") {
      setIsScheduleDateCalendar(true);
    }
    if (updateTask && action == "taskassign") {
      setIsTaskAssignDropdown(true);
    }
    if (taskData) {
    }
    if (props.language.locale == "de") {
      setLocale(de);
    } else if (props.language.locale == "nl") {
      setLocale(nl);
    } else {
      setLocale(props.language.locale);
    }

    return () => {
      doHandleCleanupComponent();
    };
  }, []);
  const doHandleCleanupComponent = () => {
    onChangeInput("");
    onChangeDescription("");
    onChangeDueDate("");
    onChangeReminder("");
    // setDueDate("");
    onChangeTaskPriority("no_priority");
    setTaskassigned("");
    onChangeTaskLabel([]);
    onChageWorkspace("");
    setTaskProject("");
    setSyncGoogle && setSyncGoogle(false);
    setIsTaskAssignDropdown(false);
    setIsGoogleCalenderData && setIsGoogleCalenderData();
  };
  const scrollToBottom = () => {
    const scroll = newtaskEditorRef.current.clientHeight;
    newtaskEditorRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  };
  const getCollabData = givenId => {
    if (props.collaboratorList) {
      props.collaboratorList &&
        props.collaboratorList.map(data => {
          if (data?.user && data.user.id === givenId) {
            return data;
          }
        });
    }

    return false;
  };

  const retriveBusinessProjects = async () => {
    try {
      setIsLoaderWorkspace(true);
      const Response = await getBusinessProject();
      if (Response) {
        if (Response.data) {
          let businessArr = [];
          await Promise.all(
            Response.data &&
              Response.data.map(a => {
                businessArr.push(a.business);
                handleDefaultProjectSelection(a.business);
                // if (props.auth?.user?.businessId === a.business.id) {
                //   setSelectedProject({
                //     workspace: {
                //       ...a.business,
                //       value: a.business.title,
                //       label: a.business.title
                //     }
                //   });
                // }
              })
          );
          businessArr =
            businessArr &&
            businessArr.sort(data => {
              if (props.auth?.user?.businessId === data.id) return -1;
              else if (props.auth?.user?.businessId !== data.id) return 1;
              else return 0;
            });

          setWorkspaceData([...businessArr]);
        }
        if (Response.error) {
        }
        setIsLoaderWorkspace(false);
      }
    } catch (e) {
      setIsLoaderWorkspace(false);
      console.log(e);
    }
  };
  const handleDefaultProjectSelection = business => {
    if (props.auth.user.businessId === business.id) {
      if (business.project && business.project?.length > 0) {
        if (
          props.projects.recent_project.project_id &&
          props.projects.recent_project.project_id !== ""
        ) {
          let status = false;
          business.project &&
            business.project.map(project => {
              if (
                project.id ===
                parseInt(props.projects?.recent_project.project_id)
              ) {
                status = true;

                let selectedprojectData = "";

                selectedprojectData = {
                  workspace: {
                    ...business,
                    value: business.title,
                    label: business.title
                  },
                  project: {
                    ...project,
                    value: project.name,
                    label: project.name
                  }
                };
                setProjectCollaborators([...project.collaborator]);
                if (project && project.section.length > 0) {
                  let sectionArr = getSortedFirstIndex(project.section);
                  selectedprojectData = {
                    ...selectedprojectData,
                    section: {
                      ...sectionArr[0],
                      value: sectionArr[0].title,
                      label: sectionArr[0].title
                    }
                  };
                }

                setSelectedProject(selectedprojectData);
              }
            });
          if (!status) {
            let selectedprojectData = "";
            selectedprojectData = {
              workspace: {
                ...business,
                value: business.title,
                label: business.title
              }
            };
            if (business.project?.length > 0) {
              selectedprojectData = {
                ...selectedprojectData,
                project: {
                  ...business.project[0],
                  value: business.project[0].name,
                  label: business.project[0].name
                }
              };
              setProjectCollaborators([...business.project[0].collaborator]);
            }
            if (business.project[0].section.length > 0) {
              let sectionArr = getSortedFirstIndex(business.project[0].section);
              selectedprojectData = {
                ...selectedprojectData,
                section: {
                  ...sectionArr[0],
                  value: sectionArr[0].title,
                  label: sectionArr[0].title
                }
              };
            }

            setSelectedProject(selectedprojectData);
          }
        } else {
          let selectedprojectData = "";
          selectedprojectData = {
            workspace: {
              ...business,
              value: business.title,
              label: business.title
            }
          };
          if (business.project?.length > 0) {
            selectedprojectData = {
              ...selectedprojectData,
              project: {
                ...business.project[0],
                value: business.project[0].name,
                label: business.project[0].name
              }
            };
            setProjectCollaborators([...business.project[0].collaborator]);
          }

          if (business.project[0]?.section.length > 0) {
            let sectionArr = getSortedFirstIndex(business.project[0].section);
            selectedprojectData = {
              ...selectedprojectData,
              section: {
                ...sectionArr[0],
                value: sectionArr[0].title,
                label: sectionArr[0].title
              }
            };
          }

          setSelectedProject(selectedprojectData);
        }
      }
    }
  };
  const getSortedFirstIndex = section => {
    let data = section && section.sort((a, b) => a.id - b.id);

    return data;
  };
  const retriveBusinessProjectsCollab = async ProjectId => {
    try {
      setIsLoaderWorkspace(true);
      const Response = await getBusinessProject();
      if (Response) {
        if (Response.data) {
          await Promise.all(
            Response.data &&
              Response.data.map(a => {
                if (a.business) {
                  a.business.project &&
                    a.business.project.map((projects, j) => {
                      if (ProjectId == projects.id) {
                        setProjectCollaborators([...projects.collaborator]);
                      }
                    });
                }
              })
          );
        }
        if (Response.error) {
        }
        setIsLoaderWorkspace(false);
      }
    } catch (e) {
      setIsLoaderWorkspace(false);
    }
  };
  const checkUserAssignedPresentInNewProject = (
    assignedUser,
    projectCollaborators
  ) => {
    let status = false;
    if (projectCollaborators && assignedUser) {
      projectCollaborators &&
        projectCollaborators.map(user => {
          if (user.user.id === assignedUser.user.id) {
            status = true;
          }
        });
      // return status;
    }
  };
  const handleWorskpaceProjectSelection = data => {
    if (data) {
      if (
        data?.workspace?.id !== selectedProject?.workspace?.id &&
        selectedProject?.project
      ) {
        // remove all changes done by user label,date,reminder,priority,assign user
        // showNotifications(
        //   "You've changed Workspace ,all changes done like label,date,reminder,priority,assign user no longer available",
        //   "success"
        // );
        if (
          !checkUserAssignedPresentInNewProject(
            taskAssigned,
            data.project.collaborator
          )
        ) {
          setTaskassigned(null);
        }

        onChangeDueDate("");
        onChangeReminder("");
        onChangeTaskPriority("no_priority");
        setTaskassigned("");
        onChangeTaskLabel([]);

        // setSyncGoogle(false);
      }

      // setFetchingProjectCollab(true);

      setSelectedProject(data);
      if (data.project) {
        setProjectCollaborators([...data.project.collaborator]);
      }
      // setFetchingProjectCollab(false);
    }
  };
  const toggleEditTask = async data => {
    if (data) {
      try {
        setTaskinput(data?.title || "");
        setTaskdescription(data?.description || "");
        setTaskduedate(
          data?.scheduledAt !== null ? new Date(data?.scheduledAt) : ""
        );
        setDueDateOnChangeSet(
          data?.scheduledAt !== null ? new Date(data?.scheduledAt) : ""
        );
        setTaskReminder(
          data?.reminderAt !== null ? new Date(data?.reminderAt) : ""
        );
        setTaskpriority(data?.priority);

        setTaskassigned(
          data?.taskAssign
            ? {
                ...getCollabData(data?.taskAssign.id),
                user: data?.taskAssign
              }
            : ""
        );
        setTaskLabel(data.labels);
        setTaskProject(data.project);
        setCalenderId(data.calendar_id);
        // data?.project?.id && retriveBusinessProjectsCollab(data.project?.id);
      } catch (e) {}
    }
  };
  const onChangeInput = data => {
    setTaskinput(data);
  };

  const onChangeDescription = data => setTaskdescription(data);

  const onChangeDueDate = data => {
    setTaskduedate(data);
    if (updateTask && action == "schedule") {
      onSubmit(null, {
        scheduledAt: data ? data : ""
      });
    }
  };

  const onChangeReminder = data => setTaskReminder(data);

  const onChangeTaskPriority = data => {
    setTaskpriority(data);
    if (updateTask && action == "priority") {
      onSubmit(null, {
        priority: data
      });
    }
  };
  const getArraytoString = arrayData => {
    if (arrayData && arrayData.length > 0) {
      let newArray = "";
      arrayData &&
        arrayData.map(
          a => (newArray = newArray ? newArray + `,${a.id}` : `${a.id}`)
        );

      return newArray;
    } else {
      return "";
    }
  };
  const onChangeTaskAssign = data => {
    if (data && data.user) {
      setTaskassigned(data);

      if (updateTask && action == "taskassign") {
        let userIds = getArraytoString(data.user);

        onSubmit(null, {
          user_id: userIds
        });
      }
    }

    // if(typeof taskAssigned=="object" ){
    //   let dataA=[]
    //   dataA.user=[taskAssigned.user]
    //
    //   dataA && dataA?.user
    //   ? dataA.user.push(data)
    //   : (dataA.user = [data]);
    // setTaskassigned(dataA);
    // }
    // if(typeof taskAssigned=="array" ){

    // }
    // let assignData=taskAssigned
    // assignData && assignData?.user
    //   ? assignData.user.push(data)
    //   : (assignData.user = [data]);
    // setTaskassigned(assignData);
    //
    // if (updateTask && action == "taskassign") {
    //
    //   onSubmit(null, {
    //     user_id: data && data.id ? data.id.toString() : ""
    //   }
    //   );
    // }
  };

  const onChangeTaskLabel = data => {
    setTaskLabel(data);
  };

  const getRoundOfMinute = (givenDate = new Date()) => {
    try {
      const start = moment(givenDate);
      const remainder = 15 - (start.minute() % 15);
      const dateTime = moment(start).add(remainder, "minutes");

      return new Date(dateTime);
    } catch (E) {
      console.log(E);
    }
  };
  const onChageWorkspace = data => setTaskWorkspace(data);

  const ids = taskLabel && taskLabel.map(a => a.id);

  const togglePrioritySelection = () => {
    setPrioritySelection(!prioritySelection);
  };

  const ScheduleDateCalendar = ({ className, children }) => {
    return (
      <div>
        <CalendarContainer
        // className={className + " custom-calender-class-"}
        // style={{ display: "grid !important" }}
        >
          <div></div>
          <div style={{ position: "relative" }} className={className}>
            {children}
          </div>
          <div
            style={{
              // display: "block",
              // paddingLeft: 70,
              // textDecoration: "underline",
              // cursor: "pointer",
              display: "flex",
              // justifyContent: "center",
              alignContent: "center",
              // backgroundColor: "rgb(245 245 246)",
              // textDecoration: "underline",
              cursor: "pointer",
              borderBottom: "1px solid #aeaeae",
              borderRight: "1px solid #aeaeae",
              borderLeft: "1px solid #aeaeae",
              borderTop: "none !important",
              borderBottomLeftRadius: "0.3rem",
              borderBottomRightRadius: "0.3rem",
              border: "0px solid",
              fontSize: 12,
              marginTop: "-7px",
              backgroundColor: "white",
              padding: "10px",
              color: "black"
            }}
          >
            <div style={{ paddingRight: 6 }}>
              <div
                onClick={() => {
                  updateDueDate("");
                  onChangeDueDates("");
                  setIsScheduleDateCalendar(!isScheduleDateCalendar);
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  fontSize: 14,
                  padding: 6,
                  borderRadius: 4,
                  border: "1px solid #E6E6E6"
                }}
              >
                <IntlMessages id={"today.removedate"} />
              </div>
            </div>
            <div
              onClick={() => {
                updateDueDate(
                  dueDateOnChangeSet ? dueDateOnChangeSet : getRoundOfMinute()
                );
                setIsScheduleDateCalendar(!isScheduleDateCalendar);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                width: "30%",
                fontSize: 14,
                background: props.topbarTheme.buttonColor,
                padding: 6,
                borderRadius: 4,
                color: "#FFF"
              }}
            >
              <IntlMessages id={"globalsearchbar.applybutton"} />
            </div>
          </div>
        </CalendarContainer>
      </div>
    );
  };
  const UpdateRemainderDateChanges = ({ className, children }) => {
    return (
      <div>
        <CalendarContainer
        // className={className + " custom-calender-class-"}
        // style={{ display: "grid !important" }}
        >
          <div></div>
          <div style={{ position: "relative" }} className={className}>
            {children}
          </div>
          <div
            // onClick={() => updateRemindersAt("")}
            style={{
              // display: "block",
              // paddingLeft: 70,
              // textDecoration: "underline",
              // cursor: "pointer",
              display: "flex",
              // justifyContent: "center",
              alignContent: "center",
              // backgroundColor: "rgb(245 245 246)",
              // textDecoration: "underline",
              cursor: "pointer",
              borderBottom: "1px solid #aeaeae",
              borderRight: "1px solid #aeaeae",
              borderLeft: "1px solid #aeaeae",
              borderTop: "none !important",
              borderBottomLeftRadius: "0.3rem",
              border: "0px solid",
              background: "white",
              borderBottomRightRadius: "0.3rem",
              fontSize: 12,
              marginTop: "-7px",
              padding: "10px "
            }}
          >
            <div style={{ paddingRight: 6 }}>
              <div
                onClick={() => {
                  updateRemindersAt("");
                  onChangeReminder("");
                  setIsRemainderDateCalendar(false);
                  setIsRemainderDateAtCalendar(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  fontSize: 14,
                  padding: 6,
                  borderRadius: 4,
                  border: "1px solid #E6E6E6",
                  color: "black"
                }}
              >
                <IntlMessages id={"today.removedate"} />
              </div>
            </div>
            <div
              onClick={() => {
                updateRemindersAt(
                  remindersAtOnChangeSet
                    ? remindersAtOnChangeSet
                    : getRoundOfMinute()
                );
                setIsRemainderDateCalendar(false);
                setIsRemainderDateAtCalendar(false);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                width: "30%",
                fontSize: 14,
                background: props.topbarTheme.buttonColor,
                padding: 6,
                borderRadius: 4,
                color: "#FFF"
              }}
            >
              <IntlMessages id={"globalsearchbar.applybutton"} />
            </div>
          </div>
        </CalendarContainer>
      </div>
    );
  };

  const TaskDueDate = React.forwardRef(({ value, onClick }, ref) => (
    <RoyTooltip
      id="DueDate"
      title={InjectPlaceHolderMessage(
        "today.due_date",
        props?.LanguageSwitcher?.language?.languageId
      )}
    >
      <div className="" style={{ paddingTop: 0, paddingLeft: "0px" }}>
        <Button
          id="DueDate"
          onClick={onClick}
          ref={ref}
          className="c-btn  border c-outline-light "
          style={{
            height: "26px",
            backgroundColor: "#FFF !important",
            border: "1px solid !important",
            borderColor: "rgba(0,0,0,.4) !important",
            boxShadow: "none",
            paddingLeft: 15,
            paddingRight: 10,
            display: "flex !important",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <span
            onClick={() => setIsScheduleDateCalendar(!isScheduleDateCalendar)}
            style={{
              color: "#808080",
              fontSize: "14px",
              position: "relative",
              top: "-5px",
              minWidth: 100
            }}
          >
            {taskDueDate == "" ? (
              <IntlMessages id={"task-details.schedule"} />
            ) : (
              moment(taskDueDate).format("DD MMM hh:mm a")
            )}
          </span>
        </Button>
      </div>
    </RoyTooltip>
  ));
  const TaskReminder = React.forwardRef(({ value, onClick }, ref) => (
    <div>
      <div className="" style={{ paddingTop: 4 }}>
        <Button
          onClick={onClick}
          ref={ref}
          className="c-btns  border c-outline-light plr-10"
          style={{
            height: "26px",
            backgroundColor: "#FFF !important",
            border: "1px solid !important",
            borderColor: "rgba(0,0,0,.4) !important",
            boxShadow: "none",
            padding: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <span
            onClick={() =>
              setIsRemainderDateAtCalendar(!isRemainderDateAtCalendar)
            }
          >
            {taskReminder ? (
              <>
                <IntlMessages id={"today.reminderat"} />{" "}
                <span>{moment(taskReminder).format("DD MMM h:mm a")} </span>
              </>
            ) : (
              "Reminder"
            )}
          </span>
        </Button>
      </div>
    </div>
  ));
  const updateDueDate = date => {
    onChangeDueDate(date);
  };

  const onChangeDueDates = async date => {
    setDueDateOnChangeSet(date);
    setIsScheduleDateCalendar(true);
  };

  const onChangeRemindersAt = async date => {
    setRemindersAtOnChangeSet(date);
    // setIsRemainderDateCalendar(true);
  };
  const updateRemindersAt = date => {
    //
    onChangeReminder(date);
  };

  const onChangeSyncGoogle = async () => {
    if (!syncGoogle) {
      checkIsGoogleAuthenticated();
    }
    setSyncGoogle(!syncGoogle);
  };
  const handleAuthGoogle = async (event, name) => {
    if (name === "sign-in") {
      const response = await ApiCalendar.handleAuthClick();
      if (!response) {
        setSyncGoogle(!syncGoogle);
      }
    } else if (name === "sign-out") {
      ApiCalendar.handleSignoutClick();
    }
  };
  const checkIsGoogleAuthenticated = async () => {
    const response = await ApiCalendar.getBasicUserProfile();
    if (!response) {
      handleAuthGoogle("", "sign-in");
    }
  };
  const SyncTasktoGoogle = async taskData => {
    // list upcomming
    // if (ApiCalendar.sign)
    //   ApiCalendar.listUpcomingEvents(10).then(({ result }) => {
    //
    //   });
    if (taskData)
      var eventFromNow = {
        summary: taskData.title.toString(),
        description: taskData.description.toString(),
        start: {
          dateTime: moment(new Date(taskData.scheduledAt)).add("05", "days"),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        end: {
          dateTime: moment(new Date(taskData.scheduledAt)).add("05", "days"),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      };
    // ApiCalendar.setCalendar()
    ApiCalendar.createEvent(eventFromNow)
      .then(result => {
        if (result.error) {
          addToast(ToastMessage(result.error.message), {
            appearance: "error",
            autoDismiss: true
          });
        }
        addToast(ToastMessage("Sync to Google"), {
          appearance: "success",
          autoDismiss: true
        });
        return true;
      })
      .catch(error => {
        addToast(
          ToastMessage(
            <IntlMessages id={"task-details.something_went_wrong"} />
          ),
          {
            appearance: "error",
            autoDismiss: true
          }
        );
      });
  };
  const addNewProjectToWorkspaceArr = async newProject => {
    var arr = [...workspaceData];
    (await arr) &&
      arr.map((business, i) => {
        if (business.id == newProject?.business?.id) {
          var projectArr = arr[i].project.length > 0 ? [...arr[i].project] : [];
          projectArr.push({
            collaborator: [],
            ...newProject
          });
          arr[i].project = [...projectArr];
        }
      });

    setWorkspaceData([...arr]);
  };
  const updateProjectToRedux = data => {
    props.update_Project(data);
  };
  const handlePostAddProject = data => {
    if (props?.auth?.user?.businessId === data.business.id) {
      updateProjectToRedux(data);
    }
    addNewProjectToWorkspaceArr(data);
    selectProjectandSection(data);
  };
  const selectProjectandSection = data => {
    if (data) {
      try {
        setSelectedProject({
          workspace: data.business,
          project: {
            ...data,
            value: data.name,
            title: data.title
          },
          section: {
            ...data.section[0],
            value: data.section[0].title,
            title: data.section[0].title
          }
        });
      } catch (e) {}
    }
  };
  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref}>
      <RoyTooltip
        placement={"top"}
        id="reminders-Modal"
        title={InjectPlaceHolderMessage(
          "today.reminder",
          props?.LanguageSwitcher?.language?.languageId
        )}
      >
        <img
          onClick={() => setIsRemainderDateCalendar(!isRemainderDateCalendar)}
          id="reminders-Modal"
          src={iconClock}
          height="15"
          width="15"
          alt="Reminder"
          style={{ position: "relative", top: 0 }}
        />
      </RoyTooltip>
    </div>
  ));
  const selectLabelHandler = label => {
    if (taskLabel && ids && ids.includes(label.id)) {
      var arr = [...taskLabel];
      arr &&
        arr.map((data, i) => {
          if (data.id == label.id) {
            arr.splice(i, 1);
          }
        });
      onChangeTaskLabel(arr);
    } else onChangeTaskLabel([...taskLabel, label]);
  };
  const toggleLabelSelection = () => {
    if (updateTask) {
      toggleAction();
    }
    setLabelSelection(!labelSelection);
  };

  const onSubmit = async (e, quickActionsPayload) => {
    try {
      if (e) {
        e.preventDefault();
      }
      let payload = {
        tempId: randomUUID(),
        title: taskInput.toString(),
        description: taskDescription ? taskDescription : null,
        label_ids: ids ? ids.toString() : "",
        reminderAt: taskReminder ? taskReminder : null,
        priority: taskPriority ? taskPriority : "",
        scheduledAt: taskDueDate ? taskDueDate : "",

        ...quickActionsPayload
      };
      // check if requested payload has user_id if not then push to payload for backend request
      if (payload && !payload.hasOwnProperty("user_id")) {
        const ids = taskLabel && taskLabel.map(a => a.id);
        let userArr = taskAssigned.hasOwnProperty("user")
          ? taskAssigned["user"]
          : [];
        let userIdArr = [];
        let user = [];
        if (userArr.length > 0) {
          await Promise.all(
            userArr.map(item => {
              if (item) {
                userIdArr.push(item.id);
              }
            })
          );
        }

        let userIds = userIdArr.join(",");
        payload = {
          ...payload,
          user_id: userIds,
          taskAssign: user,
          labels: taskLabel
        };
      }

      if (taskDueDate && taskDueDate != null && taskDueDate != "") {
        payload = {
          ...payload,
          scheduledAt: taskDueDate ? taskDueDate : ""
        };
      }
      if (
        quickActionsPayload &&
        quickActionsPayload !== "" &&
        quickActionsPayload !== null
      ) {
        // payload = { ...payload, ...quickActionsPayload };
      }

      if (isProjectSelection) {
        if (
          selectedProject &&
          selectedProject.workspace &&
          selectedProject.workspace.id &&
          selectedProject.project &&
          selectedProject.project.id &&
          selectedProject.section &&
          selectedProject.section.id
        ) {
          payload = {
            ...payload,
            section_id:
              selectedProject && selectedProject.section.id.toString(),
            project_id:
              (selectedProject && selectedProject.project.id.toString()) ||
              taskProject?.id.toString()
          };
          console.log("payloadnewtask", payload);

          addTaskHandler(payload, syncGoogle);
          isProjectSelection && toggle(false);
        } else {
          setIsWorkspaceError(true);
        }
      } else {
        if (updateTask && taskProject?.id) {
          payload = {
            ...payload,
            project_id: taskProject?.id.toString(),
            task_id: taskData?.id
          };
        }
        if (hasClearEnabledOnAddTask) {
          doHandleCleanupComponent();
        }
        addTaskHandler(payload, syncGoogle);
      }
    } catch (e) {
      console.log("e ---> ", e);
    }
  };
  return (
    <div
      onClick={e => {
        setIsNewTaskModleClick(true);
      }}
    >
      <form
        onSubmit={onSubmit}
        style={{
          backgroundColor: "white",
          borderRadius: "5px"
        }}
      >
        <div
          style={{
            // border: "1px solid #ddd",
            borderRadius: 5,
            padding: "10px 15px 0",
            cursor: "text",

            paddingBottom: 5
          }}
        >
          <div>
            <NewTaskInputWrapper>
              <NewTaskInput
                autoFocus={true}
                inputValue={taskInput}
                onChange={onChangeInput}
                onKeyDown={() => onKeyDown()}
                language={props?.LanguageSwitcher?.language?.languageId}
              />
            </NewTaskInputWrapper>
            <NewTaskDescriptionWrapper>
              <NewTaskDescription
                inputValue={taskDescription}
                onChange={onChangeDescription}
                isNewTaskModleClick={isNewTaskModleClick}
                setIsNewTaskModleClick={setIsNewTaskModleClick}
                language={props?.LanguageSwitcher?.language?.languageId}
              />
            </NewTaskDescriptionWrapper>

            {isProjectSelection && (
              <div className="">
                <div className="pt-15">
                  <WorkspaceNestedItem
                    isLoader={isLoaderWorkspace}
                    toggleAddProject={id => {
                      setDefaultWorkspaceId(id);
                      setProjectModal(!projectModal);
                    }}
                    isError={isWorkspaceError}
                    toggleError={() => setIsWorkspaceError(true)}
                    disbleError={() => setIsWorkspaceError(false)}
                    PRIMARY_COLOR={topbarTheme.buttonColor}
                    selectedProject={selectedProject}
                    setSelectedProject={handleWorskpaceProjectSelection}
                    workspaceData={workspaceData && workspaceData}
                  />
                </div>
              </div>
            )}
            <div
              className={`flex-x pt-15`}
              style={{
                borderBottom: "1px solid #e8e8e8",
                paddingBottom: 10,
                display: "flex",
                alignItems: "center"
              }}
            >
              <div className={"flex-1"}>
                <DatepickerWrapper {...props}>
                  <DatePicker
                    open={isScheduleDateCalendar}
                    onClickOutside={e =>
                      setIsScheduleDateCalendar(!isScheduleDateCalendar)
                    }
                    value={dueDateOnChangeSet}
                    selected={dueDateOnChangeSet}
                    onChange={onChangeDueDates}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="Time"
                    className="custom-datepicker pl-5"
                    calendarClassName="custom-calender-class"
                    placeholderText={"Schedule"}
                    // isClearable={true}

                    minDate={!AllowAddPreviousDateTask && new Date()}
                    customInput={<TaskDueDate />}
                    // calendarContainer={ScheduleDateCalendar}
                    popperPlacement="bottom-start"
                    // popperModifiers={{
                    //   flip: {
                    //     behavior: ["bottom-start"] // don't allow it to flip to be above
                    //   },
                    //   preventOverflow: {
                    //     enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    //   },
                    //   hide: {
                    //     enabled: false // turn off since needs preventOverflow to be enabled
                    //   }
                    // }}
                    popperProps={{
                      positionFixed: true // use this to make the popper position: fixed
                    }}
                    locale={
                      locale
                      // props.language.locale == "de" ? de : props.language.locale
                    }
                  >
                    <CalendarContainer
                    // className={className + " custom-calender-class-"}
                    // style={{ display: "grid !important" }}
                    >
                      <div></div>
                      {/* <div style={{ position: "relative" }} className={className}>
                             {children}
                         </div> */}
                      <div
                        style={{
                          width: "100%",
                          // display: "block",
                          // paddingLeft: 70,
                          // textDecoration: "underline",
                          // cursor: "pointer",
                          display: "flex",
                          // justifyContent: "center",
                          alignContent: "center",
                          // backgroundColor: "rgb(245 245 246)",
                          // textDecoration: "underline",
                          borderBottom: "1px solid #aeaeae",
                          borderRight: "1px solid #aeaeae",
                          borderLeft: "1px solid #aeaeae",
                          borderTop: "none !important",
                          borderBottomLeftRadius: "0.3rem",
                          borderBottomRightRadius: "0.3rem",
                          border: "0px solid",
                          fontSize: 12,
                          marginTop: "-7px",
                          backgroundColor: "white",
                          padding: "10px",
                          color: "black"
                        }}
                      >
                        <div style={{ paddingRight: 8 }}>
                          <div
                            onClick={() => {
                              updateDueDate("");
                              onChangeDueDates("");
                              setIsScheduleDateCalendar(
                                !isScheduleDateCalendar
                              );
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              fontSize: 14,
                              cursor: "pointer",
                              padding: 6,
                              borderRadius: 4,
                              border: "1px solid #E6E6E6"
                            }}
                          >
                            <IntlMessages id={"today.removedate"} />
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            updateDueDate(
                              dueDateOnChangeSet
                                ? dueDateOnChangeSet
                                : getRoundOfMinute()
                            );
                            setIsScheduleDateCalendar(!isScheduleDateCalendar);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            width: "30%",
                            fontSize: 14,
                            cursor: "pointer",
                            background: props.topbarTheme.buttonColor,
                            padding: 6,
                            borderRadius: 4,
                            color: "#FFF"
                          }}
                        >
                          <IntlMessages id={"globalsearchbar.applybutton"} />
                        </div>
                      </div>
                    </CalendarContainer>
                  </DatePicker>
                </DatepickerWrapper>
              </div>

              <div
                className="flex-x"
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <div style={{ display: "flex" }}>
                  <AssigneeDropdown
                    // btnPadding={8}
                    TargetId="assing-"
                    setDropdownOpen={() => {
                      if (isTaskAssignDropdown) {
                      }
                      setIsTaskAssignDropdown(!isTaskAssignDropdown);
                    }}
                    dropdownOpen={isTaskAssignDropdown}
                    currentLoggedIn={props.auth?.user?.id}
                    projectCollaborators={projectCollaborators}
                    userAssigned={taskAssigned}
                    setUserAssigned={data => {
                      onChangeTaskAssign(data);
                    }}
                    language={props?.LanguageSwitcher?.language?.languageId}
                  />
                </div>
                <RoyTooltip
                  placement={"top"}
                  id="Labels-Modal"
                  title={InjectPlaceHolderMessage(
                    "today.labels",
                    props?.LanguageSwitcher?.language?.languageId
                  )}
                >
                  <div
                    className="more_icon pa-5 pl-20"
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    {/* <i className="fa fa-tag pa-5" id="Labels" style={{ color: "gray" }} /> */}
                    <div
                      className="cursor-pointer"
                      style={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <img
                        id="Labels-Modal"
                        style={{
                          position: "relative",
                          top: 0
                        }}
                        src={iconTag}
                        height="18"
                        width="18"
                        className=" "
                        alt="Labels"
                        onClick={() => setLabelSelection(!labelSelection)}
                      />
                    </div>
                    <LabelsPopover
                      isOpen={labelSelection}
                      toggle={() => setLabelSelection(!labelSelection)}
                      target={"Labels-Modal"}
                      selectedLabels={ids}
                      selectLabelHandler={selectLabelHandler}
                      updateLabelToParent={data => {
                        if (updateLabelToParent) {
                          updateLabelToParent(data);
                        }
                      }}
                    />
                  </div>
                </RoyTooltip>

                <div className="pa-5  pl-15">
                  <RoyTooltip
                    placement={"top"}
                    id="priority-Modal"
                    title={InjectPlaceHolderMessage(
                      "today.priority",
                      props?.LanguageSwitcher?.language?.languageId
                    )}
                  >
                    <span id="priority-Modal" className="cursor-pointer">
                      <span>
                        <img
                          src={getPriorityPath(taskPriority)}
                          height="17"
                          width="18"
                          className=""
                          alt="Priority"
                          // style={{ cursor: "pointer",color: priority.color }}
                        />
                      </span>
                      <UncontrolledPopover
                        isOpen={prioritySelection}
                        trigger="legacy"
                        hideArrow
                        className="roy-menu"
                        innerClassName="roy-inner-content"
                        placement={"bottom-start"}
                        target="priority-Modal"
                        toggle={() => togglePrioritySelection()}
                      >
                        <PopoverBody
                          style={{
                            minWidth: "250px",
                            boxShadow: "0px 0px 15px #0000001A",
                            borderRadius: "10px",
                            paddingBottom: "10px"
                          }}
                        >
                          <div
                            className="float-right"
                            onClick={() => {
                              togglePrioritySelection();
                            }}
                          >
                            {/* <i className="fa fa-times text-muted" /> */}
                            <img
                              alt=""
                              src={NewTaskPriorityCross}
                              style={{
                                height: "10px",
                                width: "10px",
                                marginRight: "5px",
                                marginTop: "6px"
                              }}
                            ></img>
                          </div>
                          <div
                            className="fs-14 text-center "
                            style={{
                              padding: 4,
                              color: "#808080",
                              paddingBottom: 10,
                              borderBottom: "1px solid #EBEBEB",
                              borderRadius: "5px"
                            }}
                          >
                            <IntlMessages id={"globalsearchbar.priority"} />
                          </div>
                          {allPriority &&
                            allPriority.map((priority, i) => {
                              return (
                                <div
                                  className="roy-menu-list"
                                  key={priority.value}
                                  id="PUncontrolledPopover"
                                  onClick={() => {
                                    // selectPriorityHandler(priority.name);
                                    onChangeTaskPriority(priority.name);
                                    togglePrioritySelection();
                                  }}
                                >
                                  <div
                                    className="label-list"
                                    style={{
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={priority.path}
                                          height="20"
                                          width="16"
                                          className=""
                                          alt={priority.name}
                                          style={{
                                            cursor: "pointer",
                                            color: priority.color
                                          }}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          marginLeft: 10,
                                          color: "black",
                                          fontSize: 13
                                        }}
                                      >
                                        {/* {priority.value} */}
                                        {<IntlMessages id={priority.value} />}
                                      </div>
                                    </div>
                                    {priority.name == taskPriority && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center"
                                        }}
                                      >
                                        <IconTint
                                          maxHeight="15"
                                          maxWidth="15"
                                          src={singleCheckIcon}
                                          color={props.topbarTheme.buttonColor}
                                        />
                                        {/* <i
                                          className={classNames(
                                            "fas fa-check-double",
                                            "ml-15",
                                            `${priority.color}--text`
                                          )}
                                          style={{ color: priority.color }}
                                        ></i> */}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </PopoverBody>
                      </UncontrolledPopover>
                    </span>
                  </RoyTooltip>
                </div>
                {/* <i className="fa fa-clock pa-5" style={{ color: "gray" }} /> */}
                {taskDueDate && (
                  <div className="pa-5 pl-15 cursor-pointer">
                    <DatepickerWrapper {...props}>
                      <DatePicker
                        open={isRemainderDateCalendar}
                        popperPlacement="bottom-start"
                        popperProps={{
                          positionFixed: true // use this to make the popper position: fixed
                        }}
                        onClickOutside={e =>
                          setIsRemainderDateCalendar(!isRemainderDateCalendar)
                        }
                        value={remindersAtOnChangeSet || new Date()}
                        selected={remindersAtOnChangeSet}
                        onChange={onChangeRemindersAt}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        timeCaption="Time"
                        className="custom-datepicker"
                        disabledKeyboardNavigation
                        calendarClassName="custom-calender-class"
                        placeholderText={"Reminder at"}
                        // isClearable={true}
                        minDate={new Date()}
                        maxDate={taskDueDate}
                        customInput={<ExampleCustomInput />}
                        // calendarContainer={UpdateRemainderDateChanges}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true
                          }
                        }}
                        locale={
                          locale
                          // props.language.locale == "de"
                          //   ? de
                          //   : props.language.locale
                        }
                      >
                        <CalendarContainer
                        // className={className + " custom-calender-class-"}
                        // style={{ display: "grid !important" }}
                        >
                          <div></div>
                          {/* <div style={{ position: "relative" }} className={className}>
                             {children}
                             </div> */}
                          <div
                            // onClick={() => updateRemindersAt("")}
                            style={{
                              width: "100%",
                              // display: "block",
                              // paddingLeft: 70,
                              // textDecoration: "underline",
                              cursor: "alias",
                              display: "flex",
                              // justifyContent: "center",
                              alignContent: "center",
                              // backgroundColor: "rgb(245 245 246)",
                              // textDecoration: "underline",

                              borderBottom: "1px solid #aeaeae",
                              borderRight: "1px solid #aeaeae",
                              borderLeft: "1px solid #aeaeae",
                              borderTop: "none !important",
                              borderBottomLeftRadius: "0.3rem",
                              border: "0px solid",
                              background: "white",
                              borderBottomRightRadius: "0.3rem",
                              fontSize: 12,
                              marginTop: "-7px",
                              padding: "10px "
                            }}
                          >
                            <div
                              style={{
                                paddingRight: 8
                              }}
                            >
                              <div
                                onClick={() => {
                                  updateRemindersAt("");
                                  onChangeReminder("");
                                  setIsRemainderDateCalendar(false);
                                  setIsRemainderDateAtCalendar(false);
                                }}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  fontSize: 14,
                                  padding: 6,
                                  borderRadius: 4,
                                  cursor: "pointer",
                                  border: "1px solid #E6E6E6",
                                  color: "black"
                                }}
                              >
                                <IntlMessages id={"today.removedate"} />
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                updateRemindersAt(
                                  remindersAtOnChangeSet
                                    ? remindersAtOnChangeSet
                                    : getRoundOfMinute()
                                );
                                setIsRemainderDateCalendar(false);
                                setIsRemainderDateAtCalendar(false);
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                width: "30%",
                                fontSize: 14,
                                cursor: "pointer",
                                background: props.topbarTheme.buttonColor,
                                padding: 6,
                                borderRadius: 4,
                                color: "#FFF"
                              }}
                            >
                              <IntlMessages
                                id={"globalsearchbar.applybutton"}
                              />
                            </div>
                          </div>
                        </CalendarContainer>
                      </DatePicker>
                    </DatepickerWrapper>
                  </div>
                )}
              </div>
            </div>
            {taskDueDate && taskReminder && (
              <div style={{ paddingTop: 10 }}>
                <DatepickerWrapper {...props}>
                  <DatePicker
                    open={isRemainderDateAtCalendar}
                    onClickOutside={e =>
                      setIsRemainderDateAtCalendar(!isRemainderDateAtCalendar)
                    }
                    value={remindersAtOnChangeSet}
                    selected={remindersAtOnChangeSet}
                    onChange={onChangeRemindersAt}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="Time"
                    disabledKeyboardNavigation
                    className="custom-datepicker pl-5"
                    calendarClassName="custom-calender-class"
                    placeholderText={"Schedule"}
                    // isClearable={true}
                    minDate={new Date()}
                    customInput={<TaskReminder />}
                    // calendarContainer={UpdateRemainderDateChanges}
                    popperModifiers={{
                      preventOverflow: {
                        enabled: false
                      }
                    }}
                    locale={
                      locale
                      // props.language.locale == "de" ? de : props.language.locale
                    }
                  >
                    <CalendarContainer
                    // className={className + " custom-calender-class-"}
                    // style={{ display: "grid !important" }}
                    >
                      <div></div>
                      {/* <div style={{ position: "relative" }} className={className}>
                      {children}
                       </div> */}
                      <div
                        // onClick={() => updateRemindersAt("")}
                        style={{
                          width: "100%",
                          // display: "block",
                          // paddingLeft: 70,
                          // textDecoration: "underline",
                          // cursor: "pointer",
                          display: "flex",
                          // justifyContent: "center",
                          alignContent: "center",
                          // backgroundColor: "rgb(245 245 246)",
                          // textDecoration: "underline",
                          cursor: "alias",
                          borderBottom: "1px solid #aeaeae",
                          borderRight: "1px solid #aeaeae",
                          borderLeft: "1px solid #aeaeae",
                          borderTop: "none !important",
                          borderBottomLeftRadius: "0.3rem",
                          border: "0px solid",
                          background: "white",
                          borderBottomRightRadius: "0.3rem",
                          fontSize: 12,
                          marginTop: "-7px",
                          padding: "10px "
                        }}
                      >
                        <div style={{ paddingRight: 8 }}>
                          <div
                            onClick={() => {
                              updateRemindersAt("");
                              onChangeReminder("");
                              setIsRemainderDateCalendar(false);
                              setIsRemainderDateAtCalendar(false);
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              fontSize: 14,
                              padding: 6,
                              cursor: "pointer",
                              borderRadius: 4,
                              border: "1px solid #E6E6E6",
                              color: "black"
                            }}
                          >
                            <IntlMessages id={"today.removedate"} />
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            updateRemindersAt(
                              remindersAtOnChangeSet
                                ? remindersAtOnChangeSet
                                : getRoundOfMinute()
                            );
                            setIsRemainderDateCalendar(false);
                            setIsRemainderDateAtCalendar(false);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            width: "30%",
                            fontSize: 14,
                            cursor: "pointer",
                            background: props.topbarTheme.buttonColor,
                            padding: 6,
                            borderRadius: 4,
                            color: "#FFF"
                          }}
                        >
                          <IntlMessages id={"globalsearchbar.applybutton"} />
                        </div>
                      </div>
                    </CalendarContainer>
                  </DatePicker>
                </DatepickerWrapper>
              </div>
            )}
            <div>
              <div
                className=""
                style={{
                  marginLeft: "2px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {taskAssigned &&
                  taskAssigned.hasOwnProperty("user") &&
                  taskAssigned["user"].map(item => {
                    return (
                      <div className="react-form-input-new-task mt-10 ml-1">
                        {/* {[data].map(item => {
                          return ( */}
                        <RoyTooltip id="userAssigned" title={item.name}>
                          <div
                            onClick={() =>
                              setIsTaskAssignDropdown(!isTaskAssignDropdown)
                            }
                            style={{
                              cursor: "pointer"
                            }}
                          >
                            {/* <i className="fa fa-user pr-2 text-muted" /> */}
                            {item.userMeta && item.userMeta?.thumbnail ? (
                              <img
                                alt=""
                                id="userAssigned"
                                src={
                                  item.userMeta.thumbnail
                                    ? item.userMeta.thumbnail
                                    : assignnew
                                }
                                style={{
                                  height: 20,
                                  width: 20,
                                  marginRight: "5px",
                                  marginBottom: "4px",
                                  borderRadius: "50%"
                                }}
                              ></img>
                            ) : (
                              <div
                                id="userAssigned"
                                style={profileBackground(
                                  item.name ? item.name : item.email,
                                  22,
                                  22,
                                  14
                                )}
                              >
                                {item.name
                                  ? item.name.charAt(0).toUpperCase()
                                  : item.email.charAt(0).toUpperCase()}
                              </div>
                            )}
                            {/* {userAssigned.user.name} */}
                          </div>
                        </RoyTooltip>

                        {/* ) */}
                        {/* })} */}
                      </div>
                    );
                  })}
              </div>

              {taskLabel && taskLabel.length > 0 && (
                <div style={{}}>
                  <div style={{ marginTop: 10 }}>
                    <div
                      className="label-block-task"
                      style={{
                        maxWidth: "100%",
                        marginTop: 0
                      }}
                    >
                      <div
                        className="all-labels"
                        style={{
                          justifyContent: "left"
                        }}
                      >
                        {taskLabel &&
                          taskLabel
                            .sort(function(a, b) {
                              return a.name.length - b.name.length;
                            })
                            .map((e, i) => {
                              return (
                                <>
                                  <div
                                    key={i}
                                    title={e.name}
                                    // id="UncontrolledPopover"
                                    onClick={() => toggleLabelSelection()}
                                    className={classNames(
                                      "label",
                                      "demi-bold-text"
                                    )}
                                    style={{
                                      backgroundColor: getColorCode(e.color),
                                      cursor: "pointer",
                                      padding: 0,
                                      fontWeight: 500
                                    }}
                                  >
                                    {/* <i className="fa fa-tag fs-12 pr-1"/> */}
                                    {/* <img
                                      style={{ marginLeft: "3px" }}
                                      src={iconTagWhite}
                                      height="16"
                                      width="16"
                                      className=" "
                                      alt=""
                                    /> */}
                                    <span
                                      className=""
                                      style={{
                                        marginRight: "0px",
                                        padding: "0px 5px"
                                      }}
                                    >
                                      {e.name}
                                    </span>
                                  </div>
                                </>
                              );
                            })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          ref={newtaskEditorRef}
          style={{
            padding: 5,
            marginTop: 5,
            paddingLeft: 16,
            paddingTop: 0,
            paddingBottom: 10
          }}
        >
          <button
            type="submit"
            disabled={taskInput && taskInput?.length > 0 ? false : true}
            className="btn btn-sm"
            style={{
              backgroundColor: topbarTheme.buttonColor,
              border: "none",
              color: "#FFF",
              borderRadius: "4px",
              // width: "75px",
              cursor:
                taskInput && taskInput?.length > 0 ? "pointer" : "not-allowed"
            }}
            onClick={() => {
              addTaskHandler();
              setIsBoardCardscroll && setIsBoardCardscroll(false);
              if (updateTask ? updateTask : "") {
                updateTask
                  ? setIsGoogleCalenderData && setIsGoogleCalenderData(true)
                  : setIsGoogleCalenderData && setIsGoogleCalenderData(false);
              }
            }}
          >
            <span style={{ fontSize: 13 }}>
              {" "}
              {updateTask ? (
                <IntlMessages id={"today.updatetask"} />
              ) : (
                <IntlMessages id={"today.addtask"} />
              )}
            </span>
          </button>

          <button
            className="btn btn-sm"
            style={{
              background: "#F4F5F7",
              border: 0,
              color: "#000",
              borderRadius: "4px",
              marginLeft: "15px",
              fontSize: "20px",
              padding: "5px 15px"
              // width: "75px"
            }}
            onClick={() => toggle(false)}
          >
            <span style={{ fontSize: 13 }}>
              {" "}
              {<IntlMessages id={"project.cancel"} />}
            </span>
          </button>
        </div>
      </form>
      <AddProject
        modal={projectModal}
        setmodal={() => {
          setProjectModal(!projectModal);
        }}
        defaultWorkspaceId={defaultWorkspaceId}
        showNotifications={() => {}}
        history={route => props.history.push(route)}
        updateRedux={handlePostAddProject}
        UpdateSidebarProject={data => {
          updateSidebarProjectToSidebar(data);
        }}
        checkforunique={(datachild, toCheckValue) =>
          checkforunique(datachild, toCheckValue)
        }
        sidebarData={sidebarData}
      />
      <AddLabel
        modal={addLabelModal}
        sidebarData={sidebarData}
        setmodal={() => {
          addLabelModal && setLabelSelection(!labelSelection);
          setAddLabelModal(!addLabelModal);
        }}
        updateLabelToRedux={data => {
          props.update_Label(data);
        }}
      />
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher.language }
  };
};

export default connect(mapStateToProps, { update_Label, update_Project })(
  NewTask
);
