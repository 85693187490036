import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { getCollaboratorsList } from "helper/services/collboratorsServices";
import { Alert } from "reactstrap";
import { connect } from "react-redux";
import { ListGridCheckImage, ListGridCircleImage } from "helper/constant";
import {
  profileBackground,
  profileImage
} from "helper/methods/getUserAvatarColor";
import { ProjectCollaboratorsCross } from "helper/constant";
import BusinessListShimmer from "components/shimmer/BusinessListShimmer";
import IntlMessages from "util/intlMessages";
const ShareModal = ({
  isOpen,
  toggle,
  projectId,
  topbarTheme,
  collaboratorList,
  taskId,
  taskAssign,
  updateTaskAssign,
  dummyData,
  doUpdateTaskAssign,
  PRIMARY_COLOR
}) => {
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [collaboratorListArr, setCollaboratorListArr] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isCollabFetchingLoader, setIsCollabFetchingLoader] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const retrieveCollaboratsList = async projectId => {
    if (projectId) {
      try {
        setIsCollabFetchingLoader(true);
        const result = await getCollaboratorsList(projectId);
        if (result) {
          if (result.error) {
          }
          if (result.data) {
            setCollaboratorListArr([...result.data]);
            setSelectedIds(getIds(taskAssign));
          }
        }
        setIsCollabFetchingLoader(false);
      } catch (error) {
        setIsCollabFetchingLoader(false);
      }
    }
  };
  const changeSelection = data => {
    if (data) {
      if (isSelecteduser(data?.user?.id)) {
        const filteredIds =
          selectedIds && selectedIds.filter(a => a !== data?.user?.id);
        setSelectedIds(filteredIds);
      } else {
        setSelectedIds(
          selectedIds && selectedIds?.length
            ? [...selectedIds, data.user.id]
            : [data.user.id]
        );
      }
    }
  };
  const isSelecteduser = id => {
    if (id && selectedIds) {
      if (selectedIds?.includes(id)) {
        return true;
      }
      return false;
    }
    return false;
  };
  useEffect(() => {
    if (isOpen && projectId) {
      if (collaboratorList && collaboratorList?.length) {
        setSelectedIds(getIds(taskAssign));
        setCollaboratorListArr(collaboratorList);
      } else {
        retrieveCollaboratsList(projectId);
      }
    }
    return () => {
      setCollaboratorListArr([]);
    };
  }, [isOpen]);
  const getIds = data => (data && data.length > 0 ? data.map(a => a.id) : []);
  const handlePostRequestResponse = Response => {
    if (Response) {
      if (Response.status.toLowerCase() === "ok") {
        setIsLoader(false);
      } else {
      }
    }
  };
  const handleAssignTask = async user => {
    if (projectId && taskId) {
      const filteredData = [];
      collaboratorListArr &&
        collaboratorListArr.map(a => {
          if (a && a.user) {
            if (selectedIds?.includes(a.user.id)) {
              filteredData.push(a.user);
            }
          }
        });

      updateTaskAssign({ user: [...filteredData] }, handlePostRequestResponse);
      // try {
      //   setIsLoader(true);
      //   if (!dummyData) {
      //     const Response = await assignTaskToUser({
      //       project_id: projectId ? projectId.toString() : "",
      //       task_id: taskId ? taskId.toString() : "",
      //       user_id: user ? user.id.toString() : ""
      //     });
      //     if (Response) {
      //       if (Response.error) {
      //         setIsLoader(false);
      //       }
      //       if (Response.message) {
      //         setIsLoader(false);
      //         updateTaskAssign(user);
      //       }
      //     }
      //   } else {
      //     setIsLoader(false);
      //     updateTaskAssign("");
      //   }
      // } catch (e) {}
    }
  };
  const handleRemoveAssignTask = async (user, collabId) => {
    if (projectId && taskId && projectId && collabId) {
      updateTaskAssign("", () => {});
      // try {
      //   setIsLoader(true);
      //   if (!dummyData) {
      //     const Response = await removeAssignTask({
      //       project_id: projectId ? projectId.toString() : "",
      //       task_id: taskId ? taskId.toString() : "",
      //       collaborator_id: collabId ? collabId.toString() : ""
      //     });
      //     if (Response) {
      //       if (Response.error) {
      //         setIsLoader(false);
      //       }
      //       if (Response.message) {
      //         setIsLoader(false);
      //         updateTaskAssign(null, taskId);
      //       }
      //     }
      //   } else {
      //     setIsLoader(false);
      //     updateTaskAssign(null);
      //   }
      // } catch (e) {}
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} size="md" centered>
      <ModalHeader style={{ paddingLeft: 27 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: 17, color: "black", fontWeight: "600" }}>
            {" "}
            <IntlMessages id={"task-details.manage_assignee"} />
          </span>
          {isLoader && <BusinessListShimmer />}
          <div>
            <img
              alt=""
              src={ProjectCollaboratorsCross}
              style={{
                height: "18px",
                width: "15px",
                marginRight: "0px",
                marginTop: "0px",
                textAlign: "center",
                position: "absolute",
                top: 22,
                right: "27px",
                cursor: "pointer"
              }}
              onClick={() => toggle()}
            ></img>
          </div>
        </div>
      </ModalHeader>
      <Alert
        className="c-"
        style={{ backgroundColor: "RED ", color: "white" }}
        isOpen={visible}
        toggle={() => setVisible(false)}
      >
        {visible ? errorMessage : ""}
      </Alert>
      <ModalBody style={{ padding: "10px", paddingTop: "0px" }}>
        <div style={{ height: 280, overflow: "auto" }}>
          <div
            className="container list-container"
            style={{ overflow: "auto" }}
            /* key={i} */
          >
            {isCollabFetchingLoader ? (
              // <Loader />
              <BusinessListShimmer />
            ) : (
              <>
                {collaboratorListArr &&
                  collaboratorListArr.map((data, i) => {
                    if (data.user && data.requestStatus === "ACCEPTED") {
                      return (
                        <div
                          className=" ptb-5 ma-0 flex-x align-center "
                          key={i}
                          onClick={() => changeSelection(data)}
                          style={{ cursor: "pointer" }}
                        >
                          {/* change class name className="ptb-10 ma-0 flex-x align-center" */}
                          {data.user?.userMeta &&
                          data.user?.userMeta !== null ? (
                            <div>
                              <img
                                alt=""
                                src={data.user.userMeta.thumbnail}
                                style={profileImage}
                              />
                            </div>
                          ) : (
                            <div
                              style={profileBackground(
                                data.user && data.user.name
                                  ? data.user.name
                                  : data.user.email
                              )}
                            >
                              {data.user && data.user.name
                                ? data.user.name.charAt(0).toUpperCase()
                                : data.user.email.charAt(0).toUpperCase()}
                            </div>
                          )}
                          <div className="mlr-10 flex-1">
                            <div style={{ fontSize: "14px", color: "black" }}>
                              {/* change class name up fs-14 demi-bold-text */}
                              {data.user && data.user.name
                                ? data.user.name
                                : data.user.email.split("@")[0]}
                              {"     "}
                              <span>
                                {/* change class name up className="fs-12" */}
                                {data.role === "OWNER" ? (
                                  <span
                                    className="c-btn-round ma-5 fs-10"
                                    style={{
                                      padding: "2px 10px 4px 10px",
                                      backgroundColor: "#dedede"
                                    }}
                                  >
                                    <IntlMessages id={"projectdetails.owner"} />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                            <div style={{ fontSize: "14px", color: "#808080" }}>
                              {" "}
                              {data.user.email}
                            </div>
                          </div>
                          <div className="task-icons">
                            <span style={{ paddingLeft: 0 }}>
                              <div style={{ display: "inline-flex" }}>
                                <div
                                  style={{ cursor: "pointer" }}
                                  // onClick={() => changeSelection(data)}
                                >
                                  {isSelecteduser(data.user.id) ? (
                                    <img
                                      alt=""
                                      src={ListGridCheckImage}
                                      style={{
                                        height: 18,
                                        width: 18
                                      }}
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      src={ListGridCircleImage}
                                      style={{
                                        height: 18,
                                        width: 18
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      );
                    }
                  })}
              </>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {" "}
          <button
            style={{
              height: 42,
              backgroundColor: PRIMARY_COLOR,
              lineSpacing: "1px",
              borderRadius: "5px",
              fontSize: "14px",
              display: "flex",
              color: "white",
              alignItems: "center",
              justifyContent: "center",
              width: "50%",
              padding: "5px 10px",
              border: "0px solid ",
              margin: "10px 16px 5px 16px"
            }}
            onClick={() => handleAssignTask()}
            // onClick={() => doUpdateTaskAssign(data.user)}
          >
            <IntlMessages id={"globalsearchbar.applybutton"} />
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    PRIMARY_COLOR: state.themeChanger.topbarTheme.buttonColor
  };
};

export default connect(mapStateToProps, null)(ShareModal);
