import React from "react";
import { DefaultToast } from "react-toast-notifications";
import "./toast.style.css";
import { connect } from "react-redux";
import IconTint from "react-icon-tint";
import {
  checklistNotificationIcon,
  dangerNotificationIcon
} from "helper/constant";
import MyCustomTostWrapper from "./MyCustomTostWrapper.style";
const MyCustomToast = ({ children, ...props }) => (
  <MyCustomTostWrapper {...props}>
    <DefaultToast
      {...props}
      style={{
        backgroundColor: "white",
        border: "2px solid white",
        color:
          props.appearance != "error" ? props.topbarTheme.buttonColor : "red",
        borderRadius: "15px"
      }}
      autoDismiss={true}
      autoDismissTimeout={8000}
      appearance={props.appearance ? props.appearance : "error"}
    >
      <div
        // className="row"
        style={{
          display: "contents",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <div
            // className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12"
            style={{
              height: 34,
              width: 34,
              // height: "100%",
              // width:"100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:
                props.appearance != "error"
                  ? props.topbarTheme.buttonColor
                  : "rgba(235, 78, 45, 0.9)",
              borderRadius: "34%",
              marginRight: 10
              // padding:19,
              // boxShadow: "rgb(0 0 0 / 15%) 0px 0px 10px"
            }}
          >
            {/* <IconTint
                                  maxHeight="20"
                                  maxWidth="20"
                                  src={
                                    props.appearance != "error"
                                      ? checklistNotificationIcon
                                      : dangerNotificationIcon
                                  }
                                  style={{
                                    position: "relative",
                                    top: -1,
                                    height: "18",
                                    width: "18"
                                  }}
                                  color={props.topbarTheme.buttonColor}
                                /> */}
            <img
              src={
                props.appearance != "error"
                  ? checklistNotificationIcon
                  : dangerNotificationIcon
              }
              style={{
                height: "18px",
                width: "18px",
                // position: props.appearance != "error" ? "relative" : "relative",
                position: "relative",
                top: props.appearance != "error" ? "0px" : "-1px"
              }}
            ></img>
          </div>
        </div>
        <span
          // className="col-lg-9col-md-9 col-sm-12 col-xs-12 col-12"
          style={{
            // color: props.appearance != "error" ? "#333C48" : "#333C48",
            // color:props.appearance != "error"? props.topbarTheme.buttonColor:"#eb4e2de6",
            color: "black",
            fontSize: "14px",
            padding: "0px",
            position: "relative",
            top: "0px",
            fontWeight: "500"
          }}
        >
          {children}
        </span>
      </div>
    </DefaultToast>
  </MyCustomTostWrapper>
);
const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps)(MyCustomToast);
