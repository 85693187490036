export const getPlaceholderColor = letter => {
  if (letter) {
    let reqChar = parseInt(letter.toLowerCase().charCodeAt());
    if (reqChar >= 97 && reqChar <= 100) {
      return "transparent linear-gradient(211deg, #FFE4E4 0%, #005AA7 100%) 0% 0% no-repeat padding-box";
    }
    if (reqChar >= 101 && reqChar <= 104) {
      return " transparent linear-gradient(211deg, #A8FF78 0%, #005AA7 100%) 0% 0% no-repeat padding-box";
    }
    if (reqChar >= 105 && reqChar <= 108) {
      return "transparent linear-gradient(41deg, #E94057 0%, #8A2387 100%) 0% 0% no-repeat padding-box";
    }
    if (reqChar >= 109 && reqChar <= 112) {
      return "transparent linear-gradient(211deg, #009FFF 0%, #EC2F4B 100%) 0% 0% no-repeat padding-box";
    }
    if (reqChar >= 113 && reqChar <= 116) {
      return "transparent linear-gradient(220deg, #8360C3 0%, #2EBF91 100%) 0% 0% no-repeat padding-box";
    }
    if (reqChar >= 117 && reqChar <= 120) {
      return "transparent linear-gradient(211deg, #7F7FD5 0%, #91ACEA 100%) 0% 0% no-repeat padding-box";
    }
    if (reqChar >= 121 && reqChar <= 122) {
      return " transparent linear-gradient(211deg, #A8FF78 0%, #005AA7 100%) 0% 0% no-repeat padding-box";
    }
    return "transparent linear-gradient(211deg, #FFE4E4 0%, #005AA7 100%) 0% 0% no-repeat padding-box";
  }
};

export const getUserCharColor = letter => {
  if (letter) {
    let reqChar = parseInt(letter.toLowerCase().charCodeAt());
    if (reqChar >= 97 && reqChar <= 100) {
      return "#F86B5E";
    }
    if (reqChar >= 101 && reqChar <= 104) {
      return " #FFA500";
    }
    if (reqChar >= 105 && reqChar <= 108) {
      return "#008080";
    }
    if (reqChar >= 109 && reqChar <= 112) {
      return "#8346AF";
    }
    if (reqChar >= 113 && reqChar <= 116) {
      return "#CC34CC";
    }
    if (reqChar >= 117 && reqChar <= 120) {
      return "#41D9D9";
    }
    if (reqChar >= 121 && reqChar <= 122) {
      return " #005AA7";
    }
    return "#F86B5E";
  }
};
