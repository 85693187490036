import React, { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { iconTag, SidebarDownArrow, SidebarUpArrow } from "helper/constant";
import ShimmerLoader from "components/shimmer/BusinessListShimmer";
import { getUserCharColor } from "helper/methods/PlaceholderColor";
import { workspaceSymbolIcon } from "helper/constant";
import AddProjectWorkspaceWrapper from "./AddProjectWorkspaceWrapper.style";
import IntlMessages from "util/intlMessages";
const ColorPopover = ({
  options,
  selectedWorkspace,
  changeSelectedWorkspace,
  isLoading,
  borderError
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const onChangeSelectedWorkspace = data => {
    changeSelectedWorkspace(data);
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <AddProjectWorkspaceWrapper>
      <ButtonDropdown
        style={{
          width: "100%",
          border:
            selectedWorkspace == null && borderError == true
              ? "1px solid red"
              : "1px solid #E6E6E6",
          borderRadius: 5
        }}
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
      >
        <DropdownToggle
          // caret
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
            width: "100%",
            textTransform: "capitalize",
            border: selectedWorkspace ? "1px solid red" : "1px solid #E6E6E6",
            borderRadius: 5,
            textAlign: "left",
            fontSize: 14,
            color: "#495057",
            padding: 0,
            paddingLeft: 20,
            zIndex: 0,
            justifyContent: "space-between"
          }}
          className={"form-control"}
        >
          {isLoading ? (
            <IntlMessages id={"project.fetching"} />
          ) : (
            <>
              {selectedWorkspace && selectedWorkspace !== null ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    alt=""
                    className=""
                    src={workspaceSymbolIcon}
                    style={{ paddingRight: "8px" }}
                  />
                  {selectedWorkspace.label}
                </div>
              ) : (
                "select workspace"
              )}
            </>
          )}
          <img alt="" style={{ marginRight: 15 }} src={SidebarDownArrow}></img>
        </DropdownToggle>
        <DropdownMenu
          style={{
            padding: "5px 11px",
            height: 200,
            width: "100%",
            overflowX: "scroll",
            background: "#FFF"
          }}
        >
          {/* <div className="container" style={{width:"100%"}}> */}

          {isLoading ? (
            <ShimmerLoader />
          ) : (
            <>
              {options &&
                options?.length > 0 &&
                options.map((item, i) => {
                  return (
                    <>
                      <DropdownItem
                        style={{ padding: "5px 11px 5px 9px" }}
                        key={i * 1454}
                        onClick={() => onChangeSelectedWorkspace(item)}
                      >
                        <div className="flex-x roe-menu-list ptb-5">
                          <div style={{ width: "15%" }}>
                            {item?.businessMeta ? (
                              <img
                                className=" workspace-placeholder-search-popover"
                                alt=""
                                src={
                                  item?.businessMeta?.thumbnail
                                    ? item?.businessMeta?.thumbnail
                                    : null
                                }
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover"
                                }}
                              />
                            ) : (
                              <div className="workspace-Avatar">
                                <div
                                  className=" workspace-placeholder-search-popover"
                                  style={{
                                    // background: props.topbarTheme.buttonColor,
                                    background: getUserCharColor(
                                      item.label && item.label.charAt(0)
                                    ),
                                    cursor: "pointer",
                                    objectFit: "cover"
                                  }}
                                >
                                  {item?.label &&
                                    item.label.charAt(0).toUpperCase()}
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="text-left flex-x"
                            style={{ width: "85%", alignItems: "center" }}
                          >
                            <div>{item.label}</div>
                          </div>
                        </div>
                      </DropdownItem>
                    </>
                  );
                })}
              {!options?.length && (
                <div
                  style={{
                    padding: 40,
                    justifyContent: "center",
                    display: "flex"
                  }}
                >
                  no workspace
                </div>
              )}
            </>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    </AddProjectWorkspaceWrapper>
  );
};

export default ColorPopover;
