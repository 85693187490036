/** @format */

import styled from "styled-components";

const CommentEditorWrapper = styled.div`
  .quill {
    min-height: 15px !important;
    max-height: 110px !important;
    white-space: normal !important;
  }
  .ql-editor {
    min-height: 15px !important;
    max-height: 110px !important;
    padding: 0px 15px !important;
  }
  .ql-toolbar.ql-snow {
    border: none !important;
  }
  .ql-container.ql-snow {
    border: none !important;
  }
  .ql-editor ql-blank {
    min-height: 15px !important;
    max-height: 110px !important;
  }
`;

export default CommentEditorWrapper;
