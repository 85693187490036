import chroma from "chroma-js";
export const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: `''`,
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10
  }
});

export const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default"
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css()
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white"
    }
  })
};
// Colors.red,
// Colors.pink,
// Colors.purple,
// Colors.deepPurple,
// Colors.indigo,
// Colors.blue,
// Colors.lightBlue,
// Colors.cyan,
// Colors.teal,
// Colors.green,
// Colors.lightGreen,
// Colors.lime,
// Colors.yellow,
// Colors.amber,
// Colors.orange,
// Colors.deepOrange,
// Colors.brown,
// Colors.grey,
// Colors.blueGrey,
// Colors.black,
//  deepOrange, brown, grey, blueGrey,
export const ProjectColors = [
  // { value: "amber", label: "amber", color: "#FFBF00" },
  // { value: "grey", label: "grey", color: "#808080" },
  // { value: "blue", label: "blue", color: "#0000FF" },
  // { value: "pink", label: "pink", color: "#FFC0CB" },
  // { value: "purple", label: "purple", color: "#800080" },
  // { value: "deepPurple", label: "deepPurple", color: "#301934" },
  // { value: "indigo", label: "indigo", color: "#4B0082" },
  // { value: "lightBlue", label: "lightBlue", color: "#ADD8E6" },
  // { value: "cyan", label: "cyan", color: "#00FFFF" },
  // { value: "teal", label: "teal", color: "#008080" },
  // { value: "lightGreen", label: "lightGreen", color: "#90EE90" },
  // { value: "lime", label: "lime", color: "#BFFF00" },
  // { value: "yellow", label: "yellow", color: "#FFFF00" },

  // { value: "red", label: "red", color: "#FF0000" },
  // { value: "orange", label: "orange", color: "#FFA500" },
  // { value: "brown", label: "brown", color: "#A52A2A" },
  // { value: "blueGrey", label: "blueGrey", color: "#6699cc" },
  // { value: "black", label: "black", color: "#000000" }

  { value: "amber", label: "amber", color: "#FFBF00" },
  { value: "grey", label: "grey", color: "#808080" },
  { value: "blue", label: "blue", color: "#246FE0" },
  { value: "pink", label: "pink", color: "#FF9191" },
  { value: "purple", label: "purple", color: "#CC34CC" },
  { value: "deepPurple", label: "deep Purple", color: "#301934" },
  { value: "indigo", label: "indigo", color: "#8346AF" },
  { value: "lightBlue", label: "light Blue", color: "#ADD8E6" },
  { value: "cyan", label: "cyan", color: "#41D9D9" },
  { value: "teal", label: "teal", color: "#008080" },
  { value: "lightGreen", label: "light Green", color: "#77D877" },
  { value: "orange", label: "orange", color: "#FFA500" },
  { value: "brown", label: "brown", color: "#D1453B" },
  { value: "red", label: "red", color: "red" }
];

export const ProjectColorsGn = [
  // { value: "amber", label: "amber", color: "#FFBF00" },
  // { value: "grey", label: "grey", color: "#808080" },
  // { value: "blue", label: "blue", color: "#0000FF" },
  // { value: "pink", label: "pink", color: "#FFC0CB" },
  // { value: "purple", label: "purple", color: "#800080" },
  // { value: "deepPurple", label: "deepPurple", color: "#301934" },
  // { value: "indigo", label: "indigo", color: "#4B0082" },
  // { value: "lightBlue", label: "lightBlue", color: "#ADD8E6" },
  // { value: "cyan", label: "cyan", color: "#00FFFF" },
  // { value: "teal", label: "teal", color: "#008080" },
  // { value: "lightGreen", label: "lightGreen", color: "#90EE90" },
  // { value: "lime", label: "lime", color: "#BFFF00" },
  // { value: "yellow", label: "yellow", color: "#FFFF00" },

  // { value: "red", label: "red", color: "#FF0000" },
  // { value: "orange", label: "orange", color: "#FFA500" },
  // { value: "brown", label: "brown", color: "#A52A2A" },
  // { value: "blueGrey", label: "blueGrey", color: "#6699cc" },
  // { value: "black", label: "black", color: "#000000" }

  { value: "amber", label: "Bernstein", color: "#FFBF00" },
  { value: "grey", label: "grau", color: "#808080" },
  { value: "blue", label: "blau", color: "#246FE0" },
  { value: "pink", label: "rosa", color: "#FF9191" },
  { value: "purple", label: "lila", color: "#CC34CC" },
  { value: "deepPurple", label: "dunkellila", color: "#301934" },
  { value: "indigo", label: "indigo", color: "#8346AF" },
  { value: "lightBlue", label: "hellblau", color: "#ADD8E6" },
  { value: "cyan", label: "cyan", color: "#41D9D9" },
  { value: "teal", label: "blaugrün", color: "#008080" },
  { value: "lightGreen", label: "hellgrün", color: "#77D877" },
  { value: "orange", label: "orange", color: "#FFA500" },
  { value: "brown", label: "braun", color: "#D1453B" },
  { value: "red", label: "Rot", color: "red" }
];

export const ProjectColorsDut = [
  { value: "amber", label: "amber", color: "#FFBF00" },
  { value: "grey", label: "grijs", color: "#808080" },
  { value: "blue", label: "blauw", color: "#246FE0" },
  { value: "pink", label: "roze", color: "#FF9191" },
  { value: "purple", label: "paars", color: "#CC34CC" },
  { value: "deepPurple", label: "donker paars", color: "#301934" },
  { value: "indigo", label: "indigo", color: "#8346AF" },
  { value: "lightBlue", label: "lichtblauw", color: "#ADD8E6" },
  { value: "cyan", label: "cyaan", color: "#41D9D9" },
  { value: "teal", label: "wintertaling", color: "#008080" },
  { value: "lightGreen", label: "licht groen", color: "#77D877" },
  { value: "orange", label: "oranje", color: "#FFA500" },
  { value: "brown", label: "bruin", color: "#D1453B" },
  { value: "red", label: "rood", color: "red" }
];

export const getColorCode = colorname => {
  let colorcode = null;
  ProjectColorsGn.filter(data => {
    if (data.value === colorname || data.label === colorname) {
      colorcode = data.color;
      return data.color;
    }
  });
  return colorcode;
};
