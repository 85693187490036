import React from "react";
import { withFormik } from "formik";
import IntlMessages from "util/intlMessages";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    oldPassword: Yup.string()
      .required(<IntlMessages id={"profile.oldpassworderror"} />)
      .min(8, <IntlMessages id={"profile.oldpassworderror2"} />),
    newPassword: Yup.string()
      .required(<IntlMessages id={"profile.newpassworderror"} />)
      .min(8, <IntlMessages id={"profile.oldpassworderror2"} />),
    confirmPassword: Yup.string()
      .required(<IntlMessages id={"profile.confirmnewpassworderror"} />)
      .min(8, <IntlMessages id={"profile.oldpassworderror2"} />)
      .oneOf(
        [Yup.ref("newPassword"), null],

        <IntlMessages id={"profile.samepassworderror"} />
      )
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
