import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  inviteCollaborators,
  removeCollaborators
} from "helper/services/projectServices";
import chroma from "chroma-js";
import ShimmerLoader from "components/shimmer/BusinessListShimmer";
import { Alert } from "reactstrap";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import Select, { components } from "react-select";
import { getCollaboratorsList } from "helper/services/businessCollaboratorsServices";
import CustomAlert from "util/CustomAlert";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import { useToasts } from "react-toast-notifications";

import ProjectDropdown from "components/newTask/ProjectDropdown";
import { getBusinessProject } from "helper/services/businessServices";
import ToastMessage from "components/notifications/ToastMessage";
import IntlMessages from "util/intlMessages";
const InviteCollaborators = props => {
  const {
    modal,
    setmodal,
    topbarTheme,
    PRIMARY_COLOR,
    businessId,
    type,
    pushCollaborator
  } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoader, setIsLoader] = useState(false);

  const [isEditing, setEditing] = useState(false);

  const [partcipantsArr, setPartcipantsArr] = useState([]);
  const [projectCollaboratorList, setProjectCollaboratorList] = useState([]);
  const [inviteParticipants, setInviteParticipants] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const { addToast } = useToasts();
  const [workspaceData, setWorkspaceData] = useState([]);
  const [isLoaderWorkspace, setIsLoaderWorkspace] = useState(false);
  const [isDeleteAlert, setDeleteAlert] = useState({
    status: false,
    id: "",
    isLoader: false
  });
  const [dropdownInputValue, setDropdownInputValue] = useState("");
  const activeModal = ["multiProject", "singleProject"];
  const [modalType, setModalType] = useState(activeModal[0]);
  const [cheakEmail, setCheakEmail] = useState();
  const [value, setValue] = useState();
  const [cheakvalue, setCheakValue] = useState(false);
  const participantsInputRef = useRef(null);

  useEffect(() => {
    if (modal && isEditing) {
      participantsInputRef.current.focus();
    }
  });
  useEffect(() => {
    if (modal) {
      // fetchParticipants();
      if (type) {
        if (type == "singleProject") {
          setModalType(activeModal[1]);

          retriveBusiness();
        }
      } else {
        setModalType(activeModal[0]);
        retriveBusiness();
      }
    }
    return () => {
      setSelectedProject(null);
      setPartcipantsArr([]);
      setProjectCollaboratorList([]);
      setInviteParticipants([]);
      setProjectData([]);
      setWorkspaceData([]);
      setModalType(activeModal[0]);
    };
  }, [modal]);

  const colourStyles = {
    control: (base, state) => ({
      ...base,
      background: "#ffff",

      // match with the menu
      border: state.isDisabled
        ? null
        : state.isSelected
        ? "#909090"
        : state.isFocused
        ? "#f6f6f6"
        : null
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#909090"
          : isFocused
          ? "#f6f6f6"
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast("grey", "white") > 2
            ? "white"
            : "black"
          : "#808080",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6")
        }
      };
    },
    singleValue: styles => ({ ...styles, color: "black" })
  };

  const profileImage = {
    borderRadius: "50%",
    border: "1 solid",
    width: 35,
    height: 35,
    objectFit: "cover",
    textAlign: "center"
  };

  const InCap = {
    lineHeight: 1,
    fontSize: 16,
    color: "white",
    textAlign: "center"
  };
  //
  const retriveBusiness = async () => {
    try {
      setIsLoaderWorkspace(true);
      const Response = await getBusinessProject();
      if (Response) {
        if (Response.data) {
          var businessArr = [];
          Response.data && Response.data.map(a => businessArr.push(a.business));
          await setWorkspaceData([...businessArr]);
          await setProjectData([...businessArr]);
          if (type == "singleProject") {
            businessArr &&
              businessArr.map(data => {
                data &&
                  data.project.map(projectData => {
                    if (projectData.id === props.project.id) {
                      handleChangeProject(
                        {
                          workspace: data,
                          project: projectData
                        },
                        businessArr
                      );
                    }
                  });
              });
          }
        }
        if (Response.error) {
          addToast(ToastMessage(Response.error.message), {
            appearance: "error",
            autoDismiss: true
          });

          setIsLoaderWorkspace(false);
        }
        setIsLoaderWorkspace(false);
      }
    } catch (e) {
      setIsLoaderWorkspace(false);
    }
  };
  const removeCollaboratorById = id => {
    var projects = [...projectCollaboratorList];
    var removeUser =
      projects && projects.filter(collaborator => collaborator.id !== id);
    var pushUserToDropdown =
      projects && projects.filter(collaborator => collaborator.id == id);

    setProjectCollaboratorList([...removeUser]);
    if (pushUserToDropdown?.length && pushUserToDropdown?.length > 0) {
      setPartcipantsArr([
        ...partcipantsArr,
        {
          ...pushUserToDropdown[0],
          label: `${pushUserToDropdown[0].user.name} (${pushUserToDropdown[0].user.email})`,
          chipLabel: `${pushUserToDropdown[0].user.email} `,
          value: pushUserToDropdown[0].user.email
        }
      ]);
    }
  };
  const fetchCollaborators = async (selectedData, businessArr) => {
    workspaceData &&
      workspaceData.map(async workspace => {
        if (workspace.id == selectedData.workspace.id) {
          await Promise.all(
            workspace &&
              workspace.project.map((project, i) => {
                if (project.id == selectedData.project.id) {
                  setProjectCollaboratorList([...project.collaborator]);
                  handleParticipants(
                    [...workspace.collaborator],
                    [...project.collaborator]
                  );
                }
              })
          );
        }
      });
    businessArr &&
      businessArr.map(async workspace => {
        if (workspace.id == selectedData.workspace.id) {
          await Promise.all(
            workspace &&
              workspace.project.map((project, i) => {
                if (project.id == selectedData.project.id) {
                  setProjectCollaboratorList([...project.collaborator]);
                  handleParticipants(
                    [...workspace.collaborator],
                    [...project.collaborator]
                  );
                }
              })
          );
        }
      });
  };
  const handleChangeProject = (data, businessArr) => {
    setSelectedProject(data);
    setPartcipantsArr([]);
    setInviteParticipants([]);
    fetchCollaborators(data, businessArr);
  };

  const checkEmailUnique = emailid => {
    //
    //
    var flag = true;
    projectCollaboratorList &&
      projectCollaboratorList.map(data => {
        if (data.user && data.user.email == emailid) {
          flag = false;
        }
      });
    //
    return flag;
  };

  // this function check InviteParticipantst  array to chek alrady in que or not
  const isEmailAlreadyInviteParticipantst = emailid => {
    const participant = inviteParticipants;
    var isExist = false;

    participant &&
      participant.map(data => {
        let str = data.chipLabel;
        str = str.slice(0, -1);

        if (str == emailid) {
          isExist = true;
          setCheakValue(true);
        }
      });

    return isExist;
  };

  const isEmailAlreadyParticipant = emailid => {
    const participant = inviteParticipants;
    var isExist = false;

    participant &&
      participant.map(data => {
        if (data.chipLabel && emailid == data.chipLabel) {
          isExist = true;
          setCheakValue(true);
        }
      });

    return isExist;
  };

  const checkEmailValidations = email =>
    email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  // const toggleEditing = (e) => {
  //   // e.preventDefault();
  //   setEditing(!isEditing);
  // };
  const handleKeyDown = e => {
    if (cheakvalue == true) {
      if (e.keyCode == 32) {
        handleSendInvite(cheakEmail);
      } else if (e.keyCode == 188) {
        handleSendInvite(cheakEmail);
      } else if (e.keyCode == 13) {
        handleSendInvite(cheakEmail);
      }
      if (e.keyCode == 9) {
        handleSendInvite(cheakEmail);

        // setEditing(!isEditing);
        // participantsInputRef.current.focus();
        // toggleEditing()
      }
    }
  };

  const handleSendInvite = async emailid => {
    if (emailid && checkEmailValidations(emailid)) {
      const newChip = {
        chipLabel: emailid,
        label: emailid,
        value: emailid
      };

      //
      // setInviteParticipants([...inviteParticipants, newChip]);
      //
      let spaceremove = dropdownInputValue.substr(0, 1);
      if (spaceremove == " " || spaceremove == ",") {
        setDropdownInputValue("");
      }
      const start = 0;
      let end = dropdownInputValue.length;
      let cheakvalue = dropdownInputValue.substr(start, end);
      //
      //
      if (newChip.chipLabel == cheakvalue) {
        return (
          setDropdownInputValue(""),
          setInviteParticipants([...inviteParticipants, newChip])
        );
      } else {
        return "";
      }
    }
    //   setIsInvitationLoader(true);
    //   try {
    //     const Response = await inviteCollaborators({
    //       project_id: selectedProject && selectedProject?.project.id,
    //       recipient_email: [emailid]

    //     });
    //     if (Response) {
    //       if (Response.error) {
    //         addToast(ToastMessage(Response.error.message), {
    //           appearance: "error",
    //           autoDismiss: true
    //         });
    //         setIsInvitationLoader(false);
    //       }

    //       if (Response.data) {

    //         setInviteParticipants([]);
    //         retriveBusiness();
    //         addToast(ToastMessage(Response.message), {
    //           appearance: "success",
    //           autoDismiss: true
    //         });
    //         setIsInvitationLoader(false);
    //         setmodal(!modal);
    //       }
    //     }
    //   } catch (e) {}
  };
  const pushNewCollabtoProject = (newCollab, workspacId, projectId) => {
    let workspaceArr = [...workspaceData];

    if (workspaceData) {
      workspaceData.map((workspace, workspaceIndex) => {
        if (workspace?.id == workspacId) {
          if (workspace && workspace?.project?.length > 0) {
            return workspace?.project?.map((project, projectIndex) => {
              if (project.id == projectId) {
                workspaceArr[workspaceIndex].project[
                  projectIndex
                ].collaborator = [
                  ...workspaceArr[workspaceIndex].project[projectIndex]
                    .collaborator,
                  [...newCollab]
                ];
              }
            });
          }
        }
      });
    }
    setWorkspaceData(workspaceArr);

    setProjectCollaboratorList([...projectCollaboratorList, ...newCollab]);
    //
  };

  const handleCharactersValidation = emailid => {
    //
    let givenEmail = emailid;
    if (removeComma(givenEmail)) {
      givenEmail = removeComma(emailid);
    }
    givenEmail = removeSpace(givenEmail);
    return givenEmail;
  };
  const removeSpace = emailid => {
    return emailid.replace(/\s/g, "");
  };
  const removeComma = emailid => {
    if (emailid.includes(",")) {
      const checkIndex = emailid.indexOf(",");
      if (checkIndex > -1) {
        let givenEmail = emailid;
        givenEmail = emailid.slice(checkIndex + 1, emailid.length);
        return givenEmail;
      }
      return 0;
    }
  };

  const handleNoOptionsMessage = emailid => {
    if (emailid && emailid.length > 0) {
      let givenEmail = handleCharactersValidation(emailid).toLowerCase();
      if (checkEmailValidations(givenEmail)) {
        // setCheakEmail(givenEmail)
        return (
          <div>
            {!checkEmailUnique(givenEmail)
              ? (setCheakValue(false),
                (
                  <div className=" ptb-1 ">
                    <p style={{ color: "red", fontSize: 14, fontWeight: 400 }}>
                      {givenEmail} already a collaborator
                    </p>
                  </div>
                ))
              : isEmailAlreadyParticipant(givenEmail) ||
                isEmailAlreadyInviteParticipantst(givenEmail)
              ? (setCheakValue(false),
                (
                  <div className=" ptb-1 ">
                    <p style={{ color: "red", fontSize: 14, fontWeight: 400 }}>
                      Email is already present
                    </p>
                  </div>
                ))
              : (setCheakValue(true),
                setCheakEmail(givenEmail),
                (
                  <div className=" ptb-1 " style={{ fontSize: 14 }}>
                    <p>
                      Add multiple emails by pressing comma, space or enter.
                    </p>
                  </div>
                ))}
            {/* {isEmailAlreadyParticipant(emailid)  ? 
            (<div className=" ptb-10 border-bottom">
              <p>
                Email is already present
              </p>
            </div>) : (
              <div className=" ptb-10 border-bottom">
                <p>
                  press “send” button below and we'll send email to this {emailid}{" "}
                 email for an invitation.
               </p>
              </div>
            )
            } */}
            {/* {isInvitationLoader ? (
              <div style={{ marginTop: 10, marginBottom: 10, float: "left" }}>
                <Loader height="20px" width="20px" />
              </div>
            ) : cheakvalue == true ? (
              <>
                <div style={{ marginTop: 10, marginBottom: 48 }}>
                  <button
                    className="btn button"
                    type="button"
                    onClick={() => {
                      handleSendInvite(emailid);
                    }}
                    style={{
                      backgroundColor: PRIMARY_COLOR
                        ? PRIMARY_COLOR
                        : "#ff6b6b",
                      color: "#FFF",
                      float: "left"
                    }}
                  >
                    Add New collaborator
                  </button>
                </div>
              </>
            ) : (
              <>
                <div style={{ marginTop: 10, marginBottom: 48 }}>
                  <button
                    className="btn button"
                    type="button"
                    // onClick={() => handleSendInvite(emailid)}
                    style={{
                      backgroundColor: "grey",
                      color: "#FFF",
                      float: "left",
                      cursor: "not-allowed"
                    }}
                  >
                    Add New collaborator
                  </button>
                </div>
              </>
            )} */}
          </div>
        );
      } else {
        let spaceremove = dropdownInputValue.substr(0, 1);
        if (spaceremove == " " || spaceremove == ",") {
          setDropdownInputValue("");
        }
        return (
          <>
            <div
              className=" ptb-1 "
              style={{ justifyContent: "center", fontSize: 14 }}
            >
              <p>
                <IntlMessages id={"project.projectcollabratorplaceholder1"} />{" "}
              </p>
              <p>
                <IntlMessages id={"project.projectcollabratorplaceholder2"} />
              </p>
            </div>
            {/* <div style={{ marginTop: 10, marginBottom: 48 }}>
              <button
                className="btn button"
                type="button"
                // onClick={() => handleSendInvite(emailid)}
                style={{
                  backgroundColor: "grey",
                  color: "#FFF",
                  float: "left",
                  cursor: "not-allowed"
                }}
              >
                Add New collaborator
              </button>
            </div> */}
          </>
        );
      }
    } else {
      return (
        <>
          <div
            className=" ptb-1 "
            style={{ justifyContent: "center", fontSize: 14 }}
          >
            <p>
              <IntlMessages id={"projectdetails.nocollaborator"} />
            </p>
          </div>
          {/* <div style={{ marginTop: 10, marginBottom: 48 }}>
            <button
              className="btn button"
              type="button"
              // onClick={() => handleSendInvite(emailid)}
              style={{
                backgroundColor: "grey",
                color: "#FFF",
                float: "left",
                cursor: "not-allowed"
              }}
            >
              Add New collaborator
            </button>
          </div> */}
        </>
      );
    }
  };
  const fetchParticipants = async e => {
    // setIsParticipantsLoader(true);
    const result = await getCollaboratorsList(businessId);
    if (result) {
      if (result.error) {
        // showNotifications(result.error.message);
      }
      if (result.data) {
        //  setCollaboratorArr(result.data);

        handleParticipants(result.data);
      }
      //  setIsParticipantsLoader(false);
    }
  };

  const MultiValue = props => (
    <components.MultiValue {...props}>
      {props.data.chipLabel}
    </components.MultiValue>
  );
  const handleRemoveParticipant = async id => {
    if (id) {
      try {
        setDeleteAlert({ ...isDeleteAlert, isLoader: true });
        const response = await removeCollaborators({
          collaboration_id: id
        });
        if (response) {
          if (response.error) {
            addToast(ToastMessage(Response.error.message), {
              appearance: "error",
              autoDismiss: true
            });
          }
          if (response.message) {
            retriveBusiness();
            addToast(ToastMessage(Response.message), {
              appearance: "success",
              autoDismiss: true
            });
            removeCollaboratorById(id);
            setDeleteAlert({ status: false, id: "", isLoader: false });
          }
        }
      } catch (e) {
        setDeleteAlert({ status: false, id: "", isLoader: false });
      }
    }
  };
  const handleParticipants = async (workspaceData, projectCollaboratorList) => {
    // handle data for to check request are accepted and are collaborator
    // also if they are part of project than dont display
    var updatedData = [];

    await Promise.all(
      workspaceData.map(data => {
        if (data?.requestStatus?.toLowerCase() == "accepted") {
          updatedData.push({
            ...data,
            label: `${data.user.name} (${data.user.email})`,
            chipLabel: `${data.user.email} `,
            value: data.user.email
          });
        }
      })
    );

    let finalDatarr = [];
    projectCollaboratorList &&
      (await Promise.all(
        updatedData.map(async item => {
          if (item) {
            let arr1 = await Promise.all(
              projectCollaboratorList.filter(
                item2 => item2 && item2?.user && item.user.id == item2.user.id
              )
            );
            if (arr1.length == 0) {
              finalDatarr.push(item);
            }
          }
        })
      ));
    // projectCollaboratorList.sort((a, b) => a.id - b.id);
    // change up data sorting
    setPartcipantsArr(finalDatarr);
    // setPartcipantsArr(updatedData);
  };
  const getEmailArray = collab => {
    var emailArray = [];
    collab.map(data => {
      if (data.user?.email) {
        emailArray.push(data.user.email.toString());
      } else {
        emailArray.push(data.value.toString());
      }
    });
    return emailArray;
  };
  const handleProjectAddCollaborators = async e => {
    e.preventDefault();
    setVisible(false);
    if (inviteParticipants && inviteParticipants.length > 0) {
      setIsLoader(true);
      try {
        if (inviteParticipants) {
          const Response = await inviteCollaborators({
            project_id: selectedProject && selectedProject?.project?.id,
            recipient_email: getEmailArray(inviteParticipants),
            business_id: selectedProject?.workspace?.id
          });
          if (Response) {
            setIsLoader(false);
            if (Response.error) {
              addToast(ToastMessage(Response.error.message), {
                appearance: "error",
                autoDismiss: true
              });
              setVisible(true);
              setErrorMessage(Response.error.message);
            }
            if (Response.errors) {
              addToast(ToastMessage(Response.error.message), {
                appearance: "error",
                autoDismiss: true
              });
              setVisible(true);
              setErrorMessage(Response.errors.message);
            }
            if (Response.message) {
              setVisible(false);
              if (Response.data) {
                if (type == "singleProject") {
                  setmodal(!modal);
                }
                pushNewCollabtoProject(
                  Response.data,
                  selectedProject?.workspace?.id,
                  selectedProject?.project?.id
                );
                removeNewAddedCollabFromDropdownArr(inviteParticipants);

                addToast(ToastMessage(Response.message), {
                  appearance: "success",
                  autoDismiss: true
                });
                setInviteParticipants([]);
                retriveBusiness();
                setInviteParticipants([]);
                pushCollaborator(Response.data);
              }
            }
          }
        } else {
          setIsLoader(false);
          setVisible(true);
          setErrorMessage(
            "Please Enter Valid Email id email must be email@domain.com"
          );
        }
      } catch (e) {
        setIsLoader(false);
      }
    }
  };
  const removeNewAddedCollabFromDropdownArr = inviteParticipants => {
    //
    let participantsDataArr = [...partcipantsArr];
    participantsDataArr &&
      participantsDataArr.map(participant => {
        inviteParticipants &&
          inviteParticipants.map((inviteParticipant, i) => {
            if (inviteParticipant && inviteParticipant?.id == participant?.id) {
              participantsDataArr.splice(i, 1);
            }
          });
      });
    setPartcipantsArr(participantsDataArr);
  };
  return (
    <Modal isOpen={modal} toggle={() => setmodal(!modal)} size="md" centered>
      <form onSubmit={handleProjectAddCollaborators}>
        <ModalHeader>
          <span style={{ fontSize: "18px", fontWeight: "600" }}>
            <IntlMessages id={"globalquickaddactions.addyourteamtoproject"} />
          </span>
        </ModalHeader>
        <Alert
          className="c-"
          style={{ backgroundColor: "RED ", color: "white" }}
          isOpen={visible}
          // toggle={() => setVisible(false)}
        >
          {visible ? errorMessage : ""}
        </Alert>
        <ModalBody>
          {activeModal[0] == modalType && (
            <div className="form-group">
              {/* Choose Project */}
              <span style={{ fontSize: 14 }}>
                <IntlMessages id={"globalquickaddactions.selectproject"} />
              </span>
              <div className="form-group">
                <ProjectDropdown
                  isLoader={isLoaderWorkspace}
                  selectedProject={selectedProject}
                  setSelectedProject={handleChangeProject}
                  projectData={projectData && projectData}
                  dropdownWidth={"100%"}
                />
              </div>
            </div>
          )}

          {/* <div className="form-group mb-0">Add Member</div> */}
          <span style={{ fontSize: 14 }}>
            <div className="form-group mb-0">
              <IntlMessages id={"projectdetails.add_collaborator"} />
            </div>
          </span>
          <div className="form-group">
            {
              <Select
                styles={colourStyles}
                // onBlur={e => {
                //   participantsInputRef.current.focus();
                // }}
                // autoFocus={true}
                isMulti
                ref={participantsInputRef}
                closeMenuOnSelect={false}
                placeholder={
                  <IntlMessages id={"projectdetails.add_collaborator"} />
                }
                id="participants"
                name="participants"
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#989797"
                  }
                })}
                options={partcipantsArr}
                onChange={e => {
                  setInviteParticipants(e);
                  if (inviteParticipants?.length > 0) {
                    setIsDisabled(false);
                  } else {
                    setIsDisabled(true);
                  }
                }}
                inputValue={dropdownInputValue}
                value={inviteParticipants}
                className="basic-multi-select"
                classNamePrefix="select"
                onInputChange={e => {
                  setDropdownInputValue(e);
                  setValue(e.inputValue);
                }}
                noOptionsMessage={e => handleNoOptionsMessage(e.inputValue)}
                onKeyDown={handleKeyDown}
                components={{ MultiValue }}
              />
            }
          </div>
          <div style={{ height: 200, overflow: "auto" }}>
            <div
              className="container list-container"
              /* key={i} */
            >
              {isLoaderWorkspace ? (
                <ShimmerLoader />
              ) : (
                projectCollaboratorList &&
                projectCollaboratorList.map((data, i) => {
                  if (data.user && data.requestStatus === "ACCEPTED") {
                    return (
                      <div className="ptb-10 ma-0 flex-x align-center" key={i}>
                        {data.user?.userMeta && data.user?.userMeta !== null ? (
                          <div>
                            <img
                              src={data.user.userMeta.thumbnail}
                              style={profileImage}
                              alt=""
                            />
                          </div>
                        ) : (
                          <div
                            style={profileBackground(
                              data.user ? data.user.name : data.user.email
                            )}
                          >
                            <span style={InCap}>
                              {data.user && data.user.name
                                ? data.user.name.charAt(0).toUpperCase()
                                : data.user.email.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        )}
                        <div className="mlr-10 flex-1">
                          <p
                            className=" fs-14 demi-bold-text"
                            style={{ marginBottom: "-3px" }}
                          >
                            {data.user && data.user.name
                              ? data.user.name
                              : data.user.email.split("@")[0]}{" "}
                            {data.role.toLowerCase() == "owner" && (
                              <span
                                style={{
                                  borderRadius: "15",
                                  color: "white",
                                  padding: "2px 10px",
                                  fontSize: "10px",
                                  textAlign: "center",
                                  justifyContent: "center",
                                  backgroundColor: PRIMARY_COLOR
                                    ? PRIMARY_COLOR
                                    : "black"
                                }}
                                className="c-btn-round ma-5 "
                              >
                                <IntlMessages id={"projectdetails.owner"} />
                              </span>
                            )}
                          </p>
                          <small className="text-muted">
                            {data.user.email}
                          </small>
                        </div>

                        <div
                          className="task-icons"
                          onClick={() =>
                            setDeleteAlert({
                              status: true,
                              id: data.id,
                              isLoader: true
                            })
                          }
                        >
                          {data.role.toLowerCase() == "collaborator" && (
                            <>
                              {isDeleteAlert &&
                              isDeleteAlert.id === data.id &&
                              isDeleteAlert.isLoader ? (
                                <Loader height="18px" width="17px" />
                              ) : (
                                <i className="fas fa-trash text-muted fs-12"></i>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  }
                })
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="button btn"
            onClick={() => setmodal(!modal)}
            // style={{ background: "#909090", border: 0 }}
            style={{
              background: "transparent",
              border: 0,
              color: "#000",
              padding: "12px"
            }}
          >
            {/* Cancel */}
            <span style={{ fontSize: 14 }}>
              <IntlMessages id={"globalquickaddactions.cancelbutton"} />
            </span>
          </Button>
          {isLoader ? (
            <Loader height="24px" width="20px" />
          ) : (
            <Button
              type="submit"
              className="button btn"
              // style={{
              //   backgroundColor: isDisabled ? "gray" : topbarTheme.buttonColor,
              //   border: "none",
              //   backgroundColor: PRIMARY_COLOR ? PRIMARY_COLOR : "black"
              // }}
              style={{
                cursor: inviteParticipants?.length > 0 ? "" : "not-allowed",
                border: "none",
                backgroundColor:
                  inviteParticipants?.length > 0
                    ? topbarTheme.buttonColor
                    : "gray"
              }}
              disabled={inviteParticipants?.length > 0 ? false : true}
            >
              {/* Add */}
              <span style={{ fontSize: 14 }}>
                <IntlMessages id={"globalquickaddactions.addbutton"} />
              </span>
            </Button>
          )}
        </ModalFooter>
      </form>
      <CustomAlert
        action="remove"
        messageType="collaborator"
        show={isDeleteAlert.status}
        onConfirm={() => {
          if (isDeleteAlert.id) {
            handleRemoveParticipant(isDeleteAlert.id);
          }
        }}
        PRIMARY_COLOR={topbarTheme.buttonColor}
        onCancel={() => {
          setDeleteAlert({ id: "", status: false, isLoader: false });
        }}
      />
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, null)(InviteCollaborators);
