import React, { useState } from "react";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";
import { updateProject } from "helper/services/projectServices";
import {
  search,
  TaskDialogCrossImage,
  threeDot_Icon,
  CardTaskCalenderImage
} from "helper/constant";

import moment from "moment";
import { archive, addProjectTabImage } from "helper/constant";
import { connect } from "react-redux";
import ProjectTabWrapper from "./ProjectTabWrapper.style";
import { getColorCode } from "components/projects/utils/color.jsx";
import { Popover, PopoverBody } from "reactstrap";
import CardShimmer from "components/shimmer/CardShimmer";
import ProjectCard from "./ProjectCard";
import ProjectCardWrapper from "./ProjectCardWrapper.style";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
const ProjectTab = props => {
  const {
    projectArr,
    toggleAddProject,
    archivedProjectArr,
    pushTounArchivedProject,
    isLoader,
    pushToArchivedProject,
    showNotifications,
    handleGoTOProject,
    updateProjectToRedux,
    topbarTheme,
    hideSearchBar
  } = props;
  const [isSearch, setIsSearch] = useState(false);
  const [optionMenu, setOptionMenu] = useState({ status: false, id: null });
  const [isArchivedOpen, setIsArchivedOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryProjectArr, setSearchQueryProjectArr] = useState([]);
  // useEffect(() => {

  // },projectArr,archivedProjectArr)
  const handleSearch = query => {
    setSearchQuery(query);
    let filteredProjects = [];
    if (!query.replace(/\s/g, "").length) {
      setIsSearch(false);
    } else {
      filteredProjects = projectArr.filter(project => {
        return project.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
      setSearchQueryProjectArr(filteredProjects);
      setIsSearch(true);
    }
  };
  const handleArchive = async (archive, id) => {
    try {
      const Response = await updateProject(
        {
          isArchived: archive
        },
        id
      );
      if (Response) {
        if (Response.error) {
          showNotifications();
        }
        if (Response.data) {
          setOptionMenu({ status: false, id: null });
          updateProjectToRedux(Response?.data);

          if (Response?.data?.isArchived) {
            pushToArchivedProject(Response?.data);

            showNotifications(
              <IntlMessages id={"workspace.project_archived"} />,
              "success"
            );
          } else {
            pushTounArchivedProject(Response.data);
            showNotifications(
              <IntlMessages id={"workspace.project_unarchived"} />,
              "success"
            );
          }
        }
      }
    } catch (e) {
      showNotifications();
    }
  };

  const getOverDueProjectDate = endDate => {
    if (endDate) {
      const checkDate = moment().diff(new Date(endDate), "days");
      if (checkDate > 0) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              alt=""
              src={CardTaskCalenderImage}
              style={{ marginRight: 5, height: 12, width: 12 }}
            ></img>
            <div className="text-overdue">{`-${checkDate} days overdue`}</div>
          </div>
        );
      }
      if (checkDate < 0) {
        return "";
        // return (
        //   <div className="text-remaining">{`${Math.abs(
        //     checkDate
        //   )} days remaining`}</div>
        // );
      }
      if (checkDate == 0) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              alt=""
              src={CardTaskCalenderImage}
              style={{ marginRight: 5, height: 12, width: 12 }}
            ></img>
            <div className="text-due-today">Due today</div>
          </div>
        );
      }
    }
    return "";
  };
  return (
    <div>
      {!hideSearchBar && (
        <div
          className="project-header tab-header"
          style={{
            marginBottom: "12px"
          }}
        >
          <span style={{ fontSize: 14, color: "#808080", marginTop: "6px" }}>
            {searchQuery?.length > 0 ? (
              searchQueryProjectArr.length > 0 ? (
                <>
                  <IntlMessages id={"workspace.search-found"} /> '{searchQuery}'
                </>
              ) : (
                <>
                  <IntlMessages id={"workspace.search-not-found"} /> '
                  {searchQuery}'
                </>
              )
            ) : (
              <>
                <IntlMessages id={"workspace.showing"} /> {projectArr?.length}{" "}
                <IntlMessages id={"workspace.project"} />
                {projectArr?.length > 1 ? (
                  <IntlMessages id={"project.s"} />
                ) : (
                  ""
                )}
              </>
            )}
          </span>

          <div className="project-search">
            <ProjectTabWrapper>
              <InputGroup className="input-search-group">
                <InputGroupAddon
                  addonType="prepend"
                  className="icon-search-input"
                  size="sm"
                >
                  <img
                    src={search}
                    style={{ marginLeft: "5px", height: 14, width: 14 }}
                    alt=""
                  />
                </InputGroupAddon>

                <Input
                  style={{ backgroundColor: "#F5F5F5" }}
                  value={searchQuery}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder={InjectPlaceHolderMessage(
                    "workspace.search",
                    props.LanguageSwitcher.language.languageId
                  )}
                  className="no-border search-input outline-none"
                />

                {searchQuery?.length > 0 && (
                  <InputGroupAddon
                    addonType="append"
                    className="icon-search-input"
                    size="sm"
                    onClick={() => {
                      setSearchQuery("");
                      setIsSearch(false);
                    }}
                  >
                    <img
                      id="close"
                      src={TaskDialogCrossImage}
                      height="12"
                      width="12"
                      style={{ marginRight: "10px" }}
                      alt="Labels"
                    />
                  </InputGroupAddon>
                )}
              </InputGroup>
            </ProjectTabWrapper>
          </div>
        </div>
      )}
      <div>
        {isLoader ? (
          <div className="row">
            <>
              <div
                className="col-lg-4 col-sm-12 col-xs-12 col-md-4"
                style={{ padding: 10 }}
              >
                <div className="project-card">
                  <CardShimmer />
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-12 col-xs-12 col-md-4"
                style={{ padding: 10 }}
              >
                <div className="project-card">
                  <CardShimmer />
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-12 col-xs-12 col-md-4"
                style={{ padding: 10 }}
              >
                <div className="project-card">
                  <CardShimmer />
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-12 col-xs-12 col-md-4"
                style={{ padding: 10 }}
              >
                <div className="project-card">
                  <CardShimmer />
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-12 col-xs-12 col-md-4"
                style={{ padding: 10 }}
              >
                <div className="project-card">
                  <CardShimmer />
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-12 col-xs-12 col-md-4"
                style={{ padding: 10 }}
              >
                <div className="project-card">
                  <CardShimmer />
                </div>
              </div>
            </>
          </div>
        ) : (
          <>
            <div>
              {/* <span style={{ fontSize: 14, color: "#808080" }}>
                {searchQuery?.length > 0
                  ? searchQueryProjectArr.length > 0
                    ? `Showing projects for '${searchQuery}'`
                    : `We couldn't find any project named '${searchQuery}'`
                  : `Showing ${projectArr?.length} project${projectArr?.length > 1 ? "s" : ""
                  }`}
              </span> */}
            </div>
            <ProjectCardWrapper>
              <div className="row">
                {searchQueryProjectArr &&
                  isSearch &&
                  searchQueryProjectArr?.length > 0 && (
                    <>
                      {searchQueryProjectArr &&
                        searchQueryProjectArr?.length > 0 &&
                        searchQueryProjectArr.map((project, i) => {
                          return (
                            <ProjectCard
                              targetAppend="search"
                              project={project}
                              handleArchive={handleArchive}
                              handleGoTOProject={() =>
                                handleGoTOProject(project.id)
                              }
                            />
                            // <div
                            //   className="col-lg-4 col-sm-12 col-xs-12 col-md-4"
                            //   onClick={() => handleGoTOProject(project.id)}
                            //   style={{ padding: 10 }}
                            // >
                            //   {project.isProjectOwner && (
                            //     <>
                            //       <div
                            //         className="more-option"
                            //         id={`project-as-${project.id}`}
                            //         onClick={e => e.stopPropagation()}
                            //       >
                            //         <i className="fas fa-ellipsis-h"></i>
                            //       </div>
                            //       <Popover
                            //         trigger="legacy"
                            //         className="roy-menu"
                            //         innerClassName="roy-inner-content"
                            //         placement="bottom-end"
                            //         target={`project-as-${project.id}`}
                            //         isOpen={
                            //           optionMenu.status &&
                            //           optionMenu.id === project.id
                            //         }
                            //         toggle={() =>
                            //           setOptionMenu({
                            //             status: !optionMenu.status,
                            //             id: project.id
                            //           })
                            //         }
                            //       >
                            //         <PopoverBody>
                            //           <div
                            //             className="roy-menu-list"
                            //             style={{
                            //               display: "flex",
                            //               justifyContent: "center",
                            //               alignItems: "center"
                            //             }}
                            //             onClick={e => {
                            //               e.stopPropagation();
                            //               handleArchive(true, project.id);
                            //             }}
                            //           >
                            //             <img
                            //               src={archive}
                            //               style={{
                            //                 paddingRight: 2,
                            //                 height: 15,
                            //                 width: 15
                            //               }}
                            //             />
                            //             <span style={{ paddingLeft: 2 }}>
                            //               Archive project
                            //             </span>
                            //           </div>
                            //         </PopoverBody>
                            //       </Popover>
                            //     </>
                            //   )}
                            //   <div
                            //     className="project-card"
                            //     style={{
                            //       background: getColorCode(project.color)
                            //     }}
                            //   >
                            //     <div className="project-card-title">
                            //       {project?.name}
                            //     </div>
                            //   </div>
                            // </div>
                          );
                        })}
                    </>
                  )}
                {!isSearch &&
                  projectArr &&
                  projectArr?.length > 0 &&
                  projectArr.map((project, i) => {
                    return (
                      <ProjectCard
                        targetAppend="project"
                        project={project}
                        handleArchive={handleArchive}
                        handleGoTOProject={() => handleGoTOProject(project.id)}
                      />
                    );
                  })}

                <div
                  className="col-lg-4 col-sm-12 col-xs-12 col-md-4"
                  style={{ padding: "0px 15px 15px 15px" }}
                  onClick={toggleAddProject}
                >
                  <div
                    className="project-card"
                    style={{ backgroundColor: "#F4F5F7", minHeight: "120px" }}
                  >
                    <div className="add-project-card">
                      <img
                        alt=""
                        src={addProjectTabImage}
                        style={{
                          marginRight: 5,
                          height: 10,
                          width: 10,
                          position: "relative",
                          top: "-2px"
                        }}
                      />
                      <IntlMessages id={"workspace.add_project"} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 "
                  style={{ padding: 10 }}
                >
                  {archivedProjectArr?.length > 0 && (
                    <>
                      {isArchivedOpen ? (
                        archivedProjectArr?.length > 0 && (
                          <>
                            <div className="tab-header">
                              <div className="project-header-heading">
                                {/* Archived Projects{" "} */}
                                <span
                                  style={{
                                    paddingLeft: 2,
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    textDecoration: "underline"
                                  }}
                                  onClick={() =>
                                    setIsArchivedOpen(!isArchivedOpen)
                                  }
                                >
                                  {" "}
                                  <IntlMessages
                                    id={"workspace.hidearchived_projects"}
                                  />{" "}
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                {archivedProjectArr &&
                                  archivedProjectArr?.length > 0 &&
                                  archivedProjectArr.map((project, i) => {
                                    return (
                                      <>
                                        {project && (
                                          <div
                                            onClick={() =>
                                              handleGoTOProject(project.id)
                                            }
                                            style={{
                                              paddingBottom: 15,
                                              cursor: "pointer"
                                            }}
                                            className={
                                              "col-lg-4 col-sm-12 col-xs-12 col-md-4 "
                                            }
                                          >
                                            <div
                                              className="project-card-container"
                                              style={{
                                                borderTop: `5px solid ${getColorCode(
                                                  project.color
                                                )}`
                                              }}
                                            >
                                              <div
                                                className={"row"}
                                                style={{
                                                  minHeight: 70,
                                                  padding: "0px 15px"
                                                }}
                                              >
                                                <div
                                                  className={
                                                    "col-lg-10 col-sm-10 col-xs-10 col-md-10"
                                                  }
                                                  style={{
                                                    paddingLeft: 0,
                                                    paddingRight: 20,
                                                    position: "relative",
                                                    top: -7
                                                  }}
                                                >
                                                  <div className="project-title">
                                                    {project.name}
                                                  </div>
                                                  <div
                                                    className="project-description"
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        project.description !==
                                                          null &&
                                                        project.description !==
                                                          "null"
                                                          ? project.description
                                                          : ""
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              <div
                                                className="row "
                                                style={{
                                                  paddingTop: 0,
                                                  display: "flex",
                                                  alignItems: "center"
                                                }}
                                              >
                                                <div className="col-lg-6 col-sm-6 col-xs-0 col-md-6">
                                                  <div
                                                    style={{ paddingLeft: 0 }}
                                                  >
                                                    {project.isFavorite && (
                                                      <i
                                                        className="fa fa-heart fs-12"
                                                        style={{ color: "red" }}
                                                      ></i>
                                                    )}
                                                  </div>
                                                </div>

                                                <div
                                                  className="col-lg-6 col-sm-6 col-xs-12 col-md-6 fs-13"
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    padding: "0px 15px 0px 0px"
                                                  }}
                                                >
                                                  <div>
                                                    {getOverDueProjectDate(
                                                      project.endDate
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {project.isProjectOwner && (
                                              <>
                                                <div
                                                  style={{
                                                    padding: "13px 10px 5px 5px"
                                                  }}
                                                  className="more-option"
                                                  id={`project-a-${project.id}`}
                                                  onClick={e =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  <img
                                                    alt=""
                                                    src={threeDot_Icon}
                                                  ></img>
                                                </div>

                                                <Popover
                                                  style={{
                                                    position: "absolute",
                                                    backgroundColor: "white",
                                                    boxShadow:
                                                      "rgb(0 0 0 / 10%) 0px 0px 15px",
                                                    minWidth: "210px",
                                                    borderRadius: 5,
                                                    right: 20,
                                                    top: -25
                                                  }}
                                                  hideArrow={true}
                                                  trigger="legacy"
                                                  className="roy-menu"
                                                  innerClassName="roy-inner-content"
                                                  placement="bottom"
                                                  target={`project-a-${project.id}`}
                                                  isOpen={
                                                    optionMenu.status &&
                                                    optionMenu.id === project.id
                                                  }
                                                  toggle={() =>
                                                    setOptionMenu({
                                                      status: !optionMenu.status,
                                                      id: project.id
                                                    })
                                                  }
                                                >
                                                  <PopoverBody
                                                    style={{
                                                      borderRadius: 5,
                                                      padding: 0,
                                                      cursor: "pointer"
                                                    }}
                                                    onClick={e => {
                                                      e.stopPropagation();
                                                      handleArchive(
                                                        false,
                                                        project.id
                                                      );
                                                    }}
                                                  >
                                                    {project.isProjectOwner && (
                                                      <div
                                                        className="roy-menu-list"
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                          alignItems: "center",
                                                          padding: "0px",
                                                          margin: "10px 15px",
                                                          height: "35px"
                                                        }}
                                                        onClick={e => {
                                                          e.stopPropagation();
                                                          handleArchive(
                                                            false,
                                                            project.id
                                                          );
                                                        }}
                                                      >
                                                        <img
                                                          alt=""
                                                          src={archive}
                                                          style={{
                                                            paddingRight: 2,
                                                            height: 15,
                                                            width: 15,
                                                            marginRight: 5
                                                          }}
                                                        />
                                                        <span
                                                          style={{
                                                            paddingLeft: 1
                                                          }}
                                                        >
                                                          {
                                                            <IntlMessages
                                                              id={
                                                                "project.unarchive_project"
                                                              }
                                                            />
                                                          }
                                                        </span>
                                                      </div>
                                                    )}
                                                  </PopoverBody>
                                                </Popover>
                                              </>
                                            )}
                                          </div>
                                        )}
                                        {/* <ProjectCard
                                          targetAppend="arch-unarch"
                                          project={project}
                                          handleArchive={handleArchive}
                                          handleGoTOProject={() =>
                                            handleGoTOProject(project.id)
                                          }
                                        /> */}
                                        {/* <div
                                          className="col-lg-4 col-sm-12 col-xs-12 col-md-4"
                                          onClick={() =>
                                            handleGoTOProject(project.id)
                                          }
                                          style={{ padding: 10 }}
                                        >
                                          {" "}
                                          {project.isProjectOwner && (
                                            <>
                                              <div
                                                className="more-option"
                                                id={`project--${project.id}`}
                                                onClick={e => e.stopPropagation()}
                                              >
                                                <i className="fas fa-ellipsis-h"></i>
                                              </div>
                                              <Popover
                                                trigger="legacy"
                                                className="roy-menu"
                                                innerClassName="roy-inner-content"
                                                placement="bottom-end"
                                                target={`project--${project.id}`}
                                                isOpen={
                                                  optionMenu.status &&
                                                  optionMenu.id === project.id
                                                }
                                                toggle={() =>
                                                  setOptionMenu({
                                                    status: !optionMenu.status,
                                                    id: project.id
                                                  })
                                                }
                                              >
                                                <PopoverBody>
                                                  {project.isProjectOwner && (
                                                    <div
                                                      className="roy-menu-list"
                                                      style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                      }}
                                                      onClick={e => {
                                                        e.stopPropagation();
                                                        handleArchive(
                                                          false,
                                                          project.id
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={archive}
                                                        style={{
                                                          paddingRight: 2,
                                                          height: 15,
                                                          width: 15
                                                        }}
                                                      />
                                                      <span
                                                        style={{ paddingLeft: 2 }}
                                                      >
                                                        Unarchive project
                                                    </span>
                                                    </div>
                                                  )}
                                                </PopoverBody>
                                              </Popover>
                                            </>
                                          )}
                                          <div
                                            className="project-card"
                                            style={{
                                              background: getColorCode(
                                                project.color
                                              )
                                            }}
                                          >
                                            <div className="project-card-title">
                                              {project?.name}
                                            </div>
                                          </div>
                                        </div> */}
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          </>
                        )
                      ) : (
                        <div className="tab-header text-underline">
                          <div
                            className="project-header-heading"
                            onClick={() => setIsArchivedOpen(!isArchivedOpen)}
                            style={{
                              textDecoration: "underline",
                              fontSize: "14px",
                              cursor: "pointer"
                            }}
                          >
                            <IntlMessages
                              id={"workspace.showarchivedprojects"}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </ProjectCardWrapper>
          </>
        )}
      </div>
    </div>
  );
};

// export default ProjectTab;
const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps)(ProjectTab);
