import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  inviteCollaborators,
  removeCollaborators
} from "helper/services/projectServices";
import { ProjectCollaboratorsCross } from "helper/constant";
import chroma from "chroma-js";
import { Alert } from "reactstrap";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import Select, { components } from "react-select";
import { getCollaboratorsList } from "helper/services/businessCollaboratorsServices";
import CustomAlert from "util/CustomAlert";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import { useToasts } from "react-toast-notifications";
import { projectSocketRoomPrefix } from "constatnt";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
const ShareModal = props => {
  const {
    modal,
    setmodal,
    projectId,
    topbarTheme,
    collaboratorList,
    showNotifications,
    PRIMARY_COLOR,
    businessId,
    removeCollaboratorById,
    pushCollaborator,
    currentLoggedInUser,
    leftCollab
  } = props;
  const { addToast } = useToasts();
  const [isDisabled, setIsDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoader, setIsLoader] = useState(false);

  const [enterValue, setEnterValue] = useState();
  const [CheckEmailPresent, setCheckEmailPresent] = useState();
  const [isCheckEmailValidation, setIsCheckEmailValidation] = useState(false);
  const [dropdownInputValue, setDropdownInputValue] = useState("");

  const [partcipantsArr, setPartcipantsArr] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const [inviteParticipants, setInviteParticipants] = useState([]);
  const [isDeleteAlert, setDeleteAlert] = useState({
    status: false,
    id: "",
    isLoader: false,
    projectCollaboratorListName: ""
  });
  let payload = {
    roomname: projectId && `${projectSocketRoomPrefix}${projectId}`
  };
  const participantsInputRef = useRef(null);
  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    if (modal && isEditing) {
      participantsInputRef.current.focus();
    }
  });
  useEffect(() => {
    if (modal) {
      fetchParticipants();
    }
    return () => {};
  }, [modal]);
  const profileImage = {
    borderRadius: "50%",
    border: "1 solid",
    width: 35,
    height: 35,
    objectFit: "cover",
    textAlign: "center"
  };

  const InCap = {
    lineHeight: 1,
    fontSize: 15,
    // color: "rgb(23, 43, 77)",
    color: "white",
    textAlign: "center",
    fontWeight: 600
  };

  const colourStyles = {
    control: (base, state) => ({
      ...base,
      background: "#ffff",

      // match with the menu
      border: state.isDisabled
        ? null
        : state.isSelected
        ? "#909090"
        : state.isFocused
        ? "#f6f6f6"
        : null
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#909090"
          : isFocused
          ? "#f6f6f6"
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast("grey", "white") > 2
            ? "white"
            : "black"
          : "#808080",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#909090" : "#f6f6f6")
        }
      };
    },
    singleValue: styles => ({ ...styles, color: "black" })
  };

  // checkEmailValidations
  const checkEmailValidations = email =>
    email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  // check user input value  checkEmailUnique is allready in present  or not
  const checkEmailUnique = emailid => {
    //
    //
    var flag = true;
    collaboratorList &&
      collaboratorList.map(data => {
        if (data.user && data.user.email == emailid) {
          flag = false;
        }
      });
    //
    return flag;
  };

  const isEmailAlreadyInviteParticipantst = emailid => {
    const participant = inviteParticipants;
    var isExist = false;

    participant &&
      participant.map(data => {
        let str = data.chipLabel;
        str = str.slice(0, -1);

        if (str == emailid) {
          isExist = true;
          setIsCheckEmailValidation(true);
        }
      });

    return isExist;
  };
  // check isEmailAlreadyParticipant is allready in present  or not
  const isEmailAlreadyParticipant = emailid => {
    const participant = inviteParticipants;
    var isExist = false;

    participant &&
      participant.map(data => {
        if (data.chipLabel && emailid == data.chipLabel) {
          isExist = true;
          setIsCheckEmailValidation(true);
        }
      });
    return isExist;
  };

  // const handleSendInvite = async emailid => {
  //   if (emailid && checkEmailValidations(emailid)) {
  //     if (checkEmailUnique(emailid)) {
  //       setIsInvitationLoader(true);
  //       try {
  //         const Response = await inviteCollaborators({
  //           project_id: projectId,
  //           recipient_email: [emailid]
  //         });
  //         if (Response) {
  //           if (Response.error) {
  //             addToast(ToastMessage(Response.error.message), {
  //               appearance: "error",
  //               autoDismiss: true
  //             });
  //             // showNotifications();
  //             setIsInvitationLoader(false);
  //           }
  //           if (Response.message) {
  //             //        addCollaborator(payload, cb => {
  //             //     if (cb.status == "Ok") {
  //             //         fetchParticipants();

  //             // } else {
  //             //   /**
  //             //    * @TODO
  //             //    * Handle error callback from socket
  //             //    * sample error cb: { status: "Failed", message: "error message" };
  //             //    */
  //             //   showNotifications();
  //             // }
  //             // })
  //             addToast(ToastMessage(Response.message), {
  //               appearance: "success",
  //               autoDismiss: true
  //             });
  //             setIsInvitationLoader(false);
  //             setmodal(!modal);
  //             // showNotifications(Response.message);
  //           }
  //         }
  //       } catch (e) { }
  //     } else {
  //       addToast(ToastMessage("Already a collaborator"), {
  //         appearance: "error",
  //         autoDismiss: true
  //       });
  //     }
  //   }
  // };
  const handleKeyUp = e => {
    // if (isCheckEmailValidation == true) {
    if (e.keyCode == 32) {
    } else if (e.keyCode == 188) {
    } else if (e.keyCode == 13) {
    } else if (e.keyCode == 9) {
    }
    // }
  };

  // space and comma key press then add new chip in chip input value
  const handleKeyDown = e => {
    if (isCheckEmailValidation == true) {
      if (e.keyCode == 32) {
        handleAddChipValue(CheckEmailPresent);
      } else if (e.keyCode == 188) {
        handleAddChipValue(CheckEmailPresent);
      } else if (e.keyCode == 13) {
        handleAddChipValue(CheckEmailPresent);
      } else if (e.keyCode == 9) {
        handleAddChipValue(CheckEmailPresent);
      }
    }
  };

  // add new chip in inputvalue then check value is allready in present or not
  const handleAddChipValue = async emailid => {
    try {
      if (emailid && checkEmailValidations(emailid)) {
        const newChip = {
          chipLabel: emailid,
          label: emailid,
          value: emailid
        };
        //
        //
        // setInviteParticipants([...inviteParticipants, newChip]);
        if (inviteParticipants) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
        // handleCharactersValidation(dropdownInputValue);

        let spaceremove = dropdownInputValue.substr(0, 1);
        if (spaceremove == " " || spaceremove == ",") {
          setDropdownInputValue("");
        }
        const start = 0;
        let end = dropdownInputValue.length;
        let isCheckEmailValidation = dropdownInputValue.substr(start, end);

        if (newChip.chipLabel == isCheckEmailValidation) {
          const newParticipants =
            inviteParticipants?.length > 0
              ? [...inviteParticipants, newChip]
              : [newChip];
          return (
            setDropdownInputValue(""), setInviteParticipants(newParticipants)
          );
        } else {
          return "no";
        }
      }
    } catch (e) {}
  };

  // remove spaces and comma which are entered by user i/0 generate chips
  const handleCharactersValidation = email => {
    let givenEmail = email;
    if (removeComma(givenEmail)) {
      givenEmail = removeComma(email);
    }
    givenEmail = removeSpace(givenEmail);
    return givenEmail;
  };
  const removeSpace = email => {
    return email.replace(/\s/g, "");
  };
  const removeComma = email => {
    if (email.includes(",")) {
      const checkIndex = email.indexOf(",");
      if (checkIndex > -1) {
        let givenEmail = email;
        givenEmail = email.slice(checkIndex + 1, email.length);
        return givenEmail;
      }
      return 0;
    }
  };

  // handleNoOptionsMessage user on change in inputvalue
  const handleNoOptionsMessage = emailid => {
    if (emailid.length > 0) {
      let givenEmail = handleCharactersValidation(emailid).toLowerCase();
      if (checkEmailValidations(givenEmail)) {
        setEnterValue(givenEmail);
        return (
          <>
            <div>
              {!checkEmailUnique(givenEmail)
                ? (setIsCheckEmailValidation(false),
                  (
                    <div className=" ptb-1">
                      <p
                        style={{ color: "red", fontSize: 14, fontWeight: 400 }}
                      >
                        {givenEmail}
                        {
                          <IntlMessages
                            id={"project.project_already_a_collaborator"}
                          />
                        }
                      </p>
                    </div>
                  ))
                : isEmailAlreadyParticipant(givenEmail) ||
                  isEmailAlreadyInviteParticipantst(givenEmail)
                ? (setIsCheckEmailValidation(false),
                  (
                    <div className=" ptb-1">
                      <p
                        style={{ color: "red", fontSize: 14, fontWeight: 400 }}
                      >
                        {
                          <IntlMessages
                            id={"project.project_email_is_already_present"}
                          />
                        }
                      </p>
                    </div>
                  ))
                : (setIsCheckEmailValidation(true),
                  setCheckEmailPresent(givenEmail),
                  (
                    <div className=" ptb-1" style={{ fontSize: 14 }}>
                      <p>
                        {
                          <IntlMessages
                            id={
                              "project.project_add_multiple_emails_by_pressing_comma"
                            }
                          />
                        }
                      </p>
                    </div>
                  ))

              // <div className=" ptb-10 border-bottom">
              //   <p>
              //     press “send” button below and we'll send email to this {givenEmail}{" "}
              //   email for an invitation.
              // </p>
              // </div>
              }
              {/* {isInvitationLoader ? (
                <div style={{ marginTop: 10, marginBottom: 10, float: "left" }}>
                  <Loader height="23px" width="20px" />
                </div>
              ) : !checkEmailUnique(emailid) && emailid ? <div style={{ marginTop: 10, marginBottom: 48 }}>
                <button
                  className="btn button"
                  type="button"
                  // onClick={checkEmailUnique(emailid) ? () => handleSendInvite(emailid) : ""}
                  style={{
                    backgroundColor: "grey",
                    color: "#FFF",
                    float: "left",
                    cursor: !checkEmailUnique(emailid) ? "not-allowed" : "",
                  }}
                >
                  Send invitation
                  </button>
              </div> : <div style={{ marginTop: 10, marginBottom: 48 }}>
                <button
                  className="btn button"
                  type="button"
                  // onClick={checkEmailUnique(emailid) ? () => handleSendInvite(emailid) : ""}
                  style={{
                    backgroundColor: PRIMARY_COLOR,
                    color: "#FFF",
                    float: "left",
                    cursor: !checkEmailUnique(emailid) ? "not-allowed" : "",
                  }}
                >
                  Send invitation
                  </button>
              </div>} */}
            </div>
          </>
        );
      } else {
        let spaceremove = dropdownInputValue.substr(0, 1);
        if (spaceremove == " " || spaceremove == ",") {
          setDropdownInputValue("");
        }
        return (
          <>
            <div
              className=" ptb-1"
              style={{ justifyContent: "center", fontSize: 14 }}
            >
              <p>
                <IntlMessages id={"project.projectcollabratorplaceholder1"} />{" "}
              </p>
              <p>
                <IntlMessages id={"project.projectcollabratorplaceholder2"} />
              </p>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <div
            className=" ptb-1"
            style={{ justifyContent: "center", fontSize: 14 }}
          >
            {<IntlMessages id={"projectdetails.nocollaborator"} />}
          </div>
        </>
      );
    }
  };

  const fetchParticipants = async e => {
    // setIsParticipantsLoader(true);
    const result = await getCollaboratorsList(businessId);
    if (result) {
      if (result.error) {
        showNotifications(result.error.message);
      }
      if (result.data) {
        //  setCollaboratorArr(result.data);
        handleParticipants(result.data);
        var checkArr = [...result.data];
        let getOwner =
          checkArr &&
          checkArr.filter(data => data.role.toLowerCase() == "owner");

        if (getOwner[0].user.id === currentLoggedInUser.id) {
          setIsOwner(true);
        } else {
          setIsOwner(false);
        }
      }
      //  setIsParticipantsLoader(false);
    }
  };
  // const showNotifications = (message, status) => {
  //   addToast(ToastMessage(message), {
  //     appearance: status ? status : "error",
  //     autoDismiss: true
  //   });
  // };
  const MultiValue = props => (
    <components.MultiValue {...props}>
      {props.data.chipLabel}
    </components.MultiValue>
  );
  const handleRemoveParticipant = async id => {
    if (id) {
      try {
        setDeleteAlert({ ...isDeleteAlert, isLoader: true });
        const response = await removeCollaborators({
          collaboration_id: id
        });
        if (response) {
          if (response.error) {
            // addToast(ToastMessage(response.error.message), {
            //   appearance: "error",
            //   autoDismiss: true
            // });
            showNotifications();
          }
          if (response.message) {
            // addToast(ToastMessage(response.message), {
            //   appearance: "success",
            //   autoDismiss: true
            // });
            showNotifications(response.message, "success");
            removeCollaboratorById(id);

            if (checkcurrentLoggedInUser(isDeleteAlert?.userId) && !isOwner) {
              leftCollab();
            }
            //      addCollaborator(payload, cb => {
            //     if (cb.status == "Ok") {
            //         fetchParticipants();

            // } else {
            //   /**
            //    * @TODO
            //    * Handle error callback from socket
            //    * sample error cb: { status: "Failed", message: "error message" };
            //    */
            //   showNotifications();
            // }
            // })

            setDeleteAlert({ status: false, id: "", isLoader: false });
          }
        }
      } catch (e) {
        setDeleteAlert({ status: false, id: "", isLoader: false });
        showNotifications();
      }
    }
  };
  const handleParticipants = async data => {
    // handle data for to check request are accepted and are collaborator
    // also if they are part of project than dont display
    var updatedData = [];
    await Promise.all(
      data.map(data => {
        if (data.requestStatus.toLowerCase() == "accepted" && data?.user) {
          updatedData.push({
            ...data,
            label: `${data?.user?.name} (${data?.user?.email})`,
            chipLabel: `${data?.user?.email} `,
            value: data?.user?.email
          });
        }
      })
    );

    let finalDatarr = [];
    collaboratorList &&
      (await Promise.all(
        updatedData.map(async item => {
          let arr1 = await Promise.all(
            collaboratorList.filter(item2 => item?.user?.id == item2?.user?.id)
          );
          if (arr1.length == 0) {
            finalDatarr.push(item);
          }
        })
      ));
    // collaboratorList.sort((a, b) => a.id - b.id);
    // change up data sorting
    setPartcipantsArr(finalDatarr);
  };

  const getEmailArray = collab => {
    var emailArray = [];
    collab.map(data => {
      emailArray.push(
        data?.user?.email
          ? data.user.email.toString()
          : data.chipLabel.toString()
      );
    });
    return emailArray;
  };
  const checkcurrentLoggedInUser = userid => {
    if (currentLoggedInUser?.id == userid) {
      return true;
    }
    return false;
  };
  const handleProjectaddCollaborators = async e => {
    e.preventDefault();
    setVisible(false);
    if (inviteParticipants && inviteParticipants.length > 0) {
      setIsLoader(true);
      try {
        if (inviteParticipants) {
          const Response = await inviteCollaborators({
            project_id: projectId,
            recipient_email: getEmailArray(inviteParticipants)
          });
          if (Response) {
            setIsLoader(false);
            if (Response.error) {
              // addToast(ToastMessage(Response.error.message), {
              //   appearance: "error",
              //   autoDismiss: true
              // });
              setVisible(true);
              setErrorMessage(Response.error.message);
              showNotifications();
            } else if (Response.errors) {
              // addToast(ToastMessage(Response.error.message), {
              //   appearance: "error",
              //   autoDismiss: true
              // });
              showNotifications();
              setVisible(true);
              setErrorMessage(Response.errors.message);
            } else {
              setVisible(false);
              if (Response.data) {
                // addToast(ToastMessage(Response.message), {
                //   appearance: "success",
                //   autoDismiss: true
                // });
                showNotifications(
                  "Collaborator added to project successfully",
                  "success"
                );
                setInviteParticipants([]);
                if (Response.data?.length > 0) {
                  pushCollaborator(Response.data);
                }
                //          addCollaborator(payload, cb => {
                //     if (cb.status == "Ok") {
                //         fetchParticipants();

                // } else {
                //   /**
                //    * @TODO
                //    * Handle error callback from socket
                //    * sample error cb: { status: "Failed", message: "error message" };
                //    */
                //   showNotifications();
                // }
                // })
                setmodal(!modal);
              }
            }
          }
        } else {
          setIsLoader(false);
          setVisible(true);
          setErrorMessage(
            "Please Enter Valid Email id email must be email@domain.com"
          );
        }
      } catch (e) {
        setIsLoader(false);
      }
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => setmodal(!modal)}
      size="lg"
      centered
      style={{ width: 600 }}
    >
      <form onSubmit={handleProjectaddCollaborators}>
        <ModalHeader style={{ position: "relative" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ fontSize: 17, color: "black", fontWeight: 600 }}>
              <IntlMessages id={"project.project_collaborators"} />
            </div>
            <div>
              <img
                src={ProjectCollaboratorsCross}
                style={{
                  height: "18px",
                  width: "15px",
                  marginRight: "0px",
                  marginTop: "0px",
                  textAlign: "center",
                  position: "absolute",
                  top: 22,
                  right: "20px",
                  cursor: "pointer"
                }}
                onClick={() => setmodal(!modal)}
              ></img>
            </div>
          </div>
        </ModalHeader>

        <Alert
          className="c-"
          style={{ backgroundColor: "RED ", color: "white" }}
          isOpen={visible}
          // toggle={() => setVisible(false)}
        >
          {visible ? errorMessage : ""}
        </Alert>
        <ModalBody style={{ minHeight: 380 }}>
          <div
            className="form-group"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              margin: 0
            }}
          >
            <div style={{ width: "73%" }}>
              <Select
                styles={colourStyles}
                // onBlur={e => {
                //   participantsInputRef.current.focus();
                // }}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#989797"
                  }
                })}
                ref={participantsInputRef}
                isMulti
                closeMenuOnSelect={false}
                placeholder={InjectPlaceHolderMessage(
                  "project.addcollaborator_placeholder",
                  props.LanguageSwitcher.language.languageId
                )}
                id="participants"
                name="participants"
                value={inviteParticipants}
                options={partcipantsArr}
                onChange={e => {
                  setInviteParticipants(e);
                  if (e) {
                    setIsDisabled(false);
                  } else {
                    setIsDisabled(true);
                  }
                }}
                inputValue={dropdownInputValue}
                onInputChange={e => {
                  setDropdownInputValue(e);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
                onKeyDown={handleKeyDown}
                noOptionsMessage={e => handleNoOptionsMessage(e.inputValue)}
                components={{
                  IndicatorSeparator: () => null,
                  MultiValue
                }}
                // components={{ MultiValue }}
              />
            </div>
            <div>
              {isLoader ? (
                <Loader />
              ) : (
                <Button
                  type="submit"
                  className="button btn"
                  // onClick={() => handleSendInvite(inviteParticipants)}
                  style={{
                    border: "none",
                    backgroundColor: PRIMARY_COLOR ? PRIMARY_COLOR : "black",
                    width: "100px",
                    height: "36px",
                    fontSize: "17px !important",
                    color: "white"
                  }}
                  disabled={isDisabled}
                >
                  <span
                    style={{
                      fontSize:
                        "14flex-x  align-center plr-10 ptb-5 flex-x  align-center plr-10 ptb-5 flex-x  align-center plr-10 ptb-5px"
                    }}
                  >
                    <IntlMessages id={"project.invite_button"} />
                  </span>
                </Button>
              )}
            </div>
          </div>
          <div style={{ height: 310, overflow: "auto" }}>
            <div
              style={{ paddingLeft: 14 }}
              className="container list-container"
              /* key={i} */
            >
              {collaboratorList &&
                collaboratorList.map((data, i) => {
                  if (data.user && data.requestStatus === "ACCEPTED") {
                    return (
                      <div className="ptb-10 ma-0 flex-x align-center" key={i}>
                        {data?.user &&
                        data.user !== null &&
                        data?.user?.thumbnail &&
                        data?.user?.thumbnail != "" ? (
                          <div>
                            <img
                              src={data?.user?.thumbnail}
                              style={profileImage}
                              alt=""
                            />
                          </div>
                        ) : (
                          <div
                            style={profileBackground(
                              data.user
                                ? data.user.name.charAt(0)
                                : data.user.email,
                              35,
                              35
                            )}
                          >
                            <span style={InCap}>
                              {data.user && data.user.name
                                ? data.user.name.charAt(0).toUpperCase()
                                : data.user.email.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        )}
                        <div className="mlr-10 flex-1">
                          <p
                            className=" fs-14 demi-bold-text"
                            style={{ marginBottom: "-3px" }}
                          >
                            {data.user && data.user.name
                              ? data.user.name
                              : data.user.email.split("@")[0]}{" "}
                            {data.role.toLowerCase() == "owner" && (
                              <span
                                style={{
                                  borderRadius: "15",
                                  color: "white",
                                  padding: "2px 10px",
                                  fontSize: "10px",
                                  textAlign: "center",
                                  justifyContent: "center",
                                  backgroundColor: PRIMARY_COLOR
                                    ? PRIMARY_COLOR
                                    : "black"
                                }}
                                className="c-btn-round ma-5 "
                              >
                                <IntlMessages id={"projectdetails.owner"} />
                              </span>
                            )}
                          </p>
                          <small style={{ color: "#808080", fontSize: "14px" }}>
                            {data.user.email}
                          </small>
                        </div>

                        <div
                          className="task-icons"
                          onClick={() =>
                            setDeleteAlert({
                              status: true,
                              id: data.id,
                              isLoader: true,
                              userId: data.user.id,
                              projectCollaboratorListName: data.user.name
                            })
                          }
                        >
                          {data.role.toLowerCase() == "collaborator" &&
                            (checkcurrentLoggedInUser(data?.user?.id) ||
                              isOwner) && (
                              <>
                                {isDeleteAlert &&
                                isDeleteAlert.id === data.id &&
                                isDeleteAlert.isLoader ? (
                                  <Loader height="18px" width="17px" />
                                ) : (
                                  <i className="fas fa-trash text-muted fs-12"></i>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </ModalBody>
        {/* <ModalFooter>
          <Button
            className="button btn"
            onClick={() => setmodal(!modal)}
            style={{
              backgroundColor: "transparent",
              border: 0,
              color: "black"
            }}
          >
            Cancel
          </Button>
          {isLoader ? (
            <Loader height="24px" width="20px" />
          ) : (
            <Button
              type="submit"
              className="button btn"
              // onClick={() => handleSendInvite(inviteParticipants)}
              style={{
                backgroundColor: isDisabled ? "gray" : topbarTheme.buttonColor,
                border: "none",
                backgroundColor: PRIMARY_COLOR ? PRIMARY_COLOR : "black",
                width: "80px"
              }}
              disabled={isDisabled}
            >
              Add
            </Button>
          )}
        </ModalFooter> */}
      </form>
      <CustomAlert
        CustomMessage={isDeleteAlert.projectCollaboratorListName}
        action="remove"
        messageType="collaborator"
        show={isDeleteAlert.status}
        onConfirm={() => {
          if (isDeleteAlert.id) {
            handleRemoveParticipant(isDeleteAlert.id);
          }
        }}
        PRIMARY_COLOR={PRIMARY_COLOR}
        onCancel={() => {
          setDeleteAlert({
            id: "",
            status: false,
            isLoader: false,
            projectCollaboratorListName: ""
          });
        }}
      />
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    LanguageSwitcher: { ...state.LanguageSwitcher }
  };
};

export default connect(mapStateToProps, null)(ShareModal);
