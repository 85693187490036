import React from "react";
import IntlMessages from "util/intlMessages";

const TaskDataNotFound = ({ isSelf }) => {
  return (
    <div>
      <div
        style={{
          margin: "50px auto"
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "bold"
          }}
        >
          {isSelf ? (
            <>
              <div>
                <IntlMessages id={"globalmyassignment.decription2"} />
              </div>
              <div>
                <IntlMessages id={"globalmyassignment.decription4"} />
              </div>
            </>
          ) : (
            <>
              <div>
                {" "}
                <IntlMessages id={"globalmyassignment.decription2"} />
              </div>
              <div>
                {" "}
                <IntlMessages id={"globalmyassignment.decription3"} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskDataNotFound;
