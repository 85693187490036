import React, { useEffect, useState } from "react";
import Loader from "components/loader/Loader";
import IntlMessages from "util/intlMessages";
const EditList = props => {
  const {
    title,
    id,
    orderId,
    closeEditList,
    PRIMARY_COLOR,
    isUpdateSectionLoading,
    onSubmit,
    width
  } = props;
  const [listInput, setListInput] = useState("");

  useEffect(() => {
    setListInput(title);
    return () => setListInput("");
  }, [title]);
  return (
    <div>
      <div style={{ paddingBottom: 20 }}>
        <div
          className="add-comment-input-block add-card-block roe-box-shadow"
          style={{
            // border: "1px solid rgb(221, 221, 221)",
            width: width,
            padding: "10px 15px",
            backgroundColor: "white"
          }}
        >
          <textarea
            style={{ backgroundColor: "#f4f5f7", marginTop: "5px " }}
            // ref={textArea}
            // value={cardInput}
            autoFocus
            defaultValue={title || listInput}
            className="add-subtask-input"
            name="taskTitle"
            rows="2"
            onFocus={function(e) {
              var val = e.target.value;
              e.target.value = "";
              e.target.value = val;
            }}
            onChange={e => {
              setListInput(e.target.value);
            }}
            // onBlur={closeAddingCard}
            onKeyPress={event => {
              if (event.key === "Enter") {
                if (!event.shiftKey) {
                  event.preventDefault();
                  if (listInput.match(/^\S+\w{0,50}\S{0,}/g)) {
                    onSubmit({
                      title: listInput,
                      id,
                      orderId
                    });
                  }
                }
              }
            }}
          ></textarea>
          <div className="subtask-action" style={{ marginTop: 5 }}>
            {isUpdateSectionLoading ? (
              <Loader />
            ) : (
              <>
                <button
                  className="btn btn-secondary "
                  style={{
                    backgroundColor: listInput.match(/^\S+\w{0,50}\S{0,}/g)
                      ? PRIMARY_COLOR
                      : "gray",
                    border: 0,
                    marginRight: "10px",
                    fontSize: "13px"
                  }}
                  disabled={
                    listInput.match(/^\S+\w{0,50}\S{0,}/g) ? false : true
                  }
                  onClick={() => {
                    onSubmit({
                      title: listInput,
                      id,
                      orderId
                    });
                  }}
                >
                  <IntlMessages id={"profile.savebutton"} />
                </button>
                <button
                  className="btn btn-secondary"
                  style={{
                    color: "black",
                    background: "transparent",
                    border: 0,
                    fontSize: "13px",
                    paddingLeft: "0px"
                  }}
                  onClick={() => {
                    closeEditList();
                  }}
                >
                  <IntlMessages id={"project.cancel"} />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditList;
