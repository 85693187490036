import React, { useState } from "react";
import CustomAlert from "util/CustomAlert";
import { toggleArchive } from "helper/services/businessServices";
import Loader from "components/loader/Loader";
import IntlMessages from "util/intlMessages";
const SettingsTab = props => {
  const {
    id,
    showNotifications,
    archiveProject,
    isArchived,
    isLoader,
    leaveProject,
    isDeleteLoader,
    isOwner,
    projectDetails
  } = props;
  const [isActionAlert, setActionAlert] = useState({
    status: false,
    action: 0
  });
  return (
    <div>
      <div className="tab-header">
        <div className="project-header-heading">{/*Settings*/}</div>
      </div>
      <div>
        {isOwner && (
          <div
            className="project-active-workspace  flex-x "
            style={{
              borderBottom: "1px solid #E6E6E6",
              paddingBottom: "20px",
              display: "flex",
              alignItems: "center"
            }}
          >
            {/* <div className="flex-x "> */}
            <div className="flex-1">
              <div className="mr-10 flex-1">
                <p
                  className=" fs-16 demi-bold-text"
                  style={{ fontWeight: 500, color: "black" }}
                >
                  {" "}
                  {isArchived ? (
                    <IntlMessages id={"project.unarchive_project"} />
                  ) : (
                    <IntlMessages id={"project.archive_project"} />
                  )}{" "}
                </p>
                <small
                  className="text-muted fs-14"
                  style={{ color: "#808080" }}
                >
                  {" "}
                  {isArchived ? (
                    <IntlMessages id={"project.archive_description"} />
                  ) : (
                    <IntlMessages id={"project.archive_description2"} />
                  )}
                </small>
              </div>
            </div>
            <div className="fs-14">
              <>
                {" "}
                {isLoader ? (
                  <Loader height="20px" width="16px" />
                ) : (
                  <button
                    type="button"
                    class="btn btn"
                    style={{
                      textDecoration: "none",
                      border: "1px solid #FF4331",
                      color: "#FF4331",
                      width: "200px",
                      height: "36px"
                    }}
                    onClick={() =>
                      setActionAlert({ status: true, action: "archive" })
                    }
                  >
                    {isArchived ? (
                      <IntlMessages id={"project.unarchive_project"} />
                    ) : (
                      <IntlMessages id={"project.archive_project"} />
                    )}{" "}
                  </button>
                )}
              </>
            </div>
            {/* </div> */}
          </div>
        )}
        {!isOwner && (
          <div
            className="flex-x"
            style={{
              paddingTop: 35,
              borderBottom: "1px solid #E6E6E6",
              paddingBottom: "20px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <div className="mr-10 flex-1">
              <p
                className=" fs-16 demi-bold-text"
                style={{ fontWeight: 500, color: "black" }}
              >
                <IntlMessages id={"projectdetails.leave_project"} />
              </p>
              <small className="text-muted fs-14" style={{ color: "#808080" }}>
                <IntlMessages id={"projectdetails.afterleaving_project"} />
              </small>
            </div>

            <div
              className="fs-14"
              onClick={() => setActionAlert({ status: true, action: "delete" })}
            >
              <div style={{ paddingTop: 0 }}>
                {isDeleteLoader ? (
                  <Loader height="20px" width="16px" />
                ) : (
                  <button
                    type="button"
                    class="btn btn"
                    style={{
                      textDecoration: "none",
                      border: "1px solid #FF4331",
                      color: "#FF4331",
                      width: "200px",
                      height: "36px"
                    }}
                  >
                    <IntlMessages id={"projectdetails.leave_project"} />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <CustomAlert
        CustomMessage={projectDetails.name}
        action={
          isActionAlert.action == "archive"
            ? isArchived
              ? "unarchive"
              : "archive"
            : "leave"
        }
        show={isActionAlert.status}
        onConfirm={() => {
          if (isActionAlert.action == "archive") {
            archiveProject();

            setActionAlert({ status: false, action: 0 });
          } else {
            leaveProject();
            setActionAlert({ status: false, action: 0 });
          }
        }}
        PRIMARY_COLOR={props.topbarTheme.buttonColor}
        onCancel={() => {
          setActionAlert(false);
        }}
        confirmMessage={
          isActionAlert.action == "archive"
            ? isArchived
              ? "unarchive"
              : "archive"
            : "leave"
        }
        messageType={"Project"}
      />
    </div>
  );
};

export default SettingsTab;
