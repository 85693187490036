import React, { useEffect, useRef, useState } from "react";
import {
  inviteCollaborators,
  removeCollaborators,
  resendInviteCollaborators
} from "helper/services/businessCollaboratorsServices";
import { useToasts } from "react-toast-notifications";
import ChipInput from "material-ui-chip-input";
import ToastMessage from "components/notifications/ToastMessage";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import { bin } from "helper/constant";
import { edit } from "helper/constant";
import RoyTooltip from "components/common/RoyTooltip";
import profileModelAction from "redux/profileModel/actions";
import Loader from "components/loader/Loader";
import CustomAlert from "util/CustomAlert";
import { inviteuser } from "helper/constant";
import ResendInvitation from "./ResendInvitation";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
const { open_profile_modal } = profileModelAction;

const MemberTab = props => {
  const { addToast } = useToasts();
  const {
    id,
    collaboratorArr,
    setCollaboratorArr,
    isOwner,
    currentLoggedInUser,
    handleResponse,
    open_workpsace_alert,
    workspaceName
  } = props;
  const [enterCount, setEnterCount] = useState(0);
  const [chipCount, setChipCount] = useState(0);
  const [isAddParticipant, setIsAddParticipant] = useState(false);
  const [isEditBusiness, setIsEditBusiness] = useState(false);
  const [isParticipantsLoader, setIsParticipantsLoader] = useState(false);
  const [addParticipant, setAddParticipant] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState(false);
  //   const [collaboratorArr, setCollaboratorArr] = useState([]);
  const [isUserInput, setIsUserInput] = useState(false);
  const [isAddParticipantLoader, setIsAddParticipantLoader] = useState(false);

  const [isDefaultBusiness, setIsDefaultBusiness] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [isEditBusinessName, setIsEditBusinessName] = useState(false);
  const [isEditBusinessLoader, setIsEditBusinessLoader] = useState(false);
  const [isDeleteProfilePic, setIsDeleteProfilePic] = useState(false);
  const [
    workspaceRemoveCollaborator,
    setWorkspaceRemoveCollaborator
  ] = useState("");

  const [
    validateEmailAndCollaborator,
    setValidateEmailAndCollaborator
  ] = useState({
    status: false,
    message: ""
  });
  const [dropdownInputValue, setDropdownInputValue] = useState("");

  const inputref = useRef(addParticipant);
  useEffect(() => {
    // inputref.current && inputref.current.focus();
    if (inputref.current) inputref.current = addParticipant;
  }, [inputref]);

  const [isRemoveLoader, setIsRemoveLoader] = useState({
    id: "",
    status: false
  });
  const [isDeleteAlert, setDeleteAlert] = useState({
    id: "",
    status: false
  });

  const handleAddParticipant = async e => {
    if (addParticipant && addParticipant.length > 0) {
      setIsAddParticipantLoader(true);
      const response = await inviteCollaborators({
        business_id: id,
        recipient_email: addParticipant
      });
      if (response) {
        if (response.error) {
          handleResponse(response.error.message);
          showNotifications(response.error.message, "error");
          setIsAddParticipantLoader(false);
        }
        if (response.message) {
          var newData = [...collaboratorArr];

          addParticipant.map(data =>
            newData.push({
              id: "",
              role: "COLLABORATOR",
              requestStatus: "SENT",
              requestedEmail: data,
              user: null
            })
          );

          if (newData) {
            console.log([...newData]);
            setCollaboratorArr([...newData]);
          }
          setAddParticipant([]);
          setIsAddParticipantLoader(false);
          setIsAddParticipant(false);
          showNotifications(response.message, "success");
          setEnterCount(0);
          setChipCount(0);
        }
      }
    }
  };
  const handleResendInvitation = async (
    collabId,
    startLoader,
    stopLoader,
    doUpdateHasInvitationSent
  ) => {
    try {
      if (id && collabId) {
        startLoader();
        const response = await resendInviteCollaborators({
          collaboration_id: collabId,
          business_id: id
        });
        if (response) {
          if (response.error) {
            handleResponse(Response.error.message);
            showNotifications();
            stopLoader();
            doUpdateHasInvitationSent(false);
          }
          if (response.message) {
            showNotifications(response.message, "success");
            stopLoader();
            doUpdateHasInvitationSent(true);
          }
        }
      }
    } catch (e) {
      stopLoader();
      doUpdateHasInvitationSent(true);
    }
  };
  const ParticipantType = (status, role) => {
    if (role.toLowerCase() == "owner") {
      return (
        <span
          style={{
            borderRadius: "15",
            color: "white",
            padding: "2px 10px",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: props.themeChanger.topbarTheme.buttonColor
              ? props.themeChanger.topbarTheme.buttonColor
              : "black"
          }}
          className="c-btn-round ma-2 fs-10"
        >
          <IntlMessages id={"projectdetails.owner"} />
        </span>
      );
    }
    if (
      role.toLowerCase() == "collaborator" &&
      status.toLowerCase() == "accepted"
    ) {
      // return (
      //   <span
      //     style={{
      //       borderRadius: "15",
      //       color: "white",
      //       padding: 6,
      // textAlign: "center",
      // justifyContent: "center"
      //       backgroundColor: "rgb(167, 167, 167)",
      //     }}
      //     className="c-btn-round ma-5 fs-10"
      //   >
      //     {role.toLowerCase()}
      //   </span>
      // );
    }
    if (
      role.toLowerCase() == "collaborator" &&
      status.toLowerCase() == "sent"
    ) {
      return (
        <span
          style={{
            borderRadius: "15",
            color: "#313131",
            padding: "2px 10px",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "rgb(222 222 222)"
          }}
          className="c-btn-round ma-5 fs-10"
        >
          <IntlMessages id={"projectdetails.request"} />{" "}
          <IntlMessages id={"projectdetails.sent"} />
        </span>
      );
    }
    if (
      role.toLowerCase() == "collaborator" &&
      status.toLowerCase() == "rejected"
    ) {
      return (
        <span
          style={{
            borderRadius: "15",
            color: "white",
            padding: "2px 10px",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "red"
          }}
          className="c-btn-round ma-5 fs-10"
        >
          <IntlMessages id={"projectdetails.rejected"} />
        </span>
      );
    }
  };
  const handleSpaceComma = e => {
    if (e.keyCode == 188 || e.keyCode == 32) {
      setDropdownInputValue("");
    }
  };
  const ErrorEmailValidation = () => {
    let spaceremove = dropdownInputValue.substr(0, 1);
    if (spaceremove == " " || spaceremove == ",") {
      setDropdownInputValue("");
    }
    return (
      // <span
      // style={{ color: "red", paddingLeft: 3 }}

      //   className={"fs-14" ,props.class ? props.class : "error-msg"}
      // >
      //   Please enter valid email example@email.com
      // </span>
      <span style={{ color: "red", paddingLeft: 3 }} className="fs-14">
        {<IntlMessages id={"project.please_enter_valid"} />}
      </span>
    );
  };
  const handleRemoveParticipant = async participantId => {
    if (participantId) {
      try {
        setIsRemoveLoader({
          id: participantId,
          status: true
        });
        const Response = await removeCollaborators({
          collaboration_id: participantId
        });
        if (Response) {
          if (Response.error) {
            handleResponse(Response.error.message);
            showNotifications();
            setIsRemoveLoader({
              id: "",
              status: false
            });
          }
          if (Response.message) {
            setIsRemoveLoader({
              id: "",
              status: false
            });
            showNotifications(Response.message, "success");
            var checkArr = [...collaboratorArr];

            checkArr.map((data, i) => {
              if (data.id == participantId) {
                checkArr.splice(i, 1);
              }
            });

            setCollaboratorArr([...checkArr]);
            if (!isOwner) {
              open_workpsace_alert();
            }
          }
        }
      } catch (e) {
        setIsRemoveLoader({
          id: "",
          status: false
        });
      }
    }
  };
  const handleShowError = emailid => {
    if (emailid.length > 0) {
      let spaceremove = dropdownInputValue.substr(0, 1);
      if (spaceremove == " " || spaceremove == ",") {
        setDropdownInputValue("");
      }
      let givenEmail = handleCharactersValidation(emailid);
      if (isEmailValidate(givenEmail)) {
        if (isEmailAlreadyParticipant(givenEmail)) {
          setValidateEmailAndCollaborator({
            status: true,
            message: <IntlMessages id={"workspace.email_collaborator"} />
          });
        } else if (isEmailExist(givenEmail)) {
          setValidateEmailAndCollaborator({
            status: true,
            message: (
              <IntlMessages id={"project.project_email_is_already_present"} />
            )
          });
        } else {
          setValidateEmailAndCollaborator({
            status: false,
            message: ""
          });
        }
      } else {
      }
    } else {
    }
  };
  const handleChangeChip = chip => {
    if (!isEmailAlreadyParticipant(chip) && !isEmailExist(chip)) {
      if (addParticipant) {
        setIsSendButtonDisabled(false);
      } else {
        setIsSendButtonDisabled(true);
      }
      setIsUserInput(true);
      setChipCount(addParticipant.length + 1);
      setAddParticipant([...addParticipant, chip]);
      let spaceremove = dropdownInputValue.substr(0, 1);
      if (spaceremove == " " || spaceremove == ",") {
        setDropdownInputValue("");
      }
      const start = 0;
      let end = dropdownInputValue.length;
      let isCheckEmailValidation = dropdownInputValue.substr(start, end);
      if (chip == isCheckEmailValidation) {
        return setDropdownInputValue("");
      } else {
        return "no";
      }
    } else if (isEmailAlreadyParticipant(chip)) {
      setEnterCount(0);
      // showNotifications("Email is already collaborator ", "error");
      // setValidateEmailAndCollaborator({
      //   status: true,
      //   message: "Email is already collaborator "
      // })
    } else if (isEmailExist(chip)) {
      setEnterCount(0);
      // showNotifications("Email is already present ", "error");
      // setValidateEmailAndCollaborator({
      //   status: true,
      //   message: "Email is already present "
      // })
    } else {
      setEnterCount(0);
      // showNotifications("Email is already collaborator", "error");
      // setValidateEmailAndCollaborator({
      //   status: true,
      //   message: "Please enter valid email example@email.com "
      // })
    }
  };
  const isEmailExist = chip => {
    var status = false;
    addParticipant.map(data => {
      if (data === chip) {
        status = true;
      }
    });
    return status;
  };

  const isEmailValidate = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const handleEmailValidation = email => {
    setIsUserInput(true);

    let givenEmail = handleCharactersValidation(email).toLowerCase();
    if (isEmailValidate(givenEmail)) {
      setIsEmailValid(true);
      handleChangeChip(givenEmail);
    } else {
      setIsEmailValid(false);
    }
  };
  const handleDeleteChip = (email, index) => {
    var emailArr = [...addParticipant];
    emailArr.splice(index, 1);
    setChipCount(emailArr?.length);
    setAddParticipant(emailArr);
  };

  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };
  // remove spaces and comma which are entered by user tio generate chips
  const handleCharactersValidation = email => {
    let givenEmail = email;
    if (removeComma(givenEmail)) {
      givenEmail = removeComma(email);
    }
    givenEmail = removeSpace(givenEmail);
    return givenEmail;
  };
  const removeSpace = email => {
    return email.replace(/\s/g, "");
  };
  const removeComma = email => {
    if (email.includes(",")) {
      const checkIndex = email.indexOf(",");
      if (checkIndex > -1) {
        let givenEmail = email;
        givenEmail = email.slice(checkIndex + 1, email.length);
        return givenEmail;
      }
      return 0;
    }
  };
  const isEmailAlreadyParticipant = email => {
    const participant = collaboratorArr;
    var isExist = false;
    participant.map(data => {
      if (
        data.user &&
        email == data.user.email &&
        data?.requestStatus == "ACCEPTED"
      ) {
        isExist = true;
      }
      if (
        !data.user &&
        data.requestedEmail == email &&
        data?.requestStatus == "ACCEPTED"
      ) {
        isExist = true;
      }
    });

    return isExist;
  };
  return (
    // <BusinessWrapper>
    <div>
      <div className="tab-header">
        <div className="project-header-heading">{/*Members*/}</div>
      </div>
      {collaboratorArr &&
        collaboratorArr.map((data, i) => {
          if (data && data?.role?.toLowerCase() == "owner") {
            return (
              <div
                className="flex-x pt-10 pb-20 border-bottom"
                style={{ cursor: "pointer" }}
                key={i}
              >
                <div>
                  {data.user?.userMeta ? (
                    <img
                      alt=""
                      className="img-sm"
                      src={data.user?.userMeta.thumbnail}
                      onClick={() => {
                        props.open_profile_modal({ profile: data.user });
                      }}
                    />
                  ) : (
                    <div
                      style={profileBackground(
                        data.user
                          ? data.user.name
                          : data.requestedEmail
                          ? data.requestedEmail
                          : ""
                      )}
                      // style={{
                      //   borderRadius: "50%",
                      //   border: "1 solid",
                      //   width: 36,
                      //   background: "#F86B5E",
                      //   height: 36,
                      //   objectFit: "fill",
                      //   textAlign: "center",
                      //   color: "white",
                      //   fontSize: 16,
                      //   display: "flex",
                      //   justifyContent: "center",
                      //   alignItems: "center"
                      //   // paddingTop: "3px"
                      // }}
                      onClick={() => {
                        props.open_profile_modal({ profile: data.user });
                      }}
                    >
                      {data.user
                        ? data.user.name.charAt(0).toUpperCase()
                        : data.requestedEmail.charAt(0).toUpperCase()
                        ? data.requestedEmail.charAt(0).toUpperCase()
                        : ""}
                    </div>
                  )}
                </div>
                <div className="mlr-10 flex-1">
                  <p
                    className=" fs-14 demi-bold-text "
                    style={{ marginBottom: "-3px", color: "black" }}
                  >
                    {data?.user?.name ? data?.user?.name : ""}{" "}
                    <small
                      className="text-muted bold-text text-muted"
                      style={{}}
                    >
                      {" "}
                      {/* {`(${data.role.toLowerCase()})`} */}
                      {ParticipantType(data.requestStatus, data.role)}
                    </small>
                  </p>
                  <small
                    className="text-muted fs-14 "
                    style={{ color: "#808080" }}
                  >
                    {data?.user?.email ? data?.user?.email : " "}
                  </small>
                </div>
              </div>
            );
          }
        })}
      {collaboratorArr &&
        collaboratorArr.map((data, i) => {
          if (
            data.role.toLowerCase() !== "owner" &&
            data.requestStatus.toLowerCase() !== "rejected"
          ) {
            if (data && data.requestStatus == "SENT" && data.requestedEmail) {
              return (
                <div
                  className="flex-x ptb-10 border-bottom mt-9"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                >
                  <div>
                    <div
                      style={profileBackground(
                        data.user
                          ? data.user.name
                          : data.requestedEmail
                          ? data.requestedEmail
                          : ""
                      )}
                      onClick={() =>
                        props.open_profile_modal({ profile: data.user })
                      }
                    >
                      {data.user
                        ? data.user.name.charAt(0).toUpperCase()
                        : data.requestedEmail
                        ? data.requestedEmail.charAt(0).toUpperCase()
                        : ""}{" "}
                    </div>
                  </div>
                  <div className="mlr-10 flex-1">
                    <p
                      className=" fs-14 demi-bold-text "
                      style={{ marginBottom: "-3px" }}
                    >
                      <small className="fs-14 demi-bold-text">
                        {data.requestedEmail
                          ? data.requestedEmail.substr(
                              0,
                              data.requestedEmail.indexOf("@")
                            )
                          : ""}{" "}
                        {/* {`(${data.role.toLowerCase()})`} */}
                        {ParticipantType(data.requestStatus, data.role)}
                      </small>
                    </p>
                    <small className="text-muted fs-12">
                      {data.requestedEmail ? data.requestedEmail : ""}
                    </small>
                  </div>
                  {data.requestStatus.toLowerCase() === "sent" && data.id && (
                    <ResendInvitation
                      PRIMARY_COLOR={props.themeChanger.topbarTheme.buttonColor}
                      id={data.id}
                      doResendInvitation={handleResendInvitation}
                    />
                  )}
                  {isOwner && data.requestStatus.toLowerCase() !== "sent" && (
                    <>
                      {isRemoveLoader.participantId == data.id &&
                      isRemoveLoader.status ? (
                        <Loader />
                      ) : (
                        <RoyTooltip
                          id={"remove-" + data.id}
                          title="Remove collaborator"
                        >
                          <small
                            id={"remove-" + data.id}
                            className="  pt-2 plr-5 cursor-pointer hover-underline underlinetextStyle"
                            style={{
                              color: `${props.themeChanger.topbarTheme.buttonColor}`
                            }}
                            onClick={
                              () =>
                                setDeleteAlert({
                                  id: data.id,
                                  status: true
                                })
                              // handleRemoveParticipant(data.id)
                            }
                          >
                            <i className="fas fa-trash test-muted" />
                          </small>
                        </RoyTooltip>
                      )}
                    </>
                  )}
                </div>
              );
            }

            if (data && data.requestStatus == "SENT" && data.user) {
              // console.log(data.user)
              return (
                <div
                  className="flex-x ptb-10 border-bottom mt-9"
                  style={{ cursor: "pointer" }}

                  // onClick={() => {
                  //   props.open_profile_modal({
                  //     profile: data.user
                  //   });
                  // }}
                >
                  <div>
                    <div
                      style={profileBackground(
                        data.user
                          ? data.user.name
                          : data.requestedEmail
                          ? data.requestedEmail
                          : ""
                      )}
                      onClick={() => {
                        console.log(12345678);
                      }}
                    >
                      {data.user
                        ? data.user.name.charAt(0).toUpperCase()
                        : data.requestedEmail
                        ? data.requestedEmail.charAt(0).toUpperCase()
                        : ""}{" "}
                    </div>
                  </div>
                  <div className="mlr-10 flex-1">
                    <p
                      className=" fs-14 demi-bold-text "
                      style={{ marginBottom: "-3px" }}
                    >
                      {data?.user?.name ? data?.user?.name : ""}{" "}
                      <small
                        className="text-muted bold-text text-muted"
                        style={{}}
                      >
                        {" "}
                        {/* {`(${data.role.toLowerCase()})`} */}
                        {ParticipantType(data.requestStatus, data.role)}
                      </small>
                    </p>
                    <small className="text-muted fs-12 " style={{}}>
                      {data?.user?.email ? data?.user?.email : " "}
                    </small>
                  </div>
                  {data.requestStatus.toLowerCase() === "sent" && data.id && (
                    <ResendInvitation
                      PRIMARY_COLOR={props.themeChanger.topbarTheme.buttonColor}
                      id={data.id}
                      doResendInvitation={handleResendInvitation}
                    />
                  )}
                </div>
              );
            }
            if (data && data.requestStatus == "ACCEPTED") {
              return (
                <div
                  className="flex-x ptb-10 border-bottom mt-9"
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    {data.user?.userMeta ? (
                      <img
                        alt=""
                        className="img-sm"
                        src={data.user?.userMeta.thumbnail}
                        onClick={() => {
                          props.open_profile_modal({ profile: data.user });
                        }}
                      />
                    ) : (
                      <>
                        <div
                          style={profileBackground(
                            data.user
                              ? data.user.name
                              : data.requestedEmail
                              ? data.requestedEmail
                              : ""
                          )}
                          onClick={() => {
                            props.open_profile_modal({
                              profile: data && data.user
                            });
                          }}
                        >
                          {data.user
                            ? data.user.name.charAt(0).toUpperCase()
                            : data.requestedEmail
                            ? data.requestedEmail.charAt(0).toUpperCase()
                            : ""}{" "}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mlr-10 flex-1">
                    <p
                      className=" fs-14 demi-bold-text  "
                      style={{ marginBottom: "-3px" }}
                    >
                      {data?.user?.name ? data?.user?.name : " "}{" "}
                      <small
                        className="text-muted bold-text text-muted"
                        style={{}}
                      >
                        {" "}
                        {/* {`(${data.role.toLowerCase()})`} */}
                        {ParticipantType(data.requestStatus, data.role)}
                      </small>
                    </p>
                    <small className="text-muted fs-12 " style={{}}>
                      {data?.user?.email ? data?.user?.email : " "}
                    </small>
                  </div>
                  {data.requestStatus.toLowerCase() !== "sent" && (
                    <>
                      {" "}
                      {isRemoveLoader.participantId == data.id &&
                      isRemoveLoader.status ? (
                        <Loader />
                      ) : (
                        <RoyTooltip
                          id={"remove" + data.id}
                          title={`${
                            isOwner
                              ? InjectPlaceHolderMessage(
                                  "archived.remove_collaborator",
                                  props?.LanguageSwitcher?.language?.languageId
                                )
                              : InjectPlaceHolderMessage(
                                  "workspace.leaveworkspace",
                                  props?.LanguageSwitcher?.language?.languageId
                                )
                          } `}
                        >
                          <small
                            id={"remove" + data.id}
                            className="  pt-2 plr-5 cursor-pointer  member-remove "
                            style={{
                              color: `${props.themeChanger.topbarTheme.buttonColor}`
                            }}
                            onClick={() => {
                              setWorkspaceRemoveCollaborator(data.user.name);
                              // handleRemoveParticipant(data.id)
                              setDeleteAlert({
                                id: data.id,
                                status: true
                              });
                            }}
                          >
                            {/* <i className={`${isOwner ? 'fas fa-trash':'fas fa-trash'} test-muted fs-12`} /> */}
                            <span className="hover-underline">
                              {isOwner ? (
                                <IntlMessages id={"projectdetails.remove"} />
                              ) : (
                                currentLoggedInUser?.id == data?.user.id && (
                                  <IntlMessages id={"projectdetails.leave"} />
                                )
                              )}
                            </span>
                          </small>
                        </RoyTooltip>
                      )}{" "}
                    </>
                  )}
                </div>
              );
            }
          }
        })}
      {/* {isOwner && ( */}

      {isAddParticipant ? (
        <>
          <h3
            className="mb-0 fs-14 header"
            style={{
              marginTop: 20,
              display: "flex",
              alignItems: "flex-end"
            }}
          >
            <IntlMessages id={"workspace.addcollaborator(s)_button"} />
            <RoyTooltip
              id={`addParticipantsInfor`}
              // title="Press comma, enter or space to add multiple email addresses"
              title={InjectPlaceHolderMessage(
                "project.project_add_multiple_emails_by_pressing_comma",
                props?.LanguageSwitcher?.language?.languageId
              )}
            >
              <i
                className="fa fa-info-circle ml-2"
                style={{ color: "grey" }}
                id={`addParticipantsInfor`}
              ></i>
            </RoyTooltip>
          </h3>
          <div className="pb-0" style={{ paddingTop: 10 }}>
            <ChipInput
              ref={inputref}
              value={addParticipant}
              autoFocus
              // helperText="Press enter to add multiple email addresses"
              id="participantemail"
              name="participantemail"
              onBeforeAdd={handleEmailValidation}
              onChange={chips => {
                handleChangeChip(chips);
              }}
              onKeyUp={handleSpaceComma}
              inputValue={dropdownInputValue}
              onUpdateInput={e => {
                if (
                  e.target.value.length == 0 ||
                  e.target.value == " " ||
                  e.target.value == ","
                ) {
                  setIsEmailValid(true);
                }
                handleShowError(e.target.value);
                setDropdownInputValue(e.target.value);
              }}
              clearInputValueOnChange={true}
              onDelete={(chip, index) => {
                handleDeleteChip(chip, index);
              }}
              // enter,comma,space to add new chips
              newChipKeyCodes={[13, 32, 188, 9]}
              style={{
                width: "100% "
              }}
              placeholder={InjectPlaceHolderMessage(
                "project.example1@email.com",
                props?.LanguageSwitcher?.language?.languageId
              )}
            />
            {!isEmailValid && isUserInput ? (
              <ErrorEmailValidation field="name" />
            ) : (
              ""
            )}
            {validateEmailAndCollaborator.status && (
              <span style={{ color: "red", paddingLeft: 3 }} className="fs-14">
                {validateEmailAndCollaborator.message}
              </span>
            )}
          </div>
          {isAddParticipantLoader ? (
            <div className="flex-x ptb-15 ">
              <Loader />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse"
                }}
              >
                <div
                  className="ptb-5 plr-5 "
                  style={{ alignItems: "flex-end" }}
                >
                  <button
                    className="btn button plr-5"
                    style={{
                      textTransform: "none ",
                      color: "white",
                      fontSize: "14px"
                    }}
                    disabled={!addParticipant?.length}
                    onClick={handleAddParticipant}
                  >
                    <IntlMessages id={"workspace.sendinvitation_button"} />
                  </button>
                </div>
                <div className="ptb-5">
                  <button
                    className="btn btn-secondary plr-5"
                    style={{
                      textTransform: "none ",
                      color: "black",
                      background: "transparent",
                      border: 0,
                      fontSize: "14px"
                    }}
                    onClick={() => {
                      setDropdownInputValue("");
                      setIsAddParticipant(!isAddParticipant);
                    }}
                  >
                    <IntlMessages id={"globalquickaddactions.cancelbutton"} />
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="text-center pt-100 pb-15">
          <button
            onClick={() => setIsAddParticipant(!isAddParticipant)}
            className="btn "
            style={{
              backgroundColor: props.topbarTheme.buttonColor,
              color: "#FFF",
              width: 280,
              height: 36,
              borderRadius: "5px"
            }}
          >
            <img
              alt=""
              src={inviteuser}
              height="15px"
              width="15px"
              style={{ paddingBottom: 2, height: 15, width: 15 }}
            />{" "}
            <span style={{ fontSize: 12 }}>
              {" "}
              <IntlMessages id={"workspace.addcollaborator(s)_button"} />
            </span>
          </button>
        </div>
      )}
      <CustomAlert
        CustomMessage={isOwner ? workspaceRemoveCollaborator : workspaceName}
        PRIMARY_COLOR={props.topbarTheme.buttonColor}
        action={isOwner ? "Remove" : "Leave"}
        confirmMessage={isOwner ? "Remove" : "Leave"}
        show={isDeleteAlert.status}
        onConfirm={() => {
          if (isDeleteAlert.id) {
            handleRemoveParticipant(isDeleteAlert.id);
            setDeleteAlert({ id: "", status: false });
          }
        }}
        // PRIMARY_COLOR={props.topbarTheme.buttonColor}
        onCancel={() => {
          setDeleteAlert({ id: "", status: false });
        }}
        messageType={isOwner ? "collaborator" : "workspace"}
      />
    </div>
    //   </BusinessWrapper>
  );
};

export default connect(null, { open_profile_modal })(MemberTab);
