import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import {
  PtrojectPopOverArchiveImage,
  PtrojectPopOverEditImage,
  PtrojectPopOverFavouriteImage,
  PtrojectPopOverMoveImage,
  PtrojectPopOverShareImage
} from "helper/constant";
import ProjectMoreOptionsWrapper from "./ProjectMoreOptionWrapper.style";
import IntlMessages from "util/intlMessages";
const MoreOptionsMenu = ({
  isOpen,
  projectmenuID,
  moreProjectOptions,
  setMoreProjectOptions,
  toggleEditProject,

  updateViewOptions,
  setSectionCardFlag,
  deleteProject,
  leaveProject,
  isOwner,
  workspaceCount,
  isArchived,
  popoverTarget,
  toggle,
  updateArchiveProject,
  isFavorite,
  updateisFavoriteProject,
  setInviteCollaborators,
  isInviteCollaborators,
  toggleMoveProject,
  projectData
}) => {
  return (
    <div>
      {isOpen ? (
        <ProjectMoreOptionsWrapper>
          <Popover
            hideArrow={true}
            trigger="legacy"
            className="roy-menu"
            innerClassName="roy-inner-content"
            placement="bottom-start"
            target={popoverTarget}
            isOpen={isOpen}
            toggle={toggle}
          >
            <PopoverBody style={{ padding: 15 }}>
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="roy-menu-list"
                onClick={e => {
                  e.stopPropagation();
                  toggle();
                  toggleEditProject(projectData);
                }}
              >
                <img
                  alt="edit project"
                  src={PtrojectPopOverEditImage}
                  style={{
                    height: 15,
                    width: 15
                  }}
                ></img>
                <span style={{ marginLeft: 15, fontSize: 14, color: "black" }}>
                  {<IntlMessages id={"project.edit_project"} />}
                </span>
              </div>
              {isOwner && workspaceCount > 1 && (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="roy-menu-list"
                  onClick={e => {
                    e.stopPropagation();
                    toggle();
                    toggleMoveProject();
                  }}
                >
                  <img
                    alt="move proejct"
                    src={PtrojectPopOverMoveImage}
                    style={{
                      height: 15,
                      width: 15
                    }}
                  ></img>
                  <span
                    style={{ marginLeft: 15, fontSize: 14, color: "black" }}
                  >
                    {<IntlMessages id={"project.move_project"} />}
                  </span>
                </div>
              )}
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="roy-menu-list"
                onClick={e => {
                  e.stopPropagation();
                  toggle();
                  setInviteCollaborators({ status: true });
                }}
              >
                <img
                  alt="share project"
                  src={PtrojectPopOverShareImage}
                  style={{
                    height: 15,
                    width: 15
                  }}
                ></img>
                <span style={{ marginLeft: 15, fontSize: 14, color: "black" }}>
                  {<IntlMessages id={"project.share_project"} />}
                </span>
              </div>
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="roy-menu-list"
                onClick={e => {
                  e.stopPropagation();
                  toggle();
                  updateisFavoriteProject();
                }}
              >
                <img
                  alt="favourite project"
                  src={PtrojectPopOverFavouriteImage}
                  style={{
                    height: 15,
                    width: 15
                  }}
                ></img>
                <span style={{ marginLeft: 15, fontSize: 14, color: "black" }}>
                  {" "}
                  {isFavorite ? (
                    <IntlMessages id={"project.removefrom_favourite"} />
                  ) : (
                    <IntlMessages id={"project.addto_favourite"} />
                  )}
                </span>
              </div>
              {/* <div
            className="roy-menu-list"
            onClick={updateViewOptions}
          >{`View as ${
            viewMode && viewMode !== null && viewMode == "list"
              ? "Grid"
              : "List"
          }`}</div> */}
              {isOwner && (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="roy-menu-list"
                  onClick={e => {
                    e.stopPropagation();
                    toggle();
                    updateArchiveProject();
                  }}
                >
                  <img
                    alt=""
                    src={PtrojectPopOverArchiveImage}
                    style={{
                      height: 15,
                      width: 15
                    }}
                  ></img>
                  <span
                    style={{ marginLeft: 15, fontSize: 14, color: "black" }}
                  >
                    {isArchived ? (
                      <IntlMessages id={"project.unarchive_project"} />
                    ) : (
                      <IntlMessages id={"project.archive_project"} />
                    )}
                  </span>
                </div>
              )}

              {/* {!isOwner && (
            <>
              <div
                className="roy-menu-list"
                style={{ color: "red" }}
                onClick={() => leaveProject()}
              >
                <i className="fa fa-sign-out" />
                UnArchive Project
              </div>
            </>
          )} */}
              {/* <hr />
          <div className="roy-menu-list" onClick={() => deleteProject()}>
            Delete Project
          </div> */}
            </PopoverBody>
          </Popover>
        </ProjectMoreOptionsWrapper>
      ) : (
        ""
      )}
    </div>
  );
};

export default MoreOptionsMenu;
