import React from "react";
import { repeatTypeOptions } from "util/data/taskDetailsutilData";
import { ListGridCircleImage, datesnewmodal } from "helper/constant";
import { ReactComponent as RepeatSvgIcon } from "assets/images/recurrenceIcon.svg";
import { getPriorityPath } from "../../constatnt";
import moment from "moment";
const AddedTask = ({ task, actor, project, handleRedirection }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column"
      }}
    >
      <div
        className="task-activity-addtask-title fs-14"
        style={{
          fontWeight: "600",
          fontSize: 14
        }}
      >
        On&nbsp;
        <span
          style={{
            color: "#1b6ac9",
            textDecoration: "underline",
            cursor: "pointer"
          }}
          onClick={() => {
            handleRedirection({
              redirection_type: "project",
              project: project
            });
          }}
        >
          {" "}
          <span>{project && project?.name}</span>
          {/* This to-do is for testing perpose{" "} */}
        </span>
        , {actor && actor?.name} added
      </div>
      <div
        className="fs-13"
        style={{
          // display: "flex",
          cursor: "pointer",
          alignItems: "center"
        }}
        onClick={() => {
          handleRedirection({
            redirection_type: "task",
            task: task,
            project: project
          });
        }}
      >
        <img
          alt=""
          src={ListGridCircleImage}
          style={{
            height: 16,
            width: 16,
            marginRight: 5,
            marginBottom: 2
          }}
        ></img>
        <span className="fs-13" style={{ wordBreak: "break-all" }}>
          {" "}
          {task && task?.title}
        </span>

        {task.scheduledAt && (
          <span style={{ paddingLeft: "5px" }} className="fs-13">
            <img
              alt=""
              src={datesnewmodal}
              height="15px"
              width="15px"
              style={{ marginRight: 5, marginLeft: 5, marginBottom: 5 }}
            />
            {!task.repeatTill &&
              task?.repeatType !== repeatTypeOptions[0]?.value && (
                <RepeatSvgIcon
                  style={{
                    fill: "green",
                    height: "20px",
                    width: "20px",
                    marginRight: 5
                  }}
                />
              )}
            {/* Date */}
            {moment(task.scheduledAt).format("DD MMM YYYY")}
          </span>
        )}
        {task.priority && (
          <span className="pl-0">
            <img
              src={getPriorityPath(task.priority)}
              height="16"
              width="16"
              alt="Priority"
              style={{ marginLeft: 5 }}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default AddedTask;
