const updateProjectActions = {
  DO_UPDATE_PROJECT: "DO_UPDATE_PROJECT",
  CLOSE_UPDATE_PROJECT: "CLOSE_UPDATE_PROJECT",

  do_update_project: data => {
    return {
      type: updateProjectActions.DO_UPDATE_PROJECT,
      data: true
    };
  },
  close_update_project: () => {
    return {
      type: updateProjectActions.CLOSE_UPDATE_PROJECT
    };
  }
};
export default updateProjectActions;
