/** @format */

import React, { useState, useRef, useCallback } from "react";
import { UncontrolledPopover, PopoverBody, Row, Col } from "reactstrap";
import { UncontrolledCollapse, Button, CardBody, Card } from "reactstrap";
import _ from "lodash";
import { allPriority } from "constatnt";
import { connect } from "react-redux";
import { retrieveBusinessFilter } from "helper/services/globalSearchServices";
import {
  searchNotFound,
  CrossSearchPopoverChips,
  filternew,
  WorkspacePopoverCrossIcon,
  SidebarDownArrow,
  SidebarUpArrow
} from "helper/constant";
import HeaderPopover from "./HeaderPopover.style";
import { getUserCharColor } from "helper/methods/PlaceholderColor";
import Shimmer from "components/shimmer/Shimmer";
import { getBusinessList } from "helper/services/businessServices";
import Loader from "components/loader/Loader";
import SearchPopoverWrapper from "./SearchPopoverWrapper.style";
import LinearLoader from "components/loader/LinearLoader";
import IntlMessages from "util/intlMessages";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";

function SearchPopover(props) {
  const {
    isSearchPopover,
    searchResult,
    searchQuery,
    setIsSearchPopover,
    getSerchQueryLength,
    history,
    isFetching,
    viewMoreTasks,
    viewMoreProjects,
    viewMoreWorkspaces,
    viewMoreTasksLoader,
    viewMoreWorkspacesLoader,
    viewMoreProjectsLoader,
    closeSearchPopover,
    currentWorkspace,
    projects,
    handleRedirection,
    setFilterSearch,
    topbarTheme,
    doSearch,
    workspaces,
    setWorkspaces,
    priority,
    setPriority,
    filterDates,
    setFilterDates,
    filterprojects,
    setFilterProjects,
    filterLabels,
    setFilterLabels,
    getTotalAppliedCount,
    getIds,
    getPriorityIds,
    getDatetypes,
    isFilter,
    setIsFilter,
    doClearResultOnResetFilter
  } = props;

  const [isFilterLoader, setIsFilterLoader] = useState(false);
  const [isAppliedFilter, setIsAppliedFilter] = useState(true);
  const [isToggle, setIsToggle] = useState({
    Workspaces: true,
    Projects: true,
    Priority: true,
    Date: true,
    Label: true
  });

  const handleApplyFilter = (isToggle = true) => {
    // isToggle for to check if we want to toggle or not after apply filter by default true
    var prjectsIds = getIds([...filterprojects]);
    var labelsIds = getIds([...filterLabels]);
    var workspaceIds = getIds([...workspaces]);
    var priorityId = getPriorityIds([...priority]);
    var dates = getDatetypes({ ...filterDates });
    setFilterSearch({
      business_ids: workspaceIds.ids || "",
      label_ids: labelsIds.ids || "",
      project_ids: prjectsIds.ids || "",
      priority: priorityId.ids || ""

      // date_filter: dates || []
    });
    doSearch({
      business_ids: workspaceIds.ids || "",
      label_ids: labelsIds.ids || "",
      project_ids: prjectsIds.ids || "",
      priority: priorityId.ids || ""
      // date_filter: dates || []
    });
    if (isToggle) {
      handleToggleFilter();
    }
  };
  const handleResetFilter = () => {
    handleUnselectFlag(workspaces, setWorkspaces);
    handleUnselectFlag(priority, setPriority);
    handleUnselectFlag(filterLabels, setFilterLabels);
    handleUnselectFlag(filterprojects, setFilterProjects);

    setFilterSearch({
      business_ids: "",
      label_ids: "",
      project_ids: "",
      priority: []
      // date_filter: []
    });
    setFilterDates({
      today: false,
      Upcoming: false,
      overdue: false
    });
    doClearResultOnResetFilter();
  };
  const handleToggleFilter = () => {
    if (isFilter) {
      setIsFilter(!isFilter);
      setIsToggle({
        Workspaces: false,
        Projects: false,
        Priority: false,
        Date: false,
        Label: false
      });
    }
    if (!isFilter) {
      // fetchApi
      setPriority([...injectSelectedToArr(allPriority)]);
      retriveBusiness();

      // showLoader
      // after than open collapses
      setIsFilter(!isFilter);
      setIsToggle({
        Workspaces: true,
        Projects: true,
        Priority: true,
        Date: true,
        Label: true
      });
    }
  };

  const doGetProjectsAndLabels = useCallback(
    _.debounce(q => fetchBusinessFilterProjectLabelData(q), 0),
    []
  );

  const fetchBusinessFilterProjectLabelData = async businessIDs => {
    try {
      setIsFilterLoader(true);
      const Result = await retrieveBusinessFilter({
        business_ids: businessIDs
      });
      if (Result) {
        setIsFilterLoader(false);
        if (Result.data) {
          const labels = injectSelectedToArr(Result.data.labels);
          const projects = injectSelectedToArr(Result.data.projects);
          setFilterProjects(projects);
          setFilterLabels(labels);
        }
        setIsFilterLoader(false);
      }
    } catch (e) {}
  };
  const injectSelectedToArr = givenData => {
    if (givenData) {
      const finalData =
        givenData && givenData.map(v => ({ ...v, isSelected: false }));
      return finalData;
    }
  };
  const retriveBusiness = async () => {
    try {
      setIsFilterLoader(true);
      const Response = await getBusinessList();
      if (Response) {
        if (Response.error) {
        }
        if (Response.data) {
          const workspacesArr =
            Response.data &&
            Response.data.map(v => {
              let filterW =
                workspaces && workspaces.filter(data => v.id == data.id);
              if (filterW && filterW.length > 0) {
                return {
                  ...v,
                  isSelected: filterW[0].isSelected
                    ? filterW[0].isSelected
                    : false
                };
              }
              return { ...v, isSelected: false };
            });
          setWorkspaces(workspacesArr);
          setIsFilterLoader(false);
        }
      }
    } catch (e) {
      setIsFilterLoader(false);
    }
  };

  const ButtonChip = ({ name, selected, onClick }) => {
    const propss = {
      ...props,
      selected
    };

    return (
      <SearchPopoverWrapper {...propss}>
        <div
          className="chip-button"
          title={
            selected
              ? InjectPlaceHolderMessage(
                  "globalsearchbar.remove",
                  props?.LanguageSwitcher?.language?.languageId
                )
              : InjectPlaceHolderMessage(
                  "sidebar.select",
                  props?.LanguageSwitcher?.language?.languageId
                )
          }
          onClick={onClick}
          style={{
            fontSize: 12,
            border: selected
              ? `2px solid ${topbarTheme.buttonColor}`
              : "2px solid #E6E6E6",
            borderRadius: 10,
            backgroundColor: selected ? topbarTheme.buttonColor : "transparent",
            color: selected ? "#FFF" : "#585858",
            padding: "4px 8px",
            minWidth: "45px",
            maxWidth: 140,
            display: "inline-block",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 10,
            marginTop: 5,
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                maxWidth: 140,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: selected ? "white" : "#585858",
                position: "relative",
                top: "-1px"
              }}
            >
              {/* {name} */}

              {<IntlMessages id={name} />}
            </div>
            {selected && (
              <img
                alt=""
                className="search"
                style={{
                  height: 14,
                  width: 14,
                  marginLeft: 8
                }}
                src={CrossSearchPopoverChips}
              ></img>
            )}
          </div>
        </div>
      </SearchPopoverWrapper>
    );
  };
  const handleUnselectFlag = (givenArr, setState) => {
    if (givenArr) {
      let dataArr = givenArr;
      givenArr &&
        givenArr.map((check, i) => {
          if (check) {
            let givenData = { ...check };
            if (givenData) {
              givenData = {
                ...givenData,
                isSelected: false
              };
            }
            dataArr[i] = givenData;
          }
        });
      setState([...dataArr]);
    }
  };
  const handleChipSelection = (data, givenArr, setState, callApi) => {
    if (data && givenArr) {
      let dataArr = givenArr;
      givenArr &&
        givenArr.map((check, i) => {
          if (check.id == data.id) {
            let givenData = { ...check };
            if (givenData) {
              givenData = {
                ...givenData,
                isSelected: givenData.isSelected ? false : true
              };
            }
            dataArr[i] = givenData;
          }
        });
      var { ids } = getIds([...dataArr]);
      callApi(ids);
      setState([...dataArr]);
    }
  };
  const handlePrioritySelection = (data, givenArr, setState, callApi) => {
    if (data && givenArr) {
      let dataArr = givenArr;
      givenArr &&
        givenArr.map((check, i) => {
          if (check.name == data.name) {
            let givenData = { ...check };
            if (givenData) {
              givenData = {
                ...givenData,
                isSelected: givenData.isSelected ? !givenData.isSelected : true
              };
            }
            dataArr[i] = givenData;
          }
        });
      var { ids } = getIds([...dataArr]);
      callApi(ids);
      setState([...dataArr]);
    }
  };
  return (
    <HeaderPopover>
      <UncontrolledPopover
        target="searchbox"
        className="header-popover"
        trigger="legacy"
        hideArrow={true}
        placement="bottom"
        style={{
          background: "white",
          borderRadius: "10px",
          boxShadow: "0px 0px 15px #0000001A"
        }}
        isOpen={isSearchPopover}
        toggle={e =>
          isSearchPopover
            ? closeSearchPopover(e)
            : setIsSearchPopover(!isSearchPopover)
        }
      >
        <HeaderPopover>
          <PopoverBody
            className="mail-popover-body"
            style={{
              width: "450px",
              height: "495px"
              // boxShadow: "0px 0px 54px #0000001a"
            }}
          >
            <>
              <div className="fs-13 bold-text mb-10">
                {searchResult && searchResult.length > 0
                  ? getSerchQueryLength(searchResult) +
                    " results found for " +
                    searchQuery
                  : ""}
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#000000",
                    borderBottom: "1px solid #ebebeb",
                    paddingBottom: "10px",
                    fontWeight: "600"
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      color: "#000000",
                      fontWeight: "600"
                    }}
                  >
                    {" "}
                    {!isFilter ? (
                      <IntlMessages id={"globalsearchbar.globalsearch"} />
                    ) : (
                      <>
                        {" "}
                        <IntlMessages
                          id={"globalsearchbar.advancefilter"}
                        />{" "}
                        {`(${getTotalAppliedCount()})`}
                      </>
                    )}
                  </div>
                  <img
                    alt=""
                    title={
                      !isFilter ? (
                        <IntlMessages id={"globalsearchbar.advancefilter"} />
                      ) : (
                        <IntlMessages id={"globalsearchbar.close"} />
                      )
                    }
                    onClick={handleToggleFilter}
                    style={{
                      height: !isFilter ? 17 : 15,
                      width: !isFilter ? 17 : 15,
                      position: "absolute",
                      // right: !isFilter ? 23 : 23,
                      right: 23,

                      top: 23,
                      cursor: "pointer"
                    }}
                    src={!isFilter ? filternew : WorkspacePopoverCrossIcon}
                  />
                  {!getTotalAppliedCount() == 0 && !isFilter ? (
                    <div
                      style={{
                        height: 10,
                        width: 10,
                        borderRadius: "50%",
                        background: topbarTheme.buttonColor,
                        position: "absolute",
                        top: 20,
                        right: 20
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </div>
                {!isFilter ? (
                  isFetching ? (
                    <>
                      <div
                        className="Search-data"
                        style={{
                          height: 430,
                          overflowX: "hidden",
                          minWidth: 270,
                          padding: "0px 25px"
                        }}
                      >
                        {getTotalAppliedCount() > 0 && (
                          <div
                            style={{
                              borderBottom: "1px solid #f3f3f3",
                              paddingBottom: 10,
                              margin: "10px 0px"
                            }}
                          >
                            <Button
                              onClick={() =>
                                setIsAppliedFilter(!isAppliedFilter)
                              }
                              className="btn btn"
                              id="applied_filter"
                              style={{
                                marginBottom: "0px",
                                backgroundColor: "white",
                                width: "100%",
                                color: "#808080",
                                borderTop: "0px solid #E6E6E6",
                                borderLeft: "0px solid #E6E6E6",
                                borderRight: "0px solid #E6E6E6",
                                borderBottom: "0px solid #E6E6E6",
                                borderRadius: 0,
                                padding: "0px 0px 0px 0px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                                fontSize: 13
                              }}
                            >
                              <div>
                                <IntlMessages
                                  id={"globalsearchbar.appliedfilters"}
                                />
                                {getTotalAppliedCount() > 0
                                  ? ` (${getTotalAppliedCount()})`
                                  : " "}
                              </div>
                              <img
                                alt=""
                                style={{ marginLeft: 15 }}
                                src={
                                  isAppliedFilter
                                    ? SidebarUpArrow
                                    : SidebarDownArrow
                                }
                              ></img>
                            </Button>
                            <UncontrolledCollapse
                              isOpen={isAppliedFilter || false}
                              toggler="#applied_filter"
                            >
                              {/* <Card>
                               <CardBody> */}
                              <div
                                style={{
                                  padding: 5,
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: 12,
                                  color: "gray"
                                }}
                              >
                                {getTotalAppliedCount() < 1 &&
                                  "Applied filters will show up here"}
                              </div>
                              <div>
                                {workspaces &&
                                  workspaces
                                    .filter(i => i.isSelected)
                                    .map(data => (
                                      <ButtonChip
                                        name={data.title}
                                        selected={data.isSelected || false}
                                        onClick={() =>
                                          handleChipSelection(
                                            data,
                                            workspaces,
                                            setWorkspaces,
                                            id => handleApplyFilter(false)
                                          )
                                        }
                                      />
                                    ))}

                                {filterprojects &&
                                  filterprojects
                                    .filter(i => i.isSelected)
                                    .map(project => {
                                      return (
                                        <ButtonChip
                                          name={project.name}
                                          selected={project.isSelected || false}
                                          onClick={() =>
                                            handleChipSelection(
                                              project,
                                              filterprojects,
                                              setFilterProjects,
                                              () => {
                                                handleApplyFilter(false);
                                              }
                                            )
                                          }
                                        />
                                      );
                                    })}
                                {filterLabels &&
                                  filterLabels
                                    .filter(i => i.isSelected)
                                    .map(label => {
                                      return (
                                        <ButtonChip
                                          name={label.name}
                                          selected={label.isSelected || false}
                                          onClick={() =>
                                            handleChipSelection(
                                              label,
                                              filterLabels,
                                              setFilterLabels,
                                              () => {
                                                handleApplyFilter(false);
                                              }
                                            )
                                          }
                                        />
                                      );
                                    })}
                                {priority &&
                                  priority
                                    .filter(i => i.isSelected)
                                    .map(prty => {
                                      return (
                                        <ButtonChip
                                          name={prty.value}
                                          selected={prty.isSelected || false}
                                          onClick={() =>
                                            handlePrioritySelection(
                                              prty,
                                              priority,
                                              setPriority,
                                              () => {
                                                handleApplyFilter(false);
                                              }
                                            )
                                          }
                                        />
                                      );
                                    })}
                              </div>
                            </UncontrolledCollapse>
                          </div>
                        )}
                        <Shimmer />
                        <Shimmer />
                      </div>
                      {/* <div style={{ position: "relative", top: "-20px" }}>
                        <div style={{ overflow: "hidden", maxHeight: "100%" }}>
                          
                        </div>
                      </div> */}
                    </>
                  ) : (
                    <div
                      className="Search-data"
                      style={{
                        height: 430,
                        overflowY: "scroll",
                        overflowX: "hidden",
                        minWidth: 270,
                        padding: "0px 25px"
                      }}
                    >
                      {getTotalAppliedCount() > 0 && (
                        <div
                          style={{
                            borderBottom: "1px solid #f3f3f3",
                            paddingBottom: 10,
                            margin: "10px 0px"
                          }}
                        >
                          <Button
                            onClick={() => setIsAppliedFilter(!isAppliedFilter)}
                            className="btn btn"
                            id="applied_filter"
                            style={{
                              marginBottom: "0px",
                              backgroundColor: "white",
                              width: "100%",
                              color: "#808080",
                              borderTop: "0px solid #E6E6E6",
                              borderLeft: "0px solid #E6E6E6",
                              borderRight: "0px solid #E6E6E6",
                              borderBottom: "0px solid #E6E6E6",
                              borderRadius: 0,
                              padding: "0px 0px 0px 0px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "baseline",
                              fontSize: 13
                            }}
                          >
                            <div>
                              <IntlMessages
                                id={"globalsearchbar.appliedfilters"}
                              />
                              {getTotalAppliedCount() > 0
                                ? ` (${getTotalAppliedCount()})`
                                : " "}
                            </div>
                            <img
                              alt=""
                              style={{ marginLeft: 15 }}
                              src={
                                isAppliedFilter
                                  ? SidebarUpArrow
                                  : SidebarDownArrow
                              }
                            ></img>
                          </Button>
                          <UncontrolledCollapse
                            isOpen={isAppliedFilter || false}
                            toggler="#applied_filter"
                          >
                            {/* <Card>
                               <CardBody> */}
                            <div
                              style={{
                                padding: 5,
                                display: "flex",
                                justifyContent: "center",
                                fontSize: 12,
                                color: "gray"
                              }}
                            >
                              {getTotalAppliedCount() < 1 &&
                                "Applied filters will show up here"}
                            </div>
                            <div>
                              {workspaces &&
                                workspaces
                                  .filter(i => i.isSelected)
                                  .map(data => (
                                    <ButtonChip
                                      name={data.title}
                                      selected={data.isSelected || false}
                                      onClick={() =>
                                        handleChipSelection(
                                          data,
                                          workspaces,
                                          setWorkspaces,
                                          id => handleApplyFilter(false)
                                        )
                                      }
                                    />
                                  ))}

                              {filterprojects &&
                                filterprojects
                                  .filter(i => i.isSelected)
                                  .map(project => {
                                    return (
                                      <ButtonChip
                                        name={project.name}
                                        selected={project.isSelected || false}
                                        onClick={() =>
                                          handleChipSelection(
                                            project,
                                            filterprojects,
                                            setFilterProjects,
                                            () => {
                                              handleApplyFilter(false);
                                            }
                                          )
                                        }
                                      />
                                    );
                                  })}
                              {filterLabels &&
                                filterLabels
                                  .filter(i => i.isSelected)
                                  .map(label => {
                                    return (
                                      <ButtonChip
                                        name={label.name}
                                        selected={label.isSelected || false}
                                        onClick={() =>
                                          handleChipSelection(
                                            label,
                                            filterLabels,
                                            setFilterLabels,
                                            () => {
                                              handleApplyFilter(false);
                                            }
                                          )
                                        }
                                      />
                                    );
                                  })}
                              {priority &&
                                priority
                                  .filter(i => i.isSelected)
                                  .map(prty => {
                                    return (
                                      <ButtonChip
                                        name={prty.value}
                                        selected={prty.isSelected || false}
                                        onClick={() =>
                                          handlePrioritySelection(
                                            prty,
                                            priority,
                                            setPriority,
                                            () => {
                                              handleApplyFilter(false);
                                            }
                                          )
                                        }
                                      />
                                    );
                                  })}
                            </div>
                          </UncontrolledCollapse>
                        </div>
                      )}
                      {searchResult &&
                      (searchResult.workspaces?.length > 0 ||
                        searchResult?.workspaces?.length > 0 ||
                        searchResult?.projects?.length > 0 ||
                        searchResult?.tasks?.length > 0) ? (
                        <>
                          <div
                            style={{
                              fontSize: 14,
                              color: "black",
                              fontWeight: "bold",
                              marginTop: 15
                            }}
                          >
                            <IntlMessages
                              id={"globalsearchbar.searchresults"}
                            />
                          </div>
                          {searchResult &&
                            searchResult.workspaces &&
                            searchResult.workspaces?.length > 0 && (
                              <div
                                style={{
                                  borderBottom: "1px solid #EBEBEB",
                                  marginTop: "10px"
                                }}
                              >
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: "13px",
                                    color: "#808080"
                                  }}
                                >
                                  <IntlMessages
                                    id={"globalworkspacelist.workspaces"}
                                  />
                                </span>
                                <Row
                                  style={{ marginTop: 15, marginBottom: 15 }}
                                >
                                  {searchResult &&
                                  searchResult.workspaces &&
                                  searchResult.workspaces?.length > 0 ? (
                                    searchResult.workspaces.map(data => {
                                      return (
                                        <Col
                                          className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 "
                                          style={{ paddingRight: "0px" }}
                                        >
                                          <div
                                            className="workspace-details-wrapper"
                                            style={{ padding: 5 }}
                                          >
                                            <>
                                              <div style={{ display: "flex" }}>
                                                <div className="workspace-Avatar">
                                                  <div
                                                    className=" workspace-placeholder-"
                                                    style={{
                                                      // background: props.topbarTheme.buttonColor,
                                                      background: getUserCharColor(
                                                        data.name.charAt(0)
                                                      ),
                                                      cursor: "pointer"
                                                    }}
                                                    onClick={() => {
                                                      handleRedirection(data);
                                                      // history.push(
                                                      //   `/business/${data?.businessId}`
                                                      // );
                                                      // closeSearchPopover();
                                                    }}
                                                  >
                                                    {data?.name &&
                                                      data.name
                                                        .charAt(0)
                                                        .toUpperCase()}
                                                  </div>
                                                </div>
                                                <div
                                                  className="workspace-details-contents"
                                                  style={{
                                                    position: "relative",
                                                    top: -4
                                                  }}
                                                >
                                                  <div
                                                    className="workspace-title text-underline"
                                                    style={{
                                                      display: "inline-block",
                                                      overflow: "hidden",
                                                      color: "black",
                                                      maxWidth: "120px",
                                                      textOverflow: "ellipsis",
                                                      margin: "0px",
                                                      fontSize: "14px",
                                                      fontWeight: "500",
                                                      cursor: "pointer",
                                                      whiteSpace: "nowrap"

                                                      // display: "-webkit-box",
                                                      // WebkitLineClamp: 1,
                                                      // WebkitBoxOrient:
                                                      //   " vertical",
                                                      // overflow: "hidden",
                                                      // color: "black",
                                                      // width: "100%",
                                                      // overflowWrap: "break-word",
                                                      // margin: "0px",
                                                      // fontSize: "14px",
                                                      // fontWeight: "500",
                                                      // cursor: "pointer"
                                                    }}
                                                    onClick={() => {
                                                      handleRedirection(data);
                                                      // history.push(
                                                      //   `/business/${data?.businessId}`
                                                      // );
                                                      // closeSearchPopover();
                                                    }}
                                                  >
                                                    {data?.name && data.name}
                                                  </div>
                                                  {/* <div
                                  className="edit-workspace workspace-bg"
                                      // style={{width: "45%"}}
                                  // onClick={toggleEditBusiness}
                                   >                    
                                   </div> */}
                                                </div>
                                              </div>
                                            </>
                                          </div>
                                        </Col>
                                      );
                                    })
                                  ) : (
                                    <Col
                                      className="col-12 flex-x"
                                      style={{
                                        justifyContent: "center",
                                        fontSize: 12,
                                        color: "gray",
                                        marginBottom: 15
                                      }}
                                    >
                                      <IntlMessages
                                        id={"globalsearchbar.noworkspace"}
                                      />
                                    </Col>
                                  )}
                                </Row>

                                {searchResult?.moreWorkspace && (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      fontSize: "13px",
                                      color: "#808080",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                      paddingBottom: 10
                                    }}
                                  >
                                    {viewMoreWorkspacesLoader ? (
                                      <LinearLoader />
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          padding: "1rem 0rem"
                                        }}
                                        onClick={() => viewMoreWorkspaces()}
                                      >
                                        <IntlMessages
                                          id={"globalsearchbar.viewmore"}
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          {searchResult &&
                            searchResult.projects &&
                            searchResult.projects?.length > 0 && (
                              <div
                                style={{
                                  marginTop: "10px",
                                  borderBottom: "1px solid #ebebeb"
                                }}
                              >
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: "13px",
                                    color: "#808080"
                                  }}
                                >
                                  <IntlMessages
                                    id={"globalsearchbar.projects"}
                                  />
                                </span>
                                <Row
                                  style={{ marginTop: 15, marginBottom: 15 }}
                                >
                                  {searchResult &&
                                  searchResult.projects &&
                                  searchResult.projects?.length > 0 ? (
                                    searchResult.projects.map(data => {
                                      return (
                                        <Col
                                          className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 "
                                          style={{ paddingRight: "0px" }}
                                        >
                                          <div
                                            className="workspace-details-wrapper"
                                            style={{ padding: 5 }}
                                          >
                                            <>
                                              <div style={{ display: "flex" }}>
                                                <div className="workspace-Avatar">
                                                  <div
                                                    className=" workspace-placeholder-search-popover"
                                                    style={{
                                                      // background: props.topbarTheme.buttonColor,
                                                      background: getUserCharColor(
                                                        data.name.charAt(0)
                                                      ),
                                                      cursor: "pointer",
                                                      objectFit: "cover"
                                                    }}
                                                    onClick={() => {
                                                      handleRedirection(data);
                                                      // history.push(
                                                      //   `/project-details/${data?.projectId}`
                                                      // );
                                                      // closeSearchPopover();
                                                    }}
                                                  >
                                                    {data?.name &&
                                                      data.name
                                                        .charAt(0)
                                                        .toUpperCase()}
                                                  </div>
                                                </div>
                                                <div
                                                  className="workspace-details-contents"
                                                  style={{
                                                    position: "relative",
                                                    top: -4
                                                  }}
                                                >
                                                  <div
                                                    className="workspace-title text-underline"
                                                    style={{
                                                      // display: "-webkit-box",
                                                      // WebkitLineClamp: 1,
                                                      // WebkitBoxOrient:
                                                      //   " vertical",
                                                      // overflow: "hidden",
                                                      // color: "black",
                                                      // width: "100%",
                                                      // overflowWrap: "break-word",
                                                      // margin: "0px",
                                                      // // paddingBottom:"5px",
                                                      // fontSize: "14px",
                                                      // fontWeight: "500",
                                                      // cursor: "pointer"
                                                      display: "inline-block",
                                                      overflow: "hidden",
                                                      color: "black",
                                                      maxWidth: "120px",
                                                      textOverflow: "ellipsis",
                                                      margin: "0px",
                                                      fontSize: "14px",
                                                      fontWeight: "500",
                                                      cursor: "pointer",
                                                      whiteSpace: "nowrap"
                                                    }}
                                                    onClick={() => {
                                                      handleRedirection(data);
                                                      // history.push(
                                                      //   `/project-details/${data?.projectId}`
                                                      // );
                                                      // closeSearchPopover();
                                                    }}
                                                  >
                                                    {data?.name && data.name}
                                                  </div>
                                                  <div
                                                    className=""
                                                    style={{
                                                      color: "#808080",
                                                      fontSize: 13,
                                                      display: "inline-block",
                                                      overflow: "hidden",
                                                      maxWidth: "120px",
                                                      textOverflow: "ellipsis",
                                                      margin: "0px",
                                                      fontWeight: "500",
                                                      cursor: "pointer",
                                                      whiteSpace: "nowrap"
                                                    }}
                                                    // onClick={() => {
                                                    //   // handleRedirection(data);
                                                    //   // history.push(
                                                    //   //   `/business/${data?.businessId}`
                                                    //   // );
                                                    // closeSearchPopover();
                                                    // }}
                                                  >
                                                    {data?.businessName &&
                                                      data.businessName}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          </div>
                                        </Col>
                                      );
                                    })
                                  ) : (
                                    <Col
                                      className="col-12 flex-x"
                                      style={{
                                        justifyContent: "center",
                                        fontSize: 12,
                                        color: "gray",
                                        marginBottom: 15
                                      }}
                                    >
                                      <IntlMessages
                                        id={"globalsearchbar.noprojects"}
                                      />
                                    </Col>
                                  )}
                                </Row>

                                {searchResult?.moreProject && (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      fontSize: "13px",
                                      color: "#808080",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                      addingBottom: 10
                                    }}
                                  >
                                    {viewMoreProjectsLoader ? (
                                      <LinearLoader />
                                    ) : (
                                      <div
                                        style={{
                                          height: 100,
                                          display: "flex",
                                          justifyContent: "center",
                                          paddingTop: "2.09rem"
                                        }}
                                        onClick={() => viewMoreProjects()}
                                      >
                                        <IntlMessages
                                          id={"globalsearchbar.viewmore"}
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}

                          {searchResult &&
                            searchResult.tasks &&
                            searchResult.tasks?.length > 0 && (
                              <div style={{ marginTop: "10px" }}>
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: "13px",
                                    color: "#808080"
                                  }}
                                >
                                  <IntlMessages id={"globalsearchbar.tasks"} />
                                </span>
                                <Row
                                  style={{ marginTop: 15, marginBottom: 15 }}
                                >
                                  {searchResult &&
                                  searchResult.tasks &&
                                  searchResult.tasks?.length > 0
                                    ? searchResult.tasks.map(data => {
                                        return (
                                          <Col
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 flex-x"
                                            style={{ marginBottom: "10px" }}
                                          >
                                            <div
                                              className=""
                                              style={{
                                                padding: 5,
                                                width: "70%"
                                              }}
                                            >
                                              <>
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  <div className="workspace-Avatar">
                                                    <div
                                                      className=" workspace-placeholder-search-popover"
                                                      style={{
                                                        // background: props.topbarTheme.buttonColor,
                                                        background: getUserCharColor(
                                                          data.name.charAt(0)
                                                        ),
                                                        cursor: "pointer",
                                                        objectFit: "cover"
                                                      }}
                                                      onClick={() => {
                                                        handleRedirection(data);
                                                        // history.push(
                                                        //   `/project/${data?.projectId}?tId=${data?.taskId}`
                                                        // );
                                                        // closeSearchPopover();
                                                        setIsSearchPopover(
                                                          !isSearchPopover
                                                        );
                                                      }}
                                                    >
                                                      {data.name &&
                                                        data.name
                                                          .charAt(0)
                                                          .toUpperCase()}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="workspace-details-contents"
                                                    style={{
                                                      position: "relative",
                                                      top: -4
                                                    }}
                                                  >
                                                    <div
                                                      className="workspace-title text-underline"
                                                      style={{
                                                        // display: "-webkit-box",
                                                        // WebkitLineClamp: 1,
                                                        // WebkitBoxOrient:
                                                        //   " vertical",
                                                        // overflow: "hidden",
                                                        // color: "black",
                                                        // width: "100%",
                                                        // overflowWrap:
                                                        //   "break-word",
                                                        // margin: "0px",
                                                        // // paddingBottom:"5px",
                                                        // fontSize: "14px",
                                                        // fontWeight: "500",
                                                        // cursor: "pointer"
                                                        display: "inline-block",

                                                        overflow: "hidden",
                                                        color: "black",
                                                        maxWidth: "120px",
                                                        textOverflow:
                                                          "ellipsis",
                                                        margin: "0px",
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        cursor: "pointer",
                                                        whiteSpace: "nowrap"
                                                      }}
                                                      onClick={() => {
                                                        handleRedirection(data);
                                                        // history.push(
                                                        //   `/project/${data?.projectId}?tId=${data?.taskId}`
                                                        // );
                                                        // closeSearchPopover();
                                                        setIsSearchPopover(
                                                          !isSearchPopover
                                                        );
                                                      }}
                                                    >
                                                      {data.name && data.name}
                                                    </div>
                                                    <div
                                                      // className="edit-workspace workspace-bg"
                                                      style={{
                                                        color: "#808080",
                                                        fontSize: 13
                                                      }}
                                                      // onClick={toggleEditBusiness}
                                                    >
                                                      in{" "}
                                                      <b>
                                                        {" "}
                                                        {data.sectionName &&
                                                          data.sectionName}{" "}
                                                      </b>
                                                      on{" "}
                                                      <b
                                                        className="text-underline"
                                                        style={{
                                                          cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                          handleRedirection(
                                                            data
                                                          );
                                                          // history.push(
                                                          //   `/project/${data?.projectId}`
                                                          // );
                                                          // closeSearchPopover();
                                                          setIsSearchPopover(
                                                            !isSearchPopover
                                                          );
                                                        }}
                                                      >
                                                        {data.projectName &&
                                                          data.projectName}
                                                      </b>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            </div>
                                            <div
                                              style={{
                                                width: "30%",
                                                color: "gray",
                                                height: "43px",
                                                fontWeight: 500,
                                                wordBreak: "break-word",
                                                display: "-webkit-box",
                                                WebkitBoxOrient: "vertical",
                                                lineClamp: 2,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                WebkitLineClamp: 2,
                                                WebkitFontSmoothing:
                                                  "antialiased",
                                                textAlign: "right"
                                              }}
                                            >
                                              {data.businessName}
                                            </div>
                                          </Col>
                                        );
                                      })
                                    : ""}
                                </Row>

                                {searchResult?.moreTask && (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      fontSize: "13px",
                                      color: "#808080",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                      paddingBottom: 10
                                    }}
                                  >
                                    {viewMoreTasksLoader ? (
                                      <LinearLoader />
                                    ) : (
                                      <div
                                        style={{
                                          height: 100,
                                          display: "flex",
                                          justifyContent: "center",
                                          paddingTop: "2.09rem"
                                        }}
                                        onClick={() => viewMoreTasks()}
                                      >
                                        <IntlMessages
                                          id={"globalsearchbar.viewmore"}
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                        </>
                      ) : (
                        <>
                          {searchQuery == null ||
                          searchQuery == "" ||
                          !searchQuery ? (
                            <>
                              <div style={{ marginTop: "15px" }}>
                                <span
                                  style={{
                                    textAlign: "left",
                                    fontSize: "13px",
                                    color: "#808080"
                                  }}
                                >
                                  <IntlMessages
                                    id={"globalsearchbar.currentworkspace"}
                                  />
                                </span>
                                <Row
                                  style={{ marginTop: 10, marginBottom: 10 }}
                                >
                                  <Col className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div
                                      className="workspace-details-wrapper"
                                      style={{ padding: 5 }}
                                    >
                                      <>
                                        <div style={{ display: "flex" }}>
                                          <div className="workspace-Avatar">
                                            <div
                                              className=" workspace-placeholder-search-popover"
                                              style={{
                                                // background: props.topbarTheme.buttonColor,
                                                background: getUserCharColor(
                                                  currentWorkspace &&
                                                    currentWorkspace?.businessName &&
                                                    currentWorkspace?.businessName.charAt(
                                                      0
                                                    )
                                                ),
                                                cursor: "pointer",
                                                objectFit: "cover"
                                              }}
                                              onClick={() => {
                                                history.push(
                                                  `/business/${currentWorkspace?.businessId}`
                                                );
                                                // closeSearchPopover();
                                              }}
                                            >
                                              {currentWorkspace?.businessName &&
                                                currentWorkspace?.businessName
                                                  .charAt(0)
                                                  .toUpperCase()}
                                            </div>
                                          </div>
                                          <div
                                            className="workspace-details-contents"
                                            style={{
                                              position: "relative",
                                              top: -4
                                            }}
                                          >
                                            <div
                                              className="workspace-title text-underline"
                                              style={{
                                                // display: "-webkit-box",
                                                // WebkitLineClamp: 1,
                                                // WebkitBoxOrient: " vertical",
                                                // overflow: "hidden",
                                                // color: "black",
                                                // width: "100%",
                                                // overflowWrap: "break-word",
                                                // margin: "0px",
                                                // fontSize: "14px",
                                                // fontWeight: "500",
                                                // cursor: "pointer"
                                                display: "inline-block",
                                                overflow: "hidden",
                                                color: "black",
                                                textOverflow: "ellipsis",
                                                margin: "0px",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                cursor: "pointer",
                                                wordBreak: "break-word"
                                              }}
                                              onClick={() => {
                                                history.push(
                                                  `/business/${currentWorkspace?.businessId}`
                                                );
                                                // closeSearchPopover();
                                              }}
                                            >
                                              {currentWorkspace &&
                                                currentWorkspace?.businessName}
                                            </div>
                                            {/* <div
                                  className="edit-workspace workspace-bg"
                                      // style={{width: "45%"}}
                                  // onClick={toggleEditBusiness}
                                   >                    
                                   </div> */}
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              {projects && projects.projects?.length > 0 && (
                                <div
                                  style={{
                                    borderBottom: "1px solid #EBEBEB",
                                    marginTop: "10px"
                                  }}
                                >
                                  <span
                                    style={{
                                      textAlign: "left",
                                      fontSize: "13px",
                                      color: "#808080"
                                    }}
                                  >
                                    <IntlMessages
                                      id={"globalsearchbar.recentprojects"}
                                    />
                                  </span>
                                  <Row
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                  >
                                    {projects &&
                                      projects.projects?.length > 0 &&
                                      projects.projects.map((data, i) => {
                                        if (!data.isDeleted && i <= 2) {
                                          return (
                                            <Col
                                              className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 "
                                              style={{ paddingRight: "0px" }}
                                            >
                                              <div
                                                className="workspace-details-wrapper"
                                                style={{ padding: 5 }}
                                              >
                                                <>
                                                  <div
                                                    style={{ display: "flex" }}
                                                  >
                                                    <div className="workspace-Avatar">
                                                      <div
                                                        className=" workspace-placeholder-search-popover"
                                                        style={{
                                                          // background: props.topbarTheme.buttonColor,
                                                          background: getUserCharColor(
                                                            data.name.charAt(0)
                                                          ),
                                                          cursor: "pointer",
                                                          objectFit: "cover"
                                                        }}
                                                        onClick={() => {
                                                          handleRedirection({
                                                            ...data,
                                                            projectId: data.id,
                                                            type: "PROJECT",
                                                            businessId:
                                                              currentWorkspace?.businessId
                                                          });
                                                          // history.push(
                                                          //   `/project-details/${data.id}`
                                                          // );
                                                          // closeSearchPopover();
                                                        }}
                                                      >
                                                        {data &&
                                                          data?.name
                                                            .charAt(0)
                                                            .toUpperCase()}
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="workspace-details-contents"
                                                      style={{
                                                        position: "relative",
                                                        top: -4
                                                      }}
                                                    >
                                                      <div
                                                        className="workspace-title text-underline"
                                                        style={{
                                                          display:
                                                            "inline-block",

                                                          overflow: "hidden",
                                                          color: "black",
                                                          maxWidth: "120px",
                                                          textOverflow:
                                                            "ellipsis",
                                                          margin: "0px",
                                                          fontSize: "14px",
                                                          fontWeight: "500",
                                                          cursor: "pointer",
                                                          whiteSpace: "nowrap"
                                                        }}
                                                        onClick={() => {
                                                          handleRedirection({
                                                            ...data,
                                                            projectId: data.id,
                                                            type: "PROJECT",
                                                            businessId:
                                                              currentWorkspace?.businessId
                                                          });
                                                          // history.push(
                                                          //   `/project-details/${data.id}`
                                                          // );
                                                          // closeSearchPopover();
                                                        }}
                                                      >
                                                        {data && data.name}
                                                      </div>
                                                      {/* <div
                                  className="edit-workspace workspace-bg"
                                      // style={{width: "45%"}}
                                  // onClick={toggleEditBusiness}
                                   >                    
                                   </div> */}
                                                    </div>
                                                  </div>
                                                </>
                                              </div>
                                            </Col>
                                          );
                                        }
                                      })}
                                  </Row>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {searchQuery &&
                                searchQuery != "" &&
                                searchQuery != null &&
                                !isFetching && (
                                  <>
                                    <div
                                      style={{
                                        padding: 20,
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "#808080"
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src={
                                          searchNotFound
                                        } /* height="142px" */
                                        style={{
                                          marginLeft: "59px",
                                          marginRight: "55px",
                                          marginTop: "80px",
                                          width: "318px",
                                          height: "142px"
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        width: "400px",
                                        height: "38px",
                                        padding: 50,
                                        display: "flex",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        marginLeft: "-16px",
                                        fontSize: 14,
                                        color: "#808080"
                                      }}
                                    >
                                      <IntlMessages
                                        id={"globalsearchbar.nomatchdata"}
                                      />
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )
                ) : (
                  <>
                    <>
                      {isFilterLoader && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: 380,
                            position: "absolute"
                          }}
                        >
                          <Loader height="30px" width="30px" />
                        </div>
                      )}

                      <div
                        style={{
                          margin: "10px 15px",
                          height: 375,
                          overflow: "hidden scroll",
                          opacity: isFilterLoader ? 0.2 : 1
                        }}
                        disabled={isFilterLoader}
                      >
                        {/* workspace collapse */}
                        <div
                          style={{
                            borderBottom: "1px solid #f3f3f3",
                            paddingBottom: 10,
                            marginBottom: 10
                          }}
                        >
                          <Button
                            onClick={() =>
                              setIsToggle({
                                ...isToggle,
                                Workspaces: !isToggle.Workspaces
                              })
                            }
                            className="btn btn"
                            id="workspaces"
                            style={{
                              marginBottom: "0px",
                              backgroundColor: "white",
                              width: "100%",
                              color: "#808080",
                              borderTop: "0px solid #E6E6E6",
                              borderLeft: "0px solid #E6E6E6",
                              borderRight: "0px solid #E6E6E6",
                              borderBottom: "0px solid #E6E6E6",
                              borderRadius: 0,
                              fontWeight: "600",
                              padding: "0px 0px 0px 0px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "baseline"
                            }}
                          >
                            <IntlMessages
                              id={"globalworkspacelist.workspaces"}
                            />
                            <img
                              alt=""
                              style={{ marginLeft: 15 }}
                              src={
                                isToggle.Workspaces
                                  ? SidebarUpArrow
                                  : SidebarDownArrow
                              }
                            ></img>
                          </Button>
                          <UncontrolledCollapse
                            isOpen={
                              isToggle?.Workspaces ? isToggle.Workspaces : false
                            }
                            toggler="#workspaces"
                          >
                            {/* <Card>
                            <CardBody> */}

                            <div>
                              {workspaces &&
                                workspaces.map(workspace => {
                                  return (
                                    <ButtonChip
                                      name={workspace.title}
                                      selected={workspace.isSelected || false}
                                      onClick={() =>
                                        handleChipSelection(
                                          workspace,
                                          workspaces,
                                          setWorkspaces,
                                          id => {
                                            if (id) {
                                              doGetProjectsAndLabels(id);
                                            } else {
                                            }
                                          }
                                        )
                                      }
                                    />
                                  );
                                })}
                            </div>

                            {/* </CardBody>

                          </Card> */}
                          </UncontrolledCollapse>
                        </div>
                        {/* project collapse */}
                        {workspaces &&
                          workspaces.length > 0 &&
                          filterprojects &&
                          filterprojects.length > 0 && (
                            <div
                              style={{
                                borderBottom: "1px solid #f3f3f3",
                                paddingBottom: 10,
                                marginBottom: 10
                              }}
                            >
                              <Button
                                onClick={() =>
                                  setIsToggle({
                                    ...isToggle,
                                    Projects: !isToggle.Projects
                                  })
                                }
                                className="btn btn"
                                id="projects"
                                style={{
                                  marginBottom: "0px",
                                  backgroundColor: "white",
                                  width: "100%",
                                  color: "#808080",
                                  borderTop: "0px solid #E6E6E6",
                                  borderLeft: "0px solid #E6E6E6",
                                  borderRight: "0px solid #E6E6E6",
                                  borderBottom: "0px solid #E6E6E6",
                                  borderRadius: 0,
                                  fontWeight: "600",
                                  padding: "0px 0px 0px 0px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "baseline"
                                }}
                              >
                                <IntlMessages id={"globalsearchbar.projects"} />
                                <img
                                  alt=""
                                  style={{ marginLeft: 15 }}
                                  src={
                                    isToggle.Projects
                                      ? SidebarUpArrow
                                      : SidebarDownArrow
                                  }
                                ></img>
                              </Button>
                              <UncontrolledCollapse
                                isOpen={
                                  isToggle?.Projects ? isToggle.Projects : false
                                }
                                toggler="#projects"
                              >
                                {/* <Card>
                            <CardBody> */}

                                <div>
                                  {filterprojects &&
                                    filterprojects.map(project => {
                                      return (
                                        <ButtonChip
                                          name={project.name}
                                          selected={project.isSelected || false}
                                          onClick={() =>
                                            handleChipSelection(
                                              project,
                                              filterprojects,
                                              setFilterProjects,
                                              () => {}
                                            )
                                          }
                                        />
                                      );
                                    })}
                                </div>

                                {/* </CardBody>

                          </Card> */}
                              </UncontrolledCollapse>
                            </div>
                          )}

                        {/* label collapse */}
                        {filterLabels && filterLabels.length > 0 && (
                          <div
                            style={{
                              borderBottom: "1px solid #f3f3f3",
                              paddingBottom: 10,
                              marginBottom: 10
                            }}
                          >
                            <Button
                              onClick={() =>
                                setIsToggle({
                                  ...isToggle,
                                  Label: !isToggle.Label
                                })
                              }
                              className="btn btn"
                              id="labeler"
                              style={{
                                marginBottom: "0px",
                                backgroundColor: "white",
                                width: "100%",
                                color: "#808080",
                                borderTop: "0px solid #E6E6E6",
                                borderLeft: "0px solid #E6E6E6",
                                borderRight: "0px solid #E6E6E6",
                                borderBottom: "0px solid #E6E6E6",
                                borderRadius: 0,
                                fontWeight: "600",
                                padding: "0px 0px 0px 0px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline"
                              }}
                            >
                              <IntlMessages id={"globalsearchbar.labels"} />
                              <img
                                alt=""
                                style={{ marginLeft: 15 }}
                                src={
                                  isToggle.Label
                                    ? SidebarUpArrow
                                    : SidebarDownArrow
                                }
                              ></img>
                            </Button>
                            <UncontrolledCollapse
                              isOpen={isToggle.Label ? isToggle.Label : false}
                              toggler="#labeler"
                            >
                              {/* <Card>
                            <CardBody> */}

                              <div>
                                {filterLabels &&
                                  filterLabels.map(label => {
                                    return (
                                      <ButtonChip
                                        name={label.name}
                                        selected={label.isSelected || false}
                                        onClick={() =>
                                          handleChipSelection(
                                            label,
                                            filterLabels,
                                            setFilterLabels,
                                            () => {}
                                          )
                                        }
                                      />
                                    );
                                  })}
                              </div>

                              {/* </CardBody>

                          </Card> */}
                            </UncontrolledCollapse>
                          </div>
                        )}
                        {/* priority collapse */}
                        <div
                          style={{
                            borderBottom: "1px solid #f3f3f3",
                            paddingBottom: 10,
                            marginBottom: 10
                          }}
                        >
                          <Button
                            onClick={() =>
                              setIsToggle({
                                ...isToggle,
                                Priority: !isToggle.Priority
                              })
                            }
                            className="btn btn"
                            id="priority"
                            style={{
                              marginBottom: "0px",
                              backgroundColor: "white",
                              width: "100%",
                              color: "#808080",
                              borderTop: "0px solid #E6E6E6",
                              borderLeft: "0px solid #E6E6E6",
                              borderRight: "0px solid #E6E6E6",
                              borderBottom: "0px solid #E6E6E6",
                              borderRadius: 0,
                              fontWeight: "600",
                              padding: "0px 0px 0px 0px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "baseline"
                            }}
                          >
                            <IntlMessages id={"globalsearchbar.priority"} />
                            <img
                              alt=""
                              style={{ marginLeft: 15 }}
                              src={
                                isToggle.Priority
                                  ? SidebarUpArrow
                                  : SidebarDownArrow
                              }
                            ></img>
                          </Button>
                          <UncontrolledCollapse
                            isOpen={
                              isToggle?.Priority ? isToggle.Priority : false
                            }
                            toggler="#priority"
                          >
                            {/* <Card>
                            <CardBody> */}

                            {/* allPriority */}
                            <div>
                              {priority &&
                                priority.map(prty => {
                                  return (
                                    <ButtonChip
                                      name={prty.value}
                                      selected={prty.isSelected || false}
                                      onClick={() =>
                                        handlePrioritySelection(
                                          prty,
                                          priority,
                                          setPriority,
                                          () => {}
                                        )
                                      }
                                    />
                                  );
                                })}
                            </div>

                            {/* </CardBody>

                          </Card> */}
                          </UncontrolledCollapse>
                        </div>
                        {/* date collapse */}
                        {/* <div
                          style={{
                            borderBottom: "1px solid #f3f3f3",
                            paddingBottom: 10,
                            marginBottom: 10
                          }}
                        >
                          <Button
                            onClick={() =>
                              setIsToggle({ ...isToggle, Date: !isToggle.Date })
                            }
                            className="btn btn"
                            id="Date"
                            style={{
                              marginBottom: "0px",
                              backgroundColor: "white",
                              width: "100%",
                              color: "#808080",
                              borderTop: "0px solid #E6E6E6",
                              borderLeft: "0px solid #E6E6E6",
                              borderRight: "0px solid #E6E6E6",
                              borderBottom: "0px solid #E6E6E6",
                              borderRadius: 0,
                              fontWeight: "600",
                              padding: "0px 0px 0px 0px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "baseline"
                            }}
                          >
                            Date
                            <img
                              style={{ marginLeft: 15 }}
                              src={
                                isToggle.Date
                                  ? SidebarUpArrow
                                  : SidebarDownArrow
                              }
                            ></img>
                          </Button>
                          <UncontrolledCollapse
                            isOpen={isToggle?.Date ? isToggle.Date : false}
                            toggler="#Date"
                          >
                            

                            <div>
                              <ButtonChip
                                name={"Today"}
                                selected={filterDates.today || false}
                                onClick={() => {
                                  setFilterDates({
                                    ...filterDates,
                                    today: !filterDates.today
                                  });
                                }}
                              />
                              <ButtonChip
                                name={"Upcoming"}
                                selected={filterDates.Upcoming || false}
                                onClick={() => {
                                  setFilterDates({
                                    ...filterDates,
                                    Upcoming: !filterDates.Upcoming
                                  });
                                }}
                              />
                              <ButtonChip
                                name={"Overdue"}
                                selected={filterDates.overdue || false}
                                onClick={() => {
                                  setFilterDates({
                                    ...filterDates,
                                    overdue: !filterDates.overdue
                                  });
                                }}
                              />
                            </div>
 
                          </UncontrolledCollapse>
                        </div> */}
                      </div>

                      <div style={{ padding: "0px 15px" }}>
                        <Button
                          onClick={handleApplyFilter}
                          type="submit"
                          className="btn "
                          disabled={getTotalAppliedCount() > 0 ? false : true}
                          style={{
                            cursor:
                              getTotalAppliedCount() == 0 ? "not-allowed" : "",
                            border: "none",
                            backgroundColor: topbarTheme.buttonColor,
                            height: "35px",
                            width: "29%",
                            marginRight: "1%",
                            borderRadius: "5px"
                          }}
                        >
                          <span
                            style={{
                              fontSize: 14,
                              color: "white",
                              fontWeight: "500"
                            }}
                          >
                            <IntlMessages id={"globalsearchbar.applybutton"} />
                          </span>
                        </Button>
                        <Button
                          className="button btn"
                          onClick={() => handleResetFilter()}
                          style={{
                            background: "transparent",
                            color: "#000",
                            padding: "0px",
                            height: "35px",
                            width: "29%",
                            marginLeft: "1%",
                            border: "1px solid #E6E6E6",
                            borderRadius: "5px"
                          }}
                        >
                          <span
                            style={{
                              fontSize: 14,
                              color: topbarTheme.buttonColor,
                              fontWeight: "500"
                            }}
                          >
                            <IntlMessages id={"globalsearchbar.clearfilter"} />
                          </span>
                        </Button>
                      </div>
                    </>
                  </>
                )}
              </div>
            </>
          </PopoverBody>
        </HeaderPopover>
      </UncontrolledPopover>
    </HeaderPopover>
  );
}

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, null)(SearchPopover);
