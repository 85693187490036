import React from "react";
import AddedTask from "./AddedTask";
import { TaskComplete } from "./TaskComplete";
import { Commented } from "./Commented";
import { AddedUser } from "./AddedUser";
import { RemovedUser } from "./RemovedUser";
export const GetActivityData = ({ activity, handleRedirection }) => {
  if (activity) {
    if (activity.activityType == ActivityType.TASKADD) {
      return (
        <>
          <AddedTask
            actor={activity.actor}
            task={activity.task}
            project={activity.project}
            handleRedirection={data => {
              handleRedirection({
                ...data,
                business: activity.business,
                ...activity
              });
            }}
          />
        </>
      );
    }
    if (activity.activityType == ActivityType.TASKCOMPLETE) {
      return (
        <>
          <TaskComplete
            activity={activity}
            handleRedirection={data =>
              handleRedirection({
                ...data,
                business: activity.business,
                ...activity
              })
            }
          />
        </>
      );
    }
    if (activity.activityType == ActivityType.COMMENTADD) {
      return (
        <Commented
          activity={activity}
          handleRedirection={data =>
            handleRedirection({
              ...data,
              business: activity.business,
              ...activity
            })
          }
        />
      );
    }
    if (
      activity.activityType == ActivityType.ADDINTOBUSINESS ||
      activity.activityType == ActivityType.ADDINTOPROJECT
    ) {
      return (
        <AddedUser
          activity={activity}
          type={
            activity.activityType == ActivityType.ADDINTOBUSINESS
              ? "business"
              : "project"
          }
          handleRedirection={data =>
            handleRedirection({
              ...data,
              business: activity.business,
              ...activity
            })
          }
        />
      );
    }
    if (
      activity.activityType == ActivityType.REMOVEFROMBUSINESS ||
      activity.activityType == ActivityType.REMOVEFROMPROJECT
    ) {
      return (
        <RemovedUser
          activity={activity}
          type={
            activity.activityType == ActivityType.REMOVEFROMBUSINESS
              ? "business"
              : "project"
          }
        />
      );
    }
  }
  return <div></div>;
};
const ActivityType = {
  COMMENTADD: "comment_add",
  TASKCOMPLETE: "task_complete",
  TASKADD: "task_add",
  ADDINTOBUSINESS: "add_into_business",
  REMOVEFROMBUSINESS: "remove_from_business",
  ADDINTOPROJECT: "add_into_project",
  REMOVEFROMPROJECT: "remove_from_project"
};
