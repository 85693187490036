import styled from "styled-components";

const DescriptionEdiotrWrapper = styled.div`
  .ql-editor.ql-blank::before {
    color: #808080 !important;
    font-size: 13px !important;
  }
  .quill {
    min-height: 95px !important;
    max-height: 200px !important;
  }
  .ql-editor {
    min-height: 95px !important;
    max-height: 200px !important;
  }
  .ql-toolbar.ql-snow {
    border: none !important;
  }
  .ql-container.ql-snow {
    border: none !important;
  }
  .ql-editor ql-blank {
    min-height: 95px !important;
    max-height: 200px !important;
  }
`;

export default DescriptionEdiotrWrapper;
