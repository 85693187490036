import moment from "moment";
export const isNewDateFuture = (currentDate = new Date(), newDate) => {
  if (moment(new Date(currentDate)) < moment(new Date(newDate))) {
    return true;
  } else {
    return false;
  }
};
export const isNewDateToday = (currentDate = new Date(), newDate) => {
  if (
    moment(new Date(currentDate)).format("DD-MM-YYYY") ==
    moment(new Date(newDate)).format("DD-MM-YYYY")
  ) {
    return true;
  } else {
    return false;
  }
};
export const isNewDatePast = (currentDate = new Date(), newDate) => {
  const present = moment(currentDate);
  const endDate = moment(newDate);

  // console.log("today-.", present.diff(endDate));
  // console.log("future-.", endDate.diff(present, "days"));
  if (endDate.diff(present, "days") < 0) {
    return true;
  } else {
    return false;
  }
};
export const isNewDateOverDue = (currentDate = new Date(), newDate) => {
  if (moment(new Date(currentDate)) > moment(new Date(newDate))) {
    return true;
  } else {
    return false;
  }
};
export const doPopFromArray = (todo, todoArr) => {
  if (todo && todo.id && todoArr && todoArr.length > 0) {
    const filteredArr = todoArr && todoArr.filter(pop => pop.id !== todo.id);
    return filteredArr;
  }
  return null;
};
export const doUpdate_Or_PushToArray = (todo, todoArr) => {
  if (todo && todoArr) {
    const checkIsExist = findTodo(todo, todoArr);
    if (checkIsExist) {
      return doUpdateTodoArr(todo, todoArr);
    } else {
      let array = [...todoArr];
      array.push(todo);
      return array;
    }
  }
};
export const doPushToArray = (todo, todoArr) => {
  if (todo && todoArr) {
    let array = [...todoArr];
    array.push(todo);
    return array;
  }
  return null;
};
export const doUpdateTodoArr = (updatedTodo, todoArr) => {
  let newTodoToarr = [updatedTodo];
  let NewArray = todoArr;
  NewArray.map((item, index) => {
    if (item.id == newTodoToarr[0].id) {
      todoArr[index] = { ...NewArray[index], ...newTodoToarr[0] };
    }
  });

  return NewArray;
};
export const findTodo = (todo, todoArr) => {
  if (todoArr && todoArr?.length > 0 && todo && todo?.id) {
    const todoArrFilter =
      todoArr && todoArr.filter((find, i) => find.id == todo.id);

    if (todoArrFilter && todoArrFilter.length > 0) {
      return todoArrFilter;
    }

    return false;
  }

  return null;
};
