import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import { deleteIcon } from "helper/constant";
import IntlMessages from "util/intlMessages";

const CommentMenu = ({
  target,
  toggleMenu,
  isMenu,
  editComment,
  deleteComment
}) => {
  React.useEffect(() => {}, [isMenu]);
  return (
    <div>
      {isMenu ? (
        <Popover
          trigger="legacy"
          className="roy-menu"
          innerClassName="roy-inner-content"
          placement="bottom-end"
          target={target}
          onScrollCapture={() => toggleMenu()}
          isOpen={isMenu}
          toggle={() => toggleMenu()}
        >
          <PopoverBody style={{ width: 175 }}>
            <div
              className="roy-menu-list flex-x"
              style={{ paddingLeft: 4, color: "#000" }}
              onClick={e => {
                e.stopPropagation();
                deleteComment();
              }}
            >
              <div style={{ width: "20%" }} align="left">
                <img alt="" src={deleteIcon} height="16px" width="18px" />
              </div>
              <span style={{ width: "80%" }}>
                {<IntlMessages id={"project.delete"} />}
              </span>
            </div>
          </PopoverBody>
        </Popover>
      ) : (
        ""
      )}
    </div>
  );
};
export default CommentMenu;
