import React, { useState } from "react";
import QuickAddWrapper from "./QuickAddWapper.style";
import {
  projectIcon,
  notebook,
  invite,
  workspaceIcon,
  addHeaderIcon
} from "helper/constant";

import { UncontrolledPopover, PopoverBody, PopoverHeader } from "reactstrap";
import IntlMessages from "util/intlMessages";
const QuickAdd = props => {
  const {
    toggleNewTask,
    toggleInvite,
    toggleNewProject,
    toggleNewBusiness
  } = props;
  const [isQuickAddActions, setIsQuickAddActions] = useState(false);
  const toggle = () => setIsQuickAddActions(!isQuickAddActions);
  return (
    <div>
      <div>
        <div className="pl-18 flex-1">
          <button id="quickAddAction" className="top-header-icon">
            {/* <i className="fas fa-plus"></i> */}
            <img alt="" src={addHeaderIcon} height="18px" width="18px" />
          </button>
        </div>
        <QuickAddWrapper>
          <UncontrolledPopover
            target="quickAddAction"
            placement="bottom-end"
            trigger="legacy"
            isOpen={isQuickAddActions}
            toggle={() => toggle()}
            hideArrow
          >
            <QuickAddWrapper>
              <PopoverBody className="roy-menu-quick-action">
                <div
                  className="fs-14 text-center"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "9px",
                    color: "#000000",
                    borderBottom: "1px solid #EBEBEB",
                    marginLeft: "-7px",
                    marginRight: "-7px",
                    fontWeight: "600"
                  }}
                >
                  {/* <b> Quick Add Actions</b> */}
                  <IntlMessages id={"header.quickaddactions"} />
                </div>
                <div style={{ padding: "12px 0px" }}>
                  <div
                    className="roy-menu-list  "
                    onClick={() => {
                      toggle();
                      toggleNewTask();
                    }}
                  >
                    <div className="text-left single-list-title">
                      {/* <i className="fa fa-plus single-list-icon" /> */}
                      <img
                        alt=""
                        src={notebook}
                        style={{
                          color: "black",
                          height: "18px",
                          // width: "14px",
                          paddingRight: "17px"
                        }}
                      ></img>
                      <IntlMessages id={"globalquickaddactions.createtask"} />
                    </div>
                    <div className=" single-list-description">
                      <IntlMessages
                        id={"globalquickaddactions.taskdecription"}
                      />
                    </div>
                  </div>
                  <div
                    className="roy-menu-list  "
                    onClick={() => {
                      toggle();
                      toggleNewProject();
                    }}
                  >
                    <div className="text-left single-list-title">
                      <span style={{ color: "black" }}>
                        <img
                          alt=""
                          src={projectIcon}
                          style={{
                            color: "black",
                            height: "17px",
                            // width: "14px",
                            paddingRight: "18px"
                          }}
                        ></img>
                        {/* <i className="fa fa-circle fs-10 single-list-icon" /> */}
                      </span>
                      {/* <PopoverBody toggle={() => setmodal(!UncontrolledPopover)}>Create Project</PopoverBody> */}
                      <IntlMessages
                        id={"globalquickaddactions.createproject"}
                      />
                    </div>
                    <div className=" single-list-description">
                      <IntlMessages
                        id={"globalquickaddactions.projectdecription"}
                      />
                    </div>
                  </div>
                  <div
                    className="roy-menu-list  "
                    onClick={() => {
                      toggle();
                      toggleNewBusiness();
                    }}
                  >
                    <div className="text-left single-list-title">
                      {/* <i className="fa fa-building single-list-icon" /> */}
                      <img
                        alt=""
                        src={workspaceIcon}
                        style={{
                          color: "#000000",
                          height: "16px",
                          // width: "14px",
                          paddingRight: "15px",
                          position: "relative",
                          left: -2
                        }}
                      ></img>
                      <IntlMessages
                        id={"globalquickaddactions.createworkspace"}
                      />
                    </div>
                    <div className=" single-list-description">
                      <IntlMessages
                        id={"globalquickaddactions.workspacedecription"}
                      />
                    </div>
                  </div>
                  <div
                    className="roy-menu-list  "
                    onClick={() => {
                      toggle();
                      toggleInvite();
                    }}
                  >
                    <div className="text-left single-list-title">
                      {/* <i className="fas fa-user-plus single-list-icon" /> */}
                      <img
                        alt=""
                        src={invite}
                        style={{
                          color: "#000000",
                          height: "16px",
                          // width: "14px",
                          paddingRight: "21px"
                        }}
                      ></img>
                      <IntlMessages
                        id={"globalquickaddactions.invitecollaborators"}
                      />
                    </div>
                    <div className=" single-list-description">
                      <IntlMessages
                        id={"globalquickaddactions.collaboratorsdecription"}
                      />
                    </div>
                  </div>
                </div>
              </PopoverBody>
            </QuickAddWrapper>
          </UncontrolledPopover>
        </QuickAddWrapper>
      </div>
    </div>
  );
};

export default QuickAdd;
