const internetActions = {
  CHANGE_STATE: "CHANGE_STATE",

  changeInternetState: data => {
    return {
      type: internetActions.CHANGE_STATE,
      isInternetActivated: data.state
    };
  }
};
export default internetActions;
