import { firebaseConfig } from "constatnt";
import firebase from "firebase/app";
import "firebase/messaging";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const messaging = () => {
  if (firebase.messaging.isSupported()) {
    return firebase.messaging();
  }
  return false;
};

export const requestFirebaseNotificationPermission = () => {
  return new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging().getToken())
      .then(firebaseToken => {
        resolve(firebaseToken);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging().onMessage(payload => {
      resolve(payload);
    });
  });

// export const setBackgroundMessageHandler = () =>
//   new Promise(resolve => {
//     messaging.setBackgroundMessageHandler(function (payload) {
//
//       resolve(self.registration.showNotification(title, options));
//     });
//   });

export const isSupported = firebase.messaging.isSupported();

export default messaging;
