import fileDropActions from "./actions";

const initState = {
  isDrag: localStorage.getItem("isDrag")
    ? localStorage.getItem("isDrag") === "true"
    : false,

  fileName: localStorage.getItem("fileName")
    ? localStorage.getItem("fileName")
    : null,

  isFileDropAllowed: localStorage.getItem("isFileDropAllowed")
    ? localStorage.getItem("isFileDropAllowed")
    : false
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case fileDropActions.ONFILEDRAG:
      return {
        ...state,
        isDrag: true
      };
    case fileDropActions.ONFILEDRAGLEAVE:
      return {
        ...state,
        isDrag: false
      };
    case fileDropActions.ONFILEDROP:
      return {
        ...state,
        fileName: action?.fileName ? action.fileName : null
      };
    case fileDropActions.ENABLE_FILE_DROP:
      return {
        ...state,
        isFileDropAllowed: true
      };
    case fileDropActions.DISABLE_FILE_DROP:
      return {
        ...state,
        isFileDropAllowed: false,
        isDrag: false,
        fileName: null
      };

    default:
      return state;
  }
}
