import React from "react";
import { UncontrolledTooltip } from "reactstrap";

const RoyTooltip = ({ id, title, children, placement = "bottom" }) => {
  return (
    <>
      {children}
      <UncontrolledTooltip
        className="roy-tooltip"
        arrowClassName="roy-tooltip-arrow"
        innerClassName="roy-tooltip-inner"
        placement={placement}
        target={id}
      >
        {title}
      </UncontrolledTooltip>
    </>
  );
};

export default RoyTooltip;
