/** @format */

const alertActions = {
  OPEN_WORKPSACE_ALERT: "OPEN_WORKPSACE_ALERT",
  OPEN_PROJECT_ALERT: "OPEN_PROJECT_ALERT",
  CLOSE_ALERT: "CLOSE_ALERT",

  open_workpsace_alert: data => {
    return {
      type: alertActions.OPEN_WORKPSACE_ALERT,
      alert: data
    };
  },
  open_project_alert: data => {
    return {
      type: alertActions.OPEN_PROJECT_ALERT,
      alert: data
    };
  },
  close_alert: data => {
    return {
      type: alertActions.CLOSE_ALERT,
      alert: data
    };
  }
};
export default alertActions;
