import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import RoyTooltip from "components/common/RoyTooltip";
import _ from "lodash";
import "./WeekDatePicker.css";
import DatePicker from "react-datepicker";
import { getHasUpcommingTaskinWeek } from "helper/services/todayServices";
import {
  LeftWeekDatePickerImage,
  RightWeekDatePickerImage,
  threeDot_Icon
} from "helper/constant";

import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import { connect } from "react-redux";
import { compose } from "redux";
import { getToday } from "helper/services/todayServices";
import WeekDatePickerWrapper from "./WeekDateWrapper.style";
import DatePickerWrapper from "components/forms/alldatepickers/datepicker.style";
import WeekDateButtonWrapper from "./WeekDateButtonWrapper.style";
import IntlMessages from "./intlMessages";
const WeekDatePicker = props => {
  const {
    fetchUpcomingTask,
    topbarTheme,
    weekDatesHasTask,
    setWeekDatesHasTask,
    doGetHasUpcommingTaskinWeek,
    activeDay,
    setActiveDay,
    activeMonth,
    setActiveMonth,
    weekDates,
    setWeekDates
  } = props;

  const [date, setDate] = useState(moment());
  // const [weekDates, setWeekDates] = useState([]);

  const [selectedUpcomingDate, setSelectedUpcomingDate] = useState(new Date());
  // const [activeDay, setActiveDay] = useState();
  // const [activeMonth, setActiveMonth] = useState(moment().format("MMM YYYY"));
  useEffect(() => {
    getCurrentWeek();
  }, []);

  const fetchTodayTask = async selectedDate => {
    try {
      props.startLoad();
      props.updateSelectedDate(selectedDate);

      let date = moment(selectedDate).format("YYYY-MM-DD");
      const Response = await getToday(null, date);
      if (Response) {
        if (Response.error) {
          props.stopLoad();
          return;
        }
        if (Response.data) {
          props.stopLoad();
          props.setUpcomingTodo(Response.data);
        }
      }
    } catch (e) {
      props.stopLoad();
    }
  };
  // const doGetHasUpcommingTaskinWeek = async (startDate, endDate) => {
  //   if (startDate && endDate) {
  //     try {
  //       const response = await getHasUpcommingTaskinWeek(startDate, endDate);
  //       if (response) {
  //         if (response.error) {
  //         }
  //         console.log(response);
  //         if (response.data) {
  //           setWeekDatesHasTask([...response.data]);
  //         }
  //       }
  //     } catch (e) {}
  //   }
  // };
  const handleChangeWeek = (startDate, endDate, isPresent) => {
    if (moment(startDate).isValid() && moment(endDate).isValid()) {
      isPresent
        ? doGetHasUpcommingTaskinWeek(startDate, endDate)
        : delayedQuery(startDate, endDate);
    }
  };

  const delayedQuery = useCallback(
    _.debounce((startDate, endDate) => {
      doGetHasUpcommingTaskinWeek(startDate, endDate);
    }, 800),
    []
  );
  const isEqualDate = (dateArr, givenDate) => {
    if (dateArr?.length > 0 && givenDate) {
      let filtereData =
        dateArr &&
        dateArr.filter(
          i =>
            moment(i.date).format("YYYY-MM-DD") ==
              moment(givenDate).format("YYYY-MM-DD") && i.hasTask
        );
      // console.log(filtereData?.length ,filtereData)
      return filtereData?.length;
    }
    return false;
  };
  const getCurrentWeek = () => {
    var currentDate = date;
    setActiveDay(`day-${moment(currentDate).format("DD")}`);
    var weekStart = currentDate.clone().startOf("isoWeek");
    var days = [];
    for (var i = 0; i <= 6; i++) {
      days.push(
        moment(weekStart)
          .add(i, "days")
          .format()
      );
    }
    setWeekDates(days);
    handleChangeWeek(
      moment(days[0]).format("YYYY-MM-DD"),
      moment(days[6]).format("YYYY-MM-DD"),
      true
    );
  };

  const nextWeek = () => {
    var weekStart = moment(date)
      .add(1, "weeks")
      .clone()
      .startOf("isoWeek");
    var days = [];
    for (var i = 0; i <= 6; i++) {
      days.push(
        moment(weekStart)
          .add(i, "days")
          .format()
      );
    }
    setDate(weekStart);

    setWeekDates(days);
    handleActiveMonth(days[0]);
    handleChangeWeek(
      moment(days[0]).format("YYYY-MM-DD"),
      moment(days[6]).format("YYYY-MM-DD"),
      false
    );
  };
  const handleActiveMonth = firstDayOfWeek => {
    setActiveMonth(moment(firstDayOfWeek).format("MMM YYYY"));
  };
  const handleChangeSelectionDateToToday = () => {
    let currentDate = moment(new Date());
    var weekStart = currentDate.clone().startOf("isoWeek");
    var days = [];
    for (var i = 0; i <= 6; i++) {
      days.push(
        moment(weekStart)
          .add(i, "days")
          .format()
      );
    }
    setWeekDates(days);
    handleChangeWeek(
      moment(days[0]).format("YYYY-MM-DD"),
      moment(days[6]).format("YYYY-MM-DD"),
      true
    );
    props.updateSelectedDate(currentDate);
    fetchUpcomingTask(currentDate);
    setActiveDay(`day-${moment(currentDate).format("DD")}`);
    setActiveMonth(moment(currentDate).format("MMM YYYY"));
  };
  const prevWeek = () => {
    var weekStart = moment(date)
      .subtract(1, "weeks")
      .clone()
      .startOf("isoWeek");
    var days = [];
    for (var i = 0; i <= 6; i++) {
      days.push(
        moment(weekStart)
          .add(i, "days")
          .format()
      );
    }
    setDate(weekStart);
    setWeekDates(days);
    handleActiveMonth(days[0]);
    handleChangeWeek(
      moment(days[0]).format("YYYY-MM-DD"),
      moment(days[6]).format("YYYY-MM-DD"),
      false
    );
  };

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <span onClick={onClick} ref={ref}>
      <span
        style={{
          marginRight: 10,
          fontSize: 15,
          color: "black",
          fontWeight: "600"
        }}
      >
        {moment(date).format("MMM YYYY")}
      </span>
      {/* <i
        id="calender"
        className="fas fa-angle-down"
        style={{ fontSize: 15 }}
      ></i> */}
    </span>
  ));
  const IsTaskExist = d => {
    if (isEqualDate(weekDatesHasTask, d)) {
      return (
        <i
          className={`fa fa-circle fs-6 `}
          style={{
            color: topbarTheme.buttonColor,
            paddingTop: "10px"
          }}
        />
      );
    } else {
      return (
        <i
          className={`fa fa-circle fs-6`}
          style={{
            color: "#FFF",
            paddingTop: "10px"
          }}
        />
      );
    }
  };
  return (
    <>
      <div>
        <div
          className="roe-card-header"
          style={{
            paddingLeft: 0,
            height: 60,
            backgroundColor: "white",
            // display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #EBEBEB",
            lineHeight: 3
          }}
        >
          <div className="file-manager-toolbar cursor-pointer">
            {/* <RoyTooltip id="datePicker" title="Select custom date"> */}
            <div className={"row"}>
              <div className="col-md-10 col-sm-10 col-lg-10 col-xl-10 col-xs-12">
                <div id="datePicker">
                  <span className="fs-14 demi-bold-text">
                    <span
                      style={{
                        marginRight: 10,
                        fontSize: 15,
                        color: "black",
                        fontWeight: "600"
                      }}
                    >
                      {activeMonth}
                      {/* {moment(date).format("MMM YYYY")} */}
                    </span>
                    {/* <DatePickerWrapper>
                    <DatePicker
                      value={selectedUpcomingDate}
                      selected={selectedUpcomingDate}
                      onChange={e => {
                        setSelectedUpcomingDate(e);
                        setDate(e);
                        props.updateSelectedDate(e);

                        fetchUpcomingTask(e);
                      }}
                      dateFormat="dd MMM yy"
                      className="custom-datepicker"
                      minDate={new Date()}
                      customInput={<ExampleCustomInput />}
                    />
                  </DatePickerWrapper> */}
                  </span>
                </div>
              </div>
              <div
                className="col-md-2 col-sm-2 col-lg-2 col-xl-2 col-xs-12"
                align="center"
                style={{ paddingRight: 0 }}
              >
                <div
                  title={InjectPlaceHolderMessage(
                    "today.go_to_today",
                    props.language
                  )}
                  style={{
                    marginRight: 10,
                    fontSize: 15,
                    fontWeight: "500"
                  }}
                  onClick={() => {
                    handleChangeSelectionDateToToday();
                  }}
                >
                  <span
                    title={InjectPlaceHolderMessage(
                      "today.go_to_today",
                      props.LanguageSwitcher.language.languageId
                    )}
                    style={{
                      border: "1px solid #d3d2d2",
                      borderRadius: 5,
                      padding: "7px 14px",
                      color: "gray"
                    }}
                  >
                    {<IntlMessages id={"today.today"} />}
                  </span>
                </div>
              </div>
            </div>

            {/* </RoyTooltip> */}
          </div>
        </div>

        <div className="row  mt-20" style={{ marginBottom: 25 }}>
          <div
            className="col-1"
            align="left"
            style={{ display: "flex", alignItems: "center" }}
          >
            <RoyTooltip
              id="prevBtn"
              title={InjectPlaceHolderMessage(
                "upcoming.previousweek",
                props?.LanguageSwitcher?.language?.languageId
              )}
            >
              <WeekDateButtonWrapper {...props}>
                <div
                  className="btn"
                  onClick={prevWeek}
                  id="prevBtn"
                  style={{
                    color: "grey",
                    cursor: "pointer",
                    height: "33px",
                    width: "33px",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 5
                    // textDecoration: "underline"
                  }}
                >
                  <img
                    src={LeftWeekDatePickerImage}
                    style={{
                      height: 12,
                      width: 6
                    }}
                  ></img>
                  {/* <i className="fa fa-chevron-left"></i> */}
                </div>
              </WeekDateButtonWrapper>
            </RoyTooltip>
          </div>
          <div
            className="col-10"
            align="center "
            style={{ backgroundColor: "white", borderRadius: "10px" }}
          >
            <ul className="weekdays">
              <li>
                <IntlMessages id="upcoming.mon" />
              </li>
              <li>
                <IntlMessages id="upcoming.tue" />
              </li>
              <li>
                <IntlMessages id="upcoming.wed" />
              </li>
              <li>
                <IntlMessages id="upcoming.thu" />
              </li>
              <li>
                <IntlMessages id="upcoming.fri" />
              </li>
              <li>
                <IntlMessages id="upcoming.sat" />
              </li>
              <li>
                <IntlMessages id="upcoming.sun" />
              </li>
            </ul>
            <ul className="days">
              <WeekDatePickerWrapper {...props}>
                {weekDates.map((d, i) => {
                  return (
                    <li
                      style={{ cursor: "pointer" }}
                      id={`day-${moment(d).format("DD")}`}
                      className={
                        activeDay == `day-${moment(d).format("DD")}`
                          ? "active day"
                          : "day "
                      }
                      key={moment(d).format("DD MM YYYY")}
                      onClick={() => {
                        props.updateSelectedDate(d);
                        fetchUpcomingTask(d);
                        setActiveDay(`day-${moment(d).format("DD")}`);
                        setActiveMonth(moment(d).format("MMM YYYY"));
                      }}
                    >
                      <div style={{ display: "inline-grid" }}>
                        <span
                          style={{
                            backgroundColor:
                              moment().format("DD-MM-yyyy") ===
                              moment(d).format("DD-MM-yyyy")
                                ? `${topbarTheme.buttonColor}`
                                : "white",
                            borderRadius: "50%",
                            width: "35px",
                            height: "35px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color:
                              moment().format("DD-MM-yyyy") ===
                              moment(d).format("DD-MM-yyyy")
                                ? "white"
                                : "black"
                          }}
                        >
                          {moment(d).format("DD")}
                        </span>
                        {IsTaskExist(d)}
                      </div>
                    </li>
                  );
                })}
              </WeekDatePickerWrapper>
            </ul>
          </div>
          <div
            className="col-1"
            align="right"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end"
            }}
          >
            <RoyTooltip
              id="nextBtn"
              title={InjectPlaceHolderMessage(
                "upcoming.nextweek",
                props?.LanguageSwitcher?.language?.languageId
              )}
            >
              <WeekDateButtonWrapper>
                <div
                  className="btn"
                  onClick={nextWeek}
                  id="nextBtn"
                  style={{
                    color: "grey",
                    cursor: "pointer",
                    height: "33px",
                    width: "33px",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 5
                    // textDecoration: "underline"
                  }}
                >
                  <img
                    src={RightWeekDatePickerImage}
                    style={{
                      height: 12,
                      width: 6
                    }}
                  ></img>
                  {/* <i className="fa fa-chevron-right"></i> */}
                </div>
              </WeekDateButtonWrapper>
            </RoyTooltip>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    businessId: state.auth.user?.businessId,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, null)(WeekDatePicker);
