import React, { useState } from "react";
import moment from "moment";
import "./attachmentComment.style.css";
import ModalImage, { Lightbox } from "react-modal-image";
import { connect } from "react-redux";
import { calc_file_size } from "helper/methods/GetFileSize";
import { pdfIcon } from "helper/constant";
const filePlaceholder = require("assets/images/file.png");

const TaskAllAttachmentsImage = props => {
  const { comment, topbarTheme, pageType, handleRedirect } = props;
  const MEME_TYPE = {
    IMAGE_PNG: "image/png",
    IMAGE_JPG: "image/jpg",
    IMAGE_JPEG: "image/jpeg",
    VIDEO_FLASH: "video/x-flv",
    VIDEO_IPHONE_INDEX: "application/x-mpegURL",
    VIDEO_IPHONE_SEGMENT: "video/MP2T",
    VIDEO_3GP: "video/3gpp",
    VIDEO_MP4: "video/mp4",
    VIDEO_QUICK_TIME: "video/quicktime",
    VIDEO_AV_INTERLEAVE: "video/x-msvideo",
    VIDEO_WINDOWS_MEDIA: "video/x-msvideo",
    DOCUMENT_PDF: "application/pdf"
  };
  const [openLighBox, setOpenLightBox] = useState([]);
  const handleDocumentDownload = async (url, name) => {
    const image = await fetch(url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const GetMimetype = attachment => {
    if (
      attachment.mimetype == MEME_TYPE.IMAGE_PNG ||
      attachment.mimetype == MEME_TYPE.IMAGE_JPG ||
      attachment.mimetype == MEME_TYPE.IMAGE_JPEG
    ) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxHeight: "300px",
            width: "100%"
          }}
        >
          <img
            alt=""
            className="image-attachment"
            src={attachment.url}
            style={{
              height: "300px",
              width: "100%",
              objectFit: "contain",
              cursor: "zoom-in"
            }}
            onClick={() =>
              setOpenLightBox({
                url: attachment.url,
                id: attachment.id
              })
            }
          />
          {openLighBox &&
            openLighBox.url &&
            openLighBox.id &&
            attachment.id == openLighBox.id && (
              <Lightbox
                hideZoom
                hideDownload
                small={openLighBox.url}
                large={openLighBox.url}
                onClose={() => setOpenLightBox("")}
                imageBackgroundColor={"handy"}
              />
            )}
          {/* <ModalImage
            small={attachment.url}
            large={attachment.url}
            hideZoom={false}
            hideDownload={false}
          /> */}
        </div>
      );
    }

    if (
      attachment.mimetype == MEME_TYPE.VIDEO_3GP ||
      attachment.mimetype == MEME_TYPE.VIDEO_FLASH ||
      attachment.mimetype == MEME_TYPE.VIDEO_IPHONE_INDEX ||
      attachment.mimetype == MEME_TYPE.VIDEO_IPHONE_SEGMENT ||
      attachment.mimetype == MEME_TYPE.VIDEO_QUICK_TIME ||
      attachment.mimetype == MEME_TYPE.VIDEO_AV_INTERLEAVE ||
      attachment.mimetype == MEME_TYPE.VIDEO_WINDOWS_MEDIA ||
      attachment.mimetype == MEME_TYPE.VIDEO_MP4
    ) {
      return (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <video width="100%" height="100%" controls muted>
            <source
              src={attachment.url}
              type={
                attachment.mimetype == MEME_TYPE.VIDEO_QUICK_TIME
                  ? "video/mp4"
                  : attachment.mimetype
              }
            />
          </video>
        </div>
      );
    }
    if (attachment.mimetype == MEME_TYPE.DOCUMENT_PDF) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%"
          }}
        >
          <a href={attachment.url} target="_blank">
            <img
              alt=""
              src={pdfIcon}
              style={{
                height: 60
                // margin: 10
              }}
            />
          </a>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%"
        }}
      >
        <a href={attachment.url} target="_blank" rel="noopener noreferrer">
          <img
            alt=""
            src={filePlaceholder}
            style={{
              height: 60
              // margin: 10
            }}
          />
        </a>
      </div>
    );
  };
  return (
    <>
      {comment && comment.commentMeta && (
        <div
          style={{
            padding: 0,
            marginTop: 2
          }}
        >
          <div className="" align="left">
            {GetMimetype(comment.commentMeta)}
            <div
              style={{
                textAlign: "center"
              }}
            >
              <div style={{ display: "block" }}>
                <a
                  href={comment.commentMeta.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontSize: "14px",
                    color: topbarTheme.buttonColor,
                    textDecoration: "underline"
                  }}
                >
                  <span>{comment.commentMeta.originalname}</span>
                  {/* <i
                    style={{
                      color: "black",
                      marginLeft: 5,
                      cursor: "pointer"
                    }}
                    className="fa fa-link fs-10"
                  ></i> */}
                </a>

                <br />
                <div style={{ fontSize: "12px", padding: 5 }}>
                  <span style={{ fontSize: "12px" }}>
                    {comment.commentBy?.name}
                  </span>{" "}
                  on{" "}
                  <span style={{ fontSize: "12px" }}>
                    {moment(comment.commentedAt).format("MMM DD")}
                  </span>
                  <br></br>
                  <div
                    className="flex-x text-center"
                    style={{ justifyContent: "center" }}
                  >
                    {pageType && (
                      <span
                        onClick={handleRedirect}
                        style={{
                          fontSize: 12,
                          cursor: "pointer",
                          textDecoration: "underline"
                        }}
                      >
                        <ul>
                          {pageType == "task"
                            ? "View in task"
                            : "View in project comment"}
                        </ul>
                      </span>
                    )}
                    &nbsp;-&nbsp;
                    <span
                      style={{
                        fontSize: 12,
                        cursor: "pointer",
                        textDecoration: "underline"
                      }}
                      onClick={() =>
                        handleDocumentDownload(
                          comment.commentMeta.url,
                          comment.commentMeta.originalname
                        )
                      }
                    >
                      <u>Download </u>{" "}
                      <span
                        style={{
                          color: "black",
                          fontSize: 12,
                          cursor: "pointer"
                        }}
                      >
                        {" "}
                        ({calc_file_size(comment.commentMeta.size)})
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {})(TaskAllAttachmentsImage);
