import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import Radium from "radium";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";

import NewTaskDescriptionEditorWrapper from "./NewTaskDescriptionEditorWrapper.style";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
import IntlMessages from "util/intlMessages";
var userCollabList = [];

function TaskDescriptionEditor({
  inputValue,
  onChange,
  setIsFocus,
  collaboratorsList,
  isPreviewEnabled,
  language
}) {
  // in some component where the editor is used

  useEffect(() => {
    if (collaboratorsList) {
      var collabs = [];
      collaboratorsList.map((data, i) => {
        if (data.user) {
          collabs.push({
            id: data.user.id,
            username: data.user.name,
            value: data.user.name
          });
        }
      });
      userCollabList = collabs;
    }
    return () => {};
  }, []);
  // Autofocus method
  const useFocusAndSetRef = ref => {
    ref = useCallback(
      node => {
        if (node !== null) {
          ref.current = node; // it is not done on it's own
          const len = inputValue.length;
          const selection = { index: len, length: len };
          node.setEditorSelection(node.editor, selection);
        }
      },
      [ref]
    );

    return ref;
  };

  let editorRef;
  editorRef = useFocusAndSetRef(editorRef);
  const handleKeyDown = e => {
    if (e.shiftKey && e.keyCode == 13) {
      //   if (type == "comment") {
      //     addComment(description);
      //   } else {
      //     addDescription(description);
      //   }
    }
  };
  return (
    <>
      <div
        className="mt-0 "
        style={{ backgroundColor: "#f4f5f7", borderRadius: 5 }}
      >
        <NewTaskDescriptionEditorWrapper>
          <ReactQuill
            ref={editorRef}
            autoFocus
            // onBlur={() => setIsFocus(false)}
            value={inputValue}
            onChange={(content, delta, source, editor) => {
              onChange(content);
              if (content == "<p><br></p>") {
                onChange(null);
              }
            }}
            rows={"3"}
            theme={"snow"}
            placeholder={InjectPlaceHolderMessage(
              "today.description",
              language.language.languageId
            )}
            modules={TaskDescriptionEditor.modules}
            formats={TaskDescriptionEditor.formats}
            onKeyDown={e => handleKeyDown(e)}
            bounds={".app"}
            onBlur={(e, s, delta, sss) => {
              // if source s==silent then allow to copy else if it is user than toggle input
              if (s !== "silent") {
                // setIsFocus(false);
              }
            }}
          ></ReactQuill>
          {isPreviewEnabled && (
            <div
              style={{
                display: "flex",
                fontSize: 12,
                flexDirection: "row-reverse",
                textDecoration: "underline",
                color: "blue",
                paddingRight: 10,
                cursor: "pointer"
              }}
              onClick={() => setIsFocus(false)}
            >
              {<IntlMessages id={"today.preview"} />}
            </div>
          )}
        </NewTaskDescriptionEditorWrapper>
      </div>
    </>
  );
}
// export default CardTask;

// TaskDescriptionEditor.keyboard({
//   key: 'B',
//   shortKey: true
// }, function(range, context) {
//   TaskDescriptionEditor.formatText(range, 'bold', true);
// });

// addBinding may also be called with one parameter,
// in the same form as in initialization
// TaskDescriptionEditor.keyboard.addBinding({ key: 'B', shiftKey: true }, handler);

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
TaskDescriptionEditor.modules = {
  toolbar: {
    // handlers: {
    //   preview: function() {
    //     console.log("ytes");
    //     // setIsFocus(false);
    //   }
    // },
    container: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline", "strike", "blockquote", "link"],
      [{ list: "ordered" }, { list: "bullet" }]
      // ["preview"]

      // [
      //   {
      //     color: ["#F00", "#0F0", "#00F", "#000", "#FFF", "color-picker"]
      //   }
      // ]
    ]
  },
  clipboard: {
    matchVisual: false
  },
  // preview:function(e) {
  //   console.log("in")
  // },
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    source: function(searchTerm, renderList, mentionChar) {
      let values;
      if (mentionChar === "@") {
        values = userCollabList;
      }
      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    }
  }
};
TaskDescriptionEditor.formats = [
  "autofocus",
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "mention"
  // "preview"
  // "color"
];

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {})(Radium(TaskDescriptionEditor));
