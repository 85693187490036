import ProjectCard from "components/WorkspaceDetails/ProjectCard";
import ProjectTab from "components/WorkspaceDetails/ProjectTab";
import ToastMessage from "components/notifications/ToastMessage";
import { getAllBusiness } from "helper/services/businessServices";
import {
  getFavoriteProjects,
  getLables,
  getProject
} from "helper/services/projectServices";
import React, { useEffect, useState, useRef } from "react";
import { useToasts } from "react-toast-notifications";
import AddProject from "components/projects/AddProject";
import {
  checkforunique,
  sidebarData,
  updateSidebarLabelToSidebar,
  updateSidebarProjectToSidebar
} from "util/data/sidebar";
import { getUserCharColor } from "helper/methods/PlaceholderColor";
import { connect } from "react-redux";
import projectActions from "redux/projects/actions";
import LinearLoader from "components/loader/LinearLoader";
import CardShimmer from "components/shimmer/CardShimmer";
import OneLineShimmer from "components/shimmer/OneLineShimmer";
import CreateWorkspaceInviteTeam from "components/business/CreateWorkspace&InviteTeam";
import BusinessSidebar from "components/sidebar/BusinessSidebar";
import workspaceDropdownAction from "redux/workspaceDropdown/actions";
import IntlMessages from "util/intlMessages";
const { update_Project, set_projects } = projectActions;
const { open_workspace_modal, close_workspace_modal } = workspaceDropdownAction;

const StyleSheet = {
  container: {
    border: "0px solid rgba(255, 255, 255, 1)",
    borderRadius: "4px"
  },

  line: {
    width: "40%",
    height: "45px"
    // alignSelf: "center",
    // marginLeft: "16px"
  }
};

const AllBusiness = props => {
  const { classes } = props;
  const [business, setBusiness] = useState([]);
  const [isLoader, setIsloader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [isNextPageLoader, setIsNextPageLoader] = useState(false);
  const [isAlertAction, setIsAlertAction] = useState({
    status: false,
    data: ""
  });
  const { addToast } = useToasts();
  const [projectArr, setProjectArr] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const [isAddProject, setIsAddProject] = useState({
    business: "",
    toggle: false
  });
  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    totalPages: 0,
    isLastPage: false
  });
  const [addBusiness, setAddBusiness] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryProjectArr, setSearchQueryProjectArr] = useState([]);
  const violationRef = useRef();
  const [workspaceId, setWorkspaceId] = useState();
  const [sidebarEvent, setSidebarEvent] = useState();
  const [isDefaultBusiness, setIsDefaultBusiness] = useState(false);
  const [favouritesArrayList, setFavouritesArrayList] = useState([]);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  useEffect(() => {
    if (props.auth.accessToken && props.auth.isLogin) {
      fetchBusiness();
      setIsAddProject({ business: "", toggle: false });
    }

    return () => {
      setCurrentPage(0);
      setIsAlertAction({ status: false, data: "" });
      setIsloader(false);
      setPageInfo({
        currentPage: 0,
        totalPages: 0,
        isLastPage: false
      });
      setProjectArr([]);
      setIsOwner(false);
      setFavouritesArrayList([]);
    };
  }, []);

  // resize width
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  const fetchProject = async token => {
    const Response = await getProject(
      token
        ? token
        : props.auth.accessToken || props.history.location.state.token
    );
    if (Response && Response?.data) {
      props.set_projects({ projects: Response.data });

      UpdateSidebarProject(Response.data);
    }
  };
  const handleSearch = query => {
    setSearchQuery(query);
    let filteredProjects = [];
    if (!query.replace(/\s/g, "").length) {
      setIsSearch(false);
    } else {
      filteredProjects = favouritesArrayList.filter(project => {
        return project.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
      setSearchQueryProjectArr(filteredProjects);
      setIsSearch(true);
    }
  };
  const fetchLabels = async token => {
    try {
      const Response = await getLables(
        token
          ? token
          : props.auth.accessToken || props.history.location.state.token
      );
      if (Response) {
        if (Response.data) {
          props.set_labels({ labels: Response.data });
          updateSidebarLabelToSidebar(Response.data);
        }
        if (Response.error) {
        }
      }
    } catch (e) {}
  };

  const handleResponse = message => {
    try {
      if (
        message.toLocaleLowerCase() == "Business not found".toLocaleLowerCase()
      ) {
        setIsAlertAction({ status: true, data: "" });
      }
    } catch (e) {}
  };
  const updateProjectandLabel = async token => {
    fetchProject(token);
    fetchLabels(token);
  };

  // Api data of All getAllBusiness

  const fetchBusiness = async (isNextPage = false) => {
    try {
      !isNextPage && setIsloader(true);
      let nextPage = pageInfo.currentPage + 1;
      isNextPage && setIsNextPageLoader(true);
      const { data, error } = await getAllBusiness(
        props.auth.accessToken || props.history.location.state.token,
        10,
        nextPage
      );

      if (data) {
        const response = data.data;

        if (response.error) {
          handleResponse(response.error.message);
          setIsloader(false);
          showNotifications();
        }
        if (response) {
          if (hasMorePages && nextPage > 1) {
            setBusiness(business ? [...business, ...response] : [...response]);
          } else {
            setBusiness([...response]);
          }
          setPageInfo({
            currentPage: parseInt(data.currentPage),
            totalPages: data.totalPages,
            isLastPage: data.isLastPage
          });
          fetchFavoriteProjects();
        }

        setIsloader(false);
        isNextPage && setIsNextPageLoader(false);
        setHasMorePages(!data.isLastPage);
        setCurrentPage(nextPage);
      }
    } catch (error) {
      isNextPage && setIsNextPageLoader(false);
      setIsloader(false);
    }
    // finally {
    //   isNextPage && setIsNextPageLoader(false);
    //   setIsloader(false);
    // }
  };

  const fetchFavoriteProjects = async () => {
    try {
      setIsloader(true);
      const response = await getFavoriteProjects(
        props.auth.accessToken || props.history.location.state.token
      );

      if (response) {
        if (response.data) {
          setIsloader(false);
          if (response.data && response.data?.length)
            setFavouritesArrayList([...response.data]);
        }
        if (response.error) {
          setIsloader(false);
        }
      }
    } catch (error) {
      setIsloader(false);
    }
  };
  const UpdateSidebarProject = ResponseData => {
    updateSidebarProjectToSidebar(ResponseData);
  };
  const updateProjectToRedux = data => {
    props.update_Project(data);
  };
  const pushProject = async data => {
    if (isAddProject.business === data.business.id) {
      if (data) {
        await setProjectArr([...projectArr, data]);
      }
    }

    // business
    let index = -1;
    business &&
      business.map((b, i) => {
        if (b.id === data.business.id) {
          index = i;
        }
      });
    let tempBus = [...business];
    tempBus[index].project.push(data);
    setBusiness(tempBus);

    // favourite project
    let tempProject = [...favouritesArrayList];
    if (data.isFavorite === true) {
      tempProject.push(data);
      setFavouritesArrayList([...tempProject]);
    }
  };

  // handle redirection on confirm start
  // const doSwitchBusiness = async () => {
  //     if (isAlertAction.status) {
  //         setIsAlertActionLoader(true);
  //         const response = await handleSwitchWorkspace(
  //             isAlertAction.data.business.id,
  //             id => handleRedirection(isAlertAction.data, id),
  //             showNotifications
  //         );
  //         if (response.status) {
  //             setIsAlertActionLoader(false);
  //         } else {
  //             console.error(response.error);
  //         }
  //     }
  // };
  // const handleRedirection = (item, newBusinessId) => {
  //     if (item.redirection_type && item.redirection_type === "business") {
  //         if (item.business.id) {
  //             if (
  //                 item.business.id == newBusinessId
  //             ) {
  //                 return props.history.push(`/business/${item.business.id}`);
  //             } else {
  //                 setIsAlertAction({ status: true, data: item });
  //             }
  //         }
  //         return false;
  //     }
  //     if (item.redirection_type && item.redirection_type === "project") {
  //         if (item.project) {
  //             if (
  //                 item.project == newBusinessId
  //             ) {
  //                 return props.history.push(`/project/${item.project}`);
  //             } else {
  //                 setIsAlertAction({ status: true, data: item });
  //             }
  //         }
  //         return false;
  //     }
  // };
  // const getMessageType = item => {
  //     if (item.redirection_type && item.redirection_type === "business") {
  //         return (
  //             <>
  //                 Switch the workspace to see the{" "}
  //                 {item.business.title ? <b>{item.business.title}</b> : "different"}{" "}
  //                 workspace details now.
  //             </>
  //         );
  //     }
  //     if (item.redirection_type && item.redirection_type === "project") {
  //         return (
  //             <>
  //                 Switch the project to see the{" "}
  //                 {item.name ? <b>{item.name}</b> : "different"}{" "}
  //                 project details now.
  //             </>
  //         );
  //     }
  // };
  // handleredirection on confirm end

  const handleGoTOProject = async projectId => {
    props.history.push(`/project/${projectId}`);
    // if (handleRedirection) {
    //     console.log(projectId)
    //     return handleRedirection({
    //         redirection_type: "project",
    //         project: projectId,
    //     })
    // }
    // if (isDefaultBusiness) {
    //   props.history.push(`/project/${projectId}`);
    // } else {
    //   // await handleSwitchBusiness(id);
    //   props.history.push(`/project/${projectId}`);
    // }
  };
  const updateExistingProjectToRedux = data => {
    props.update_projects_existing({ projects: data });
  };
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });
  };

  //scrollIntoView go to  project/workspace

  const handleEventClick = event => {
    setWorkspaceId(event);
  };

  const handleButtonClick = () => {
    if (workspaceId) {
      violationRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  };
  const doOpenModalFromSidebar = (data, sidebar) => {
    setSidebarEvent(sidebar);
    setAddBusiness(data);
  };

  return (
    <>
      {isLoader ? (
        <>
          <div
            className="container"
            style={{ marginTop: "9rem", marginBottom: "9rem" }}
          >
            <div className="row">
              <div className="col-lg-3 col-md-3 col-xs-12 col-sm-12">
                <OneLineShimmer />
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12" />
              <div className="col-lg-3 col-md-3 col-xs-12 col-sm-12">
                <OneLineShimmer />
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-12 col-sm-12 col-xs-12 col-md-12"
                style={{
                  padding: 10,
                  margin: "4rem 0rem 2rem"
                }}
              >
                <div className="project-card">
                  <CardShimmer />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-xs-12 col-sm-12">
                <OneLineShimmer />
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12" />
              <div className="col-lg-3 col-md-3 col-xs-12 col-sm-12">
                <OneLineShimmer />
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-12 col-sm-12 col-xs-12 col-md-12"
                style={{
                  padding: 10,
                  margin: "4rem 0rem 2rem"
                }}
              >
                <div className="project-card">
                  <CardShimmer />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : business && business.length > 0 ? (
        <>
          <div
            style={{
              background: "#fff",
              height:
                Object.keys(business).length > 1 ? "auto" : window.innerHeight
            }}
          >
            <div className="row d-flex flex-row justify-content-center align-items-start">
              <div style={{ maxWidth: 1180, width: "100%" }}>
                <div className="row">
                  <div className="col-xxl-2 col-lg-3 col-sm-0 col-xs-0 col-md-0 d-flex justify-content-end p-0">
                    <BusinessSidebar
                      handleEventClick={handleEventClick}
                      business={business}
                      handleButtonClick={handleButtonClick}
                      doOpenModalFromSidebar={doOpenModalFromSidebar}
                      toggleAddProject={result => {
                        setIsAddProject({
                          business: result,
                          toggle: !isAddProject.toggle
                        });
                      }}
                      handleGoTOProject={handleGoTOProject}
                      windowSize={windowSize}
                    />
                  </div>
                  <div className="col-xxl-10 col-xl-9 col-lg-9 col-sm-12 col-xs-12 col-md-12 ">
                    {favouritesArrayList && favouritesArrayList.length > 0 ? (
                      <>
                        <div
                          className="px-4"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "2rem 0px",
                            justifyContent: "flex-start"
                          }}
                        >
                          <div
                            style={{
                              color: "black",
                              fontWeight: 700,
                              fontSize: 16,
                              textAlign: "center",
                              whiteSpace: "nowrap"
                            }}
                          >
                            <IntlMessages id={"project.myfavourite"} />
                          </div>

                          {/* <div
                          style={{
                            height: 1,
                            width: "100%",
                            borderBottom: "1px solid #dfe1e6",
                          }}
                        ></div> */}
                        </div>

                        {/* favourite start */}
                        {/*                              
                              <div
                                className="project-search"
                                style={{
                                  width: " 16rem",
                                  float: "right",
                                  marginTop: " -4rem"
                                }}
                              >
                                <ProjectTabWrapper>
                                  Search into favourite projects 
                                  <InputGroup className="input-search-group">
                            <InputGroupAddon
                              addonType="prepend"
                              className="icon-search-input"
                              size="sm"
                            >
                              <img
                                src={search}
                                style={{
                                  marginLeft: "5px",
                                  height: 14,
                                  width: 14,
                                  
                                }}
                                alt=""
                              />
                            </InputGroupAddon>

                            <Input
                              style={{ backgroundColor: "#F5F5F5" }}
                              value={searchQuery}
                              onChange={(e) => handleSearch(e.target.value)}
                              placeholder="Search"
                              className="no-border search-input outline-none"
                            />

                            {searchQuery?.length > 0 && (
                              <InputGroupAddon
                                addonType="append"
                                className="icon-search-input"
                                size="sm"
                                onClick={() => {
                                  setSearchQuery("");
                                  setIsSearch(false);
                                }}
                              >
                                <img
                                  id="close"
                                  src={TaskDialogCrossImage}
                                  height="12"
                                  width="12"
                                  style={{ marginRight: "10px" }}
                                  alt="Labels"
                                />
                                <i
                    className="fas fa-times close-search  fs-16"
                    style={{ paddingRight: 5 }}
                  ></i>
                              </InputGroupAddon>
                            )}
                          </InputGroup>
                                </ProjectTabWrapper>
                              </div>
                              */}
                        <div className="row px-4">
                          {isLoader ? (
                            <>
                              <div
                                className="col-lg-4 col-sm-12 col-xs-12 col-md-4"
                                style={{
                                  padding: 10
                                }}
                              >
                                <div className="project-card">
                                  <CardShimmer />
                                </div>
                              </div>
                              <div
                                className="col-lg-4 col-sm-12 col-xs-12 col-md-4"
                                style={{
                                  padding: 10
                                }}
                              >
                                <div className="project-card">
                                  <CardShimmer />
                                </div>
                              </div>
                              <div
                                className="col-lg-4 col-sm-12 col-xs-12 col-md-4"
                                style={{
                                  padding: 10
                                }}
                              >
                                <div className="project-card">
                                  <CardShimmer />
                                </div>
                              </div>
                            </>
                          ) : searchQueryProjectArr?.length > 0 && isSearch ? (
                            searchQueryProjectArr &&
                            isSearch &&
                            searchQueryProjectArr?.length > 0 && (
                              <>
                                {searchQueryProjectArr &&
                                  searchQueryProjectArr?.length > 0 &&
                                  searchQueryProjectArr.map((project, i) => {
                                    return (
                                      <ProjectCard
                                        targetAppend="search"
                                        project={project}
                                        handleGoTOProject={() =>
                                          handleGoTOProject(project.id)
                                        }
                                        favouritesArrayList={
                                          favouritesArrayList
                                        }
                                        setFavouritesArrayList={
                                          setFavouritesArrayList
                                        }
                                        showNotifications={showNotifications}
                                      />
                                    );
                                  })}
                              </>
                            )
                          ) : (
                            !isSearch &&
                            favouritesArrayList &&
                            favouritesArrayList.map(favProject => (
                              <ProjectCard
                                project={favProject}
                                handleGoTOProject={() =>
                                  handleGoTOProject(favProject.id)
                                }
                                favouritesArrayList={favouritesArrayList}
                                setFavouritesArrayList={setFavouritesArrayList}
                                showNotifications={showNotifications}
                              />
                            ))
                          )
                          // favouritesArrayList &&
                          // favouritesArrayList.map(favProject => (
                          //   // <FavouriteProjectCard
                          //   //   project={favProject}
                          //   //   handleGoTOProject={() => handleGoTOProject(favProject.id)}
                          //   // />
                          //   <ProjectCard
                          //     project={favProject}
                          //     handleGoTOProject={() => handleGoTOProject(favProject.id)}
                          //   />
                          // ))
                          }
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* favourite end */}

                    {/* all workspace project start */}
                    <>
                      {favouritesArrayList && favouritesArrayList.length > 0 ? (
                        <div
                          className="px-4"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // padding: "2.5rem 0px",
                            marginTop: "3.2rem",
                            justifyContent: "flex-start"
                          }}
                        >
                          <div
                            style={{
                              color: "black",
                              fontWeight: 700,
                              fontSize: 16,
                              whiteSpace: "nowrap"
                            }}
                          >
                            <IntlMessages
                              id={"project.workspacewithprojects"}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "#fff",
                          marginTop: "-34px"
                        }}
                      >
                        {business.map((result, i) => (
                          <div
                            className="px-4"
                            style={
                              {
                                // paddingBottom: "6.25rem"
                              }
                            }
                          >
                            <div
                              key={result.id}
                              className="row d-flex align-items-center"
                              style={{
                                margin: "4rem 0rem 2rem -0.9rem"
                              }}
                            >
                              <div className="col-lg-8 col-md-8 col-sm-0 col-xs-0">
                                <div
                                  ref={
                                    result.id == workspaceId
                                      ? violationRef
                                      : null
                                  }
                                  className="flex"
                                  style={{
                                    alignItems: "center"
                                    //   margin: "4px",
                                    //   marginBottom: "10px"
                                  }}
                                >
                                  <div className="workspace-Avatar">
                                    {result && result?.businessMeta ? (
                                      <img
                                        alt="workspace"
                                        src={result?.businessMeta?.url}
                                        height="42px"
                                        width="44px"
                                        style={{
                                          borderRadius: "7px"
                                        }}
                                      />
                                    ) : (
                                      <div
                                        className=" workspace-placeholder"
                                        style={{
                                          // background: props.topbarTheme.buttonColor,
                                          background: getUserCharColor(
                                            result?.title.charAt(0)
                                          ),
                                          height: "42px",
                                          width: "44px",
                                          fontSize: "22px"
                                        }}
                                      >
                                        {result &&
                                          result?.title[0]?.toUpperCase()}
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      color: "#877457",
                                      cursor: "pointer",
                                      marginLeft: "4px"
                                    }}
                                    title="Go to workspace"
                                    onClick={() =>
                                      props.history.push(
                                        `/business/${result.id}`
                                      )
                                    }
                                    // onClick={() => handleRedirection &&
                                    //     handleRedirection({
                                    //         redirection_type: "business",
                                    //         project: result.project,
                                    //         business: result
                                    //     })}
                                  >
                                    {result?.title}
                                  </div>
                                </div>
                              </div>
                              <div className="content-center col-lg-4 col-md-4 col-sm-0 col-xs-0 d-flex  p-0">
                                <button
                                  style={{
                                    position: "relative",
                                    background: "#e0e0e0",
                                    padding: "6px 12px",
                                    color: "#000",
                                    fontWeight: 400,
                                    whiteSpace: "nowrap",
                                    borderRadius: "5px",
                                    height: "36px",
                                    border: "none"
                                  }}
                                  onClick={() =>
                                    props.history.push(
                                      `/business/${result.id}`,
                                      {
                                        tabId: "project"
                                      }
                                    )
                                  }
                                >
                                  <IntlMessages id={"workspace.projects"} />

                                  <label
                                    style={{
                                      color: "#6b6565",
                                      paddingLeft: "3px"
                                    }}
                                  >
                                    {" "}
                                    ({result.project.length})
                                  </label>
                                </button>

                                <button
                                  style={{
                                    marginLeft: "15px",
                                    background: "#e0e0e0",
                                    padding: "6px 12px",
                                    whiteSpace: "nowrap",
                                    borderRadius: "5px",
                                    height: "36px",
                                    border: "none"
                                  }}
                                  onClick={() =>
                                    props.history.push(
                                      `/business/${result.id}`,
                                      {
                                        tabId: "member"
                                      }
                                    )
                                  }
                                >
                                  <IntlMessages id={"workspace.members"} />

                                  <label
                                    style={{
                                      color: "#6b6565",
                                      paddingLeft: "3px"
                                    }}
                                  >
                                    {" "}
                                    ({result.collaboratorCount})
                                  </label>
                                </button>

                                <button
                                  style={{
                                    marginLeft: "15px",
                                    background: "#e0e0e0",
                                    padding: "6px 12px",
                                    whiteSpace: "nowrap",
                                    borderRadius: "5px",
                                    height: "36px",
                                    border: "none"
                                  }}
                                  onClick={() =>
                                    props.history.push(
                                      `/business/${result.id}`,
                                      {
                                        tabId: "settings"
                                      }
                                    )
                                  }
                                >
                                  <IntlMessages id={"workspace.settings"} />
                                </button>
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{
                                backgroundColor: "#fff",
                                marginTop: "3rem",
                                // marginBottom: "5rem",
                                // padding: "5rem",
                                borderRadius: "10px"
                              }}
                            >
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <ProjectTab
                                  isLoader={isLoader}
                                  projectArr={result.project}
                                  isOwner={isOwner}
                                  topbarTheme={props.topbarTheme}
                                  toggleAddProject={() => {
                                    setIsAddProject({
                                      business: result.id,
                                      toggle: !isAddProject.toggle
                                    });
                                  }}
                                  showNotifications={showNotifications}
                                  handleGoTOProject={handleGoTOProject}
                                  updateProjectToRedux={
                                    updateExistingProjectToRedux
                                  }
                                  hideSearchBar
                                  favouritesArrayList={favouritesArrayList}
                                  setFavouritesArrayList={
                                    setFavouritesArrayList
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>

                    {business && business.length > 0
                      ? hasMorePages && (
                          <div
                            style={{
                              color: props.PRIMARY_COLOR,
                              fontSize: 14,
                              textDecoration: isNextPageLoader
                                ? "none"
                                : "underline",
                              textAlign: "center",
                              cursor: isNextPageLoader
                                ? "not-allowed"
                                : "pointer"
                              // paddingBottom: 10
                            }}
                          >
                            {isNextPageLoader ? (
                              <LinearLoader />
                            ) : (
                              <div
                                className="px-4"
                                style={{
                                  height: 100,
                                  display: "flex",
                                  justifyContent: "center"
                                  // paddingTop: "2.09rem"
                                }}
                                onClick={() =>
                                  hasMorePages
                                    ? fetchBusiness(hasMorePages)
                                    : {}
                                }
                              >
                                <IntlMessages id={"globalsearchbar.viewmore"} />
                              </div>
                            )}
                          </div>
                        )
                      : ""}

                    {/* all workspace project end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ backgroundColor: "white" }}>
          <div
            className=""
            style={{
              fontSize: 14,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <span
              style={{
                fontWeight: 400,
                fontSize: 14,
                color: "black",
                paddingTop: "5rem"
              }}
            >
              <IntlMessages id={"project.pleaseaddworkspace"} />
            </span>
          </div>

          <div
            className="text-muted"
            style={{
              paddingTop: 20,
              fontSize: 14,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <button
              type="submit"
              className="btn btn-lg"
              style={{
                backgroundColor: props.topbarTheme.buttonColor,
                border: "none",
                color: "#FFF"
              }}
              onClick={() => {
                setAddBusiness(!addBusiness);
                setSidebarEvent(false);
              }}
            >
              <IntlMessages id={"globalworkspacelist.addworkspace"} />
            </button>
          </div>
        </div>
      )}

      <AddProject
        modal={isAddProject.toggle}
        setmodal={() =>
          setIsAddProject({
            business: "",
            toggle: !isAddProject.toggle
          })
        }
        showNotifications={showNotifications}
        history={route => props.history.push(route)}
        updateRedux={data => {
          pushProject(data);
          if (props.auth.user.businessId === data.business.id) {
            updateProjectToRedux(data);
          }
        }}
        UpdateSidebarProject={data => {
          UpdateSidebarProject(data);
        }}
        checkforunique={(datachild, toCheckValue) =>
          checkforunique(datachild, toCheckValue)
        }
        defaultWorkspaceId={isAddProject.business}
        sidebarData={sidebarData}
      />

      {/* <AddBusiness
        status={"Add"}
        modal={addBusiness}
        // setmodal={() => setAddBusiness(!addBusiness)}
        updateRedux={(data) => updateProjectToRedux(data)}
        updateProjectandLabel={(token, businessName, businessMeta) => {
          updateProjectandLabel(token);
          setTimeout(() => {
            // Delay this action by one second
          }, 200);
        }}
        isExistingUser={true}
        // history={(route, data) => props.history.push(route, { ...data })}
      /> */}
      <CreateWorkspaceInviteTeam
        modal={addBusiness}
        setmodal={() => setAddBusiness(!addBusiness)}
        // topbarTheme,
        updateProjectandLabel={(token, businessName, businessMeta) => {
          updateProjectandLabel(token);
          setTimeout(() => {
            // Delay this action by one second
          }, 200);
        }}
        // logout,
        updateRedux={data => updateProjectToRedux(data)}
        isExistingUser={true}
        // workspaceDetails
        history={(route, data) => props.history.push(route, { ...data })}
        sidebarEvent={sidebarEvent}
      />
    </>
  );
};
const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme }
  };
};

export default connect(mapStateToProps, {
  update_Project,
  set_projects,
  open_workspace_modal,
  close_workspace_modal
})(AllBusiness);
