import actions from "./actions";

const initState = {
  InternetState: localStorage.getItem("InternetState")
    ? localStorage.getItem("InternetState")
    : {
        InternetState: { isInternetActivated: false }
      }
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.CHANGE_STATE:
      return {
        ...state,
        isInternetActivated: action.isInternetActivated
      };
    default:
      return state;
  }
}
