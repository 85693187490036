import React, { useState, useEffect } from "react";
import { TaskDialogChecklistImage } from "helper/constant";
import IntlMessages from "util/intlMessages";

const CheckListTitle = ({
  checklist,
  PRIMARY_COLOR,
  updateChecklistTitle,
  deleteChecklist
}) => {
  const [isEditCheckList, setIsEditCheckList] = useState(false);
  const [CheckList, setCheckList] = useState(checklist);
  useEffect(() => {
    if (checklist) {
      setCheckList(checklist);
    }
  }, [checklist]);

  return (
    <div style={{ display: "flex", marginTop: 10 }}>
      <div>
        <img
          src={TaskDialogChecklistImage}
          height="14"
          width="14"
          style={{
            marginRight: 10,
            fontSize: 13,
            position: "relative",
            left: "-4px"
          }}
        />
        {/* <i
          className="fas fa-check-double"
          style={{
            color: "grey",
            paddingRight: 10,
            fontSize: 13,
            paddingTop: 11
          }}
        ></i> */}
      </div>
      <div style={{ width: "100%", marginTop: 1 }}>
        {isEditCheckList ? (
          <>
            <div style={{ marginBottom: 5, marginRight: 3 }}>
              <input
                type="text"
                id="subtasktitle"
                defaultValue={CheckList.title}
                className="form-control react-form-input pr-2 add-task-input"
                aria-describedby="subtask"
                placeholder="Title"
                // ref={titleRef}
                autoFocus
                name="taskTitle"
                style={{
                  border: "1px solid #e2e2e2",
                  ":focus": {
                    outline: "none",
                    paddingRight: 5
                  }
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    if (CheckList.title) {
                      updateChecklistTitle(CheckList.title, CheckList.id);
                      setIsEditCheckList(false);
                    }
                  }
                }}
                onChange={e =>
                  setCheckList({
                    id: checklist.id,
                    title: e.target.value
                  })
                }
              />
            </div>
            <div style={{ marginBottom: 15 }}>
              <button
                className="c-btn mr-10"
                style={{
                  backgroundColor:
                    checklist.title.length != 0 ? PRIMARY_COLOR : "grey",
                  color: "#fff",
                  boxShadow: "none"
                }}
                disabled={checklist.title.length == 0 ? true : false}
                onClick={e => {
                  updateChecklistTitle(CheckList.title, CheckList.id);

                  setIsEditCheckList(false);
                }}
              >
                <IntlMessages id={"profile.savebutton"} />
              </button>
              <button
                className="c-btn c-default"
                style={{
                  boxShadow: "none"
                }}
                onClick={() => {
                  setIsEditCheckList(false);
                  setCheckList({
                    id: checklist.id,
                    title: checklist.title
                  });
                }}
              >
                Cancel
              </button>
            </div>
          </>
        ) : (
          <div className={"primary-label flex-x"}>
            <div
              className="flex-1"
              onClick={() => setIsEditCheckList(true)}
              style={{
                wordBreak: "break-all",
                paddingTop: "0px",
                fontSize: 15,
                fontWeight: "600"
              }}
            >
              {CheckList.title}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                className="btn btn "
                style={{
                  cursor: "pointer",
                  background: "#E7E9EC",
                  boxShadow: "none",
                  height: 25,
                  width: 60,
                  borderRadius: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px"
                }}
                onClick={() => deleteChecklist(CheckList.id, CheckList.title)}
              >
                <div
                  style={{
                    height: 25,
                    width: 60,
                    borderRadius: 4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 12,
                    fontWeight: "600",
                    color: "black"
                    // position: "relative",
                    // top: -1
                  }}
                >
                  {" "}
                  Delete
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckListTitle;
