import React from "react";
import ToastWrapper from "./toast.style";
import classNames from "classnames";

const CustomToast = props => {
  const {
    message = "s",
    redirectMessage = "s",
    redirectLink,
    heading,
    className,
    style,
    width,
    position = "",
    show,
    transition = false
  } = props;

  return (
    <ToastWrapper width={450}>
      {show && (
        <div
          className={classNames(
            className,
            "custom-toast",
            transition && position.substring(0, 3) === "top" && "animated",
            transition && position.substring(0, 3) === "top" && "fadeInDown",
            transition && position.substring(0, 6) === "bottom" && "animated",
            transition && position.substring(0, 6) === "bottom" && "fadeInUp",
            position
          )}
          style={style}
        >
          <div>
            <i
              className="far fa-times-circle close-icon"
              onClick={() => props.onCloseCLick && props.onCloseCLick()}
            />
          </div>
          <div style={{ display: "flex" }}>
            {message}{" "}
            {redirectMessage && redirectLink && (
              <div style={{ paddingLeft: "5px" }}>
                {"  "}{" "}
                <a
                  className="text-underline"
                  style={{ color: "#FFF", textDecoration: "underline" }}
                  href={redirectLink}
                >
                  {redirectMessage}
                </a>{" "}
              </div>
            )}
          </div>
        </div>
      )}
    </ToastWrapper>
  );
};

export default CustomToast;
