import React from "react";
import AppLocale from "languageProvider";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import publicRoutesLogin from "../routes/publicRoutesLogin";
import { PublicRoutes } from "./../routes/PublicRoutes";
import LoggedInRoutes from "./LoggedInRoutes";
const AuthLayout = props => {
  const { locale } = props;
  const currentAppLocale = AppLocale[locale];

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <Switch>
        {props.authData.isLogin &&
          LoggedInRoutes.map((prop, key) => {
            return (
              <Route
                exact
                path={prop.path}
                component={prop.component}
                props={props}
              />
            );
          })}
        <PublicRoutes {...props}>
          {publicRoutesLogin.map((prop, key) => {
            return (
              <Route
                exact
                path={prop.path}
                component={prop.component}
                key={key}
                props={props}
              />
            );
          })}
        </PublicRoutes>
      </Switch>

      {/* Route Layout Finish*/}
    </IntlProvider>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    LanguageSwitcher: state.LanguageSwitcher,
    locale: state.LanguageSwitcher.language.locale,
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      business: state.business.business
    }
  };
};
export default connect(mapStateToProps, {})(AuthLayout);
