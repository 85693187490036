import React, { useState, useEffect } from "react";
import CustomEditor from "components/customEditor/index";
import TaskDescriptionEditor from "./TaskDescriptionEditor";
import RoyTooltip from "components/common/RoyTooltip";
import InjectPlaceHolderMessage from "helper/methods/PlaceHolderText";
var atValues = [];
const NewTaskDescription = props => {
  const { isNewTaskModleClick, setIsNewTaskModleClick } = props;
  const DescriptionPlaceholder = `<p style="font-size:14px;color: gray;">${InjectPlaceHolderMessage(
    "today.description",
    props.language
  )}</p>`;
  const [isFocus, setIsFocus] = useState(false);
  const { inputValue, onChange } = props;
  const handleKeyDown = e => {
    if (e.shiftKey && e.keyCode == 13) {
    }
  };

  useEffect(() => {
    setIsFocus(false);
    setIsNewTaskModleClick && setIsNewTaskModleClick(false);
  }, [isNewTaskModleClick]);
  // props.isFocused for taskdetails page component
  useEffect(() => {
    if (props.isFocused) {
      setIsFocus(true);
    }
    return () => {
      setIsFocus(false);
    };
  }, [props.isFocused]);
  const checkIsEmpty = () => {
    if (inputValue === "<p><br></p>" || inputValue.match(/\s/)) {
      onChange("");
      return true;
    }
    return false;
  };
  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <>
        {!isFocus ? (
          <RoyTooltip
            id={`description`}
            title={InjectPlaceHolderMessage(
              "today.click_to_edit",
              props.language
            )}
            placement="bottom"
          >
            <div
              // title="Description"
              id={`description`}
              onFocus={() => setIsFocus(true)}
              className="react-form-search-input-dummy description-input react-form-input-new-task"
              style={{ minHeight: 70, maxHeight: 150, overflowX: "scroll" }}
              onClick={e => {
                e.stopPropagation();
                setIsFocus(true);
              }}
              dangerouslySetInnerHTML={{
                __html: inputValue || DescriptionPlaceholder
              }}
            ></div>
          </RoyTooltip>
        ) : (
          <TaskDescriptionEditor
            inputValue={inputValue}
            onChange={data => {
              onChange(data);
            }}
            setIsFocus={flag => setIsFocus(flag)}
            isPreviewEnabled={true}
            onClick={e => {
              e.stopPropagation();
              setIsFocus(true);
              setIsNewTaskModleClick && setIsNewTaskModleClick(true);
            }}
            language={props.language}
          />
        )}
      </>
    </div>
  );
};
CustomEditor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike", "blockquote", "link"],
    [{ list: "ordered" }, { list: "bullet" }][
      {
        color: ["#F00", "#0F0", "#00F", "#000" /* "#FFF", "color-picker" */]
      }
    ]
  ],
  clipboard: {
    matchVisual: false
  },
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    source: function(searchTerm, renderList, mentionChar) {
      let values;
      if (mentionChar === "@") {
        values = atValues;
      }
      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    }
  }
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
CustomEditor.formats = [
  "autofocus",
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "mention",
  "color"
];

export default NewTaskDescription;
