import React, { useEffect } from "react";
import { Popover, PopoverBody } from "reactstrap";
import {
  editList_icon,
  archiveList_icon,
  duplicateList_icon,
  deleteList_icon,
  moveList_icon
} from "helper/constant";
import IntlMessages from "util/intlMessages";
const SectionHeaderMenu = ({
  isOpen,
  toggle,
  sectionmenuID,
  SetEditSectionToggle,
  toggleMoveList,
  isMoveTaskAction,
  doArchiveSection,
  doDuplicateSection,
  sectionEdiId,
  divid,
  deleteSection,
  editSectionData
}) => {
  useEffect(() => {
    //
    return () => {};
  }, []);
  return (
    <div>
      {isOpen && sectionmenuID ? (
        <Popover
          trigger="legacy"
          className="roy-menu"
          innerClassName="roy-inner-content"
          placement="bottom-end"
          target={sectionmenuID}
          hideArrow={true}
          isOpen={isOpen}
          toggle={() => toggle()}
        >
          <PopoverBody style={{ minWidth: 200, padding: 15 }}>
            <div
              className="roy-menu-list flex-x"
              style={{
                paddingLeft: 4,
                color: "#000",
                display: "flex",
                alignItems: "center"
              }}
              onClick={() => {
                SetEditSectionToggle();
                toggle();
              }}
            >
              <div style={{ width: "20%", display: "flex" }} align="left">
                <img alt="" src={editList_icon} height="16px" width="18px" />
              </div>
              <span style={{ width: "80%" }}>
                {<IntlMessages id={"project.edit_list"} />}
              </span>
            </div>
            <hr
              style={{ margin: "10px 0px", borderTop: "1px solid #EBEBEB" }}
            />
            {isMoveTaskAction && (
              <div
                className="roy-menu-list flex-x"
                style={{
                  paddingLeft: 4,
                  color: "#000",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={() => {
                  toggleMoveList(editSectionData.id);
                  toggle();
                }}
              >
                <div style={{ width: "20%", display: "flex" }} align="left">
                  <img alt="" src={moveList_icon} height="16px" width="18px" />
                </div>
                <span style={{ width: "80%" }}>
                  {<IntlMessages id={"project.move_list"} />}
                </span>
              </div>
            )}

            <div
              className="roy-menu-list flex-x"
              style={{
                paddingLeft: 4,
                color: "#000",
                display: "flex",
                alignItems: "center"
              }}
              onClick={() => {
                doArchiveSection(editSectionData);
                toggle();
              }}
            >
              <div style={{ width: "20%", display: "flex" }} align="left">
                <img alt="" src={archiveList_icon} height="16px" width="18px" />
              </div>
              <span style={{ width: "80%" }}>
                {<IntlMessages id={"project.archive_list"} />}
              </span>
            </div>
            <div
              className="roy-menu-list flex-x"
              style={{
                paddingLeft: 4,
                color: "#000",
                display: "flex",
                alignItems: "center"
              }}
              onClick={() => {
                doDuplicateSection(editSectionData);
                toggle();
              }}
            >
              {" "}
              <div style={{ width: "20%", display: "flex" }} align="left">
                <img
                  alt=""
                  src={duplicateList_icon}
                  height="16px"
                  width="18px"
                />
              </div>
              <span style={{ width: "80%" }}>
                {" "}
                {<IntlMessages id={"project.duplicate_list"} />}
              </span>
            </div>
            <hr
              style={{ margin: "10px 0px", borderTop: "1px solid #EBEBEB" }}
            />
            <div
              className="roy-menu-list flex-x"
              style={{
                paddingLeft: 4,
                color: "#000",
                display: "flex",
                alignItems: "center"
              }}
              onClick={() => {
                deleteSection();
                toggle();
              }}
            >
              <div style={{ width: "20%", display: "flex" }} align="left">
                <img alt="" src={deleteList_icon} height="16px" width="18px" />
              </div>
              <span style={{ width: "80%" }}>
                {" "}
                {<IntlMessages id={"project.delete_list"} />}
              </span>
            </div>
          </PopoverBody>
        </Popover>
      ) : (
        ""
      )}
    </div>
  );
};

export default SectionHeaderMenu;
