import React, { useState, useEffect } from "react";
import _ from "lodash";
import AddBusiness from "components/business/CreateWorkspace&InviteTeam";
import HeaderWrapper from "./header.style";

import BusinessModal from "components/business/BusinessListModal";
import { businessSocketRoomPrefix } from "constatnt/index";
import { getProject, getLables } from "helper/services/projectServices";
import {
  checkforunique,
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import {
  profile,
  settingsHeaderPopoverImage,
  profileHeaderPopoverImage,
  logoutHeaderPopoverImage
} from "helper/constant";
import { joinRoom, leaveRoom, businessInit } from "services/socket";

import { hamburgerHeaderIcon } from "helper/constant";
import { connect } from "react-redux";
import { disconnect } from "services/socket";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import { sidebarData } from "util/data/sidebar";
import PopupNotifications from "components/notifications/PopupNotifications";
import labelActions from "redux/labels/actions";
import projectActions from "redux/projects/actions";
import { GoogleLogout } from "react-google-login";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";
import messaging, {
  onMessageListener,
  // onBackgroundMessage,
  requestFirebaseNotificationPermission,
  isSupported
} from "services/firebase.js";
import { firebasePushCert, userTopic } from "constatnt";
import {
  subscribeToTopic,
  unSubscribeToTopic
} from "helper/services/notificationService";

import CustomNotification from "util/CustomNotification";
import { profileBackground } from "helper/methods/getUserAvatarColor";
import QuickAdd from "./QuickAdd";
import NewTaskModal from "../newTask/NewTaskModal";
import InviteCollaborators from "components/collaborators/InviteCollaborators.jsx";
import AddProject from "components/projects/AddProject";
import GlobalSearch from "./GlobalSearch";
import GlobalWorkspaceSelection from "./Workspace/GlobalWorkspaceSelection";
import {
  logoutWithEmail,
  updateDeviceInformation
} from "helper/services/authServices";
import { generateLocalNotifications } from "services/localNotification";
import GlobalFavouritesSelection from "components/Favourites/GlobalFavouritesSelection";
import GlobalTaskActivitySelection from "TaskActivity/GlobalTaskActivitySelection";
import GlobalMyAssignmentsSelection from "MyAssignments/GlobalMyAssignmentsSelection";
import IntlMessages from "util/intlMessages";
import HomePage from "./Workspace/HomePage";
const { unset_labels } = labelActions;
const { unset_projects, update_Project } = projectActions;
const { set_labels, update_Label } = labelActions;
const { set_projects } = projectActions;
const { update_token, update_business, logout, update_User } = AuthActions;
const Header = props => {
  const { addToast } = useToasts();
  const {
    drawerMiniMethod,
    mini,
    unreadNotificationCount,
    setUnreadNotificationCount
  } = props;
  const [userprofile, setUserProfile] = useState(null);
  const [addBusiness, setAddBusiness] = useState(false);

  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Something went wrong");
  const [isnewTask, setIsNewTask] = useState(false);
  const [isInviteCollaborators, setInviteCollaborators] = useState(false);
  const [redirectLink, setRedirectLink] = useState(false);
  const [projectModal, setProjectModal] = useState(false);
  const [isUserSettings, setIsUserSettings] = useState(false);
  const [businessModal, setBusinessModal] = useState(false);

  const { sidebarTheme } = props;
  const [headerIconSet, setHeaderIconSet] = useState(false);

  const [redirectMessage, setRedirectMessage] = useState(false);
  useEffect(() => {
    if (props.auth?.user?.userMeta?.url) {
      setUserProfile(props.auth.user.userMeta.thumbnail);
    } else {
      setUserProfile(profile);
    }
    // retrieveToken()

    if (isSupported) {
      checkNotificationPermission();
    }
  }, [props]);

  const retrieveToken = () => {
    requestFirebaseNotificationPermission()
      .then(firebaseToken => {
        // eslint-disable-next-line no-console

        console.log(firebaseToken);
      })
      .catch(err => {
        return err;
      });
  };

  window.addEventListener("online", () => {
    props.changeInternetState({
      state: true
    });
  });
  window.addEventListener("offline", () => {
    if (props.InternetState && props.InternetState.isInternetActivated) {
      console.log("offline!");
      props.changeInternetState({
        state: false
      });
      toggleToast();
    }
  });
  const toggleToast = () => {
    if (props.InternetState && !props.InternetState.isInternetActivated) {
      addToast("Offline", {
        appearance: "error",
        autoDismiss: false,
        PlacementType: "bottom-right"
      });
    } else {
    }
  };
  const updateToken = async (token, businessName, businessMeta) => {
    props.update_business({ businessName, businessMeta });

    props.update_token(token);

    fecthProject(token);
    fetchLabels(token);
  };

  const fecthProject = async token => {
    const Response = await getProject(token);
    if (Response) {
      props.set_projects({ projects: Response.data });
      updateSidebarProjectToSidebar(Response.data);
    }
  };
  const doSignout = async deviceId => {
    try {
      const response = await logoutWithEmail(
        {
          deviceId: deviceId
        },
        null
      );
      if (response) {
        if (response.error) {
        }
        if (response) {
        }
      }
    } catch (e) {}
  };

  const userSignout = async () => {
    await doUnSubscribeToTopic(props.auth?.accessToken);
    doSignout(props.auth.accessId);
    props.unset_labels();
    props.unset_projects();
    localStorage.removeItem("fcmToken");
    localStorage.removeItem("persist:root");

    sidebarData.map((data, i) => {
      if (data.name === "sidebar.projects" || data.name === "sidebar.labels") {
        data.child = [];
      }
    });
    props.logout();
    localStorage.removeItem("fcmToken");
    localStorage.removeItem("recent_workspace");
    disconnect();
  };
  const doUpdateDeviceInformation = async (deviceId, fcmToken) => {
    try {
      const response = await updateDeviceInformation({
        deviceId: deviceId,
        fcmToken: fcmToken,
        isNotificationOn: true
      });
      if (response) {
      }
    } catch (e) {}
  };

  const checkNotificationPermission = () => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then(function(permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          doSaveToken();
        }
      });
    } else {
      doSaveToken();
    }
  };

  const fetchLabels = async token => {
    try {
      const Response = await getLables(
        token
          ? token
          : props.auth.accessToken || props.history.location.state.token
      );
      if (Response) {
        if (Response.data) {
          props.set_labels({ labels: Response.data });
          updateSidebarLabelToSidebar(Response.data);
        }
        if (Response.error) {
        }
      }
    } catch (e) {}
  };
  const fetchProject = async token => {
    const Response = await getProject(
      token
        ? token
        : props.auth.accessToken || props.history.location.state.token
    );
    if (Response) {
      props.set_projects({ projects: Response.data });

      UpdateSidebarProject(Response.data);
    }
  };
  const UpdateSidebarProject = ResponseData => {
    updateSidebarProjectToSidebar(ResponseData);
  };

  const updateBusinessCB = async data => {
    updateProjectandLabel();
  };
  const handleBusinessSocketRoom = async (
    prevBusinessId,
    newBusinessId,
    token
  ) => {
    await leaveRoom(`${businessSocketRoomPrefix}${prevBusinessId}`);
    disconnect();
    await businessInit(updateBusinessCB, token);
    await joinRoom(`${businessSocketRoomPrefix}${newBusinessId}`);
  };

  const updateProjectToRedux = data => {
    props.update_Project(data);
  };
  const updateProjectandLabel = async token => {
    fetchProject(token);
    fetchLabels(token);
  };
  const showNotifications = (message, status) => {
    addToast(ToastMessage(message), {
      appearance: status ? status : "error",
      autoDismiss: true
    });

    // setToast(true);

    // setRedirectMessage(redirectMessage);
    // setRedirectLink(redirectLink);
    // setToastMessage(message ? message : "something went wrong");
    // setTimeout(() => {
    //   setToast(false);
    //   setToastMessage("");
    //   setRedirectLink(false);
    //   setRedirectMessage(false);
    // }, 15000);
  };
  const doSaveToken = async () => {
    if (messaging() && messaging() !== false) {
      messaging()
        .getToken({ vapidKey: firebasePushCert })
        .then(async currentToken => {
          // console.log("firebase token", currentToken);
          const localToken = localStorage.getItem("fcmToken");

          if (localToken) {
            if (localToken !== currentToken) {
              await doUpdateDeviceInformation(
                props.auth.accessId,
                currentToken
              );

              localStorage.setItem("fcmToken", currentToken);
            }
          } else {
            await doUpdateDeviceInformation(props.auth.accessId, currentToken);

            localStorage.setItem("fcmToken", currentToken);
          }

          if (currentToken) {
            if (props.auth?.user?.id) {
              await subscribeToTopic(
                currentToken,
                `${userTopic}${props.auth.user.id}`
              );
            }
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch(err => {
          console.log("err", err);
        });
    }
  };
  const doUnSubscribeToTopic = async token => {
    let topic = `${userTopic}${props.auth.user.id}`;
    try {
      if (topic && token) {
        const response = await unSubscribeToTopic(token, topic);
        console.log(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const FindFirstLetter = () => {
    var str = props.auth.user.name;
    var matches = str.match(/\b(\w)/g);
    var acronym = matches.join("");
    var TwoLetter = acronym.substring(0, 2);
    return TwoLetter.toUpperCase();
  };

  if (isSupported) {
    onMessageListener()
      .then(payload => {
        if (payload?.data?.unreadNotificationCount) {
          setUnreadNotificationCount(payload.data.unreadNotificationCount);
        }

        // Local push notification based on an event
        generateLocalNotifications(
          payload.notification.title,
          payload.notification.body
        );
      })
      .catch(err => {
        console.log("payload failed: ", err);
      });
  }

  // onBackgroundMessage()
  //   .then(payload => {
  //
  //     if (payload?.data?.unreadNotificationCount) {
  //       setUnreadNotificationCount(payload.data.unreadNotificationCount);
  //     }

  //     // Local push notification based on an event
  //     generateLocalNotifications(payload.notification.title, payload.notification.body)
  //   })
  //   .catch(err => {
  //     console.log("payload failed: ", err);
  //   });

  let propsaData = {
    ...props,
    headerIconSet
  };

  return (
    <HeaderWrapper {...propsaData}>
      <div className="headerBack">
        <div className="flex-x align-center" style={{ height: "60px" }}>
          {props.history.location.pathname == "/home" ? (
            // <>
            //   <div
            //     className="drawer-handle-arrow"
            //     style={{
            //       backgroundColor: "transparent",
            //       display: "none"
            //     }}
            //   >
            //     {mini ? (
            //       <button
            //         className="top-header-icon"
            //         style={{ marginLeft: "22px" }}
            //         onClick={() => {
            //           if (props.history.location.pathname !== "/home") {
            //             drawerMiniMethod();
            //           }
            //         }}
            //       >
            //         <img
            //           src={hamburgerHeaderIcon}
            //           height="16"
            //           width="21"
            //           className=" "
            //           alt="Labels"
            //           style={{ marginRight: "0px" }}
            //         />
            //         {/* <i className="fas fa-bars"></i> */}
            //       </button>
            //     ) : (
            //       <button
            //         className="top-header-icon"
            //         style={{ marginLeft: "22px" }}
            //         onClick={() => {
            //           if (props.history.location.pathname !== "/home") {
            //             drawerMiniMethod();
            //           }
            //         }}
            //       >
            //         <img
            //           src={hamburgerHeaderIcon}
            //           height="16"
            //           width="21"
            //           className=" "
            //           alt="Labels"
            //           style={{ marginRight: "0px" }}
            //         />
            //       </button>
            //     )}
            //   </div>
            // </>

            <>
              <div
                className="drawer-handle-arrow"
                style={{
                  backgroundColor: "transparent",
                  display: "none"
                }}
              >
                <button
                  className="top-header-icon"
                  style={{ marginLeft: "22px" }}
                  onClick={() => {
                    if (props.history.location.pathname !== "/home") {
                      drawerMiniMethod();
                    }
                  }}
                >
                  <img
                    src={hamburgerHeaderIcon}
                    height="16"
                    width="21"
                    className=" "
                    alt="Labels"
                    style={{ marginRight: "0px" }}
                  />
                  {/* <i className="fas fa-bars"></i> */}
                </button>
              </div>
            </>
          ) : (
            // <>
            //   <div
            //     className="drawer-handle-arrow"
            //     style={{
            //       backgroundColor: "transparent"
            //     }}
            //   >
            //     {mini ? (
            //       <button
            //         className="top-header-icon"
            //         style={{ marginLeft: "22px" }}
            //         onClick={() => {
            //           if (props.history.location.pathname !== "/home") {
            //             drawerMiniMethod();
            //           }
            //         }}
            //       >
            //         <img
            //           src={hamburgerHeaderIcon}
            //           height="16"
            //           width="21"
            //           className=" "
            //           alt="Labels"
            //           style={{ marginRight: "0px" }}
            //         />
            //         {/* <i className="fas fa-bars"></i> */}
            //       </button>
            //     ) : (
            //       <button
            //         className="top-header-icon"
            //         style={{ marginLeft: "22px" }}
            //         onClick={() => {
            //           if (props.history.location.pathname !== "/home") {
            //             drawerMiniMethod();
            //           }
            //         }}
            //       >
            //         <img
            //           src={hamburgerHeaderIcon}
            //           height="16"
            //           width="21"
            //           className=" "
            //           alt="Labels"
            //           style={{ marginRight: "0px" }}
            //         />
            //         {/* <i className="fas fa-bars"></i> */}
            //       </button>
            //     )}
            //   </div>
            // </>

            <>
              <div
                className="drawer-handle-arrow"
                style={{
                  backgroundColor: "transparent"
                }}
              >
                <button
                  className="top-header-icon"
                  style={{ marginLeft: "22px" }}
                  onClick={() => {
                    if (props.history.location.pathname !== "/home") {
                      drawerMiniMethod();
                    }
                  }}
                >
                  <img
                    src={hamburgerHeaderIcon}
                    height="16"
                    width="21"
                    className=" "
                    alt="Labels"
                    style={{ marginRight: "0px" }}
                  />
                </button>
              </div>
            </>
          )}
          {props.history.location.pathname == "/home" ? (
            <>
              <div
                className="mini-drawer-menu-icon"
                style={{ marginLeft: "30px", display: "none" }}
                onClick={() => {
                  if (props.history.location.pathname !== "/home") {
                    drawerMiniMethod();
                  }
                }}
              >
                <img
                  src={hamburgerHeaderIcon}
                  height="16"
                  width="21"
                  className=" "
                  alt="Labels"
                  style={{ marginRight: "0px" }}
                />
                {/* <i className="fas fa-bars" />{" "} */}
                {/* <span className="app-name fs-14 bold-text">{"ToDo"}</span> */}
              </div>
            </>
          ) : (
            <>
              <div
                className="mini-drawer-menu-icon"
                style={{ marginLeft: "30px" }}
                onClick={() => {
                  if (props.history.location.pathname !== "/home") {
                    drawerMiniMethod();
                  }
                }}
              >
                <img
                  src={hamburgerHeaderIcon}
                  height="16"
                  width="21"
                  className=" "
                  alt="Labels"
                  style={{ marginRight: "0px" }}
                />
                {/* <i className="fas fa-bars" />{" "} */}
                {/* <span className="app-name fs-14 bold-text">{"ToDo"}</span> */}
              </div>
            </>
          )}

          <HomePage
            // PRIMARY_COLOR={props.PRIMARY_COLOR}
            history={props.history}
          />
          <GlobalWorkspaceSelection
            PRIMARY_COLOR={props.PRIMARY_COLOR}
            toggleNewBusiness={() => setAddBusiness(!addBusiness)}
            setBusinessModal={setBusinessModal}
            businessModal={businessModal}
            currentbusinessName={props?.auth?.user?.businessName}
            currentbusinessId={props?.auth?.user?.businessId}
            history={props.history}
          />
          <GlobalFavouritesSelection
            PRIMARY_COLOR={props.PRIMARY_COLOR}
            history={props.history}
          />
          <GlobalTaskActivitySelection
            PRIMARY_COLOR={props.PRIMARY_COLOR}
            history={props.history}
          />

          <GlobalMyAssignmentsSelection
            PRIMARY_COLOR={props.PRIMARY_COLOR}
            history={props.history}
          />

          <div className="pl-10 flex-1">
            {/* <button id="notification" className="top-header-icon">
              <i className="far fa-bell"></i>
              <div className="button-badge fs-11 demi-bold-text">3</div>
            </button>
            <UncontrolledPopover
              placement="bottom-start"
              target="notification"
              className="header-popover"
              trigger="focus"
            >
              <PopoverBody className="mail-popover-body">
                <div className="fs-13 bold-text mb-10">
                  You have 3 Notifications.
                </div>
                <PopoverBlock
                  people={people1}
                  name="Juli Hacks"
                  text="Send You a message..."
                  created="Just Now"
                />
                <PopoverBlock
                  people={people2}
                  name="Scarlet JohnSon"
                  text="Like your status..."
                  created="22nd July 2019"
                />
                <PopoverBlock
                  people={people3}
                  name="Andrew Hales"
                  text="Tagged you in his status"
                  created="20th Jun 2019"
                />
              </PopoverBody>
            </UncontrolledPopover>
          */}
          </div>

          <GlobalSearch
            PRIMARY_COLOR={props.PRIMARY_COLOR}
            projects={props.projects?.projects}
            currentWorkspace={{
              businessName: props.auth?.user?.businessName,
              businessId: props.auth?.user?.businessId
            }}
            showNotifications={showNotifications}
            history={props.history}
          />

          <QuickAdd
            toggleNewTask={() => setIsNewTask(!isnewTask)}
            toggleInvite={() => setInviteCollaborators(!isInviteCollaborators)}
            toggleNewProject={() => setProjectModal(!projectModal)}
            toggleNewBusiness={() => setAddBusiness(!addBusiness)}
          />
          <NewTaskModal
            setmodal={() => setIsNewTask(!isnewTask)}
            modal={isnewTask}
            showNotifications={showNotifications}
          />

          <AddBusiness
            modal={addBusiness}
            cancelButton={true}
            setmodal={() => setAddBusiness(!addBusiness)}
            updateRedux={data => updateProjectToRedux(data)}
            updateProjectandLabel={(token, businessName, businessMeta) => {
              updateProjectandLabel(token);
              setTimeout(() => {
                // Delay this action by one second
              }, 800);
            }}
            isExistingUser={true}
            history={(route, data) => props.history.push(route, { ...data })}
          />
          <AddProject
            modal={projectModal}
            setmodal={() => {
              setProjectModal(!projectModal);
            }}
            showNotifications={showNotifications}
            history={route => props.history.push(route)}
            updateRedux={data => {
              if (props.auth.user.businessId === data.business.id) {
                updateProjectToRedux(data);
              }
            }}
            UpdateSidebarProject={data => {
              UpdateSidebarProject(data);
            }}
            checkforunique={(datachild, toCheckValue) =>
              checkforunique(datachild, toCheckValue)
            }
            sidebarData={sidebarData}
          />
          <InviteCollaborators
            setmodal={() => setInviteCollaborators(!isInviteCollaborators)}
            showNotifications={message => showNotifications(message)}
            modal={isInviteCollaborators}
          />
          <PopupNotifications
            {...props}
            PRIMARY_COLOR={props.PRIMARY_COLOR}
            unreadNotificationCount={unreadNotificationCount}
            setUnreadNotificationCount={setUnreadNotificationCount}
          />
          {/* <div className="pl-10">
                        <button
                            className="top-header-icon"
                        >
                            <i className="fas fa-search"></i>
                        </button>
                    </div> */}
          <div className="pl-18">
            <div style={{ display: "flex", cursor: "pointer" }} id="profile">
              <div>
                <img
                  className="top-header-profile-class"
                  style={{
                    objectFit: "cover",
                    border: "2px solid white",
                    marginRight: "8px",
                    background: "#FFF"
                  }}
                  src={userprofile}
                  alt="profile"
                />
              </div>
              <span
                className="Profile-header-name"
                style={{
                  color: "white",
                  marginRight: 20,
                  display: "inline-block",
                  maxWidth: "70px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "15px",
                  position: "relative",
                  top: "2px"
                }}
              >
                {props?.auth?.user?.name}
              </span>
            </div>

            <UncontrolledPopover
              className="roy-menu"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="profile"
              hideArrow
              trigger="legacy"
              style={{
                position: "absolute",
                right: "40px",
                backgroundColor: " #FFFFFF ",
                boxShadow: "0px 0px 54px #0000001A",
                borderRadius: 10,
                minWidth: 230
              }}
              isOpen={isUserSettings}
              toggle={() => setIsUserSettings(!isUserSettings)}
            >
              <PopoverBody>
                <div
                  // onClick={() => {
                  //   setIsUserSettings(!isUserSettings);
                  //   props.history.push("/profile");
                  // }}
                  style={{ padding: 10 }}
                >
                  <div className="">
                    {props?.auth?.user && (
                      <div
                        className="flex-x"
                        style={{
                          paddingBottom: "15px",
                          borderBottom: "1px solid #EBEBEB"
                        }}
                      >
                        {props?.auth?.user &&
                        props?.auth?.user?.userMeta &&
                        props?.auth?.user?.userMeta.thumbnail ? (
                          <img
                            className="top-header-profile-class"
                            style={{
                              objectFit: "cover",
                              height: 40,
                              width: 40,
                              borderRadius: "50%"
                            }}
                            src={props.auth.user.userMeta.thumbnail}
                            alt="profile"
                          />
                        ) : (
                          <div
                            // style={profileBackground(
                            //   props?.auth?.user != null &&
                            //     props?.auth?.user?.name &&
                            //     props.auth.user.name,
                            //   40,
                            //   40
                            // )}
                            // style={{
                            //   background: "#F86B5E",
                            //   borderRadius: " 50%",
                            //   width: "36px",
                            //   height: "36px",
                            //   fontWeight: "500",
                            //   textAlign: " center",
                            //   color: "white",
                            //   fontSize: " 16px",
                            //   display: " flex",
                            //   justifyContent: "center",
                            //   alignItems: " center"
                            // }}
                            style={profileBackground(
                              props.auth.user.name,
                              36,
                              36,
                              16
                            )}
                          >
                            {FindFirstLetter()

                            //  props.auth.user.name.charAt(0).toUpperCase()
                            }
                          </div>
                        )}
                        <div className="flex-1" style={{ paddingLeft: 13 }}>
                          <div
                            className="fs-14 demi-bold-text"
                            style={{ color: "black", fontWeight: "600" }}
                          >
                            {props?.auth?.user && props?.auth?.user?.name}
                          </div>
                          <div
                            className="fs-13 medium-text"
                            style={{ display: "inline-flex", color: "#808080" }}
                          >
                            {props?.auth?.user && props?.auth?.user?.email}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="roy-menu-list  "
                  onClick={() => {
                    setIsUserSettings(!isUserSettings);
                    props.history.push("/profile");
                  }}
                >
                  {/* <i
                        className="fa fa-user text-muted fs-18"
                        style={{
                          paddingLeft: 13,
                          paddingRight: 25,
                          color: "black"
                        }}
                      /> */}
                  <img
                    src={profileHeaderPopoverImage}
                    height="17"
                    width="17"
                    style={{ marginLeft: 10, marginRight: 22 }}
                  />
                  <span style={{ color: "black" }}>
                    <IntlMessages id={"profile.profile"} />
                  </span>
                </div>
                <div
                  className="roy-menu-list  "
                  onClick={() => {
                    setIsUserSettings(!isUserSettings);
                    props.history.push("/settings");
                  }}
                >
                  {/* <i
                    className="fa fa-cog text-muted fs-18"
                    style={{
                      paddingLeft: 13,
                      paddingRight: 24,
                      color: "black"
                    }}
                  /> */}
                  <img
                    src={settingsHeaderPopoverImage}
                    height="18"
                    width="18"
                    style={{ marginLeft: 10, marginRight: 21 }}
                  />
                  <span style={{ color: "black" }}>
                    <IntlMessages id={"setting.settings"} />
                  </span>
                </div>

                <>
                  {props.auth && props.auth?.user?.google_id ? (
                    <GoogleLogout
                      render={renderProps => (
                        <div
                          className="roy-menu-list "
                          onClick={renderProps.onClick}
                        >
                          <i
                            className="fas fa-sign-out-alt text-muted  fs-18"
                            style={{
                              paddingLeft: 13,
                              paddingRight: 24,
                              color: "black"
                            }}
                          />

                          <label
                            disabled={renderProps.disabled}
                            style={{ color: "black" }}
                          >
                            <IntlMessages id={"logout.logout"} />
                          </label>
                        </div>
                      )}
                      clientId={process.env.REACT_APP_GOOGLE_ID}
                      onLogoutSuccess={() => {
                        setIsUserSettings(!isUserSettings);
                        userSignout();
                      }}
                      icon={false}
                    />
                  ) : (
                    <div
                      className="roy-menu-list "
                      onClick={() => {
                        setIsUserSettings(!isUserSettings);
                        userSignout();
                      }}
                    >
                      {" "}
                      {/* <i
                        className="fas fa-sign-out-alt text-muted  fs-18"
                        style={{ paddingLeft: 13, paddingRight: 19 }}
                      />{" "} */}
                      <img
                        src={logoutHeaderPopoverImage}
                        height="19"
                        width="18"
                        style={{ marginLeft: 11, marginRight: 20 }}
                      />
                      <label style={{ color: "black" }}>
                        <IntlMessages id={"logout.logout"} />
                      </label>
                    </div>
                  )}
                </>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </div>
        {/* <AddBusiness
          modal={addBusiness}
          setmodal={() => setAddBusiness(!addBusiness)}
          updateToken={(token, businessName, businessMeta) => {
            updateToken(token, businessName, businessMeta);
            setTimeout(() => {
              // Delay this action by one second
              setAddBusiness(!addBusiness);
              if (props.history.location.pathname !== "/dashboard") {
                props.history.push("/dashboard", {
                  token: token,
                  businessName: businessName,
                });
              }
            }, 800);
          }}
        /> */}
      </div>
      <BusinessModal
        topbarTheme={sidebarTheme}
        modal={businessModal}
        setmodal={() => setBusinessModal(!businessModal)}
        {...props}
        handleBusinessJoinRoom={(prevBusinessId, newBusinessId, token) =>
          handleBusinessSocketRoom(prevBusinessId, newBusinessId, token)
        }
      />
      <CustomNotification
        show={toast}
        message={toastMessage}
        redirectLink={redirectLink}
        redirectMessage={redirectMessage}
        closeNotification={() => setToast(false)}
      />
    </HeaderWrapper>
  );
};
const mapStateToProps = state => {
  return {
    ...state,
    PRIMARY_COLOR: state.themeChanger.topbarTheme.buttonColor
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    logout,
    unset_labels,
    unset_projects,
    set_projects,
    set_labels,
    update_token,
    update_business,
    update_Project,
    update_Label,
    update_User
  })
)(Header);
