import React, { Fragment, useState, useRef, useEffect } from "react";
import TaskAuthor from "components/scrumboard/TaskAuthor";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { updateTodo } from "helper/services/todoService";
import IconTint from "react-icon-tint";
import Shimmer from "components/shimmer/Shimmer";
import ActivityShimmer from "components/shimmer/ActivityShimmer";
import ModalWrapper from "./ModalWrapper.style";
import AttachmentShimmer from "components/shimmer/AttachmentShimmer";
import ChecklistShimmer from "components/shimmer/ChecklistShimmer";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Subtask from "components/scrumboard/Subtask";
import classNames from "classnames";
import DialogHeaderActions from "components/scrumboard/DialogHeaderActions";
import DatePicker, { CalendarContainer } from "react-datepicker";
import UploadAttachmentShimmer from "components/shimmer/UploadAttachmentShimmer";
import DatepickerWrapper from "./TaskDialogDatePicker.style";
import "react-datepicker/dist/react-datepicker.css";
import {
  PRIMARY_COLOR,
  projectSocketRoomPrefix,
  labelSocketRoomPrefix,
  todaySocketRoomPrefix,
  upcommingSocketRoomPrefix,
  prioritySocketRoomPrefix,
  getPriorityPath
} from "../../constatnt";
import moment from "moment";
import CustomNotification from "util/CustomNotification";
import TaskCommentModal from "./comment/TaskCommentModal";
import TaskAssignModal from "components/projects/collaborators/TaskAssignModal";
import AssignSubTaskModal from "components/projects/collaborators/AssignSubTaskModal";
import { getAllSubtask } from "helper/services/subtaskService";
import Radium from "radium";
import { connect } from "react-redux";
import { allPriority } from "constatnt";
import Loader from "components/loader/Loader";
import Assignee from "components/assignee";
import CustomAlert from "util/CustomAlert";
import {
  sidebarData,
  checkforunique,
  updateSidebarProjectToSidebar,
  updateSidebarLabelToSidebar
} from "util/data/sidebar";
import {
  leaveRoom,
  updateTask,
  taskInit,
  addSubTask,
  updateSubTask,
  addChecklist,
  updateComment,
  updateChecklist,
  deleteChecklist,
  subtaskAssign,
  updateTaskDateFilter,
  updateLabel as updateLabelSocket,
  updateTaskUpcomming,
  updatPriority,
  joinRoom
} from "services/socket";
import { taskSocketRoomPrefix } from "constatnt";
import TaskActivity from "components/scrumboard/TaskActivity";
import CustomEditor from "components/customEditor";
import { createNewCheckList } from "helper/services/CheckListServices";
import "./style.css";
import AttachmentUI from "components/attachment";
import AddItemInput from "./AddItemInput";
import {
  ListGridCheckImage,
  ListGridCircleImage,
  singleCheckIcon,
  NewTaskPriorityCross
} from "helper/constant";
import TaskCommentList from "./comment/TaskCommentList";
import TaskactivityListSingle from "components/scrumboard/TaskActivitySingle";
import { getTaskActivity } from "helper/services/taskServices";
import { Progress } from "react-sweet-progress";
import { FileDrop } from "react-file-drop";
import "../../assets/css/custom.css";
import CheckListTitle from "./CheckListTitle";
// import { updateChecklist } from "helper/services/CheckListServices";
import { getCollaboratorsList } from "helper/services/collboratorsServices";
import labelActions from "redux/labels/actions";
import {
  getTaskcomments,
  addcomment,
  deleteComment,
  addReactions,
  deleteReactions
} from "helper/services/taskServices";
import { getColorCode } from "components/projects/utils/color";
import AddLabel from "components/Labels/AddLabel";
//chenge
import {
  profileBackground,
  profileImage
} from "helper/methods/getUserAvatarColor";
import { useToasts } from "react-toast-notifications";
import ToastMessage from "components/notifications/ToastMessage";

import {
  dates,
  share,
  attachment,
  labelsmodal,
  prioritymodal,
  checklist,
  checklistnewmodal,
  labelsnewmodal,
  attachmentnew,
  sharenew,
  prioritynewmodal,
  datesnewmodal,
  alarmnew,
  TaskDialogDescriptionImage,
  TaskDialogAttachmentImage,
  TaskDialogActivityImage
} from "helper/constant";
import { de, nl } from "date-fns/locale";
import IntlMessages from "util/intlMessages";
const { update_Label } = labelActions;
const CardTask = props => {
  const [task, setTask] = useState(null);
  const [checked, setChecked] = useState(false);
  const [isDragEnter, setIsDragEnter] = useState(false);
  const [title, setTitle] = useState("");
  const [subtaskList, setSubtaskList] = useState([]);
  const [dueDate, setDueDate] = useState(null);
  const [remindersDate, setRemindersDate] = useState(null);
  const [remindersDateOnChangeSet, setRemindersDateOnChangeSet] = useState(
    null
  );

  const [titleFlag, setTitleFlag] = useState(false);
  const [isDueDateCalender, setIsDueDateCalender] = useState(false);
  const [isRemindersDateCalender, setIsRemindersDateCalender] = useState(false);

  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [labelArr, setLabelArr] = useState([]);
  const [isTaskCommentModal, setIsTaskCommentModal] = useState(false);
  const [isAssignTaskModal, SetisAssignTaskModal] = useState(false);
  const [isExceedAttachment, setIsExceedAttachment] = useState(false);
  const [isAssignSubTaskModal, SetisAssignSubTaskModal] = useState({
    status: false,
    data: ""
  });
  const [subTaskDetails, setSubTaskDetails] = useState(null);
  const [subTaskId, setSubTaskId] = useState(null);
  const [taskActivityModal, setTaskActivityModal] = useState(false);
  const [addChecklistFlag, setAddChecklistFlag] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionInput, setDescriptionInput] = useState(false);
  const [comment, setComment] = useState("");
  const [commentInput, setCommentInput] = useState(false);
  const [checklistId, setChecklistId] = useState(null);
  const [ChecklistInput, setChecklistInput] = useState("");
  const [attachmentComments, setAttachmentComments] = useState([]);
  const [ActivityHistory, setActivityHistory] = useState([]);
  const [isHideActivity, setIsHideActivity] = useState(false);
  const [attachmentCommentsLoad, setAttachmentCommentsLoad] = useState(true);
  const [isLoader, SetIsLoader] = useState(false);
  const [isLoaderComment, setIsLoaderComment] = useState(false);
  const [taskComments, setTaskComments] = useState([]);
  const [taskActivityData, setTaskActivityData] = useState([]);
  const [descriptionLoader, setDescriptionLoader] = useState(false);
  const [checklistLoader, setChecklistLoader] = useState(false);
  const [temlistLoader, setEditItemlistLoader] = useState(false);
  const [isLoaderChecklist, setIsLoaderChecklist] = useState(false);
  const [openPrioritySelection, setOpenPrioritySelection] = useState(false);
  const [
    openSelectedPrioritySelection,
    setOpenSelectedPrioritySelection
  ] = useState(false);
  // const [openShareSelection, setOpenShareSelection] = useState(false);
  const [openLabelSelection, setOpenLabelSelection] = useState(false);
  const [openSelectedLabelSelection, setOpenSelectedLabelSelection] = useState(
    false
  );
  const [showDeleteCommentAlert, setShowDeleteCommentAlert] = useState({
    status: false,
    action: "delete",
    messageType: "comment",
    id: "",
    title: ""
  });
  const [shareLink, setShareLink] = useState("https://todo-up.netlify.app/");
  const [isAddItemFlag, setIsAddItemFlag] = useState(false);
  const [isUploadAttachent, setIsUploadAttachent] = useState(false);
  const [addLabelModal, setAddLabelModal] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState(null);
  const [locale, setLocale] = useState();

  const textArea = useRef(null);
  const titleRef = useRef(null);

  const { addToast } = useToasts();

  const {
    taskModel,
    setTaskModel,
    taskDetail,
    addCommentHandler,
    updateActiveTaskDetail
  } = props;

  const { AllowAddPreviousDateTask } = props.auth.user?.userSetting
    ? props.auth.user.userSetting
    : "";
  const taskRef = React.useRef(subtaskList);
  const commentRef = React.useRef(ActivityHistory);
  const taskactivity = React.useRef(null);
  const labelIds = task?.labels && task?.labels?.map(a => a.id);
  React.useEffect(() => {
    if (subtaskList && ActivityHistory) {
      taskRef.current = subtaskList;
      commentRef.current = ActivityHistory;
    }

    if (taskDetail?.action == "taskactivity" && taskactivity?.current) {
      taskactivity.current.scrollIntoView();
    }
  });

  useEffect(() => {
    if (taskDetail && taskModel) {
      taskDetail?.scheduledAt && setDueDate(new Date(taskDetail.scheduledAt));
      taskDetail?.reminderAt &&
        setRemindersDate(new Date(taskDetail.reminderAt));
      setTitle(taskDetail.title);
      setChecked(taskDetail.isCompleted);

      setTask(taskDetail);

      fetchComments(true);
      setShareLink(taskDetail.shareablelink);
      retrieveSubtask(true);
      taskInit(
        addSubTaskCB,
        updateSubTaskCB,
        addChecklistCB,
        updateChecklistCB,
        deleteChecklistCB,
        updateCommentCB,
        subtaskAssignCB
      );
      // joinRoom(`${taskSocketRoomPrefix}${taskDetail.id}`);
    }
    if (props.labels && props.labels.labels && props.labels.labels.length > 0) {
      setLabelArr(props.labels.labels);
    }
    if (props.language.locale == "de") {
      setLocale(de);
    } else if (props.language.locale == "nl") {
      setLocale(nl);
    } else {
      setLocale(props.language.locale);
    }
    return () => {
      setIsExceedAttachment(false);
      setIsDueDateCalender(false);
      // leaveRoom(`${taskSocketRoomPrefix}${taskDetail.id}`);
      setIsAddItemFlag(false);
      SetIsLoader(false);
      setIsLoaderChecklist(false);
    };
  }, [taskDetail]);

  const fetchComments = async loadingStatus => {
    // const messagesEndRef = useRef(null)
    try {
      if (taskDetail && taskDetail.id) {
        setAttachmentCommentsLoad(loadingStatus);
        SetIsLoader(loadingStatus);

        const Response = await getTaskcomments(taskDetail.id);
        if (Response) {
          if (Response.error) {
            setAttachmentCommentsLoad(false);
            SetIsLoader(false);
          } else {
            let unsortedComments =
              Response.data.length > 0 && Response.data[0].taskComment;
            // removed attachments from comments
            let commentsWithAttachment =
              unsortedComments &&
              unsortedComments.filter(item => item.commentMeta);
            unsortedComments =
              unsortedComments &&
              unsortedComments.filter(item => !item.commentMeta);
            unsortedComments.sort((a, b) => a.id - b.id);
            setTaskComments(unsortedComments);
            window.scroll({
              top: document.body.offsetHeight,
              left: 0,
              behavior: "smooth"
            });
            fetchTaskActivity(unsortedComments);

            setAttachmentComments(commentsWithAttachment);
            SetIsLoader(false);
            setAttachmentCommentsLoad(false);
          }
        }
      }
    } catch (e) {}
  };
  const fetchTaskActivity = async comments => {
    if (taskDetail && taskDetail.id) {
      const Response = await getTaskActivity(taskDetail.id);
      if (Response) {
        if (Response.error) {
        }
        if (Response.data) {
          setTaskActivityData(Response.data[0]?.taskActivity);
          handleMergeActivity(comments, Response.data[0]?.taskActivity);
          SetIsLoader(false);
        }
      }
    }
  };

  const handleToggleActivity = () => {
    setIsHideActivity(!isHideActivity);
  };
  const handleMergeActivity = (taskComments, taskActivityDatas) => {
    if (taskActivityDatas && taskComments) {
      let activity = [...taskActivityDatas, ...taskComments];

      activity.sort(function compare(a, b) {
        var dateA = new Date(a.updatedAt);
        var dateB = new Date(b.updatedAt);
        return dateB - dateA;
      });
      //
      setActivityHistory(activity);
    }
  };

  const handleDeleteComment = async (commentId, taskId) => {
    if ((commentId, taskId)) {
      const Response = await deleteComment(
        {
          project_id: props.projectId.toString(),
          task_id: taskId.toString()
        },
        commentId
      );
      if (Response) {
        if (Response.error) {
        }
        if (Response.message) {
          let comments = attachmentComments;
          comments.map((data, i) => {
            if (data.id == commentId) {
              comments.splice(i, 1);
            }
          });
          let activityHistory = ActivityHistory;

          activityHistory.map((data, i) => {
            if (data.id == commentId) {
              if (
                data.hasOwnProperty("comment") ||
                hasOwnProperty("commentBy")
              ) {
                activityHistory.splice(i, 1);
              }
            }
          });
          // props.updateCommentCount(task.id, "delete_comment");
          setActivityHistory(activityHistory);
          setAttachmentComments([...comments]);
          let payload = {
            roomname: `${taskSocketRoomPrefix}${props.todoId}`
          };
          fetchComments(false);
          updateComment(payload, cb => {
            // if (cb.status == "Ok") {
            // } else {
            //   /**
            //    * @TODO
            //    * Handle error callback from socket
            //    * sample error cb: { status: "Failed", message: "error message" };
            //    */
            // }
          });
        }
      }
    }
  };

  const closeCustomAlert = () => {
    setShowDeleteCommentAlert({
      status: false,
      action: "",
      messageType: "",
      id: "",
      title: ""
    });
  };
  const openCustomAlert = (status, action, messageType, id, title) => {
    setShowDeleteCommentAlert({
      status: status,
      action: action,
      messageType: messageType,
      id: id,
      title: title
    });
  };
  const handleCustomAlertOnConfirm = () => {
    if (
      showDeleteCommentAlert.status &&
      showDeleteCommentAlert.action == "delete" &&
      showDeleteCommentAlert.messageType == "comment" &&
      showDeleteCommentAlert.id
    ) {
      handleDeleteComment(showDeleteCommentAlert.id, task.id);
      closeCustomAlert();
    }
    if (
      showDeleteCommentAlert.status &&
      showDeleteCommentAlert.action == "delete" &&
      showDeleteCommentAlert.messageType == "checklist"
    ) {
      doDeleteChecklistTitle(showDeleteCommentAlert.id);
      closeCustomAlert();
    }
  };
  const handleCustomAlertOnCancel = () => {
    if (
      showDeleteCommentAlert.status &&
      showDeleteCommentAlert.action == "delete"
    ) {
      setDeleteCommentId(null);
      closeCustomAlert();
    }
  };
  //socket cb function start
  const addSubTaskCB = async data => {
    let subtasks = taskRef.current;

    await Promise.all(
      subtasks.map((s, i) => {
        if (s.id == data.checklist.id) {
          if (s.subTask) {
            isSubtaskExist(s.subTask, data) && s.subTask.push(data);
          } else {
            s.subTask = [data];
          }
        }
      })
    );
    setSubtaskList([...subtasks]);
  };
  const isSubtaskExist = (oldArray, newData) => {
    var isUnique = true;
    if (oldArray) {
      oldArray.map((subtask, i) => {
        if (subtask.id == newData.id) {
          isUnique = false;
        }
      });

      return isUnique;
    }
  };
  const updateCommentCB = async data => {
    if (props.auth.user.id !== data.user.id) {
      fetchComments(false);
    }
  };
  const updateSubTaskCB = async data => {
    try {
      let subTaskData = taskRef.current;
      if (subTaskData) {
        if (data.isDeleted) {
          await Promise.all(
            subTaskData.map((c, i) => {
              return c.subTask.map((s, j) => {
                if (s.id == data.id) {
                  subTaskData[i].subTask.splice(j, 1);
                }
              });
            })
          );
        } else {
          await Promise.all(
            subTaskData &&
              subTaskData.map((c, i) => {
                return (
                  c?.subTask &&
                  c?.subTask?.length > 0 &&
                  c?.subTask.map((s, j) => {
                    if (s.id == data.id) {
                      subTaskData[i].subTask[j] = {
                        ...subTaskData[i].subTask[j],
                        ...data
                      };
                    }
                  })
                );
              })
          );
        }
        setSubtaskList([...subTaskData]);
      }
    } catch (e) {}
  };
  const subtaskAssignCB = async data => {
    retrieveSubtask(false);
  };
  const addChecklistCB = async data => {
    let subTaskData = taskRef.current;

    if (subTaskData.length > 0) {
      isChecklistExist(subTaskData, data) &&
        setSubtaskList([...subTaskData, ...[data]]);
    } else {
      setSubtaskList([...[data]]);
    }
  };

  const updateChecklistCB = async data => {
    let subTaskData = taskRef.current;
    if (subTaskData) {
      await Promise.all(
        subTaskData.map((c, i) => {
          if (c.id == data.id) {
            subTaskData[i] = {
              ...subTaskData[i],
              ...data
            };
          }
        })
      );
      setSubtaskList([...subTaskData]);
    }
  };

  const deleteChecklistCB = async data => {
    let subTaskData = taskRef.current;
    if (subTaskData) {
      await Promise.all(
        subTaskData.map((c, i) => {
          if (c.id == data.checklist_id) {
            subTaskData.splice(i, 1);
          }
        })
      );
      setSubtaskList([...subTaskData]);
    }
  };
  //socket cb function end
  const isChecklistExist = (oldArray, newData) => {
    var isUnique = true;
    if (oldArray) {
      oldArray.map((checklist, i) => {
        if (checklist.id == newData.id) {
          isUnique = false;
        }
      });

      return isUnique;
    }
  };
  // const showNotifications = (message, status) => {
  //   addToast(ToastMessage(message), {
  //     appearance: status ? status : "error",
  //     autoDismiss: false
  //   });
  // };

  const doUpdateTask = requestedPayload => {
    let payload = requestedPayload;
    if (!requestedPayload?.hasOwnProperty("scheduledAt")) {
      payload = { ...payload, scheduledAt: task.scheduledAt };
    }
    if (!requestedPayload?.hasOwnProperty("reminderAt")) {
      payload = { ...payload, reminderAt: task.reminderAt };
    }

    updateTask(payload, cb => {
      if (cb.status == "Ok") {
        setChecked(false);
        fetchComments(false);
        setToast(true);
        setToastMessage("Task Updated.");
        setTimeout(() => {
          setToast(false);
        }, 2500);
      } else {
        /**
         * @TODO
         * Handle error callback from socket
         * sample error cb: { status: "Failed", message: "error message" };
         */
        props.showNotifications();
      }
    });
    //     if (props.taskType == "label") {
    //   updateLabelSocket(payload, cb => {
    //     if (cb.status == "Ok") {
    //       // setToast(true);
    //       // setToastMessage("Task Updated.");
    //       // setTimeout(() => {
    //       //   setToast(false);
    //       // }, 2500);
    //       fetchComments(false);
    //     } else {
    //       /**
    //        * @TODO
    //        * Handle error callback from socket
    //        * sample error cb: { status: "Failed", message: "error message" };
    //        */
    //       props.showNotifications();
    //     }
    //   });
    // }
    // if (props.taskType == "priority") {
    //   updatPriority(payload, cb => {
    //     if (cb.status == "Ok") {
    //       setChecked(checked);
    //       setToast(true);
    //       setToastMessage("Task Updated.");
    //       setTimeout(() => {
    //         setToast(false);
    //       }, 2500);
    //       fetchComments(false);
    //     } else {
    //       /**
    //        * @TODO
    //        * Handle error callback from socket
    //        * sample error cb: { status: "Failed", message: "error message" };
    //        */
    //       props.showNotifications();
    //     }
    //   });
    // }

    // if (props.taskType == "date_filter") {
    //   // updateTaskDateFilter
    //   updateTaskDateFilter(payload, cb => {
    //     if (cb.status == "Ok") {
    //       setChecked(checked);
    //       fetchComments(false);
    //       setToast(true);
    //       setToastMessage("Task Updated.");
    //       setTimeout(() => {
    //         setToast(false);
    //       }, 2500);
    //     } else {
    //       /**
    //        * @TODO
    //        * Handle error callback from socket
    //        * sample error cb: { status: "Failed", message: "error message" };
    //        */
    //       props.showNotifications();
    //     }
    //   });
    // }
    // if (props.taskType == "date_filter_upcomming") {
    //   // updateTaskDateFilter
    //   updateTaskUpcomming(payload, cb => {
    //     if (cb.status == "Ok") {
    //       setChecked(checked);
    //       fetchComments(false);
    //       setToast(true);

    //       setToastMessage("Task Updated.");
    //       setTimeout(() => {
    //         setToast(false);
    //       }, 2500);
    //     } else {
    //       /**
    //        * @TODO
    //        * Handle error callback from socket
    //        * sample error cb: { status: "Failed", message: "error message" };
    //        */
    //       props.showNotifications();
    //     }
    //   });
    // } else {
    //   updateTask(payload, cb => {
    //     if (cb.status == "Ok") {
    //       setChecked(checked);
    //       fetchComments(false);
    //       setToast(true);
    //       setToastMessage("Task Updated.");
    //       setTimeout(() => {
    //         setToast(false);
    //       }, 2500);
    //     } else {
    //       /**
    //        * @TODO
    //        * Handle error callback from socket
    //        * sample error cb: { status: "Failed", message: "error message" };
    //        */
    //       props.showNotifications();
    //     }
    //   });
    // }
  };

  const AddAttachment = async file => {
    if (file !== null) {
      const MaxFileSize = 10 * 1024 * 1024; /* 10 mb limit */
      if (file.size <= MaxFileSize) {
        setIsUploadAttachent(true);
        const Response = await addcomment({
          attachment: file,
          comment: null,
          taskId: task.id,
          projectId: props.projectId.toString()
        });
        if (Response) {
          setIsUploadAttachent(false);
          if (Response.error) {
            props.showNotifications();
          } else {
            document.getElementById("commentsAttachment").value = "";
            setAttachmentComments([...attachmentComments, ...[Response.data]]);

            const commentData = {
              ...Response.data,
              commentBy: { id: props.auth.user.id, name: props.auth.user.name }
            };
            fetchComments(false);
            // adding to comments
            // var acitivityArr = [...[commentData], ...ActivityHistory];

            // setActivityHistory(acitivityArr);
            let payload = {
              roomname: `${taskSocketRoomPrefix}${props.todoId}`
            };

            updateComment(payload, cb => {
              if (cb.status == "Ok") {
                setComment("");
                // setChecklistInput("");
                // setChecklistLoader(false);
                // setAddChecklistFlag(false);
                // setIsAddItemFlag(true);
              } else {
                /**
                 * @TODO
                 * Handle error callback from socket
                 * sample error cb: { status: "Failed", message: "error message" };
                 */
              }
            });
          }
        }
      } else {
        toggleAttachmentLimitExceedError();
      }
    }
  };
  const toggleAttachmentLimitExceedError = () => {
    document.getElementById("commentsAttachment").value = "";
    setIsExceedAttachment(true);
    setTimeout(() => {
      setIsExceedAttachment(false);
    }, 3000);
    props.showNotifications("File size exceeds 10MB limit", "error");
  };
  const handleSubtaskAssignee = (dataA, task_id) => {
    let checklist = subtaskList;

    checklist.map((data, i) => {
      data.subTask.map((sub, j) => {
        if (sub.id == task_id) {
          if (!dataA) {
            checklist[i].subTask[j].taskAssign = null;
          } else {
            checklist[i].subTask[j].taskAssign = {
              id: dataA.id,
              name: dataA.name,
              thumbnail: dataA.userMeta?.thumbnail
            };
          }
        }
      });
    });

    setSubtaskList(checklist);
    SetisAssignSubTaskModal({ status: !isAssignSubTaskModal.status, data: "" });
  };
  const getRoomName = async => {
    if (props.taskType == "label") {
      return `${labelSocketRoomPrefix}${props.labelId}`;
    }
    if (props.taskType == "date_filter") {
      return `${todaySocketRoomPrefix}${props.auth.user.businessId}`;
    }
    if (props.taskType == "date_filter_upcomming") {
      return `${upcommingSocketRoomPrefix}${props.auth.user.businessId}`;
    }
    if (props.taskType == "priority") {
      return `${prioritySocketRoomPrefix}${props.priorityId}_${props.auth.user.businessId}`;
    }

    return `${projectSocketRoomPrefix}${props.projectId}`;
  };
  const updateName = async () => {
    let payload = {
      title: title,
      project_id: props.projectId.toString(),
      section_id: props.sectionId ? props.sectionId.toString() : "",
      task_id: props.todoId
        ? props.todoId.toString()
        : taskDetail.id.toString(),
      roomname: getRoomName()
    };
    doUpdateTask(payload);
  };

  const onChangeDueDateSubtask = async (subtaskId, date, checklistId) => {
    // setChecked(checked);
    let payload = {
      scheduledAt: date
    };

    await doUpdateSubtask(payload, subtaskId, checklistId);
    setToast(true);
    setToastMessage("Due Date updated successfully");
    setTimeout(() => {
      setToast(false);
    }, 2500);
  };
  const updateIsCompleted = async checked => {
    let payload = {
      isCompleted: checked,
      project_id: props.projectId.toString(),
      section_id: props.sectionId ? props.sectionId.toString() : "",
      task_id: props.todoId.toString(),
      roomname: getRoomName()
    };
    if (task.scheduledAt) {
      payload = { ...payload, scheduledAt: task.scheduledAt };
    }
    if (task.reminderAt) {
      payload = { ...payload, reminderAt: task.reminderAt };
    }
    doUpdateTask(payload);
  };
  const updateTaskAssign = async taskAssignedUser => {
    let payload = {
      project_id: props.projectId.toString(),
      user_id: taskAssignedUser ? taskAssignedUser.id.toString() : "",
      task_id: props.todoId.toString(),
      roomname: getRoomName()
    };

    doUpdateTask(payload);
  };
  const onChangeDueDate = async date => {
    setDueDate(date);
  };
  const updateDueDate = async date => {
    setDueDate(date);
    let payload = {
      scheduledAt: date ? moment(new Date(date)).utc() : "",
      project_id: props.projectId.toString(),
      section_id: props.sectionId ? props.sectionId.toString() : "",
      task_id: props.todoId.toString(),
      roomname: getRoomName()
    };
    if (!date) {
      payload = { ...payload, reminderAt: "" };
    }
    if (date && remindersDate) {
      payload = {
        ...payload,
        reminderAt: remindersDate ? moment(new Date(remindersDate)).utc() : ""
      };
    }
    setIsDueDateCalender(!isDueDateCalender);
    doUpdateTask(payload);
  };

  const onChangeReminderDate = async date => {
    // setRemindersDate(date);
    setRemindersDateOnChangeSet(date);
  };
  const updateRemindersDate = async date => {
    // setRemindersDate(date);
    setRemindersDateOnChangeSet(date);
    let payload = {
      scheduledAt: dueDate ? moment(new Date(dueDate)).utc() : "",
      reminderAt: date ? moment(new Date(date)).utc() : "",
      project_id: props.projectId.toString(),
      section_id: props.sectionId ? props.sectionId.toString() : "",
      task_id: props.todoId.toString(),
      roomname: getRoomName()
    };
    setIsRemindersDateCalender(!isRemindersDateCalender);

    doUpdateTask(payload);
  };
  const updateLabel = async label => {
    if (task.labels) {
      let taskLabelArr = task.labels;
      let index;
      let ids = taskLabelArr.map((a, i) => {
        if (a.id == label.id) {
          index = i;
        }
        return a.id;
      });
      if (ids.includes(label.id)) {
        ids.splice(index, 1);
      } else {
        ids.push(label.id);
      }
      let payload = {
        label_ids: ids.join(),
        project_id: props.projectId.toString(),
        section_id: props.sectionId ? props.sectionId.toString() : "",
        task_id: props.todoId.toString(),
        roomname: getRoomName()
      };

      doUpdateTask(payload);
    }
  };

  const updatePriority = async priority => {
    if (priority != task.priority) {
      let payload = {
        priority,
        project_id: props.projectId.toString(),
        section_id: props.sectionId ? props.sectionId.toString() : "",
        task_id: props.todoId.toString(),
        roomname: getRoomName()
      };
      doUpdateTask(payload);
    }
  };

  const DueDateCalendar = ({ className, children }) => {
    return (
      <div>
        {/* <CalendarContainer
        // className={className + " custom-calender-class-"}
        // style={{ display: "grid !important" }}
        > */}
        <div></div>
        <div style={{ position: "relative" }} className={className}>
          {children}
        </div>
        <div
          style={{
            display: "flex",
            // borderBottom: "1px solid #aeaeae",
            // borderRight: "1px solid #aeaeae",
            // borderLeft: "1px solid #aeaeae",
            borderBottomLeftRadius: "0.3rem",
            borderBottomRightRadius: "0.3rem",
            backgroundColor: "white",
            cursor: "pointer",
            padding: "10px",
            background: "white",
            border: "0px solid",
            fontSize: 14
            // boxShadow: "0px 0px 15px 1px #0000001a !important"
          }}
        >
          <div></div>
          <div style={{ position: "relative" }} className={className}>
            {children}
          </div>
          <div
            style={{
              display: "flex",
              // borderBottom: "1px solid #aeaeae",
              // borderRight: "1px solid #aeaeae",
              // borderLeft: "1px solid #aeaeae",
              borderBottomLeftRadius: "0.3rem",
              borderBottomRightRadius: "0.3rem",
              backgroundColor: "white",
              cursor: "pointer",
              padding: "10px",
              background: "white",
              border: "0px solid",
              fontSize: 14
              // boxShadow: "0px 0px 15px 1px #0000001a !important"
            }}
          >
            <div style={{ paddingRight: 6 }}>
              <div
                onClick={() => {
                  onChangeDueDate("");
                  updateDueDate("");
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  fontSize: 14,
                  padding: 6,
                  borderRadius: 4,
                  border: "1px solid #E6E6E6",
                  color: "black"
                }}
              >
                Remove Date
              </div>
            </div>
            <div
              onClick={() => {
                onChangeDueDate("");
                updateDueDate("");
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                fontSize: 14,
                padding: 6,
                borderRadius: 4,
                border: "1px solid #E6E6E6",
                color: "black"
              }}
            >
              Remove Date
            </div>
          </div>
          <div
            onClick={() => {
              updateDueDate(dueDate ? dueDate : new Date());
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",

              fontSize: 14,
              background: props.topbarTheme.buttonColor,
              padding: 6,
              borderRadius: 4,
              color: "#FFF"
            }}
          >
            Apply
          </div>
        </div>
        {/* </CalendarContainer> */}
      </div>
    );
  };
  const ReminderDateCalendar = ({ className, children }) => {
    return (
      <div>
        <CalendarContainer
        // className={className + " custom-calender-class-"}
        // style={{ display: "grid !important" }}
        >
          <div></div>
          <div style={{ position: "relative" }} className={className}>
            {children}
          </div>
          <div
            // onClick={() => updateRemindersDate("")}
            style={{
              display: "flex",
              // display: "grid",
              alignContent: "center",
              backgroundColor: "white",
              // textDecoration: "underline",
              cursor: "pointer",
              borderBottom: "1px solid #aeaeae",
              borderRight: "1px solid #aeaeae",
              borderLeft: "1px solid #aeaeae",
              borderTop: "none !important",
              border: "0px solid",
              borderBottomLeftRadius: "0.3rem",
              borderBottomRightRadius: "0.3rem",
              fontSize: 12,
              padding: "10px"
            }}
          >
            <div style={{ paddingRight: 6 }}>
              <div
                onClick={() => {
                  updateRemindersDate("");
                  onChangeReminderDate("");
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  fontSize: 14,
                  padding: 6,
                  borderRadius: 4,
                  border: "1px solid #E6E6E6"
                }}
              >
                Remove Date
              </div>
            </div>
            <div
              onClick={() =>
                updateRemindersDate(
                  remindersDateOnChangeSet
                    ? remindersDateOnChangeSet
                    : new Date()
                )
              }
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",

                fontSize: 14,
                background: props.topbarTheme.buttonColor,
                padding: 6,
                borderRadius: 4,
                color: "#FFF"
              }}
            >
              Apply
            </div>
          </div>
        </CalendarContainer>
      </div>
    );
  };
  const DueDateInput = React.forwardRef(
    ({ value, onClick }, ref) => (
      //  { dueDate ?
      <div
        onClick={onClick}
        ref={ref}
        className={"member-block"}
        style={{
          padding: 8,
          backgroundColor: "white",
          border: "1px solid #EBEBEB"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            cursor: "pointer"
          }}
        >
          <span onClick={() => setIsDueDateCalender(!isDueDateCalender)}>
            {/* <i
          id="calender"
          className="far fa-calendar-alt"
          style={{ fontSize: 14 }}
        ></i> */}
            <img
              src={datesnewmodal}
              height="15"
              width="15"
              style={{
                marginRight: "14px",
                marginLeft: "3px",
                marginTop: "-5px"
              }}
              className=" "
              alt="Labels"
            />
            <span
              style={{
                fontSize: 15,
                // marginLeft: 3,
                color: "172b4d"
              }}
            >
              Schedule
            </span>
          </span>
        </div>
      </div>
    )
    // :""}
  );

  const RemindersInput = React.forwardRef(({ value, onClick }, ref) => (
    <div
      onClick={onClick}
      ref={ref}
      className={"member-block"}
      style={{
        padding: 8,
        backgroundColor: "white",
        border: "1px solid #EBEBEB"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          cursor: "pointer"
        }}
      >
        <span
          onClick={() => setIsRemindersDateCalender(!isRemindersDateCalender)}
        >
          {/* <i
            id="calender"
            className="far fa-calendar-alt"
            style={{ fontSize: 14 }}
          ></i> */}
          <img
            src={alarmnew}
            height="15"
            width="15"
            style={{
              marginRight: "14px",
              marginLeft: "3px",
              marginTop: "-4px"
            }}
            className=" "
            alt="Labels"
          />
          <span
            style={{
              fontSize: 15,
              // marginLeft: 3,
              color: "172b4d"
            }}
          >
            Reminders
          </span>
        </span>
      </div>
    </div>
  ));
  const retrieveSubtask = async isLoader => {
    try {
      setIsLoaderChecklist(isLoader);
      let payload = {
        project_id: props.projectId.toString(),
        task_id: props.todoId ? props.todoId.toString() : taskDetail.id
      };
      const Response = await getAllSubtask(payload);
      if (Response) {
        if (Response.error) {
          setIsLoaderChecklist(false);
        }
        if (Response.data) {
          setSubtaskList(Response.data);

          const ResponseData = isUniqueSubtask(Response.data, "id");

          setIsLoaderChecklist(false);
        }
      }
    } catch (e) {}
  };

  const isUniqueSubtask = (subtaskArr, key) => {
    const arrayUniqueByKey = [
      ...new Map(
        subtaskArr && subtaskArr.map(item => [item[key], item])
      ).values()
    ];

    if (arrayUniqueByKey) {
      return arrayUniqueByKey;
    }
    return false;
  };
  const addNewSubtask = async (checklistId, subtaskTitle) => {
    if (subtaskTitle != "") {
      let payload = {
        project_id: props.projectId.toString(),
        task_id: props.todoId.toString(),
        checklist_id: checklistId.toString(),
        title: subtaskTitle,
        orderIndex: 1,
        roomname: `${taskSocketRoomPrefix}${props.todoId}`
      };

      addSubTask(payload, cb => {
        if (cb.status == "Ok") {
          fetchComments(false);
        } else {
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
        }
      });
      updateComment(
        {
          task_id: props.todoId.toString(),
          roomname: `${taskSocketRoomPrefix}${props.todoId}`
        },
        cb => {
          if (cb.status == "Ok") {
            setComment("");
          } else {
          }
        }
      );
    }
  };
  const doUpdateChecklistTitle = async (title, id) => {
    if (id && title) {
      try {
        let payload = {
          title,
          checklist_id: id.toString(),
          roomname: `${taskSocketRoomPrefix}${props.todoId}`
        };
        updateChecklist(payload, cb => {
          if (cb.status == "Ok") {
            fetchComments(false);
          } else {
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
          }
        });
      } catch (e) {}
    }
  };
  const doDeleteChecklistTitle = async id => {
    if (id) {
      try {
        let payload = {
          checklist_id: id.toString(),
          roomname: `${taskSocketRoomPrefix}${props.todoId}`
        };
        deleteChecklist(payload, cb => {
          if (cb.status == "Ok") {
            fetchComments(false);
          } else {
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
          }
        });
      } catch (e) {}
    }
  };
  const doUpdateSubtask = async (reqObj, subtaskId, checklistId) => {
    try {
      let data = {
        project_id: props.projectId.toString(),
        task_id: subtaskId.toString(),
        checklist_id: checklistId.toString(),
        roomname: `${taskSocketRoomPrefix}${props.todoId}`
      };
      let payload = { ...data, ...reqObj };

      updateSubTask(payload, cb => {
        if (cb.status == "Ok") {
          fetchComments(false);
        } else {
          /**
           * @TODO
           * Handle error callback from socket
           * sample error cb: { status: "Failed", message: "error message" };
           */
        }
      });
    } catch (e) {}
  };

  const doDeleteSubtask = async (subtaskId, checklistId) => {
    let payload = {
      isDeleted: true
    };
    doUpdateSubtask(payload, subtaskId, checklistId);
  };

  const updateIsCompletedSubTask = async (subtaskId, checked, checklistId) => {
    let payload = {
      isCompleted: checked
    };
    doUpdateSubtask(payload, subtaskId, checklistId);
  };
  const handleRemoveCommentReactions = async (id, taskCommentId) => {
    if (id) {
      try {
        const Response = await deleteReactions(id);
        if (Response) {
          if (Response.error) {
          }
          if (Response.message) {
            let activityHistory = ActivityHistory;

            activityHistory.map((data, i) => {
              if (data.id == taskCommentId) {
                if (
                  data.hasOwnProperty("comment") ||
                  hasOwnProperty("commentBy")
                ) {
                  var emojiArr = [...activityHistory[i].commentEmoji];
                  // var result = emojiArr.filter(data => data.id !== id);
                  emojiArr &&
                    emojiArr.map((comment, i) => {
                      if (comment.id == id) {
                        emojiArr.splice(i, 1);
                      }
                    });
                  activityHistory[i].commentEmoji = emojiArr;
                }
              }
            });
            let payload = {
              roomname: `${taskSocketRoomPrefix}${props.todoId}`
            };

            updateComment(payload, cb => {
              if (cb.status == "Ok") {
                setComment("");

                // setChecklistInput("");
                // setChecklistLoader(false);
                // setAddChecklistFlag(false);
                // setIsAddItemFlag(true);
              } else {
                /**
                 * @TODO
                 * Handle error callback from socket
                 * sample error cb: { status: "Failed", message: "error message" };
                 */
              }
            });
            setActivityHistory([...activityHistory]);
          }
        }
      } catch (e) {}
    }
  };
  const addCommentReactions = async (unicode, taskCommentId) => {
    try {
      if (unicode && taskCommentId) {
        const Response = await addReactions({
          comment: unicode,
          task_comment_id: taskCommentId ? taskCommentId : ""
        });
        if (Response) {
          if (Response.error) {
          }
          if (Response.data) {
            let activityHistory = ActivityHistory;

            activityHistory.map((data, i) => {
              if (data.id == taskCommentId) {
                if (
                  data.hasOwnProperty("comment") ||
                  hasOwnProperty("commentBy")
                ) {
                  var emojiArr = activityHistory[i].commentEmoji
                    ? [...activityHistory[i].commentEmoji]
                    : [];
                  emojiArr.push(Response.data);
                  activityHistory[i].commentEmoji = emojiArr;
                }
              }
            });
            setActivityHistory([...activityHistory]);
            let payload = {
              roomname: `${taskSocketRoomPrefix}${props.todoId}`
            };

            updateComment(payload, cb => {
              if (cb.status == "Ok") {
                setComment("");
                // setChecklistInput("");
                // setChecklistLoader(false);
                // setAddChecklistFlag(false);
                // setIsAddItemFlag(true);
              } else {
                /**
                 * @TODO
                 * Handle error callback from socket
                 * sample error cb: { status: "Failed", message: "error message" };
                 */
              }
            });
          }
        }
      }
    } catch (e) {}
  };

  const addnewCheckList = async () => {
    if (ChecklistInput !== "") {
      try {
        setChecklistLoader(true);
        /**
         * Add new checklist through socket
         */

        let payload = {
          title: ChecklistInput,
          project_id: props.projectId.toString(),
          task_id: props.todoId.toString(),
          roomname: `${taskSocketRoomPrefix}${props.todoId}`
        };
        addChecklist(payload, cb => {
          if (cb.status == "Ok") {
            setChecklistInput("");
            setChecklistLoader(false);
            setAddChecklistFlag(false);
            setIsAddItemFlag(true);
            fetchComments(false);
          } else {
            /**
             * @TODO
             * Handle error callback from socket
             * sample error cb: { status: "Failed", message: "error message" };
             */
          }
        });
      } catch (e) {}
      setChecklistInput("");
    }
  };

  const doUpdateTodo = async reqObj => {
    try {
      let data = {
        project_id: props.projectId.toString(),
        section_id: props.sectionId ? props.sectionId.toString() : ""
      };
      let payload = { ...data, ...reqObj };
      const Response = await updateTodo(payload, props.todoId);
      if (Response) {
        if (Response.error) {
        }
        if (Response.data) {
          setTask(Response.data);
          props.updateTodoList(Response.data);
        }
      }
    } catch (e) {}
  };
  const handleAddDescription = async (
    id,
    project_id = props.projectId.toString(),
    section_id = props.sectionId ? props.sectionId.toString() : ""
  ) => {
    try {
      setDescriptionLoader(true);
      let payload = {
        description: description,
        project_id: props.projectId.toString(),
        section_id: props.sectionId ? props.sectionId.toString() : "",
        task_id: props.todoId.toString(),
        roomname: getRoomName()
      };

      await doUpdateTask(payload);
      setDescriptionLoader(false);
      setToast(true);
      setToastMessage("Description added successfully.");
      setTimeout(() => {
        setToast(false);
      }, 2500);
    } catch (e) {
      setDescriptionLoader(false);
    }
    setDescriptionInput(!descriptionInput);
  };

  const handleAddComment = async () => {
    if (comment) {
      let payload = {
        comment: comment,
        task_id: props.todoId.toString(),
        projectId: props.projectId.toString(),
        roomname: `${taskSocketRoomPrefix}${props.todoId}`
      };
      setIsLoaderComment(true);
      const Response = await addcomment({
        // attachment: fileToUpload,
        comment: comment,
        taskId: task.id,
        projectId: props.projectId.toString()
      });
      if (Response) {
        if (Response.error) {
        } else {
          // rmeoved from comments btu
          if (Response.data?.commentMeta) {
            setAttachmentComments([Response.data, ...attachmentComments]);
          } else {
            setActivityHistory([Response.data, ...ActivityHistory]);
          }
          fetchComments(false);
          // props.updateCommentCount(task.id, "new_comment");
          setComment("");

          updateComment(payload, cb => {
            // if (cb.status == "Ok") {
            // } else {
            //   /**
            //    * @TODO
            //    * Handle error callback from socket
            //    * sample error cb: { status: "Failed", message: "error message" };
            //    */
            // }
          });
        }

        setIsLoaderComment(false);
      }
    }
    setCommentInput(!commentInput);
  };
  const checkEmojiExist = (unicode, taskCommentId) => {
    let activityHistory = ActivityHistory;
    var status = { status: false, id: 0 };
    activityHistory.map((comment, i) => {
      if (comment.id == taskCommentId && comment && "comment" in comment) {
        if (
          (comment.hasOwnProperty("comment") || hasOwnProperty("commentBy")) &&
          comment.hasOwnProperty("commentEmoji")
        ) {
          var emojiArr = [...activityHistory[i].commentEmoji];

          emojiArr.map(data => {
            if (
              data.emojiname == unicode &&
              data.user.id == props.auth.user.id
            ) {
              status = { status: true, id: data.id };
            }
          });
          if (!status.status) {
            status = { status: false };
          }
        } else {
          status = { status: false };
        }
      }
    });
    if (!status.status) {
      status = { status: false };
    }
    return status;
  };
  const doUpdateAssignSubtask = async reqObj => {
    let payload = {
      roomname: `${taskSocketRoomPrefix}${props.todoId}`,
      ...reqObj
    };
    subtaskAssign(payload, cb => {
      if (cb.status == "Ok") {
        fetchComments(false);
      } else {
      }
    });
  };
  const getDescriptionStyle = () => {
    if (!task.description) {
      return {
        width: "100%",
        position: "relative",
        top: 1
      };
    }
    return {
      width: "100%"
    };
  };
  const handleCloseModal = () => {
    if (props.history && props.history?.location?.search) {
      const reqTaskIdparams = new URLSearchParams(
        props.history.location?.search
      );
      const reqtaskIds = reqTaskIdparams.get("tId");
      if (reqtaskIds && taskModel) {
        props.history.replace(`/project/${props.projectId}`);
      }
    }
    setTaskModel();
  };
  return (
    <Fragment>
      {task && (
        <>
          <ModalWrapper>
            <Modal
              isOpen={taskModel}
              toggle={() => {
                leaveRoom(`${taskSocketRoomPrefix}${task.id}`);
                handleCloseModal();
              }}
              size="lg"
              centered
              style={{ borderRadius: "30px" }}
              className="task-dialog"
              contentClassName="task-dialog-content"
            >
              <ModalHeader
                toggle={() => {
                  leaveRoom(`${taskSocketRoomPrefix}${task.id}`);
                  handleCloseModal();
                }}
                style={{
                  marginBottom: 0,
                  paddingBottom: 0,
                  backgroundColor: "#F4F5F7",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10
                }}
                className="border-bottom-none"
              >
                {task && task?.isArchived && (
                  <div className="task-archive">This Task is Archived</div>
                )}
                {attachmentComments.length <= 0 && isExceedAttachment && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    File size exceeds 10MB limit
                  </div>
                )}
                <div
                  className="task-dialog-header"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "none !important",
                    marginBottom: "10px"
                  }}
                >
                  {/* Task title */}
                  <div style={{ width: "60%" }}>
                    {titleFlag ? (
                      <>
                        <div style={{ marginBottom: 0, paddingLeft: 5 }}>
                          <input
                            type="text"
                            value={title}
                            aria-describedby="emailHelp"
                            placeholder="Title"
                            ref={titleRef}
                            autoFocus
                            className="add-task-input"
                            name="taskTitle"
                            style={{
                              border: "1px solid #EBEBEB",
                              ":focus": {
                                outline: "none"
                              },
                              height: "50px",
                              width: "400px",
                              borderRadius: 5,
                              padding: "0px 15px",
                              fontsize: 20,
                              marginBottom: 15,
                              fontWeight: 600
                            }}
                            onKeyDown={e => {
                              if (e.keyCode === 13) {
                                updateName();
                                setTitleFlag(false);
                              }
                            }}
                            onChange={e => setTitle(e.target.value)}
                          />
                        </div>
                        <div style={{ marginBottom: 15, paddingLeft: 5 }}>
                          <button
                            className="c-btn mr-10"
                            style={{
                              backgroundColor:
                                title.length != 0
                                  ? props.topbarTheme.buttonColor
                                  : "grey",
                              color: "#fff",
                              boxShadow: "none",
                              borderRadius: 5,
                              height: 32,
                              width: 68
                            }}
                            disabled={title.length == 0 ? true : false}
                            onClick={() => {
                              updateName();
                              setTitleFlag(false);
                            }}
                          >
                            <span style={{ color: "white", fontSize: 13 }}>
                              <IntlMessages id={"profile.savebutton"} />
                            </span>
                          </button>
                          <button
                            className="c-btn c-default"
                            style={{
                              boxShadow: "none",
                              backgroundColor: "#E7E9EC",
                              borderRadius: 5,
                              height: 32,
                              width: 68
                            }}
                            onClick={() => {
                              setTitleFlag(false);
                              setTitle(task.title);
                            }}
                          >
                            <span style={{ color: "black", fontSize: 13 }}>
                              Cancel
                            </span>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          <div>
                            <div
                              className="mt-0 pretty p-icon p-round p-smooth"
                              style={{
                                marginRight: 10,
                                marginLeft: 2,
                                // backgroundColor: "white",
                                // borderRadius: 10,
                                fontSize: 20
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={checked}
                                onChange={e => updateIsCompleted(!checked)}
                              />
                              {!checked ? (
                                <img
                                  src={ListGridCircleImage}
                                  style={{
                                    height: 20,
                                    width: 20,
                                    // marginRight: 5,
                                    marginTop: 4
                                  }}
                                ></img>
                              ) : (
                                <img
                                  src={ListGridCheckImage}
                                  style={{
                                    height: 20,
                                    width: 20,
                                    marginTop: 4
                                    // marginRight: 5
                                  }}
                                ></img>
                              )}
                              {/* <div className="state p-success">
                                <i
                                  className="icon fas fa-check"
                                  style={{
                                    marginLeft: 0,
                                    marginRight: 5,
                                    backgroundColor: checked
                                      ? "#00D67F"
                                      : "#cacaca"
                                  }}
                                ></i>
                                <label></label>
                              </div> */}
                              {/* <div className="state p-info-o">
                                <i className="icon fas fa-check"></i>
                                <label></label>
                              </div> */}
                            </div>
                          </div>
                          <div
                            // className="task-title"
                            className={"primary-label"}
                            onClick={() => setTitleFlag(true)}
                            style={{
                              fontSize: "21px",
                              color: "black",
                              fontWeight: "bold",
                              marginTop: -2,
                              wordBreak: "break-all"
                            }}
                          >
                            {task.title}
                            {/* <i
                              className="fa fa-edit"
                              style={{
                                color: "grey",
                                fontSize: 10,
                                paddingLeft: 5
                              }}
                            ></i> */}
                          </div>
                        </div>
                        {props.sectionTitle && (
                          <span
                            className={"fs-13"}
                            style={{ color: "#808080", paddingLeft: 33 }}
                          >
                            in list{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                color: "#808080"
                              }}
                            >
                              {props.sectionTitle}
                            </span>
                          </span>
                        )}{" "}
                      </>
                    )}
                  </div>

                  {/* Task header actions */}
                  <div style={{ width: "40%" }} align="right">
                    <DialogHeaderActions
                      updateLabelToParent={props.updateLabelToParent}
                      PRIMARY_COLOR={props.topbarTheme.buttonColor}
                      openTaskCommentModal={() => setIsTaskCommentModal(true)}
                      setTaskModel={() => handleCloseModal()}
                      selectedLabels={task.labels}
                      selectLabelHandler={label => updateLabel(label)}
                      labelArr={props.labels.labels}
                      sharelink={shareLink}
                      taskPriority={task.priority}
                      selectPriorityHandler={priority =>
                        updatePriority(priority)
                      }
                      openAddLabel={() => setAddLabelModal(true)}
                      openAssignTaskModal={() =>
                        SetisAssignTaskModal(!isAssignTaskModal)
                      }
                      openTaskActivity={() => setTaskActivityModal(true)}
                      showTaskAssign={
                        true
                        // props.showTaskAssign ? props.showTaskAssign : false
                      }
                      openPrioritySelection={openPrioritySelection}
                      togglePrioritySelection={() =>
                        setOpenPrioritySelection(!openPrioritySelection)
                      }
                      // openShareSelection={openShareSelection}
                      // toggleShareSelection={() =>
                      //   setOpenShareSelection(!openShareSelection)
                      // }
                      openLabelSelection={openLabelSelection}
                      toggleLabelSelection={() =>
                        setOpenLabelSelection(!openLabelSelection)
                      }
                    />
                    {taskActivityModal && (
                      <TaskActivity
                        style={{ border: "1px soilid black" }}
                        isOpen={taskActivityModal}
                        toggleTaskActivity={() =>
                          setTaskActivityModal(!taskActivityModal)
                        }
                        taskId={taskDetail && taskDetail.id}
                      />
                    )}
                    <TaskCommentModal
                      projectId={props.projectId}
                      name={taskDetail && taskDetail.title}
                      isOpen={isTaskCommentModal}
                      toggle={() => setIsTaskCommentModal(!isTaskCommentModal)}
                      taskId={taskDetail && taskDetail.id}
                    />
                  </div>
                  <TaskAssignModal
                    isOpen={isAssignTaskModal}
                    toggle={() => {
                      SetisAssignTaskModal(!isAssignTaskModal);
                    }}
                    collaboratorList={props.collaboratorList}
                    dummyData={props.dummyData || false}
                    taskId={taskDetail && taskDetail.id}
                    projectId={props.projectId}
                    taskAssign={taskDetail && taskDetail.taskAssign}
                    doUpdateTaskAssign={updateTaskAssign}
                    updateTaskAssign={data => {
                      SetisAssignTaskModal(!isAssignTaskModal);
                      updateTaskAssign(data);
                      updateActiveTaskDetail &&
                        updateActiveTaskDetail({
                          ...taskDetail,
                          taskAssign: data
                            ? {
                                id: data.id,
                                name: data.name,
                                thumbnail: data.userMeta?.thumbnail
                              }
                            : null
                        });
                    }}
                  />
                  <AssignSubTaskModal
                    isOpen={isAssignSubTaskModal.status || false}
                    toggle={() =>
                      SetisAssignSubTaskModal({
                        status: !isAssignSubTaskModal.status,
                        data: ""
                      })
                    }
                    collaboratorList={props.collaboratorList}
                    taskId={
                      isAssignSubTaskModal && isAssignSubTaskModal?.data?.id
                    }
                    projectId={props.projectId}
                    dummyData={false}
                    taskAssign={
                      isAssignSubTaskModal &&
                      isAssignSubTaskModal?.data?.taskAssign
                    }
                    updatetaskAssign={(data, task_id) => {
                      setSubTaskDetails({ taskAssign: data });
                      handleSubtaskAssignee(data, task_id);
                    }}
                    socket={true}
                    assignSubtask={reqobj => doUpdateAssignSubtask(reqobj)}
                  />
                </div>
              </ModalHeader>
              <ModalBody
                style={{
                  backgroundColor: "#F4F5F7",
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10
                }}
              >
                {isDragEnter && (
                  <div
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "40%",
                      fontSize: 16,
                      fontWeight: "600"
                    }}
                  >
                    Drop your Files here.
                  </div>
                )}
                {isExceedAttachment && (
                  <ExceedLimit
                    setIsExceedAttachment={setIsExceedAttachment}
                    setIsDragEnter={setIsDragEnter}
                  />
                )}

                <div
                  className="task-body"
                  style={{
                    minHeight: 600,
                    opacity: isExceedAttachment || isDragEnter ? 0 : 1
                  }}
                >
                  <FileDrop
                    onFrameDragEnter={event => {
                      setIsDragEnter(true);
                    }}
                    onFrameDragLeave={event => {
                      setIsDragEnter(false);
                    }}
                    onFrameDrop={event => {
                      setIsDragEnter(false);
                    }}
                    onDragOver={event => {}}
                    onDragLeave={event => {}}
                    onDrop={(files, event) => {
                      AddAttachment(files[0]);
                    }}
                  >
                    <div>
                      <div
                        className="all-labels fs-14 "
                        style={{ marginLeft: 28 }}
                      >
                        {task &&
                          task.priority &&
                          task.priority != "no_priority" && (
                            <div
                              // id="PUncontrolledPopover"
                              className={classNames("demi-bold-text")}
                              style={{ padding: 5 }}
                            >
                              <div
                                style={{ width: "100px" }}
                                id="PUncontrolledPopoversLeft"
                                onClick={() =>
                                  setOpenSelectedPrioritySelection(
                                    !openSelectedPrioritySelection
                                  )
                                }
                              >
                                {allPriority.map(item => {
                                  if (item.name == task.priority) {
                                    return (
                                      <>
                                        <img
                                          src={getPriorityPath(item.name)}
                                          height="18"
                                          width="18"
                                          className=""
                                          alt="Priority"
                                          // style={{ cursor: "pointer",color: priority.color }}
                                        />
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            padding: "3px 6px",
                                            color: "black"
                                          }}
                                        >
                                          {item.value}
                                        </span>
                                      </>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          )}
                        {task &&
                          task.priority &&
                          openSelectedPrioritySelection && (
                            <UncontrolledPopover
                              isOpen={openSelectedPrioritySelection}
                              trigger="legacy"
                              className="roy-menu"
                              hideArrow={true}
                              innerClassName="roy-inner-content"
                              placement="bottom-start"
                              target="PUncontrolledPopoversLeft"
                              toggle={() =>
                                setOpenSelectedPrioritySelection(
                                  !openSelectedPrioritySelection
                                )
                              }
                            >
                              <PopoverBody style={{ minWidth: "220px" }}>
                                <div
                                  className="float-right"
                                  onClick={() =>
                                    setOpenSelectedPrioritySelection(
                                      !openSelectedPrioritySelection
                                    )
                                  }
                                >
                                  <img
                                    src={NewTaskPriorityCross}
                                    style={{
                                      height: "10px",
                                      width: "10px",
                                      marginRight: "5px",
                                      marginTop: "6px"
                                    }}
                                  ></img>
                                  {/* <i className="fa fa-times text-muted" /> */}
                                </div>
                                <div
                                  className="fs-14 text-center border-bottom"
                                  style={{
                                    padding: "4px 4px 10px 4px",
                                    color: "#808080",
                                    borderBottom: "1px solid #ebebeb"
                                  }}
                                >
                                  Priority
                                </div>

                                {allPriority &&
                                  task &&
                                  allPriority.map((priority, i) => {
                                    return (
                                      <div
                                        className="roy-menu-list"
                                        key={priority.value}
                                        // id="PUncontrolledPopoversLeft"
                                        onClick={() => {
                                          updatePriority(priority.name);
                                          setOpenSelectedPrioritySelection(
                                            !openSelectedPrioritySelection
                                          );
                                        }}
                                      >
                                        <div
                                          className="label-list"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center"
                                            }}
                                          >
                                            <div>
                                              <img
                                                src={priority.path}
                                                height="20"
                                                width="16"
                                                className=""
                                                alt={priority.name}
                                                style={{
                                                  cursor: "pointer",
                                                  color: priority.color
                                                }}
                                              />
                                            </div>
                                            <div style={{ marginLeft: 10 }}>
                                              {priority.value}
                                            </div>
                                          </div>
                                          {priority.name == task.priority && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center"
                                              }}
                                            >
                                              <IconTint
                                                maxHeight="15"
                                                maxWidth="15"
                                                src={singleCheckIcon}
                                                color={
                                                  props.topbarTheme.buttonColor
                                                }
                                              />
                                              {/* <img
                                                src={singleCheckIcon}
                                                className={classNames("mlr-15")}
                                              ></img> */}
                                              {/* <i
                                                className={classNames(
                                                  "fas fa-check-double",
                                                  "ml-15",
                                                  `${priority.color}--text`
                                                )}
                                                style={{
                                                  color: priority.color,
                                                }}
                                              ></i> */}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </PopoverBody>
                            </UncontrolledPopover>
                          )}
                      </div>
                      <div className="row">
                        <div
                          className="col-lg-8 col-md-8 col-sm-12 col-xl-8 col-xs-12"
                          style={{
                            paddingTop: 1,
                            paddingLeft: 24,
                            paddingRight: 25
                          }}
                        >
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {/* Task asssigin */}
                            {props.taskDetail?.taskAssign && (
                              <>
                                <div
                                  style={{
                                    marginLeft: "25px",
                                    marginTop: "10px"
                                  }}
                                >
                                  <div
                                    className="item-header"
                                    style={{
                                      fontSize: "14px",
                                      color: "black",
                                      fontWeight: "600"
                                    }}
                                  >
                                    {" "}
                                    task Assignee
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignSelf: "center",
                                      height: 40,
                                      alignItems: "center"
                                    }}
                                  >
                                    <span>
                                      <p
                                        style={profileBackground(
                                          props.taskDetail.taskAssign.name &&
                                            props.taskDetail.taskAssign.name,
                                          22,
                                          22,
                                          12
                                        )}
                                      >
                                        {props.taskDetail.taskAssign.name &&
                                          props.taskDetail.taskAssign.name
                                            .charAt(0)
                                            .toUpperCase()}
                                      </p>
                                    </span>
                                    <span
                                      style={{
                                        marginTop: "-1px",
                                        paddingLeft: "6px",
                                        color: "#808080",
                                        fontSize: "13px"
                                      }}
                                    >
                                      <Assignee
                                        thumbnail={
                                          props.taskDetail.taskAssign.thumbnail
                                            ? props.taskDetail.taskAssign
                                                .thumbnail
                                            : null
                                        }
                                        name={
                                          props.taskDetail.taskAssign.name
                                            ? props.taskDetail.taskAssign.name
                                            : props.taskDetail.taskAssign
                                                .email &&
                                              props.taskDetail.taskAssign.email.split(
                                                "@"
                                              )[0]
                                        }
                                      />
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Labels */}
                            {!props.label &&
                              task?.labels &&
                              task.labels.length > 0 && (
                                <div style={{ marginLeft: 25, marginTop: 10 }}>
                                  <div
                                    className="item-header"
                                    style={{
                                      fontSize: "14px",
                                      color: "black",
                                      fontWeight: "600"
                                    }}
                                  >
                                    {" "}
                                    labels
                                  </div>
                                  <div
                                    className="label-block"
                                    style={{ maxWidth: "100%", marginTop: 0 }}
                                  >
                                    <div
                                      className="all-labels"
                                      style={{
                                        justifyContent: "left",
                                        display: "flex",
                                        // height: 40,
                                        alignItems: "center"
                                      }}
                                    >
                                      {task &&
                                        task.labels &&
                                        task.labels
                                          .sort(function(a, b) {
                                            return (
                                              a.name.length - b.name.length
                                            );
                                          })
                                          .map((e, i) => {
                                            return (
                                              <>
                                                <div
                                                  key={i}
                                                  title={e.name}
                                                  id="UncontrolledPopoverLeft"
                                                  onClick={
                                                    () =>
                                                      setOpenLabelSelection(
                                                        !openLabelSelection
                                                      )
                                                    // setOpenSelectedLabelSelection(
                                                    //   !openSelectedLabelSelection
                                                    // )
                                                  }
                                                  className={classNames(
                                                    "label",
                                                    "demi-bold-text"
                                                  )}
                                                  style={{
                                                    backgroundColor: getColorCode(
                                                      e.color
                                                    ),
                                                    cursor: "pointer",
                                                    padding:
                                                      "4px 15px 4px 15px",
                                                    display: "flex",
                                                    height: "30px",
                                                    alignItems: "center",
                                                    margin: "5px 8px 5px 0px"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "12px",
                                                      color: "white",
                                                      fontWeight: "600",
                                                      whiteSpace: "nowrap",
                                                      textOverflow: "ellipsis",
                                                      overflow: "hidden",
                                                      alignItems: "center"
                                                    }}
                                                  >
                                                    {" "}
                                                    {e.name}
                                                  </span>
                                                </div>
                                                {/* {task &&
                                        task.labels &&    <UncontrolledPopover
                                                  isOpen={
                                                    openSelectedLabelSelection
                                                  }
                                                  trigger="legacy"
                                                  className="roy-menu"
                                                  innerClassName="roy-inner-content"
                                                  placement="bottom-end"
                                                  target="UncontrolledPopoverLeft"
                                                  toggle={() =>
                                                    setOpenSelectedLabelSelection(
                                                      !openSelectedLabelSelection
                                                    )
                                                  }
                                                >
                                                  <PopoverBody
                                                    style={{
                                                      minWidth: "200px"
                                                    }}
                                                    onBlur={() =>
                                                      setOpenSelectedLabelSelection(
                                                        !openSelectedLabelSelection
                                                      )
                                                    }
                                                  >
                                                    <div
                                                      className="float-right"
                                                      onClick={() =>
                                                        setOpenSelectedLabelSelection(
                                                          !openSelectedLabelSelection
                                                        )
                                                      }
                                                    >
                                                      <i className="fa fa-times text-muted" />
                                                    </div>
                                                    <div
                                                      className="fs-14 text-center border-bottom "
                                                      style={{ padding: 4 }}
                                                    >
                                                      <b>Labels</b>
                                                    </div>

                                                    {labelArr &&
                                                    labelArr.length > 0 ? (
                                                      <div
                                                        className="border-bottom"
                                                        style={{
                                                          maxHeight: "250px",
                                                          minHeight: "125",
                                                          overflowX: "auto"
                                                        }}
                                                      >
                                                        {labelArr.map(
                                                          (label, i) => {
                                                            return (
                                                              <div
                                                                className="roy-menu-list"
                                                                key={label.id}
                                                                onClick={() => {
                                                                  updateLabel(
                                                                    label
                                                                  );
                                                                  
                                                                }}
                                                              >
                                                                <div className="label-list">
                                                                  <div
                                                                  >
                                                                    <i
                                                                      className="fa fa-tag"
                                                                      style={{
                                                                        color: getColorCode(
                                                                          label.color
                                                                        )
                                                                      }}
                                                                    />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      marginLeft: 10,
                                                                      overflow:
                                                                        "hidden",
                                                                      textOverflow:
                                                                        "ellipsis",
                                                                      display:
                                                                        "-webkit-box",
                                                                      WebkitLineClamp: 4 ,
                                                                      WebkitBoxOrient:
                                                                        "vertical"
                                                                    }}
                                                                  >
                                                                    {label.name}
                                                                  </div>
                                                                  {labelIds &&
                                                                    labelIds.includes(
                                                                      label.id
                                                                    ) && (
                                                                      <div>
                                                                        <i
                                                                          className={classNames(
                                                                            "fas fa-check-double",
                                                                            "ml-15",
                                                                            `${label.color}--text`
                                                                          )}
                                                                          style={{
                                                                            color: getColorCode(
                                                                              label.color
                                                                            )
                                                                          }}
                                                                        ></i>
                                                                      </div>
                                                                    )}
                                                                </div>
                                                              </div>
                                                            );
                                                          }
                                                        )}{" "}
                                                      </div>
                                                    ) : (
                                                      <>
                                                        <div
                                                          className="pa-50 border-bottom"
                                                          style={{
                                                            cursor: "auto"
                                                          }}
                                                        >
                                                          <div className="text-muted text-center">
                                                            {"No Label"}
                                                          </div>
                                                        </div>
                                                        <div></div>
                                                      </>
                                                    )}
                                                    <div style={{ padding: 6 }}>
                                                      <button
                                                        className="btn btn button fs-14"
                                                        style={{
                                                          width: "100%",
                                                          boxSizing:
                                                            "border-box",
                                                          WebkitAppearance:
                                                            "none",
                                                          borderRadius: 3,
                                                          border: "none",
                                                          display:
                                                            "inline-block",
                                                          textDecoration:
                                                            "none",
                                                          position: "relative",
                                                          color: "black",
                                                          backgroundColor:
                                                            "rgba(9, 30, 66, 0.04)",
                                                          cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                          setAddLabelModal(
                                                            true
                                                          );
                                                          // toggleOpenNewModal();
                                                        }}
                                                      >
                                                        Create new label
                                                      </button>
                                                    </div>
                                                  </PopoverBody>
                                                </UncontrolledPopover>
                                            }  */}
                                              </>
                                            );
                                          })}
                                    </div>
                                  </div>
                                </div>
                              )}
                            {/* Due Date */}{" "}
                            {task.scheduledAt ? (
                              <div style={{ marginLeft: 25, marginTop: 10 }}>
                                <div
                                  className="item-header"
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    fontWeight: "600"
                                  }}
                                >
                                  Schedule
                                </div>
                                <div
                                  style={{
                                    marginTop: 12,
                                    height: 32,
                                    width: 140
                                  }}
                                >
                                  <span
                                    className="due-date-dialog"
                                    style={{ fontSize: "13px", color: "black" }}
                                  >
                                    {" "}
                                    {moment(task.scheduledAt).format(
                                      "DD MMM hh:mm a"
                                    )}{" "}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {task.reminderAt || remindersDate ? (
                              <div
                                style={{
                                  marginLeft: 20,
                                  marginTop: 10,
                                  height: 32,
                                  width: 140
                                }}
                              >
                                <div
                                  className="item-header"
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    fontWeight: "600"
                                  }}
                                >
                                  Reminders
                                </div>
                                <div style={{ marginTop: 12 }}>
                                  <span
                                    className="due-date-dialog"
                                    style={{ fontSize: "13px", color: "black" }}
                                  >
                                    {" "}
                                    {moment(
                                      task.reminderAt || remindersDate
                                    ).format("DD MMM hh:mm a")}{" "}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {/* Description */}
                          <div style={{ display: "flex", marginTop: 35 }}>
                            <div>
                              <img
                                src={TaskDialogDescriptionImage}
                                height="15"
                                width="16"
                                style={{
                                  color: "grey",
                                  marginRight: 10,
                                  position: "relative",
                                  top: "2px",
                                  left: -4,
                                  marginLeft: "-3px"
                                }}
                                className=" "
                                alt="Labels"
                              />
                              {/* <i
                                className="fas fa-align-left"
                                style={{
                                  color: "grey",
                                  paddingRight: 10,
                                  fontSize: 14,
                                  position: "relative",
                                  top: "3px"
                                  // paddingTop: 8
                                }}
                              ></i> */}
                            </div>
                            <div style={getDescriptionStyle()}>
                              <span
                                className={"primary-label"}
                                style={{
                                  fontSize: 15,
                                  fontWeight: "600",
                                  marginRight: 8
                                }}
                              >
                                Description{" "}
                              </span>
                              {task.description && (
                                <button
                                  className="c-btn "
                                  style={{
                                    cursor: "pointer",
                                    background: "rgba(9, 30, 66, 0.04)",
                                    boxShadow: "none",
                                    height: 22,
                                    position: "relative",
                                    top: "6px"
                                  }}
                                  onClick={() => {
                                    setDescription(task.description);
                                    setDescriptionInput(true);
                                  }}
                                >
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      position: "relative",
                                      top: "-6px",
                                      fontSize: 12,
                                      color: "black"
                                    }}
                                  >
                                    {" "}
                                    {" Edit "}
                                  </span>
                                </button>
                              )}
                              {descriptionInput ? (
                                <>
                                  {" "}
                                  {descriptionLoader ? (
                                    <div style={{ display: "inline-flex" }}>
                                      {" "}
                                      <Loader height="25px" width="20px" />
                                    </div>
                                  ) : (
                                    <CustomEditor
                                      value={description}
                                      placeholder={"Add a description"}
                                      onChangeValue={setDescription}
                                      collaboratorsList={props.collaboratorList}
                                      handleSubmitData={() => {
                                        handleAddDescription(task.id);
                                      }}
                                      toggleInput={() =>
                                        setDescriptionInput(!descriptionInput)
                                      }
                                    />
                                  )}
                                </>
                              ) : (
                                <>
                                  <div style={{ paddingTop: 10 }}>
                                    <div
                                      className="flex-x"
                                      style={{
                                        wordBreak: "break-all"
                                        // borderBottom:
                                        //   "1px solid rgb(232, 232, 232)",
                                        // padding: "10px 0px"
                                      }}
                                    >
                                      {task.description &&
                                        task.description != "" &&
                                        task.description != " " && (
                                          <div
                                            className="flex-1"
                                            dangerouslySetInnerHTML={{
                                              __html: task.description
                                            }}
                                            style={{
                                              backgroundColor: "#FFFFFF99",
                                              padding: 10,
                                              borderRadius: 5,
                                              border: "1px solid #EBEBEB"
                                            }}
                                          ></div>
                                        )}
                                    </div>
                                  </div>
                                  {!task.description && (
                                    <button
                                      className="dialog-chip mt-10"
                                      style={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        fontWeight: "100",
                                        backgroundColor: "white",
                                        // border: "1px solid #ebebebyar",
                                        textAlign: "left",
                                        cursor: "text",
                                        height: 45,
                                        display: "flex",
                                        color: "grey",
                                        border: "1px solid #EBEBEB"
                                      }}
                                      onClick={() => setDescriptionInput(true)}
                                    >
                                      <span
                                        style={{
                                          fontSize: 13,
                                          color: "#808080"
                                        }}
                                      >
                                        {" "}
                                        Add description
                                      </span>
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                          </div>

                          {/* Attachment */}
                          {attachmentCommentsLoad && <AttachmentShimmer />}
                          {attachmentComments.length > 0 && (
                            <>
                              <div style={{ display: "flex", marginTop: 10 }}>
                                <div>
                                  <img
                                    src={TaskDialogAttachmentImage}
                                    height="16"
                                    width="15"
                                    style={{
                                      marginRight: 10,
                                      fontSize: 13,
                                      position: "relative",
                                      top: "-2px",
                                      left: "-4px",
                                      marginLeft: "-2px"
                                    }}
                                  />
                                  {/* <i
                                    className="fas fa-paperclip"
                                    style={{
                                      color: "grey",
                                      paddingRight: 10,
                                      fontSize: 16
                                    }}
                                  ></i> */}
                                </div>
                                <div style={{ width: "100%" }}>
                                  <span
                                    className={"primary-label"}
                                    style={{ fontSize: 15, fontWeight: "600" }}
                                  >
                                    Attachment(s)
                                  </span>
                                  <AttachmentUI
                                    PRIMARY_COLOR={
                                      props.topbarTheme.buttonColor
                                    }
                                    attachmentComments={attachmentComments}
                                    onDelete={id =>
                                      handleDeleteComment(id, task.id)
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {isUploadAttachent && <UploadAttachmentShimmer />}

                          {attachmentComments.length > 0 && (
                            <div
                              className=""
                              style={{
                                marginLeft: 23,
                                backgroundColor: !isUploadAttachent
                                  ? "#FFFFFFB3"
                                  : "",
                                borderBottomLeftRadius: 5,
                                borderBottomRightRadius: 5,
                                border: !isUploadAttachent
                                  ? "1px solid #EBEBEB"
                                  : "",
                                borderTop: !isUploadAttachent ? "none" : ""
                              }}
                            >
                              {isUploadAttachent ? (
                                <label
                                  className=" c-btn c-default "
                                  style={{
                                    background: "rgba(9, 30, 66, 0.04)",
                                    boxShadow: "none",
                                    fontSize: 12,
                                    marginLeft: 3
                                  }}
                                >
                                  <Loader height="25px" width="20px" />
                                </label>
                              ) : (
                                <label
                                  className=" c-btn c-default "
                                  style={{
                                    background: "#E7E9EC",
                                    boxShadow: "none",
                                    fontSize: 11,
                                    color: "black",
                                    marginLeft: 15,
                                    marginBottom: 10
                                  }}
                                  htmlFor="commentsAttachment"
                                >
                                  <label
                                    htmlFor="commentsAttachment"
                                    className="cursor-pointer"
                                    style={{
                                      marginBottom: 0,
                                      fontSize: 11,
                                      color: "black",
                                      fontWeight: "600"
                                    }}
                                  >
                                    Add an attachment
                                  </label>
                                </label>
                              )}
                              {isExceedAttachment && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  File size exceeds 10MB limit
                                </div>
                              )}
                            </div>
                          )}

                          {/* Checklist */}
                          {isLoaderChecklist && <Shimmer />}
                          {task &&
                            subtaskList &&
                            subtaskList.length > 0 &&
                            subtaskList.map((checklist, checklistIndex) => {
                              return (
                                <div key={checklistIndex} className="mt-40">
                                  <div className="subtask-block-header">
                                    <div className="subtask-title fs-14">
                                      {/* <i
                                  className="fas fa-check-double"
                                  style={{
                                    color: "grey",
                                    paddingRight: 10,
                                    fontSize: 16
                                  }}
                                ></i> */}
                                      <CheckListTitle
                                        checklist={checklist}
                                        style={{
                                          fontSize: 15,
                                          fontWeight: "600"
                                        }}
                                        PRIMARY_COLOR={
                                          props.topbarTheme.buttonColor
                                        }
                                        deleteChecklist={(id, title) =>
                                          openCustomAlert(
                                            true,
                                            "delete",
                                            "checklist",
                                            id,
                                            title
                                          )
                                        }
                                        updateChecklistTitle={async (
                                          data,
                                          id
                                        ) => {
                                          await doUpdateChecklistTitle(
                                            data,
                                            id
                                          );
                                          setToast(true);
                                          setToastMessage(
                                            "Checklist title updated!"
                                          );
                                          setTimeout(() => {
                                            setToast(false);
                                          }, 2500);
                                        }}
                                      />
                                      {checklist.subTask && (
                                        <div
                                          style={{
                                            width: "100%",
                                            marginLeft: 20
                                          }}
                                        >
                                          <Progress
                                            percent={
                                              checklist.subTask.length > 0
                                                ? (
                                                    (checklist.subTask.filter(
                                                      s => s.isCompleted
                                                    ).length /
                                                      checklist.subTask
                                                        .length) *
                                                    100
                                                  ).toFixed(0)
                                                : 0
                                            }
                                            theme={{
                                              active: {
                                                color: `#5BA4CF`
                                              }
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="subtask-list"
                                    style={{ marginLeft: 8 }}
                                  >
                                    {checklist.subTask &&
                                      checklist.subTask.map(
                                        (subtask, index) => {
                                          return (
                                            <Subtask
                                              AllowAddPreviousDateTask={
                                                AllowAddPreviousDateTask
                                              }
                                              collaboratorList={
                                                props.collaboratorList
                                              }
                                              PRIMARY_COLOR={
                                                props.topbarTheme.buttonColor
                                              }
                                              key={index}
                                              subtask={subtask}
                                              deleteSubtaskHandler={subtaskId =>
                                                doDeleteSubtask(
                                                  subtaskId,
                                                  checklist.id
                                                )
                                              }
                                              onChangeDueDateSubtask={(
                                                id,
                                                date
                                              ) =>
                                                onChangeDueDateSubtask(
                                                  id,
                                                  date,
                                                  checklist.id
                                                )
                                              }
                                              changeStatusOfSubtaskHandler={(
                                                subtaskId,
                                                checked
                                              ) =>
                                                updateIsCompletedSubTask(
                                                  subtaskId,
                                                  checked,
                                                  checklist.id
                                                )
                                              }
                                              updateSubtask={async (
                                                data,
                                                id
                                              ) => {
                                                await doUpdateSubtask(
                                                  { title: data },
                                                  id,
                                                  checklist.id
                                                );
                                                setToast(true);
                                                setToastMessage(
                                                  "Item name updated!"
                                                );
                                                setTimeout(() => {
                                                  setToast(false);
                                                }, 2500);
                                              }}
                                              addCommentHandler={(
                                                commentObj,
                                                subtaskId
                                              ) =>
                                                addCommentHandler(
                                                  commentObj,
                                                  subtaskId,
                                                  task.id
                                                )
                                              }
                                              openAssignSubTaskModal={subtask => {
                                                SetisAssignSubTaskModal({
                                                  status: !isAssignSubTaskModal.status,
                                                  data: {
                                                    id: subtask.id,
                                                    taskAssign:
                                                      subtask.taskAssign
                                                  }
                                                });
                                              }}
                                              projectTask={false}
                                            />
                                          );
                                        }
                                      )}
                                    <div className="">
                                      <AddItemInput
                                        PRIMARY_COLOR={
                                          props.topbarTheme.buttonColor
                                        }
                                        isAddItemFlag={
                                          subtaskList.length ==
                                          checklistIndex + 1
                                            ? isAddItemFlag
                                            : false
                                        }
                                        setIsAddItemFlag={() =>
                                          setIsAddItemFlag(!isAddItemFlag)
                                        }
                                        addNewSubtask={(
                                          checklistId,
                                          subtaskTitle
                                        ) => {
                                          addNewSubtask(
                                            checklistId,
                                            subtaskTitle
                                          );
                                        }}
                                        checklistId={checklist.id}
                                        setChecklistId={data =>
                                          setChecklistId(data)
                                        }
                                        todoId={
                                          props.todoId
                                            ? props.todoId.toString()
                                            : taskDetail.id
                                        }
                                        projectId={props.projectId.toString()}
                                        subtaskList={subtaskList}
                                        setSubtaskList={data =>
                                          setSubtaskList(data)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}

                          {/* Add checklist */}
                          <div style={{ display: "flex", marginTop: 10 }}>
                            <div style={{ width: 20 }}></div>
                            <div
                              className="subtitle-title"
                              style={{ width: "100%" }}
                            >
                              <div>
                                {checklistLoader && <ChecklistShimmer />}

                                {addChecklistFlag && (
                                  <>
                                    <div className="add-comment-input-block mt-10">
                                      <input
                                        ref={textArea}
                                        value={ChecklistInput}
                                        autoFocus
                                        className="add-subtask-input inputStyle"
                                        name="checklistTitle"
                                        rows="1"
                                        onChange={e =>
                                          setChecklistInput(e.target.value)
                                        }
                                        // onBlur={() => {
                                        //   setAddChecklistFlag(false);
                                        //   setChecklistInput("");
                                        // }}
                                        style={{
                                          border: "1px solid #e2e2e2",
                                          backgroundColor: "#fff"
                                        }}
                                        placeholder={"Title"}
                                        onKeyPress={event => {
                                          if (event.key === "Enter") {
                                            /* checks if white space at starting of input present or not */
                                            if (
                                              !event.shiftKey &&
                                              ChecklistInput.match(
                                                /^\S+\w{0,50}\S{0,}/g
                                              )
                                            ) {
                                              event.preventDefault();
                                              addnewCheckList();
                                            }
                                          }
                                        }}
                                      />
                                      <div
                                        className="subtask-action"
                                        style={{ margin: "15px 0px" }}
                                      >
                                        <>
                                          <button
                                            className="c-btn mr-10"
                                            style={{
                                              backgroundColor:
                                                props.topbarTheme.buttonColor,
                                              color: "#fff",
                                              boxShadow: "none"
                                            }}
                                            disabled={
                                              !ChecklistInput.match(
                                                /^\S+\w{0,50}\S{0,}/g
                                              )
                                                ? true
                                                : false
                                            }
                                            onClick={() => {
                                              addnewCheckList();
                                              setChecklistInput("");
                                            }}
                                          >
                                            Add Checklist
                                          </button>
                                          <button
                                            className="c-btn c-default"
                                            onClick={() => {
                                              setAddChecklistFlag(false);
                                              setChecklistInput("");
                                            }}
                                            style={{
                                              boxShadow: "none"
                                            }}
                                          >
                                            Cancel
                                          </button>
                                        </>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Activity */}
                          <div
                            style={{ display: "flex", marginTop: 10 }}
                            id="taskactivity"
                            ref={taskactivity}
                          >
                            <div>
                              <img
                                src={TaskDialogActivityImage}
                                height="18"
                                width="16"
                                style={{
                                  marginRight: 10,
                                  fontSize: 16,
                                  position: "relative",
                                  top: "4px",
                                  left: "-4px",

                                  marginLeft: -2
                                }}
                              />
                              {/* <i
                                className="fas fa-history"
                                style={{
                                  color: "grey",
                                  paddingRight: 10,
                                  fontSize: 16,
                                  position: "relative",
                                  top: "5px"
                                }}
                              ></i> */}
                            </div>
                            <div style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between"
                                }}
                              >
                                <span
                                  className={"primary-label"}
                                  style={{
                                    marginTop: "6px",
                                    fontSize: 15,
                                    fontWeight: "600",
                                    color: "black"
                                  }}
                                >
                                  Activity
                                </span>
                                <span
                                  className={"fs-13 "}
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: "#E7E9EC",
                                    padding: "9px 8px",
                                    borderRadius: 5,
                                    height: 25,
                                    marginTop: 6
                                  }}
                                  onClick={handleToggleActivity}
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontSize: 12,
                                      position: "relative",
                                      top: "-7px"
                                    }}
                                  >
                                    {isHideActivity
                                      ? "Show Activity"
                                      : "Hide Activity"}
                                  </span>
                                </span>
                              </div>
                              {commentInput ? (
                                <>
                                  <CustomEditor
                                    collaboratorsList={props.collaboratorList}
                                    type={"comment"}
                                    value={comment}
                                    placeholder={"Write a comment"}
                                    onChangeValue={setComment}
                                    handleSubmitData={() => handleAddComment()}
                                    toggleInput={() => {
                                      setComment("");
                                      setCommentInput(!commentInput);
                                    }}
                                    isLoaderButton={isLoaderComment || false}
                                  />
                                </>
                              ) : (
                                <button
                                  className="dialog-chip mt-10"
                                  style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    fontWeight: "100",
                                    backgroundColor: "white",
                                    border: "1px solid #EBEBEB",
                                    textAlign: "left",
                                    cursor: "text",
                                    color: "grey",
                                    borderRadius: 5
                                  }}
                                  onClick={() => setCommentInput(true)}
                                >
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "#808080"
                                    }}
                                  >
                                    {" "}
                                    Write a comment ...
                                  </span>
                                </button>
                              )}
                              {isLoader && <ActivityShimmer />}
                              <div style={{ marginLeft: -36 }}>
                                {ActivityHistory &&
                                  ActivityHistory.map((Activity, i) => {
                                    if (!isHideActivity) {
                                      if (
                                        Activity.hasOwnProperty("activity_type")
                                      ) {
                                        return (
                                          <>
                                            <TaskactivityListSingle
                                              key={i}
                                              lastActivity={
                                                ActivityHistory?.length - 1 == i
                                                  ? true
                                                  : false
                                              }
                                              taskActivityArr={Activity}
                                            />
                                          </>
                                        );
                                      }
                                    }
                                    if (Activity.hasOwnProperty("commentBy")) {
                                      return (
                                        <TaskCommentList
                                          lastActivity={
                                            ActivityHistory?.length - 1 == i
                                              ? true
                                              : false
                                          }
                                          key={i}
                                          checkEmojiExist={(
                                            unicode,
                                            taskCommentId
                                          ) =>
                                            checkEmojiExist(
                                              unicode,
                                              taskCommentId
                                            )
                                          }
                                          Activity={Activity}
                                          onDelete={id => {
                                            setDeleteCommentId(id);
                                            openCustomAlert(
                                              true,
                                              "delete",
                                              "comment",
                                              id
                                            );
                                          }}
                                          addReactionsComment={(
                                            unicode,
                                            taskCommentId
                                          ) => {
                                            addCommentReactions(
                                              unicode,
                                              taskCommentId
                                            );
                                          }}
                                          currentLoggedInUser={
                                            props?.auth?.user?.id
                                          }
                                          user={
                                            props.auth?.user && props.auth?.user
                                          }
                                          handleRemoveCommentReactions={(
                                            id,
                                            taskCommentId
                                          ) => {
                                            handleRemoveCommentReactions(
                                              id,
                                              taskCommentId
                                            );
                                          }}
                                        />
                                      );
                                    } else {
                                      if (Activity.hasOwnProperty("comment")) {
                                        let ActivityData = Activity;
                                        ActivityData.commentBy = {
                                          name: props.auth.user.name
                                        };
                                        return (
                                          <TaskCommentList
                                            key={i}
                                            lastActivity={
                                              ActivityHistory?.length - 1 == i
                                                ? true
                                                : false
                                            }
                                            Activity={ActivityData}
                                            checkEmojiExist={(
                                              unicode,
                                              taskCommentId
                                            ) =>
                                              checkEmojiExist(
                                                unicode,
                                                taskCommentId
                                              )
                                            }
                                            onDelete={id => {
                                              setDeleteCommentId(id);
                                              openCustomAlert(
                                                true,
                                                "delete",
                                                "comment",
                                                id
                                              );
                                            }}
                                            addReactionsComment={(
                                              unicode,
                                              taskCommentId
                                            ) => {
                                              addCommentReactions(
                                                unicode,
                                                taskCommentId
                                              );
                                            }}
                                            user={
                                              props.auth?.user &&
                                              props.auth?.user
                                            }
                                            handleRemoveCommentReactions={(
                                              id,
                                              taskCommentId
                                            ) => {
                                              handleRemoveCommentReactions(
                                                id,
                                                taskCommentId
                                              );
                                            }}
                                          />
                                        );
                                      }
                                    }
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-lg-4 col-md-4 col-sm-12 col-xl-4 col-xs-12"
                          style={{ marginTop: 15 }}
                        >
                          {/* Due date */}
                          <div>
                            <div style={{ marginBottom: 8 }}>
                              <DatepickerWrapper {...props}>
                                <DatePicker
                                  arrow={false}
                                  open={isDueDateCalender}
                                  onClickOutside={e =>
                                    setIsDueDateCalender(!isDueDateCalender)
                                  }
                                  value={dueDate}
                                  shouldCloseOnSelect={false}
                                  selected={dueDate ? dueDate : ""}
                                  onChange={onChangeDueDate}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  dateFormat="MMMM d, yyyy h:mm aa"
                                  timeCaption="Time"
                                  className="custom-datepicker"
                                  calendarClassName="custom-calender-class"
                                  placeholderText={"Schedule"}
                                  // isClearable={true}
                                  minDate={
                                    !AllowAddPreviousDateTask && new Date()
                                  }
                                  customInput={<DueDateInput />}
                                  locale={locale}
                                  // calendarContainer={DueDateCalendar}
                                >
                                  <div>
                                    <CalendarContainer>
                                      <div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignContent: "center",
                                            width: "100%",
                                            fontSize: 14,
                                            padding: 10,
                                            borderRadius: 4,
                                            color: "#FFF",
                                            height: "20%"
                                          }}
                                        >
                                          <div
                                            onClick={() => {
                                              onChangeDueDate("");
                                              updateDueDate("");
                                            }}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignContent: "center",
                                              fontSize: 14,

                                              padding: 6,
                                              borderRadius: 4,
                                              cursor: "pointer",
                                              border: "1px solid #E6E6E6",
                                              color: "black",
                                              marginRight: "8px"
                                            }}
                                          >
                                            Remove Date
                                          </div>
                                          <div
                                            onClick={() => {
                                              updateDueDate(
                                                dueDate ? dueDate : new Date()
                                              );
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignContent: "center",

                                              fontSize: 14,
                                              background:
                                                props.topbarTheme.buttonColor,
                                              padding: 6,
                                              borderRadius: 4,
                                              color: "#FFF"
                                            }}
                                          >
                                            Apply
                                          </div>
                                        </div>
                                      </div>
                                    </CalendarContainer>
                                  </div>
                                </DatePicker>
                              </DatepickerWrapper>
                            </div>
                          </div>
                          {/* Reminders date */}
                          <div>
                            {dueDate ? (
                              <div style={{ marginBottom: 8 }}>
                                <DatepickerWrapper {...props}>
                                  <DatePicker
                                    open={isRemindersDateCalender}
                                    onClickOutside={e =>
                                      setIsRemindersDateCalender(
                                        !isRemindersDateCalender
                                      )
                                    }
                                    isRemindersDateCalender
                                    // value={remindersDate}
                                    // selected={remindersDate}
                                    value={remindersDateOnChangeSet}
                                    selected={remindersDateOnChangeSet}
                                    onChange={onChangeReminderDate}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    timeCaption="Time"
                                    className="custom-datepicker"
                                    calendarClassName="custom-calender-class"
                                    placeholderText={"Reminders"}
                                    // isClearable={true}
                                    minDate={new Date()}
                                    maxDate={dueDate}
                                    customInput={<RemindersInput />}
                                    // calendarContainer={ReminderDateCalendar}
                                    locale={locale}
                                  >
                                    <div>
                                      <CalendarContainer>
                                        <div
                                          // onClick={() => updateRemindersDate("")}
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            // display: "grid",
                                            alignContent: "center",
                                            backgroundColor: "white",
                                            // textDecoration: "underline",
                                            borderBottom: "1px solid #aeaeae",
                                            borderRight: "1px solid #aeaeae",
                                            borderLeft: "1px solid #aeaeae",
                                            borderTop: "none !important",
                                            border: "0px solid",
                                            borderBottomLeftRadius: "0.3rem",
                                            borderBottomRightRadius: "0.3rem",
                                            fontSize: 12,
                                            padding: "10px"
                                          }}
                                        >
                                          <div
                                            style={{
                                              paddingRight: 8
                                            }}
                                          >
                                            <div
                                              onClick={() => {
                                                updateRemindersDate("");
                                                onChangeReminderDate("");
                                              }}
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                fontSize: 14,
                                                padding: 6,
                                                borderRadius: 4,
                                                cursor: "pointer",
                                                border: "1px solid #E6E6E6"
                                              }}
                                            >
                                              Remove Date
                                            </div>
                                          </div>
                                          <div
                                            onClick={() =>
                                              updateRemindersDate(
                                                remindersDateOnChangeSet
                                                  ? remindersDateOnChangeSet
                                                  : new Date()
                                              )
                                            }
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignContent: "center",

                                              fontSize: 14,
                                              background:
                                                props.topbarTheme.buttonColor,
                                              padding: 6,
                                              borderRadius: 4,
                                              cursor: "pointer",
                                              color: "#FFF"
                                            }}
                                          >
                                            Apply
                                          </div>
                                        </div>
                                      </CalendarContainer>
                                    </div>
                                  </DatePicker>
                                </DatepickerWrapper>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          {/* Created by & Assigned to */}
                          {/* <TaskAuthor task={task && task} /> */}
                          {/* Checklist */}
                          <div
                            className="member-block"
                            style={{
                              marginBottom: 10,
                              backgroundColor: "white"
                            }}
                          >
                            <button
                              className="dialog-chip fs-14 center-align"
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                fontWeight: "400",
                                border: "1px solid #EBEBEB",
                                backgroundColor: "white"
                              }}
                              onClick={() => setAddChecklistFlag(true)}
                            >
                              {/* <i
                                className="fa fa-check"
                                style={{ paddingRight: 10 }}
                              /> */}
                              <img
                                src={checklistnewmodal}
                                height="14"
                                width="15"
                                style={{
                                  marginRight: "13px",
                                  marginLeft: "0px"
                                }}
                                className=" "
                                alt="Labels"
                              />
                              <span
                                style={{ fontsize: "15px", color: "black" }}
                              >
                                {" "}
                                Checklist
                              </span>
                            </button>
                          </div>

                          {/* Labels */}
                          <div
                            className="member-block"
                            style={{
                              maxWidth: "100%",
                              marginBottom: 10,
                              backgroundColor: "white"
                            }}
                          >
                            <button
                              className="dialog-chip fs-14 center-align"
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                fontWeight: "400",
                                border: "1px solid #EBEBEB",
                                backgroundColor: "white"
                              }}
                              id="UncontrolledPopover"
                              onClick={() =>
                                setOpenLabelSelection(!openLabelSelection)
                              }
                            >
                              {/* <i
                                className="fa fa-tag"
                                style={{ paddingRight: 10 }}
                              /> */}
                              <img
                                src={labelsnewmodal}
                                height="19"
                                width="19"
                                style={{
                                  marginRight: "12px",
                                  marginLeft: "-2px"
                                }}
                                className=" "
                                alt="Labels"
                              />
                              <span
                                style={{ fontsize: "15px", color: "black" }}
                              >
                                {" "}
                                Labels
                              </span>
                            </button>
                          </div>

                          {/* Priority */}
                          <div
                            className="member-block"
                            style={{
                              maxWidth: "100%",
                              marginBottom: 10,
                              backgroundColor: "white"
                            }}
                          >
                            <button
                              className="dialog-chip fs-14 center-align"
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                border: "1px solid #EBEBEB",
                                fontWeight: "400",
                                backgroundColor: "white"
                              }}
                              id="PUncontrolledPopover"
                              onClick={() =>
                                setOpenPrioritySelection(!openPrioritySelection)
                              }
                            >
                              {/* <i
                                className="fa fa-flag"
                                style={{ paddingRight: 10 }}
                              /> */}
                              <img
                                src={prioritynewmodal}
                                height="16"
                                width="15"
                                className=" "
                                alt="Labels"
                                style={{ marginRight: "14px" }}
                              />
                              <span
                                style={{ fontsize: "15px", color: "black" }}
                              >
                                {" "}
                                Priority
                              </span>
                            </button>
                          </div>

                          {/* Attachment */}
                          <div
                            className="member-block"
                            style={{
                              marginBottom: 10,
                              backgroundColor: "white"
                            }}
                          >
                            <label
                              className="dialog-chip fs-14 center-align"
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                fontWeight: "400",
                                backgroundColor: "white",
                                border: "1px solid #EBEBEB",
                                cursor: "pointer"
                              }}
                              htmlFor="commentsAttachment"
                            >
                              <label
                                style={{ marginBottom: 0, cursor: "pointer" }}
                                htmlFor="commentsAttachment"
                              >
                                {/* <i
                                  className="fa fa-paperclip"
                                  style={{ paddingRight: 12 }}
                                /> */}
                                <img
                                  src={attachmentnew}
                                  height="15"
                                  width="15"
                                  style={{
                                    marginRight: "11px",
                                    cursor: "pointer",
                                    marginTop: "-5px"
                                  }}
                                  className=" "
                                  alt="Labels"
                                />
                                <span
                                  style={{ fontsize: "15px", color: "black" }}
                                >
                                  {" "}
                                  Attachment
                                </span>
                              </label>
                            </label>
                          </div>

                          {/* Share */}
                          <div
                            className="member-block"
                            style={{
                              marginBottom: 10,
                              backgroundColor: "white"
                            }}
                            id="LUncontrolledPopover"
                          >
                            <button
                              className="dialog-chip fs-14 center-align"
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                fontWeight: "400",
                                backgroundColor: "white",
                                border: "1px solid #EBEBEB"
                              }}

                              //  onClick={()=>setOpenShareSelection(!openShareSelection)}
                            >
                              {/* <i
                                className="fa fa-share"
                                style={{ paddingRight: 10 }}
                              /> */}
                              <img
                                src={sharenew}
                                height="15"
                                width="15"
                                style={{ marginRight: "14px" }}
                                className=" "
                                alt="Labels"
                              />
                              <span
                                style={{ fontsize: "15px", color: "black" }}
                              >
                                {" "}
                                Share
                              </span>
                            </button>
                          </div>

                          <input
                            style={{ display: "none" }}
                            type="file"
                            id="commentsAttachment"
                            name="commentsAttachment"
                            onChange={e => {
                              AddAttachment(e.target.files[0]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </FileDrop>
                </div>
              </ModalBody>
            </Modal>
          </ModalWrapper>{" "}
        </>
      )}
      <CustomAlert
        CustomMessage={showDeleteCommentAlert.title}
        action={showDeleteCommentAlert.action}
        messageType={showDeleteCommentAlert.messageType}
        show={showDeleteCommentAlert.status}
        onConfirm={handleCustomAlertOnConfirm}
        PRIMARY_COLOR={props.topbarTheme.buttonColor}
        onCancel={handleCustomAlertOnCancel}
      />
      {/* <CustomNotification
        show={toast}
        message={toastMessage}
        closeNotification={() => setToast(false)}
      /> */}
      <AddLabel
        modal={addLabelModal}
        sidebarData={sidebarData}
        setmodal={() => {
          addLabelModal && setOpenLabelSelection(!openLabelSelection);
          setAddLabelModal(!addLabelModal);
        }}
        updateLabelToRedux={data => {
          props.update_Label(data);
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    topbarTheme: { ...state.themeChanger.topbarTheme },
    language: { ...state.LanguageSwitcher.language }
  };
};
const ExceedLimit = ({ setIsExceedAttachment, setIsDragEnter }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "40%",
        left: "38%"
      }}
    >
      <span
        style={{
          fontSize: 16,
          fontWeight: "600",
          color: "red"
        }}
      >
        <IntlMessages id={"task-details.filesizeerror"} />
      </span>
      {/* <span>({timer}s)</span>{" "} or */}
      {/* <u
        style={{
          fontSize: 14,
          color: "black",
          cursor: "pointer",
          fontSize: 14,
          paddingLeft: 3
        }}
        onClick={() => {
          setIsExceedAttachment(false)
          setIsDragEnter(false)
        }}
      >
        Close
      </u> */}
    </div>
  );
};
export default connect(mapStateToProps, { update_Label })(Radium(CardTask));

/* 
ref to parent div tag for scroll into view
 {isDragEnter && attachmentComments.length < 1 &&
                              <div style={{ marginTop: 10, padding: 10, border: '1px dashed #63ff2a', background: '#FFF' }}>
                                <div style={{ display: "flex" }}>
                                  <div>
                                    <img
                                      src={TaskDialogAttachmentImage}
                                      height="16"
                                      width="15"
                                      style={{
                                        marginRight: 10,
                                        fontSize: 13,
                                        position: "relative",
                                        top: "-2px",
                                        left: "-4px",
                                        marginLeft: "-2px"
                                      }}
                                    /> 
                                  </div>
                                  <div style={{ width: "100%" }}>
                                    <span
                                      className={"primary-label"}
                                      style={{ fontSize: 15, fontWeight: "600" }}
                                    >
                                      Attachment(s)
                                    </span>
                                  </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
                                  Drop Your files
                                </div>
                              </div>
                            }
 */
